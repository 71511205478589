function sortAncestryData(item) {
  return item.sort(sortData)
}

function sortData(a, b) {
  if (a.key < b.key) {
    return -1
  }
  if (a.key > b.key) {
    return 1
  }
  return 0
}

export default sortAncestryData