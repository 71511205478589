import { Component } from 'react'
import {
  SafeAreaView,
  Text,
  View,
  ScrollView,
  StyleSheet,
  Image,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  Dimensions,
  SectionList,
  Platform,
} from 'react-native'

import { i18n } from '$localization/config.js'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'

class ContactUs extends Component {
  // ========================== header ==========================
  static navigationOptions = ({ navigation }) => {
    const colorSettings = navigation.getParam('cs')

    const options = DefaultNavigationOptions({
      navigation,
      hasCloseButton: true,
      closeButtonType: 'back',
      headerTitle: i18n.t('contact_us').default,
      headerBackgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
    })

    return options
  }

  // ========================== end of header ==========================

  render() {
    return (
      <SafeAreaView>
        <Text>Contact Us</Text>
      </SafeAreaView>
    )
  }
}

export default ContactUs
