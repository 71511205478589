import { memo, Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  SafeAreaView,
} from 'react-native'

import { connect } from 'react-redux'

import { HeaderBackButton } from 'react-navigation-stack'
import Icon from 'react-native-vector-icons/Feather'
import { i18n } from '$localization/config.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

// components
import {
  showDefaultAlert,
  resetStackAction,
} from '$navigation/_utils/NavigationUtils.js'
import ButtonMainWithList from '$screens/personal_details/onboarding_disease_selection/components/button_main_with_list'
import ButtonDone from '$components/button_done'
import LoadingView from '$constants/LoadingView.js'
import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'
import translateDisease from '$screens/personal_details/relative_gene_test/_utils/TextTranslation.js'
import ButtonWithList from '$screens/personal_details/onboarding_disease_selection/components/button_with_list'
import ModalLoading from '$components/modal_loading'
import strings from './res/strings'

import FlexContainer from '$components/flex_container'
import SelectedDiseaseList from './components/selected_disease_list/SelectedDiseaseList'

// redux actions
import {
  setScreenWhereToNavigate,
  setMemberDisease,
  setMemberSkipLogic,
  setSelectedDiseaseDetails,
  setDiseaseProband,
  setIndividualDisease,
  setTypeByOrg,
  setIsSingleCategoryDisease,
} from '$redux/diseases/actions.js'

import {
  resetPositiveList,
  resetGeneTestingStore,
} from '$redux/gene_testing/actions.js'

import {
  clearProbandGeneticTesting,
  setProbandDisease,
  setProbandSkipLogic,
} from '$redux/proband/actions.js'

import {
  setShouldReloadDiseases,
  setShouldReloadGeneticTestings,
} from '$redux/member_profile/actions.js'
import { setLastLoginScreen, setError } from '$redux/defaults/actions.js'

// utils
import DiseaseList from './utils/diseaseList'
import {
  convertIncomingDiseases,
  memberSaveDiseases,
  translationConverter,
} from './utils/_utils'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'
import saveLastScreen from '$screens/_utils/SaveLastScreen.js'
import UpdateMemberProfile from '$screens/_utils/UpdateMemberProfile.js'

import { IS_WEB } from '$constants/Platforms'

import DiseaseCategories from '$screens/personal_details/onboarding_disease_selection/components/disease_sublist/DiseaseCategories.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.DiseaseListMainScreen?.name
const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

let hasGenderIdentity = false
let isLastScreenBeforeLogin = false

const MemoizedButtonWithList = memo(ButtonWithList)

class DiseaseListMain extends Component {
  colorSettings = this.props.colorSettings

  selectedDiseases = this.props.selectedDiseases

  authCredentials = this.props.authCredentials

  selectedClinician = this.props.selectedClinician

  diseaseFromOnboarding = this.props.diseaseFromOnboarding

  account = this.props.account

  probandProfile = this.props.probandProfile

  proband = this.props.proband

  currentMemberProfile = this.props.currentMemberProfile

  constructor(props) {
    super(props)

    const { diseaseType } = this.props

    this.state = {
      diseaseTypes: null,
      isLoading: true,
      selectedDiseases: this.selectedDiseases,
      savingDiseases: false,
      loadingTitle: translationConverter(
        strings.diseaseMainScreen.loadingTitle
      ),
      scrollViewHeight: DefaultFullHeight(),
      // from DiseaseSublistState
      searchValue: '',
      renderShowMoreBtn: diseaseType === 'cancer-polyps',
      collapseShowMoreBtn: false,
      diseaseListCopy: null,
      isHiddenShowMoreButton: false,
      isHiddenSeeMoreButton: true,
      isEmptyDiseaseListMessageShown: false,
      isLoadingDiseases: false,
      isEnabledUmlsSearch: false,
      umlsSearchCount: 0,
      umlsSearchSize: 0,
      isSingleCategory: false,
      categoryDiseaseType: '',
      diseaseList: [],
    }
  }

  async componentDidMount() {
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    const {
      _saveDiseaseProband_,
      probandProfile,
      lastScreenLogin,
      setTypeByOrg,
      saveError,
      currentMemberProfile,
      selectedClinician,
      diseaseFromOnboarding,
      _setShouldReloadDiseases_,
      navigation,
      colorSettings,
      _saveSelectedDiseaseDetails_,
      setIsSingleCategoryDisease,
    } = this.props

    if (probandProfile && probandProfile?.profileData?.genderIdentity) {
      hasGenderIdentity = true
    } else {
      hasGenderIdentity = false
    }

    /* Set flag to reload current member's profile */
    _setShouldReloadDiseases_(true)

    isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.diseases

    navigation.setParams({
      hasGenderIdentity,
      isLastScreenBeforeLogin,
    })

    let payload
    let memberDisease

    if (diseaseFromOnboarding) {
      const clinicianId = selectedClinician
        ? selectedClinician?.clinician_code?.id
        : null
      payload = {
        mode: 'onboarding',
        clinician_id: clinicianId,
      }

      memberDisease =
        probandProfile?.healthHistoryData?.diseasesData?.diseases ?? []
    } else {
      memberDisease =
        currentMemberProfile?.healthHistoryData?.diseasesData?.diseases ?? []
    }

    const convertedDisease = convertIncomingDiseases(memberDisease)
    _saveDiseaseProband_(convertedDisease)

    const apiPayload = {
      path: 'disease/disease_type/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: payload,
    }

    const response = await ApiFetchHandler(apiPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    setTypeByOrg(response.disease_type)

    if (response.disease_type.length === 1) {
      setIsSingleCategoryDisease(true)
      const responseDiseaseType = response.disease_type[0]

      const categoryDiseaseType = {
        diseaseType: responseDiseaseType,
      }

      _saveSelectedDiseaseDetails_(categoryDiseaseType)

      this.setState({
        isSingleCategory: true,
        categoryDiseaseType,
        isLoading: true,
      })

      await this.loadDiseaseCategories(responseDiseaseType)
      return
    }

    setIsSingleCategoryDisease(false)

    this.setState({
      diseaseTypes: this.modifyItem(response.disease_type),
      isLoading: false,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDiseases } = this.props

    if (prevProps.selectedDiseases !== selectedDiseases) {
      this.setState({
        selectedDiseases,
      })
    }
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  loadDiseaseCategories = async (categoryDiseaseType) => {
    const {
      selectedDiseases,
      selectedLanguage,
      authCredentials,
      navigation,
      colorSettings,
      diseaseType,
    } = this.props

    navigation.setParams({
      cs: colorSettings,
    })

    let payload = null

    if (this.diseaseFromOnboarding) {
      const clinicianId = this.selectedClinician
        ? this.selectedClinician?.clinician_code?.id
        : null
      payload = {
        mode: 'onboarding',
        clinician_id: clinicianId,
        disease_type: categoryDiseaseType,
      }
    } else {
      payload = {
        disease_type: categoryDiseaseType,
      }
    }

    const apiPayload = {
      method: 'post',
      path: 'disease/proband_list/',
      token: authCredentials.accessToken,
      body: payload,
    }

    const { saveError } = this.props
    const response = await ApiFetchHandler(apiPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    await this.fetchDiseasesAPI(response.diseases, selectedDiseases)
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  modifyItem = (types) => {
    const diseaseTypes = []
    let i = 1

    types.sort()
    types.map((item) => {
      diseaseTypes.push({
        id: `dc${i}`,
        title: item,
      })
      i++
    })

    return diseaseTypes
  }

  setDiseaseType = (selectedDiseases) => {
    const newDiseaseType = []
    const { diseaseTypes } = this.state

    if (selectedDiseases) {
      selectedDiseases.forEach((element) => {
        newDiseaseType.push(element.disease_type)
      })
    }

    if (diseaseTypes) {
      diseaseTypes.forEach((item) => newDiseaseType.push(item.title))
    }

    const uniqDiseaseTypes = [...new Set(newDiseaseType)]

    return this.modifyItem(uniqDiseaseTypes)
  }

  navigateToDisease = (diseaseType) => {
    const { _saveSelectedDiseaseDetails_, navigation } = this.props

    const payload = {
      diseaseType,
    }

    _saveSelectedDiseaseDetails_(payload)

    if (this.diseaseFromOnboarding) {
      navigation.navigate('OtherDiseasesList')
    } else {
      navigation.navigate('DiseasesListView')
    }
  }

  handleOnEdit = async (diseaseType, diseaseId) => {
    const { selectedDiseases } = this.state
    const {
      saveError,
      _saveIndividualDisease_,
      navigation,
      _saveScreenWhereToNavigate_,
    } = this.props

    this.setState({
      loadingTitle: 'Loading',
      savingDiseases: true,
    })

    let payload = null
    let headers = null

    if (this.diseaseFromOnboarding) {
      const clinicianId = this.selectedClinician
        ? this.selectedClinician?.clinician_code?.id
        : null
      payload = {
        mode: 'onboarding',
        clinician_id: clinicianId,
        disease_type: diseaseType,
      }
    } else {
      headers = this.authCredentials
      payload = {
        disease_type: diseaseType,
      }
    }

    try {
      const diseasePayload = {
        method: 'get',
        path: 'disease/',
        token: this.authCredentials.accessToken,
      }

      const response = await ApiFetchHandler(diseasePayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        return
      }

      const diseaseList = response.diseases
      let getDiseases = diseaseList.find((disease) => disease.id === diseaseId)
      if (!getDiseases) {
        getDiseases = diseaseList.find(
          (disease) => disease.umls_id === diseaseId
        )
      }
      const umlsDisease = selectedDiseases.find(
        (disease) => disease.disease_id === diseaseId
      )

      let destination
      const constructedUmlsDiseases = {
        ...umlsDisease,
        id: diseaseId,
        skip_logistics: [],
        short_name: umlsDisease.disease_name,
        umls_search: true,
        umls_id: diseaseId,
      }

      if (this.diseaseFromOnboarding) {
        destination = 'DiseaseSkipLogic'
      } else {
        destination = 'DiseasesSkipLogicView'
      }

      _saveIndividualDisease_(getDiseases || constructedUmlsDiseases)
      _saveScreenWhereToNavigate_(true)
      navigation.navigate(destination)
      this.setState({
        savingDiseases: false,
      })
    } catch (err) {
      console.log(
        '🚀 \n\n file: DiseaseMainList.js:466 \n\n DiseaseListMain \n\n handleOnEdit= \n\n err:',
        err
      )
    }
  }

  handleOnRemove = (diseaseId) => {
    const { selectedDiseases } = this.state
    const { _saveDiseaseProband_ } = this.props
    const diseaseList = new DiseaseList(selectedDiseases)
    const disease = diseaseList.deleteDisease(diseaseId)

    _saveDiseaseProband_(disease.list)
  }

  renderItems = (item) => {
    const { selectedDiseases } = this.state
    const diseaseList = new DiseaseList(selectedDiseases)
    const getDiseaseOfType = diseaseList.getDiseaseOfType(item.title)
    const showList = getDiseaseOfType.length !== 0

    return (
      <ButtonMainWithList
        key={item.id}
        colorSettings={this.colorSettings}
        buttonItem={item ?? ''}
        onPress={this.navigateToDisease}
        showList={showList}
        data={getDiseaseOfType.list}
        toggleEdit={(diseaseId) => this.handleOnEdit(item.title, diseaseId)}
        toggleRemove={(diseaseId) => this.handleOnRemove(diseaseId)}
      />
    )
  }

  infoAlert = () => {
    showDefaultAlert(
      translationConverter(strings.diseaseMainScreen.infoAlertTitle, 'default'),
      translationConverter(strings.diseaseMainScreen.infoAlertBody, 'default')
    )
  }

  handleDoneButtonTitle = () => {
    let title

    if (this.diseaseFromOnboarding) {
      title = translationConverter(
        strings.diseaseMainScreen.bottomButtonTitle.next
      )
    } else {
      title = translationConverter(
        strings.diseaseMainScreen.bottomButtonTitle.done
      )
    }

    return title
  }

  handleNextButton = async () => {
    const {
      navigation,
      _saveProbandDisease_,
      _saveProbandSkipLogic_,
      _clearProbandGeneticTesting_,
      _resetGeneTestingStore_,
      authCredentials,
      account,
      saveError,
      proband,
    } = this.props
    const { selectedDiseases } = this.state
    const { navigate, pop } = navigation
    const diseases = new DiseaseList(selectedDiseases)
    const diseaseSummary = diseases.getDiseaseSummary()
    const memberDisease = {
      diseases: diseaseSummary.diseases,
    }
    const memberSkipLogic = {
      skip_logic: diseaseSummary.skip_logic,
    }

    _saveProbandDisease_(memberDisease)
    _saveProbandSkipLogic_(memberSkipLogic)
    _clearProbandGeneticTesting_()
    _resetGeneTestingStore_()

    if (this.diseaseFromOnboarding) {
      const screen = {
        last_screen: RAW_LAST_VISITED_SCREENS.diseases,
      }

      try {
        await saveLastScreen({
          account,
          authCredentials,
          item: screen,
          saveError,
          navigation,
        })

        const { probandID } = proband

        const selectedMember = {
          memberID: probandID,
        }

        const nextRoute = getNextWorkflowRoute({
          defaultNextRoute: CUSTOM_FLOW_ROUTES.PersonalGeneticTesting.name,
        })

        await this.handleMemberSaveDiseases(selectedMember, () =>
          handleNavigateToNextScreen({
            navigation,
            routeName: nextRoute,
            selectedScreen: '',
          })
        )

        this.setState({ isLoading: false })
      } catch (err) {
        console.log(
          '🚀 \n\n file: DiseaseMainList.js:580 \n\n DiseaseListMain \n\n handleNextButton= \n\n err:',
          err
        )
      }
    } else {
      const nav = pop()
      await this.handleMemberSaveDiseases(this.currentMemberProfile, () => nav)
    }
  }

  handleMemberSaveDiseases = async (id, onNavigate) => {
    const { selectedDiseases } = this.state

    await memberSaveDiseases({
      diseaseSelected: selectedDiseases,
      authCredentials: this.authCredentials,
      currentSelectedMember: id,
      probandProfile: this.probandProfile,
      savingStatusStart: () => {
        this.setState({
          savingDiseases: true,
        })
      },
      savingStatusEnd: () => {
        setTimeout(() => {
          this.setState({
            savingDiseases: false,
          })
        }, 0)
      },
      APIfetchingActions: () => {
        onNavigate()
      },
    })
  }

  handleHeaderTitle = () => {
    const { diseaseFromOnboarding } = this.props
    const selectedMemberId = this.currentMemberProfile?.memberID ?? 'memberId'
    const probandId = this?.proband?.probandID ?? null // <- Use this
    const { isSingleCategory } = this.state

    let title

    if (diseaseFromOnboarding || selectedMemberId === probandId) {
      if (!isSingleCategory) {
        title = translationConverter(
          strings.diseaseMainScreen.headerTitle.user,
          'default'
        )
      }

      title = translationConverter(
        strings.diseaseMainScreen.headerTitleSingleCategory.user,
        'default'
      )
      return title
    }

    if (!isSingleCategory) {
      title = translationConverter(
        strings.diseaseMainScreen.headerTitle.member,
        'default'
      )
    } else {
      title = translationConverter(
        strings.diseaseMainScreen.headerTitleSingleCategory.member,
        'default'
      )
    }

    return title
  }

  updateProbandProfileStorage = async () => {
    const { proband, _saveProbandProfileToStore_, authCredentials } = this.props

    const memberData = {
      proband_id: proband.probandID,
      member_id: proband.probandID,
    }

    try {
      const memberProfile = await UpdateMemberProfile(
        memberData,
        authCredentials
      )
      _saveProbandProfileToStore_(memberProfile)
    } catch (err) {
      console.log(
        '🚀 \n\n file: DiseaseMainList.js:668 \n\n DiseaseListMain \n\n updateProbandProfileStorage= \n\n err:',
        err
      )
    }
  }

  handleRenderedList = () => {
    const { diseaseTypes } = this.state
    if (!diseaseTypes) {
      return
    }
    const diseaseTypeList = []

    for (const val in diseaseTypes) {
      diseaseTypeList.push(this.renderItems(diseaseTypes[val]))
    }

    return diseaseTypeList
  }

  // ===============================
  // Display loading screen
  // after API loads
  // ===============================

  loadSelectedDisease = (selectedDiseases) => {
    const { colorSettings } = this.props

    const selectedDiseaseList = selectedDiseases.map((item) => {
      const diseaseListComponent = (
        <SelectedDiseaseList
          colorSettings={colorSettings}
          item={item}
          toggleEdit={() => this.handleOnEdit(item.title, item.disease_id)}
          toggleRemove={() => this.handleOnRemove(item.disease_id)}
        />
      )
      return diseaseListComponent
    })
    return selectedDiseaseList
  }

  loadScreen = (selectedDiseases) => {
    if (this.state.isLoading) {
      return (
        <FlexContainer
          style={[
            styles.loadingContainer,
            {
              height: this.state.scrollViewHeight,
            },
          ]}
        >
          <LoadingView
            backgroundColor={this.colorSettings?.onboarding_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_text_2}
            textColor={this.colorSettings?.btn_no_fill_text_2}
            visible={this.state.isLoading}
          />
        </FlexContainer>
      )
    }
    return (
      <ScrollView
        style={{ height: this.state.scrollViewHeight }}
        contentContainerStyle={styles.contentContainer}
      >
        <FlexContainer>
          <Text
            style={[
              styles.titleText,
              {
                marginBottom: 17,
                color: this.colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {this.handleHeaderTitle()}
          </Text>
          <TouchableOpacity
            onPress={this.infoAlert}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginVertical: 10,
            }}
          >
            <Icon
              name="info"
              size={20}
              color={this.colorSettings?.text_input_title_color_2 || 'black'}
            />
          </TouchableOpacity>
          {this.loadSelectedDisease(selectedDiseases)}
          <View style={{ marginBottom: 20 }}>
            <Text
              style={[
                global_styles.titleHelperText,
                {
                  color: this.colorSettings?.text_input_label_color_2,
                },
              ]}
            >
              {translationConverter(
                strings.diseaseMainScreen.subtitle,
                'default'
              )}
            </Text>
          </View>
          {/* render disease types */}
          {this.handleRenderedList()}
        </FlexContainer>
      </ScrollView>
    )
  }

  // from DiseaseSubList functions

  renderCategoryItems = (item) => {
    const { selectedDiseases } = this.state
    const { selectedDiseases: probandDiseases } = this.props
    const diseaseList = new DiseaseList(selectedDiseases)
    const disease = diseaseList.get(item.id, item.umls_id)

    return (
      <MemoizedButtonWithList
        key={item.id}
        colorSettings={this.colorSettings}
        title={item?.disease_name ?? ''}
        fromUmls={item.umls_search}
        toggled={!!disease}
        onPress={(onToggle) => this.handleButtonAction(item, onToggle)}
        data={disease}
        item={item}
        diseaseFilter={this.diseaseFilter({
          diseaseListA: probandDiseases,
          diseaseListB: item,
        })}
        onEdit={() => this.handleButtonAction(item, true)}
      />
    )
  }

  handleButtonAction = (disease, onToggle) => {
    const {
      _saveIndividualDisease_,
      _setScreenWhereToNavigate_,
      navigation,
      colorSettings,
      _saveDiseaseProband_,
    } = this.props
    let destination

    if (!onToggle) {
      const { selectedDiseases } = this.state
      const diseaseList = new DiseaseList(selectedDiseases)
      const updatedDisease = diseaseList.deleteDisease(disease.id)
      _saveDiseaseProband_(updatedDisease.list)
    }

    _saveIndividualDisease_(disease)
    _setScreenWhereToNavigate_(false)
    if (this.diseaseFromOnboarding) {
      destination = 'DiseaseSkipLogic'
    } else {
      destination = 'DiseasesSkipLogicView'
    }

    navigation.navigate(destination)
  }

  getHiddenDiseaseList = (searchValue) => {
    const { completeDiseaseList } = this.state

    let isHiddenShowMoreButton
    let collapseShowMoreBtn =
      this.handleShowMoreButtonOnMount(completeDiseaseList)

    if (searchValue.length > 0) {
      isHiddenShowMoreButton = true
      collapseShowMoreBtn = true
    }

    this.setState({
      diseaseList: this.diseaseSearchQuery(searchValue, completeDiseaseList),
      isHiddenSeeMoreButton: false,
      isHiddenShowMoreButton,
      collapseShowMoreBtn,
      searchValue,
      isEnabledUmlsSearch: true,
      umlsSearchCount: 0,
      isLoadingDiseases: false,
      umlsSearchSize: 0,
    })
  }

  handleShowMoreButtonOnMount = (diseaseList) => {
    const { selectedDiseases } = this
    const selectedDiseaseIds = selectedDiseases.map((item) => item.disease_id)

    const cleanFilterDiseaseList = diseaseList.filter((disease) => disease)
    const checkShowDisease = cleanFilterDiseaseList.filter(
      (item) => selectedDiseaseIds.includes(item.id) && item.show === 'expanded'
    )
    return checkShowDisease.length !== 0
  }

  diseaseSearchQuery = (searchValue, diseaseList) => {
    const itemLower = searchValue.toLowerCase()
    const cleanFilter = diseaseList.filter((diseases) => diseases)

    const diseaseQuery = cleanFilter.filter((disease) => {
      let diseaseName = disease.disease_name
      diseaseName = translateDisease({ diseaseName, isLowerCased: true })

      return diseaseName.includes(itemLower)
    })

    return diseaseQuery
  }

  renderDiseaseTitle = () => {
    const { diseaseType } = this.props
    const { categoryDiseaseType } = this.state

    const title = categoryDiseaseType.diseaseType

    let name = title
      .trim()
      .toLowerCase()
      .replace(/\//g, ' ')
      .replace(/[^\w-\s]/gi, '')
      .replace(/\s/g, '_')
      .replace(/_conditions/, '')

    if (!title.includes('cancer') || !title.includes('polyps')) {
      name += '_conditions'
    }

    return i18n.t(name).title
  }

  handleOnChangeSearch = async (searchValue, diseaseList) => {
    const { diseaseListCopy, completeDiseaseList } = this.state

    this.setState({
      isLoadingDiseases: true,
      diseaseList: [],
    })

    const queryNonHidden = this.diseaseSearchQuery(searchValue, diseaseListCopy)
    const queryHiddenDiseases = this.diseaseSearchQuery(
      searchValue,
      completeDiseaseList
    )

    const diseaseDifference = [
      ...this.getDiseaseDifference(queryNonHidden, queryHiddenDiseases),
      ...this.getDiseaseDifference(queryHiddenDiseases, queryNonHidden),
    ]

    if (queryNonHidden.length > 0) {
      this.getNonHiddenDiseaseList(
        searchValue,
        diseaseListCopy,
        diseaseDifference.length > 0,
        queryNonHidden
      )
    } else if (queryHiddenDiseases.length > 0) {
      this.getHiddenDiseaseList(searchValue)
    } else {
      this.umlsSearch(searchValue)
    }
  }

  getDiseaseDifference = (queryNonHidden, queryHiddenDiseases) =>
    queryNonHidden.filter(
      (nonHiddenDisease) =>
        !queryHiddenDiseases.some(
          (hiddenDisease) => nonHiddenDisease.id === hiddenDisease.id
        )
    )

  getNonHiddenDiseaseList = (
    item,
    diseaseList,
    hasValueHiddenDiseaseQuery = false,
    diseaseSearchFiltered
  ) => {
    let isHiddenShowMoreButton
    const isHiddenSeeMoreButton = true
    let isEnabledUmlsSearch = false
    let collapseShowMoreBtn = this.handleShowMoreButtonOnMount(diseaseList)

    if (item.length > 0) {
      isHiddenShowMoreButton = true
      collapseShowMoreBtn = true
      isEnabledUmlsSearch = !hasValueHiddenDiseaseQuery
    }

    this.setState({
      searchValue: item,
      diseaseList: diseaseSearchFiltered,
      isHiddenShowMoreButton,
      collapseShowMoreBtn,
      isHiddenSeeMoreButton: false,
      isEnabledUmlsSearch,
      isEmptyDiseaseListMessageShown: false,
      umlsSearchCount: 0,
      isLoadingDiseases: false,
      umlsSearchSize: 0,
    })
  }

  umlsSearch = async (searchValue, addUmlsDiseases = false) => {
    const { authCredentials, selectedLanguage } = this.props
    const { umlsSearchCount, diseaseList, umlsSearchSize } = this.state
    const umlsCount = addUmlsDiseases ? umlsSearchCount + 10 : 0

    this.setState({
      isLoadingDiseases: true,
    })

    if (!addUmlsDiseases) {
      this.setState({
        diseaseList: [],
      })
    }

    const language = {
      fr: 'FRE',
      en: 'ENG',
      nl: 'DUT',
      de: 'GER',
      es: 'SPA',
    }

    const payload = {
      path: `umls/search/?term=${searchValue}&start=${umlsCount}&lang=${
        language[selectedLanguage] || 'ENG'
      }`,
      method: 'get',
      token: authCredentials.accessToken,
    }

    const { saveError } = this.props

    const itemSearch = await ApiFetchHandler(payload)

    if (itemSearch.isError) {
      this.setState({
        isLoadingDiseases: false,
        isHiddenSeeMoreButton: true,
      })
      this.setEmptyDiseaseListMessage(searchValue)

      saveError({
        isShown: true,
        status: itemSearch.status,
        message: itemSearch.error,
      })
      return
    }

    if (!itemSearch) return
    const newDisease = addUmlsDiseases ? [...diseaseList] : []
    const umlsSearchSizeCount = umlsSearchSize + itemSearch.ConceptList.length
    const isHiddenSeeMoreButton = addUmlsDiseases
      ? itemSearch.ConceptSize <= umlsSearchSizeCount
      : itemSearch.ConceptSize <= 10

    itemSearch.ConceptList.forEach((umls) => {
      newDisease.push({
        id: umls.CUI,
        umls_id: umls.CUI,
        disease_name: umls.Name,
        show: 'always',
        gender_specific: null,
        skip_logistics: [],
        umls_search: true,
        umls_name: umls.Name,
        short_name: umls.Name,
      })
    })

    this.setState({
      isLoadingDiseases: false,
      searchValue,
      diseaseList: newDisease,
      isHiddenSeeMoreButton,
      isHiddenShowMoreButton: true,
      isEnabledUmlsSearch: true,
      umlsSearchCount: umlsCount,
      isEmptyDiseaseListMessageShown: itemSearch.ConceptSize <= 0,
      umlsSearchSize: umlsSearchSizeCount,
    })
  }

  setEmptyDiseaseListMessage = (item) => {
    this.setState({
      isEmptyDiseaseListMessageShown: true,
      searchValue: item,
      diseaseList: [],
      isHiddenSeeMoreButton: true,
      isHiddenShowMoreButton: true,
      isLoadingDiseases: false,
    })
  }

  renderList = (shownData = false) => {
    const data = this.handleShowData(shownData)
    const diseaseList = []

    for (const val in data) {
      diseaseList.push(this.renderCategoryItems(data[val]))
    }

    return diseaseList
  }

  handleShowData = (isShown = true) => {
    const { currentSelectedMember, diseaseFromOnboarding, probandProfile } =
      this.props
    const { diseaseList } = this.state

    const diseases = diseaseList || []
    let filterShownDisease
    let filterGender
    const cleanDiseaseFilter = diseases.filter((n) => n)

    if (isShown) {
      filterShownDisease = cleanDiseaseFilter.filter(
        (disease) => disease.show === 'always' || !disease?.show
      )
    } else {
      filterShownDisease = cleanDiseaseFilter.filter(
        (disease) => disease.show !== 'always' && disease?.show
      )
    }

    if (diseaseFromOnboarding) {
      filterGender = filterShownDisease.filter(
        (disease) =>
          disease.gender_specific ===
            probandProfile?.profileData?.biologicalGender ||
          disease.gender_specific === null
      )
    } else {
      filterGender = filterShownDisease.filter(
        (disease) =>
          disease.gender_specific === currentSelectedMember.gender ||
          disease.gender_specific === null
      )
    }
    return filterGender
  }

  renderCollapsedDiseases = () => {
    if (this.state.collapseShowMoreBtn) {
      return this.renderList(false)
    }
  }

  fetchDiseasesAPI = async (diseaseList, selectedDiseases) => {
    const { authCredentials, diseaseTypeByOrg, saveError } = this.props
    const { categoryDiseaseType } = this.state
    const listTypePayload = {
      path: `disease/?disease_type=${categoryDiseaseType}`,
      method: 'get',
      token: authCredentials.accessToken,
    }

    const nonOrgDiseaseTypePayload = {
      path: 'diseases/disease_types/',
      method: 'get',
      token: authCredentials.accessToken,
    }

    const diseasesPayload = {
      path: 'disease/',
      method: 'get',
      token: authCredentials.accessToken,
    }

    const [fullListTypeDiseases, getDiseases, nonOrgDiseaseType] =
      await Promise.all([
        ApiFetchHandler(listTypePayload),
        ApiFetchHandler(diseasesPayload),
        ApiFetchHandler(nonOrgDiseaseTypePayload),
      ])

    if (fullListTypeDiseases.isError) {
      saveError({
        isShown: true,
        status: fullListTypeDiseases.status,
        message: fullListTypeDiseases.error,
      })
    }
    if (getDiseases.isError) {
      saveError({
        isShown: true,
        status: getDiseases.status,
        message: getDiseases.error,
      })
    }
    if (nonOrgDiseaseType.isError) {
      saveError({
        isShown: true,
        status: nonOrgDiseaseType.status,
        message: nonOrgDiseaseType.error,
      })
    }

    const filteredNonOrgDiseaseType = nonOrgDiseaseType.disease_types.filter(
      (type) => !diseaseTypeByOrg.includes(type)
    )

    const filteredDiseaseTypeList = diseaseTypeByOrg.filter(
      (type) => !nonOrgDiseaseType.disease_types.includes(type)
    )

    const getListDifference = [
      ...filteredNonOrgDiseaseType,
      ...filteredDiseaseTypeList,
      'other_conditions',
    ]

    const filterHiddenDisease = getDiseases.diseases.filter((fullListItem) =>
      getListDifference.some(
        (listDifferenceItem) => fullListItem.disease_type === listDifferenceItem
      )
    )

    const finalList =
      categoryDiseaseType === 'other_conditions'
        ? filterHiddenDisease
        : fullListTypeDiseases.diseases

    const generatedDiseaseList = this.getHiddenSelectedDisease(
      diseaseList,
      finalList,
      selectedDiseases
    )

    this.setState({
      collapseShowMoreBtn:
        this.handleShowMoreButtonOnMount(generatedDiseaseList),
      diseaseList: generatedDiseaseList,
      diseaseListCopy: generatedDiseaseList,
      isLoading: false,
      completeDiseaseList: finalList,
    })
  }

  getHiddenSelectedDisease = (
    shownDiseases,
    fullDiseaseList,
    selectedDiseases
  ) => {
    const setDiseases = []

    selectedDiseases.forEach((disease) => {
      const found = shownDiseases.find(
        (currentDisease) => currentDisease.id === disease.disease_id
      )

      if (!found) {
        const selected = fullDiseaseList.find(
          (currentSelectedDisease) =>
            currentSelectedDisease.id === disease.disease_id
        )
        setDiseases.push(selected)
      }
    })

    const newDiseaseList = shownDiseases.concat(setDiseases)
    return this.alphabeticalSort(newDiseaseList)
  }

  alphabeticalSort = (diseaseList) => {
    const sortedDiseaseList = diseaseList.sort((a, b) => {
      if (a.disease_name < b.disease_name) {
        return -1
      }
      if (a.disease_name > b.disease_name) {
        return 1
      }
      return 0
    })
    return sortedDiseaseList
  }

  diseaseFilter = ({ diseaseListA, diseaseListB }) => {
    const diseaseDifference = diseaseListA.some(
      (disease) => disease.disease_id === diseaseListB.id
    )
    return diseaseDifference
  }

  render() {
    const { navigation, diseaseFromOnboarding, colorSettings } = this.props
    const {
      selectedDiseases,
      savingDiseases,
      loadingTitle,
      isSingleCategory,
      isLoading,
      scrollViewHeight,
      searchValue,
      renderShowMoreBtn,
      collapseShowMoreBtn,
      diseaseListCopy,
      isHiddenShowMoreButton,
      isHiddenSeeMoreButton,
      isEmptyDiseaseListMessageShown,
      isLoadingDiseases,
      isEnabledUmlsSearch,
    } = this.state

    const getDiseaseList = isEnabledUmlsSearch
      ? () => this.umlsSearch(searchValue, true)
      : () => this.getHiddenDiseaseList(searchValue)

    const shouldLoadSingleCategoryScreen = !isSingleCategory ? (
      this.loadScreen(selectedDiseases)
    ) : (
      <DiseaseCategories
        currentState={{
          isLoading,
          scrollViewHeight,
          searchValue,
          renderShowMoreBtn,
          collapseShowMoreBtn,
          diseaseListCopy,
          isHiddenShowMoreButton,
          isHiddenSeeMoreButton,
          isEmptyDiseaseListMessageShown,
          isLoadingDiseases,
          isEnabledUmlsSearch,
        }}
        currentProps={{
          colorSettings,
        }}
        renderDiseaseTitle={this.handleHeaderTitle()}
        handleOnChangeSearch={(value) =>
          this.handleOnChangeSearch(value, diseaseListCopy)
        }
        renderList={this.renderList(true)}
        renderCollapsedDiseases={this.renderCollapsedDiseases()}
        getDiseaseList={getDiseaseList}
        buttonShowMoreOnPress={(val) =>
          this.setState({ collapseShowMoreBtn: val })
        }
      />
    )

    return (
      <MainWrapper
        navigation={navigation}
        disableLogout={diseaseFromOnboarding}
      >
        <SafeAreaView
          style={[
            styles.container,
            {
              height: '100%',
              backgroundColor:
                this.colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          {/* Content Body */}
          {shouldLoadSingleCategoryScreen}
          {/* Next button */}
          <ButtonDone
            title={this.handleDoneButtonTitle()}
            onPress={() => this.handleNextButton()}
            colorSettings={this.colorSettings}
          />
          <ModalLoading
            colorSettings={this.colorSettings}
            isVisible={savingDiseases}
            loadingTitle={loadingTitle}
          />
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
  },
  contentContainer: {
    marginHorizontal: 17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
  },
  tooltipImage: {
    marginBottom: 17,
    alignSelf: 'center',
    height: 16,
    width: 16,
  },
  loadingContainer: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

// <Redux functions>
function mapGlobalStateToProps(state) {
  const {
    store,
    probandStore,
    diseaseStore,
    accountStore,
    clinicianStore,
    memberProfileStore,
  } = state
  return {
    colorSettings: store.colorSettings,
    proband: probandStore.proband,
    selectedDiseases: diseaseStore.probandDiseases,
    authCredentials: accountStore.authCredentials,
    selectedClinician: clinicianStore.selectedClinician,
    diseaseFromOnboarding: diseaseStore.fromOnboarding,
    basicFamilyMembers: probandStore.basicFamilyMembers,
    probandProfile: probandStore.probandProfile,
    currentMemberProfile: memberProfileStore.memberProfile,
    account: accountStore.account,
    lastScreenLogin: store.lastScreenLogin,
    // added from DiseaseSublist
    diseaseList: diseaseStore.selectedDisease.diseasesPerType,
    diseaseType: diseaseStore.selectedDisease.diseaseType,
    currentSelectedMember: memberProfileStore.currentMember,
    selectedLanguage: store.selectedLanguage,
    diseaseTypeByOrg: diseaseStore.diseaseTypeByOrg,
  }
}

const actionCreator = {
  _saveSelectedDiseaseDetails_: setSelectedDiseaseDetails,
  _saveDiseaseProband_: setDiseaseProband,
  _saveScreenWhereToNavigate_: setScreenWhereToNavigate,
  _saveIndividualDisease_: setIndividualDisease,
  _saveMemberDisease_: setMemberDisease,
  _saveMemberSkipLogic_: setMemberSkipLogic,
  _resetPositiveList_: resetPositiveList,
  _clearProbandGeneticTesting_: clearProbandGeneticTesting,
  _resetGeneTestingStore_: resetGeneTestingStore,
  _saveProbandDisease_: setProbandDisease,
  _saveProbandSkipLogic_: setProbandSkipLogic,
  _setScreenWhereToNavigate_: setScreenWhereToNavigate,
  setTypeByOrg,
  setIsSingleCategoryDisease,

  /* MemberProfile Reloader Actions */
  _setShouldReloadDiseases_: setShouldReloadDiseases,
  _setShouldReloadGeneticTestings_: setShouldReloadGeneticTestings,
  saveError: setError,
}

export default connect(mapGlobalStateToProps, actionCreator)(DiseaseListMain)
