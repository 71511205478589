import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
  StatusBar,
} from 'react-native'

import { connect } from 'react-redux'
import { MaterialIndicator } from 'react-native-indicators'
import { i18n } from '$localization/config.js'

import PhoneInputView from '$components/phone-input-view'
import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { hasInputMask } from '$api/_utils/Utils.js'
import { setError } from '$redux/defaults/actions.js'

import custom_styles from './styles/main.styles.js'
import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { WEB } from '$constants/Platforms.js'

import { getLang } from '$utils/lang_utils.js'

const inputAccessoryViewID = 'ForgotPWScreenEnterPhoneNumber'

class ForgotPWScreenEnterPhoneNumber extends Component {
  phoneNumber = ''

  formattedPhoneNumber = ''

  numSet1 = ''

  numSet2 = ''

  numSet3 = ''

  appSettings = this.props.appSettings

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      showLoadingView: false,
      phoneNumber: '',
      formattedPhoneNumber: '',
      countryPickerVisible: false,
      cca2: this.appSettings.default_country_code,
      phonePrefix: this.appSettings.default_dialing_code,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const isValid = this.validateInput()
    const style = isValid
      ? 'bottom_next_btn_enabled'
      : 'bottom_next_btn_disabled'

    return {
      backgroundColor: this.colorSettings?.[style],
      borderColor: this.colorSettings?.[style] || 'black',
    }
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const enabled = this.validateInput()
    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  validateInput = () => {
    if (this.phoneNumber && this.phoneNumber.length >= 5) {
      return true
    }
    return false
  }

  phoneNumberDidChanged = (value) => {
    this.phoneNumber = value.replace(/[^A-Z0-9]/gi, '')

    const len = this.phoneNumber.length
    this.formattedPhoneNumber = this.phoneNumber

    if (!hasInputMask(this.state.cca2)) {
      this.setState({
        formattedPhoneNumber: this.formattedPhoneNumber,
      })
      return
    }

    if (len <= 10) {
      this.numSet1 = this.phoneNumber.substring(0, 3).trim()
      this.numSet2 = this.phoneNumber.substring(3, 6).trim()
      this.numSet3 = this.phoneNumber.substring(6).trim()
    }

    if (len === 11) {
      this.numSet1 = this.phoneNumber.substring(0, 3).trim()
      this.numSet2 = this.phoneNumber.substring(3, 7).trim()
      this.numSet3 = this.phoneNumber.substring(7).trim()
    }

    if (len === 12) {
      this.numSet1 = this.phoneNumber.substring(0, 4).trim()
      this.numSet2 = this.phoneNumber.substring(4, 8).trim()
      this.numSet3 = this.phoneNumber.substring(8).trim()
    }

    this.formattedPhoneNumber = this.numSet1

    if (len > 3) {
      this.formattedPhoneNumber = `(${this.numSet1})` + ` ${this.numSet2}`
    }
    if (len > 6) {
      this.formattedPhoneNumber =
        `(${this.numSet1})` + ` ${this.numSet2} ${this.numSet3}`
    }

    this.setState({
      formattedPhoneNumber: this.formattedPhoneNumber,
    })
  }

  onPressFlag = () => {
    this.toggleCountryPicker(true)
  }

  toggleCountryPicker = (visible = false) => {
    this.setState({ countryPickerVisible: visible })
  }

  countryPickerOnSelect = (country) => {
    const _cca2 = country.cca2.toLowerCase()
    this.phone.selectCountry(_cca2)
    this.setState(
      {
        cca2: _cca2,
        phonePrefix: country.callingCode[0],
      },
      function () {
        this.phoneNumberDidChanged(this.phoneNumber)
      }
    )
  }

  nextButtonAction = async () => {
    Keyboard.dismiss()

    if (!this.validateInput()) {
      return
    }
    this.setState({ showLoadingView: true })
    await this.requestVerificationCode()
  }

  requestVerificationCode = async (shouldNext = true) => {
    /* Prepare API request for verification code */
    // Note: Email should be empty string for now.

    const { phonePrefix } = this.state
    const { navigation } = this.props
    const payload = {
      dialing_code: phonePrefix,
      phone_number: this.phoneNumber,
      email: '',
      type: 2,
      mode: 'password_reset',
      lang: getLang(),
    }

    const verificationCodePayload = {
      path: 'account/new_verification_code/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'ForgotPWScreenEnterPhoneNumber.js',
      },
    }

    const response = await ApiFetchHandler(verificationCodePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      switch (response.status) {
        case 404:
          showDefaultAlert(
            i18n.t('phone_number_not_found').default,
            i18n.t(
              'sorry_the_phone_number_you_entered_does_not_exist_in_our_system'
            ).default
          )
          break
        default:
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          break
      }
      return
    }

    const { account_id: accountID, email } = response
    const phoneNumberWithPrefix = `+${phonePrefix}${this.phoneNumber}`

    if (shouldNext) {
      navigation.push('ForgotPWEnterCode', {
        accountID,
        email,
        phoneNumber: phoneNumberWithPrefix,
        type: payload.type,
        resendCodeAction: () => this.requestVerificationCode(false),
      })
    }

    this.setState({ showLoadingView: false })
  }

  render() {
    let enableKeyboardAvoiding = false
    if (Platform.OS === 'ios') {
      enableKeyboardAvoiding = true
    }

    return (
      <View
        style={[
          custom_styles.container,
          { backgroundColor: this.colorSettings?.splash_bgcolor },
        ]}
      >
        {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}

        <KeyboardAvoidingView
          nativeID="web_scaled_main_container"
          style={{ flex: 1.0 }}
          contentContainerStyle={{ flex: 1.0 }}
          behavior="position"
          enabled={enableKeyboardAvoiding}
        >
          <ScrollView
            style={{ height: this.state.scrollViewHeight }}
            contentContainerStyle={[
              custom_styles.contentContainer,
              { marginHorizontal: 32 },
            ]}
          >
            {/* <Content> */}
            <View style={custom_styles.content}>
              <View style={{ flex: 0.3, width: '100%' }}>
                <View style={{ flex: 1.0 }}>
                  {/* <Title Text> */}
                  <View style={{ flex: 0.3, justifyContent: 'center' }}>
                    <Text
                      style={[
                        custom_styles.titleText,
                        {
                          color: this.colorSettings?.text_input_title_color_1,
                        },
                      ]}
                    >
                      {i18n.t('enter_your_phone_number').default}
                    </Text>
                  </View>
                  {/* </Title Text> */}

                  <View style={{ flex: 0.1 }} />

                  {/* <Main Text Input> */}
                  <View style={{ flex: 0.3, justifyContent: 'center' }}>
                    <View
                      style={[
                        custom_styles.mainTextInputContainer,
                        { flex: 1.0, flexDirection: 'row' },
                      ]}
                    >
                      <View style={{ flex: 0.1, backgroundColor: '' }} />

                      <View style={{ flex: 0.2 }}>
                        <View
                          style={{
                            backgroundColor: '',
                            flex: 1.0,
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}
                        >
                          <PhoneInputView
                            sender={this}
                            textColor={this.colorSettings?.text_input_color_1}
                            initialCountryCode={this.state.cca2}
                            formattedPhoneNumber={
                              this.state.formattedPhoneNumber
                            }
                            countryPickerVisible={
                              this.state.countryPickerVisible
                            }
                          />
                        </View>
                      </View>

                      <View style={{ flex: 0.1, backgroundColor: '' }} />

                      <View
                        style={[
                          custom_styles.mainTextInputContainer,
                          { flex: 0.6 },
                        ]}
                      >
                        <TextInput
                          nativeID="web_text_input"
                          style={[
                            custom_styles.mainTextInput,
                            {
                              // fontSize: (!hasInputMask(this.state.cca2) && this.state.formattedPhoneNumber === "") ? 15 : 20
                            },
                          ]}
                          inputAccessoryViewID={inputAccessoryViewID}
                          maxLength={20}
                          selectionColor="black"
                          keyboardType="phone-pad"
                          placeholderTextColor={
                            this.colorSettings
                              ?.text_input_placeholder_color_1 ||
                            'rgba(74, 74, 74, 0.5)'
                          }
                          placeholder={
                            hasInputMask(this.state.cca2)
                              ? '(000) 000 0000'
                              : i18n.t('enter_number').default
                          }
                          clearButtonMode="while-editing"
                          onChangeText={this.phoneNumberDidChanged}
                          onSubmitEditing={this.nextButtonAction}
                          value={this.state.formattedPhoneNumber}
                        />
                      </View>

                      <View style={{ flex: 0.1, backgroundColor: '' }} />
                    </View>

                    <View style={{ height: 1.0 }}>
                      <View style={{ flex: 1.0, flexDirection: 'row' }}>
                        <View
                          style={{ flex: 0.05, backgroundColor: 'transparent' }}
                        />
                        <View
                          style={{
                            flex: 0.9,
                            backgroundColor:
                              this.colorSettings
                                ?.text_input_placeholder_color_1 || 'black',
                          }}
                        />
                        <View
                          style={{ flex: 0.05, backgroundColor: 'transparent' }}
                        />
                      </View>
                    </View>
                  </View>
                  {/* </Main Text Input> */}

                  {/* <Helper Text Container> */}
                  <View style={{ flex: 0.3, justifyContent: 'center' }}>
                    <View style={{ flex: 1.0, flexDirection: 'row' }}>
                      <View
                        style={{ flex: 0.05, backgroundColor: 'transparent' }}
                      />
                      <View
                        style={[
                          custom_styles.helperTextContainer,
                          { flex: 0.9 },
                        ]}
                      >
                        <Text
                          style={[
                            custom_styles.helperText,
                            {
                              color:
                                this.colorSettings?.text_input_label_color_1,
                            },
                          ]}
                        >
                          {
                            i18n.t(
                              'we_just_need_your_registered_phone_number_to_send_you_the_6-digit_code'
                            ).default
                          }
                        </Text>
                      </View>
                      <View
                        style={{ flex: 0.05, backgroundColor: 'transparent' }}
                      />
                    </View>
                  </View>
                  {/* </Helper Text Container> */}
                </View>
              </View>
            </View>
            {/* </Content> */}

            {/* <Loading Indicator> */}
            <View
              style={{
                position: 'absolute',
                bottom: 32,
                height: 60,
                width: '100%',
              }}
            >
              {this.toggleLoadingIndicator(this.state.showLoadingView)}
            </View>
            {/* </Loading Indicator> */}
          </ScrollView>
        </KeyboardAvoidingView>

        {/* <Next button> */}
        <View style={custom_styles.nextButtonContainer}>
          <TouchableOpacity
            disabled={this.toggleNextButton()}
            style={[
              custom_styles.nextButton,
              this.toggleNextButtonColor(),
              {
                backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            onPress={this.nextButtonAction}
          >
            <Text
              style={[
                custom_styles.nextButtonText,
                {
                  color: this.toggleNextButtonText(),
                },
              ]}
            >
              {i18n.t('next').default}
            </Text>
          </TouchableOpacity>
        </View>

        <NextButtonAccessoryView
          nativeID={inputAccessoryViewID}
          backgroundColor="white"
          labelText={i18n.t('next').default}
          labelColor={this.toggleNextButtonText()}
          disabled={this.toggleNextButton()}
          style={[
            custom_styles.nextButton,
            this.toggleNextButtonColor(),
            {
              backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
            },
          ]}
          onPress={this.nextButtonAction}
        />
        {/* </Next button> */}
      </View>
    )
  }

  toggleLoadingIndicator = (show = false) => {
    if (!show) {
      return
    }
    return <MaterialIndicator color="rgba(132,209,192,1)" />
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPWScreenEnterPhoneNumber)
