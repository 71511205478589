import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'

const ButtonDoneView = ({ colorSettings, title, onPress, disable }) => (
    <View style={styles.container}>
      <TouchableOpacity
        disabled={disable}
        style={[
          styles.nextButton,
          {
            opacity: disable ? 0.5 : 1,
            backgroundColor: colorSettings?.bottom_next_btn_enabled || 'white',
            borderColor: colorSettings?.bottom_next_btn_enabled || 'black',
          },
        ]}
        onPress={() => onPress()}
      >
        <Text
          style={[
            styles.nextButtonText,
            {
              color: colorSettings?.bottom_next_btn_text || 'black',
            },
          ]}
        >
          {title}
        </Text>
      </TouchableOpacity>
    </View>
  )

const styles = StyleSheet.create({
  container: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  nextButton: {
    justifyContent: 'center',
    // backgroundColor: "rgba(132,209,192,1.0)",
    height: '100%',
    width: '100%',
    borderWidth: 1,
  },
})

export default ButtonDoneView
