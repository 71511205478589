import { StyleSheet } from 'react-native'
import { IS_WEB } from '$constants/Platforms.js'

const black = 'black'
const blue = 'blue'

const styles = StyleSheet.create({
  container: {
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    width: IS_WEB() ? 'inherit' : 'auto',
  },
  appInfoLeftContent: {
    flex: 0.5,
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  appInfoRightContent: {
    flex: 0.5,
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  appLanguage: {
    flex: 1.0,
    marginLeft: 8,
    height: '100%',
    justifyContent: 'flex-end',
  },
  languageTitle: {
    color: black,
    fontSize: 10,
    textAlign: 'left',
  },
  appInfoLanguage: {
    fontSize: 10,
    textAlign: 'left',
    color: blue,
  },
  appInfoVersion: {
    fontSize: 10,
    textAlign: 'right',
    color: blue,
  },
})

export default styles
