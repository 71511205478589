import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
    minWidth: 180,
  },
  buttonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 22,
    marginHorizontal: 18,
    color: colorSettings?.selectable_btn_text_active_1 || 'black',
  }),
  textContainer: {
    flex: 1,
  },
  emptyContainer: {
    flex: 1,
  },
})

export default styles
