import { Component } from 'react'
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import CollapseView from 'react-native-collapse-view'
import { i18n } from '$localization/config.js'

class CancerRiskCollapseView extends Component {
  riskFactors = this.props.data || []

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      collapse: true,
      riskFactors: this.riskFactors,
    }
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const newState = prevState
    newState.riskFactors = newProps.data
    return newState
  }

  componentDidUpdate() {
    this.riskFactors = this.state.riskFactors
  }

  renderCollapsibleView = (collapse) => {
    if (!collapse) {
      return null
    }

    const RiskInfo = () => (
      <TouchableOpacity
        style={{
          // backgroundColor: "rgb(48,190,107)",
          position: 'absolute',
          width: 50,
          height: '100%',
          right: 0,
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        }}
        onPress={this.props.navigateToRiskInfo}
      >
        <Image
          style={{ width: 23, height: 23 }}
          source={require('$assets/images/new_assets/icon-info-teal.png')}
        />
      </TouchableOpacity>
    )

    const RiskLevelTitle = () => {
      if (!this.riskFactors || this.riskFactors.length === 0) {
        return (
          <View
            style={[
              styles.collapseViewSection,
              {
                flexDirection: 'row',
              },
            ]}
          >
            <View
              style={{
                borderBottomColor: 'rgb(48,190,107)',
                borderBottomWidth: 4.0,
                alignSelf: 'flex-start',
              }}
            >
              <Text
                style={{
                  color: 'rgb(48,190,107)',
                  fontFamily: 'montserrat-semibold',
                  fontSize: 21.0,
                  textAlign: 'left',
                  paddingBottom: 2,
                }}
              >
                {i18n.t('average_risk').default}
              </Text>
            </View>

            <RiskInfo />
          </View>
        )
      }
      return (
        <View
          style={[
            styles.collapseViewSection,
            {
              flexDirection: 'row',
            },
          ]}
        >
          <View
            style={{
              borderBottomColor: 'rgb(243,156,18)',
              borderBottomWidth: 4.0,
              alignSelf: 'flex-start',
            }}
          >
            <Text
              style={{
                color: 'rgb(243,156,18)',
                fontFamily: 'montserrat-semibold',
                fontSize: 21.0,
                textAlign: 'left',
                paddingBottom: 2,
              }}
            >
              {i18n.t('increased_risk').default}
            </Text>
          </View>

          <RiskInfo />
        </View>
      )
    }

    const RiskLevelMessage = () => {
      let message = ''

      if (!this.riskFactors || this.riskFactors.length === 0) {
        // message = i18n.t('based_on_the_information_you_provided_you_do_not_appear_to_be_at_increased_risk_for_a_hereditary_cancer_syndrome').default
        message = i18n.t(
          'it_does_not_appear_that_you_have_entered_a_family_history_that_needs_further_evaluation_but_you_should_always_seek_advice_from_a_doctor_or_genetic_counselor_if_there_is_something_you_are_concerned_about'
        ).default
      } else {
        // message = i18n.t('based_on_the_information_you_provided_you_may_be_at_increased_risk_for_a_hereditary_cancer_syndrome').default
        message = i18n.t(
          'based_on_the_information_you_provided_you_may_want_to_consider_discussing_your_family_health_history_with_a_doctor_or_genetic_counselor'
        ).default
      }

      return (
        <View style={[styles.collapseViewSection, { flexDirection: 'column' }]}>
          <Text
            style={{
              color: this.colorSettings?.member_tile_title,
              fontFamily: 'montserrat',
              fontSize: 16.0,
              lineHeight: 24,
            }}
          >
            {message}
          </Text>
        </View>
      )
    }

    const RiskFactors = () => {
      const { riskFactors } = this
      const layout = []

      let textColor = 'rgb(243,156,18)'
      if (!riskFactors || riskFactors.length === 0) {
        textColor = 'rgb(48,190,107)'
      }

      layout.push(
        <View style={styles.collapseViewSection}>
          <Text
            style={{
              color: textColor,
              fontFamily: 'montserrat',
              fontSize: 13,
              textAlign: 'left',
              alignSelf: 'flex-start',
            }}
          >
            {`${i18n.t('risk_factors').default}:`}
          </Text>
        </View>
      )

      // If Average Risk
      if (!riskFactors || riskFactors.length === 0) {
        const message = i18n.t(
          'risk_of_cancer_can_be_influenced_by_the_cancer_in_your_family_and_your_own_medical_history_adding_new_information_can_change_your_risk_so_be_sure_to_keep_your_information_up_to_date_and_check_back_often'
        ).default
        layout.push(
          <View style={[styles.collapseViewSection, { flexDirection: 'row' }]}>
            <View style={styles.contentBulletPointsContainer}>
              <View style={styles.contentBulletPoints} />
            </View>
            <Text
              style={[
                styles.contentBulletedText,
                { color: this.colorSettings?.member_tile_title },
              ]}
            >
              {message}
            </Text>
          </View>
        )
      }
      // If Increased Risk
      else {
        for (let i = 0; i < riskFactors.length; i++) {
          const riskCriteria = riskFactors[i]
          layout.push(
            <View
              style={[styles.collapseViewSection, { flexDirection: 'row' }]}
            >
              <View style={styles.contentBulletPointsContainer}>
                <View style={styles.contentBulletPoints} />
              </View>
              <Text
                style={[
                  styles.contentBulletedText,
                  { color: this.colorSettings?.member_tile_title },
                ]}
              >
                {i18n.t(riskCriteria.riskFactorCode).default}
              </Text>
            </View>
          )
        }

        layout.push(
          <View
            style={[styles.collapseViewSection, { alignItems: 'flex-start' }]}
          >
            <Text
              style={{
                color: 'rgb(74,74,74)',
                fontFamily: 'montserrat',
                fontSize: 14,
                textAlign: 'left',
              }}
            >
              {
                i18n.t(
                  'you_should_discuss_this_with_a_genetic_counselor_or_healthcare_provider'
                ).default
              }
            </Text>
          </View>
        )
      }

      return layout
    }

    return (
      <View style={styles.collapseViewContainer}>
        <View style={{ marginVertical: 20 }}>
          <RiskLevelTitle />
          <RiskLevelMessage />
          <RiskFactors />

          <TouchableOpacity
            style={[styles.collapseViewSection, { alignItems: 'flex-start' }]}
            onPress={this.props.navigateToGeneticResources}
          >
            <View
              style={[
                styles.footerButton,
                {
                  backgroundColor: this.colorSettings?.selectable_btn_active_3,
                  borderColor: this.colorSettings?.selectable_btn_active_3,
                  width: Platform.OS === 'web' ? 300 : '100%',
                },
              ]}
              nativeID="risk_genetic_resources_button"
            >
              <Text
                style={[
                  styles.footerButtonText,
                  {
                    color: this.colorSettings?.selectable_btn_text_active_3,
                  },
                ]}
              >
                {i18n.t('genetic_resources').default}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  render() {
    const { collapse } = this.state
    let riskIconImage = null

    if (!this.riskFactors || this.riskFactors.length === 0) {
      riskIconImage = require('$assets/images/new_assets/icon-risk-ok.png')
    } else {
      riskIconImage = require('$assets/images/icon/risk-icon/risk.png')
    }

    return (
      <View>
        <TouchableOpacity
          activeOpacity={1.0}
          onPress={() => this.setState({ collapse: !collapse })}
        >
          <View
            style={[
              styles.riskButtonContainer,
              {
                height: 80,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomLeftRadius: !collapse ? 10 : 0,
                borderBottomRightRadius: !collapse ? 10 : 0,
                borderWidth: 1.0,
              },
            ]}
          >
            <View
              style={[
                styles.riskButtonImageOuterContainer,
                { flex: Platform.OS === 'web' ? 0.18 : 0.25 },
              ]}
            >
              <View style={styles.riskButtonImageInnerContainer}>
                <Image
                  style={{ width: 40, height: 40, resizeMode: 'contain' }}
                  source={riskIconImage}
                />
              </View>
            </View>

            <View style={styles.riskButtonTextOuterContainer}>
              <Text style={styles.riskButtonText}>
                {i18n.t('cancer_risk').default}
              </Text>
            </View>

            <View style={{ flex: 0.1, justifyContent: 'center' }}>
              <Image
                source={require('$assets/images/new_assets/icon-dash-arrow.png')}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: 'contain',
                  alignSelf: 'flex-start',
                  transform: collapse
                    ? [{ rotate: '90deg' }]
                    : [{ rotate: '0deg' }],
                }}
              />
            </View>
          </View>
        </TouchableOpacity>

        {this.renderCollapsibleView(collapse)}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  collapseViewContainer: {
    backgroundColor: 'white',
    borderColor: 'rgba(200,200,200,0.5)',
    borderWidth: 1.0,
    justifyContent: 'center',
    flex: 1.0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  collapseViewSection: {
    // backgroundColor: "white",
    alignItems: 'center',
    // justifyContent: "center",
    marginHorizontal: 16.0,
    marginVertical: 10,
  },
  itemContainerOrange: {
    backgroundColor: 'rgb(242,120,59)',
    justifyContent: 'center',
    height: 40,
    width: 160,
    borderRadius: 20,
    marginVertical: 5,
  },

  riskButtonContainer: {
    backgroundColor: 'white',
    borderColor: 'rgba(200,200,200,0.5)',
    flexDirection: 'row',
    marginTop: 12,
  },
  riskButtonImageOuterContainer: {
    flex: 0.25,
    alignItems: 'center',
    flexDirection: 'row',
  },
  riskButtonImageInnerContainer: {
    flex: 1,
    alignItems: 'center',
  },
  riskButtonTextOuterContainer: {
    flex: 0.75,
    flexDirection: 'row',
    alignItems: 'center',
  },
  riskButtonText: {
    flex: 1.0,
    fontFamily: 'karla-bold',
    color: 'rgb(65,70,97)',
    fontSize: 18,
  },
  footerButton: {
    height: 50,
    borderWidth: 0.5,
    backgroundColor: 'rgb(65,70,97)',
    borderRadius: 37.5,
    justifyContent: 'center',
  },
  footerButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
  contentBulletPointsContainer: {
    flex: 1,
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingTop: 5,
  },
  contentBulletPoints: {
    height: 8,
    width: 8,
    backgroundColor: 'rgb(102,102,102)',
  },
  contentBulletedText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
    alignSelf: 'flex-start',
    flex: 8.2,
  },
})

export default CancerRiskCollapseView
