import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native'

const EllipseButton = (props) => {
  let button_height_style = { height: 60.0 }
  if (Platform.OS === 'web') {
    button_height_style = { minHeight: 60.0 }
  }

  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.roundedButtonContainer,
        props.buttonStyle,
        button_height_style,
      ]}
      activeOpacity={1.0}
    >
      <View style={[styles.roundedButtonTitleTextContainer, props.innerStyle]}>
        {props.children}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  roundedButtonContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    borderRadius: 37.5,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
  },
  roundedButtonTitleTextContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default EllipseButton
