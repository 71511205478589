import MemberProfile from '../../../../../data_models/MemberProfile'
import { showDefaultAlert } from '../../../../../navigation/_utils/NavigationUtils'
import { i18n } from '$localization/config.js'

const createAndNavigateToCreatedUser = (
  member,
  saveShouldReloadFamilyList,
  saveCurrentMemberToStore,
  saveInvitedMember,
  navigation,
  route,
  saveDidAddNewMember,
  saveAddNewMemberPayload
) => {
  if (!member?.member_id) return

  const currentMember = new MemberProfile().constructBasicProfile({
    ...member,
  })

  saveShouldReloadFamilyList(true)
  saveCurrentMemberToStore(currentMember)
  saveInvitedMember(member)

  if (member?.is_deleted) {
    return showDefaultAlert(
      i18n.t('oops')?.default,
      i18n.t('sorry_this_person_might_have_already_been_deleted')?.default
    )
  }

  if (!currentMember?.member_id) return

  const routes = route?.params?.isComingFromFamilyMembersNavigator
    ? 'EditProfileCancer'
    : 'EditProfileView'

  saveDidAddNewMember(false)
  saveAddNewMemberPayload([])

  return navigation.navigate(routes, {
    isFamilyScreen: true,
    isComingFromFamilyMembersNavigator:
      route?.params?.isComingFromFamilyMembersNavigator,
  })
}

export default createAndNavigateToCreatedUser
