import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  content: {
    width: '100%',
    paddingHorizontal: 10,
  },
  divider: {
    height: 20,
    width: '100%',
  },
  genderIdentityOptionContainer: {
    height: 120,
  },
  genderPronounDivider: {
    height: 8,
    width: '100%',
  },
  pronoundOption: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginBottom: 55,
  },
})

export default styles
