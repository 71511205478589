import { useState } from 'react'
import { TextInput, View } from 'react-native'
import PropTypes from 'prop-types'

import styles from './styles'
import { i18n } from '../../../../../localization/config.js'
import { LabelHandler } from '../../../helpers'

export default function TextShort({
  colorSettings,
  masterQuestion,
  answer,
  saveCompletedSurveyAnswer,
}) {
  const [inputData, setInputData] = useState(answer?.answer || '')
  const { place_holder_text: placeHolderText, question_label: questionLabel } =
    masterQuestion

  const saveOnBlur = (value) => {
    const payload = {
      master_question_id: masterQuestion.id,
      master_question_choice_id: null,
      answer: value.nativeEvent.text,
    }

    saveCompletedSurveyAnswer(payload)
  }

  return (
    <View style={styles.mainContainer}>
      {LabelHandler({
        questionLabel: i18n.t(questionLabel).default || questionLabel,
        colorSettings,
        masterQuestion,
      })}
      <TextInput
        nativeID="web_text_input"
        onChangeText={(item) => setInputData(item)}
        value={inputData}
        placeholder={placeHolderText}
        placeholderTextColor={
          colorSettings?.text_input_placeholder_color_2 ||
          'rgba(74, 74, 74, 0.5)'
        }
        style={[
          styles.inputField,
          {
            borderBottomColor: colorSettings?.btn_no_fill_border_2 || 'black',
            color: colorSettings?.text_input_color_2 || 'black',
          },
        ]}
        returnKeyType="done"
        onBlur={saveOnBlur}
      />
    </View>
  )
}

TextShort.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.objectOf(PropTypes.string),
  answer: PropTypes.number,
  saveCompletedSurveyAnswer: PropTypes.func,
}

TextShort.defaultProps = {
  colorSettings: [],
  answer: '',
  saveCompletedSurveyAnswer: () => {},
}
