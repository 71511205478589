import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  StatusBar,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import { MaterialIndicator } from 'react-native-indicators'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { setError } from '$redux/defaults/actions.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import custom_styles from './styles/main.styles.js'
import { WEB } from '$constants/Platforms.js'
import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { getLang } from '$utils/lang_utils.js'

const inputAccessoryViewID = 'ForgotPWScreenEnterEmail'

class ForgotPWScreenEnterEmail extends Component {
  email = ''

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)
    this.state = {
      showLoadingIndicator: false,
      email: '',
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  nextButtonAction = async () => {
    if (!this.validateInput()) {
      return
    }
    this.setState({ showLoadingIndicator: true })
    await this.requestVerificationCode()
  }

  requestVerificationCode = async (shouldNext = true) => {
    /* Prepare API request for verification code */
    const payload = {
      dialing_code: null,
      phone_number: null,
      email: this.state.email,
      type: 1,
      mode: 'password_reset',
      lang: getLang(),
    }

    const verificationCodePayload = {
      path: 'account/new_verification_code/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'ForgotPWScreenEnterEmail.js',
      },
    }

    const response = await ApiFetchHandler(verificationCodePayload)
    const { saveError, navigation } = this.props

    if (response.isError) {
      this.setState({ showLoadingIndicator: false })

      switch (response.status) {
        case 404:
          showDefaultAlert(
            i18n.t('email_not_found').default,
            i18n.t('email_not_found_message').default
          )
          break
        default:
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          break
      }
      return
    }

    this.setState({ showLoadingIndicator: false })

    if (response.status_code !== 200) {
      return
    }

    const data = response
    const accountID = data.account_id
    const { email } = data
    const phoneNumberWithPrefix = `+ ${this.state.phonePrefix}${this.phoneNumber}`

    if (shouldNext) {
      navigation.push('ForgotPWEnterCode', {
        accountID,
        email,
        phoneNumber: phoneNumberWithPrefix,
        type: payload.type,
        resendCodeAction: () =>
          this.requestVerificationCode((shouldNext = false)),
      })
    }
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    let style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled,
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }

    const isValid = this.validateInput()
    if (isValid) {
      style = {
        backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return style
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const enabled = this.validateInput()
    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  validateInput = () => {
    let emailIsValid = false

    // Validate email
    const validator = require('email-validator')
    if (validator.validate(this.state.email)) {
      emailIsValid = true
    }

    if (emailIsValid) {
      return true
    }
    return false
  }

  onChangeText = (value) => {
    this.setState({ email: value })
  }

  toggleLoadingIndicator = (show = false) => {
    if (!show) {
      return
    }
    return <MaterialIndicator color="rgba(132,209,192,1)" />
  }

  render() {
    let enableKeyboardAvoiding = false
    if (Platform.OS === 'ios') {
      enableKeyboardAvoiding = true
    }

    return (
      <ErrorBoundary>
        <View
          style={[
            custom_styles.container,
            { backgroundColor: this.colorSettings?.splash_bgcolor },
          ]}
        >
          {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}

          <KeyboardAvoidingView
            style={{ flex: 1.0 }}
            contentContainerStyle={custom_styles.contentContainer}
            behavior="position"
            enabled={enableKeyboardAvoiding}
          >
            <ScrollView
              // nativeID="web_scaled_main_container"
              style={{ height: this.state.scrollViewHeight }}
              contentContainerStyle={[
                custom_styles.contentContainer,
                { marginHorizontal: 32 },
              ]}
            >
              {/* <Content> */}
              <View style={custom_styles.content}>
                <View
                  style={{
                    flex: 0.3,
                    ...Platform.select({
                      web: {
                        width: '30%',
                        minWidth: 370,
                      },
                      default: {
                        width: '100%',
                      },
                    }),
                    backgroundColor: 'transparent',
                  }}
                >
                  <View style={{ flex: 1.0 }}>
                    {/* <Title Text> */}
                    <View
                      style={{
                        flex: 0.3,
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={[
                          custom_styles.titleText,
                          {
                            color: this.colorSettings?.text_input_title_color_1,
                          },
                        ]}
                      >
                        {i18n.t('enter_your_email_address').default}
                      </Text>
                    </View>
                    {/* </Title Text> */}

                    <View style={{ flex: 0.1 }} />

                    {/* <Main Text Input> */}
                    <View
                      style={{
                        flex: 0.3,
                        justifyContent: 'center',
                      }}
                    >
                      <View
                        style={[
                          custom_styles.mainTextInputContainer,
                          { flex: 1.0, flexDirection: 'row' },
                        ]}
                      >
                        <View style={{ flex: 0.05, backgroundColor: '' }} />

                        <View
                          style={[
                            custom_styles.mainTextInputContainer,
                            { flex: 0.9, backgroundColor: '' },
                          ]}
                        >
                          <TextInput
                            nativeID="web_text_input"
                            style={[
                              custom_styles.mainTextInput,
                              {
                                color:
                                  this.colorSettings?.text_input_color_1 ||
                                  'rgba(74, 74, 74, 1)',
                              },
                            ]}
                            inputAccessoryViewID={inputAccessoryViewID}
                            autoCapitalize="none"
                            autoCorrect={false}
                            autoComplete="off"
                            selectionColor="black"
                            keyboardType="email-address"
                            placeholderTextColor={
                              this.colorSettings?.text_input_label_color_1 ||
                              'rgba(153, 153, 153, 1)'
                            }
                            placeholder={i18n.t('email').default}
                            clearButtonMode="while-editing"
                            onChangeText={this.onChangeText}
                            onSubmitEditing={this.nextButtonAction}
                          />
                        </View>

                        <View style={{ flex: 0.05, backgroundColor: '' }} />
                      </View>

                      <View style={{ height: 1.0 }}>
                        <View style={{ flex: 1.0, flexDirection: 'row' }}>
                          <View
                            style={{
                              flex: 0.05,
                              backgroundColor: 'transparent',
                            }}
                          />
                          <View
                            style={{
                              flex: 0.9,
                              backgroundColor:
                                this.colorSettings
                                  ?.text_input_placeholder_color_1 || 'black',
                            }}
                          />
                          <View
                            style={{
                              flex: 0.05,
                              backgroundColor: 'transparent',
                            }}
                          />
                        </View>
                      </View>
                    </View>
                    {/* </Main Text Input> */}

                    {/* <Helper Text Container> */}
                    <View style={{ flex: 0.3, justifyContent: 'center' }}>
                      <View style={{ flex: 1.0, flexDirection: 'row' }}>
                        <View
                          style={{ flex: 0.05, backgroundColor: 'transparent' }}
                        />
                        <View
                          style={[
                            custom_styles.helperTextContainer,
                            { flex: 0.9 },
                          ]}
                        >
                          <Text
                            style={[
                              custom_styles.helperText,
                              {
                                color:
                                  this.colorSettings?.text_input_label_color_1,
                              },
                            ]}
                          >
                            {
                              i18n.t(
                                'we_just_need_your_registered_email_address_to_send_you_the_6-digit_code'
                              ).default
                            }
                          </Text>
                        </View>
                        <View
                          style={{ flex: 0.05, backgroundColor: 'transparent' }}
                        />
                      </View>
                    </View>
                    {/* </Helper Text Container> */}
                  </View>
                </View>
              </View>
              {/* </Content> */}

              {/* <Loading Indicator> */}
              <View
                style={{
                  position: 'absolute',
                  bottom: 32,
                  height: 60,
                  width: '100%',
                }}
              >
                {this.toggleLoadingIndicator(this.state.showLoadingIndicator)}
              </View>
              {/* </Loading Indicator> */}
            </ScrollView>
          </KeyboardAvoidingView>

          {/* <Next button> */}
          <View style={custom_styles.nextButtonContainer}>
            <TouchableOpacity
              disabled={this.toggleNextButton()}
              style={[
                custom_styles.nextButton,
                this.toggleNextButtonColor(),
                {
                  backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  custom_styles.nextButtonText,
                  {
                    color: this.toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('next').default}
              </Text>
            </TouchableOpacity>
          </View>

          <NextButtonAccessoryView
            nativeID={inputAccessoryViewID}
            backgroundColor="white"
            labelText={i18n.t('next').default}
            labelColor={this.toggleNextButtonText()}
            disabled={this.toggleNextButton()}
            style={[
              custom_styles.nextButton,
              this.toggleNextButtonColor(),
              {
                backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            onPress={this.nextButtonAction}
          />
          {/* </Next button> */}
        </View>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(ForgotPWScreenEnterEmail)
