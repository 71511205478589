import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  Platform,
} from 'react-native'

import { capitalizeFirstLetter } from '$api/_utils/Utils.js'

import { i18n } from '$localization/config.js'

export default class ToggleButtonWeb extends Component {
  parent = this.props.parent

  buttonClass = this.props.buttonClass

  buttonStyle = this.props.buttonStyle

  colorSettings = this.props.colorSettings

  listData = [
    {
      id: 1,
      name: i18n.t('yes').default,
    },
    {
      id: 0,
      name: i18n.t('no').default,
    },
  ]

  constructor(props) {
    super(props)

    // Initialize component state.
    this.state = {
      selectedOption: null,
      refresh: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      this.parent.toggleButton(this.buttonClass, this.state.selectedOption.id)
    }
  }

  onPressItem = (item) => {
    this.setState({
      selectedOption: item,
      refresh: !this.state.refresh,
    })
  }

  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={{ flex: 1.0, marginTop: 30 }}>
          <FlatList
            data={this.listData}
            extraData={this.state.refresh}
            keyExtractor={(item) => `${item?.id}`}
            numColumns={2}
            renderItem={({ item, index, section }) => {
              const selected = item === this.state.selectedOption
              const optionName = item.name

              const style = selected
                ? {
                    backgroundColor:
                      this.colorSettings?.selectable_btn_active_1 ||
                      'rgba(0, 0, 0, 0.2)',
                    borderColor:
                      this.colorSettings?.selectable_btn_border_active_1,
                  }
                : {
                    backgroundColor:
                      this.colorSettings?.selectable_btn_inactive_1,
                    borderColor:
                      this.colorSettings?.selectable_btn_border_inactive_1,
                  }

              // Render list item.
              return (
                <View style={[styles.sectionItemContainer]}>
                  <TouchableOpacity
                    style={[
                      style,
                      this.buttonStyle,
                      {
                        justifyContent: 'center',
                        borderRadius: 30,
                        borderWidth: 2,
                        height: 60,
                        marginVertical: 30,
                        marginHorizontal: 6,
                        // paddingHorizontal: 50,
                        flex: 1,
                      },
                    ]}
                    activeOpacity={1.0}
                    onPress={() => this.onPressItem(item)}
                  >
                    <Text style={styles.sectionItemLabelText}>
                      {optionName}
                    </Text>
                  </TouchableOpacity>
                </View>
              )
            }}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    alignSelf: 'center',
    ...Platform.select({
      web: {
        width: '23%',
        minWidth: 350,
      },
      default: {
        width: '100%',
      },
    }),
  },
  sectionItemContainer: {
    // width: "100%",
    // height: 65,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 0,
    flex: 1,
  },
  sectionItemLabelText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})
