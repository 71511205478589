import { createRef, Component } from 'react'
import {
  Image,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { connect } from 'react-redux'

import { StackActions } from '@react-navigation/compat'

import {
  setDiseaseProband,
  setFromOnboardingDiseases,
} from 'redux/diseases/actions.js'
import {
  clearProbandDisease,
  clearProbandSkipLogic,
} from 'redux/proband/actions.js'
import Icon from 'react-native-vector-icons/Feather'
import { i18n } from '$localization/config.js'

import UpdateMemberProfile from '$screens/_utils/UpdateMemberProfile.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

// utils
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

import { setProbandBiologicalGender } from '$redux/proband/actions'

import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'
import custom_styles from './styles/styles.main.js'
import secondary_styles from './styles/styles.secondary'

import { WEB } from '$constants/Platforms.js'

// redux actions
import { setFromOnboardingGeneTest } from '$redux/gene_testing/actions.js'
import { setProbandProfileAction } from '$redux/proband/actions.js'
import PersonalGenderIdentity from '../gender_identity/PersonalGenderIdentity'
import { setError } from '$redux/defaults/actions.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

// const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.PersonalGender?.name

class PersonalGender extends Component {
  colorSettings = this.props.colorSettings

  selectedClinician = this.props.selectedClinician

  constructor(props) {
    super(props)

    this.otherGenderIdentity = createRef()
    this.state = {
      selectedGender: null,
      scrollViewHeight: DefaultFullHeight(),
      genderIdentity: '',
      pronoun: '',
      other_pronoun: '',
    }
  }

  componentDidMount() {
    const { navigation, probandProfile, authCredentials, account, saveError } =
      this.props

    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    const navEvent = navigation.addListener('focus', () => {
      const screen = {
        last_screen: RAW_LAST_VISITED_SCREENS.gender_selection,
      }

      saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })
    })

    if (probandProfile) {
      const { biologicalGender } = probandProfile?.profileData

      if (biologicalGender) {
        this.setState({ selectedGender: biologicalGender })
      }
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const gender = this.state.selectedGender
    if (gender) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const nextButtonEnabled = this.state.selectedGender
    const gender = this.state.selectedGender

    if (gender) {
      return {
        backgroundColor: this.colorSettings?.bottom_next_btn_enabled || 'white',
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled || 'white',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const gender = this.state.selectedGender
    if (gender) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  toggleNextButtonTextColor = () => {
    const nextButtonEnabled = this.state.selectedGender
    const gender = this.state.selectedGender

    if (gender) {
      return {
        color: this.colorSettings?.selectable_btn_text_active_1 || 'black',
      }
    }
    return {
      color: this.colorSettings?.selectable_btn_active_1 || 'black',
    }
  }

  toggleGenderButton = (isSelected = false) => {
    if (isSelected) {
      return {
        backgroundColor:
          this.colorSettings?.selectable_btn_active_1 ||
          'rgba(74, 74, 74, 0.1)',
        borderColor:
          this.colorSettings?.selectable_btn_border_active_1 || 'black',
        flex: 0.5,
      }
    }
    return {
      backgroundColor:
        this.colorSettings?.selectable_btn_inactive_1 || 'transparent',
      borderColor:
        this.colorSettings?.selectable_btn_border_inactive_1 || 'black',
      flex: 0.5,
    }
  }

  didSelectGender = (gender) => {
    const selectedGender = gender.toLowerCase()
    this.setState({ selectedGender })
    // DONE: create an action creator for this
    this.props._saveProbandBiologicalGender_(selectedGender)
  }

  tooltipButtonAction = () => {
    showDefaultAlert(
      i18n.t('biological_sex').default,
      i18n.t(
        'this_is_the_sex_you_were_assigned_at_birth_if_your_gender_identity_differs_from_your_biological_sex_you_can_indicate_that_using_the_link_below'
      ).default
    )
  }

  navigateToGenderIdentity = async () => {
    const { show_gender_identity = false } = this.selectedClinician || false

    if (!this.state.selectedGender) {
      // clear proband selected diseases

      showDefaultAlert(
        i18n.t('oops').default,
        i18n.t('please_select_your_biological_sex_first').default
      )
      return
    }

    const { account, authCredentials, navigation, saveError } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gender_selection,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation: this.props.navigation,
    })

    await this.updatePersonalInfo()
    await this.updateProbandProfileStorage()

    !show_gender_identity &&
      navigation.navigate('PersonalDetailsNavigator', {
        screen: 'PersonalGenderIdentity',
      })
  }

  getStackAction = () => {
    const pushAction = StackActions.push({
      routeName: 'PersonalIdentity',
      params: {
        cs: this.colorSettings,
      },
    })
    const { navigation } = this.props
    navigation.dispatch(pushAction)
  }

  nextButtonAction = async () => {
    if (!this.state.selectedGender) return

    const {
      account,
      navigation,
      authCredentials,
      saveError,
      _saveProbandDisease_,
      _clearProbandDisease_,
      _clearProbandSkipLogic_,
      _setFromOnboardingDiseases_,
      _setFromOnboardingGeneTest_,
    } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gender_selection,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    await this.updatePersonalInfo()
    await this.updateProbandProfileStorage()

    _saveProbandDisease_([])
    _clearProbandDisease_()
    _clearProbandSkipLogic_()
    _setFromOnboardingDiseases_(true)
    _setFromOnboardingGeneTest_(true)

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.DiseaseListMainScreen.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  getGenderIdentityInfo = (genderIdentity) => {
    this.setState({ genderIdentity })
  }

  getGenderPronounInfo = (pronoun) => {
    this.setState({ pronoun })
  }

  getGenderOtherPronounInfo = (value) => {
    this.setState({ other_pronoun: value })
  }

  updatePersonalInfo = async () => {
    const { proband, authCredentials, account, saveError } = this.props
    const { selectedGender, genderIdentity, pronoun, other_pronoun } =
      this.state

    const payload = {
      proband_id: proband.probandID,
      member_id: proband.probandID,
      member_info: {
        gender: account?.gender || selectedGender,
        gender_identity: account?.gender_identity || genderIdentity,
        pronoun: account?.pronoun || pronoun,
        other_pronoun,
      },
    }

    const updatePersonalInfoPayload = {
      path: 'member/update_personal_info/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'PersonalGender.js',
      },
    }

    const response = await ApiFetchHandler(updatePersonalInfoPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }
  }

  updateProbandProfileStorage = async () => {
    const { proband, _saveProbandProfileToStore_, authCredentials } = this.props

    const memberData = {
      proband_id: proband.probandID,
      member_id: proband.probandID,
    }

    try {
      const memberProfile = await UpdateMemberProfile(
        memberData,
        authCredentials
      )
      _saveProbandProfileToStore_(memberProfile)
    } catch (err) {
      console.log(
        '🚀 \n\n file: PersonalGender.js:344 \n\n PersonalGender \n\n updateProbandProfileStorage= \n\n err:',
        err
      )
    }
  }

  renderDifferentGenderIdentityButton = () => (
    <View
      style={[
        custom_styles.content,
        {
          flex: 0.15,
          width: '100%',
        },
      ]}
    >
      <TouchableOpacity onPress={this.navigateToGenderIdentity}>
        <Text
          style={[
            custom_styles.linkedText,
            {
              color: this.colorSettings?.text_input_label_color_2 || 'black',
            },
          ]}
        >
          {i18n.t('have_a_different_gender_identity').default}
        </Text>
      </TouchableOpacity>
    </View>
  )

  render() {
    // TODO: Use this customize the top, middle or bottom screen text coming from Django
    // const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

    const { show_gender_identity = false } = this.selectedClinician || false
    const { navigation } = this.props

    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            custom_styles.container,
            {
              backgroundColor:
                this.colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          <KeyboardAvoidingView
            style={[
              secondary_styles.keyboardAvoidingView,
              { height: this.state.scrollViewHeight },
            ]}
            contentContainerStyle={
              secondary_styles.keyboardAvoidingViewContainer
            }
            behavior="padding"
            keyboardVerticalOffset={0}
            enabled
          >
            <ScrollView>
              <View style={secondary_styles.spacer} />

              {/* <Content Body> */}
              <View
                style={[
                  custom_styles.content,
                  secondary_styles.outerContentContainer,
                ]}
              >
                <View
                  style={[
                    custom_styles.content,
                    secondary_styles.innerContentContainer,
                  ]}
                >
                  {/* <Title question> */}
                  <View
                    style={[
                      custom_styles.content,
                      secondary_styles.outerTitleQuestion,
                    ]}
                  >
                    <View style={secondary_styles.innerTitleQuestion}>
                      <TouchableOpacity
                        style={secondary_styles.genderButton}
                        onPress={this.tooltipButtonAction}
                      >
                        <Image
                          style={secondary_styles.genderImage}
                          // source={require("$assets/images/icon/information/white.png")}
                        />
                      </TouchableOpacity>

                      <View style={secondary_styles.titleIconSpacer} />

                      <Text
                        style={[
                          custom_styles.titleText,
                          secondary_styles.text,
                          {
                            color:
                              this.colorSettings?.text_input_title_color_2 ||
                              'black',
                          },
                        ]}
                      >
                        {i18n.t('whats_your_biological_sex').default}
                      </Text>

                      <View style={secondary_styles.titleIconTextSpacer} />

                      <TouchableOpacity
                        style={secondary_styles.genderButton}
                        onPress={this.tooltipButtonAction}
                      >
                        <Icon
                          name="info"
                          size={20}
                          color={
                            this.colorSettings?.text_input_title_color_2 ||
                            'black'
                          }
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  {/* </Title question> */}

                  <View style={secondary_styles.titleSpacer} />

                  <View
                    style={[
                      custom_styles.content,
                      secondary_styles.genderContent,
                    ]}
                  >
                    <View
                      style={[
                        custom_styles.horizontalContent,
                        secondary_styles.innerGenderContent,
                      ]}
                    >
                      <TouchableOpacity
                        nativeID="signup_gender_selection_tile"
                        style={[
                          custom_styles.roundedTileButton,
                          this.toggleGenderButton(
                            this.state.selectedGender === 'm'
                          ),
                        ]}
                        activeOpacity={1.0}
                        onPress={() => this.didSelectGender('m')}
                      >
                        <View
                          style={[
                            custom_styles.content,
                            secondary_styles.genderContentContainer,
                          ]}
                        >
                          <View
                            style={[
                              custom_styles.maleIcon,
                              {
                                backgroundColor:
                                  this.colorSettings
                                    ?.selectable_btn_text_active_1 || 'black',
                              },
                            ]}
                          />
                          <Text
                            style={[
                              custom_styles.roundedTileButtonText,
                              {
                                color:
                                  this.colorSettings
                                    ?.selectable_btn_text_active_1 || 'black',
                              },
                            ]}
                          >
                            {i18n.t('male').default}
                          </Text>
                        </View>
                      </TouchableOpacity>

                      <View style={secondary_styles.genderDivider} />

                      <TouchableOpacity
                        nativeID="signup_gender_selection_tile"
                        style={[
                          custom_styles.roundedTileButton,
                          this.toggleGenderButton(
                            this.state.selectedGender === 'f'
                          ),
                        ]}
                        activeOpacity={1.0}
                        onPress={() => this.didSelectGender('f')}
                      >
                        <View
                          style={[
                            custom_styles.content,
                            secondary_styles.genderContentContainer,
                          ]}
                        >
                          <View
                            style={[
                              custom_styles.femaleIcon,
                              {
                                backgroundColor:
                                  this.colorSettings
                                    ?.selectable_btn_text_active_1 || 'black',
                              },
                            ]}
                          />
                          <Text
                            style={[
                              custom_styles.roundedTileButtonText,
                              {
                                color:
                                  this.colorSettings
                                    ?.selectable_btn_text_active_1 || 'black',
                              },
                            ]}
                          >
                            {i18n.t('female').default}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>

                  <View style={secondary_styles.genderIdentityDivider} />

                  {show_gender_identity === false &&
                    this.renderDifferentGenderIdentityButton()}
                </View>
                {show_gender_identity && (
                  <Text
                    style={[
                      custom_styles.titleText,
                      secondary_styles.genderIdentityText,
                      {
                        color:
                          this.colorSettings?.text_input_title_color_2 ||
                          'black',
                      },
                    ]}
                  >
                    {i18n.t('optional').default}
                  </Text>
                )}
              </View>
              {/* <View style={{ flex: 1 }}> */}
              {show_gender_identity && (
                <PersonalGenderIdentity
                  navigation={navigation}
                  getGenderIdentityInfo={this.getGenderIdentityInfo}
                  getGenderPronounInfo={this.getGenderPronounInfo}
                  getGenderOtherPronounInfo={this.getGenderOtherPronounInfo}
                />
              )}
              {/* </View> */}

              {/* </Content Body> */}
            </ScrollView>
          </KeyboardAvoidingView>
          {/* <Next button> */}
          <View style={global_styles.nextButtonContainer}>
            <TouchableOpacity
              style={[global_styles.nextButton, this.toggleNextButtonColor()]}
              disabled={this.toggleNextButton()}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('next').default}
              </Text>
            </TouchableOpacity>
          </View>
          {/* </Next button> */}
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

// <Redux functions>
function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, diseaseStore, clinicianStore } =
    state
  return {
    colorSettings: store.colorSettings,
    account: accountStore.account,
    proband: probandStore.proband,
    selectedClinician: clinicianStore.selectedClinician,
    probandProfile: probandStore.probandProfile,
    authCredentials: accountStore.authCredentials,
    diseaseFromOnboarding: diseaseStore.fromOnboarding,
  }
}

const actionCreators = {
  _saveProbandBiologicalGender_: setProbandBiologicalGender,
  _saveProbandDisease_: setDiseaseProband,
  _clearProbandDisease_: clearProbandDisease,
  _clearProbandSkipLogic_: clearProbandSkipLogic,
  _setFromOnboardingDiseases_: setFromOnboardingDiseases,
  _setFromOnboardingGeneTest_: setFromOnboardingGeneTest,
  _saveProbandProfileToStore_: setProbandProfileAction,
  saveError: setError,
}

export default connect(mapGlobalStateToProps, actionCreators)(PersonalGender)
