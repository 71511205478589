import React, { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  Modal,
  FlatList,
  SafeAreaView,
  TextInput,
  Platform,
} from 'react-native'

import convertText from '$screens/_utils/TextConverterForTranslation.js'

import LoadingView from '$constants/LoadingView.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { i18n } from '$localization/config.js'
import { sortAncestryData } from '../../helpers'

export default class AncestrySearchModal extends Component {
  selectedAncestry = []

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      showLoadingView: true,
      ancestryList: [],
      selected: [],
      error: null,
      searchInput: '',
    }
  }

  async componentDidMount() {
    const ancestries = await this.getAncestryList()

    const translatedAncestry = ancestries.map((ancestryItem) => {
      const {
        display_name: displayName,
        id,
        ancestry_id: ancestryID,
      } = ancestryItem

      let modifiedAncestryItem

      if (!ancestryID) {
        modifiedAncestryItem = {
          ...ancestryItem,
          ancestry_id: id,
          display_name: i18n.t(convertText(displayName)).default || displayName,
        }
      } else {
        modifiedAncestryItem = {
          ...ancestryItem,
          display_name: i18n.t(convertText(displayName)).default || displayName,
        }
      }
      delete modifiedAncestryItem.id
      return modifiedAncestryItem
    })

    const sortedAncestry = sortAncestryData(translatedAncestry)

    this.selectedAncestry = sortedAncestry
    this.setState({ ancestryList: sortedAncestry })
  }

  getAncestryList = async () => {
    const { authCredentials, saveError, probandProfile } = this.props

    const getAncestryPayload = {
      path: `ancestry/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'AncestrySearchModal.js',
      },
    }

    const response = await ApiFetchHandler(getAncestryPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return []
    }

    return response
  }

  setSelectedAncestry(key) {
    const tempSelected = this.state.ancestryList.findIndex(
      (item) => item.value === key
    )
    if ('selected' in this.state.ancestryList[tempSelected]) {
      delete this.state.ancestryList[tempSelected].selected
    } else {
      this.state.ancestryList[tempSelected].selected = true
    }

    this.setState({ ancestryList: this.state.ancestryList })
  }

  cancelAction() {
    const { parentController, showModal } = this.props

    parentController?.setModalVisible(!showModal)
    this.selectedAncestry = this.selectedAncestry.map((obj) => {
      const { selected, ...rest } = obj
      return rest
    })
    this.setState({
      searchInput: '',
      ancestryList: this.selectedAncestry,
      showLoadingView: true,
    })
  }

  searchFilter(text) {
    const dataByContain = this.selectedAncestry.filter((item) => {
      const { display_name: displayName } = item
      const itemData = displayName.toUpperCase()
      const textData = text.toUpperCase()
      return itemData.indexOf(textData) > -1
    })
    const dataByStart = this.selectedAncestry.filter((item) => {
      const { display_name: displayName } = item
      const itemData = displayName.toUpperCase()
      const textData = text.toUpperCase()
      return itemData.startsWith(textData)
    })
    const remainingData = dataByStart.concat(
      dataByContain.filter((item) => dataByStart.indexOf(item) < 0)
    )

    this.setState({ ancestryList: remainingData, searchInput: text })
  }

  returnData() {
    const returnData = this.selectedAncestry.filter(
      (item, index) => 'selected' in this.selectedAncestry[index]
    )

    this.props.selectedAncestry(returnData, this.props.parent)
    this.props.parentController.setModalVisible(!this.props.showModal)
    this.selectedAncestry.forEach((obj) => {
      delete obj.selected
    })
    this.setState({
      searchInput: '',
      ancestryList: this.selectedAncestry,
      showLoadingView: true,
    })
  }

  preSelectData() {
    const { parent, mothersData, fathersData } = this.props

    const preselect = parent === 'mother' ? mothersData : fathersData

    if (preselect) {
      preselect.forEach((obj) => {
        const tempSelected = this.selectedAncestry.findIndex(
          (item) => obj.ancestry_id === item.ancestry_id
        )
        this.selectedAncestry[tempSelected].selected = true
        this.setState({ ancestryList: this.selectedAncestry })
      })
    }
    this.setState({ showLoadingView: false })
  }

  renderList(item) {
    const { display_name: displayName } = item
    const isSelected = item?.selected

    return (
      <>
        <TouchableOpacity
          activeOpacity={1.0}
          style={{
            height: 60,
            justifyContent: 'center',
            width: '100%',
            backgroundColor: isSelected && 'rgba(0,0,0,0.2)',
          }}
          onPress={() => this.setSelectedAncestry(item.value)}
        >
          <Text
            style={[
              styles.itemText,
              {
                color: this.colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {displayName}
          </Text>
        </TouchableOpacity>
      </>
    )
  }

  render() {
    return (
      <Modal
        animationType="slide"
        visible={this.props.showModal}
        onShow={() => this.preSelectData()}
      >
        <SafeAreaView
          style={{
            flex: 1,
            backgroundColor: this.colorSettings?.onboarding_bgcolor || 'white',
          }}
        >
          <View
            style={[
              styles.container,
              {
                backgroundColor:
                  this.colorSettings?.onboarding_bgcolor || 'white',
              },
            ]}
          >
            <View style={styles.contentContainerHead}>
              <View style={styles.innerHeadContainer}>
                <View style={styles.backButtonContainer}>
                  <TouchableOpacity onPress={() => this.cancelAction()}>
                    <Image
                      source={
                        this.colorSettings?.selectable_btn_text_active_1
                          ? require('$assets/images/icon/back/white.png')
                          : require('$assets/images/icon/back/black.png')
                      }
                      style={{ width: 24, height: 24, resizeMode: 'contain' }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles.inputContainer}>
                  <TextInput
                    style={[
                      styles.textInputHead,
                      {
                        color:
                          this.colorSettings?.text_input_color_2 || 'black',
                      },
                    ]}
                    inputAccessoryViewID="AncestrySearchModal"
                    maxLength={30}
                    placeholder={i18n.t('search_ancestry').default}
                    placeholderTextColor={
                      this.colorSettings?.text_input_placeholder_color_2 ||
                      'rgba(74, 74, 74, 0.5)'
                    }
                    selectionColor={this.colorSettings?.text_input_color_2}
                    autoCorrect={false}
                    autoCapitalize="sentences"
                    onChangeText={(text) => this.searchFilter(text)}
                    value={this.state.searchInput}
                  />
                </View>
                <View />
              </View>
            </View>

            {/* Ancestry List */}
            <View style={styles.contentContainer}>
              <FlatList
                style={styles.ancestryList}
                maxToRenderPerBatch={50}
                keyExtractor={(item, index) => item + index}
                data={this.state.ancestryList}
                renderItem={({ item }) => this.renderList(item)}
              />
            </View>
          </View>

          <LoadingView
            message={i18n.t('please_wait').default}
            visible={this.state.showLoadingView}
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
          />

          {/* Done Button */}
          <View
            style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}
          >
            <TouchableOpacity
              style={[
                styles.doneButton,
                {
                  backgroundColor:
                    this.colorSettings?.bottom_next_btn_enabled || 'white',
                  borderColor:
                    this.colorSettings?.bottom_next_btn_enabled || 'black',
                  borderWidth: 1.0,
                },
              ]}
              onPress={() => this.returnData()}
            >
              <Text
                style={[
                  styles.doneButtonText,
                  {
                    color: this.colorSettings?.bottom_next_btn_text || 'black',
                  },
                ]}
              >
                {i18n.t('done').default}
              </Text>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  backButtonContainer: {
    width: 40,
  },
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
    width: '100%',
  },
  contentContainerHead: {
    height: 60,
    flexDirection: 'row',
    paddingHorizontal: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 1.0,
    borderColor: 'rgba(216,216,216,0.2)',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  textInputHead: {
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'left',
    // marginHorizontal: 17,
    width: '100%',
    ...Platform.select({
      web: {
        outlineColor: '#ccc',
      },
    }),
    maxWidth: 700,
  },
  cancelButton: {
    color: 'rgba(255,255,255,0.4)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  },
  itemText: {
    paddingHorizontal: 16,
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'left',
  },
  innerHeadContainer: {
    flexDirection: 'row',
    flex: 1,
    ...Platform.select({
      web: {
        justifyContent: 'space-between',
      },
    }),
  },
  inputContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        maxWidth: 760,
        minWidth: 100,
        width: '100%',
        alignSelf: 'center',
      },
    }),
  },
  modalBackground: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  doneButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  doneButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  ancestryList: {
    width: '100%',
    maxWidth: 700,
  },
})
