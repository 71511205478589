import React, { useState, useEffect } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import Alert from '$components/Alert'

import CustomTextInputView from '$components/custom-text-input-view/CustomTextInputView'
import DarkGraySwitch from '$components/custom-switches/DarkGraySwitch'
import { getData, saveData } from '$constants/SecureStore'
import {
  checkBiometricPermissionDataEnrolled,
  getBiometricName,
  handleBiometricsSupportAndPermissions,
} from '$constants/Biometrics'
import ApiFetchHandler from '$api/ApiFetchHandler.js'
import { i18n } from '$localization/config.js'
import styles from './styles.main'
import biometricsTypeName from '$constants/BiometricsType'

function BiometricsSettings() {
  const navigation = useNavigation()
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )

  const [switchEnabled, setSwitchEnabled] = useState(false)
  const [enabledDone, setEnabledDone] = useState(false)
  const [noBiometrics, setNoBiometrics] = useState(true)
  const [isBiometricEnrolled, setIsBiometricEnrolled] = useState(true)
  const [password, setPassword] = useState('')
  const [hideInput, setHideInput] = useState(true)
  const [biometricType, setBiometricType] = useState('')

  const checkIfBiometricsEnabled = async () => {
    try {
      const isBiometricEnabled = await getData({
        key: 'hasUserAcceptedBiometric',
      })
      const biometricEnrolled = await checkBiometricPermissionDataEnrolled()
      if (!biometricEnrolled) {
        setIsBiometricEnrolled(false)
      }
      if (isBiometricEnabled === 'true') {
        setSwitchEnabled(true)
        setHideInput(true)
      } else {
        const biometricsEnabled = await handleBiometricsSupportAndPermissions()
        if (biometricsEnabled) {
          setNoBiometrics(false)
        } else {
          setNoBiometrics(true)
        }
      }
    } catch (e) {
      console.log(
        '🚀 \n\n file: BiometricSettings.js:49 \n\n checkIfBiometricsEnabled \n\n e:',
        e
      )

      setSwitchEnabled(false)
    }
  }

  useEffect(() => {
    if (password.length > 0) {
      setEnabledDone(true)
    } else {
      setEnabledDone(false)
    }
  }, [password])

  const handleDisplayBiometricText = () => {
    if (!isBiometricEnrolled) {
      return i18n.t(
        'you_need_to_enable_fingerprint_authentication_in_your_settings_to_use_it_in_the_application.default',
        {
          biometricType: biometricsTypeName(biometricType),
        }
      )
    }
    return i18n.t('biometricsTitle.default', {
      biometricsType: biometricsTypeName(biometricType),
    })
  }

  const handleDisplayBiometricAlert = (status) => {
    if (status === 'failed') {
      return i18n.t('biometrics_display_alert_invalid.default', {
        biometricType: biometricsTypeName(biometricType),
      })
    }
    return i18n.t('biometrics_display_alert_success.default', {
      biometricType: biometricsTypeName(biometricType),
    })
  }

  const getBiometricType = async () => {
    const BIOMETRICS_TYPE = await getBiometricName()
    setBiometricType(BIOMETRICS_TYPE)
  }

  const handleSwitchButton = () => {
    setSwitchEnabled(!switchEnabled)

    if (switchEnabled) {
      setEnabledDone(true)
      setHideInput(true)
    } else {
      setHideInput(false)
    }
  }

  const handlePasswordCheck = async () => {
    try {
      const payload = {
        path: 'account/password_check/',
        method: 'post',
        header: {
          'Content-Type': 'application/json;charset=UTF-8',
          platform: 'mobile',
        },
        body: {
          password,
        },
        token: authCredentials.accessToken,
      }

      const response = await ApiFetchHandler(payload)

      if (response.isError) {
        Alert.alert(
          i18n.t('failed').default,
          i18n.t('invalid_password').default
        )
        return
      }

      await saveData({ key: 'password', value: password })
      await saveData({ key: 'email', value: response })
      await saveData({ key: 'hasUserAcceptedBiometric', value: 'true' })

      setSwitchEnabled(true)
      setPassword('')
      setHideInput(true)

      Alert.alert(
        i18n.t('success').default,
        handleDisplayBiometricAlert('success')
      )

      setTimeout(() => {
        navigation.pop()
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleNextButtonText = (enabled = true) =>
    enabled
      ? colorSettings?.bottom_next_btn_text_enabled || 'black'
      : colorSettings?.bottom_next_btn_text_disabled || 'black'

  const toggleNextButtonColor = (enabled = true) =>
    enabled
      ? colorSettings?.bottom_next_btn_enabled
      : colorSettings?.bottom_next_btn_disabled

  const handleDoneButton = async () => {
    if (!hideInput) {
      await handlePasswordCheck()
      return
    }

    await saveData({ key: 'hasUserAcceptedBiometric', value: 'false' })

    Alert.alert(
      i18n.t('success').default,
      handleDisplayBiometricAlert('failed')
    )

    setTimeout(() => {
      navigation.pop()
    }, 500)
  }

  useEffect(() => {
    checkIfBiometricsEnabled()
    getBiometricType()
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.topContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.biometricAuthtentiactionType}>
              {handleDisplayBiometricText()}
            </Text>
            {isBiometricEnrolled && (
              <DarkGraySwitch
                onValueChange={() => handleSwitchButton()}
                value={switchEnabled}
              />
            )}
          </View>
          <View style={styles.biometricAuthtentiactionTextContainer}>
            {isBiometricEnrolled && (
              <Text style={styles.biometricAuthtentiactionText}>
                {i18n.t(
                  'use_biometric_to_login_without_entering_your_password_you_can_turn_off_biometric_anytime_from_here.default',
                  {
                    biometricType: biometricsTypeName(biometricType),
                  }
                )}
              </Text>
            )}
          </View>
          {!hideInput && (
            <CustomTextInputView
              inputType="password"
              label={i18n.t('password').default}
              onChangeText={(text) => {
                setPassword(text)
              }}
            />
          )}
        </View>
        <View style={{ height: 60, width: '100%' }}>
          <View style={styles.nextButtonContainer}>
            <TouchableOpacity
              disabled={!enabledDone ?? noBiometrics}
              style={[
                styles.nextButton,
                toggleNextButtonColor(enabledDone),
                {
                  backgroundColor: colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={() => handleDoneButton()}
            >
              <Text
                style={[
                  styles.nextButtonText,
                  {
                    color: toggleNextButtonText(enabledDone),
                  },
                ]}
              >
                Done
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  )
}

export default BiometricsSettings
