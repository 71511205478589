const { CLIN_PORTAL_URL } = require('$constants/globalVariables')

module.exports = function () {
  let iframeUri = null
  let iframeContainerId = null
  let token = ''

  this.openInIframe = (uri, iframeContId, tokenStr) => {
    iframeUri = uri
    iframeContainerId = iframeContId
    token = tokenStr

    setTimeout(this.iframeLoadDelayed, 1000)
  }

  this.iframeLoadDelayed = () => {
    // we need the clinician portal domain that this app should be connected to,
    // maybe add as an env variable
    const clinicianPortalDomain = CLIN_PORTAL_URL

    // this is a route for patient pedigree landing page for patient web portal,
    // this is constant so this would not need any changes
    const patientPedigreeLandingPageRoute = '/patient-pedigree/'

    const iframeEl = document.createElement('iframe')
    iframeEl.setAttribute(
      'src',
      iframeContainerId == 'web-pedigree-iframe'
        ? clinicianPortalDomain + patientPedigreeLandingPageRoute
        : iframeUri
    )
    iframeEl.setAttribute(
      'style',
      'border: none; outline: none; width: auto; height: 100%;'
    )

    const iframeCont = document.getElementById(iframeContainerId) // get the iframe container div
    iframeCont.appendChild(iframeEl)

    if (iframeContainerId == 'web-pedigree-iframe') {
      // if web pedigree iframe, send the token as a message to the iframe element
      iframeEl.onload = () => {
        setTimeout(() => {
          iframeEl.contentWindow.postMessage(
            {
              token,
              type: iframeContainerId,
              url: clinicianPortalDomain + iframeUri,
              route: iframeUri,
            },
            '*'
          )
        }, [100])
      }
    }

    iframeEl.focus()
  }
}
