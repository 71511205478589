/* Custom Components and Constants */
import BaseController from '$components/BaseController.js'

import AuthCredentials from '$data_models/AuthCredentials'
import Proband from '$data_models/Proband.js'
import MemberProfile from '$data_models/MemberProfile.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'

/* Custom Utilities */
import { i18n } from '$localization/config.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { DefaultFullHeightForWeb } from '$constants/styles/global.styles.js'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class GenericFamilyListController extends BaseController {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  familyData = []

  allMembersList = []

  /** Note: Do not change. These keys are strictly tied to an API response. */
  sectionListOrder = {}

  state = {
    ...this.state,
    // showTutorialModal: false,
    sectionListRefreshing: false,
    familyData: [],
    allowFamilyInvite: false,
    scrollViewHeight: getScrollViewHeight(),
  }

  constructor(props) {
    super(props)

    /* Set shared instance */
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    const { setParams } = this.props.navigation
    setParams({ cs: this.props.colorSettings })

    /* Add listener to adjust scrollview height for Web */
    this.setScrollViewHeightListenerForWeb(true)

    /* Load initial Family list */
    setTimeout(() => {
      // this.setState({ showTutorialModal: isModalShown })
      this.getBasicFamilyMembers()
    }, 400)

    /* Return Redux identifiers to their default values. */
    this.props._setShouldReloadFamilyList_(false)

    /* Set the component's navigation event listeners */
    this.setNavigationEventListener('focus')
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  setNavigationEventAction = () => {
    const { setParams } = this.props.navigation
    setParams({ cs: this.props.colorSettings })

    /* 
    Note: Had to force reload because of bug wherein the state does not reload
    when the component reappears from StackNavigator.
    */
    this.getBasicFamilyMembers()
  }

  getBasicFamilyMembersPayload = () => {
    const proband = this.proband ?? null
    const probandID = proband?.probandID ?? null
    const familyID = proband?.familyID ?? null
    const headers = this.authCredentials ?? null
    const payload = {
      proband_id: probandID,
      member_id: probandID,
    }

    return {
      probandID,
      familyID,
      headers,
      payload,
    }
  }

  addToFamilyData = (memberList) => {
    const sectionData = []

    for (const j in memberList) {
      const _memberData = memberList[j]
      const memberData = new MemberProfile().constructBasicMemberData(
        _memberData
      )

      /* Disable Family Invite here */
      memberData.member.allow_family_invite = this.state.allowFamilyInvite
        ? 'ENABLED'
        : 'HIDDEN'

      sectionData.push(memberData)

      /* Update list of all Members */
      this.allMembersList.push(memberData)
    }

    return sectionData
  }

  requestAddRelative = async ({ relationshipToProband, familySide }) => {
    if (!this.proband?.probandID) return

    const { proband } = this
    const { saveError } = this.props
    const probandID = proband?.probandID

    const headers = this.authCredentials
    const payload = new MemberProfile().createPayloadForAddMember(
      probandID,
      probandID,
      {
        relationship_to_proband: relationshipToProband,
        side: familySide,
      }
    )

    const followUpAction = () => {
      /* Reloads the SectionList data */
      this.getBasicFamilyMembers()
    }

    /* Activate loading view */
    this.reloadAndRenderView()

    switch (relationshipToProband) {
      case RelationshipTypes.PARTNER: {
        // API request to add partner
        const addPartnerPayload = {
          path: 'member/add_partner/',
          method: 'post',
          token: headers.accessToken,
          body: { ...payload },
          pageDetails: {
            page: 'controller.js',
          },
        }

        const response = await ApiFetchHandler(addPartnerPayload)

        if (response.isError) {
          this.reloadAndRenderView(false)
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        this.reloadAndRenderView(false)
        followUpAction(response)

        break
      }
      case RelationshipTypes.SON:
      case RelationshipTypes.DAUGHTER: {
        if (relationshipToProband == RelationshipTypes.SON) payload.gender = 'M'
        if (relationshipToProband == RelationshipTypes.DAUGHTER)
          payload.gender = 'F'
        // API request to add child
        const addChildPayload = {
          path: 'member/add_child/',
          method: 'post',
          token: headers.accessToken,
          body: { ...payload },
          pageDetails: {
            page: 'controller.js',
          },
        }

        const response = await ApiFetchHandler(addChildPayload)

        if (response.isError) {
          this.reloadAndRenderView(false)
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        this.reloadAndRenderView(false)
        followUpAction(response)

        break
      }
      case RelationshipTypes.PREGNANCY:
        payload.gender = ''
        payload.pregnancy = true
        // Temporary unavailable.
        // API request to add pregnancy child
        this.reloadAndRenderView(false)
        showDefaultAlert(
          i18n.t('add_pregnancy')?.default,
          i18n.t('coming_soon')?.default
        )
        break

      case RelationshipTypes.BROTHER:
      case RelationshipTypes.SISTER: {
        if (relationshipToProband == RelationshipTypes.BROTHER)
          payload.gender = 'M'
        if (relationshipToProband == RelationshipTypes.SISTER)
          payload.gender = 'F'
        // API request to add sibling
        const addSiblingPayload = {
          path: 'member/add_sibling/',
          method: 'post',
          token: headers.accessToken,
          body: { ...payload },
          pageDetails: {
            page: 'controller.js',
          },
        }

        const response = await ApiFetchHandler(addSiblingPayload)

        if (response.isError) {
          this.reloadAndRenderView(false)
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        this.reloadAndRenderView(false)
        followUpAction(response)

        break
      }
      case RelationshipTypes.PARENT:
      case RelationshipTypes.FATHER:
      case RelationshipTypes.MOTHER: {
        if (relationshipToProband == RelationshipTypes.FATHER)
          payload.gender = 'M'
        if (relationshipToProband == RelationshipTypes.MOTHER)
          payload.gender = 'F'
        // API request to add parents
        const addParentsPayload = {
          path: 'member/add_parents/',
          method: 'post',
          token: headers.accessToken,
          body: { ...payload },
          pageDetails: {
            page: 'controller.js',
          },
        }

        const response = await ApiFetchHandler(addParentsPayload)

        if (response.isError) {
          this.reloadAndRenderView(false)
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        /** Temporary */
        this.reloadAndRenderView(false)
        showDefaultAlert('200', i18n.t('success')?.default)

        break
      }
      case RelationshipTypes.UNCLE:
      case RelationshipTypes.AUNT: {
        if (relationshipToProband == RelationshipTypes.UNCLE)
          payload.gender = 'M'
        if (relationshipToProband == RelationshipTypes.AUNT)
          payload.gender = 'F'
        // API request to add aunt/uncle
        const addAuntUnclePayload = {
          path: 'member/add_aunt_uncle/',
          method: 'post',
          token: headers.accessToken,
          body: { ...payload },
          pageDetails: {
            page: 'controller.js',
          },
        }

        const response = await ApiFetchHandler(addAuntUnclePayload)

        if (response.isError) {
          this.reloadAndRenderView(false)
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        this.reloadAndRenderView(false)
        followUpAction(response)

        break
      }
      default:
        showDefaultAlert(
          'Error 400',
          'requestAddRelative(): Unknown relationship type'
        )
        break
    }
  }

  createNavigateToNewMember = (data = {}, relationshipToProband) => {
    /** Creates and navigates to new Member */

    if (!data?.member_id) return

    const currentMember = new MemberProfile().constructBasicProfile({
      ...data,
      gender: data?.gender,
      relationship_to_proband: relationshipToProband,
      allow_family_invite: this.state.allowFamilyInvite ? 'ENABLED' : 'HIDDEN',
      newlyAdded: true,
    })

    setTimeout(() => {
      /* Proceed navigating to new Member Profile */
      this.navigateToMemberProfile(currentMember, true)
    }, 100)
  }

  navigateToMemberProfile = (member, shouldReloadList = false) => {
    const currentMember = new MemberProfile().constructBasicProfile({
      ...member,
    })

    /* Set identifier to reload family list on Redux store. */
    if (shouldReloadList) this.props._setShouldReloadFamilyList_(true)

    /* Note: This is not needed */
    /* Save basic family members to redux store. */
    // this.props._setBasicFamilyMembers_(this.familyData);

    /* Save basic member profile data to redux store */
    this.props._saveCurrentMemberToStore_(currentMember)

    if (member?.is_deleted) {
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('sorry_this_person_might_have_already_been_deleted')?.default
      )
      return
    }

    if (!currentMember?.member_id) return

    const routeNameComingFrom = this.props.navigation.state

    this.props.navigation.navigate('EditProfileView', {
      routeNameComingFrom,
    })
  }

  nextButtonAction = () => {
    this.props._setBasicFamilyMembers_(null)
  }
}

export default GenericFamilyListController
