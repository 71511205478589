import { Component } from 'react'
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import LoadingView from '$constants/LoadingView.js'

import {
  setIsFirstLogin,
  setShouldProceedToMain,
  setError,
} from '$redux/defaults/actions.js'
import { setSelectedClinicianAction } from '$redux/clinician/actions.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'
import UpdateMemberProfile from '$screens/_utils/UpdateMemberProfile.js'

// redux actions
import {
  setProbandProfileAction,
  setProbandImmediateFamily,
} from '$redux/proband/actions.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import {
  getFirstWorkflowScreen,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

// const CURRENT_SCREEN_NAME =
//   CUSTOM_FLOW_ROUTES?.SuccessScreenAccountCreated?.name

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  fullBackgroundImage: {
    flex: 1.0,
    width: '100%',
    justifyContent: 'center',
  },
  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
  },
  bodyText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
})

class SuccessScreenAccountCreated extends Component {
  colorSettings = this.props.colorSettings

  clinicianCodeID = this.props.route?.params?.clinicianCodeID ?? ''

  constructor(props) {
    super(props)

    this.state = {
      showLoadingView: false,
    }
  }

  async componentDidMount() {
    const clinician = this.props?.appSettings?.automatic_opt_in_clinician

    if (clinician) {
      const headers = this.props.authCredentials
      const payload = {
        clinician_code: clinician.code,
      }

      this.setState({ showLoadingView: true })
      await this.saveClinician(payload, headers)
    }
  }

  showCodeError = () => {
    showDefaultAlert(
      i18n.t('server_error').default,
      i18n.t(
        'sorry_there_was_an_error_processing_your_request_please_try_again_later'
      ).default
    )
  }

  saveClinician = async (payload, headers) => {
    const { saveError } = this.props

    const clinicianCheckPayload = {
      path: 'clinician/check/',
      method: 'post',
      token: headers.accessToken,
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SuccessScreenAccountCreated.js',
      },
    }

    const response = await ApiFetchHandler(clinicianCheckPayload)

    if (response.isError) {
      // this.showCodeError()
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.props._saveClinician_(response)
    this.setState({ showLoadingView: false })
  }

  createProband = async () => {
    const {
      authCredentials,
      account,
      saveError,
      saveProbandImmediateFamily,
      saveProbandProfileToStore,
      navigation,
    } = this.props

    const createProbandPayload = {
      path: 'proband/add/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        proband: {
          account_id: account.accountID,
          email: account.email,
          first_name: account.firstName,
          middle_name: '',
          last_name: account.lastName,
          age: account.age,
          dob: account.dob,
          phone_number: account.phoneNumber,
        },
      },
      pageDetails: {
        page: 'SuccessScreenAccountCreated.js',
      },
    }

    const response = await ApiFetchHandler(createProbandPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const familyData = {
      familyID: response.family_id,
      probandID: response.proband_id,
    }
    const memberData = {
      proband_id: response.proband_id,
      member_id: response.proband_id,
    }

    saveProbandImmediateFamily(familyData)
    /* Get basic member Profile when proband is created */
    const mapMemberProfile = await UpdateMemberProfile(
      memberData,
      authCredentials
    )

    saveProbandProfileToStore(mapMemberProfile)

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.account_created_screen,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })
  }

  getParameters = (routeName) => {
    const params = {
      nextPage: 0,
      prevPage: 0,
      navEditMode: false,
      navCompletedSurvey: null,
    }

    if (routeName === 'SurveyCustom') return params
    return {}
  }

  nextButtonAction = async () => {
    const {
      navigation,
      shouldProceedToMain,
      _setShouldProceedToMain_,
      _setIsFirstLogin_,
      account,
      authCredentials,
      saveError,
    } = this.props

    if (shouldProceedToMain) {
      /* Return identifier to proceed to Main to it's default */
      _setShouldProceedToMain_(false)

      /* Set isFirstLogin identifier for later use */
      _setIsFirstLogin_(true)

      /* Proceed to Dashboard (Main) */
      navigation.navigate('MainStack')
    } else {
      /* Default: Continue Onboarding process */

      const screen = {
        last_screen: RAW_LAST_VISITED_SCREENS.account_created_screen,
      }

      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })

      const firstWorkflowScreen = await getFirstWorkflowScreen({
        id: this.clinicianCodeID,
        fileName: 'SuccessScreenAccountCreated.js',
        defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
        lastVisitedScreen: '',
        isShortCustomWorkflow: false,
      })

      handleNavigateToNextScreen({
        navigation,
        routeName: firstWorkflowScreen,
        selectedScreen: '',
        params: {
          nextPage: 0,
          prevPage: 0,
          navEditMode: false,
          navCompletedSurvey: null,
        },
      })
    }
  }

  render() {
    // TODO: Use this customize the top, middle or bottom screen text coming from Django
    // const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

    const { colorSettings } = this.props

    const message = [
      i18n.t('your_account_has_successfully_been_created')?.default,
      i18n.t('now_lets_get_some_information_about_your_health')?.default,
      i18n.t(
        'dont_worry_if_you_dont_know_the_information_-_you_can_enter_it_later'
      )?.default,
    ].join('\n\n')

    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor:
              colorSettings?.success_screen_account_bg ?? 'white',
          },
        ]}
      >
        <ScrollView>
          <View style={styles.messageContentContainer}>
            <View style={{ height: 50 }} />

            <Image
              source={require('$assets/images/icon/success/badge.png')}
              style={{ width: 150, height: 150, resizeMode: 'contain' }}
            />

            <View style={{ width: '100%', marginVertical: 16 }}>
              <Text
                style={[
                  styles.titleText,
                  {
                    color:
                      colorSettings?.success_screen_account_header_text ??
                      'black',
                  },
                ]}
              >
                {i18n.t('congratulations')?.default}
              </Text>
            </View>
            <View style={{ width: '100%', paddingHorizontal: 16 }}>
              <Text
                style={[
                  styles.bodyText,
                  {
                    color:
                      colorSettings?.success_screen_account_text ?? 'black',
                  },
                ]}
              >
                {message}
              </Text>
            </View>

            <View
              style={{ marginTop: 40, marginHorizontal: 16, marginBottom: 40 }}
            >
              <View style={styles.nextButtonContainer}>
                <TouchableOpacity
                  style={[
                    styles.nextButton,
                    {
                      backgroundColor:
                        colorSettings?.success_screen_account_button_bg ??
                        'white',
                      borderColor:
                        colorSettings?.success_screen_account_button_border ??
                        'black',
                      borderWidth: 1.0,
                    },
                  ]}
                  onPress={this.nextButtonAction}
                >
                  <Text
                    style={[
                      styles.nextButtonText,
                      {
                        color:
                          colorSettings?.success_screen_account_button_text ??
                          'black',
                      },
                    ]}
                  >
                    {i18n.t('continue')?.default}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <LoadingView
            visible={this.state.showLoadingView}
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
          />
        </ScrollView>
      </View>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore } = state
  return {
    colorSettings: store.colorSettings,
    shouldProceedToMain: store.shouldProceedToMain,
    appSettings: store.appSettings,
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setIsFirstLogin_: (data) => dispatch(setIsFirstLogin(data)),
    _setShouldProceedToMain_: (data) => dispatch(setShouldProceedToMain(data)),
    _saveClinician_: (item) => dispatch(setSelectedClinicianAction(item)),
    saveProbandImmediateFamily: (item) =>
      dispatch(setProbandImmediateFamily(item)),
    saveProbandProfileToStore: (probandProfile) =>
      dispatch(setProbandProfileAction(probandProfile)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessScreenAccountCreated)
