import { useState, useEffect } from 'react'
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Platform,
} from 'react-native'
import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'
import EllipseButton from '$components/EllipseButton'
import CategoryButtonList from '$screens/relatives/profile/sections/health_history/components/disease_list_components/CategoryButtonList.js'
import { adjustFontSizeByLength, fixDiseaseName } from '$api/_utils/Utils.js'

const DiseaseListButton = (props) => {
  const { colorSettings } = props

  const [highlightButton, toggleHighlightButton] = useState(false)

  const diseaseFilter = props.memberData.filter(
    (item) => item.disease_id === props.diseaseData.id
  )

  useEffect(() => {
    if (diseaseFilter.length > 0) {
      toggleHighlightButton(true)
    }
  }, [diseaseFilter.length])

  const diseaseChecker = props.memberData.some(
    (item) => item.disease_id === props.diseaseData.id
  )

  // pass id and delete disease to  main DiseasesList.js
  const handleRemoveDiseaseData = (id) => {
    props.removeMemberDisease(id)
  }

  const renderDiseaseList = (itemData) => (
    <CategoryButtonList
      data={itemData}
      renderSkipLogic
      withButtonRemove={false}
      onEdit={props.navNextScreen.bind(this, props.diseaseData, diseaseFilter)}
    />
  )

  const sampleData = {
    disease_name: 'age_of_diagnosis',
    age_diagnosed: diseaseFilter[0] ? diseaseFilter[0].age_diagnosed : null,
  }

  const handleAOD = (show) => {
    if (!show) {
      return
    }
    return (
      <CategoryButtonList
        data={sampleData || []}
        containerStyle={{ marginTop: 20 }}
        withButtonRemove={false}
        onEdit={props.navNextScreen.bind(
          this,
          props.diseaseData,
          diseaseFilter
        )}
      />
    )
  }

  const handleOnPress = () => {
    if (!highlightButton) {
      props.navNextScreen(props.diseaseData, diseaseFilter)
    } else {
      handleRemoveDiseaseData(diseaseFilter[0].disease_id)
      toggleHighlightButton(false)
    }
  }

  const titleConverter = () => fixDiseaseName(props.diseaseData.disease_name)

  return (
    <View
      style={[
        styles.collapsableContainer,
        props.containerStyle,
        { width: Platform.OS === 'web' ? 529 : 'auto' },
      ]}
    >
      <EllipseButton
        onPress={handleOnPress}
        buttonStyle={{
          backgroundColor: highlightButton
            ? colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
            : colorSettings?.selectable_btn_inactive_1,
          borderColor: highlightButton
            ? colorSettings?.selectable_btn_border_active_1
            : colorSettings?.selectable_btn_border_inactive_1,
        }}
      >
        <Text
          style={[
            styles.roundedButtonTitleText,
            {
              color: colorSettings?.selectable_btn_text_active_1,
              fontSize:
                Platform.OS === 'web'
                  ? 18
                  : adjustFontSizeByLength(props.diseaseData.disease_name),
            },
          ]}
        >
          {i18n.t(titleConverter()).default}
        </Text>
      </EllipseButton>

      {handleAOD(highlightButton)}

      <FlatList
        keyExtractor={(item, index) => item.id}
        data={diseaseFilter[0] ? diseaseFilter[0].skip_logics : []}
        renderItem={renderDiseaseList}
        style={{ width: '100%' }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  collapsableContainer: {
    marginBottom: 10,
  },
  roundedButtonTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'center',
  },
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    diseaseCategory: store.diseaseCategory,
    diseaseCategoryList: store.diseaseCategoryList,
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps)(DiseaseListButton)
