import moment from 'moment'
import 'moment/min/locales'

import { useState } from 'react'
import { View, TextInput, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { i18n } from '$localization/config.js'
import LabelField from '../LabelField'
import { IS_WEB, IS_MOBILE } from '$constants/Platforms.js'
import SimplerDatePicker from '$components/web_date_picker/SimpleDatePicker.js'
import CustomDatePicker from '$components/custom-date-picker/custom-date-picker.js'
import GetDateFormatString from '$screens/_utils/LocaleDateFormat.js'
import styles from './styles'

export default function DateField({ inputFieldData, colorSettings }) {
  const [datePickerIsVisible, setDatePickerIsVisible] = useState(false)
  const [date, setDate] = useState(new Date(0))
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  const getDatePickerForWeb = () => (
    <View style={styles.datePickerContainer}>
      <SimplerDatePicker
        containerStyle={styles.dateContainer}
        yearStyle={styles.yearStyle}
        monthStyle={styles.monthStyle}
        dayStyle={styles.dayStyle}
        minDate={moment().subtract(185, 'years')}
        maxDate={moment().subtract(1, 'days')}
        onDatePicked={() => {}}
        getPromptString={(name) => i18n.t(name.toLowerCase())?.default}
        date={date}
        language={selectedLanguage}
      />
    </View>
  )

  const onChangeDateChange = ({ selectedDate }) => {
    if (!selectedDate.selectedDate)
      return setDatePickerIsVisible((visible) => !visible)

    const currentDate = moment(`${selectedDate.selectedDate}`).format(
      GetDateFormatString()
    )

    setDate(currentDate)
    setDatePickerIsVisible((visible) => !visible)
  }

  const birthDatePicker = () => {
    moment.locale(selectedLanguage)
    const currentDate = date
      ? moment(`${date}`).format(GetDateFormatString())
      : inputFieldData.placeholder

    return (
      <View>
        <TextInput
          style={[
            styles.inputField,
            {
              borderBottomColor: colorSettings?.btn_no_fill_border_2 || 'black',
              color: colorSettings?.text_input_color_2 || 'black',
            },
          ]}
          placeholder={inputFieldData.label}
          placeholderTextColor={
            colorSettings?.text_input_placeholder_color_2 ||
            'rgba(74, 74, 74, 0.5)'
          }
          value={`${currentDate}`}
        />
        <TouchableOpacity
          style={styles.datePickerButton}
          onPress={() => setDatePickerIsVisible((prevValue) => !prevValue)}
          activeOpacity={1}
        />
      </View>
    )
  }

  return (
    <View style={styles.mainContainer}>
      <LabelField
        title={inputFieldData.category_label}
        colorSettings={colorSettings}
      />
      {IS_WEB() && <View>{getDatePickerForWeb()}</View>}
      {IS_MOBILE() && <View>{birthDatePicker()}</View>}
      {datePickerIsVisible && (
        <CustomDatePicker
          colorSettings={colorSettings}
          onDatePickerChange={(selectedDate) =>
            onChangeDateChange({ selectedDate })
          }
          date={date ?? new Date(0)}
          visible={datePickerIsVisible}
          selectedLanguage={selectedLanguage}
        />
      )}
    </View>
  )
}

DateField.propTypes = {
  inputFieldData: PropTypes.shape({
    input_choices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        choice: PropTypes.string,
        order: PropTypes.number,
      })
    ),
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  colorSettings: PropTypes.objectOf(PropTypes.string),
}

DateField.defaultProps = {
  inputFieldData: {},
  colorSettings: {},
}
