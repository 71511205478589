import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlatList } from 'react-native'
import RELATIVE_TYPES from '../constants/constants'
import { storeCurrentMember } from '$redux/member_profile/actions'
import AddFamilyMemberOptionListItem from './AddFamilyMemberOptionListItem'
import { RELATION_TYPE } from '../../edit_profile/_components/relationship_info/_components/twinsUtils/constants'

const AddFamilyMemberOptions = ({
  selectedItemKeyRef,
  selectedFamilyMembers,
  setSelectedFamilyMembers,
  setAllowSubmit,
  hasMemberParents,
}) => {
  const dispatch = useDispatch()
  const probandProfile = useSelector(
    (state) => state.probandStore.probandProfile
  )
  const isCustomWorkflow = useSelector(
    (state) => state.customFlowRoutesStore.isCustomWorkflow
  )
  const [parentsSwitch, setParentsSwitch] = useState(false)
  const saveCurrentMemberToStore = (data) => dispatch(storeCurrentMember(data))

  const handleSkipTypes = () => {
    if (isCustomWorkflow) {
      return RELATIVE_TYPES.filter(
        (item) => item.type !== 'pregnancy' && item.type !== 'partner'
      )
    }

    return RELATIVE_TYPES
  }

  const handleRelativeCount = ({ relative, count, relativeSide }) => {
    const newSelectedFamilyMembers = selectedFamilyMembers

    const relativeIndex = selectedFamilyMembers
      .map((familyMember) => familyMember.selectedItemKeyRef)
      .indexOf(relative)

    const familyMember = newSelectedFamilyMembers?.[relativeIndex]

    switch (relativeSide) {
      case 'mom':
        familyMember.momSideCount = count
        break
      case 'dad':
        familyMember.dadSideCount = count
        break
      default:
        familyMember.count = count
    }

    newSelectedFamilyMembers[relativeIndex] = familyMember

    const isCountZero = newSelectedFamilyMembers.every(
      (member) =>
        member.count === 0 ||
        member.dadSideCount === 0 ||
        member.momSideCount === 0
    )

    const siblingsData = selectedFamilyMembers?.filter(
      (member) =>
        member.selectedItemKeyRef === 'sister' ||
        member.selectedItemKeyRef === 'brother'
    )

    setAllowSubmit(!isCountZero)
    setSelectedFamilyMembers(newSelectedFamilyMembers)

    return siblingsData?.some((member) => member.count > 0)
  }

  const didSelectOption = (relativeType, gender) => {
    const newSelectedFamilyMembers = selectedFamilyMembers

    const relativeIndex = selectedFamilyMembers
      .map((familyMember) => familyMember.selectedItemKeyRef)
      .indexOf(relativeType)

    if (relativeIndex === -1) {
      newSelectedFamilyMembers.push({
        selectedItemKeyRef: relativeType,
        selectedItemGender: gender,
        count: 1,
      })
    }

    setSelectedFamilyMembers(newSelectedFamilyMembers)
  }

  useEffect(() => {
    if (isCustomWorkflow) {
      const probandData = probandProfile
      probandData.relationship_to_proband = RELATION_TYPE.PROBAND
      saveCurrentMemberToStore(probandData)
    }
  }, [])

  const CURRENT_RELATIVE_TYPES = handleSkipTypes()

  return (
    <FlatList
      data={CURRENT_RELATIVE_TYPES}
      renderItem={({ item }) => (
        <AddFamilyMemberOptionListItem
          relativeType={item}
          selectedItemKeyRef={selectedItemKeyRef}
          didSelectOption={didSelectOption}
          handleRelativeCount={handleRelativeCount}
          hasMemberParents={hasMemberParents}
          setParentsSwitch={setParentsSwitch}
          parentsSwitch={parentsSwitch}
        />
      )}
      style={{ flex: 0.8 }}
    />
  )
}

export default AddFamilyMemberOptions
