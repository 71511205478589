import { Text, View, StyleSheet, Platform } from 'react-native'

const FlexContainerView = ({ children, style }) => <View style={[styles.container, style]}>{children}</View>

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 400,
    alignSelf: 'center',
    justifyContent: 'center',
    // alignItems: 'center',
  },
})

export default FlexContainerView
