import { Keyboard } from 'react-native'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'
import { DOMAIN_CLIENT_NAME } from '$constants/globalVariables'

import { i18n } from '$localization/config.js'

const addPhoneNumber = async (
  shouldNext,
  payload,
  setShowLoading,
  navigation,
  colorSettings,
  saveError
) => {
  Keyboard.dismiss()

  /* Prepare API request to add phone number. */
  setShowLoading(true)

  // Get Phone number and Dialing code.
  const addPhoneNumberPayload = {
    path: 'phone_number_verification/add/',
    method: 'post',
    body: {
      ...payload,
      domain_client: DOMAIN_CLIENT_NAME,
    },
    pageDetails: {
      page: 'addPhoneNumber.js',
    },
  }

  const response = await ApiFetchHandler(addPhoneNumberPayload)

  if (response.isError) {
    setShowLoading(false)

    return handleErrorResponse(response, saveError)
  }

  setShowLoading(false)

  // Navigate to Phone number verification.
  if (shouldNext) {
    navigation.navigate('SignupScreenPhoneCode', {
      resendPhoneNumberTime: response?.backoff_time,
    })
  }

  setShowLoading(false)
  return response
}

const handleErrorResponse = (response, saveError) => {
  const { status, error } = response
  switch (status) {
    case 409:
      showDefaultAlert(
        i18n.t('phone_number_not_available').default,
        i18n.t(
          'you_have_entered_a_phone_number_that_is_already_in_use_by_another_account_please_use_a_different_one'
        ).default
      )
      break

    case 400:
      showDefaultAlert(
        i18n.t('error').default,
        i18n.t('please_check_the_phone_number_and_try_again').default
      )
      break

    default:
      saveError({
        isShown: true,
        status,
        message: error,
      })
      break
  }
}

export default addPhoneNumber
