import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  // main container
  mainContainer: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
  },

  // category title with black wrapper
  categoryTitleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderColor: null,
    backgroundColor: 'rgba(0, 0, 0, .4)',
    height: 36,
    marginTop: 25,
    alignSelf: 'center',
  },
  categoryTitleTxt: {
    textAlign: 'right',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    paddingHorizontal: 25,
    // color: '#fff'
  },

  // Yes and No button
  selectButton: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '45%',
  },
  selectButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  //next buttong styling
  nxtBtnContainer: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
  nxtBtn: {
    justifyContent: 'center',
    // backgroundColor: "rgba(132,209,192,1.0)",
    height: '100%',
    width: '100%',
    borderWidth: 1,
  },
  nxtBtnText: {
    //   color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  //scrollView
  scrollView: {
    paddingHorizontal: 18,
    // marginHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //progress bar
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
})
