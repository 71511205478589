import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, View, Dimensions, InteractionManager } from 'react-native'
import useStateWithCallback from 'use-state-with-callback'

import { i18n } from '$localization/config.js'
import RelativeCount from './RelativeCount'

import styles from './styles'
import DarkGraySwitch from '../custom-switches/DarkGraySwitch'

export default function BorderedEllipseButton({
  isActive: isActiveProp,
  onPress: onPressHandler,
  relativeSide: relativeSideProp,
  keyRef,
  handleRelativeCount,
  titleText,
  hasMemberParents,
  relationToProband,
  triggerParentSelect,
  parentsSwitch,
  setParentsSwitch,
}) {
  const [relativeCount, setRelativeCount] = useState(0)
  const [momSideCount, setMomSideCount] = useState(0)
  const [dadSideCount, setDadSideCount] = useState(0)
  const [isActive, setIsActive] = useStateWithCallback(
    isActiveProp ?? false,
    (prevState) => {
      if (isActive && relativeCount && momSideCount && dadSideCount) {
        setIsActive(prevState)
        setRelativeCount(relativeCount)
        setMomSideCount(momSideCount)
        setDadSideCount(dadSideCount)
        return () => setRelativeCountHandler()
      }
    }
  )
  const isParentsRelationship = titleText === 'Parents'
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const getCount = (operator, value) => {
    if (operator === 'addition') return parseInt(value, 10) + 1
    if (operator === 'substraction') return parseInt(value, 10) - 1
    return parseInt(value, 10)
  }

  const handleAddParentsWhileSiblingsAdded = (shouldAddParents) => {
    const isParentAndSelectedSiblings =
      relationToProband === 'grandparent' &&
      (keyRef === 'sister' || keyRef === 'brother') &&
      !hasMemberParents

    if (isParentAndSelectedSiblings) {
      triggerParentSelect()
      setParentsSwitch(shouldAddParents)
      handleRelativeCount({
        relative: 'parent',
        count: shouldAddParents ? 1 : 0,
      })
    }
  }

  const setRelativeCountHandler = (relativeSide, operator) => {
    const isSubstraction = operator === 'substraction'

    switch (relativeSide) {
      case 'mom':
        if (momSideCount <= 0 && isSubstraction) return

        const momCount = getCount(operator, momSideCount)

        handleRelativeCount({ relative: keyRef, count: momCount, relativeSide })

        setMomSideCount(momCount)
        break
      case 'dad':
        if (dadSideCount <= 0 && isSubstraction) return

        const dadCount = getCount(operator, dadSideCount)

        handleRelativeCount({ relative: keyRef, count: dadCount, relativeSide })

        setDadSideCount(dadCount)
        break
      default:
        if (relativeCount <= 0 && isSubstraction) return

        const count = getCount(operator, relativeCount)

        const shouldAddParents = handleRelativeCount({
          relative: keyRef,
          count,
          relativeSide,
        })

        handleAddParentsWhileSiblingsAdded(shouldAddParents)
        setRelativeCount(count)
    }
  }

  const onPress = ({ relativeSide, operator }) => {
    if (isActive) {
      setRelativeCountHandler(relativeSide, operator)
      return
    }

    onPressHandler(keyRef)

    setIsActive(() => {
      setRelativeCount(relativeCount)
      setMomSideCount(momSideCount)
      setDadSideCount(dadSideCount)

      return InteractionManager.runAfterInteractions(() =>
        setRelativeCountHandler(relativeSide, operator)
      )
    })
  }

  const handleTypedRelations = (relativeSide, value) => {
    switch (relativeSide) {
      case 'mom':
        handleRelativeCount({ relative: keyRef, count: value, relativeSide })

        setMomSideCount(value)
        break
      case 'dad':
        handleRelativeCount({ relative: keyRef, count: value, relativeSide })

        setDadSideCount(value)
        break
      default:
        const shouldAddParents = handleRelativeCount({
          relative: keyRef,
          count: value,
          relativeSide,
        })
        handleAddParentsWhileSiblingsAdded(shouldAddParents)
        setRelativeCount(value)
    }
  }

  const handleParentRelative = () => {
    triggerParentSelect()
    setParentsSwitch(!parentsSwitch)
    handleRelativeCount({ relative: 'parent', count: parentsSwitch ? 0 : 1 })
  }

  const onChange = ({ value, relativeSide }) =>
    handleTypedRelations(relativeSide, +value)

  const onFocus = (relativeSide) => {
    onPressHandler()
    handleTypedRelations(relativeSide, '')
  }

  const inputValidation = (relativeSide) => {
    const isInputFilled = relativeCount > 0 || Number.isNaN(relativeCount)

    const isDadOrMomSideInputFilled = momSideCount > 0 || dadSideCount > 0
    if (isInputFilled || isDadOrMomSideInputFilled) return

    handleTypedRelations(relativeSide, 0)
  }

  const { width: screenWidth } = Dimensions.get('window')

  const familyMembersCount = {
    relativeCount,
    momSideCount,
    dadSideCount,
  }

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.emptyContainer} />

        <View
          style={[
            styles.textContainer,
            { minWidth: screenWidth < 360 ? 140 : 200 },
          ]}
        >
          <Text style={styles.buttonText(colorSettings)}>
            {i18n?.t(`${titleText}`)?.title ?? titleText}
          </Text>
        </View>

        <View style={styles.buttonContainer}>
          {isParentsRelationship && !hasMemberParents ? (
            <View
              style={{
                width: '100%',
                height: 66,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DarkGraySwitch
                key={parentsSwitch}
                value={parentsSwitch}
                onValueChange={handleParentRelative}
              />
            </View>
          ) : (
            <RelativeCount
              relativeSide={relativeSideProp}
              colorSettings={colorSettings}
              familyMembersCount={familyMembersCount}
              inputValidation={inputValidation}
              onPress={onPress}
              onChange={onChange}
              isEditable
              onFocus={onFocus}
              onPressHandler={onPressHandler}
            />
          )}
        </View>

        <View style={styles.emptyContainer} />
      </View>
    </View>
  )
}
