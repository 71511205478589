import {
  capitalizeFirstLetter,
  fixDiseaseName,
  getFamilySideAndRelationshipGender,
  translateLongRelationshipName,
} from '$api/_utils/Utils'
import { i18n } from '$localization/config.js'

const excludedRelationshipToCount = [
  'proband',
  'mother',
  'father',
  'grandmother',
  'grandfather',
]

const familyMemberCounter = (relationship, listOfRelationships) => {
  if (listOfRelationships.length < 1) {
    return [true, true]
  }

  const getRelationshipStatus = listOfRelationships.find(
    (childrenRelationship) =>
      childrenRelationship?.relationshipName === relationship
  )

  if (!getRelationshipStatus) {
    return [true, true]
  }

  return [false, false, true]
}

const calculateRelationship = (member, relationshipList) => {
  const _relationshipToProband = getFamilySideAndRelationshipGender(
    member?.relationship_to_proband,
    member?.gender
  )

  const _relationship = _relationshipToProband[0]?.toLowerCase()
  const _familySide = _relationshipToProband[1]?.toLowerCase()
  let _greatCounter = _relationshipToProband[2]

  let relationship =
    i18n.t(fixDiseaseName(_relationship))?.default ??
    capitalizeFirstLetter(fixDiseaseName(_relationship), true)

  relationship = translateLongRelationshipName(relationship)

  relationship = `${relationship} `

  const item = {
    relationshipName: _familySide
      ? `${relationship}${_familySide}`
      : relationship,
    count: 1,
  }

  if (excludedRelationshipToCount.includes(member?.relationship_to_proband))
    return { relationship, item }

  const [
    hasRelationshipItem = false,
    hasRelationshipCount = false,
    hasRelationshipIndex = false,
  ] = familyMemberCounter(item?.relationshipName, relationshipList)

  if (hasRelationshipItem) relationshipList.push(item)

  if (hasRelationshipCount) relationship += 1

  if (hasRelationshipIndex) {
    const getRelationshipIndex = relationshipList.findIndex(
      (childrenRelationship) =>
        childrenRelationship?.relationshipName === item.relationshipName
    )

    const count = relationshipList[getRelationshipIndex].count + 1
    relationship += count

    relationshipList[getRelationshipIndex].count += 1
  }

  if (_familySide) {
    relationship = `${relationship} (${i18n.t(_familySide)?.default})`
  }

  while (_greatCounter > 0) {
    let relationshipCount = relationship

    if (
      relationshipCount.includes('Grandfather') ||
      relationshipCount.includes('Grandmother')
    ) {
      relationshipCount = relationshipCount.replace(/\d/g, '')
    }

    relationship = `${i18n.t('great')?.default} ${relationshipCount}`
    _greatCounter--
  }

  // Capitalize first letter of every word.
  relationship = capitalizeFirstLetter(relationship, true, ' ')

  return {
    relationship,
    item,
  }
}

export default calculateRelationship
