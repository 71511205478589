import moment from 'moment'

import { Component } from 'react'
import { View, Text, Keyboard, Platform, Appearance } from 'react-native'

import { connect } from 'react-redux'
import { TextInputMask } from 'react-native-masked-text'

import SimplerDatePicker from '$components/web_date_picker/SimpleDatePicker.js'

import { i18n } from '$localization/config.js'
import GetDateFormatString from '$screens/_utils/LocaleDateFormat.js'

import { defaultDateFormat, calculateAgeFromDob } from '$api/_utils/Utils.js'

import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'
import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import { yearIntervalFromNow } from '$api/_utils/Utils'

const customDateFormat = GetDateFormatString(false, ' / ')
const CURRENT_AGE = 'currentAge'
const DOB = 'dob'

class BirthdateFieldWeb extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  allowUserInputDOB = this.props.allowUserInputDOB

  setAllowUserInputDOB = this.props.setAllowUserInputDOB

  currentAgeType = this.props.currentAgeType ?? 'years'

  state = {
    memberProfile: this.memberProfile,
    profileData: this.profileData,
    currentAge: null,
    dob: '',
  }

  __refs__ = {
    datePicker: null,
  }

  constructor(props) {
    super(props)

    if (Platform.OS === 'ios') {
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardWillHide',
        this._keyboardDismissed
      )
    } else {
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this._keyboardDismissed
      )
    }

    const { memberProfile, profileData } = this.state

    if (profileData) {
      const dob = profileData?.dob
      let formattedDOB = null

      if (dob) {
        formattedDOB = moment(dob, defaultDateFormat).format(customDateFormat)

        formattedDOB = formattedDOB !== 'Invalid date' ? formattedDOB : null
      }

      this.state = {
        ...this.state,
        dob: formattedDOB,
        currentAge: profileData?.currentAge,
      }
    }
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const { profileData } = memberProfile

    if (profileData) {
      const dob = profileData?.dob
      let formattedDOB = null

      if (dob) {
        formattedDOB = moment(dob, defaultDateFormat).format(customDateFormat)

        formattedDOB = formattedDOB !== 'Invalid date' ? formattedDOB : null
      }

      const newState = {
        ...prevState,
        memberProfile,
        profileData,
        currentAge: profileData?.currentAge,
        dob: formattedDOB,
      }

      return newState
    }
  }

  componentDidMount() {
    // Method to check for 'dark mode' setting.
    const bgAppearance = Appearance.getColorScheme()

    if (bgAppearance !== 'no-preference' || bgAppearance !== null) {
      this.setState({ colorScheme: bgAppearance })
    }

    this.updateBirthdate(this.state.dob)
  }

  componentWillUnmount() {
    this.keyboardDidHideListener.remove()
  }

  _keyboardDismissed = () => {
    this.updateBirthdate(this.state.dob)
  }

  updateBirthdate = (dob) => {
    let formattedDOB = null

    if (dob) {
      formattedDOB = moment(dob, customDateFormat).format(defaultDateFormat)

      formattedDOB = formattedDOB !== 'Invalid date' ? formattedDOB : null

      this.props.handleWebDatepickerWithAgeUnits(formattedDOB)
    }

    this.parentController?._updateState_(DOB, formattedDOB)
  }

  onDatePickerChange = (value) => {
    if (value == null) return

    // Update component's state
    this.setState({ dob: value })

    // Disables manual DOB input
    this.setAllowUserInputDOB(false)
    this.updateBirthdate(value)
  }

  DobToMoment = (value) => {
    if (!value) return null

    const dateNoSpaces = value?.replaceAll('', '')

    const date = moment(dateNoSpaces, 'MM/DD/YYYY').utc()

    return date
  }

  render() {
    if (!this.state.profileData) return null

    return (
      <View style={{ zIndex: -1 }}>
        <View style={section_styles.contentSeparatorLine} />
        <View style={section_styles.contentItem}>
          <View style={section_styles.contentItemLabelTextContainer}>
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: this.colorSettings?.text_input_label_color_1 },
              ]}
            >
              {i18n.t('birthdate')?.default}
            </Text>
          </View>

          <View style={section_styles.contentItemTextInputContainer}>
            <SimplerDatePicker
              date={this.DobToMoment(this.state.dob)}
              minDate={moment().subtract(185, 'years')}
              maxDate={moment().subtract(1, 'days')}
              onDatePicked={this.onDatePickerChange}
              textStyle={{}}
              yearStyle={{
                borderStyle: 'solid',
                borderColor: 'rgb(204, 204, 204)',
                borderRadius: 3,
                marginRight: 10,
                marginTop: 7,
                height: 34,
                width: 74,
                paddingLeft: 8,
                fontFamily: 'montserrat-medium',
                fontSize: 15,
                color: 'rgb(74, 74, 74)',
              }}
              monthStyle={{
                borderStyle: 'solid',
                borderColor: 'rgb(204, 204, 204)',
                borderRadius: 3,
                marginRight: 10,
                marginTop: 7,
                height: 34,
                width: 122,
                paddingLeft: 8,
                fontFamily: 'montserrat-medium',
                fontSize: 15,
                color: 'rgb(74, 74, 74)',
              }}
              dayStyle={{
                borderStyle: 'solid',
                borderColor: 'rgb(204, 204, 204)',
                borderRadius: 3,
                marginTop: 7,
                height: 34,
                width: 69,
                paddingLeft: 8,
                fontFamily: 'montserrat-medium',
                fontSize: 15,
                color: 'rgb(74, 74, 74)',
              }}
              getPromptString={(name) => i18n.t(name.toLowerCase())?.default}
              language={this.props.language}
            />
          </View>
        </View>
      </View>
    )
  }
}

/* 
function mapGlobalStateToProps(globalState) {
  return {
    appSettings: globalState.appSettings,
    colorSettings: globalState.colorSettings,
  }
}
export default connect(mapGlobalStateToProps, null)(BirthdateFieldWeb)
*/

export default BirthdateFieldWeb
