import { Suspense, lazy, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setInviteID } from '$redux/defaults/actions.js'
import ApiFetchHandler from '$api/ApiFetchHandler'
import ErrorBoundary from 'react-native-error-boundary'

import {
  setAccountAction,
  setCustomWorkflowAccountStatus,
  setIsLogoutAction,
  setIsAccountDeletedAction,
} from '$redux/account/actions'

import { clearWorkflowDataAction } from '$redux/customFlowRoutes/actions.js'

import LoadingView from '$constants/LoadingView.js'
import { IS_MOBILE } from '$constants/Platforms.js'

const LoginView = lazy(() => import('../login/Login.view'))

export default function CustomLoginScreen({ navigation }) {
  const dispatch = useDispatch()

  const [userHasPassword, setUserHasPassword] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const userUUID = useSelector((state) => state.store.inviteID)

  const { isAccountDeleted, isLogout } = useSelector(
    (state) => state.accountStore
  )
  const saveInviteID = (data) => dispatch(setInviteID(data))

  const saveCustomWorkflowAccountStatus = (data) =>
    dispatch(setCustomWorkflowAccountStatus(data))
  const saveIsAccountDeleted = (data) =>
    dispatch(setIsAccountDeletedAction(data))
  const saveIsLogout = (data) => dispatch(setIsLogoutAction(data))
  const savePatientFirstName = (data) => dispatch(setAccountAction(data))
  const saveError = (data) => dispatch(setError(data))

  const getScreen = async () => {
    const { getInitialURL, parse } = require('expo-linking')

    try {
      const getUrl = await getInitialURL()

      const { queryParams } = parse(getUrl)

      let inviteID = queryParams.id

      if (isLogout && userUUID) {
        inviteID = userUUID
      }

      if (!inviteID || isAccountDeleted) {
        saveInviteID(null)
        setUserHasPassword(true)
        setIsLoading(false)
        saveIsAccountDeleted(false)
        return
      }

      const payload = {
        inviteID,
      }

      const passwordCheckPayload = {
        path: `patient_account/${inviteID}/invite_auth_type`,
        method: 'get',
        body: {
          ...payload,
        },
        pageDetails: {
          page: 'CustomLoginScreen.js',
        },
      }

      const response = await ApiFetchHandler(passwordCheckPayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        setUserHasPassword(true)
        setIsLoading(false)

        return
      }

      if (response.auth_type === 'password') {
        setUserHasPassword(true)
        setIsLoading(false)

        return
      }

      saveCustomWorkflowAccountStatus(response.account_status)
      saveInviteID(inviteID)

      savePatientFirstName({ first_name: response.first_name })
      saveIsLogout(false)
      saveIsAccountDeleted(false)
      setUserHasPassword(false)

      setIsLoading(false)

      navigation.navigate('WelcomeScreen', {
        id: inviteID,
      })
    } catch (error) {
      console.log(
        '🚀 \n\n file: CustomLoginScreen.js:108 \n\n getScreen \n\n error:',
        error
      )

      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (IS_MOBILE()) return

    getScreen()

    dispatch(clearWorkflowDataAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (IS_MOBILE())
    return (
      <ErrorBoundary>
        <Suspense fallback={<LoadingView />}>
          <LoginView navigation={navigation} />
        </Suspense>
      </ErrorBoundary>
    )

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingView />}>
        {userHasPassword && !isLoading ? (
          <LoginView navigation={navigation} />
        ) : (
          <LoadingView />
        )}
      </Suspense>
    </ErrorBoundary>
  )
}
