import React, { useEffect, useRef, useState } from 'react'
import { View, Platform, SafeAreaView, Modal, Text } from 'react-native'
import { WebView } from 'react-native-webview'
import * as FileSystem from 'expo-file-system'
import { shareAsync } from 'expo-sharing'
import { useSelector, useDispatch } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'
import { IS_WEB, WEB, IS_MOBILE } from '$constants/Platforms.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'
import LoadingView from '$constants/LoadingView.js'
import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'
import styles from './assets/styles'
import ApiFetchHandler from '$api/ApiFetchHandler'
import { setError } from '$redux/defaults/actions.js'
import { mapDataToMemberProfile } from '$api/_utils/Utils.js'
import { setProbandProfileAction } from '$redux/proband/actions.js'
import MemberProfile from '$data_models/MemberProfile.js'
import {
  storeCurrentMember,
  setDidAddNewMember,
} from '$redux/member_profile/actions.js'
import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'
import sectionListOrder from '$screens/main/family/FamilyListScreen/constants/sectionListOrder.js'
import { requestAddRelative } from '$screens/main/family/FamilyListScreen/helpers/handleAddRelatives.js'

import useGetMemberProfile from '$hooks/useGetMemberProfile'

import { CLIN_PORTAL_URL } from '$constants/globalVariables'

const WEB_IFRAME_CONTAINER_ID = 'web-pedigree-iframe'

function FamilyPedigreeScreen({ navigation }) {
  const webView = useRef(null)
  const dispatch = useDispatch()

  const [scrollViewHeight, setScrollViewHeight] = useState(
    DefaultFullHeightForWeb()
  )
  const [showCloseButton, setShowCloseButton] = useState(
    DefaultFullHeightForWeb()
  )
  const [memberId, setMemberId] = useState(null)
  const [navItem, setNavItem] = useState(null)

  const proband = useSelector((state) => state.probandStore.proband)
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)
  const { didAddNewMember, addNewMemberPayload } = useSelector(
    (state) => state.memberProfileStore
  )
  const saveError = (data) => dispatch(setError(data))
  const saveProbandProfileToStore = (data) =>
    dispatch(setProbandProfileAction(data))

  const saveCurrentMemberToStore = (data) =>
    new Promise((resolve, reject) => {
      dispatch(storeCurrentMember(data))
      resolve('done')
    })
  const saveDidAddNewMember = (data) => dispatch(setDidAddNewMember(data))

  const { profile, memberProfileLoading } = useGetMemberProfile(
    proband?.probandID,
    authCredentials,
    memberId
  )
  const [showLoadingView, setShowLoadingView] = useState(memberProfileLoading)

  useEffect(() => {
    if (memberProfileLoading) return setShowLoadingView(true)

    setShowLoadingView(false)
    saveDidAddNewMember(null)
    const { probandID } = proband
    const handleGetFamilyDetails = (event) => {
      if (isJsonString(event.data)) {
        const data = JSON.parse(event.data)
        setMemberId(data.selected_member_id)

        setShowLoadingView(true)
        if (data.type === 'add-member') {
          setNavItem('addMember')
        } else if (data.type === 'edit-member') {
          setNavItem('editMember')
        }
      }
    }

    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        setScrollViewHeight(DefaultFullHeightForWeb())
      )

      window.addEventListener('message', () => {}, true)

      window.addEventListener('message', handleGetFamilyDetails)

      const WebViewIframeOpen = require('$utils/WebViewIframeOpen.web.js')
      const token = authCredentials?.accessToken

      new WebViewIframeOpen().openInIframe(
        `/pedigree/${probandID}/#family-history`,
        WEB_IFRAME_CONTAINER_ID,
        token
      )
    }

    navigation.setParams({
      printPedigree: IS_MOBILE() ? onPrintClickMobile : onPrintClickWeb,
    })

    saveDidAddNewMember(false)

    return () => window.removeEventListener('message', handleGetFamilyDetails)
  }, [])

  useEffect(() => {
    if (memberProfileLoading) return
    if (navItem === 'addMember') {
      navigation.navigate('AddMemberNavigator', {
        screen: 'AddFamilyMemberView',
        params: {
          fromFamilyPedigreeScreen: true,
        },
      })
    }

    if (navItem === 'editMember') {
      navigation.navigate('EditProfileView', {
        isFamilyScreen: true,
      })
    }

    setNavItem(null)
    setShowLoadingView(false)
  }, [memberProfileLoading])

  const onPrintClickWeb = () => {
    const iFrameContainer = document.getElementById(WEB_IFRAME_CONTAINER_ID)
    if (iFrameContainer) {
      iFrameContainer.children[0].contentWindow.postMessage(
        'web-pedigree-iframe-print',
        '*'
      )
    }
  }

  useEffect(() => {
    if (!didAddNewMember) return
    if (!addNewMemberPayload || addNewMemberPayload.length <= 0) return

    const memberAdd = async (reloadPlatform) => {
      for (const payload of addNewMemberPayload) {
        await requestAddRelative(payload, authCredentials, saveError)
      }

      reloadPlatform()
      saveDidAddNewMember(false)
    }

    if (IS_WEB()) {
      memberAdd(pedigreeReloadWeb)
      return () => {}
    }

    memberAdd(pedigreeReloadMobile)
  }, [didAddNewMember])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (IS_WEB()) {
        pedigreeReloadWeb()
        return () => {}
      }

      pedigreeReloadMobile()

      // return alert(' call pedigreeReloadMobile')
    })

    return unsubscribe
  }, [navigation])

  const pedigreeReloadWeb = () => {
    const iFrameContainer = document.getElementById(WEB_IFRAME_CONTAINER_ID)

    if (iFrameContainer) {
      iFrameContainer.children[0].contentWindow.postMessage(
        'web-reload-pedigree',
        '*'
      )
    }
  }

  const pedigreeReloadMobile = () => {
    // trigger event on clinician portal iframe
    const myInjectedJs = `(function(){
          const event = new Event("mobile-reload-pedigree");
          window.dispatchEvent(event);
        })();`
    webView.current.injectJavaScript(myInjectedJs)
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const onPrintClickMobile = () => {
    // trigger event on clinician portal iframe
    const myInjectedJs = `(function(){
      const event = new Event("mobile-print");
      window.dispatchEvent(event);
    })();`
    webView.current.injectJavaScript(myInjectedJs)
  }

  // download for Android
  const downloadForAos = async (pdfBase64Str, fileName) => {
    const folder =
      FileSystem.StorageAccessFramework.getUriForDirectoryInRoot('test')
    const permissions =
      await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync(
        folder
      )
    if (!permissions.granted) return
    const filePath = await FileSystem.StorageAccessFramework.createFileAsync(
      permissions.directoryUri,
      `${fileName}.pdf`,
      'application/pdf'
    )
    try {
      await FileSystem.StorageAccessFramework.writeAsStringAsync(
        filePath,
        pdfBase64Str,
        { encoding: FileSystem.EncodingType.Base64 }
      )
    } catch (err) {
      console.log(err)
    }
  }
  // download for iOS
  const downloadForIos = async (pdfBase64Str, fileName) => {
    try {
      const filePath = `${FileSystem.documentDirectory}${fileName}.pdf`
      await FileSystem.StorageAccessFramework.writeAsStringAsync(
        filePath,
        pdfBase64Str,
        { encoding: FileSystem.EncodingType.Base64 }
      )
      await shareAsync(filePath)
    } catch (err) {
      console.log(err)
    }
  }

  const handleMessage = (event) => {
    const data = JSON.parse(event.nativeEvent.data)
    if (data.type === 'print-data') {
      const dataURL = data.data
      const fileName = data.file_name
      if (Platform.OS === 'ios') {
        downloadForIos(dataURL, fileName)
      } else {
        downloadForAos(dataURL, fileName)
      }
    } else if (data.type === 'add-member') {
      setShowLoadingView(true)
      setMemberId(data.selected_member_id)
      setNavItem('addMember')
    } else if (data.type === 'edit-member') {
      setShowLoadingView(true)
      setMemberId(data.selected_member_id)
      setNavItem('editMember')
    }
  }

  const token = authCredentials?.accessToken
  const myInjectedJs = `(function(){
      localStorage.setItem('patient-pedigree-token', '${token}');
    })();`

  const clinicianPortalDomain = CLIN_PORTAL_URL

  if (IS_WEB()) {
    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <View
            style={[
              styles.containerWeb,
              {
                backgroundColor: colorSettings?.onboarding_bgcolor,
              },
            ]}
          >
            <View
              style={styles.webViewContent}
              nativeID={WEB_IFRAME_CONTAINER_ID}
            />
          </View>
          <LoadingView
            visible={showLoadingView}
            tintColor={colorSettings?.post_onboarding_nav_bgcolor}
            textColor={colorSettings?.post_onboarding_nav_bgcolor}
          />
        </MainWrapper>
      </ErrorBoundary>
    )
  }

  if (IS_MOBILE()) {
    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor:
                  colorSettings?.post_onboarding_bgcolor ?? 'rgb(245,245,245)',
                ...Platform.select({
                  [WEB]: {
                    flex: null,
                    height: scrollViewHeight,
                  },
                }),
              },
            ]}
          >
            {/* {this.renderSurveyList()} */}
            <WebView
              ref={webView}
              style={styles.webViewMainContainer}
              containerStyle={styles.webViewInnerContainer}
              onLoadEnd={() => {
                setShowLoadingView(false)
                setShowCloseButton(false)
              }}
              onError={() => {
                setShowLoadingView(false)
                setShowCloseButton(true)
              }}
              onHttpError={() => {
                setShowLoadingView(false)
                setShowCloseButton(true)
              }}
              bounces={false}
              source={{
                uri: `${clinicianPortalDomain}/pedigree/${proband?.probandID}/#family-history`,
              }}
              injectedJavaScriptBeforeContentLoaded={myInjectedJs}
              javaScriptEnabled
              domStorageEnabled
              sharedCookiesEnabled
              allowUniversalAccessFromFileURLs
              allowingReadAccessToURL
              data
              allowFileAccess
              mixedContentMode="always"
              onMessage={(event) => {
                handleMessage(event)
              }}
            />

            {showLoadingView && (
              <LoadingView
                // visible={showLoadingView}
                tintColor={colorSettings?.post_onboarding_nav_bgcolor}
                textColor={colorSettings?.post_onboarding_nav_bgcolor}
              />
            )}
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

export default FamilyPedigreeScreen
