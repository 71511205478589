import { Component } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  LayoutAnimation,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'
import DiseaseListButton from '$screens/relatives/profile/sections/health_history/components/disease_list_components/DiseaseListButton.js'
import LoadingView from '$constants/LoadingView.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import global_styles from '$constants/styles/global.styles.js'
import EllipseButton from '$components/EllipseButton'

import { setError } from '$redux/defaults/actions.js'

import { getStackRouterRoot } from '$navigation/_utils/NavigationUtils.js'

let stackRouterRoot = null

class DiseasesList extends Component {
  colorSettings = this.props.colorSettings

  existingMemberDiseases =
    this.props.route.params?.existingMemberDiseases ?? null

  onDismiss = this.props.route.params?.onDismiss ?? null

  memberDiseases = this.props.route.params?.diseaseList ?? null

  diseaseCategory = this.props.route.params?.diseaseCategory ?? null

  title = this.props.route.params?.diseaseName ?? null

  memberProfile = this.props.route.params?.memberProfile ?? null

  proband = this.props.route.params?.proband ?? null

  updateMainMemberDisease = this.props.route.params?.updateMemberDisease ?? null

  memberGender = this.memberProfile.profileData.biologicalGender

  selectedDisease = this.props.diseaseCategoryList.diseases.filter(
    (item) =>
      item.disease_type === this.diseaseCategory &&
      (item.gender_specific === this.memberGender ||
        item.gender_specific === null)
  )

  shownDiseases = this.selectedDisease.filter((item) => item.show != 'expanded')

  hiddenDiseases = this.selectedDisease.filter(
    (item) => item.show == 'expanded'
  )

  _scrollOffset = 0

  constructor(props) {
    super(props)

    this.state = {
      memberDiseases: this.memberDiseases,
      showShowMoreButton: false,
      showDoneButton: true,
      diseasesList: null,
      diseaseLoaded: false,
      shownDiseases: null,
      hideDisease: null,
    }

    stackRouterRoot = getStackRouterRoot(this.props.route)
  }

  async componentDidMount() {
    const headers = this.proband.account.authCredentials
    const payload = {
      disease_type: this.diseaseCategory,
    }
    const { saveError } = this.props

    const apiPayload = {
      method: 'post',
      path: 'disease/proband_list/',
      token: headers.accessToken,
      body: payload,
    }

    const response = await ApiFetchHandler(apiPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    if (response.status == 200) {
      const { diseases } = response.data
      const genderFilteredDiseases = diseases.filter(
        (item) =>
          item.disease_type === this.diseaseCategory &&
          (item.gender_specific === this.memberGender ||
            item.gender_specific === null)
      )

      shownDiseases = genderFilteredDiseases.filter(
        (item) => item.show != 'expanded'
      )
      hiddenDiseases = genderFilteredDiseases.filter(
        (item) => item.show == 'expanded'
      )

      this.memberDiseases.forEach((memberDisease) =>
        hiddenDiseases.forEach((disease) => {
          if (memberDisease.disease_id === disease.id) {
            this.setState({ showShowMoreButton: true })
          }
        })
      )

      this.setState({
        diseasesList: diseases,
        shownDiseases,
        hiddenDiseases,
        diseaseLoaded: true,
      })
    }
  }

  renderDiseaseList = (itemData) => (
    <View
      style={{
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <DiseaseListButton
        navNextScreen={this.handleNavigateNextScreen}
        diseaseData={itemData.item}
        memberData={this.state.memberDiseases}
        containerStyle={{ paddingHorizontal: 16 }}
        isSkipLogic={false}
        removeMemberDisease={this.onDeleteMemberDisease}
      />
    </View>
  )

  // update member disease
  onUpdateMemberDisease = (data) => {
    const { memberDiseases } = this.state

    const objIndex = memberDiseases?.findIndex(
      (obj) => obj.disease_id === data.disease_id
    )

    // if disease exist
    if (objIndex > -1) {
      const memberDiseaseId = memberDiseases[objIndex].disease_id
      const filteredMemberDiseases = memberDiseases?.filter(
        (item) => item.disease_id !== memberDiseaseId
      )

      filteredMemberDiseases.push(data)
      this.setState({ memberDiseases: filteredMemberDiseases })
    } else {
      memberDiseases.push(data)
      this.setState({ memberDiseases })
    }
  }

  // delete member disease
  onDeleteMemberDisease = (diseaseId) => {
    const { memberDiseases } = this.state

    const filteredMemberDiseases = memberDiseases?.filter(
      (item) => item.disease_id !== diseaseId
    )

    this.setState({ memberDiseases: filteredMemberDiseases })
  }

  handleNavigateNextScreen = (disease, memberDisease) => {
    stackRouterRoot.navigate('EditMemberDisease', {
      memberDisease,
      diseaseList: disease,
      onUpdateMemberDisease: this.onUpdateMemberDisease,
      memberProfile: this.memberProfile,
      proband: this.proband,
      existingMemberDiseases: this.existingMemberDiseases,
      onDismiss: this.onDismiss,
    })
  }

  handleShowButton = () => {
    const { hiddenDiseases, showShowMoreButton } = this.state

    if (hiddenDiseases?.length < 1) {
      return
    }

    return (
      <EllipseButton
        buttonStyle={{
          width: '35%',
          height: 40,
          marginVertical: 10,
          marginBottom: 20,
          alignSelf: 'center',
          backgroundColor: showShowMoreButton
            ? this.colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
            : this.colorSettings?.selectable_btn_inactive_1,
          borderColor: showShowMoreButton
            ? this.colorSettings?.selectable_btn_border_active_1
            : this.colorSettings?.selectable_btn_border_inactive_1,
        }}
        onPress={() => {
          this.setState((prevState) => ({
            showShowMoreButton: !prevState.showShowMoreButton,
          }))
        }}
      >
        <Text
          style={[
            styles.showButtonText,
            {
              color: this.colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {!showShowMoreButton
            ? i18n.t('show_more').capitalize
            : i18n.t('show_less').capitalize}
        </Text>
      </EllipseButton>
    )
  }

  headerComponent = () => (
    <View
      style={[
        global_styles.content,
        {
          alignItems: 'center',
          marginVertical: 48,
          paddingHorizontal: 16,
        },
      ]}
    >
      <View style={{ marginBottom: 20 }}>
        <Text
          style={[
            global_styles.titleText,
            {
              color: this.colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {this.title}
        </Text>
      </View>
      <View>
        <Text
          style={[
            global_styles.titleHelperText,
            {
              color: this.colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {i18n.t('select_a_disease').default}
        </Text>
      </View>
    </View>
  )

  onDone = () => {
    this.updateMainMemberDisease(
      this.state.memberDiseases,
      this.diseaseCategory
    )

    this.props.navigation.getParent().goBack()
  }

  footerComponent = () => (
    <View style={{ width: '100%' }}>
      {this.handleShowButton()}
      {this.handleHiddenDisease()}
    </View>
  )

  handleHiddenDisease = () => {
    if (!this.state.showShowMoreButton) {
      return
    }

    let containerHeight = global_styles.contentContainerFullHeight
    if (Platform.OS === 'web') {
      containerHeight = { height: 'auto' }
    }

    return (
      <FlatList
        keyExtractor={(item) => `${item.id}`}
        data={this.state.hiddenDiseases}
        style={[containerHeight, { width: '100%' }]}
        contentContainerStyle={{ width: '100%' }}
        onScroll={this.handleScroll}
        renderItem={this.renderDiseaseList}
      />
    )
  }

  isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 100
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    )
  }

  handleScroll = (event) => {
    const CustomLayoutLinear = {
      duration: 100,
      create: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity,
      },
      update: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity,
      },
      delete: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity,
      },
    }

    const currentOffset = event.nativeEvent.contentOffset.y
    let showDoneButton

    if (currentOffset > this._scrollOffset || currentOffset === 0) {
      // scroll down or on top of ScrollView
      showDoneButton = false
    } else {
      // scroll up
      showDoneButton = true
    }

    if (
      showDoneButton !== this.state.showDoneButton &&
      (!showDoneButton || currentOffset > 5)
    ) {
      LayoutAnimation.configureNext(CustomLayoutLinear)
      this._scrollOffset = currentOffset
      this.setState({ showDoneButton })
    }

    if (this._scrollOffset <= 0) {
      this.setState({ showDoneButton: true })
    }

    if (this.isCloseToBottom(event.nativeEvent)) {
      this.setState({ showDoneButton: false })
    }

    this._scrollOffset = currentOffset
  }

  handleDoneButton = () => (
    <View
      style={{
        height: 60,
        width: '100%',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      <View style={{ height: 60, width: '100%' }}>
        <TouchableOpacity
          style={[
            global_styles.nextButton,
            {
              backgroundColor:
                this.colorSettings?.bottom_next_btn_enabled || 'white',
              borderColor: this.colorSettings?.bottom_next_btn_enabled,
            },
          ]}
          onPress={this.onDone}
        >
          <Text
            style={[
              global_styles.nextButtonText,
              {
                color: this.colorSettings?.btn_filled_text_1,
              },
            ]}
          >
            {i18n.t('done').title}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )

  onLoadComponent = () => {
    if (!this.state.diseaseLoaded) {
      return (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <LoadingView
            backgroundColor={this.colorSettings?.onboarding_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_text_2}
            textColor={this.colorSettings?.btn_no_fill_text_2}
            visible
          />
        </View>
      )
    }
    return (
      <FlatList
        keyExtractor={(item, index) => `${item.id}`}
        data={this.state.shownDiseases}
        style={{ width: '100%' }}
        contentContainerStyle={{ width: '100%' }}
        ListHeaderComponent={this.headerComponent}
        ListFooterComponent={this.footerComponent}
        onScroll={this.handleScroll}
        ListFooterComponentStyle={{ width: '100%' }}
        renderItem={this.renderDiseaseList}
      />
    )
  }

  render() {
    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor,
          },
        ]}
      >
        <View style={{ flex: 1, justifyContent: 'center' }}>
          {this.onLoadComponent()}
        </View>
        {this.handleDoneButton()}
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  showButtonText: {
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    textAlign: 'center',
  },
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
    width: '100%',
  },
  contentContainer: {
    // backgroundColor: "red",
    // marginHorizontal: 18,
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    diseaseCategory: store.diseaseCategory,
    diseaseCategoryList: store.diseaseCategoryList,
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapGlobalStateToProps, mapDispatchToProps)(DiseasesList)
