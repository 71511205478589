import { useEffect, useState } from 'react'
import { View } from 'react-native'
import uuid from 'react-native-uuid'
import PropTypes from 'prop-types'

import { i18n } from '../../../../../localization/config.js'
import { ButtonSubView } from '../../../../../components/button_sub/index.js'
import { DecreaseFontSize, LabelHandler } from '../../../helpers/index.js'

export default function SingleSelectField({
  masterQuestion,
  answer,
  saveCompletedSurveyAnswer,
  colorSettings,
}) {
  const {
    question_label: questionLabel,
    master_question_choices: masterQuestionChoices,
  } = masterQuestion

  const [userOption, setUserOption] = useState()

  useEffect(() => {
    if (!answer) return
    let getAnswer

    masterQuestionChoices.forEach((element) => {
      if (answer.master_question_choice_id === element.id) {
        getAnswer = element
      }
    })

    setUserOption(getAnswer)
  }, [answer])

  const styleHandler = (selectedItem) => {
    if (selectedItem?.id === userOption?.id) {
      return {
        backgroundColor:
          colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)',
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  const buildPayload = (choiceId, repeatClick) => {
    const payload = {
      field_type: 'select_one',
      master_question_id: masterQuestion.id,
      master_question_choice_id: choiceId,
      answer: null,
      repeat_click: repeatClick,
    }

    return payload
  }

  const handleSelectedItem = (item) => {
    if (item.id !== userOption?.id || !userOption) {
      setUserOption(item)
      const payload = buildPayload(item.id, false)
      saveCompletedSurveyAnswer(payload)
      return
    }
    setUserOption('')
    const payload = buildPayload(item.id, true)
    saveCompletedSurveyAnswer(payload)
  }

  return (
    <View>
      {LabelHandler({
        questionLabel: i18n.t(questionLabel).default || questionLabel,
        colorSettings,
        masterQuestion,
      })}

      {masterQuestionChoices.map((item) => {
        const translationKey = `${item.translation_key}.choice`
        let choiceLabelTranslation = i18n.t(translationKey)

        if (
          choiceLabelTranslation === translationKey ||
          !choiceLabelTranslation
        ) {
          choiceLabelTranslation = item.choice
        }

        return (
          <ButtonSubView
            key={uuid.v4()}
            colorSettings={colorSettings}
            onPress={() => handleSelectedItem(item)}
            styleHandler={() => styleHandler(item)}
            fontSizeHandler={() => DecreaseFontSize(item.choice)}
            title={choiceLabelTranslation}
          />
        )
      })}
    </View>
  )
}

SingleSelectField.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.objectOf(PropTypes.string),
  answer: PropTypes.number,
  saveCompletedSurveyAnswer: PropTypes.func,
}

SingleSelectField.defaultProps = {
  colorSettings: [],
  answer: '',
  saveCompletedSurveyAnswer: () => {},
}
