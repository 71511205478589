import { useCallback, useEffect, useState } from 'react'
import {
  View,
  ScrollView,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { useSelector } from 'react-redux'
import uuid from 'react-native-uuid'

import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { WEB } from '$constants/Platforms.js'
import FlexContainer from '$components/flex_container'
import LabelField from './components/fields/label-field/LabelField'
import ButtonDone from '$components/button_done'
import { i18n } from '$localization/config.js'

import TextField from './components/fields/text-field/TextField'
import MultiSelectField from './components/fields/multi-select-field/MultiSelectField'
import ButtonToggle from '$components/button_toggle'
import SingleSelectField from './components/fields/single-select-field/SingleSelectField'
import NumberField from './components/fields/number-field/NumberField'
import DateField from './components/fields/date-field/DateField'
import sampleData from './components/mockData'
import styles from './CustomInputScreen.style'

let setTitle = 'Custom Screen'

const CustomInputScreen = ({ navigation: { setParams } }) => {
  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const screenResizeHandler = useCallback(() => {
    setScrollViewHeight(DefaultFullHeight())
  }, [])

  useEffect(() => {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', screenResizeHandler)
    }

    return () => {
      if (WEB === Platform.OS) {
        window.removeEventListener('resize', screenResizeHandler)
      }
    }
  })

  useEffect(() => {
    setParams({
      cs: colorSettings,
    })

    setTitle = sampleData?.header_title ?? ''
  }, [])

  const handleInputFields = ({ inputItem }) => {
    let returnField

    switch (inputItem.type) {
      case 'text':
        returnField = (
          <TextField
            inputFieldData={inputItem}
            colorSettings={colorSettings}
            keyboardType="email-address"
          />
        )
        break
      case 'toggle':
        returnField = (
          <ButtonToggle
            inputFieldData={inputItem}
            colorSettings={colorSettings}
            subContainerStyle={styles.toggleField}
          />
        )
        break
      case 'multi-select':
        returnField = (
          <MultiSelectField
            inputFieldData={inputItem}
            colorSettings={colorSettings}
          />
        )
        break
      case 'single-select':
        returnField = (
          <SingleSelectField
            inputFieldData={inputItem}
            colorSettings={colorSettings}
          />
        )
        break
      case 'number':
        returnField = (
          <NumberField
            inputFieldData={inputItem}
            colorSettings={colorSettings}
          />
        )
        break
      case 'date':
        returnField = (
          <DateField inputFieldData={inputItem} colorSettings={colorSettings} />
        )
        break
      default:
        return <View />
    }

    return returnField
  }

  const customFieldBuilder = () => {
    const { custom_categories } = sampleData

    return custom_categories.map((inputItem) => (
      <View style={styles.fieldContainer} key={uuid.v4()}>
        {handleInputFields({ inputItem })}
      </View>
    ))
  }

  return (
    <KeyboardAvoidingView behavior="padding" enabled>
      <SafeAreaView>
        <ScrollView
          style={{
            height: scrollViewHeight,
            backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
          }}
        >
          <FlexContainer style={styles.flexContainer}>
            {Boolean(sampleData?.sub_title) && (
              <LabelField
                title={sampleData.sub_title}
                colorSettings={colorSettings}
              />
            )}
            {customFieldBuilder()}
          </FlexContainer>
        </ScrollView>
        <ButtonDone
          title={i18n.t('next').default}
          onPress={() => {}}
          colorSettings={colorSettings}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

CustomInputScreen.navigationOptions = ({ navigation: { getParam } }) => {
  const colorSettings = getParam('cs')

  return {
    title: setTitle,
    headerTitleAlign: 'center',
    headerStyle: {
      backgroundColor: colorSettings?.onboarding_nav_bgcolor || 'white',
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
      shadowOpacity: 0,
      boxShadow: '',
    },
    headerTintColor: colorSettings?.nav_title_light || 'black',
  }
}

// CustomInputScreen.propTypes = {
//   navigation: PropTypes.shape({
//     setParams: PropTypes.func,
//   }),
// }

CustomInputScreen.defaultProps = {
  navigation: {},
}

export default CustomInputScreen
