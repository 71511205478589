import { handleCollapseSectionsWhileSearchingMember } from './collapsibleSectionsHelpers'
import { excludedRelationships } from '../constants/excludedRelationships'

export const getSearchedFamilyMember = (object, value) => {
  const result = []

  object.forEach((member) => {
    const currentMember = member
    if (
      currentMember?.is_blood_related_to_proband ||
      excludedRelationships.includes(currentMember?.is_blood_related_to_proband)
    )
      currentMember.children = []

    if (currentMember?.relationship_to_proband === 'partner')
      currentMember.partners = []

    const ZERO = 0
    const hasCurrentFamilyMemberChildren = currentMember.children?.length > ZERO
    const hasCurrentFamilyMemberPartners = currentMember.partners?.length > ZERO
    const isCurrentMemberNameMatchWithSearchedValue = currentMember?.name
      .toLowerCase()
      .startsWith(value.toLowerCase())

    if (isCurrentMemberNameMatchWithSearchedValue) {
      result.push({ ...currentMember, partners: [] })
    }
    if (hasCurrentFamilyMemberPartners) {
      const correctPartners = currentMember.partners.filter(
        (partner) =>
          !excludedRelationships.includes(partner.relationship_to_proband)
      )

      result.push(...getSearchedFamilyMember(correctPartners, value))
    }
    if (hasCurrentFamilyMemberChildren) {
      result.push(...getSearchedFamilyMember(currentMember.children, value))
    }
  })

  return result
}

export const onSearchFamilyList = (
  value,
  setFilteredFamilyData,
  familyData,
  setExpandedSections
) => {
  const TWO = 2
  const ZERO = 0

  if (value.length < TWO) return setFilteredFamilyData([...familyData])

  const newFamilyData = familyData.map((familyType) => {
    const { title } = familyType
    const searchedMember = getSearchedFamilyMember(familyType.data, value)

    if (searchedMember?.length > ZERO) {
      handleCollapseSectionsWhileSearchingMember(title, setExpandedSections)
    }

    return {
      title,
      data: searchedMember,
    }
  })

  return setFilteredFamilyData(newFamilyData)
}
