import * as Localization from 'expo-localization'
import i18n from 'i18n-js'
import { Platform } from 'react-native'
import enTranslation from './lang/en'
import frTranslation from './lang/fr'
import esTranslation from './lang/es'
import nlTranslation from './lang/nl'
import deTranslation from './lang/de'

// TODO: Update this library to the latest version, for now is updated to the latest working version with this signature

const translations = {
  en: enTranslation,
  fr: frTranslation,
  es: esTranslation,
  nl: nlTranslation,
  de: deTranslation,
}

// Note: Comment this line to disable getting of device's region settings.
// Example value: i18n.locale = "en-US";
export const availableValues = ['en', 'fr', 'es', 'nl', 'de']
const expoLocaleString = Localization.locale.substring(0, 2)
const defaultLocale = 'en-US'
const isWeb = Platform.OS === 'web'
const isValidExpoLocale = availableValues.includes(expoLocaleString)

function geti18nLocale() {
  if (!isValidExpoLocale) return defaultLocale

  const locale = Localization.locale ? Localization.locale : defaultLocale

  if (isValidExpoLocale && isWeb) {
    const localStorageCurrentLanguage = localStorage
      .getItem('currentLanguage')
      ?.substring(0, 2)

    const webLocalStorageCurrentLanguage = localStorageCurrentLanguage || locale

    return webLocalStorageCurrentLanguage
  }

  return locale
}

i18n.locale = geti18nLocale()

i18n.fallbacks = true
i18n.translations = translations

const { locale } = i18n
const { isRTL } = Localization
const { region } = Localization
const { timezone } = Localization
const { locales } = Localization
const currentLanguage = `${locale}`.split('-')[0]
const currentRegion = `${locale}`.split('-')[1]

console.groupCollapsed('%cLocalization settings:', 'color: orange')
console.log(`LOCALIZATION.LOCALE:\n  ${locale}\n`)
console.log(`LOCALIZATION.REGION:\n  ${region}\n`)
console.log(`LOCALIZATION.TIMEZONE:\n  ${timezone}\n`)
console.log(`LOCALIZATION.ISRTL:\n  ${isRTL}\n`)
console.log(`LOCALIZATION.LOCALES:\n  ${locales}\n`)
console.log(`CURRENT LANGUAGE:\n  ${currentLanguage}\n`)
console.log(`CURRENT REGION:\n  ${currentRegion}\n`)
console.groupEnd()

export {
  i18n,
  translations,
  isRTL,
  locale,
  region,
  timezone,
  currentLanguage,
  currentRegion,
}
