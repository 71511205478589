import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import globalStyles from '$constants/styles/global.styles.js'

function NextButtonAbsolute({
  onPressAction = () => {},
  isDisabled = false,
  buttonText,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const toggleNextButtonColor = () => {
    const enabledBackgroundColor =
      colorSettings?.bottom_next_btn_enabled ?? 'white'
    const enabledBorderColor = colorSettings?.bottom_next_btn_enabled

    const disabledBackgroundColor =
      colorSettings?.btn_filled_disabled_1 ?? 'white'
    const disabledBorderColor = colorSettings?.btn_filled_disabled_1

    return {
      backgroundColor: isDisabled
        ? disabledBackgroundColor
        : enabledBackgroundColor,
      borderColor: isDisabled ? disabledBorderColor : enabledBorderColor,
    }
  }

  return (
    <View style={globalStyles.nextButtonContainerAbsolute}>
      <TouchableOpacity
        style={[globalStyles.nextButton, toggleNextButtonColor()]}
        onPress={onPressAction}
        disabled={isDisabled}
      >
        <Text
          style={[
            globalStyles.nextButtonText,
            {
              color: isDisabled
                ? colorSettings?.bottom_next_btn_text_disabled
                : colorSettings?.bottom_next_btn_text_enabled,
            },
          ]}
        >
          {buttonText ?? i18n.t('next').default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default NextButtonAbsolute
