import { Text } from 'react-native'
import { useRoute } from '@react-navigation/core'
import FlexContainer from '$components/flex_container'
import globalStyles from '$constants/styles/global.styles.js'
import styles from '../styles'

function ScreeningWorkflowHeaderText() {
  const route = useRoute()
  const customText = route.params?.nextScreenText?.bottomText

  const headerText =
    customText?.length > 0
      ? customText
      : 'Select each family member who had cancer and add their diagnosis in the disease section. If you need to add a person, use the plus button.'

  return (
    <FlexContainer style={globalStyles.contentContainerPadded}>
      <Text style={styles.screeningWorkflowHeaderText}>{headerText}</Text>
    </FlexContainer>
  )
}

export default ScreeningWorkflowHeaderText
