import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
  SafeAreaView,
  Platform,
  StatusBar,
  Keyboard,
  Animated,
  Dimensions,
} from 'react-native'

import { connect } from 'react-redux'

import LoadingView from '$constants/LoadingView.js'
import PhoneInputView from '$components/phone-input-view'
import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'

import { isEmptyObject, hasInputMask } from '$api/_utils/Utils.js'

import { setDialingCode, setDialingNumber } from '$redux/account/actions'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'

import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'

import { setError } from '$redux/defaults/actions.js'
import { IS_WEB, IOS } from '$constants/Platforms.js'

import { i18n } from '$localization/config.js'
import addPhoneNumber from '$screens/_utils/addPhoneNumber'

const inputAccessoryViewID = 'SignupScreenPhone'

class SignupScreenPhone extends Component {
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  static navigationOptions = ({ navigation }) => {
    const options = DefaultNavigationOptions({
      navigation,
      hasRightPlaceholderButton: true,
    })

    return options
  }

  // DONE: switch to this.props.account
  account = this.props.account

  colorSettings = this.props.colorSettings

  phoneNumber = ''

  formattedPhoneNumber = ''

  numSet1 = ''

  numSet2 = ''

  numSet3 = ''

  contentMarginTop = new Animated.Value(Dimensions.get('window').height / 4)

  appSettings = this.props.appSettings

  constructor(props) {
    super(props)
    this.constructor.setSharedInstance(this)
    if (Platform.OS === 'ios') {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardWillShow',
        this._keyboardIsActive
      )
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardWillHide',
        this._keyboardDismissed
      )
    } else {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        this._keyboardIsActive
      )
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this._keyboardDismissed
      )
    }

    this.state = {
      showLoadingView: false,
      phoneNumber: '',
      formattedPhoneNumber: '',
      countryPickerVisible: false,
      cca2: this.appSettings.default_country_code,
      phonePrefix: this.appSettings.default_dialing_code,
      scrollViewHeight: DefaultFullHeight(),
    }

    if (this.colorSettings == null) {
      this.colorSettings = this.props.colorSettings
    }
  }

  componentDidMount() {
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
      // scrollToTop: this._scrollToTop
    })
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  animatedContentMarginTop(toValue, delay = 0) {
    Animated.timing(this.contentMarginTop, {
      toValue,
      duration: 300,
      delay,
    }).start()
  }

  _keyboardIsActive = () => {
    const height = Dimensions.get('window').height / 4 / 4
    this.animatedContentMarginTop(height)
  }

  _keyboardDismissed = () => {
    const height = Dimensions.get('window').height / 4
    this.animatedContentMarginTop(height)
  }

  nextButtonAction = async () => {
    await this.addPhoneNumber()
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    let style = {
      backgroundColor:
        this.colorSettings?.bottom_next_btn_disabled || 'transparent',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }
    const isValid = this.validateInput()
    if (isValid) {
      style = {
        backgroundColor:
          this.colorSettings?.bottom_next_btn_enabled || 'transparent',
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return style
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const enabled = this.validateInput()
    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  validateInput = () => {
    if (this.phoneNumber && this.phoneNumber.length >= 5) {
      return true
    }
    return false
  }

  phoneNumberDidChanged = (value) => {
    this.phoneNumber = value.replace(/[^A-Z0-9]/gi, '')

    const len = this.phoneNumber.length
    this.formattedPhoneNumber = this.phoneNumber

    if (!hasInputMask(this.state.cca2)) {
      this.setState({
        formattedPhoneNumber: this.formattedPhoneNumber,
      })
      return
    }

    if (len <= 10) {
      this.numSet1 = this.phoneNumber.substring(0, 3).trim()
      this.numSet2 = this.phoneNumber.substring(3, 6).trim()
      this.numSet3 = this.phoneNumber.substring(6).trim()
    }

    if (len === 11) {
      this.numSet1 = this.phoneNumber.substring(0, 3).trim()
      this.numSet2 = this.phoneNumber.substring(3, 7).trim()
      this.numSet3 = this.phoneNumber.substring(7).trim()
    }

    if (len === 12) {
      this.numSet1 = this.phoneNumber.substring(0, 4).trim()
      this.numSet2 = this.phoneNumber.substring(4, 8).trim()
      this.numSet3 = this.phoneNumber.substring(8).trim()
    }

    this.formattedPhoneNumber = this.numSet1

    if (len > 3) {
      this.formattedPhoneNumber = `(${this.numSet1})` + ` ${this.numSet2}`
    }
    if (len > 6) {
      this.formattedPhoneNumber =
        `(${this.numSet1})` + ` ${this.numSet2} ${this.numSet3}`
    }

    this.setState({
      formattedPhoneNumber: this.formattedPhoneNumber,
    })
  }

  phoneNumberDidEndEditing = () => {
    // Nothing to do here
    Keyboard.dismiss()
  }

  onPressFlag = () => {
    this.toggleCountryPicker(true)
  }

  toggleCountryPicker = (visible = false) => {
    this.setState({ countryPickerVisible: visible })
  }

  countryPickerOnSelect = (country) => {
    const _cca2 = country.cca2.toLowerCase()
    this.phone.selectCountry(_cca2)
    this.setState(
      {
        cca2: _cca2,
        phonePrefix: country.callingCode[0],
      },
      function () {
        this.phoneNumberDidChanged(this.phoneNumber)
      }
    )
  }

  getSnapshotBeforeUpdate(prevProps) {
    return {
      beforeUpdate: prevProps.addPhoneNumber !== this.props.addPhoneNumber,
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.beforeUpdate) {
      await addPhoneNumber()
    }
  }

  showLoading = (isShown) => {
    this.setState({ showLoadingView: isShown })
  }

  addPhoneNumber = async (shouldNext = true) => {
    const {
      navigation,
      colorSettings,
      saveError,
      _setDialingCode_,
      _setDialingNumber_,
    } = this.props
    const { phonePrefix } = this.state
    Keyboard.dismiss()

    /* Prepare API request to add phone number. */
    this.setState({ showLoadingView: true })

    // Get Phone number and Dialing code.
    // DONE: set to actions
    _setDialingCode_(phonePrefix)
    _setDialingNumber_(this.phoneNumber)

    let language = i18n.locale
    language = language.substr(0, 2)

    const payload = {
      dialing_code: phonePrefix,
      phone_number: this.phoneNumber,
      email: this.account.email,
      password: this.account.password,
      first_name: this.account.firstName,
      last_name: this.account.lastName,
      dob: this.account.dob,
      lang: language,
    }

    await addPhoneNumber(
      shouldNext,
      payload,
      this.showLoading,
      navigation,
      colorSettings,
      saveError
    )
  }

  render() {
    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor || 'white',
          },
        ]}
      >
        {Platform.OS === IOS && <StatusBar barStyle="dark-content" />}

        <ScrollView
          nativeID="web_scaled_main_container"
          style={{ height: this.state.scrollViewHeight }}
          contentContainerStyle={{ alignItems: 'center' }}
        >
          <Animated.View
            style={[
              global_styles.contentContainerFullHeight,
              {
                justifyContent: 'flex-start',
                paddingTop: this.contentMarginTop,
              },
            ]}
          >
            <View style={global_styles.titleTextContainer}>
              <Text
                style={[
                  global_styles.titleText,
                  {
                    color:
                      this.colorSettings?.text_input_title_color_2 || 'black',
                  },
                ]}
              >
                {i18n.t('what_is_your_phone_number').default}
              </Text>
            </View>

            <View
              // nativeID="phone_input_row_signup_process"
              style={{
                // borderWidth: 1.0,
                marginVertical: 32,
                flexDirection: 'row',
                alignItems: 'center',
                height: 50,
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <View>
                <PhoneInputView
                  // textColor={this.colorSettings?.text_input_title_color_2}
                  // colorSettings={this.colorSettings}
                  prefixTextColor={this.colorSettings?.text_input_title_color_2}
                  sender={this}
                  initialCountryCode={this.state.cca2}
                  countryPickerVisible={this.state.countryPickerVisible}
                />
              </View>

              <View
                style={{
                  // borderWidth: 1.0,
                  marginLeft: 16,
                  height: '100%',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  flex: Platform.OS === 'web' ? null : 0.7,
                }}
              >
                <View
                  nativeID="phone_input_signup_process"
                  style={{
                    // borderWidth: 1.0,
                    flex: 1.0,
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <TextInput
                    nativeID="web_text_input"
                    style={[
                      global_styles.mainTextInput,
                      {
                        color:
                          this.colorSettings?.text_input_title_color_2 ||
                          'black',
                        // fontSize: (!hasInputMask(this.state.cca2) && this.state.formattedPhoneNumber === "") ? 15 : 20
                      },
                    ]}
                    inputAccessoryViewID={inputAccessoryViewID}
                    // placeholderTextColor={this.colorSettings?.selectable_btn_active_1}
                    placeholderTextColor={
                      this.colorSettings?.text_input_placeholder_color_2
                    }
                    placeholder={
                      hasInputMask(this.state.cca2)
                        ? '(000) 000 0000'
                        : i18n.t('enter_number').default
                    }
                    selectionColor={this.colorSettings?.text_input_color_2}
                    maxLength={16}
                    keyboardType="phone-pad"
                    onEndEditing={() => this.phoneNumberDidEndEditing()}
                    onBlur={() => this.phoneNumberDidEndEditing()}
                    onChangeText={(val) => this.phoneNumberDidChanged(val)}
                    value={this.state.formattedPhoneNumber}
                  />
                </View>
              </View>

              <View style={{ height: '100%', flex: 0.1 }} />
            </View>
          </Animated.View>
        </ScrollView>

        {/* <Next button> */}
        <View style={global_styles.nextButtonContainer}>
          <View
            style={[global_styles.nextButton, this.toggleNextButtonColor()]}
          >
            <TouchableOpacity
              disabled={this.toggleNextButton()}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('next').default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <NextButtonAccessoryView
          nativeID={inputAccessoryViewID}
          labelText={i18n.t('next').title}
          labelColor={this.toggleNextButtonText()}
          disabled={this.toggleNextButton()}
          labelText={i18n.t('next').title}
          style={[global_styles.nextButton, this.toggleNextButtonColor()]}
          onPress={this.nextButtonAction}
        />
        {/* </Next button> */}

        <LoadingView
          visible={this.state.showLoadingView}
          backgroundColor={this.colorSettings?.splash_bgcolor}
          tintColor={this.colorSettings?.btn_no_fill_border_1}
          textColor={this.colorSettings?.btn_no_fill_text_1}
        />
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    account: accountStore.account,
    addPhoneNumber: store.addPhoneNumber,
  }
}

const actionsCreators = {
  _setDialingCode_: setDialingCode,
  _setDialingNumber_: setDialingNumber,
  saveError: setError,
}

export default connect(mapStateToProps, actionsCreators)(SignupScreenPhone)
