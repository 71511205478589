import {
  SafeAreaView,
  View,
  ScrollView,
  Text,
  SectionList,
  RefreshControl,
} from 'react-native'

import { connect } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'

import { i18n } from '$localization/config.js'

import BaseController from '$components/BaseController.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$constants/LoadingView.js'

import styles from './_styles/main.styles'
import NotificationItem from './NotificationItem'

import AuthCredentials from '$data_models/AuthCredentials.js'
import Proband from '$data_models/Proband'

import ApiFetchHandler from '$api/ApiFetchHandler.js'
import { setError } from '$redux/defaults/actions.js'

import { showGenericServerError } from '$navigation/_utils/NavigationUtils.js'

import {
  clearNotificationsStoreAction,
  setNotificationsListAction,
  setSelectedNotificationAction,
  setShouldReloadNotificationsList,
} from '$redux/notifications/actions.js'

import globalStyles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'
import mainStyles from '$screens/main/dashboard/notifications/_styles/main.styles.js'
import { IS_WEB, IS_MOBILE } from '$constants/Platforms.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

class NotificationsMain extends BaseController {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  backgroundRefreshTimer = null

  constructor(props) {
    super(props)

    this.constructor.setSharedInstance(this)
    this.state = {
      ...this.state,
      listData: [],
    }
  }

  async componentDidMount() {
    const { navigation, colorSettings } = this.props
    navigation.setParams({
      cs: colorSettings,
    })

    await this.getMemberNotifications()

    this.setNavigationEventListener('focus')
  }

  setNavigationEventAction = async () => {
    const {
      shouldReloadNotificationsList,
      setShouldReloadNotificationsListRedux,
      navigation,
      colorSettings,
    } = this.props
    if (shouldReloadNotificationsList) await this.getMemberNotifications()

    setShouldReloadNotificationsListRedux(false)

    navigation.setParams({
      cs: colorSettings,
    })
  }

  getMemberNotifications = async () => {
    const { showLoadingView } = this.state
    const { setNotificationsListActionRedux, notificationsList } = this.props
    const probandID = this.proband?.probandID
    const notificationLanguage = i18n.currentLocale().slice(0, 2)

    if (!showLoadingView) this.reloadAndRenderView(true)

    if (!probandID) return

    const getMemberNotificationsPayload = {
      path: 'member/notification/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
        lang: notificationLanguage,
      },
      pageDetails: {
        page: 'Notifications.main.js',
      },
    }

    let response = await ApiFetchHandler(getMemberNotificationsPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      let errListData = []

      const rxNotificationList = notificationsList ?? []
      if (rxNotificationList.length > 0) {
        errListData = [{ title: 'Notifications', data: rxNotificationList }]
      }

      this.setState({ errListData })

      this.reloadAndRenderView(false)
      return
    }
    if (!response) response = []
    let listData = []

    if (response.length > 0) {
      listData = [{ title: 'Notifications', data: response }]
    }

    this.setState({ listData })

    setNotificationsListActionRedux(response)

    this.reloadAndRenderView(false)
  }

  navigateToNotificationDetail = (item) => {
    if (!item?.id) return
    const { setSelectedNotificationActionRedux, colorSettings } = this.props

    setSelectedNotificationActionRedux(item)

    this.navigateTo('NotificationDetail', {
      cs: colorSettings,
    })
  }

  onPressDismiss = async (notification) => {
    await this.setNotificationToArchive(notification.id)
  }

  setNotificationToArchive = async (notificationID) => {
    const { setShouldReloadNotificationsListRedux } = this.props
    if (!notificationID) return

    const probandID = this.proband?.probandID

    this.setNotificationList(notificationID)

    const setNotificationToArchivePayload = {
      path: `notification/${notificationID}/archive/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
      },
      pageDetails: {
        page: 'Notifications.main.js',
      },
    }

    const response = await ApiFetchHandler(setNotificationToArchivePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    setShouldReloadNotificationsListRedux(true)
  }

  acceptButtonAction = async (item) => {
    if (!item?.id) return

    const itemID = item?.id
    const probandID = this.proband?.probandID

    const headers = this.authCredentials
    const payload = {
      proband_id: probandID,
      user_id: this.account?.userID,
      response: 'accept',
    }

    await this.respondToNotification(itemID, payload, headers)
  }

  denyButtonAction = async (item) => {
    if (!item?.id) return

    const itemID = item?.id
    const probandID = this.proband?.probandID

    const headers = this.authCredentials
    const payload = {
      proband_id: probandID,
      user_id: this.account?.userID,
      response: 'deny',
    }

    await this.respondToNotification(itemID, payload, headers)
  }

  respondToNotification = async (itemID, payload, headers) => {
    const { setShouldReloadNotificationsListRedux } = this.props

    this.setNotificationList(itemID)

    const respondToNotificationPayload = {
      path: `notification/${itemID}/response/`,
      method: 'post',
      token: headers.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'Notifications.main.js',
      },
    }

    const response = await ApiFetchHandler(respondToNotificationPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    setShouldReloadNotificationsListRedux(true)
  }

  setNotificationList = (notificationID) => {
    const { listData } = this.state
    const notifications = listData[0].data
    const filteredListData = notifications.filter(
      (notification) => notification.id !== notificationID
    )

    const newNotification = [{ ...listData[0], data: filteredListData }]

    this.setState({
      listData: newNotification,
    })
  }

  renderItem = ({ item }) => {
    const { colorSettings } = this.props

    return (
      <NotificationItem
        colorSettings={colorSettings}
        onDismiss={() => this.onPressDismiss(item)}
        onAccept={() => this.acceptButtonAction(item)}
        onDeny={() => this.denyButtonAction(item)}
        item={item}
      />
    )
  }

  render() {
    const { navigation, colorSettings } = this.props
    const { listData, shouldRenderComponent, showLoadingView } = this.state

    let platformStyle = {}
    let safeAreaStyle = {}

    if (IS_WEB()) {
      platformStyle = {
        height: DefaultFullHeightForWeb(),
      }
      safeAreaStyle = {
        backgroundColor: 'rgb(245,245,245)',
        width: '100%',
        height: DefaultFullHeightForWeb(),
      }
    }

    if (IS_MOBILE()) {
      platformStyle = mainStyles.container
      safeAreaStyle = globalStyles.containerWhite
    }

    if (!shouldRenderComponent) {
      return (
        <SafeAreaView style={safeAreaStyle}>
          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            visible={showLoadingView}
          />
        </SafeAreaView>
      )
    }

    const ListContainer = () => {
      let renderedList = (
        <ScrollView
          style={[mainStyles.content]}
          contentContainerStyle={styles.scrollViewRenderListContainer}
          refreshControl={
            <RefreshControl
              refreshing={showLoadingView}
              onRefresh={() => this.getMemberNotifications()}
            />
          }
        >
          <Text
            style={[mainStyles.headerTitleText, styles.listDataHeaderContainer]}
          >
            {i18n.t('no_notifications_found')?.default}
          </Text>
        </ScrollView>
      )

      if (listData && listData.length > 0) {
        renderedList = (
          <ScrollView
            nativeID="web_scaled_main_container"
            style={mainStyles.container}
            contentContainerStyle={styles.scrollViewRenderListContainer2}
            refreshControl={
              <RefreshControl
                refreshing={showLoadingView}
                onRefresh={() => this.getMemberNotifications()}
              />
            }
          >
            <View style={mainStyles.content}>
              <SectionList
                sections={listData}
                keyExtractor={(item) => `${item?.id}`}
                renderItem={this.renderItem}
              />
            </View>
          </ScrollView>
        )
      }

      return renderedList
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView style={platformStyle}>
            <ListContainer />
            {showLoadingView && (
              <LoadingView
                backgroundColor={colorSettings?.splash_bgcolor}
                tintColor={colorSettings?.btn_no_fill_border_1}
                textColor={colorSettings?.btn_no_fill_text_1}
                visible={showLoadingView}
              />
            )}
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, notificationsStore } = state

  return {
    /** Default Store */
    allowBackgroundRefresh: store.allowBackgroundRefresh,
    backgroundRefreshInterval: store.backgroundRefreshInterval,
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    proband: probandStore.proband,
    /** Notifications Store */
    notificationsList: notificationsStore.notificationsList,
    selectedNotification: notificationsStore.selectedNotification,
    shouldReloadNotificationsList:
      notificationsStore.shouldReloadNotificationsList,
  }
}

const mapDispatchToProps = {
  clearNotificationsStoreActionRedux: clearNotificationsStoreAction,
  setNotificationsListActionRedux: setNotificationsListAction,
  setSelectedNotificationActionRedux: setSelectedNotificationAction,
  setShouldReloadNotificationsListRedux: setShouldReloadNotificationsList,
  saveError: setError,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMain)
