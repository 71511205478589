import { memo, Component } from 'react'
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Modal,
  SafeAreaView,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'
import ButtonShowMore from 'screens/personal_details/onboarding_disease_selection/components/button_show_more'
import { i18n } from '$localization/config.js'
import LoadingView from '$constants/LoadingView.js'

// components
import ButtonDone from '$components/button_done'
import ButtonWithList from '$screens/personal_details/onboarding_disease_selection/components/button_with_list'

import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { IS_WEB, WEB } from '$constants/Platforms.js'
import FlexContainer from '$components/flex_container'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'
import SearchBar from './components/search_bar/SearchBar'
import SeeMoreButton from './components/see_more_button/SeeMoreButton'
import translateDisease from '$screens/personal_details/relative_gene_test/_utils/TextTranslation.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'
import DiseaseCategories from '$screens/personal_details/onboarding_disease_selection/components/disease_sublist/DiseaseCategories.js'
// redux actions
import {
  setIndividualDisease,
  setScreenWhereToNavigate,
  setDiseaseProband,
} from '$redux/diseases/actions.js'
import { setError } from '$redux/defaults/actions.js'
// utils
import DiseaseList from './utils/diseaseList'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

const MemoizedButtonWithList = memo(ButtonWithList)

class DiseaseSubList extends Component {
  colorSettings = this.props.colorSettings

  diseaseList = this.props.diseaseList

  diseaseType = this.props.diseaseType

  proband = this.props.proband

  selectedDiseases = this.props.selectedDiseases

  selectedClinician = this.props.selectedClinician

  diseaseFromOnboarding = this.props.diseaseFromOnboarding

  authCredentials = this.props.authCredentials

  constructor(props) {
    super(props)

    this.state = {
      collapseShowMoreBtn: false,
      renderShowMoreBtn: this.diseaseType === 'cancer-polyps',
      modalVisible: false,
      selectedDiseases: this.selectedDiseases || [],
      diseaseList: null,
      diseaseListCopy: null,
      isLoading: true,
      scrollViewHeight: DefaultFullHeight(),
      searchValue: '',
      isHiddenShowMoreButton: false,
      isHiddenSeeMoreButton: true,
      completeDiseaseList: null,
      isEmptyDiseaseListMessageShown: false,
      isLoadingDiseases: false,
      isEnabledUmlsSearch: false,
      umlsSearchCount: 0,
      umlsSearchSize: 0,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedDiseases !== this.props.selectedDiseases) {
      this.setState({ selectedDiseases: this.props.selectedDiseases })
    }
  }

  async componentDidMount() {
    const { selectedDiseases, authCredentials, navigation } = this.props

    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    navigation.setParams({
      cs: this.props.colorSettings,
    })

    let payload = null

    if (this.diseaseFromOnboarding) {
      const clinicianId = this.selectedClinician
        ? this.selectedClinician?.clinician_code?.id
        : null
      payload = {
        mode: 'onboarding',
        clinician_id: clinicianId,
        disease_type: this.diseaseType,
      }
    } else {
      payload = {
        disease_type: this.diseaseType,
      }
    }

    const apiPayload = {
      method: 'post',
      path: 'disease/proband_list/',
      token: authCredentials?.accessToken,
      body: payload,
    }

    const { saveError } = this.props
    const response = await ApiFetchHandler(apiPayload)
    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    await this.fetchDiseasesAPI(response.diseases, selectedDiseases)
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  fetchDiseasesAPI = async (diseaseList, selectedDiseases) => {
    const { authCredentials, diseaseType, diseaseTypeByOrg, saveError } =
      this.props
    const listTypePayload = {
      path: `disease/?disease_type=${diseaseType}`,
      method: 'get',
      token: authCredentials?.accessToken,
    }

    const nonOrgDiseaseTypePayload = {
      path: 'diseases/disease_types/',
      method: 'get',
      token: authCredentials.accessToken,
    }

    const diseasesPayload = {
      path: 'disease/',
      method: 'get',
      token: authCredentials.accessToken,
    }

    const [fullListTypeDiseases, getDiseases, nonOrgDiseaseType] =
      await Promise.all([
        ApiFetchHandler(listTypePayload),
        ApiFetchHandler(diseasesPayload),
        ApiFetchHandler(nonOrgDiseaseTypePayload),
      ])

    if (fullListTypeDiseases.isError) {
      saveError({
        isShown: true,
        status: fullListTypeDiseases.status,
        message: fullListTypeDiseases.error,
      })
    }
    if (getDiseases.isError) {
      saveError({
        isShown: true,
        status: getDiseases.status,
        message: getDiseases.error,
      })
    }
    if (nonOrgDiseaseType.isError) {
      saveError({
        isShown: true,
        status: nonOrgDiseaseType.status,
        message: nonOrgDiseaseType.error,
      })
    }

    const filteredNonOrgDiseaseType = nonOrgDiseaseType.disease_types.filter(
      (type) => !diseaseTypeByOrg.includes(type)
    )

    const filteredDiseaseTypeList = diseaseTypeByOrg.filter(
      (type) => !nonOrgDiseaseType.disease_types.includes(type)
    )

    const getListDifference = [
      ...filteredNonOrgDiseaseType,
      ...filteredDiseaseTypeList,
      'other_conditions',
    ]

    const filterHiddenDisease = getDiseases.diseases.filter((fullListItem) =>
      getListDifference.some(
        (listDifferenceItem) => fullListItem.disease_type === listDifferenceItem
      )
    )

    const finalList =
      diseaseType === 'other_conditions'
        ? filterHiddenDisease
        : fullListTypeDiseases.diseases

    const generatedDiseaseList = this.getHiddenSelectedDisease(
      diseaseList,
      finalList,
      selectedDiseases
    )

    this.setState({
      collapseShowMoreBtn:
        this.handleShowMoreButtonOnMount(generatedDiseaseList),
      diseaseList: generatedDiseaseList,
      diseaseListCopy: generatedDiseaseList,
      isLoading: false,
      completeDiseaseList: finalList,
    })
  }

  getHiddenSelectedDisease = (
    shownDiseases,
    fullDiseaseList,
    selectedDiseases
  ) => {
    const setDiseases = []

    selectedDiseases.forEach((disease) => {
      const found = shownDiseases?.find(
        (currentDisease) => currentDisease.id === disease.disease_id
      )

      if (!found) {
        const selected = fullDiseaseList?.find(
          (currentSelectedDisease) =>
            currentSelectedDisease.id === disease.disease_id
        )
        setDiseases.push(selected)
      }
    })

    const newDiseaseList = shownDiseases?.concat(setDiseases)
    return this.alphabeticalSort(newDiseaseList)
  }

  alphabeticalSort = (diseaseList) => {
    const sortedDiseaseList = diseaseList?.sort((a, b) => {
      if (a.disease_name < b.disease_name) {
        return -1
      }
      if (a.disease_name > b.disease_name) {
        return 1
      }
      return 0
    })
    return sortedDiseaseList
  }

  getHiddenDiseaseList = (searchValue) => {
    const { completeDiseaseList } = this.state

    let isHiddenShowMoreButton
    let collapseShowMoreBtn =
      this.handleShowMoreButtonOnMount(completeDiseaseList)

    if (searchValue.length > 0) {
      isHiddenShowMoreButton = true
      collapseShowMoreBtn = true
    }

    this.setState({
      diseaseList: this.diseaseSearchQuery(searchValue, completeDiseaseList),
      isHiddenSeeMoreButton: false,
      isHiddenShowMoreButton,
      collapseShowMoreBtn,
      searchValue,
      isEnabledUmlsSearch: true,
      umlsSearchCount: 0,
      isLoadingDiseases: false,
      umlsSearchSize: 0,
    })
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  handleShowMoreButtonOnMount = (diseaseList) => {
    const { selectedDiseases } = this
    const selectedDiseaseIds = selectedDiseases.map((item) => item.disease_id)

    const cleanFilterDiseaseList = diseaseList.filter((disease) => disease)
    const checkShowDisease = cleanFilterDiseaseList.filter(
      (item) => selectedDiseaseIds.includes(item.id) && item.show === 'expanded'
    )
    return checkShowDisease.length !== 0
  }

  renderItems = (item) => {
    const { selectedDiseases } = this.state

    const diseaseList = new DiseaseList(selectedDiseases)
    const disease = diseaseList.get(item.id, item.umls_id)

    return (
      <MemoizedButtonWithList
        key={item.id}
        colorSettings={this.colorSettings}
        title={item?.disease_name}
        fromUmls={item.umls_search}
        toggled={!!disease}
        onPress={(onToggle) => this.handleButtonAction(item, onToggle)}
        data={disease}
        onEdit={() => this.handleButtonAction(item, true)}
      />
    )
  }

  handleButtonAction = (disease, onToggle) => {
    let destination

    if (onToggle) {
      this.props._saveIndividualDisease_(disease)
      this.props._setScreenWhereToNavigate_(false)
      if (this.diseaseFromOnboarding) {
        destination = 'DiseaseSkipLogic'
      } else {
        destination = 'DiseasesSkipLogicView'
      }

      this.props.navigation.navigate(destination)
    } else {
      const { selectedDiseases } = this.state
      const diseaseList = new DiseaseList(selectedDiseases)
      const updatedDisease = diseaseList.deleteDisease(disease.id)
      this.props._saveDiseaseProband_(updatedDisease.list)
    }
  }

  handleShowData = (isShown = true) => {
    const { currentSelectedMember, diseaseFromOnboarding, probandProfile } =
      this.props
    const { diseaseList } = this.state
    const diseases = diseaseList || []
    let filterShownDisease
    let filterGender
    const cleanDiseaseFilter = diseases?.filter((n) => n)

    if (isShown) {
      filterShownDisease = cleanDiseaseFilter?.filter(
        (disease) => disease.show === 'always' || !disease?.show
      )
    } else {
      filterShownDisease = cleanDiseaseFilter?.filter(
        (disease) => disease.show !== 'always' && disease?.show
      )
    }

    if (diseaseFromOnboarding) {
      filterGender = filterShownDisease?.filter(
        (disease) =>
          disease.gender_specific ===
            probandProfile?.profileData?.biologicalGender ||
          disease.gender_specific === null
      )
    } else {
      filterGender = filterShownDisease?.filter(
        (disease) =>
          disease.gender_specific === currentSelectedMember.gender ||
          disease.gender_specific === null
      )
    }
    return filterGender
  }

  renderDiseaseTitle = () => {
    const title = this.diseaseType
    let name = title
      .trim()
      .toLowerCase()
      .replace(/\//g, ' ')
      .replace(/[^\w-\s]/gi, '')
      .replace(/\s/g, '_')
      .replace(/_conditions/, '')

    if (!title?.includes('cancer') || !title?.includes('polyps')) {
      name += '_conditions'
    }

    return i18n.t(name).title
  }

  renderCollapsedDiseases = () => {
    if (this.state.collapseShowMoreBtn) {
      return this.renderList(false)
    }
  }

  renderList = (shownData) => {
    const data = this.handleShowData(shownData)
    const diseaseList = []

    for (const val in data) {
      diseaseList.push(this.renderItems(data[val]))
    }

    return diseaseList
  }

  diseaseSearchQuery = (searchValue, diseaseList) => {
    const itemLower = searchValue?.toLowerCase()
    const cleanFilter = diseaseList?.filter((diseases) => diseases)

    const diseaseQuery = cleanFilter?.filter((disease) => {
      let diseaseName = disease.disease_name
      diseaseName = translateDisease({ diseaseName, isLowerCased: true })

      return diseaseName.includes(itemLower)
    })

    return diseaseQuery
  }

  errorResponseHandler = (response) => {
    const { saveError } = this.props
    const { status, error } = response
    switch (status) {
      case 400:
      case 404:
        break

      default:
        saveError({
          isShown: true,
          status,
          message: error,
        })
    }
  }

  umlsSearch = async (searchValue, addUmlsDiseases = false) => {
    const { authCredentials, selectedLanguage } = this.props
    const { umlsSearchCount, diseaseList, umlsSearchSize } = this.state
    const umlsCount = addUmlsDiseases ? umlsSearchCount + 10 : 0

    this.setState({
      isLoadingDiseases: true,
    })

    if (!addUmlsDiseases) {
      this.setState({
        diseaseList: [],
      })
    }

    const language = {
      fr: 'FRE',
      en: 'ENG',
      nl: 'DUT',
      de: 'GER',
      es: 'SPA',
    }

    const payload = {
      path: `umls/search/?term=${searchValue.trim()}&start=${umlsCount}&lang=${
        language[selectedLanguage] || 'ENG'
      }`,
      method: 'get',
      token: authCredentials.accessToken,
    }

    const { saveError } = this.props

    const itemSearch = await ApiFetchHandler(payload)

    if (itemSearch.isError) {
      this.setState({
        isLoadingDiseases: false,
        isHiddenSeeMoreButton: true,
      })
      this.setEmptyDiseaseListMessage(searchValue)

      return this.errorResponseHandler(itemSearch)
    }

    if (!itemSearch) return
    const newDisease = addUmlsDiseases ? [...diseaseList] : []
    const umlsSearchSizeCount = umlsSearchSize + itemSearch.ConceptList.length
    const isHiddenSeeMoreButton = addUmlsDiseases
      ? itemSearch.ConceptSize <= umlsSearchSizeCount
      : itemSearch.ConceptSize <= 10

    itemSearch.ConceptList.forEach((umls) => {
      newDisease.push({
        id: umls.CUI,
        umls_id: umls.CUI,
        disease_name: umls.Name,
        show: 'always',
        gender_specific: null,
        skip_logistics: [],
        umls_search: true,
        umls_name: umls.Name,
        short_name: umls.Name,
      })
    })

    this.setState({
      isLoadingDiseases: false,
      searchValue,
      diseaseList: newDisease,
      isHiddenSeeMoreButton,
      isHiddenShowMoreButton: true,
      isEnabledUmlsSearch: true,
      umlsSearchCount: umlsCount,
      isEmptyDiseaseListMessageShown: itemSearch.ConceptSize <= 0,
      umlsSearchSize: umlsSearchSizeCount,
    })
  }

  handleOnChangeSearch = async (searchValue, diseaseList) => {
    const { diseaseListCopy } = this.state

    this.setState({
      isLoadingDiseases: true,
      diseaseList: [],
    })

    const { completeDiseaseList } = this.state

    const queryNonHidden = this.diseaseSearchQuery(searchValue, diseaseListCopy)
    const queryHiddenDiseases = this.diseaseSearchQuery(
      searchValue,
      completeDiseaseList
    )

    const diseaseDifference = [
      ...this.getDiseaseDifference(queryNonHidden, queryHiddenDiseases),
      ...this.getDiseaseDifference(queryHiddenDiseases, queryNonHidden),
    ]

    if (queryNonHidden?.length > 0) {
      this.getNonHiddenDiseaseList(
        searchValue,
        diseaseListCopy,
        diseaseDifference.length > 0,
        queryNonHidden
      )
    } else if (queryHiddenDiseases?.length > 0) {
      this.getHiddenDiseaseList(searchValue)
    } else {
      this.umlsSearch(searchValue)
    }
  }

  getDiseaseDifference = (queryNonHidden, queryHiddenDiseases) =>
    queryNonHidden?.filter(
      (nonHiddenDisease) =>
        !queryHiddenDiseases?.some(
          (hiddenDisease) => nonHiddenDisease.id === hiddenDisease.id
        )
    )

  setEmptyDiseaseListMessage = (item) => {
    this.setState({
      isEmptyDiseaseListMessageShown: true,
      searchValue: item,
      diseaseList: [],
      isHiddenSeeMoreButton: true,
      isHiddenShowMoreButton: true,
      isLoadingDiseases: false,
    })
  }

  getNonHiddenDiseaseList = (
    item,
    diseaseList,
    hasValueHiddenDiseaseQuery = false,
    diseaseSearchFiltered
  ) => {
    let isHiddenShowMoreButton
    const isHiddenSeeMoreButton = true
    let isEnabledUmlsSearch = false
    let collapseShowMoreBtn = this.handleShowMoreButtonOnMount(diseaseList)

    if (item?.length > 0) {
      isHiddenShowMoreButton = true
      collapseShowMoreBtn = true
      isEnabledUmlsSearch = !hasValueHiddenDiseaseQuery
    }

    this.setState({
      searchValue: item,
      diseaseList: diseaseSearchFiltered,
      isHiddenShowMoreButton,
      collapseShowMoreBtn,
      isHiddenSeeMoreButton: false,
      isEnabledUmlsSearch,
      isEmptyDiseaseListMessageShown: false,
      umlsSearchCount: 0,
      isLoadingDiseases: false,
      umlsSearchSize: 0,
    })
  }

  loadScreen = () => {
    const {
      isLoading,
      scrollViewHeight,
      searchValue,
      renderShowMoreBtn,
      collapseShowMoreBtn,
      diseaseListCopy,
      isHiddenShowMoreButton,
      isHiddenSeeMoreButton,
      isEmptyDiseaseListMessageShown,
      isLoadingDiseases,
      isEnabledUmlsSearch,
    } = this.state

    const { colorSettings } = this.props
    const getDiseaseList = isEnabledUmlsSearch
      ? () => this.umlsSearch(searchValue, true)
      : () => this.getHiddenDiseaseList(searchValue)

    if (isLoading) {
      return (
        <FlexContainer style={{ height: scrollViewHeight }}>
          <LoadingView
            backgroundColor={colorSettings?.onboarding_bgcolor}
            tintColor={colorSettings?.btn_no_fill_text_2}
            textColor={colorSettings?.btn_no_fill_text_2}
            visible={isLoading}
          />
        </FlexContainer>
      )
    }
    return (
      <ScrollView
        style={{ height: scrollViewHeight }}
        contentContainerStyle={styles.contentContainer}
      >
        <FlexContainer style={{ alignItems: 'center' }}>
          <Text
            style={[
              styles.titleText,
              {
                color: colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {this.renderDiseaseTitle()}
          </Text>
          <Text
            style={[
              styles.titleText,
              {
                fontSize: 18,
                color: colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {i18n.t('select_all_that_apply').default}
          </Text>
          <View
            style={[
              styles.buttonContainerAdopted,
              {
                flexDirection: 'column',
                marginBottom: 10,
                justifyContent: 'center',
              },
            ]}
          >
            <SearchBar
              colorSettings={colorSettings}
              searchValue={searchValue}
              onChange={(value) =>
                this.handleOnChangeSearch(value, diseaseListCopy)
              }
            />
            {this.renderList(true)}

            {!isHiddenShowMoreButton && (
              <ButtonShowMore
                onPress={(val) => this.setState({ collapseShowMoreBtn: val })}
                renderThis={renderShowMoreBtn}
                toggleState={collapseShowMoreBtn}
                colorSettings={colorSettings}
              />
            )}
            {this.renderCollapsedDiseases()}
            {!isHiddenSeeMoreButton && (
              <SeeMoreButton
                colorSettings={colorSettings}
                onPress={getDiseaseList}
              />
            )}
            {isEmptyDiseaseListMessageShown && (
              <View style={styles.emptyDiseaseListMessageContainer}>
                <Text
                  style={[
                    styles.emptyDiseaseListMessageText,
                    {
                      color: colorSettings?.text_input_label_color_2 || 'black',
                    },
                  ]}
                >
                  {
                    i18n.t(
                      'sorry_no_diseases_match_your_search_in_this_category_try_searching_in_the_other_conditions_category_for_more_options'
                    ).default
                  }
                </Text>
              </View>
            )}
            {isLoadingDiseases && (
              <LoadingView
                backgroundColor={colorSettings?.onboarding_bgcolor}
                tintColor={colorSettings?.btn_no_fill_text_2}
                textColor={colorSettings?.btn_no_fill_text_2}
                visible
                containerStyle={styles.containerStyleLoadingView}
              />
            )}
          </View>
        </FlexContainer>
      </ScrollView>
    )
  }

  render() {
    const { navigation, diseaseFromOnboarding, colorSettings } = this.props
    const {
      isLoading,
      scrollViewHeight,
      searchValue,
      renderShowMoreBtn,
      collapseShowMoreBtn,
      diseaseListCopy,
      isHiddenShowMoreButton,
      isHiddenSeeMoreButton,
      isEmptyDiseaseListMessageShown,
      isLoadingDiseases,
      isEnabledUmlsSearch,
      modalVisible,
    } = this.state

    const getDiseaseList = isEnabledUmlsSearch
      ? () => this.umlsSearch(searchValue, true)
      : () => this.getHiddenDiseaseList(searchValue)

    return (
      <MainWrapper
        navigation={navigation}
        disableLogout={diseaseFromOnboarding}
      >
        <SafeAreaView
          style={[
            styles.container,
            {
              backgroundColor:
                this.colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          <DiseaseCategories
            currentState={{
              isLoading,
              scrollViewHeight,
              searchValue,
              renderShowMoreBtn,
              collapseShowMoreBtn,
              diseaseListCopy,
              isHiddenShowMoreButton,
              isHiddenSeeMoreButton,
              isEmptyDiseaseListMessageShown,
              isLoadingDiseases,
              isEnabledUmlsSearch,
            }}
            currentProps={{
              colorSettings,
            }}
            renderDiseaseTitle={this.renderDiseaseTitle()}
            handleOnChangeSearch={(value) =>
              this.handleOnChangeSearch(value, diseaseListCopy)
            }
            renderList={this.renderList(true)}
            renderCollapsedDiseases={this.renderCollapsedDiseases()}
            getDiseaseList={getDiseaseList}
            buttonShowMoreOnPress={(val) =>
              this.setState({ collapseShowMoreBtn: val })
            }
          />
          {/* Next button */}
          <ButtonDone
            colorSettings={this.colorSettings}
            onPress={() => navigation.pop()}
            title={i18n.t('done').default}
          />

          {/* used by survey screen */}
          <Modal visible={modalVisible} transparent>
            <SafeAreaView
              style={{ flex: 1, backgroundColor: 'rgba(0,0,0,.6)' }}
            >
              <LoadingView
                backgroundColor={this.colorSettings?.onboarding_bgcolor}
                tintColor={this.colorSettings?.btn_no_fill_text_2}
                textColor={this.colorSettings?.btn_no_fill_text_2}
                visible
                message={i18n.t('saving').default}
              />
            </SafeAreaView>
          </Modal>
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
  },
  contentContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '80%',
  },
  buttonContainerAdopted: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  emptyDiseaseListMessageContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  emptyDiseaseListMessageText: {
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 17,
  },
  containerStyleLoadingView: {
    height: '100%',
    marginTop: 150,
  },
})

// <Redux functions>
function mapGlobalStateToProps(state) {
  const {
    store,
    diseaseStore,
    probandStore,
    clinicianStore,
    accountStore,
    memberProfileStore,
  } = state
  return {
    colorSettings: store.colorSettings,
    diseaseList: diseaseStore.selectedDisease.diseasesPerType,
    diseaseType: diseaseStore.selectedDisease.diseaseType,
    proband: probandStore.proband,
    probandProfile: probandStore.probandProfile,
    selectedDiseases: diseaseStore.probandDiseases,
    selectedClinician: clinicianStore.selectedClinician,
    diseaseFromOnboarding: diseaseStore.fromOnboarding,
    authCredentials: accountStore.authCredentials,
    currentSelectedMember: memberProfileStore.currentMember,
    selectedLanguage: store.selectedLanguage,
    diseaseTypeByOrg: diseaseStore.diseaseTypeByOrg,
  }
}

const actionCreators = {
  _saveIndividualDisease_: setIndividualDisease,
  _setScreenWhereToNavigate_: setScreenWhereToNavigate,
  _saveDiseaseProband_: setDiseaseProband,
  saveError: setError,
}

export default connect(mapGlobalStateToProps, actionCreators)(DiseaseSubList)
