export default function ConvertTextToBool(item) {
  if (!item) return
  if (typeof item !== 'string') {
    console.warn('convert error: not a string')
    return
  }

  if (item.toLowerCase() === 'true') return true
  return false
}
