import { Image } from 'react-native'
import { IS_MOBILE } from '$constants/Platforms'
import { DOMAIN_CLIENT } from '$constants/globalVariables.js'
import { imageURLs } from '$utils/load-images.js'

function LogoView({ logoSizes }) {
  let uri = DOMAIN_CLIENT === 'FAMGENIX' ? imageURLs.FAMGENIX.logo[2] : ''

  if (IS_MOBILE()) {
    uri = [uri]
  }

  const imgStyle = {
    resizeMode: 'contain',
    width: logoSizes.width,
    height: logoSizes.height,
  }

  return <Image style={imgStyle} source={uri} />
}

export default LogoView
