import * as actionTypes from './actionTypes'

const clearNotificationsStoreAction = () => ({
  type: actionTypes.CLEAR_NOTIFICATIONS_STORE,
})

const setNotificationsListAction = (data) => ({
  type: actionTypes.SET_NOTIFICATIONS_LIST,
  data,
})

const setSelectedNotificationAction = (data) => ({
  type: actionTypes.SET_SELECTED_NOTIFICATION,
  data,
})

/* Reloaders */
const setShouldReloadNotificationsList = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_NOTIFICATIONS_LIST,
  data,
})

export {
  clearNotificationsStoreAction,
  setNotificationsListAction,
  setSelectedNotificationAction,
  setShouldReloadNotificationsList,
}
