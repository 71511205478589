/* React & React Native libraries */
import { Text, View, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'

import styles from '$screens/relatives/_styles/main.styles.js'

const DefaultButton = { key: null, name: null }

const GenericHeaderControls = ({ ...props }) => {
  const colorSettings = props.colorSettings

  const primaryButtonAction = props.primaryButtonAction ?? function () {}

  const showTertiaryButton = props.showTertiaryButton ?? false

  const leftButton = props.leftButton ?? DefaultButton
  const rightButton = props.rightButton ?? DefaultButton
  const tertiaryButton = props.tertiaryButton ?? DefaultButton

  const buttonStyle = {
    height: '100%',
    width: 160,
  }

  return (
    <>
      <View style={styles.headerHalfRoundedButtonContainer}>
        <View style={buttonStyle}>
          <TouchableOpacity
            onPress={() =>
              primaryButtonAction({ relationshipToProband: leftButton?.key })
            }
            style={{ width: '100%' }}
          >
            <View
              style={[
                styles.headerHalfRoundedButton,
                {
                  borderColor: colorSettings?.btn_no_fill_border_2,
                },
              ]}
            >
              <Text
                style={[
                  styles.headerHalfRoundedButtonText,
                  {
                    color: colorSettings?.btn_no_fill_text_2,
                    fontSize: 25,
                  },
                ]}
              >
                {'+'}
              </Text>
              <Text
                style={[
                  styles.headerHalfRoundedButtonText,
                  {
                    color: colorSettings?.btn_no_fill_text_2,
                  },
                ]}
              >
                {i18n.t(leftButton?.key)?.default}
              </Text>
            </View>
          </TouchableOpacity>
        </View>

        <View style={{ width: 15 }} />

        <View style={buttonStyle}>
          <TouchableOpacity
            onPress={() =>
              primaryButtonAction({ relationshipToProband: rightButton?.key })
            }
            style={{ width: '100%' }}
          >
            <View
              style={[
                styles.headerHalfRoundedButton,
                {
                  borderColor: colorSettings?.btn_no_fill_border_2,
                },
              ]}
            >
              <Text
                style={[
                  styles.headerHalfRoundedButtonText,
                  {
                    color: colorSettings?.btn_no_fill_text_2,
                    fontSize: 25,
                  },
                ]}
              >
                {'+'}
              </Text>
              <Text
                style={[
                  styles.headerHalfRoundedButtonText,
                  {
                    color: colorSettings?.btn_no_fill_text_2,
                  },
                ]}
              >
                {i18n.t(rightButton?.key)?.default}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>

      {showTertiaryButton && (
        <View style={styles.headerHalfRoundedButtonContainer}>
          <View style={buttonStyle}>
            <TouchableOpacity
              onPress={() =>
                primaryButtonAction({
                  relationshipToProband: tertiaryButton?.key,
                })
              }
              style={{ width: '100%' }}
            >
              <View
                style={[
                  styles.headerFullRoundedButton,
                  {
                    borderColor: colorSettings?.btn_no_fill_border_2,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.headerFullRoundedButtonText,
                    {
                      color: colorSettings?.btn_no_fill_text_2,
                      fontSize: 25,
                    },
                  ]}
                >
                  {'+'}
                </Text>
                <Text
                  style={[
                    styles.headerFullRoundedButtonText,
                    {
                      color: colorSettings?.btn_no_fill_text_2,
                    },
                  ]}
                >
                  {i18n.t(tertiaryButton?.key)?.default}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </>
  )
}

export default GenericHeaderControls
