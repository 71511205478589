import { Component } from 'react'
import {
  Image,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ScrollView,
  ProgressViewIOS,
  KeyboardAvoidingView,
} from 'react-native'

import styles from '../PersonalGenderIdentity.styles.js'

import { i18n } from '$localization/config.js'

export default class GenderIdentityOptions extends Component {
  onSelect = this.props.onSelect

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      selectedGenderIdentity: null,
    }
  }

  toggleGenderIdentityButton = (isSelected = false) => {
    if (isSelected) {
      return {
        backgroundColor:
          this.colorSettings?.selectable_btn_active_1 ||
          'rgba(74, 74, 74, 0.1)',
        borderColor:
          this.colorSettings?.selectable_btn_border_active_1 || 'black',
        flex: 0.33,
      }
    }
    return {
      backgroundColor:
        this.colorSettings?.selectable_btn_inactive_1 || 'transparent',
      borderColor:
        this.colorSettings?.selectable_btn_border_inactive_1 || 'black',
      flex: 0.33,
    }
  }

  onSelectOption = (value) => {
    this.setState({ selectedGenderIdentity: value })
    this.onSelect(value)
  }

  render() {
    return (
      <View style={[styles.horizontalContent, { flex: 1.0 }]}>
        <TouchableOpacity
          style={[
            styles.roundedTileButton,
            this.toggleGenderIdentityButton(
              this.state.selectedGenderIdentity === 'm'
            ),
          ]}
          onPress={() => this.onSelectOption('m')}
          activeOpacity={1.0}
          nativeID="gender_id_tile"
        >
          <View style={[styles.content, { flex: 1.0 }]}>
            <View
              style={[
                styles.maleIcon,
                {
                  backgroundColor:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                },
              ]}
            />
            <Text
              style={[
                styles.roundedTileButtonText,
                {
                  color:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                },
              ]}
            >
              {i18n.t('male').default}
            </Text>
          </View>
        </TouchableOpacity>

        <View style={{ height: '100%', width: 10 }} />

        <TouchableOpacity
          style={[
            styles.roundedTileButton,
            this.toggleGenderIdentityButton(
              this.state.selectedGenderIdentity === 'f'
            ),
          ]}
          onPress={() => this.onSelectOption('f')}
          activeOpacity={1.0}
          nativeID="gender_id_tile"
        >
          <View style={[styles.content, { flex: 1.0 }]}>
            <View
              style={[
                styles.femaleIcon,
                {
                  backgroundColor:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                },
              ]}
            />
            <Text
              style={[
                styles.roundedTileButtonText,
                {
                  color:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                },
              ]}
            >
              {i18n.t('female').default}
            </Text>
          </View>
        </TouchableOpacity>

        <View style={{ height: '100%', width: 10 }} />

        <TouchableOpacity
          style={[
            styles.roundedTileButton,
            this.toggleGenderIdentityButton(
              this.state.selectedGenderIdentity === 'nb'
            ),
          ]}
          onPress={() => this.onSelectOption('nb')}
          activeOpacity={1.0}
          nativeID="gender_id_tile"
        >
          <View style={[styles.content, { flex: 1.0 }]}>
            <View
              style={[
                styles.nonBinaryIconTop,
                {
                  borderBottomColor:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                  marginTop: 8,
                },
              ]}
            />
            <View
              style={[
                styles.nonBinaryIconBottom,
                {
                  borderTopColor:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                  marginBottom: 16,
                },
              ]}
            />
            <Text
              style={[
                styles.roundedTileButtonText,
                {
                  color:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                  fontSize: 10,
                },
              ]}
            >
              {i18n.t('non-binary_other').default}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}
