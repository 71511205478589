import { Component } from 'react'
import {
  Dimensions,
  Modal,
  Image,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
  Platform,
} from 'react-native'
import { connect } from 'react-redux'

import { ScrollView } from 'react-native-gesture-handler'
import openInNewWebTab from '$utils/openInNewWebTab'
import { DOMAIN_CLIENT_NAME } from '$constants/globalVariables'

import DarkGraySwitch from '$components/custom-switches/DarkGraySwitch.js'
import SimpleWebViewer from '$components/SimpleWebViewer.js'

import { i18n } from '$localization/config.js'

import styles from './styles/main.styles.js'

class TermsAndPrivacyScreen extends Component {
  primaryButtonAction = this.props.primaryButtonAction ?? function () {}

  cancelButtonAction = this.props.cancelButtonAction ?? function () {}

  state = {
    modalVisible: this.props.modalVisible ?? false,
    customTermsVisible: false,
    agreeToTermsAndConditions: false,
    agreeToPrivacyPolicy: false,
    agreeToCustomTermsAndConditions: false,
    showWebViewerModal: false,
    selectedWebsiteURL: null,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const modalVisible = newProps.modalVisible ?? false
    return {
      ...prevState,
      modalVisible,
    }
  }

  constructor(props) {
    super(props)

    /* Set custom T&C visibility */
    const { hide_custom_terms_and_conditions_url } = this.props.appSettings
    this.state.customTermsVisible = hide_custom_terms_and_conditions_url
  }

  toggleWebViewerModal = () => {
    this.setState({
      showWebViewerModal: !this.state.showWebViewerModal,
    })
  }

  didPressTermsAndConditionsLink = () => {
    const { terms_and_conditions_url } = this.props.appSettings
    if (Platform.OS === 'web') {
      return openInNewWebTab(terms_and_conditions_url)
    }
    this.setState({
      showWebViewerModal: true,
      selectedWebsiteURL: terms_and_conditions_url,
    })
  }

  didPressPrivacyPolicyLink = () => {
    const { privacy_settings_url } = this.props.appSettings
    if (Platform.OS === 'web') {
      return openInNewWebTab(privacy_settings_url)
    }
    this.setState({
      showWebViewerModal: true,
      selectedWebsiteURL: privacy_settings_url,
    })
  }

  didPressCustomTermsAndConditionsLink = () => {
    /* Note: For Custom Terms and Conditions only */
    const { custom_terms_and_conditions_url } = this.props.appSettings
    if (Platform.OS === 'web') {
      return openInNewWebTab(custom_terms_and_conditions_url)
    }
    this.setState({
      showWebViewerModal: true,
      selectedWebsiteURL: custom_terms_and_conditions_url,
    })
  }

  termsAndConditionsSwitchAction = () => {
    this.setState({
      agreeToTermsAndConditions: !this.state.agreeToTermsAndConditions,
    })
  }

  privacyPolicySwitchAction = () => {
    this.setState({
      agreeToPrivacyPolicy: !this.state.agreeToPrivacyPolicy,
    })
  }

  customtermsAndConditionsSwitchAction = () => {
    /* Note: For Custom Terms and Conditions only */
    this.setState({
      agreeToCustomTermsAndConditions:
        !this.state.agreeToCustomTermsAndConditions,
    })
  }

  shouldSubmit = () => {
    if (!this.state.customTermsVisible) {
      return (
        this.state.agreeToTermsAndConditions &&
        this.state.agreeToPrivacyPolicy &&
        this.state.agreeToCustomTermsAndConditions
      )
    }
    return (
      this.state.agreeToTermsAndConditions && this.state.agreeToPrivacyPolicy
    )
  }

  renderModalContainer = (
    colorSettings,
    allowSubmit,
    smallDimension = false
  ) => (
    <View
      style={[
        styles.modalContainer,
        {
          width: smallDimension === true ? '100%' : '93%',
        },
      ]}
    >
      {/* <Modal Header> */}
      <View
        style={[
          styles.modalHeader,
          {
            backgroundColor: colorSettings?.modal_top_title_bar_bg || 'white',
            height: 170,
          },
        ]}
      >
        <View style={{ flex: 1.0, flexDirection: 'row' }}>
          <View style={{ justifyContent: 'center', flex: 0.7 }}>
            <Text
              style={[
                styles.modalHeaderTitleText,
                {
                  color: colorSettings?.modal_top_title_bar_text || 'black',
                  fontSize: 18,
                },
              ]}
            >
              {
                i18n.t('please_review_our_terms_and_privacy_policy_carefully')
                  .default
              }
            </Text>
          </View>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 0.3,
            }}
          >
            <Image
              source={require('$assets/images/new_assets/illusTerms.png')}
              style={{ width: 99, height: 99, resizeMode: 'contain' }}
            />
          </View>
        </View>
      </View>
      {/* </Modal Header> */}

      {/* <Modal Content> */}
      <ScrollView>
        <View
          style={[
            styles.modalContent,
            {
              height: this.state.customTermsVisible ? 300 : 350,
            },
          ]}
        >
          {/* <Terms and Conditions> */}
          <View style={[styles.modalContentSection, { flex: 0.6 }]}>
            <View style={{ height: 38, flexDirection: 'row' }}>
              <View style={{ flex: 0.75, justifyContent: 'center' }}>
                <Text>
                  <Text style={styles.regularText}>
                    {i18n.t('view_our_').default}
                  </Text>
                  <Text
                    style={[
                      styles.linkedText,
                      {
                        color: colorSettings?.linked_text,
                      },
                    ]}
                    onPress={this.didPressTermsAndConditionsLink}
                  >
                    {i18n.t('terms_and_conditions').default}
                  </Text>
                </Text>
              </View>
              <View
                style={{
                  flex: 0.25,
                  justifyContent: 'center',
                }}
              >
                <DarkGraySwitch
                  value={this.state.agreeToTermsAndConditions}
                  onValueChange={this.termsAndConditionsSwitchAction}
                />
              </View>
            </View>

            <View style={styles.switchField}>
              <View style={{ flex: 0.85, justifyContent: 'flex-start' }}>
                <Text style={styles.switchFieldLabel}>
                  {
                    i18n.t('i_have_read_and_accept_the_terms_and_conditions')
                      .default
                  }
                </Text>
              </View>
            </View>
          </View>
          {/* </Terms and Conditions> */}

          {/* <Privacy Policy> */}
          <View style={[styles.modalContentSection, { flex: 0.6 }]}>
            <View style={{ height: 28, flexDirection: 'row' }}>
              <View style={{ flex: 0.75, justifyContent: 'center' }}>
                <Text>
                  <Text style={styles.regularText}>
                    {i18n.t('view_our_').default}
                  </Text>
                  <Text
                    style={[
                      styles.linkedText,
                      {
                        color: colorSettings?.linked_text,
                      },
                    ]}
                    onPress={this.didPressPrivacyPolicyLink}
                  >
                    {i18n.t('privacy_policy').default}
                  </Text>
                </Text>
              </View>
              <View
                style={{
                  flex: 0.25,
                  justifyContent: 'center',
                }}
              >
                <DarkGraySwitch
                  value={this.state.agreeToPrivacyPolicy}
                  onValueChange={this.privacyPolicySwitchAction}
                />
              </View>
            </View>

            <View style={styles.switchField}>
              <View style={{ flex: 0.85, justifyContent: 'flex-start' }}>
                <Text style={styles.switchFieldLabel}>
                  {i18n.t('i_have_read_and_accept_the_privacy_policy').default}
                </Text>
              </View>
            </View>
          </View>
          {/* </Privacy Policy> */}

          {/* <White Label T&C> */}
          {this.state.customTermsVisible === false && (
            <View style={[styles.modalContentSection, { flex: 0.6 }]}>
              <View style={{ height: 38, flexDirection: 'row' }}>
                <View style={{ flex: 0.75, justifyContent: 'center' }}>
                  <Text>
                    <Text style={styles.regularText}>
                      {i18n.t('view_the_').default}
                    </Text>
                    <Text style={styles.regularText}>
                      {`${DOMAIN_CLIENT_NAME} `}
                    </Text>
                    <Text
                      style={[
                        styles.linkedText,
                        {
                          color: colorSettings?.linked_text,
                        },
                      ]}
                      onPress={this.didPressCustomTermsAndConditionsLink}
                    >
                      {i18n.t('terms_and_conditions').default}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flex: 0.25,
                    justifyContent: 'center',
                  }}
                >
                  <DarkGraySwitch
                    value={this.state.agreeToCustomTermsAndConditions}
                    onValueChange={this.customtermsAndConditionsSwitchAction}
                  />
                </View>
              </View>

              <View style={styles.switchField}>
                <View style={{ flex: 0.85, justifyContent: 'flex-start' }}>
                  <Text>
                    <Text style={styles.switchFieldLabel}>
                      {i18n.t('i_have_read_and_accept_the_').default}
                    </Text>
                    <Text style={styles.switchFieldLabel}>
                      {`${DOMAIN_CLIENT_NAME} `}
                    </Text>
                    <Text style={styles.switchFieldLabel}>
                      {i18n.t('terms_and_conditions').default}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          )}
          {/* </White Label T&C> */}
        </View>
        {/* </Modal Content> */}

        {/* <Modal Footer> */}
        <View style={[styles.modalFooter, { height: 50 }]}>
          <View style={{ flex: 1.0 }}>
            <View style={{ flex: 1.0, flexDirection: 'row' }}>
              {/* <Cancel Button> */}
              <TouchableOpacity
                style={[styles.buttonContainer, { flex: 0.5, height: '100%' }]}
                onPress={() => this.cancelButtonAction()}
              >
                <Text style={styles.cancelButtonTitle}>
                  {i18n.t('cancel').default}
                </Text>
              </TouchableOpacity>
              {/* </Cancel Button> */}

              <View
                style={{
                  backgroundColor: 'rgb(234,234,234)',
                  height: '100%',
                  width: 2.0,
                }}
              />

              {/* <Submit Button> */}
              <TouchableOpacity
                disabled={!allowSubmit}
                style={[
                  styles.buttonContainer,
                  {
                    flex: 0.5,
                    height: '100%',
                    backgroundColor: 'white',
                  },
                ]}
                onPress={this?.props?.primaryButtonAction ?? null}
              >
                <Text
                  style={[
                    styles.submitButtonTitle,
                    {
                      color: allowSubmit
                        ? colorSettings?.linked_text
                        : 'rgb(153,153,153)',
                    },
                  ]}
                >
                  {i18n.t('submit').default}
                </Text>
              </TouchableOpacity>
              {/* </Submit Button> */}
            </View>
          </View>
        </View>
      </ScrollView>
      {/* </Modal Footer> */}
    </View>
  )

  renderModal = (colorSettings, allowSubmit) => {
    const { customTermsVisible } = this.state
    const dimensions = Dimensions.get('window')

    if (dimensions.height <= 568 && customTermsVisible === false) {
      return (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            width: '90%',
            marginBottom: 25,
          }}
        >
          {this.renderModalContainer(colorSettings, allowSubmit, true)}
        </ScrollView>
      )
    }

    return this.renderModalContainer(colorSettings, allowSubmit)
  }

  handleShowSimpleWebViewer = () => {
    const { showWebViewerModal, selectedWebsiteURL } = this.state

    if (showWebViewerModal) {
      return (
        <SimpleWebViewer
          backButtonAction={() => this.toggleWebViewerModal()}
          modalVisible={showWebViewerModal}
          websiteURL={selectedWebsiteURL}
        />
      )
    }

    return <View />
  }

  render() {
    const { colorSettings } = this.props
    const { modalVisible } = this.state
    const allowSubmit = this.shouldSubmit()

    return (
      <Modal animationType="fade" transparent visible={modalVisible}>
        <SafeAreaView
          style={styles.modalBackground}
          nativeID="modal_scaled_main_container"
        >
          {this.renderModal(colorSettings, allowSubmit)}

          {/* <Web Viewer> */}
          {this.handleShowSimpleWebViewer()}
          {/* <Web Viewer> */}
        </SafeAreaView>
      </Modal>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(TermsAndPrivacyScreen)
