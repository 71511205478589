import { Text, View, StyleSheet } from 'react-native'
import ButtonSub from '$components/button_sub'
import { i18n } from '$localization/config.js'

function ButtonToggleView({
  colorSettings,
  isToggledOn,
  isToggledOff,
  toggleSwitcher,
  title,
  setContainerStyle,
}) {
  return (
    <View style={[styles.mainContainer, setContainerStyle]}>
      <Text
        style={{
          fontFamily: 'karla-bold',
          fontSize: 24,
          color: colorSettings?.text_input_label_color_2,
          textAlign: 'center',
          marginBottom: 20,
        }}
      >
        {title}
      </Text>
      <View style={styles.container}>
        <View style={styles.buttonContainer}>
          <ButtonSub
            title={i18n.t('yes').default}
            onPress={() => toggleSwitcher(true)}
            colorSettings={colorSettings}
            setToggle={isToggledOn()}
            isDisabled={isToggledOn()}
          />
        </View>
        <View style={styles.buttonContainer}>
          <ButtonSub
            title={i18n.t('no').default}
            onPress={() => toggleSwitcher(false)}
            colorSettings={colorSettings}
            setToggle={isToggledOff()}
            isDisabled={isToggledOff()}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    height: 60,
    paddingHorizontal: 10,
  },
})

export default ButtonToggleView
