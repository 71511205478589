import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  pickerContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
  },
  toolbarContainer: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    height: 45,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'rgba(0,0,0,0.8)',
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
  },
  toolbarButton: {
    height: '100%',
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbarButtonText: {
    color: '#000',
    fontFamily: 'montserrat-semibold',
    fontSize: 15,
    textAlign: 'center',
  },
})

export default styles
