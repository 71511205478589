import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
// components
import ButtonSubView from './ButtonSub.view'
// utils
import { decreaseFontSize } from './utils/utils'

const ButtonSubLogic = forwardRef((props, ref) => {
  const [isSelected, setIsSelected] = useState(props.setToggle)

  useEffect(() => {
    if (props.currentState) props.currentState(isSelected)
  }, [isSelected])

  useEffect(() => {
    if (!props.preventToggleEffect) setIsSelected(props.setToggle)
  }, [props.setToggle])

  useImperativeHandle(ref, () => ({
    test() {
      handleOnPress()
    },
  }))

  const handleOnPress = useCallback(() => {
    props.onPress(!isSelected)
    if (!props.preventToggleEffect) setIsSelected((selected) => !selected)
  }, [isSelected, props.preventToggleEffect])

  const styleHandler = () => {
    if (isSelected) {
      return {
        backgroundColor:
          props.colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)',
        borderColor: props.colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: props.colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  return (
    <ButtonSubView
      colorSettings={props.colorSettings}
      onPress={handleOnPress}
      styleHandler={styleHandler}
      fontSizeHandler={() => decreaseFontSize(props.title)}
      title={props.title}
      isDisabled={props.isDisabled}
      customStyle={props.style}
    />
  )
})

export { ButtonSubLogic }
