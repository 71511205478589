import {
  fixDiseaseName,
  getFamilySideAndRelationshipGender,
} from '$api/_utils/Utils'
import { i18n } from '$localization/config.js'
import { excludedRelationships } from '../../FamilyListScreen/constants/excludedRelationships'

export const handleRelationshipName = (member) => {
  const relationshipToProband = getFamilySideAndRelationshipGender(
    member?.relationship_to_proband,
    member?.gender
  )

  const relationship = relationshipToProband[0]?.toLowerCase()
  const familySide = relationshipToProband[1]?.toLowerCase()

  if (familySide)
    return `${i18n.t(fixDiseaseName(familySide))?.default} ${relationship}`

  return `${i18n.t(fixDiseaseName(relationship)).default ?? relationship}`
}

export const getSearchedFamilyMembersWithDiseaseOrGeneticTestingData = (
  object
) => {
  const result = []

  object.forEach((member) => {
    const currentMember = member
    if (
      currentMember?.is_blood_related_to_proband ||
      excludedRelationships.includes(currentMember?.is_blood_related_to_proband)
    )
      currentMember.children = []

    if (currentMember?.relationship_to_proband === 'partner')
      currentMember.partners = []

    const hasCurrentFamilyMemberChildren = currentMember.children?.length > 0
    const hasCurrentFamilyMemberPartners = currentMember.partners?.length > 0
    const hasMemberDiseasesOrGeneticTestingData =
      currentMember?.diseases?.length > 0 || currentMember?.genes?.length > 0

    if (hasMemberDiseasesOrGeneticTestingData) {
      result.push({ ...currentMember, partners: [] })
    }
    if (hasCurrentFamilyMemberPartners) {
      const correctPartners = currentMember.partners.filter(
        (partner) =>
          !excludedRelationships.includes(partner.relationship_to_proband)
      )

      result.push(
        ...getSearchedFamilyMembersWithDiseaseOrGeneticTestingData(
          correctPartners
        )
      )
    }
    if (hasCurrentFamilyMemberChildren) {
      result.push(
        ...getSearchedFamilyMembersWithDiseaseOrGeneticTestingData(
          currentMember.children
        )
      )
    }
  })

  return result
}
