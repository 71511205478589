import { Component } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { connect } from 'react-redux'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { DOMAIN_CLIENT } from '$constants/globalVariables.js'
import { handleDefaultError } from '$navigation/_utils/NavigationUtils.js'

import AuthCredentials from '$data_models/AuthCredentials'
import Proband from '$data_models/Proband'
import { setError } from '$redux/defaults/actions.js'

import * as DashboardMenuItems from '$constants/DashboardMenuItems.js'

const styles = StyleSheet.create({
  badgeContainer: {
    height: 24,
    width: 24,
    marginLeft: 14,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
  },
  badgeText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    alignSelf: 'center',
  },
})

class DashboardBadge extends Component {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  buttonName = this.props.buttonName

  getTotalNotificationsTimer = null

  state = {
    badgeNumber: 0,
    surveyBadgeCount: this.props.surveyBadgeCount,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { surveyBadgeCount } = newProps
    return {
      ...prevState,
      surveyBadgeCount,
    }
  }

  async componentDidMount() {
    const { navigation } = this.props

    await this.getNotif()

    this.didBlurSubscription = navigation.addListener('focus', async () => {
      await this.getNotif()
    })

    if (this.props.allowBackgroundRefresh) {
      switch (this.buttonName) {
        // case "Family Updates":
        case DashboardMenuItems.FAMILY_UPDATES:
          this.getTotalNotificationsTimer = setInterval(async () => {
            await this.getNotificationAPI()
          }, this.props.backgroundRefreshInterval)
          break

        // case "Surveys":
        case DashboardMenuItems.SURVEYS:
          this.getTotalNotificationsTimer = setInterval(async () => {
            await this.getSurveyBadges()
          }, this.props.backgroundRefreshInterval)
          break
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.getTotalNotificationsTimer)
  }

  getNotif = async () => {
    if (this.buttonName === DashboardMenuItems.FAMILY_UPDATES) {
      await this.getNotificationAPI()
    }

    if (this.buttonName === DashboardMenuItems.SURVEYS) {
      await this.getSurveyBadges()
    }
  }

  getNotificationAPI = async () => {
    const getTotalUnreadNotificationsPayload = {
      path: 'notification/unread/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
      },
      pageDetails: {
        page: 'DashboardBadge.layout.js',
      },
    }

    const response = await ApiFetchHandler(getTotalUnreadNotificationsPayload)
    const { saveError } = this.props
    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.setState({ badgeNumber: response })
  }

  getSurveyBadges = async () => {
    const getProbandSurveyPayload = {
      path: 'family/surveys/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        domain_client: DOMAIN_CLIENT?.toUpperCase(),
      },
      pageDetails: {
        page: 'DashboardBadge.layout.js',
      },
    }

    const response = await ApiFetchHandler(getProbandSurveyPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const clinicianSurveys = response?.clinician_surveys
    const optionalSurvey = response?.optional_surveys

    let badgeNumber = 0

    for (const cln_lst of clinicianSurveys) {
      for (const item of cln_lst?.surveys) {
        badgeNumber =
          item?.status !== 'completed' ? badgeNumber + 1 : badgeNumber
      }
    }

    for (const opt_lst of optionalSurvey) {
      badgeNumber =
        opt_lst.status !== 'completed' ? badgeNumber + 1 : badgeNumber
    }

    this.setState({ badgeNumber })

    /* Update Survey Badge count on Redux store */
    this.props._storeSurveyBadgeCount(badgeNumber)
  }

  styleHandler = () => {
    const { colorSettings } = this.props
    return {
      backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
      borderColor: colorSettings?.post_onboarding_nav_bgcolor,
    }
  }

  render() {
    const { colorSettings } = this.props

    if (this.state.badgeNumber > 0) {
      return (
        <View
          style={[
            styles.badgeContainer,
            {
              backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
              borderColor: colorSettings?.post_onboarding_nav_bgcolor,
            },
          ]}
        >
          <Text
            style={[
              styles.badgeText,
              {
                color: colorSettings?.nav_title_light,
              },
            ]}
          >
            {this.state.badgeNumber}
          </Text>
        </View>
      )
    }

    return null
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore } = state
  return {
    /** Default Store */
    allowBackgroundRefresh: store.allowBackgroundRefresh,
    backgroundRefreshInterval: store.backgroundRefreshInterval,
    colorSettings: store.colorSettings,
    surveyBadgeCount: store.surveyBadgeCount,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    proband: probandStore.proband,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _storeSurveyBadgeCount: (surveyBadgeCount) =>
      dispatch({ type: 'STORE_SURVEYCOUNT', data: surveyBadgeCount }),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBadge)
