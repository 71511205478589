import { Component } from 'react'
import {
  Text,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  SectionList,
  Platform,
} from 'react-native'

import Alert from '$components/Alert'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'
import { IS_WEB } from '$constants/Platforms.js'
import { capitalizeFirstLetter, fixSnakeCaseText } from '$api/_utils/Utils.js'
import { VERSION_MODE } from '$constants/globalVariables'
import { APP_BUILD_NUMBER } from '$constants/envVariables.js'
import openInNewWebTab from '$utils/openInNewWebTab'

const packageJsonFile = require('../../../../package.json')

class DashboardSettings extends Component {
  ACCOUNT_SETTINGS = 'account_settings'

  LOGIN_SETTINGS = 'login_settings'

  CHANGE_PASSWORD = 'change_password'

  DELETE_ACCOUNT = 'delete_account'

  HELP = 'help'

  FAQ = 'faqsupport'

  ABOUT = 'about'

  UPDATE_PHONE_NUMBER = 'update_phone_number'

  RESEARCH_DATA_SETTINGS = 'research_data_settings'

  CONTACT_US = 'contact_us'

  TERMS_OF_USE = 'terms_of_use'

  PRIVACY_POLICY = 'privacy_policy'

  SUBMIT_IDEAS = 'submit_ideas'

  LOGOUT = 'logout'

  mobileData = {
    title: this.ACCOUNT_SETTINGS,
    data: [this.CHANGE_PASSWORD, this.LOGIN_SETTINGS, this.DELETE_ACCOUNT],
  }

  webData = {
    title: this.ACCOUNT_SETTINGS,
    data: [this.CHANGE_PASSWORD, this.DELETE_ACCOUNT],
  }

  menuData = [
    IS_WEB() ? this.webData : this.mobileData,
    {
      title: this.HELP,
      data: [this.FAQ, this.SUBMIT_IDEAS],
    },
    {
      title: this.ABOUT,
      data: [this.TERMS_OF_USE, this.PRIVACY_POLICY],
    },
  ]

  disabledButtons = []

  toggleDashboardSettings = this.props.toggleDashboardSettings

  logoutButtonAction = this.props.logoutButtonAction

  navigateTo = this.props.navigateTo

  appSettings = this.props.appSettings

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.setVersionAndBuildNumber()
  }

  setVersionAndBuildNumber() {
    let _versionMode_ = `${VERSION_MODE}`

    const buildNumber = APP_BUILD_NUMBER || Date.now().toString()

    const versionNumber = packageJsonFile?.version ?? Date.now()

    if (_versionMode_.toLowerCase() !== 'live') {
      _versionMode_ = `(${capitalizeFirstLetter(_versionMode_)})`
    } else {
      _versionMode_ = ''
    }

    const version = `v: ${versionNumber} ${_versionMode_} | ${
      i18n.t('build').capitalize
    }: ${buildNumber}`

    const objIndex = this.menuData.findIndex(
      (item) => item.title === this.ABOUT
    )
    this.menuData[objIndex].data.push(version)
    this.disabledButtons.push(version)
  }

  disabledButtonChecker = (name, objName) => {
    let disabled = false
    const style = {
      fontFamily: 'montserrat',
      fontSize: 12,
    }

    for (const val in this.disabledButtons) {
      if (this.disabledButtons[val] == name) {
        disabled = true
        style.opacity = 0.4
      }
    }

    if (objName == 'disabled') {
      return disabled
    }

    if (objName == 'style') {
      return { ...style }
    }
  }

  deleteAccount = async () => {
    const { logoutButtonAction, deleteAccountAction } = this.props
    await deleteAccountAction()
    logoutButtonAction()
  }

  navigateToItem = (item) => {
    const { appSettings } = this.props
    let url = null

    if (item === this.DELETE_ACCOUNT) {
      Alert.alert(
        i18n.t('delete_account').default,
        i18n.t(
          'are_you_sure_you_want_to_delete_your_account_all_of_your_data_will_be_deleted_and_you_will_no_longer_have_access_to_your_family_health_history_this_cannot_be_undone_if_you_have_shared_data_with_a_clinician_or_family_member_they_will_still_have_their_copy_of_the_data'
        ).default,
        [
          {
            text: i18n.t('cancel').default,
          },
          {
            text: i18n.t('delete').default,
            onPress: () => this.deleteAccount(),
          },
        ]
      )
      this.toggleDashboardSettings()
      return
    }

    switch (item) {
      case this.SUBMIT_IDEAS:
        url =
          'https://famhis.atlassian.net/servicedesk/customer/portal/2/group/3/create/15'
        break

      case this.FAQ:
        url = appSettings?.faq_url
        break

      case this.PRIVACY_POLICY:
        url = appSettings?.privacy_settings_url
        break

      case this.TERMS_OF_USE:
        url = appSettings?.terms_and_conditions_url
        break
    }

    if (Platform.OS === 'web' && url) {
      return openInNewWebTab(url)
    }

    const nav = this.removeSpaces(item)
    const changePWname =
      item === 'change_password' ? 'change_password_navigator' : item

    if (url) {
      this.props._setCustomWebViewUri_(url)
      this.navigateTo('CustomWebView', {
        t: item,
      })
    } else {
      const formatedRouteName = fixSnakeCaseText(
        capitalizeFirstLetter(item, true, '_'),
        ''
      )

      const isPasswordChangeScreen = formatedRouteName === 'ChangePassword'

      const routeName = isPasswordChangeScreen
        ? 'ChangePasswordNavigator'
        : formatedRouteName

      this.navigateTo(routeName)
    }

    this.toggleDashboardSettings()
  }

  removeSpaces = (item) => {
    const str = item.replace(/\s/g, '')
    return str
  }

  render() {
    return (
      <TouchableOpacity
        style={{ flex: 1, justifyContent: 'flex-start' }}
        activeOpacity={1}
        onPressOut={this.toggleDashboardSettings}
      >
        <View
          style={{
            flex: 1,
            ...Platform.select({
              web: {
                maxWidth: 800,
                minWidth: 500,
                width: '100%',
                alignSelf: 'center',
                alignItems: 'flex-end',
              },
              default: {
                alignItems: 'flex-end',
              },
            }),
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: 230,
                backgroundColor: 'white',
                marginTop: 35,
                borderRadius: 5,
                padding: 17,
                ...Platform.select({
                  ios: {
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 3 },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                  },
                  android: {
                    borderColor: 'rgba(0,0,0,.15)',
                    borderWidth: 1,
                  },
                }),
              }}
            >
              <SectionList
                sections={this.menuData}
                keyExtractor={(item, index) => item + index}
                renderSectionHeader={({ section: { title } }) => (
                  <View
                    style={{
                      backgroundColor:
                        this.colorSettings?.dashboard_section_btn_bg,
                      paddingVertical: 9,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'montserrat-semibold',
                        color: this.colorSettings?.post_onboarding_nav_bgcolor,
                        fontSize: 12,
                      }}
                    >
                      {`${i18n.t(title).default ?? title}`}
                    </Text>
                  </View>
                )}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    disabled={this.disabledButtonChecker(item, 'disabled')}
                    onPress={() => this.navigateToItem(item)}
                    style={{ paddingVertical: 7 }}
                  >
                    <Text style={this.disabledButtonChecker(item, 'style')}>
                      {`${i18n.t(item).default ?? item}`}
                    </Text>
                  </TouchableOpacity>
                )}
              />

              <View style={{ marginTop: 20 }}>
                <TouchableOpacity onPress={this.logoutButtonAction}>
                  <Text
                    style={{
                      fontFamily: 'montserrat',
                      fontSize: 12,
                      paddingVertical: 9,
                    }}
                  >
                    {`${i18n.t(this.LOGOUT).default}`}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableOpacity>
    )
  }
}

const mapGlobalStateToProps = (state) => {
  const { store } = state
  return {
    /** Default Store */
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    customWebViewUri: store.customWebViewUri,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _setCustomWebViewUri_: (data) =>
    dispatch({ type: 'SET_CUSTOM_WEB_VIEW_URI', data }),
})

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(DashboardSettings)
