import { StyleSheet } from 'react-native'

const grey = 'rgba(255, 255, 255, 0.5)'

export default StyleSheet.create({
  roundedButtonContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    borderRadius: 37.5,
    borderWidth: 2.0,
    borderColor: grey,
  },
  roundedButtonTitleTextContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
