import { memo, Component, createRef } from 'react'
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
} from 'react-native'

import { connect } from 'react-redux'
import { setDiseaseProband, setSkipLogic } from 'redux/diseases/actions.js'
import InputTextAnswer from 'screens/personal_details/onboarding_disease_selection/components/input_text_answer'
import { i18n } from '$localization/config.js'

// components
import InputAge from '$screens/personal_details/onboarding_disease_selection/components/input_age'
import ButtonDone from '$components/button_done'
import ButtonToggle from '$components/button_toggle'
import ToggleWithInput from '$screens/personal_details/onboarding_disease_selection/components/toggle_with_input'
import ButtonSub from '$components/button_sub'
import ModalLoading from '$components/modal_loading'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { fixDiseaseName } from '$api/_utils/Utils.js'
import { WEB, IS_MOBILE } from '$constants/Platforms.js'
import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import FlexContainer from '$components/flex_container'

import { setShouldReloadDiseases } from '$redux/member_profile/actions.js'
import styles from './assets/styles'

// utils
import SkipLogicList from '$screens/personal_details/onboarding_disease_selection/utils/skipLogicList.js'
import DiseaseList from '$screens/personal_details/onboarding_disease_selection/utils/diseaseList.js'
import {
  memberSaveDiseases,
  translationConverter,
  alphabeticalSort,
} from '../utils/_utils'
// redux
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

import xs from '$assets/styles/style'
import BottomSheetComponent from '$components/BottomSheetComponent/BottomSheetComponent'
import { IS_WEB } from '$constants/Platforms'
import DropdownComponent from '$components/DropdownComponent/DropdownComponent'
import { isAgeValid } from '$screens/main/family/edit_profile/_utils/Utils'
import { handleDisplayShorterAgeUnits } from '$api/_utils/Utils'

const MemoizedToggleInput = memo(ToggleWithInput)

const skipList = null
let clearSkipLogic = null

class DiseasesSkipLogic extends Component {
  colorSettings = this.props.colorSettings

  disease = this.props.disease

  selectedDiseases = this.props.selectedDiseases

  skipLogic = this.props.skipLogic

  account = this.props.account

  authCredentials = this.props.authCredentials

  currentSelectedMember = this.props.currentSelectedMember

  diseaseFromOnboarding = this.props.diseaseFromOnboarding

  probandProfile = this.props.probandProfile

  fromMainDisease = this.props.fromMainDisease

  currentMemberProfile = this.props.currentMemberProfile

  bottomSheetRef = createRef()

  constructor(props) {
    super(props)

    let filterDisease =
      this.selectedDiseases.filter(
        (selected) => this.disease.id === selected.disease_id
      ) || ''

    if (filterDisease?.length < 1) {
      filterDisease = this.selectedDiseases.filter(
        (selected) => this.disease.umls_id === selected.disease_id
      )
    }

    this.state = {
      skipLogicLength: 0,
      skipLogics: null,
      disableDoneButton: false,
      selectedDisease: {
        disease_id: this.disease.id || null,
        disease_type: this.disease.disease_type,
        disease_name: this.disease.disease_name,
        age_diagnosed: filterDisease[0]?.age_diagnosed || '',
        skipLogic: [],
        ...this.disease,
      },
      showDropdown: false,
      ageOfDiagnosisType: 'years',
      bottomSheetIndex: -1,
      savingDiseases: false,
      scrollViewHeight: DefaultFullHeight(),
      test: '',
      isScreenActive: true,
    }
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    const skipLogic = this.disease.skip_logistics
    // filter skiplogic that is only shown in app
    const filterSkipLogicViewInApp = skipLogic?.filter(
      (disease) => disease.view_in_app === true
    )
    // filter selected only disease
    let filterDisease = this.selectedDiseases?.filter(
      (selected) => this.disease.id === selected.disease_id
    )

    if (filterDisease?.length < 1) {
      filterDisease = this.selectedDiseases?.filter(
        (selected) => this.disease.umls_id === selected.disease_id
      )
    }

    const ageDiagnosed = filterDisease[0]?.age_diagnosed || ''
    const ageDiagnosedString = filterDisease[0]?.age_diagnosed_string ?? ''
    const skipLogicLength = filterSkipLogicViewInApp.length
    const selectedSkipLogic = filterDisease[0]?.skipLogic

    this.handleSetAgeTypeOnMount(ageDiagnosedString)
    clearSkipLogic = () => {
      if (this.skipLogic?.length !== 0) {
        this.props._saveSkipLogic_([])
      }
    }

    this.props._saveSkipLogic_(filterDisease[0]?.skipLogic || [])
    this.setState((prevState) => ({
      ...prevState,
      skipLogicLength,
      skipLogics: filterSkipLogicViewInApp,
      disableDoneButton: this.handleDisableDoneButton(
        ageDiagnosed,
        skipLogicLength,
        selectedSkipLogic
      ),
      selectedDisease: {
        ...prevState.selectedDisease,
        age_diagnosed: ageDiagnosed,
        skipLogic: selectedSkipLogic,
      },
    }))
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  handleDisableDoneButton = (
    ageDiagnosed,
    skipLogicLength,
    selectedSkipLogic
  ) => {
    if (ageDiagnosed.length <= 0 || ageDiagnosed === '') {
      return false
    }
    if (skipLogicLength === 1 && selectedSkipLogic?.length === 1) {
      return false
    }
    if (skipLogicLength === 1) {
      return true
    }
    return false
  }

  handleCalculateUnitTypeFromAgeUnit = (splitAgeUnit) => {
    switch (splitAgeUnit) {
      case 'd':
        return 'days'
      case 'm':
        return 'months'
      case 'w':
        return 'weeks'
      default:
        return 'years'
    }
  }

  handleSetAgeTypeOnMount = (ageString) => {
    const splitAgeUnit = ageString[ageString.length - 1]

    this.setState({
      ageOfDiagnosisType: this.handleCalculateUnitTypeFromAgeUnit(splitAgeUnit),
    })
  }

  textToKey = (title) => {
    const { disease } = this.props

    let key
    if (title) {
      key = fixDiseaseName(title)
    }

    const newTitle = i18n.t(key).default ?? disease?.disease_name

    return newTitle
  }

  disableDoneButton = () => {
    this.setState({ disableDoneButton: true })
  }

  handleAddingRemovingSkipLogic = (item, onPress, singleSkipLogic = false) => {
    const { selectedDisease } = this.state
    const skipLogic = selectedDisease?.skipLogic || []

    const handleSkipList = new SkipLogicList([...skipLogic])

    let skipLogicList = null
    if (onPress || singleSkipLogic) {
      skipLogicList = handleSkipList.add(item, onPress)
    } else {
      skipLogicList = handleSkipList.remove(item.id)
    }

    if (singleSkipLogic) {
      this.setState({ disableDoneButton: false })
    }

    this.setState((prevState) => ({
      ...prevState,
      selectedDisease: {
        ...prevState.selectedDisease,
        skipLogic: skipLogicList,
      },
    }))
  }

  setAgeHandler = (id, age) => {
    const skipLogic = this.state.selectedDisease?.skipLogic ?? []
    const handleSkipList = new SkipLogicList(skipLogic)
    const skipLogicList = handleSkipList.setAge(id, age)
    this.setState((prevState) => ({
      ...prevState,
      selectedDisease: {
        ...prevState.selectedDisease,
        skipLogic: skipLogicList,
      },
    }))
  }

  renderItems = (item) => {
    const ageOfDeath = this?.currentMemberProfile?.profileData?.ageOfDeath ?? 0
    const currentAge = this?.currentMemberProfile?.profileData?.currentAge ?? 0
    const deceased = this?.currentMemberProfile?.profileData?.deceased ?? false
    const skipLogic = this.state.selectedDisease?.skipLogic || []
    const handleSkipList = new SkipLogicList([...skipLogic])
    const getSkipLogic = handleSkipList.get(item.id)
    const title = translationConverter(item?.question, 'default')
    let defaultToggle = false

    if (item.question_type === 'yes_or_no_then_number') {
      if (getSkipLogic) {
        defaultToggle = !(getSkipLogic?.answer === 'n')
      }

      return (
        <MemoizedToggleInput
          key={item.id}
          colorSettings={this.colorSettings}
          buttonTitle={title}
          onPress={(val) => this.handleAddingRemovingSkipLogic(item, val)}
          returnAge={(age) => this.setAgeHandler(item.id, age)}
          inputAge={getSkipLogic?.answer || ''}
          inputTitle={translationConverter('Age of Second Diagnosis')}
          setToggle={defaultToggle}
          memberAge={
            this.diseaseFromOnboarding ? this.account?.age || '' : currentAge
          }
          onActiveScreen={() => {
            this.setState((prev) => ({ isScreenActive: !prev.isScreenActive }))
          }}
          memberAgeOfDeath={ageOfDeath || 0}
          isDeceased={deceased || false}
        />
      )
    }
    if (item.question_type === 'text') {
      return (
        <InputTextAnswer
          key={item.id}
          colorSettings={this.colorSettings}
          skipLogicState={!!getSkipLogic}
          toggleAddRemove={(val) =>
            this.handleAddingRemovingSkipLogic(item, val, true)
          }
          returnGeneTestInput={(geneTestInput) =>
            this.setAgeHandler(item.id, geneTestInput)
          }
          inputGeneTest={getSkipLogic?.answer ?? ''}
        />
      )
    }
    if (this.state.skipLogicLength === 1) {
      return (
        <ButtonToggle
          key={item.id}
          colorSettings={this.colorSettings}
          onPress={(val) => this.handleAddingRemovingSkipLogic(item, val, true)}
          title={title}
          isSelected={
            getSkipLogic?.answer === 'y'
              ? true
              : getSkipLogic?.answer === 'n'
              ? false
              : null
          }
        />
      )
    }
    return (
      <ButtonSub
        key={item.id}
        colorSettings={this.colorSettings}
        onPress={(val) => this.handleAddingRemovingSkipLogic(item, val)}
        title={title}
        setToggle={!!getSkipLogic}
      />
    )
  }

  invalidAgeAlert = (
    message = i18n.t('the_age_of_diagnosis_cannot_be_greater_than_current_age')
      .default,
    title = i18n.t('invalid_age_of_diagnosis').default
  ) => {
    showDefaultAlert(title, message)
  }

  doneButtonHandler = async () => {
    const {
      isSingleCategoryDisease,
      selectedDiseases,
      _saveProbandDisease_,
      navigation,
      route,
    } = this.props
    const { selectedDisease, ageOfDiagnosisType } = this.state

    const currentAgeString =
      this?.currentMemberProfile?.profileData?.currentAgeString ?? ''

    const ageOfDiagnosedString = `${
      selectedDisease.age_diagnosed
    }${handleDisplayShorterAgeUnits(ageOfDiagnosisType)}`

    if (!isAgeValid(currentAgeString, ageOfDiagnosedString)) {
      this.setState({ disableDoneButton: true })

      return this.invalidAgeAlert(
        i18n.t(
          'the_age_of_diagnosis_cannot_be_greater_than_this_persons_current_ageage_of_death'
        ).default
      )
    }

    const ageDiagnosedDisease = {
      ...selectedDisease,
      age_diagnosed_string: ageOfDiagnosedString,
    }

    const diseaseSelected = [...selectedDiseases]
    const diseaseList = new DiseaseList(diseaseSelected)
    diseaseList.addDisease(ageDiagnosedDisease)
    const { list } = diseaseList
    _saveProbandDisease_(list)
    const editMemberViewEdit = route?.params?.editMemberViewEdit ?? false

    _saveProbandDisease_(list)

    if (editMemberViewEdit) {
      await this.onboardingDoneButton(diseaseSelected)
    } else if (this.fromMainDisease) {
      if (!this.diseaseFromOnboarding) {
        await this.onboardingDoneButton(diseaseSelected)
      } else {
        navigation.pop()
      }
    } else if (!this.diseaseFromOnboarding) {
      await this.onboardingDoneButton(diseaseSelected)
    } else if (isSingleCategoryDisease) {
      navigation.pop()
    } else {
      navigation.pop(2)
    }
  }

  onboardingDoneButton = async (diseaseSelected) => {
    const { navigation, _setShouldReloadDiseases_, route } = this.props
    const editMemberViewEdit = route?.params?.editMemberViewEdit ?? false

    await memberSaveDiseases({
      diseaseSelected,
      authCredentials: this.authCredentials,
      currentSelectedMember: this.currentMemberProfile,
      proband: this.proband,
      probandProfile: this.probandProfile,
      savingStatusStart: () => {
        this.setState({
          savingDiseases: true,
        })
      },
      savingStatusEnd: () => {
        setTimeout(() => {
          this.setState({
            savingDiseases: false,
          })
        }, 0)
      },
      APIfetchingActions: () => {
        if (!this.fromMainDisease && editMemberViewEdit) {
          _setShouldReloadDiseases_(true)
          navigation.navigate('EditProfileView')
        } else if (this.fromMainDisease) {
          navigation.pop()
        } else {
          navigation.pop(2)
        }
      },
    })
  }

  invalidAgeAlert = (
    message = i18n.t('the_age_of_diagnosis_cannot_be_greater_than_current_age')
      .default,
    title = i18n.t('invalid_age_of_diagnosis').default
  ) => {
    showDefaultAlert(title, message)
  }

  handleInputAge = (age) => {
    let disabled = false

    if (age <= 0 || age === null) {
      disabled = false
    } else if (
      this.state.skipLogicLength === 1 &&
      !this.state.selectedDisease.skipLogic
    ) {
      disabled = true
    } else {
      disabled = false
    }

    this.setState((prevState) => ({
      ...prevState,
      disableDoneButton: disabled,
      selectedDisease: {
        ...prevState.selectedDisease,
        age_diagnosed: age,
        age_string: `${age}${handleDisplayShorterAgeUnits(
          this.state.ageOfDiagnosisType
        )}`,
      },
    }))
  }

  renderListItems = () => {
    const data = this.state?.skipLogics || []

    const sortData = data.sort((A, B) => alphabeticalSort(A, B, 'question'))
    const listItem = []
    let title = <View />

    if (data.length > 1) {
      title = this.renderQuestionTitle()
    }

    if (data) {
      for (const val in sortData) {
        listItem.push(this.renderItems(data[val]))
      }
    } else {
      return <View />
    }

    return (
      <View style={styles.answerListContainer}>
        {title}
        {listItem}
      </View>
    )
  }

  renderChoiceAgeOfDiagnosisButton = () => {
    const { ageOfDiagnosisType, showDropdown } = this.state
    const availableDiagnosisValues = ['days', 'weeks', 'months', 'years']

    const handlePressButton = (event) => {
      this.setState({ ageOfDiagnosisType: event, showDropdown: false })
    }

    const handleShowingChoice = () => {
      if (IS_WEB()) return this.setState({ showDropdown: !showDropdown })

      if (!this.bottomSheetRef.current.expand()) {
        return this.bottomSheetRef.current.expand()
      }

      return this.bottomSheetRef.current.close()
    }

    return (
      <View styles={styles.choiceOfDiagnosisAgeContainer}>
        <TouchableOpacity
          style={styles.choiceOfDiagnosisAgeButton}
          onPress={handleShowingChoice}
        >
          <Text
            style={[
              styles.choiceOfDiagnosisAgeButtonText,
              {
                color: this.colorSettings?.text_input_label_color_2 || 'black',
              },
            ]}
          >
            {i18n.t(ageOfDiagnosisType).default}
          </Text>
        </TouchableOpacity>
        {IS_WEB() && (
          <DropdownComponent
            data={availableDiagnosisValues}
            isShown={showDropdown}
            onPress={handlePressButton}
            selectedItem={ageOfDiagnosisType}
          />
        )}
      </View>
    )
  }

  renderQuestionTitle = () => (
    <View style={{ alignItems: 'center' }}>
      <Text
        style={[
          styles.titleText,
          {
            fontSize: 18,
            color: this.colorSettings?.text_input_title_color_2 || 'black',
          },
        ]}
      >
        {i18n.t('if_you_know_more_details_select_any_that_apply').default}
      </Text>
    </View>
  )

  handleBottomSheetPressButton = (event) => {
    const { selectedDisease } = this.state

    this.setState({
      ageOfDiagnosisType: event,
      selectedDisease: {
        ...selectedDisease,
        age_string: `${
          selectedDisease.age_diagnosed
        }${handleDisplayShorterAgeUnits(event)}`,
      },
    })

    return this.bottomSheetRef.current.close()
  }

  render() {
    const ageOfDeath = this?.currentMemberProfile?.profileData?.ageOfDeath ?? 0
    const currentAge =
      this?.currentMemberProfile?.profileData?.currentAgeString ?? 0
    const deceased = this?.currentMemberProfile?.profileData?.deceased ?? false
    const { navigation, diseaseFromOnboarding, disease } = this.props
    const {
      isScreenActive,
      scrollViewHeight,
      bottomSheetIndex,
      savingDiseases,
      disableDoneButton,
      ageOfDiagnosisType,
    } = this.state

    const availableDiagnosisValues = ['days', 'weeks', 'months', 'years']

    return (
      <MainWrapper
        navigation={navigation}
        disableLogout={diseaseFromOnboarding}
        screenActive={isScreenActive}
      >
        <SafeAreaView
          style={[
            xs.mainContainer,
            {
              backgroundColor:
                this.colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          <KeyboardAvoidingView
            style={styles.keyboardAvoidingView}
            behavior="padding"
            keyboardVerticalOffset={70}
          >
            {/* Content Body */}
            <ScrollView
              style={{ height: scrollViewHeight }}
              contentContainerStyle={xs.scrollView}
            >
              <FlexContainer>
                <View style={styles.innerQuestionnaireContainer}>
                  <InputAge
                    colorSettings={this.colorSettings}
                    mainAgeInput
                    headerTitle={i18n.t('age_at_diagnosis').default}
                    currentAge={this.state.selectedDisease.age_diagnosed}
                    onChange={(val) => this.handleInputAge(val)}
                    onActiveScreen={() => {
                      this.setState((prev) => ({
                        isScreenActive: !prev.isScreenActive,
                      }))
                    }}
                    onFocusEvent={() => this.disableDoneButton()}
                    memberAge={
                      this.diseaseFromOnboarding
                        ? this.account?.age || ''
                        : currentAge
                    }
                    memberAgeOfDeath={ageOfDeath || 0}
                    isDeceased={deceased || false}
                    ageOfDiagnosisType={ageOfDiagnosisType}
                  />
                  {this.renderChoiceAgeOfDiagnosisButton()}
                </View>

                {/* horizontal border */}
                <View
                  style={[
                    styles.horizontalBorder,
                    {
                      borderBottomColor:
                        this.colorSettings?.selectable_btn_border_active_1 ||
                        'black',
                    },
                  ]}
                />
                {/* category title */}
                <View
                  style={[xs.categoryTitleContainer, styles.questionnaireTitle]}
                >
                  <Text
                    style={[
                      xs.categoryTitleTxt,
                      {
                        textAlign: 'center',
                        color:
                          this.colorSettings?.text_input_label_color_2 ||
                          'black',
                      },
                    ]}
                  >
                    {this.textToKey(disease?.short_name)}
                  </Text>
                </View>
                {this.renderListItems()}
              </FlexContainer>
            </ScrollView>
          </KeyboardAvoidingView>
          <ButtonDone
            title={i18n.t('done').default}
            onPress={() => this.doneButtonHandler()}
            colorSettings={this.colorSettings}
            disable={disableDoneButton}
          />
          <ModalLoading
            colorSettings={this.colorSettings}
            isVisible={savingDiseases}
            loadingTitle={i18n.t('saving_datanplease_wait').default}
          />
          {IS_MOBILE() && (
            <BottomSheetComponent
              reference={this.bottomSheetRef}
              startPoint={10}
              index={bottomSheetIndex}
              onSelectItemAction={this.handleBottomSheetPressButton}
              selectedItem={ageOfDiagnosisType}
              listItems={availableDiagnosisValues}
            />
          )}
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

// <Redux functions>
function mapGlobalStateToProps(state) {
  const {
    store,
    diseaseStore,
    accountStore,
    memberProfileStore,
    probandStore,
  } = state
  return {
    colorSettings: store.colorSettings,
    account: accountStore.account,
    disease: diseaseStore.individualDisease,
    selectedDiseases: diseaseStore.probandDiseases,
    fromMainDisease: diseaseStore.fromMainDisease,
    skipLogic: diseaseStore.skipLogic,
    authCredentials: accountStore.authCredentials,
    currentSelectedMember: memberProfileStore.currentMember,
    diseaseFromOnboarding: diseaseStore.fromOnboarding,
    probandProfile: probandStore.probandProfile,
    currentMemberProfile: memberProfileStore.memberProfile,
    isSingleCategoryDisease: diseaseStore.isSingleCategoryDisease,
  }
}

const actionCreators = {
  _saveProbandDisease_: setDiseaseProband,
  _saveSkipLogic_: setSkipLogic,
  _setShouldReloadDiseases_: setShouldReloadDiseases,
}

export default connect(mapGlobalStateToProps, actionCreators)(DiseasesSkipLogic)
