import { Dimensions, StyleSheet, Platform } from 'react-native'
import Constants from 'expo-constants'

const DefaultFullHeight = () => {
  let { height } = Dimensions.get('window')
  const { statusBarHeight } = Constants
  const navigationBarHeight = 44
  const primaryBtnHeight = 60

  height -= statusBarHeight
  height -= navigationBarHeight
  height -= primaryBtnHeight

  return height
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    width: '100%',
  },

  formContainer: {
    marginTop: 16,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 1.0,
  },

  forgotPasswordButtonText: {
    color: 'rgba(101,209,185,1)',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },

  showPasswordButtonContainer: {
    marginLeft: -26,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 1.0,
  },

  labeledSwitchContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 35,
    alignItems: 'center',
    // borderWidth: 1.0,
  },
  labeledSwitchText: {
    fontFamily: 'montserrat',
    fontSize: 14,
    color: 'rgba(74,74,74,0.8)',
  },
  errorRequiredText: {
    fontFamily: 'montserrat',
    fontSize: 12,
    color: 'red',
    fontWeight: '500',
    marginLeft: 3,
  },
})

export default styles
