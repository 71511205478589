import { Component } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  StatusBar,
} from 'react-native'

import { connect } from 'react-redux'
import CustomSubmitButton from 'components/custom-submit-button/CustomSubmitButton.js'
import { StackActions } from 'react-navigation'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import { i18n } from '$localization/config.js'

import {
  clearAuthCredentialsAction,
  clearAccountAction,
} from '$redux/account/actions.js'

import {
  clearProbandAction,
  clearProbandProfileAction,
  clearBasicFamilyMembers,
} from '$redux/proband/actions.js'

import { clearMemberProfileAction } from '$redux/member_profile/actions.js'

import { clearRiskFactorsAction } from '$redux/risk_factor/actions.js'

import { clearCliniciansAction } from '$redux/clinician/actions.js'

import { clearSurveysAction } from '$redux/survey/actions.js'

import { IS_WEB, IS_MOBILE, IOS } from '$constants/Platforms.js'
import { DefaultFullHeightForWeb } from '$constants/styles/global.styles.js'

class SuccessScreenPasswordChanged extends Component {
  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  state = {
    scrollViewHeight: DefaultFullHeightForWeb(-50),
  }

  componentDidMount = () => {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }
  }

  gotoLogin = () => {
    this.resetStoredData()

    // navigate to root Stack of specific tab
    const popAction = StackActions.pop({
      n: 100,
    })
    this.props.navigation.dispatch(popAction)

    this.props.navigation.navigate('LoginView')
  }

  resetStoredData = () => {
    /** Clears some data from redux store */
    this.props._clearAuthCredentialsFromStore_()
    this.props._clearAccountActionFromStore_()
    this.props._clearProbandFromStore_()
    this.props._clearProbandProfileFromStore_()
    this.props._clearMemberProfileFromStore_()
    this.props._clearRiskFactorsAction_()
    this.props._clearCliniciansAction_()
    this.props._clearSurveysAction_()
    this.props._clearBasicFamilyMembers_()
  }

  render() {
    let platformStyle = {}
    if (IS_WEB()) {
      platformStyle = {
        height: this.state.scrollViewHeight,
      }
    }
    if (IS_MOBILE()) {
      platformStyle = {
        flex: 1.0,
      }
    }

    return (
      <SafeAreaView style={[styles.mainContainer, platformStyle]}>
        {Platform.OS === IOS && <StatusBar barStyle="dark-content" />}
        <View style={styles.bodyContainer}>
          <View style={styles.imageContainer}>
            <Image
              source={require('$assets/images/new_assets/illus-sent.png')}
              style={{ width: 152, height: 141, resizeMode: 'contain' }}
            />
          </View>
          <View style={styles.titleContainer}>
            <Text
              style={[
                styles.title,
                { color: this.colorSettings?.text_input_title_color_1 },
              ]}
            >
              {i18n.t('success').default}
            </Text>
          </View>
          <View style={styles.contentContainer}>
            <Text
              style={[
                styles.content,
                { color: this.colorSettings?.text_input_title_color_1 },
              ]}
            >
              {i18n.t('your_password_has_been_changed').default}
            </Text>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <CustomSubmitButton
            onPress={this.gotoLogin}
            title={i18n.t('login_to_your_account').default}
            style={{
              backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
            }}
            titleStyle={{ color: this.colorSettings?.bottom_next_btn_text }}
          />
        </View>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyContainer: {
    width: '80%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    justifyContent: 'flex-end',
    height: '43%',
  },
  titleContainer: {
    justifyContent: 'center',
    height: '14%',
    marginTop: 10,
  },
  title: {
    fontFamily: 'karla-bold',
    fontSize: 24,
    color: 'rgb(74,74,74)',
    textAlign: 'center',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    height: '43%',
  },
  content: {
    fontFamily: 'montserrat',
    fontSize: 20,
    color: 'rgb(102,102,102)',
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
  },
})

function mapGlobalStateToProps(state) {
  const { store, accountStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _clearAuthCredentialsFromStore_: () =>
      dispatch(clearAuthCredentialsAction()),
    _clearAccountActionFromStore_: () => dispatch(clearAccountAction()),
    _clearProbandFromStore_: () => dispatch(clearProbandAction()),
    _clearProbandProfileFromStore_: () => dispatch(clearProbandProfileAction()),
    _clearMemberProfileFromStore_: () => dispatch(clearMemberProfileAction()),
    _clearRiskFactorsAction_: () => dispatch(clearRiskFactorsAction()),
    _clearCliniciansAction_: () => dispatch(clearCliniciansAction()),
    _clearSurveysAction_: () => dispatch(clearSurveysAction()),
    _clearBasicFamilyMembers_: () => dispatch(clearBasicFamilyMembers()),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(SuccessScreenPasswordChanged)
