import {
  StyleSheet,
  View,
  Text,
  SafeAreaView,
  Image,
  Modal,
  Platform,
  Dimensions,
} from 'react-native'

import { i18n } from '$localization/config.js'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderContainer: {
    backgroundColor: 'rgba(245, 245, 245, 1)',
    borderColor: 'rgba(101, 209, 185, 1)',
    borderWidth: 1.0,
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderMessageText: {
    fontFamily: 'karla',
    color: 'rgba(101, 209, 185, 1)',
    textAlign: 'center',
    fontSize: 12,
  },
  animatedImage: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
})

const LoadingView = ({
  message = i18n.t('please_wait')?.default,
  tintColor = 'black',
  textColor = 'black',
  backgroundColor = 'white',
  visible = true,
  dimBackground = false,
  useSafeAreaView = true,
  containerStyle = null,
}) => {
  if (!visible) return null

  const layout = (
    <View style={styles.loaderContainer}>
      <Image
        style={styles.animatedImage}
        source={require('$assets/images/loading.gif')}
      />
      <View style={{ height: 5 }} />
      <Text style={styles.loaderMessageText}>{message}</Text>
    </View>
  )

  const containerBackgroundColor = dimBackground
    ? 'rgba(0,0,0,0.6)'
    : 'transparent'

  if (useSafeAreaView) {
    return (
      <SafeAreaView
        style={[
          styles.container,
          {
            backgroundColor: containerBackgroundColor,
          },
          containerStyle,
        ]}
      >
        {layout}
      </SafeAreaView>
    )
  }
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: containerBackgroundColor,
        },
        containerStyle,
      ]}
    >
      {layout}
    </View>
  )
}

export default LoadingView
