/* React & React Native libraries */
import {
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
} from 'react-native'

/* Third-party libraries */
import { connect } from 'react-redux'

/* Custom Components and Constants */
import LoadingView from '$constants/LoadingView.js'

import SelectLanguageController from './controller.js'

/* Custom Utilities */
import { i18n } from '$localization/config.js'

/* Custom Stylesheets */
import global_styles from '$constants/styles/global.styles.js'

class SelectLanguageView extends SelectLanguageController {
  renderItem = (item, index) => {
    const { selectedItem } = this.state
    const backgroundColor = selectedItem === item ? '#ccc' : 'white'

    return (
      <View
        key={`${index}`}
        style={[
          global_styles.sectionItemContainer,
          {
            backgroundColor,
          },
        ]}
      >
        <View style={global_styles.sectionItemHorizontalContainer}>
          <TouchableOpacity
            style={global_styles.sectionItemTitle}
            activeOpacity={1.0}
            onPress={() => this.onPressItem(item)}
          >
            <Text style={global_styles.sectionItemLabelText}>
              {i18n.t(item).title}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  render() {
    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView style={global_styles.containerWhite}>
          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </SafeAreaView>
      )
    }

    return (
      <SafeAreaView style={global_styles.containerWhite}>
        <View
          style={[
            global_styles.content,
            {
              flex: 1.0,
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <FlatList
            style={{ flex: 1.0, width: '100%' }}
            data={this.state.listData}
            renderItem={({ item, index }) => this.renderItem(item, index)}
          />
        </View>

        <LoadingView
          backgroundColor={this.colorSettings?.splash_bgcolor}
          tintColor={this.colorSettings?.btn_no_fill_border_1}
          textColor={this.colorSettings?.btn_no_fill_text_1}
          visible={this.state.showLoadingView}
        />
      </SafeAreaView>
    )
  }
}

// <Redux functions>
function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    splashSettings: store.splashSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(SelectLanguageView)
