import { View } from 'react-native'
import { useSelector } from 'react-redux'
import uuid from 'react-native-uuid'
import PartnerView from '$screens/main/family/_includes/partners/Partners.view.js'
import FamilyCard from '$components/family-card/FamilyCard.js'
import {
  formatDisplayedMemberName,
  getGenderIcon,
  getGenderIconBorderRadius,
} from '$api/_utils/Utils.js'
import customStyles from '$screens/main/family/_styles/main.styles.js'
import calculateRelationship from '../../FamilyListScreen/helpers/calculateRelationship'

function ChildrenView({
  mainMember,
  fromPartner,
  navigateToMemberProfile,
  addToMemberButtonAction,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const children = fromPartner?.children
  const relationshipList = []

  if (!children || children.length === 0) return null

  const filteredChildren = children.filter((child) => !child.is_deleted)

  return (
    <>
      {filteredChildren.map((child) => {
        const titleText = formatDisplayedMemberName(
          child?.name,
          child?.age_string ?? child?.age,
          child?.is_dead
        )

        const { relationship, item } = calculateRelationship(
          child,
          relationshipList
        )

        return (
          <FamilyCard
            key={uuid.v4()}
            listKey={uuid.v4()}
            childrenView
            member={child}
            navigateToMemberProfile={() =>
              navigateToMemberProfile(child, false)
            }
            getGenderIcon={getGenderIcon(child?.gender, child?.is_dead)}
            getGenderIconBorderRadius={getGenderIconBorderRadius(
              child?.gender,
              child?.is_dead
            )}
            addToMemberButtonAction={() =>
              addToMemberButtonAction(child, item?.relationship)
            }
            relationship={relationship}
            item={item}
            customStyles={customStyles}
            titleText={titleText}
            colorSettings={colorSettings}
            component={
              <View
                style={[customStyles.itemViewContainer, { paddingLeft: 12 }]}
              >
                <PartnerView
                  listKey={uuid.v4()}
                  colorSettings={colorSettings}
                  navigateToMemberProfile={navigateToMemberProfile}
                  addToMemberButtonAction={(member) =>
                    addToMemberButtonAction(member, item?.relationship)
                  }
                  mainMember={child}
                  partners={item?.partners ?? child?.partners}
                />
              </View>
            }
          />
        )
      })}
    </>
  )
}

export default ChildrenView
