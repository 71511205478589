import { useState, useEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { i18n } from '$localization/config.js'

import EllipseButton from '$components/EllipseButton'
import DiseaseTextInput from '$components/disease-text-input/DiseaseTextInput.js'
import ToggleButtonComponent from '$components/toggle-button/ToggleButtonComponent.js'

import {
  adjustFontSizeByLength,
  convertTextToSnakeCase,
  removePunctuationMarksFromString,
} from '$api/_utils/Utils.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

const SkipLogicList = (props) => {
  const { colorSettings } = props

  const [highlightButton, toggleHighlightButton] = useState(null)
  const [memberData, modifyMemberData] = useState(props.memberData)

  useEffect(() => {
    const ageDiagnosed = props.memberDiseaseAOD

    // check if memberData if empty
    if (ageDiagnosed !== '') {
      if (typeof props.memberData !== 'undefined') {
        toggleHighlightButton(true)
      } else {
        toggleHighlightButton(false)
      }
    }
  }, [])

  useEffect(() => {
    props.onMemberUpdate(memberData)
  }, [memberData, modifyMemberData])

  const hasNumber = (value) => {
    if (value === null) {
      return false
    }
    return /\d/.test(value)
  }

  const invalidAgeAlert = (
    title = i18n.t('invalid_age_of_diagnosis').default,
    message = i18n.t(
      'the_age_of_diagnosis_cannot_be_greater_than_this_persons_current_ageage_of_death'
    ).default
  ) => {
    showDefaultAlert(title, message)
  }

  const ageDiscrepancyChecker = () => {
    invalidAgeAlert()
    modifyMemberData((prevState) => ({ ...prevState, answer: 0 }))
  }

  const ageInputValidation = () => {
    const currentAge = props.profileData.profileData.currentAge || 0
    const ageOfDeath = props.profileData.profileData.ageOfDeath || 0

    if (currentAge !== 0) {
      if (memberData.answer > currentAge) {
        ageDiscrepancyChecker()
      }
    } else if (ageOfDeath !== 0) {
      if (memberData.answer > ageOfDeath) {
        ageDiscrepancyChecker()
      }
    }
  }

  const handleInputChange = (val) => {
    const ageInput = val.nativeEvent.text
    modifyMemberData((prevState) => ({ ...prevState, answer: ageInput }))
  }

  const handleList = () => {
    if (props.diseaseData.question_type === 'yes_or_no') {
      return
    }

    return (
      <View style={{ alignItems: 'center', marginVertical: 16 }}>
        <View style={{ marginBottom: 20 }}>
          <Text
            style={{
              color: colorSettings?.text_input_title_color_2,
              fontFamily: 'karla-bold',
              fontSize: 18,
              textAlign: 'center',
            }}
          >
            {`${i18n.t('age_of_second_diagnosis').default}:`}
          </Text>
        </View>

        <DiseaseTextInput
          value={memberData.answer}
          containerStyle={{ marginBottom: 10 }}
          returnKeyType="done"
          onChange={(val) => {
            handleInputChange(val)
          }}
          onEndEditing={() => {
            ageInputValidation()
          }}
        />
      </View>
    )
  }

  const itemFilter = (val, toggle) => {
    if (props.diseaseData.question_type === 'yes_or_no') {
      // use this setting for single type question
      if (toggle) {
        if (val) {
          return 'y'
        }
        return 'n'
        // use this setting for multiple questions
      }
      if (val) {
        return 'n'
      }
      return 'y'
    }
    // question with numerical answer
    if (val) {
      return 'n'
    }
    return '0'
  }

  const handleOnPress = (item, toggle = false) => {
    const newMemberData = JSON.parse(JSON.stringify(props.diseaseData))
    newMemberData.answer = itemFilter(item, toggle)
    newMemberData.skip_logic_id = props.diseaseData.id
    newMemberData.id = `SL${props.diseaseData.id}`

    modifyMemberData(newMemberData)
    if (toggle) {
      toggleHighlightButton(item)
    } else {
      toggleHighlightButton((val) => !val)
    }
  }

  const textToKey = (title) => {
    const key = title
      .toLowerCase()
      .split(' ')
      .join('_')
      .replace(/\//g, '_')
      .replace(/[^-0-9a-zA-Z _]/g, '')

    return i18n.t(key).default
  }

  const handleEllipseButton = (shouldRender, diseaseAnswer) => {
    if (shouldRender && !hasNumber(diseaseAnswer)) {
      let _skipLogicQuestion_ = convertTextToSnakeCase(
        props.diseaseData.question
      )
      _skipLogicQuestion_ =
        removePunctuationMarksFromString(_skipLogicQuestion_)
      const skipLogicQuestion = i18n.t(_skipLogicQuestion_).default

      return (
        <ToggleButtonComponent
          colorSettings={colorSettings}
          title={skipLogicQuestion}
          isToggled={highlightButton}
          onToggle={(item) => {
            // item !== highlightButton ? handleOnPress(!item) : null;
            handleOnPress(item, true)
          }}
        />
      )
    }

    return (
      <EllipseButton
        onPress={() => handleOnPress(highlightButton)}
        buttonStyle={{
          backgroundColor: highlightButton
            ? colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
            : colorSettings?.selectable_btn_inactive_1,
          borderColor: highlightButton
            ? colorSettings?.selectable_btn_border_active_1
            : colorSettings?.selectable_btn_border_inactive_1,
        }}
      >
        <Text
          style={[
            styles.roundedButtonTitleText,
            {
              color: colorSettings?.selectable_btn_text_active_1,
              fontSize: adjustFontSizeByLength(props.diseaseData.question),
            },
          ]}
        >
          {textToKey(props.diseaseData.question)}
        </Text>
      </EllipseButton>
    )
  }

  return (
    <View style={[styles.collapsableContainer, props.containerStyle]}>
      {handleEllipseButton(props.singleSkipLogic, props.diseaseData.answer)}
      {highlightButton && handleList(highlightButton)}
    </View>
  )
}

const styles = StyleSheet.create({
  collapsableContainer: {
    marginBottom: 10,
  },
  roundedButtonTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'center',
  },
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default SkipLogicList
