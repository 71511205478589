import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import { LabelField } from '../components'

export default function LabelHandler({
  questionLabel,
  colorSettings,
  masterQuestion,
}) {
  let label = <View />
  if (!questionLabel) return label

  label = (
    <LabelField colorSettings={colorSettings} masterQuestion={masterQuestion} />
  )

  return label
}

LabelHandler.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.objectOf(PropTypes.string),
  questionLabel: PropTypes.string,
}

LabelHandler.defaultProps = {
  colorSettings: [],
  questionLabel: '',
}
