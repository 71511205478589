import {
  Image,
  View,
  SafeAreaView,
  Text,
  SectionList,
  TouchableOpacity,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'
import { i18n } from '$localization/config.js'

import ClinicianListController from './ClinicianList.controller.js'

import { setError } from '$redux/defaults/actions.js'

import LoadingView from '$constants/LoadingView.js'
import { ConnectionStatusColor } from '$screens/main/dashboard/clinicians/_utils/utils.js'

import { isEmptyObject } from '$api/_utils/Utils.js'

import {
  setCliniciansAction,
  setSelectedClinicianAction,
} from '$redux/clinician/actions.js'

import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'
import styles from '../_styles/main.styles.js'

import { IS_WEB, IS_MOBILE } from '$constants/Platforms.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

class ClinicianListView extends ClinicianListController {
  renderItem = (item, index, section) => {
    if (!item || isEmptyObject(item)) {
      return null
    }

    let _statusText_ = 'pending'
    switch (item.status) {
      case 'two-way-connected':
        _statusText_ = 'connected'
        break
      case 'one-way-connected':
        _statusText_ = 'connected_clinician_opted_out_of_updates'
        break
      default:
        _statusText_ = item.status
        break
    }

    return (
      <TouchableOpacity
        disabled={item.status === 'pending'}
        style={[
          styles.itemView,
          { backgroundColor: this.colorSettings?.member_tile_bg || 'white' },
        ]}
        activeOpacity={0.5}
        onPress={() => this.navigateToClinicianDetails(item)}
      >
        <View
          style={[
            styles.itemImageContainer,
            { flex: Platform.OS === 'web' ? 0.15 : 0.2 },
          ]}
        >
          <Image
            source={require('$assets/images/new_assets/avatar-clinician.png')}
            style={{ width: 40, height: 40, resizeMode: 'contain' }}
          />
        </View>
        <View
          style={[
            styles.itemDetailsContainer,
            { flex: Platform.OS === 'web' ? 0.85 : 0.8 },
          ]}
        >
          <Text
            style={[
              styles.itemDetailsTitleText,
              { color: this.colorSettings?.member_tile_title },
            ]}
          >
            {item.organization.org_name}
          </Text>
          <View style={{ height: 5 }} />
          <Text
            style={[
              styles.itemDetailsSubtitleText,
              { color: ConnectionStatusColor(item.status, this.colorSettings) },
            ]}
          >
            {i18n.t(_statusText_).default}
          </Text>
        </View>
      </TouchableOpacity>
    )
  }

  render() {
    const { navigation } = this.props
    let platformStyle = {}

    if (IS_WEB()) {
      platformStyle = {
        height: DefaultFullHeight(),
      }
    }

    if (IS_MOBILE()) {
      platformStyle = {
        flex: 1.0,
      }
    }

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView style={[styles.container, platformStyle]}>
          <LoadingView
            visible={this.state.showLoadingView}
            tintColor={this.colorSettings?.post_onboarding_nav_bgcolor}
            textColor={this.colorSettings?.post_onboarding_nav_bgcolor}
          />
        </SafeAreaView>
      )
    }

    if (this.sectionListData.length === 0) {
      return (
        <MainWrapper navigation={navigation}>
          <SafeAreaView style={[styles.container, platformStyle]}>
            <View
              style={[
                styles.content,
                {
                  flex: 1.0,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              ]}
            >
              <Text style={global_styles.emptyListHelperText}>
                {i18n.t('you_havent_added_any_clinicians_yet').default}
              </Text>
            </View>
          </SafeAreaView>
        </MainWrapper>
      )
    }
    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[styles.container, platformStyle]}
            nativeID="web_scaled_main_container"
          >
            <View
              style={[
                styles.content,
                {
                  flex: 1.0,
                  justifyContent: 'center',
                },
              ]}
            >
              <SectionList
                style={{ paddingTop: 16 }}
                sections={this.sectionListData}
                keyExtractor={(item, index) => index}
                renderSectionHeader={({ section }) => null}
                renderItem={({ item, index, section }) =>
                  this.renderItem(item, index, section)
                }
              />
            </View>
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, clinicianStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** Clinican Store */
    clinicians: clinicianStore.clinicians,
    selectedClinician: clinicianStore.selectedClinician,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setCliniciansAction_: (data) => dispatch(setCliniciansAction(data)),
    _setSelectedClinicianAction_: (data) =>
      dispatch(setSelectedClinicianAction(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(ClinicianListView)
