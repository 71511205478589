import { StyleSheet, Platform } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(245,245,245)',
    width: '100%',
    flex: 1.0,
  },
  content: {
    // backgroundColor: "green",
    paddingHorizontal: 16,
    flex: 1.0,
  },

  contentCentered: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1.0,
  },

  headerContainer: {
    height: 60,
    justifyContent: 'center',
  },
  headerTitleText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: 'rgb(65,70,97)',
  },

  listContainer: {
    shadowOpacity: 0.5,
    shadowColor: 'rgb(187,187,187)',
    shadowOffset: { width: 0, height: 2 },
    flex: 1.0,
  },
  listItemContainer: {
    backgroundColor: 'white',
    marginVertical: 5,
    marginHorizontal: 0,
    borderRadius: 10,
    shadowOpacity: 0.5,
    shadowColor: 'rgb(187,187,187)',
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 2,
    // overflow: "hidden",
  },
  listItemContent: {
    paddingVertical: 16,
    marginHorizontal: 16,
    // borderWidth: 1.0,
  },

  listItemContentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    // borderWidth: 1.0,
  },
  listItemContentHeaderTitle: {
    color: 'rgb(74,74,74)',
    fontFamily: 'karla-bold',
    fontSize: 13,
    textAlign: 'left',
  },

  listItemContentBody: {},
  listItemContentTitle: {
    color: 'rgb(74,74,74)',
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },
  listItemContentText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontSize: 12,
    textAlign: 'left',
  },
  listItemDateTimeText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'karla',
    fontSize: 11,
    textAlign: 'left',
  },

  listItemContentFooter: {
    // alignItems: "center",
    justifyContent: 'center',
    // paddingBottom: 16
  },

  listItemTitleContainer: {
    // backgroundColor: "pink",
    justifyContent: 'center',
    paddingHorizontal: 16,
    flex: 1.0,
  },
  listItemTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    color: 'rgb(74,74,74)',
  },
  listItemSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
    color: 'rgb(102,102,102)',
  },

  listItemButtonContainer: {
    width: 75,
    height: 30,
    borderRadius: 15,
    borderWidth: 2.0,
    // borderColor: "rgba(74,74,74,1.0)",
    borderColor: 'rgb(219,219,219)',
  },
  listItemButton: {
    flex: 1.0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemButtonTitle: {
    fontFamily: 'montserrat-medium',
    fontSize: 10,
  },
  notificationItemContent: {
    ...Platform.select({
      web: {
        maxWidth: 800,
        // minWidth: 500,
        width: '100%',
        alignSelf: 'center',
      },
    }),
    marginTop: 20,
  },
  controlsContainer: {
    borderColor: '#ccc',
    borderTopWidth: 1.0,
    height: 60,
  },
  stackContainerHorizontal: {
    height: 50,
    flexDirection: 'row',
    flex: 1.0,
  },
  controlButtonContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlButton: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  controlButtonText: {
    color: 'rgb(153,153,153)',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },
  controlButtonSeparator: {
    backgroundColor: '#ccc',
    height: '100%',
    width: 1.0,
  },
  notifDismissButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 45,
  },
  notifDismissButtonText: {
    color: 'rgba(117,84,136,1.0)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },
  scrollViewRenderListContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollViewRenderListContainer2: { paddingVertical: 20 },
  listDataHeaderContainer: {
    color: 'rgb(74,74,74)',
    textAlign: 'center',
    paddingHorizontal: 24,
  },
})

export default styles
