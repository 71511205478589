import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  FlatList,
  SafeAreaView,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from 'accordion-collapse-react-native'
import globalStyles, {
  DefaultFullHeight,
} from 'constants/styles/global.styles.js'
import Icon from 'react-native-vector-icons/Feather'
import { i18n } from '$localization/config.js'

import SelectButton from './personalScreenDiagnosisB_component/SelectButton.js'
import ToggleButton from './personalScreenDiagnosisB_component/ToggleButtonComponent.js'

import Account from '$data_models/Account'
import Proband from '$data_models/Proband'
import GeneTesting from '$data_models/GeneTesting'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

class PersonalScreenDiagnosisC extends Component {
  isRelative = this.props.route.params?.isRelative ?? false

  testSource = this.props.route.params?.test ?? null

  positiveTest = this.props.route.params?.positiveTests ?? null

  geneList = this.props.route.params?.geneList ?? null

  account = new Account()

  proband = new Proband()

  geneTesting = new GeneTesting()

  colorSettings = this.props.route.params?.colorSettings ?? null

  genesListAPI = this.geneList

  geneNames = []

  constructor(props) {
    super(props)

    // Get Account and Proband data from previous screen
    this.account = this.props.route.params?.account
    this.proband = this.props.route.params?.proband ?? new Proband()
    this.geneTesting = this.props.route.params?.geneTest ?? new GeneTesting()

    this.state = {
      negativeTest: '',
      toggled: false,
      genes: [],
      scrollViewHeight: DefaultFullHeight(),
    }

    if (this.colorSettings == null) {
      this.colorSettings = this.props.colorSettings
    }
  }

  componentDidMount() {
    if (Platform.OS === 'web') {
      window.addEventListener('resize', this.screenResizeHandler)
    }
    this.updateGeneList(this.genesListAPI)
  }

  componentWillUnmount() {
    if (Platform.OS === 'web') {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  addGene = (id) => {
    const geneList = this.genesListAPI
    const { genes } = this.state
    const newList = {}

    for (const val in geneList) {
      if (geneList[val].id == id) {
        newList.genetic_testing_id = id
        newList.result = 'N'
        genes.push(newList)
        this.setState({ geneList })
      }
    }
  }

  removeGene = (id) => {
    const { genes } = this.state

    for (const val in genes) {
      if (genes[val].genetic_testing_id == id) {
        genes.splice(val, 1)
      }
    }
  }

  updateGeneList = (genesList) => {
    for (const val in genesList) {
      const name = {}
      name.key = genesList[val].gene
      name.id = genesList[val].id
      this.geneNames.push(name)
    }

    // this.forceUpdate()
  }

  toggleButton = (trigerButton, toggle) => {
    const button = this.state
    button[trigerButton] = toggle == 1
    this.setState({ button })

    if (this.state.negativeTest !== '') {
      this.setState({ toggled: true })
    }
  }

  nextButtonAction = () => {
    const { genes } = this.state
    const proband = this.proband.memberGeneticTesting

    this.geneTesting.negativeTest = this.state.genes

    if (this.state.negativeTest) {
      const positive = this.geneTesting.postiveTest
      const negative = this.geneTesting.negativeTest

      this.proband.memberGeneticTesting = positive.concat(negative)

      const memberGenesList = this.proband.memberGeneticTesting
      const newGenes = {
        genetic_testing: [],
      }

      for (const val in memberGenesList) {
        const genes = {}
        genes.genetic_testing_id = memberGenesList[val].genetic_testing_id
        genes.result = memberGenesList[val].result.toLowerCase()
        newGenes.genetic_testing.push(genes)
      }

      this.proband.memberGeneticTesting = newGenes

      this.props.navigation.navigate('PersonalDetailsNavigator', {
        screen: 'PersonalEthnicity',
        params: {
          account: this.account,
          proband: this.proband,
        },
      })
    } else {
      this.geneTesting.negativeTest = []
      this.props.navigation.navigate('PersonalDetailsNavigator', {
        screen: 'PersonalEthnicity',
        params: {
          account: this.account,
          proband: this.proband,
        },
      })
    }

    const genetestCheck = this.proband.memberGeneticTesting.genetic_testing
    if (!genetestCheck) {
      this.proband.memberGeneticTesting = { genetic_testing: [] }
    }
  }

  toolTipHandler = () => {
    showDefaultAlert(
      i18n.t('test_negative').default,
      i18n.t(
        'testing_negative_means_that_a_change_or_mutation_was_not_found_in_the_gene_this_could_also_be_worded_as_benign_or_likely_benign_or_normal_on_a_report'
      ).default
    )
  }

  negativeGeneListHandler = () => {
    if (this.state.negativeTest) {
      return (
        <View style={{ alignItems: 'center', width: '100%' }}>
          <View style={[styles.horizontalBorder, { marginBottom: 15 }]} />
          <Text
            style={[
              styles.titleText,
              {
                color: this.colorSettings?.text_input_title_color_2 || 'black',
                fontSize: 18,
              },
            ]}
          >
            Select all that apply
          </Text>
          <View
            style={[
              styles.buttonContainerAdopted,
              {
                flexDirection: 'column',
                marginBottom: 10,
                justifyContent: 'center',
                flex: 1,
              },
            ]}
          >
            <FlatList
              style={globalStyles.diagnosisList}
              data={this.geneNames}
              renderItem={({ item }) => (
                <SelectButton
                  id={item.id}
                  parent={this}
                  genesAPI={this.genesListAPI}
                  colorSettings={this.colorSettings}
                />
              )}
            />
          </View>
        </View>
      )
    }
  }

  render() {
    return (
      <SafeAreaView
        style={[
          styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor || 'white',
          },
        ]}
      >
        {/* Content Body */}
        <ScrollView
          style={{ height: this.state.scrollViewHeight }}
          contentContainerStyle={styles.contentContainer}
        >
          {/* main title */}
          <View style={styles.titleContainer}>
            <Text
              style={[
                styles.titleText,
                {
                  color:
                    this.colorSettings?.text_input_title_color_2 || 'black',
                },
              ]}
            >
              {i18n.t('did_you_test_negative_for_any_genes').default}
            </Text>
            <View>
              <TouchableOpacity
                style={{ padding: 10 }}
                onPress={() => this.toolTipHandler()}
              >
                <Icon
                  name="info"
                  size={20}
                  color={
                    this.colorSettings?.text_input_title_color_2 || 'black'
                  }
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.buttonContainerAdopted}>
            <ToggleButton
              buttonClass="negativeTest"
              parent={this}
              buttonStyle={{ marginTop: 5 }}
              colorSettings={this.colorSettings}
            />
          </View>

          {Platform.OS == 'web' && this.negativeGeneListHandler()}

          {Platform.OS != 'web' && (
            <Collapse
              style={{ width: '100%' }}
              diasbled
              isCollapsed={this.state.negativeTest}
            >
              <CollapseHeader />
              <CollapseBody>
                {/* horizontal border */}
                <View
                  style={[
                    styles.horizontalBorder,
                    {
                      marginBottom: 15,
                      borderBottomColor:
                        this.colorSettings?.selectable_btn_border_active_1 ||
                        'black',
                    },
                  ]}
                />

                <Text
                  style={[
                    styles.titleText,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                      fontSize: 18,
                    },
                  ]}
                >
                  {i18n.t('select_all_that_apply').default}
                </Text>

                <View
                  style={[
                    styles.buttonContainerAdopted,
                    {
                      flexDirection: 'column',
                      marginBottom: 10,
                      justifyContent: 'center',
                    },
                  ]}
                >
                  <FlatList
                    style={{ width: '100%' }}
                    data={this.geneNames}
                    renderItem={({ item }) => (
                      <SelectButton
                        id={item.id}
                        parent={this}
                        genesAPI={this.genesListAPI}
                        colorSettings={this.colorSettings}
                      />
                    )}
                  />
                </View>
              </CollapseBody>
            </Collapse>
          )}
        </ScrollView>

        {/* Next button */}
        <View style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}>
          <View style={styles.nextButtonContainer}>
            <TouchableOpacity
              disabled={!this.state.toggled}
              style={[
                styles.nextButton,
                {
                  backgroundColor: this.state.toggled
                    ? this.colorSettings?.bottom_next_btn_enabled || 'white'
                    : this.colorSettings?.bottom_next_btn_disabled,
                  borderColor: this.state.toggled
                    ? this.colorSettings?.bottom_next_btn_enabled || 'black'
                    : this.colorSettings?.bottom_next_btn_disabled || 'black',
                  borderWidth: 1.0,
                },
              ]}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  styles.nextButtonText,
                  {
                    color: this.colorSettings?.bottom_next_btn_text || 'black',
                  },
                ]}
              >
                {i18n.t('done').default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "rgba(155,89,182,1.0)",
    flex: 1.0,
  },
  contentContainer: {
    // backgroundColor: "red",
    marginHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '100%',
  },
  itemButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 340,
    height: 60,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 30,
  },
  itemButtonText: {
    // backgroundColor: "black",
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  subItemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 195,
    height: 40,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 20,
  },
  subItemButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  switchLabelText: {
    color: '#fff',
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },
  nextButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '45%',
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonContainerAdopted: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 10,
  },
  controlButton: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    // backgroundColor: "black",
    width: 80,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
  },
  horizontalBorder: {
    width: '120%',
    borderBottomWidth: 0.7,
    borderBottomColor: 'rgba(255,255,255,.3)',
    marginTop: 40,
    alignSelf: 'center',
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
  },
  nextButtonContainer: {
    height: 60,
  },
})

// <Redux functions>
function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(PersonalScreenDiagnosisC)
