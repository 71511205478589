import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  Platform,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { connect } from 'react-redux'

import LoadingView from '$constants/LoadingView.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { i18n } from '$localization/config.js'

import convertText from '$screens/_utils/TextConverterForTranslation.js'
import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

import { setProbandImmediateFamily } from '$redux/proband/actions.js'
import { setError } from '$redux/defaults/actions.js'

import { DefaultFullHeight } from '$constants/styles/global.styles.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { SearchInput, AncestrySearchModal } from './components'
import { memberAncestry, firstListDiff, secondListDiff } from './helpers'
import styles from './assets/styles'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

// const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.PersonalAncestry?.name

let hasGenderIdentity = false
let isLastScreenBeforeLogin = false
const ancestry = require('./data/mock-data-ancestry.json')

class Ancestry extends Component {
  colorSettings = this.props.colorSettings

  account = this.props.account

  proband = this.props.proband

  authCredentials = this.props.authCredentials

  clinician = this.props.clinician

  motherID = null

  fatherID = null

  constructor(props) {
    super(props)

    this.state = {
      showLoadingView: false,
      showLoadingText: i18n.t('please_wait').default,
      modalVisible: false,
      parent: null,
      fathersAncestry: [],
      mothersAncestry: [],
      scrollViewHeight: DefaultFullHeight(),
      probandDetails: null,
      ancestryList: [],
    }
  }

  async componentDidMount() {
    const { probandProfile, lastScreenLogin, navigation, colorSettings } =
      this.props

    if (Platform.OS === 'web') {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    if (probandProfile && probandProfile.profileData.genderIdentity) {
      hasGenderIdentity = true
    } else {
      hasGenderIdentity = false
    }

    isLastScreenBeforeLogin = lastScreenLogin === 'ancestry'

    navigation.setParams({
      cs: colorSettings,
      hasGenderIdentity,
      isLastScreenBeforeLogin,
    })

    if (probandProfile) {
      await this.setParentsAncestryOnMount()
    }
  }

  componentWillUnmount() {
    const { navigation, colorSettings } = this.props

    if (Platform.OS === 'web') {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
    navigation.setParams({
      cs: colorSettings,
    })
  }

  getParentDetails = ({ ancestryId, parent, selectedAncestryId }) => {
    if (!parent) return
    const { probandProfile, authCredentials } = this.props
    let parentId

    if (parent === 'mother') {
      parentId = probandProfile?.relationshipData?.mother?.id
    } else {
      parentId = probandProfile?.relationshipData?.father?.id
    }

    const getParentAncestry = memberAncestry({
      memberId: parentId,
      ancestryId,
      authCredentials,
      errorHandler: this.errorHandler,
      selectedAncestryId,
    })

    return getParentAncestry
  }

  setParentsAncestryOnMount = async () => {
    const { mothersAncestry, fathersAncestry } = await this.getParentAncestry()

    this.setState({
      showLoadingView: false,
      mothersAncestry,
      fathersAncestry,
    })
  }

  getParentAncestry = async (parent) => {
    const getMothersAncestry = this.getParentDetails({ parent: 'mother' })
    const getFathersAncestry = this.getParentDetails({ parent: 'father' })
    const mothersAncestry =
      this.updateParentData(await getMothersAncestry.get()) ?? []
    const fathersAncestry =
      this.updateParentData(await getFathersAncestry.get()) ?? []

    if (parent === 'mother') {
      return mothersAncestry
    }
    if (parent === 'father') {
      return fathersAncestry
    }

    const parentAncestries = {
      mothersAncestry,
      fathersAncestry,
    }
    return parentAncestries
  }

  getAncestry = async (memberData, authCredentials) => {
    const { selectedLanguage } = this.props
    let parentData
    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'get',
      token: authCredentials.accessToken,
      body: { ...memberData, lang: selectedLanguage },
      pageDetails: {
        page: 'Ancestry.js',
      },
    }

    const response = await ApiFetchHandler(getMemberProfilePayload)

    if (response.isError) {
      this.errorHandler(response)
      return
    }

    const parentAncestry = parentData?.data?.member_info?.ancestry
    const convert = this.convertFetchedAncestry(parentAncestry)

    return convert
  }

  convertFetchedAncestry = (parentAncestry) => {
    if (!parentAncestry) return
    const splitAncestry = parentAncestry.split(',')
    const translatedAncestry = splitAncestry.map((item) => {
      const ancestryValue = ancestry.find((val) => val.key === item)

      return {
        key: convertText(i18n.t(convertText(item)).lowercase),
        value: i18n.t(convertText(ancestryValue.value)).default,
        originalKey: item,
      }
    })

    return translatedAncestry
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  errorHandler = (response) => {
    const { saveError } = this.props
    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
  }

  updateParentData = (parentData) => {
    if (!parentData) return []

    const newSelection = parentData.map((item) => ({
      ...item,
      selected: true,
      display_name:
        i18n.t(convertText(item.display_name)).default || item.display_name,
    }))

    return newSelection
  }

  setModalVisible(visible, parent = null) {
    this.setState({
      modalVisible: visible,
      parent,
    })
  }

  handleAddingAncestry = async (selectedAncestries) => {
    const { parent, ancestryData } = selectedAncestries
    const { mothersAncestry, fathersAncestry } = this.state
    let selectedParent

    if (parent === 'mother') {
      selectedParent = mothersAncestry
    } else {
      selectedParent = fathersAncestry
    }

    const toAddSelection = firstListDiff(ancestryData, selectedParent)
    const toRemoveSelection = secondListDiff(ancestryData, selectedParent)

    if (toAddSelection.length > 0) {
      for (const data of toAddSelection) {
        const ancestry = this.getParentDetails({
          parent,
          ancestryId: data.ancestry_id,
        })
        await ancestry.add()
      }
    }

    if (toRemoveSelection.length > 0) {
      for (const data of toRemoveSelection) {
        this.handleRemovingAncestry({
          parent,
          selectedAncestryId: data.id,
        })
      }
    }

    const getParentAncestry = await this.getParentAncestry(parent)
    return getParentAncestry
  }

  handleRemovingAncestry = async (removeAncestryItems) => {
    const { parent, selectedAncestryId, removeIndex } = removeAncestryItems
    const ancestry = this.getParentDetails({ parent, selectedAncestryId })
    ancestry.remove()
  }

  removeAncestry(member, parent) {
    const { fathersAncestry, mothersAncestry } = this.state

    if (parent === 'father') {
      const newData = fathersAncestry
      const selectedIndex = newData.findIndex(
        (item) => item.ancestry_id === member.ancestry_id
      )
      this.handleRemovingAncestry({
        parent: 'father',
        selectedAncestryId: member.id,
        removeIndex: selectedIndex,
      })
      newData.splice(selectedIndex, 1)
      this.setState({ fathersAncestry: newData })
    } else if (parent === 'mother') {
      const newData = mothersAncestry
      const selectedIndex = newData.findIndex(
        (item) => item.ancestry_id === member.ancestry_id
      )
      this.handleRemovingAncestry({
        parent: 'mother',
        selectedAncestryId: member.id,
        removeIndex: selectedIndex,
      })
      newData.splice(selectedIndex, 1)
      this.setState({ mothersAncestry: newData })
    }
  }

  renderSelectedAncestry = async (data, parent) => {
    if (data.length === 0) return
    this.setState({ showLoadingView: true })
    if (parent === 'father') {
      this.handleAddingAncestry({
        parent: 'father',
        ancestryData: data,
      })
      this.setState({ fathersAncestry: data, showLoadingView: false })
    } else {
      this.handleAddingAncestry({
        parent: 'mother',
        ancestryData: data,
      })
      this.setState({ mothersAncestry: data, showLoadingView: false })
    }
  }

  nextButtonAction = () => {
    this.setState({
      showLoadingView: true,
      // showLoadingText: i18n.t('creating_proband').default
    })

    // this.createProband()
    setTimeout(() => {
      this.setParentsAncestry()
    }, 200)
  }

  // NOTE: need to test
  // to be deprecated
  createProband = async () => {
    // Create API request to create Proband data.
    if (!this.account) {
      return
    }

    // Connect to API endpoint
    const createProbandPayload = {
      path: 'proband/add/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband: {
          account_id: this.account.accountID,
          email: this.account.email,
          first_name: this.account.firstName,
          middle_name: '',
          last_name: this.account.lastName,
          // "gender": ("" + account.gender).toUpperCase(),
          age: this.account.age,
          dob: this.account.dob,
          phone_number: this.account.phoneNumber,
          patient_id: this.proband.patientID,
          gender: `${this.proband.biologicalGender}`.toLowerCase(),
          gender_identity: this.proband.genderIdentity,
          pronoun: this.proband.pronoun,
          race: this.proband.race,
          ethnicity: this.proband.ethnicity,
          ashkenazi: this.proband.ashkenazi,
          patient_adopted: this.proband.patientAdopted,
          father_adopted: this.proband.fatherAdopted,
          mother_adopted: this.proband.motherAdopted,
          clinician_code: this.clinician?.clinician_code?.code ?? null,
        },
        member_disease: this.proband.memberDisease,
        member_skip_logic: this.proband.memberSkipLogic,
        member_genetic_testing: this.proband.memberGeneticTesting,
      },
      pageDetails: {
        page: 'Ancestry.js',
      },
    }

    const response = await ApiFetchHandler(createProbandPayload)
    const { saveError, _saveProbandImmediateFamily_ } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    // DONE: save data to proband redux

    const object = response.data

    const familyData = {
      familyID: object.family_id,
      probandID: object.proband_id,
    }

    _saveProbandImmediateFamily_(familyData)

    this.setState({
      showLoadingText: i18n.t('setting_up_parent_ancestry').default,
      probandDetails: {
        ...this.proband,
        ...familyData,
      },
    })
    setTimeout(async () => {
      await this.setParentID()
    }, 200)
  }

  setParentsAncestry = async () => {
    const { probandProfile, navigation, account, authCredentials, saveError } =
      this.props

    this.motherID = probandProfile.relationshipData.mother.id
    this.fatherID = probandProfile.relationshipData.father.id
    const probandID = probandProfile.memberID

    if (
      this.state.fathersAncestry !== null &&
      this.state.fathersAncestry.length != 0
    ) {
      await this.updateAncestry('father', probandID)
    }

    if (
      this.state.mothersAncestry !== null &&
      this.state.mothersAncestry.length != 0
    ) {
      await this.updateAncestry('mother', probandID)
    }

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.ancestry,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    this.setState({ showLoadingView: false })

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.SuccessScreenPersonalHealthInfo.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  // DONE: refer to redux
  // to be deprecated
  setParentID = async () => {
    const { probandDetails } = this.state
    if (!(!probandDetails || probandDetails === '')) {
      const proband = probandDetails

      const getBasicFamilyMembersPayload = {
        path: `family/${familyID}/members/`,
        method: 'post',
        token: this.authCredentials.accessToken,
        body: { proband_id: proband.probandID },
        pageDetails: {
          page: 'Ancestry.js',
        },
      }

      const response = await ApiFetchHandler(getBasicFamilyMembersPayload)
      const { saveError } = this.props

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        return
      }

      response.parents.map((parent) => {
        if (parent.relationship_to_proband === 'mother') {
          this.motherID = parent.member_id
        } else if (parent.relationship_to_proband === 'father') {
          this.fatherID = parent.member_id
        }
      })

      if (
        this.state.fathersAncestry !== null &&
        this.state.fathersAncestry.length != 0
      ) {
        this.updateAncestry('father')
      }

      if (
        this.state.mothersAncestry !== null &&
        this.state.mothersAncestry.length != 0
      ) {
        this.updateAncestry('mother')
      }
      this.setState({
        showLoadingText: i18n.t('ancestry_updated').default,
      })

      this.setState({
        showLoadingView: false,
      })
      // DONE: remove account and proband
      this.props.navigation.navigate('SuccessScreenPersonalHealthInfo')
    }
  }

  // DONE: refer to proband redux
  updateAncestry = async (parent, probandID) => {
    if (!probandID || probandID === '') {
      return
    }

    const memberID = parent === 'father' ? this.fatherID : this.motherID
    const ancestryData =
      parent === 'father'
        ? this.state.fathersAncestry
        : this.state.mothersAncestry
    const ancestryString = ancestryData
      .map((item) => item.originalKey)
      .join(',')

    const updateAncestryPayload = {
      path: `member/${memberID}/update_ancestry/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
        ancestry: ancestryString,
      },
      pageDetails: {
        page: 'Ancestry.js',
      },
    }

    const response = await ApiFetchHandler(updateAncestryPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }
  }

  render() {
    // TODO: Use this customize the top, middle or bottom screen text coming from Django
    // const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

    const { ancestryList } = this.state

    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            styles.container,
            {
              backgroundColor:
                this.colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          {/* Content Body */}
          <ScrollView
            style={{ height: this.state.scrollViewHeight }}
            contentContainerStyle={styles.contentContainer}
          >
            <View style={styles.searchInputMainContainer}>
              {/* <Title text container> */}
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text
                  style={[
                    styles.titleText,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                    },
                  ]}
                >
                  {i18n.t('what_is_your_mothers_ancestry').default}
                </Text>
                <Text
                  style={[
                    styles.subTitleText,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                    },
                  ]}
                >
                  {i18n.t('select_all_that_apply').default}
                </Text>
              </View>
              {/* </Title text container> */}
              <SearchInput
                parent="mother"
                onPress={(parent) => this.setModalVisible(true, parent)}
                ancestryData={this.state.mothersAncestry}
                onPressRemove={(key) => this.removeAncestry(key, 'mother')}
                colorSettings={this.colorSettings}
              />
            </View>

            <View
              style={[
                styles.horizontalBorder,
                {
                  marginBottom: 15,
                  borderBottomColor:
                    this.colorSettings?.selectable_btn_border_active_1 ||
                    'rgba(74, 74, 74, 0.5)',
                },
              ]}
            />

            <View style={styles.searchInputMainContainer}>
              {/* <Title text container> */}
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text
                  style={[
                    styles.titleText,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                    },
                  ]}
                >
                  {i18n.t('what_is_your_fathers_ancestry').default}
                </Text>
                <Text
                  style={[
                    styles.subTitleText,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                    },
                  ]}
                >
                  {i18n.t('select_all_that_apply').default}
                </Text>
              </View>
              {/* </Title text container> */}
              <SearchInput
                parent="father"
                onPress={(parent) => this.setModalVisible(true, parent)}
                ancestryData={this.state.fathersAncestry}
                onPressRemove={(key) => this.removeAncestry(key, 'father')}
                colorSettings={this.colorSettings}
              />
            </View>
          </ScrollView>

          {/* Next button */}
          <View
            style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}
          >
            <View style={styles.nextButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.nextButton,
                  {
                    backgroundColor:
                      this.colorSettings?.bottom_next_btn_enabled || 'white',
                    borderColor:
                      this.colorSettings?.bottom_next_btn_enabled || 'black',
                    borderWidth: 1.0,
                  },
                ]}
                onPress={this.nextButtonAction}
              >
                <Text
                  style={[
                    styles.nextButtonText,
                    {
                      color:
                        this.colorSettings?.bottom_next_btn_text || 'black',
                    },
                  ]}
                >
                  {i18n.t('next').default}
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <LoadingView
            message={this.state.showLoadingText}
            visible={this.state.showLoadingView}
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
          />

          <AncestrySearchModal
            parentController={this}
            ancestryList={ancestryList}
            parent={this.state.parent}
            mothersData={this.state.mothersAncestry}
            fathersData={this.state.fathersAncestry}
            showModal={this.state.modalVisible}
            selectedAncestry={(data, parent) =>
              this.renderSelectedAncestry(data, parent)
            }
            getParentAncestry={this.getParentAncestry}
            authCredentials={this.props.authCredentials}
            colorSettings={this.colorSettings}
            saveError={this.props.saveError}
          />
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

// <Redux functions>
function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, clinicianStore } = state
  return {
    colorSettings: store.colorSettings,
    account: accountStore.account,
    authCredentials: accountStore.authCredentials,
    proband: probandStore.proband,
    clinician: clinicianStore.selectedClinician,
    probandProfile: probandStore.probandProfile,
    lastScreenLogin: store.lastScreenLogin,
    selectedLanguage: store.selectedLanguage,
  }
}

const actionCreator = {
  _saveProbandImmediateFamily_: setProbandImmediateFamily,
  saveError: setError,
}

export default connect(mapGlobalStateToProps, actionCreator)(Ancestry)
