import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import SelectParentsBloodRelation from '$screens/main/family/edit_profile/_components/relationship_info/_components/modals/SelectParentsBloodRelation.js'

import defaultHeaderSettings from '$constants/navigationConstants'

import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

const Stack = createStackNavigator()

function SelectParentBloodRelationNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="SelectParentsBloodRelation"
      screenOptions={{ ...defaultHeaderSettings, mode: 'card' }}
    >
      <Stack.Screen
        name="SelectParentsBloodRelation"
        component={SelectParentsBloodRelation}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.modal_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={
                i18n.t('select_relationship').default ?? 'Select Relationship'
              }
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.modal_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default SelectParentBloodRelationNavigator
