import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  inputField: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 105,
    borderWidth: 0.5,
    alignSelf: 'center',
    borderRadius: 7,
  },
})
