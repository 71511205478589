/* eslint-disable no-magic-numbers */
import { availableValues } from '$localization/config'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { store } from '$redux/configureStore'

import {
  setRoutesAction,
  setWorkflowFlags,
  setIsCustomWorkflow,
  setWorkflowType,
} from '$redux/customFlowRoutes/actions'

import { setError } from '$redux/defaults/actions.js'

import { DOMAIN_CLIENT } from '$constants/globalVariables'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'

import {
  lastVisitedScreensList,
  MAPPED_LAST_VISITED_SCREENS,
} from '$navigation/constants/lastVisitedScreensRoutes'

import { mockIsCustomSignUp } from './mockData'
import { setHasForcePassword } from '../../../redux/customFlowRoutes/actions'
import defaultRoutes from '../../constants/defaultNormalWorkflowRoutes'

// Worfklow endpoints
// These are the ones we use
// /v1/workflow/get_workflow_data/?uuid=UUID
// /v1/workflow/get_workflow_data/?domain_client_code=FAMGENIX
// /v1/workflow/get_workflow_data/?clinician_id=1

// We are not using those ones, are here for reference
// /v1/workflow/get_workflow_data/?clinician_code=aaaaaaaaa
// /v1/workflow/get_workflow_data/?workflow_id=1
// /v1/workflow/get_workflow_data/?domain_client_id=1

const WORKFLOW_TYPE = {
  SCREENING: {
    id: 1,
    name: 'screening',
  },
  STANDARD: {
    id: 2,
    name: 'standard',
  },
}

export async function fetchWorkflow({ id, fileName }) {
  const isClinicianId = Boolean(id) && String(id)?.length <= 6

  const isUUID = Boolean(id) && String(id).length > 6

  const getPath = () => {
    if (isUUID) return `workflow/get_workflow_data/?uuid=${id}`

    if (isClinicianId) return `workflow/get_workflow_data/?clinician_id=${id}`

    return `workflow/get_workflow_data/?domain_client_code=${DOMAIN_CLIENT}`
  }

  const path = getPath()

  const getFlowPayload = {
    path,
    method: 'get',
    pageDetails: {
      page: fileName,
    },
  }

  try {
    if (id === -1) throw Error

    const response = await ApiFetchHandler(getFlowPayload)

    console.log(
      '🚀 \n\n file: helpers.js:66 \n\n fetchWorkflow \n\n response:',
      response
    )

    if (response?.isError) {
      store.dispatch(
        setError({
          isShown: true,
          status: response?.status,
          message: response?.error,
        })
      )

      throw Error
    }

    return {
      id: response?.id,
      screens: response?.workflow_screens,
      isCustomSignUp: response?.workflow_screens?.length > 0,
      workflow_name: response?.workflow_name,
      clinician_id: response?.clinician_id,
      hasForcedPassword: response?.is_password_forced,
      workflowType: response?.workflow_type,

      // There will be a new filed called ‘type’ and currently would have a value of either ‘screening’ or ‘full’
    }
  } catch (error) {
    console.log(
      '🚀 \n\n file: customWorkflowHandler.js:45 \n\n fetchWorkflow \n\n error:',
      error
    )

    return {
      screens: [],
      isCustomSignUp: false,
    }
  }
}

function getMappedRoutesList({ screens, hasLastVisitedScreen }) {
  if (!Array.isArray(screens) || screens?.length === 0) return []

  const sortedScreens = screens.sort((a, b) => a.screen_order - b.screen_order)

  const ROUTES = hasLastVisitedScreen
    ? MAPPED_LAST_VISITED_SCREENS
    : CUSTOM_FLOW_ROUTES

  const mappedRoutes = sortedScreens.map((item) => {
    const route = ROUTES[item.screen.code]

    return {
      workflowID: item?.workflow,
      flows: route?.flows,
      stacks: route?.stacks,
      id: route?.id,
      screen_order: item?.screen_order,
      name: route?.name,
      texts: item?.texts,
      description: item?.description,
      surveyId: item?.screen?.survey_id,
      selected_screen: hasLastVisitedScreen ? route?.selected_screen : null,
    }
  })

  return mappedRoutes
}

function getNextRouteIndex(screens, lastVisitedScreen) {
  if (screens?.length === 0 || screens?.length === 1) return null

  if (!lastVisitedScreen) return screens?.[1]?.screen_order

  const nextRoute = screens?.find(
    (item) => item?.selected_screen === lastVisitedScreen
  )

  const isLastRoute = nextRoute?.screen_order === screens?.length

  if (isLastRoute || !nextRoute) return null

  return nextRoute.screen_order + 1
}

export function setReduxWorkflowState(
  screens,
  lastVisitedScreen,
  workflowType
) {
  const mappedRoutesList = getMappedRoutesList({
    screens,
    hasLastVisitedScreen: Boolean(lastVisitedScreen),
  })

  const nextRouteIndex = getNextRouteIndex(mappedRoutesList, lastVisitedScreen)
  const isCustomWorkflow = mappedRoutesList?.length > 0

  if (isCustomWorkflow) {
    store.dispatch(
      setIsCustomWorkflow({
        isCustomWorkflow: true,
      })
    )
    store.dispatch(
      setWorkflowType({
        workflowType:
          workflowType === WORKFLOW_TYPE.SCREENING.id
            ? WORKFLOW_TYPE.SCREENING.name
            : WORKFLOW_TYPE.STANDARD.name,
      })
    )
  }

  store.dispatch(
    setRoutesAction({
      selectedScreens: mappedRoutesList,
      nextRouteIndex,
    })
  )
}

export function setInitialReduxWorkflowState({
  isShortCustomWorkflow,
  hasLastVisitedScreen,
}) {
  store.dispatch(
    setRoutesAction({
      selectedScreens: [],
      nextRouteIndex: 0,
    })
  )

  store.dispatch(
    setWorkflowFlags({
      isCustomWorkflow: false,
      isShortCustomWorkflow,
      hasLastVisitedScreen,
    })
  )
}

export function handleGetLastVisitedScreen(lastVisitedScreen) {
  const route = lastVisitedScreensList.find(
    (item) => item.selected_screen === lastVisitedScreen
  )

  if (!route) return null

  return route?.routeName
}

export function getFirstWorkflowRouteName(screens, lastVisitedScreen) {
  if (lastVisitedScreen) return handleGetLastVisitedScreen(lastVisitedScreen)

  const mappedRoutesList = getMappedRoutesList({
    screens,
    hasLastVisitedScreen: false,
  })

  const firstRoute =
    mappedRoutesList?.find((screen) => screen?.screen_order === 1) ?? null

  return firstRoute?.name
}

export const handleNavigateToNormalWorkflowPreviousScreen = () => {
  const { lastScreenLogin } = store.getState().store

  const nextScreen =
    getFirstWorkflowRouteName([], lastScreenLogin) ?? lastScreenLogin
  const currentScreenIndex = defaultRoutes.indexOf(nextScreen)

  if (currentScreenIndex - 1 < 0) return null

  return defaultRoutes[currentScreenIndex - 1]
}

export function getCustomTextTranslation(element, index, rawCustomTextMapped) {
  const { selectedLanguage } = store.getState().store

  const customTextMapped = {
    ...rawCustomTextMapped,
  }

  customTextMapped.hasCustomText = true

  const isDefaultENtranslation = selectedLanguage === availableValues[0]

  const translatedText = isDefaultENtranslation
    ? element?.text ?? ''
    : element.translations.find(
        (transation) => transation.lang === selectedLanguage
      )?.text ?? ''

  if (+index === 1) customTextMapped.topText = translatedText
  if (+index === 2) customTextMapped.middleText = translatedText
  if (+index === 3) customTextMapped.bottomText = translatedText
  if (+index === 4) customTextMapped.nextScreenText.topText = translatedText
  if (+index === 5) customTextMapped.nextScreenText.middleText = translatedText
  if (+index === 6) customTextMapped.nextScreenText.bottomText = translatedText

  return customTextMapped
}
