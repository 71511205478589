import { StyleSheet } from 'react-native'

const white = 'white'

export default StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginVertical: 10,
  },
  incrementButton: { paddingVertical: 10 },
  inputContainer: {
    borderBottomColor: white,
    borderBottomWidth: 1,
    width: '20%',
    padding: 10,
  },
  input: {
    fontFamily: 'montserrat-medium',
    color: white,
    width: '100%',
    textAlign: 'center',
  },
  decrementButton: { paddingVertical: 10 },
  textTitle: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
  },
  outerInputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
