import { Component, createRef } from 'react'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { isNotValidAOD } from '$screens/main/family/edit_profile/_components/personal_info/components/_utils/Utils.js'
import { yearIntervalFromNow } from '$api/_utils/Utils.js'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import { IS_WEB } from '$constants/Platforms'
import DropdownComponent from '$components/DropdownComponent/DropdownComponent'
import { handleDisplayShorterAgeUnits } from '$api/_utils/Utils'

const CURRENT_AGE = 'currentAge'
const DOB = 'dob'
const CURRENT_AGE_STRING = 'currentAgeString'
class CurrentAgeField extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  visible = this.props.visible

  currentAge = 0

  allowUserInputAge = this.props.allowUserInputAge

  setAllowUserInputAge = this.props.setAllowUserInputAge

  bottomSheetRef = this.props.bottomSheetRef ?? createRef()

  constructor(props) {
    super(props)

    const newState = {
      visible: this.visible,
      currentAge: null,
      tempCurrentAge: null,
      showDropdown: false,
    }

    const { profileData } = this

    if (profileData) {
      // Calculate currentAge from DOB on first load.
      newState.currentAge = profileData?.currentAge
      newState.dob = profileData?.dob

      if (newState.dob) {
        const calculatedAgeFromDOB = yearIntervalFromNow(
          newState.dob,
          true,
          true
        )

        newState.currentAge = `${calculatedAgeFromDOB.split(' ')[0]}`
      }
    }

    this.currentAge = newState.currentAge
    this.state = newState
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const profileData = memberProfile?.profileData
    const { allowUserInputAge } = newProps

    const newState = {
      ...prevState,
      visible: newProps.visible,
    }

    if (profileData) {
      if (!allowUserInputAge) {
        newState.currentAge = profileData?.currentAge
        newState.dob = profileData?.dob
        if (newState.dob) {
          const calculatedAgeFromDOB = yearIntervalFromNow(
            newState.dob,
            true,
            true
          )

          newState.currentAge = `${calculatedAgeFromDOB.split(' ')[0]}`
        }
      }
    }

    return newState
  }

  updateCurrentAge = (val) => {
    const { currentAgeType } = this.props

    const currentAge = val ?? 0

    if (currentAge <= 0) return

    this.currentAge = `${currentAge}`
    this.parentController?._updateState_(
      CURRENT_AGE_STRING,
      `${currentAge}${handleDisplayShorterAgeUnits(currentAgeType)}`
    )

    this.parentController?._updateState_(DOB, null)
    this.parentController?._updateState_(CURRENT_AGE, currentAge)
  }

  clearAgeOnFocus = () => {
    const prevState = this.state

    this.setState({
      currentAge: '',
      tempCurrentAge: prevState.currentAge,
    })
  }

  onEndEditingAge = () => {
    const prevState = this.state
    const memberDisease =
      this.memberProfile.healthHistoryData.diseasesData.diseases
    let updatedAge = prevState.tempCurrentAge
    const updatedCurrentAgeString = `${Number(
      prevState.currentAge
    )}${handleDisplayShorterAgeUnits(this.props.currentAgeType)}`

    if (!prevState.currentAge || prevState.currentAge === '') {
      this.setState({ currentAge: prevState.tempCurrentAge })
    } else if (
      isNotValidAOD(
        prevState.currentAge,
        memberDisease,
        true,
        updatedCurrentAgeString
      )
    ) {
      this.setState({ currentAge: prevState.tempCurrentAge })
    } else {
      const newState = this.state
      newState.tempCurrentAge = updatedAge = prevState.currentAge
      this.setState(newState)
    }

    if (Number(updatedAge > 130)) {
      updatedAge = 130
      showDefaultAlert('Oops', 'Current age must not be greater than 130.')
    } else if (Number(updatedAge < 0)) {
      updatedAge = 0
      showDefaultAlert('Oops', 'Current age cannot be less than 0.')
    }

    return `${Number(updatedAge)}`
  }

  renderChoiceCurrentAgeButton = () => {
    const { showDropdown } = this.state

    const availableDiagnosisValues = ['days', 'weeks', 'months', 'years']

    const handlePressButton = (event) => {
      this.parentController?._updateState_(
        CURRENT_AGE_STRING,
        `${this.profileData.currentAge}${handleDisplayShorterAgeUnits(event)}`
      )
      this.props.handleAgeUnitChange(event)
      this.setState({ showDropdown: false })
    }

    const handleShowingChoice = () => {
      if (IS_WEB()) return this.setState({ showDropdown: !showDropdown })

      if (!this.bottomSheetRef.current.expand()) {
        return this.bottomSheetRef.current.expand()
      }

      return this.bottomSheetRef.current.close()
    }

    return (
      <View styles={section_styles.choiceOfDiagnosisAgeContainer}>
        <TouchableOpacity
          style={section_styles.choiceOfDiagnosisAgeButton}
          onPress={handleShowingChoice}
        >
          <Text
            style={[
              section_styles.choiceOfDiagnosisAgeButtonText,
              { color: this.colorSettings?.linked_text || 'black' },
            ]}
          >
            ({i18n.t(this.props.currentAgeType).default})
          </Text>
        </TouchableOpacity>
        {IS_WEB() && (
          <DropdownComponent
            data={availableDiagnosisValues}
            isShown={showDropdown}
            onPress={handlePressButton}
            selectedItem={this.props.currentAgeType}
          />
        )}
      </View>
    )
  }

  render() {
    if (!this.state.visible) return null
    if (!this.profileData) return null

    return (
      <View>
        <View style={section_styles.contentSeparatorLine} />

        <View style={section_styles.contentItem}>
          <View
            style={[
              section_styles.contentItemLabelTextContainer,
              {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            ]}
          >
            <Text
              style={[
                section_styles.contentItemLabelText,
                {
                  color: this.colorSettings?.text_input_label_color_1,
                  marginRight: 10,
                },
              ]}
              onPress={() => {
                this.ageInput.focus()
              }}
            >
              {i18n.t('current_age').default}
            </Text>
            {this.renderChoiceCurrentAgeButton()}
          </View>
          <View
            style={[
              section_styles.contentItemTextInputContainer,
              {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              },
            ]}
          >
            <TextInput
              nativeID="web_text_input"
              ref={(input) => (this.ageInput = input)}
              style={[
                section_styles.contentItemTextInput,
                { color: this.colorSettings?.text_input_color_1 },
              ]}
              maxLength={3}
              placeholder="0"
              placeholderTextColor={
                this.colorSettings?.text_input_placeholder_color_1 ||
                'rgba(74,74,74,0.5)'
              }
              keyboardType="number-pad"
              onFocus={() => {
                this.setState({ currentAge: '' })
                this.setAllowUserInputAge(true)
              }}
              onBlur={() => {
                const newAge = this.onEndEditingAge()
                this.setAllowUserInputAge(false)
                this.updateCurrentAge(newAge)
              }}
              onChangeText={(val) => {
                this.setState({ currentAge: val })
              }}
              onChange={this.props.onChange}
              value={`${this.state.currentAge}`}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default CurrentAgeField
