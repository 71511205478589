import { StyleSheet, Platform } from 'react-native'

const borderColor = 'rgb(216,216,216)'

export default StyleSheet.create({
  cardContainer: {
    width: '100%',
    paddingBottom: 0,
  },
  middleContainer: {
    width: '100%',
  },
  innerContainer: {
    paddingLeft: 10,
  },
  cardButton: {
    width: '100%',
  },
  profileImage: {
    flex: Platform.OS === 'web' ? 0.16 : 0.2,
  },
  profileContainer: {
    marginVertical: 8,
  },
  divider: {
    height: 4.0,
  },
  addButtonContainer: {
    flex: Platform.OS === 'web' ? 0.45 : 0.4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  subtitleText: {
    fontFamily: 'montserrat-italic',
  },
  addRelativeImg: {
    width: 38,
    height: 38,
    resizeMode: 'contain',
  },

  childrenCardContainer: {
    width: 'auto',
  },
  childrenMiddleContainer: {
    paddingLeft: 12,
  },
  childrenInnerContainer: {
    width: '100%',
  },
  treeLineFirstContainer: {
    top: '15%',
  },
  treeLineVertical: {
    height: '35%',
  },
  treeLineSecondContainer: {
    top: 0,
  },
  iconButton: {
    width: 50,
    height: 50,
  },
  iconContainer: {
    borderWidth: 1,
    borderRadius: 25,
    borderColor,
    padding: 10,
    alignContent: 'center',
  },
})
