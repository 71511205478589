const convertText = (text) => {
  if (!text) return text

  const inputValue = text
    .split(' ')
    .join('_')
    .toLowerCase()
    .replace(/[^-0-9a-zA-Z _]/g, '')

  return inputValue
}

export default convertText
