import { TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../styles'

const ExpandAndCollapseButtons = ({
  handleExpandFamilyMembers,
  handleCollapseFamilyMembers,
}) => {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.navigationCollapsibleButtonsContainer}>
      <View style={styles.navigationCollapsibleEachButtonContainer}>
        <TouchableOpacity
          style={styles.navigationCollapsibleButton}
          onPress={handleCollapseFamilyMembers}
        >
          <MaterialCommunityIcons
            name="arrow-collapse-vertical"
            size={28}
            color={colorSettings?.nav_title_light}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.navigationCollapsibleEachButtonContainer}>
        <TouchableOpacity
          style={styles.navigationCollapsibleButton}
          onPress={handleExpandFamilyMembers}
        >
          <MaterialCommunityIcons
            name="arrow-expand-vertical"
            size={28}
            color={colorSettings?.nav_title_light}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default ExpandAndCollapseButtons
