import { Platform, SafeAreaView, ScrollView } from 'react-native'
import { connect } from 'react-redux'
import * as NotificationsActions from '$redux/notifications/actions.js'
import { WEB, IS_WEB } from '$constants/Platforms.js'
import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import Account from '$data_models/Account'
import AuthCredentials from '$data_models/AuthCredentials.js'
import BaseController from '$components/BaseController.js'
import LoadingView from '$constants/LoadingView.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'
import NotificationItem from './NotificationItem'
import ApiFetchHandler from '$api/ApiFetchHandler.js'
import Proband from '$data_models/Proband'

import main_styles from '$screens/main/dashboard/notifications/_styles/main.styles.js'
import { setError } from '$redux/defaults/actions.js'

class NotificationDetail extends BaseController {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  account = this.props.account ?? new Account()

  proband = this.props.proband ?? new Proband()

  state = {
    ...this.state,
    selectedNotification: this.props.selectedNotification,
    scrollViewHeight: DefaultFullHeightForWeb(),
  }

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }

    const notificationID = this.state.selectedNotification?.id
    await this.setNotificationToRead(notificationID)

    /* Reload and render component */
    this.reloadAndRenderView(false)

    // Set the component's navigation event listeners
    this.setNavigationEventListener('focus')
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }
  }

  setNavigationEventAction = () => {
    /* Set color settings component reloads on foreground */
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })
  }

  acceptButtonAction = async (item) => {
    if (!item?.id) return

    const itemID = item?.id
    const probandID = this.proband?.probandID

    const headers = this.authCredentials
    const payload = {
      proband_id: probandID,
      user_id: this.account?.userID,
      response: 'accept',
    }

    await this.respondToNotification(itemID, payload, headers)
  }

  denyButtonAction = async (item) => {
    if (!item?.id) return

    const itemID = item?.id
    const probandID = this.proband?.probandID

    const headers = this.authCredentials
    const payload = {
      proband_id: probandID,
      user_id: this.account?.userID,
      response: 'deny',
    }

    await this.respondToNotification(itemID, payload, headers)
  }

  respondToNotification = async (itemID, payload, headers) => {
    /* Prepare API request to Accept/Deny the Notification */

    /* Activate loading view */
    this.reloadAndRenderView()

    const responseToNotificationPayload = {
      path: `notification/${itemID}/response/`,
      method: 'post',
      token: headers.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'Notifications.detail.js',
      },
    }

    const response = await ApiFetchHandler(responseToNotificationPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.reloadAndRenderView(false)

    /* Set flag to reload Notifications list */
    this.props._setShouldReloadNotificationsList_(true)

    /* Go back to Notifications list */
    this.props.navigation.pop()
  }

  setNotificationToRead = async (id) => {
    /* Prepare API request to set Notification to read */

    if (!id) return
    const probandID = this.proband?.probandID
    const setNotificationToReadPayload = {
      path: `notification/${id}/read/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
      },
      pageDetails: {
        page: 'Notifications.detail.js',
      },
    }

    const response = await ApiFetchHandler(setNotificationToReadPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }
  }

  setNotificationToArchive = async (id) => {
    /* Prepare API request to set Notification to archived */

    if (!id) return

    const probandID = this.proband?.probandID

    const setNotificationToArchivePayload = {
      path: `notification/${id}/archive/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
      },
      pageDetails: {
        page: 'Notifications.detail.js',
      },
    }

    const response = await ApiFetchHandler(setNotificationToArchivePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.reloadAndRenderView(false)

    /* Set flag to reload Notifications list */
    this.props._setShouldReloadNotificationsList_(true)

    /* Go back to Notifications list */
    this.props.navigation.pop()
  }

  onPressDismiss = async () => {
    const notificationID = this.state.selectedNotification?.id
    await this.setNotificationToArchive(notificationID)
  }

  render() {
    const { navigation } = this.props
    const { colorSettings } = this.props

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView style={global_styles.containerWhite}>
          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </SafeAreaView>
      )
    }

    const item = this.state.selectedNotification

    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[
            main_styles.container,
            {
              ...Platform.select({
                [WEB]: {
                  flex: null,
                  height: this.state.scrollViewHeight,
                },
              }),
              flex: 1,
            },
          ]}
        >
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <NotificationItem
              colorSettings={colorSettings}
              onDismiss={() => this.onPressDismiss(item)}
              onAccept={() => this.acceptButtonAction(item)}
              onDeny={() => this.denyButtonAction(item)}
              item={item}
            />
            {this.state.showLoadingView && (
              <LoadingView
                backgroundColor={colorSettings?.splash_bgcolor}
                tintColor={colorSettings?.btn_no_fill_border_1}
                textColor={colorSettings?.btn_no_fill_text_1}
                visible={this.state.showLoadingView}
              />
            )}
          </ScrollView>
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, notificationsStore } = state

  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
    /** Proband Store */
    proband: probandStore.proband,
    /** Notifications Store */
    notificationsList: notificationsStore.notificationsList,
    selectedNotification: notificationsStore.selectedNotification,
    shouldReloadNotificationsList:
      notificationsStore.shouldReloadNotificationsList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _clearNotificationsStoreAction_: () =>
    dispatch(NotificationsActions.clearNotificationsStoreAction()),
  _setNotificationsListAction_: (data) =>
    dispatch(NotificationsActions.setNotificationsListAction(data)),
  _setSelectedNotificationAction_: (data) =>
    dispatch(NotificationsActions.setSelectedNotificationAction(data)),
  _setShouldReloadNotificationsList_: (data) =>
    dispatch(NotificationsActions.setShouldReloadNotificationsList(data)),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail)
