import { Component } from 'react'
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import { WEB } from '$constants/Platforms.js'
import { DefaultFullHeight } from '$constants/styles/global.styles.js'

class SuccessScreenResetPassword extends Component {
  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)
    this.state = {
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  nextButtonAction = () => {
    // this.props.navigation.navigate("Login");
    this.props.navigation.navigate('Splash')
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          { backgroundColor: this.colorSettings?.splash_bgcolor },
        ]}
      >
        <ScrollView
          style={{ height: this.state.scrollViewHeight }}
          contentContainerStyle={styles.contentContainer}
          scrollEnabled={false}
        >
          {/* <Content> */}
          <View style={styles.content}>
            <View style={{ flex: 0.1 }} />

            <View style={{ flex: 0.6, width: '100%', backgroundColor: '' }}>
              <View style={{ flex: 1.0 }}>
                {/* <Success Image Icon> */}
                <View
                  style={{
                    flex: 0.7,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{
                      marginLeft: 15,
                      width: 152,
                      height: 141,
                      resizeMode: 'contain',
                    }}
                    source={require('$assets/images/new_assets/illus-sent.png')}
                  />
                </View>
                {/* </Success Image Icon> */}

                <View style={{ flex: 0.3 }}>
                  <View
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Text
                      style={[
                        styles.titleText,
                        { color: this.colorSettings?.text_input_title_color_1 },
                      ]}
                    >
                      {`${i18n.t('success').default}!`}
                    </Text>

                    <View style={{ height: 24 }} />

                    <Text
                      style={[
                        styles.subTitleText,
                        { color: this.colorSettings?.text_input_title_color_1 },
                      ]}
                    >
                      {i18n.t('your_password_has_been_reset').default}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ flex: 0.3 }} />
          </View>
          {/* </Content> */}
        </ScrollView>

        {/* <Next button> */}
        <View style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}>
          <View style={styles.nextButtonContainer}>
            <TouchableOpacity
              style={[
                styles.nextButton,
                {
                  backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
                  borderColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  styles.nextButtonText,
                  {
                    color: this.colorSettings?.bottom_next_btn_text,
                  },
                ]}
              >
                {i18n.t('login_to_your_account').default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {/* </Next button> */}
      </View>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(SuccessScreenResetPassword)

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1.0,
  },
  contentContainer: {
    // backgroundColor: "blue",
    flex: 1.0,
    width: '100%',
  },
  content: {
    // backgroundColor: "pink",
    flex: 1.0,
    marginHorizontal: 13,
    alignItems: 'center',
    justifyContent: 'center',
  },

  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
    color: 'rgb(74,74,74)',
  },
  subTitleText: {
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    color: 'rgb(102,102,102)',
  },
  nextButton: {
    justifyContent: 'center',
    // backgroundColor: "rgba(132,209,192,1.0)",
    height: 60,
    width: '100%',
    borderWidth: 1,
  },
  nextButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})
