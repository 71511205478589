import { View, Text, StyleSheet } from 'react-native'

import { i18n } from '$localization/config.js'

import EllipseButton from '$components/ellipse-button-container/EllipseButton.js'

import { adjustFontSizeByLength } from '$api/_utils/Utils.js'

const ToggleButtonComponent = (props) => {
  const { colorSettings } = props

  const handleYesBtnHighlight = () => {
    let bgColor = ''
    let borderColor = ''

    if (props.isToggled === null || !props.isToggled) {
      bgColor = colorSettings?.selectable_btn_border_active_1
      borderColor = colorSettings?.selectable_btn_border_inactive_1
    } else if (props.isToggled) {
      bgColor = colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      borderColor = colorSettings?.selectable_btn_border_active_1
    }

    return { backgroundColor: bgColor, borderColor }
  }

  const handleNoBtnHighlight = () => {
    let bgColor = ''
    let borderColor = ''

    if (props.isToggled === null || props.isToggled) {
      bgColor = colorSettings?.selectable_btn_border_active_1
      borderColor = colorSettings?.selectable_btn_border_inactive_1
    } else if (!props.isToggled) {
      bgColor = colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      borderColor = colorSettings?.selectable_btn_border_active_1
    }
    return { backgroundColor: bgColor, borderColor }
  }

  return (
    <View style={[props.containerStyle]}>
      <View style={{ marginBottom: 30 }}>
        <Text
          style={[
            styles.title,
            {
              color: colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {props.title}
        </Text>
      </View>
      <View style={[styles.buttonContainerStyle]}>
        <EllipseButton
          onPress={() => props.onToggle(true)}
          buttonStyle={[styles.buttonStyle, handleYesBtnHighlight()]}
        >
          <Text
            style={[
              styles.roundedButtonTitleText,
              {
                color: colorSettings?.selectable_btn_text_active_1,
                fontSize: adjustFontSizeByLength('Yes'),
              },
            ]}
          >
            {i18n.t('yes').title}
          </Text>
        </EllipseButton>
        <EllipseButton
          onPress={() => props.onToggle(false)}
          buttonStyle={[styles.buttonStyle, handleNoBtnHighlight()]}
        >
          <Text
            style={[
              styles.roundedButtonTitleText,
              {
                color: colorSettings?.selectable_btn_text_active_1,
                fontSize: adjustFontSizeByLength('No'),
              },
            ]}
          >
            {i18n.t('no').title}
          </Text>
        </EllipseButton>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    // color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },
  buttonContainerStyle: {
    flexDirection: 'row',
  },
  buttonStyle: {
    flex: 1,
    marginHorizontal: 10,
  },
  roundedButtonTitleText: {
    // color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'center',
  },
})

export default ToggleButtonComponent
