/* eslint-disable max-classes-per-file */
/* React & React Native libraries */
import { View, SafeAreaView, SectionList, Platform } from 'react-native'

import { HeaderBackButton } from '@react-navigation/elements'

import { connect } from 'react-redux'

/* Custom Components and Constants */
import GenericFamilyListView from '../_components/GenericFamilyList'

import LoadingView from '$constants/LoadingView.js'
import NextButton from '$components/next-button/NextButton.component'

import TutorialModal from './_components/TutorialModal'
import ExtendedHeaderView from '$screens/relatives/refactored/_components//ExtendedHeaderView'
import GenericHeaderControls from '$screens/relatives/refactored/_components/GenericHeaderControls'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'

/* Custom Utilities */
import { WEB } from '$constants/Platforms.js'
import { i18n } from '$localization/config.js'

import { storeCurrentMember } from '$redux/member_profile/actions.js'

import {
  setBasicFamilyMembers,
  setShouldReloadFamilyList,
} from '$redux/proband/actions.js'

import { setIsFirstLogin, setError } from '$redux/defaults/actions.js'

import { resetStackActionRelatives } from '$navigation/_utils/NavigationUtils.js'
import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

/* Custom Stylesheets */
import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'

import { FLOWS } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

let isLastScreenBeforeLogin = false
class MaternalAuntsUnclesListController extends GenericFamilyListView {
  /** Note: Do not change. These keys are strictly tied to an API response. */
  sectionListOrder = {
    [RelationshipTypes.AUNTSUNCLES_MOMS_SIDE]: 0,
  }

  state = {
    ...this.state,
    allowFamilyInvite: false,
    showTutorialModal: true,
  }

  componentDidMount(...args) {
    super.componentDidMount.apply(this, args)
    const { lastScreenLogin, route } = this.props

    isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.relatives_maternal_side

    const higherScreenList = ['relatives_maternal_side']

    const screenExist = higherScreenList.find(
      (screen) => screen === lastScreenLogin
    )

    const isModalShown = route.params?.isModalShown ?? true

    if (screenExist) {
      this.setState({ showTutorialModal: false })
    } else {
      this.setState({ showTutorialModal: isModalShown })
    }
  }

  getBasicFamilyMembers = async () => {
    const { familyID, headers, payload } = super
      .constructor(this.props)
      .getBasicFamilyMembersPayload()

    if (!familyID) return

    /* Initialize Family data */
    const newFamilyData = (this.familyData = [
      { title: RelationshipTypes.AUNTSUNCLES_MOMS_SIDE, data: [] },
    ])

    /* Activate loading view */
    this.reloadAndRenderView()

    const getBasicFamilyMembers = {
      path: `family/${familyID}/members/`,
      method: 'post',
      token: headers.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'MaternalAuntsUncleList index.js',
      },
    }

    const response = await ApiFetchHandler(getBasicFamilyMembers)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      this.setState({ sectionListRefreshing: false })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /** 
         What to do here?
         * Update family data on Redux.
         * Update the data on SectionList.
         * Stop refreshing of SectionList.
         * Stop loading view.
        */
    const _keys = Object.keys(response)

    // Loop through each family member by relationship to Proband.
    KeyLoop1: for (const i in _keys) {
      const relationshipToProband = `${_keys[i]}`.toLowerCase()

      switch (relationshipToProband) {
        case RelationshipTypes.MATERNAL_AUNTS_UNCLES:
          var memberList = response[relationshipToProband]

          /* Modify relationship to proband */
          var _relationshipToProband_ = RelationshipTypes.AUNTSUNCLES_MOMS_SIDE
          var SECTION_KEY = this.sectionListOrder[_relationshipToProband_]

          const parentsData =
            response?.parents?.map((sibling) => ({
              member: { ...sibling },
            })) ?? []

          newFamilyData[SECTION_KEY] = {
            ...newFamilyData[SECTION_KEY],
            data: this.addToFamilyData(memberList, payload, headers),
            parentsData,
          }

          break KeyLoop1

        default:
          /* Skip for unspecified RelationshipTypes */
          continue KeyLoop1
      }
    }

    this.props._setBasicFamilyMembers_(this.familyData)

    this.reloadAndRenderView(false)

    this.setState({
      sectionListRefreshing: false,
      familyData: this.familyData,
    })
  }

  nextButtonAction = async () => {
    const { account, authCredentials, navigation, saveError } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.relatives_maternal_side,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    /* Set isFirstLogin to true */
    this.props._setIsFirstLogin_(true)

    /* Proceed to Main Dashboard */
    const parentNav = navigation.getParent()
    const grandParentNav = parentNav.getParent()
    const greatGrandParentNav = grandParentNav.getParent()

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: FLOWS.MainStack.name,
    })

    handleNavigateToNextScreen({
      navigation: {
        navigate: greatGrandParentNav.navigate,
      },
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  toggleTutorialModal = (showTutorialModal = false) => {
    this.setState({ showTutorialModal })
  }
}

class MaternalAuntsUnclesListView extends MaternalAuntsUnclesListController {
  renderSectionHeader = (section) =>
    super.constructor(this.props).renderSectionHeader(section)

  renderItem = ({ item, index, section }) =>
    super.constructor(this.props).renderItem({ item, index, section })

  render() {
    const { colorSettings } = this.props
    const message =
      this.allMembersList.length > 0
        ? i18n.t(
            'be_sure_to_add_details_about_anyone_who_had_a_disease_or_genetic_testing'
          )?.default
        : i18n.t('now_let’s_add_your_aunts_and_uncles_on_your_mothers_side')
            ?.default

    return (
      <SafeAreaView
        style={[
          global_styles.containerWhite,
          {
            ...Platform.select({
              [WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          },
        ]}
      >
        {/* Custom Header */}
        <ExtendedHeaderView
          colorSettings={colorSettings}
          message={message}
          controlsView={
            <GenericHeaderControls
              colorSettings={colorSettings}
              primaryButtonAction={({ relationshipToProband }) => {
                this.requestAddRelative({
                  relationshipToProband,
                  familySide: RelationshipTypes.MATERNAL,
                })
              }}
              leftButton={{ key: RelationshipTypes.UNCLE }}
              rightButton={{ key: RelationshipTypes.AUNT }}
            />
          }
        />

        {/* Content */}
        <View style={[global_styles.content, { flex: 1.0 }]}>
          <SectionList
            nativeID="web_scaled_main_container"
            style={{
              ...Platform.select({
                [WEB]: {
                  height: getScrollViewHeight(),
                },
              }),
            }}
            bounces
            contentContainerStyle={{ paddingBottom: 16 }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            sections={this.state.familyData}
            keyExtractor={(item, index) => index}
            renderSectionHeader={({ section }) =>
              this.renderSectionHeader(section)
            }
            renderItem={({ item, index, section }) =>
              this.renderItem({ item, index, section })
            }
            refreshing={this.state.sectionListRefreshing}
          />
        </View>

        {/* Next button */}
        <NextButton
          buttonStyle={[
            global_styles.nextButton,
            {
              backgroundColor: colorSettings?.bottom_next_btn_enabled,
              borderColor: colorSettings?.bottom_next_btn_enabled,
            },
          ]}
          buttonTitleStyle={[
            global_styles.nextButtonText,
            {
              color: colorSettings?.bottom_next_btn_text_enabled,
            },
          ]}
          buttonTitle={i18n.t('next')?.title}
          primaryAction={() => this.nextButtonAction()}
        />

        {/* Loading View */}
        {this.state.showLoadingView && (
          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
          />
        )}

        {/* Tutorial modal */}
        {this.state.showTutorialModal && (
          <TutorialModal
            onDismiss={() => this.toggleTutorialModal(false)}
            visible={this.state.showTutorialModal}
          />
        )}
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    lastScreenLogin: store.lastScreenLogin,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
    /** Proband Store */
    proband: probandStore.proband,
    shouldReloadFamilyList: probandStore.shouldReloadFamilyList,
    basicFamilyMembers: probandStore.basicFamilyMembers,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
    currentMember: memberProfileStore.currentMember,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _setIsFirstLogin_: (data) => dispatch(setIsFirstLogin(data)),
  _setShouldReloadFamilyList_: (data) =>
    dispatch(setShouldReloadFamilyList(data)),
  _saveCurrentMemberToStore_: (data) => dispatch(storeCurrentMember(data)),
  _setBasicFamilyMembers_: (data) => dispatch(setBasicFamilyMembers(data)),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaternalAuntsUnclesListView)
