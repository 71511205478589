import { View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { i18n } from '../../../../../localization/config.js'
import styles from './styles.js'

export default function LabelField({ masterQuestion, colorSettings }) {
  const { question_label: questionLabel, translation_key: translationKey } =
    masterQuestion

  const checkString = (input) => {
    if (input.startsWith('[') && input.endsWith(']')) {
      if (input.includes('missing')) {
        return true
      }
    }
    return false
  }

  const getLabel = () => {
    let label = ''
    if (!masterQuestion) return label

    const newTranslationKey = `${translationKey}.label`

    const questionLabelTranslation = i18n.t(newTranslationKey)

    if (
      questionLabelTranslation === newTranslationKey ||
      !questionLabelTranslation ||
      checkString(questionLabelTranslation)
    ) {
      return questionLabel
    }
    return questionLabelTranslation
  }

  return (
    <Text
      style={[
        styles.titleText,
        {
          color: colorSettings?.text_input_title_color_2 || 'black',
        },
      ]}
    >
      {getLabel()}
    </Text>
  )
}

LabelField.propTypes = {
  masterQuestion: PropTypes.shape({
    question_label: PropTypes.string,
  }),
  colorSettings: PropTypes.shape({
    text_input_title_color_2: PropTypes.string,
  }),
}

LabelField.defaultProps = {
  masterQuestion: {},
  colorSettings: {},
}
