import { StyleSheet } from 'react-native'

const greyishBlack = 'rgba(74,74,74,0.5)'
const lightGreen = 'rgba(132,209,192,1)'
const lightGreen2 = 'rgba(101,209,185,1)'
const white = '#fff'
const black = 'black'
const fadedBlack = 'rgba(0,0,0,0.8)'
const violet = 'rgb(155,89,182)'
const beige = 'beige'
const fadedWhite = 'rgb(234,234,234)'
const greyishBrown = 'rgb(102,102,102)'
const lightGrey = 'rgb(153,153,153)'

export default StyleSheet.create({
  container: {
    backgroundColor: white,
    width: '100%',
    flex: 1.0,
  },
  contentContainer: {
    flex: 1.0,
  },
  content: {
    justifyContent: 'center',
    flex: 1.0,
  },

  sectionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionContent: {
    justifyContent: 'flex-end',
  },

  textInputContainer: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: greyishBlack,
    flex: 1,
  },
  textInputLabel: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: black,
    marginBottom: 10,
  },
  textInputField: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: black,
  },

  loginButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: lightGreen,
    borderRadius: 30,
    height: 60,
  },
  loginButtonText: {
    color: white,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  showPasswordButton: {
    justifyContent: 'center',
    marginLeft: -24,
  },

  helperTextContainer: {
    marginVertical: 10,
  },

  passwordHelperText: {
    color: greyishBlack,
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  },

  forgotPasswordButtonText: {
    color: lightGreen2,
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },

  submitButton: {
    justifyContent: 'center',
    backgroundColor: lightGreen,
    width: '100%',
    height: '100%',
    borderWidth: 1.0,
  },
  submitButtonText: {
    color: white,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  modalBackground: {
    backgroundColor: fadedBlack,
    flex: 1.0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: white,
    borderRadius: 10,
    overflow: 'hidden',
  },
  modalHeader: {
    backgroundColor: violet,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  modalHeaderTitleText: {
    fontFamily: 'karla-bold',
    fontSize: 21,
    color: white,
  },
  modalHeaderTitleBadge: {
    backgroundColor: beige,
    borderRadius: 45,
    width: 90,
    height: 90,
  },
  modalContent: {
    backgroundColor: white,
    paddingHorizontal: 16,
    paddingTop: 25,
    paddingBottom: 10,
  },
  modalContentSection: {
    borderColor: black,
  },
  modalFooter: {
    borderTopWidth: 2.0,
    borderColor: fadedWhite,
  },

  regularText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: greyishBrown,
  },
  linkedText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: violet,
  },
  switchField: {
    flex: 1.0,
    flexDirection: 'row',
  },
  switchFieldLabel: {
    fontFamily: 'montserrat',
    fontSize: 15,
    lineHeight: 24,
    color: greyishBrown,
  },

  buttonContainer: {
    backgroundColor: white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelButtonTitle: {
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: lightGrey,
  },
  submitButtonTitle: {
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: violet,
  },
  keyboardAvoiding: {
    flex: 1,
  },
  datePickerContainer: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  customTextInputContainer: {
    height: 70,
  },
  datePickerButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  scrollViewContainer: {
    alignItems: 'center',
  },
  textInputPasswordContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  textInputPassword: {
    paddingRight: 24,
  },
  showPasswordContainer: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
  },
  showPasswordImage: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  signUpContainer: {
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 50,
    marginTop: 20,
  },
  nextButtonContainer: {
    height: 60,
    width: '100%',
  },
})
