import { useSelector } from 'react-redux'

import {
  generalizeRelationshipToProband,
  getRelativeTitle,
} from '../helpers/helpers'
import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes'
import BorderedEllipseButton from '$components/bordered-ellipse-button/BorderedEllipseButton'

function AddFamilyMemberOptionListItem({
  relativeType,
  handleRelativeCount,
  didSelectOption,
  selectedItemKeyRef,
  hasMemberParents,
  setParentsSwitch,
  parentsSwitch,
}) {
  const currentMember = useSelector(
    (state) => state.memberProfileStore.currentMember
  )

  const relationToProband = generalizeRelationshipToProband(
    currentMember?.relationship_to_proband
  )

  const { relativeSide, type, gender, restrictions, title } = relativeType

  const keyRef = relativeType.relativeSide ? `${type}_${relativeSide}` : type
  const relativeTypeText = getRelativeTitle(title, relativeSide)
  const isParentsRelationship = relativeTypeText === 'Parents'
  const isActive = keyRef === selectedItemKeyRef
  const ProbandSiblingsCousins = '3'
  const GrandparentsOrAbove = '1'
  const Parents = '2'
  const AuntsUncles = '6'

  const shouldRenderButton = () => {
    switch (relationToProband) {
      case RelationshipTypes.PARTNER:
        return !restrictions.includes('partner')

      case RelationshipTypes.PROBAND:
        return (
          !restrictions.includes('proband') ||
          !restrictions.includes(ProbandSiblingsCousins)
        )

      case RelationshipTypes.SIBLING:
        return !restrictions.includes(ProbandSiblingsCousins)

      case RelationshipTypes.GRANDPARENT:
        if (isParentsRelationship && hasMemberParents) return false
        return !restrictions.includes(GrandparentsOrAbove)

      case RelationshipTypes.PIBLING:
      case RelationshipTypes.NIBLING:
        return !restrictions.includes(AuntsUncles)

      case RelationshipTypes.PARENT:
      case RelationshipTypes.COUSIN:
      case RelationshipTypes.CHILD:
        return !restrictions.includes(Parents)

      default:
        return false
    }
  }

  if (shouldRenderButton()) {
    return (
      <BorderedEllipseButton
        keyRef={keyRef}
        titleText={relativeTypeText}
        relativeSide={relativeSide}
        handleRelativeCount={handleRelativeCount}
        onPress={() => didSelectOption(keyRef, gender)}
        isActive={isActive}
        hasMemberParents={hasMemberParents}
        relationToProband={relationToProband}
        triggerParentSelect={() => didSelectOption('parent', null)}
        setParentsSwitch={setParentsSwitch}
        parentsSwitch={parentsSwitch}
      />
    )
  }

  return null
}

export default AddFamilyMemberOptionListItem
