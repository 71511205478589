import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setError } from '$redux/defaults/actions.js'
import ApiFetchHandler from '$api/ApiFetchHandler'
import useGetFamilyMembers from './useGetFamilyMembers'
import { mapDataToMemberProfile } from '$api/_utils/Utils.js'
import { setProbandProfileAction } from '$redux/proband/actions.js'
import MemberProfile from '$data_models/MemberProfile.js'
import getRelationshipToProband from '../screens/main/family/FamilyPedigreeScreen/helpers/getRelationshipToProband'
import { storeCurrentMember } from '$redux/member_profile/actions.js'

function useGetMemberProfile(probandID, authCredentials, memberID) {
  const dispatch = useDispatch()

  const saveError = (data) => dispatch(setError(data))
  const saveProbandProfileToStore = (data) =>
    dispatch(setProbandProfileAction(data))
  const saveCurrentMemberToStore = (data) => dispatch(storeCurrentMember(data))

  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  const { familyMembers, familyMemberLoading } = useGetFamilyMembers(
    probandID,
    authCredentials
  )

  const [profile, setMemberProfile] = useState(null)
  const [memberProfileLoading, setMemberProfileLoading] = useState(false)

  useEffect(() => {
    if (familyMemberLoading || !memberID) return setMemberProfileLoading(false)

    const getMemberProfile = async () => {
      try {
        // const probandID = proband?.probandID ?? null
        setMemberProfileLoading(true)
        const selectedMemberId = Number(memberID) ?? null
        if (!probandID) return

        // Get MemberProfile of Proband from API
        const getMemberProfilePayload = {
          path: 'member/get/',
          method: 'post',
          token: authCredentials.accessToken,
          body: {
            proband_id: probandID,
            member_id: selectedMemberId,
            lang: selectedLanguage,
          },
          pageDetails: {
            page: 'DashboardScreen.js',
          },
          params: {
            lang: selectedLanguage,
          },
        }

        const response = await ApiFetchHandler(getMemberProfilePayload)

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })

          setMemberProfileLoading(false)
          return
        }

        const memberProfile = mapDataToMemberProfile(response)

        const profileData = memberProfile?.profileData

        saveProbandProfileToStore(memberProfile)

        let age = profileData?.currentAge
        if (profileData?.deceased) {
          age = profileData?.ageOfDeath
        }

        const currentMember = new MemberProfile().constructBasicProfile({
          relationship_to_proband: getRelationshipToProband(
            memberProfile?.memberID,
            familyMembers
          ),
          member_id: memberProfile?.memberID,
          first_name: profileData?.firstName,
          last_name: profileData?.lastName,
          gender: profileData?.biologicalGender,
          is_dead: profileData?.deceased,
          age,
        })

        saveCurrentMemberToStore(currentMember)
        setMemberProfile(currentMember)
        setMemberProfileLoading(false)
      } catch (error) {
        console.log(
          '🚀 \n\n file: DashboardScreen.js:596 \n\n DashboardScreen \n\n getMemberProfile= \n\n error:',
          error
        )
      } finally {
        setMemberProfileLoading(false)
      }
    }

    getMemberProfile()
  }, [probandID, authCredentials, familyMemberLoading, memberID])

  return { profile, memberProfileLoading }
}

export default useGetMemberProfile
