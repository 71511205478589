import React from 'react'
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { i18n } from '$localization/config.js'
import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import styles, { arrowColor } from './styles'

function CollapseButton(props) {
  const {
    colorSettings,
    fieldIsHidden,
    showFieldHandler,
    title,
    arrowCollapsedPosition,
    titleTextStyle,
    children,
    dividerPosition,
  } = props

  const arrowPosition = () => {
    if (arrowCollapsedPosition === 'down') return 'chevron-down-outline'
    return 'chevron-up-outline'
  }

  const arrowImage = fieldIsHidden ? 'chevron-forward-outline' : arrowPosition()

  const textField = fieldIsHidden
    ? i18n.t('show_more').default
    : i18n.t('show_less').default
  const defaultTitle = title || textField

  return (
    <View style={{ zIndex: -1 }}>
      {dividerPosition === 'top' && (
        <View style={section_styles.contentSeparatorLine} />
      )}
      <TouchableOpacity
        style={section_styles.contentItem}
        onPress={() => {
          showFieldHandler()
        }}
      >
        <View style={section_styles.contentItemLabelTextContainer}>
          {children || (
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: colorSettings?.linked_text },
                { ...titleTextStyle },
              ]}
            >
              {defaultTitle}
            </Text>
          )}
        </View>

        <View
          style={[
            section_styles.contentItemTextInputContainer,
            styles.iconContainer,
          ]}
        >
          <Ionicons name={arrowImage} size={28} color={arrowColor} />
        </View>
      </TouchableOpacity>
      {dividerPosition === 'bottom' && (
        <View style={section_styles.contentSeparatorLine} />
      )}
    </View>
  )
}

// CollapseButton.propTypes = {
//   colorSettings: PropTypes.shape({
//     linked_text: PropTypes.string,
//   }),
//   fieldIsHidden: PropTypes.bool,
//   showFieldHandler: PropTypes.func,
//   title: PropTypes.string,
//   arrowCollapsedPosition: PropTypes.string,
//   titleTextStyle: PropTypes.shape({}),
//   children: PropTypes.node.isRequired,
//   dividerPosition: PropTypes.string,
// }

CollapseButton.defaultProps = {
  colorSettings: {
    linked_text: '',
  },
  fieldIsHidden: true,
  showFieldHandler: () => {},
  title: '',
  arrowCollapsedPosition: 'down',
  titleTextStyle: {},
  dividerPosition: '',
}

export default CollapseButton
