import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native'
import { AntDesign, Ionicons, Feather } from '@expo/vector-icons'
import styles, {
  darkRed,
  lightRed,
  darkerRed,
  darkGreen,
  lightGreen,
  darkerGreen,
  darkYellow,
  lightYellow,
  darkerYellow,
  darkBlue,
  lightBlue,
  darkerBlue,
} from './errorCard.style'

const ErrorCard = (payload) => {
  const {
    type = 'error',
    title = 'ERROR!',
    messageBody = 'Place your error message here!',
    onPress = () => {},
  } = payload

  const errorTitleText = title
  const errorBodyText = messageBody

  const typeErrorHandler = () => {
    switch (type) {
      case 'error':
        return {
          closeIconColor: darkRed,
          backgroundColor: lightRed,
          textColor: darkerRed,
          icon: (
            <AntDesign
              name="closecircleo"
              size={40}
              color={darkerRed}
              style={styles.icon}
            />
          ),
        }
      case 'success':
        return {
          closeIconColor: darkGreen,
          backgroundColor: lightGreen,
          textColor: darkerGreen,
          icon: (
            <AntDesign
              name="checkcircleo"
              size={40}
              color={darkerGreen}
              style={styles.icon}
            />
          ),
        }
      case 'warning':
        return {
          closeIconColor: darkYellow,
          backgroundColor: lightYellow,
          textColor: darkerYellow,
          icon: (
            <AntDesign
              name="warning"
              size={40}
              color={darkerYellow}
              style={styles.icon}
            />
          ),
        }
      case 'info':
        return {
          closeIconColor: darkBlue,
          backgroundColor: lightBlue,
          textColor: darkerBlue,
          icon: (
            <Feather
              name="info"
              size={40}
              color={darkerBlue}
              style={styles.icon}
            />
          ),
        }
      default:
        return {
          closeIconColor: darkRed,
          backgroundColor: lightRed,
          textColor: darkerRed,
          icon: (
            <AntDesign
              name="closecircleo"
              size={40}
              color={darkerRed}
              style={styles.icon}
            />
          ),
        }
    }
  }
  return (
    <View
      style={[
        styles.mainContainer,
        {
          borderColor: typeErrorHandler()?.closeIconColor,
          backgroundColor: typeErrorHandler()?.backgroundColor,
        },
      ]}
    >
      <View style={styles.iconContainer}>{typeErrorHandler()?.icon}</View>
      <View style={styles.messageContainer}>
        <Text
          style={[
            styles.messageTitle,
            { color: typeErrorHandler()?.textColor },
          ]}
        >
          {errorTitleText}
        </Text>
        <Text
          style={[styles.messageBody, { color: typeErrorHandler()?.textColor }]}
        >
          {errorBodyText}
        </Text>
      </View>
      <TouchableOpacity style={styles.exitButton} onPress={onPress}>
        <Ionicons
          name="ios-close"
          size={30}
          color={typeErrorHandler()?.closeIconColor}
        />
      </TouchableOpacity>
    </View>
  )
}

export default ErrorCard
