import moment from 'moment'
import { createRef } from 'react'
import { Keyboard } from 'react-native'

import BaseController from '$components/BaseController.js'

import { isNotValidAOD } from '$screens/main/family/edit_profile/_components/personal_info/components/_utils/Utils.js'

import { i18n } from '$localization/config.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import AuthCredentials from '$data_models/AuthCredentials.js'
import Proband from '$data_models/Proband'
import MemberProfile from '$data_models/MemberProfile.js'

import {
  showDefaultAlert,
  showGenericError,
} from '$navigation/_utils/NavigationUtils.js'

import {
  defaultDateFormat,
  mapDataToMemberProfile,
  yearIntervalFromNow,
} from '$api/_utils/Utils.js'

import { DefaultFullHeightForWeb } from '$constants/styles/global.styles.js'
import { ROUTES } from '$navigation/constants/routes.js'

import { convertIncomingDiseases } from '$screens/personal_details/onboarding_disease_selection/utils/_utils'

import { handleDisplayShorterAgeUnits } from '$api/_utils/Utils'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class EditProfileController extends BaseController {
  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.currentProband ?? new Proband()

  filteredMemberData = null

  basicFamilyMembers = this.props.basicFamilyMembers ?? []

  memberProfile = this.props.memberProfile ?? new MemberProfile()

  currentMember = this.props.currentMember ?? null

  state = {
    ...this.state,
    memberProfile: this.memberProfile,
    currentMember: this.currentMember,
    datePickerVisible: false,
    scrollViewHeight: getScrollViewHeight(),
    isScreenActive: true,
    keyboardShown: false,
    deleteButtonShown: true,
    currentAgeType: 'years',
    ageOfDeceasedType: 'years',
    isSameValueAges: false,
  }

  _scrollView_ = null

  _scrollViewOffsetY_ = 0

  _offsetFromContentSizeChange_ = 0

  _origScrollViewContentSize_ = { w: 0, h: 0 }

  _currentScrollViewContentSize_ = this._origScrollViewContentSize_

  bottomSheetRef = createRef()

  static getDerivedStateFromProps(newProps, prevState) {
    const memberProfile = newProps?.memberProfile
    const currentMember = newProps?.currentMember
    return {
      ...prevState,
      memberProfile,
      currentMember,
    }
  }

  constructor(props) {
    super(props)

    /* Set shared instance */
    this.constructor.setSharedInstance(this)
  }

  async componentDidMount() {
    /* Initially set reloading identifiers to default. */
    this.props._setReloadersToDefault_()

    const { setParams, getParam } = this.props.navigation

    setParams({
      cs: this.props.colorSettings,
      cm: this.state.currentMember,
    })

    this.handleMemberDeleteButton()

    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    /* Filter current Member from Family Members */
    if (this.basicFamilyMembers) {
      this.filteredMemberData = this.filterMemberFromBasicFamilyMembers(
        this.filteredMemberData,
        this.basicFamilyMembers
      )
    }

    /* Get current Member Profile */
    await this.getMemberProfile()
    this.fetchPatientDiseases()
    this.keyboardDidShowListener = Keyboard?.addListener(
      'keyboardDidShow',
      this.keyboardDidShow
    )
    this.keyboardDidHideListener = Keyboard?.addListener(
      'keyboardDidHide',
      this.keyboardDidHide
    )

    this.handleSetAgeTypeOnMount(this.state.memberProfile.profileData)
    // Set the component's navigation event listeners
    this.setNavigationEventListener('focus')
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
    this.keyboardDidShowListener.remove()
    this.keyboardDidHideListener.remove()
  }

  keyboardDidShow = () => {
    this.setState({ keyboardShown: true })
  }

  keyboardDidHide = () => {
    this.setState({ keyboardShown: false })
  }

  handleCalculateUnitTypeFromAgeUnit = (splitAgeUnit) => {
    switch (splitAgeUnit) {
      case 'd':
        return 'days'
      case 'm':
        return 'months'
      case 'w':
        return 'weeks'
      default:
        return 'years'
    }
  }

  handleSetAgeTypeOnMount = (userData) => {
    const ageString = userData?.currentAgeString

    const splitAgeUnit = ageString[ageString.length - 1]

    const isDeceasedField = userData?.deceased

    if (userData?.dob) {
      const calculatedAgeFromDOB = yearIntervalFromNow(
        userData?.dob,
        true,
        true
      )

      const ageUnit = calculatedAgeFromDOB.split(' ')[1]

      return this.setState({ currentAgeType: `${ageUnit}` })
    }

    if (!ageString) return this.setState({ currentAgeType: 'years' })

    if (isDeceasedField)
      return this.setState({
        ageOfDeceasedType:
          this.handleCalculateUnitTypeFromAgeUnit(splitAgeUnit),
      })

    this.setState({
      currentAgeType: this.handleCalculateUnitTypeFromAgeUnit(splitAgeUnit),
    })
  }

  handleMemberDeleteButton = async () => {
    const { currentProband, currentMember } = this.props
    const relatives = [
      'mother',
      'father',
      'paternal grandmother',
      'paternal grandfather',
      'maternal grandmother',
      'maternal grandfather',
      'proband',
    ]

    if (relatives.includes(currentMember.relationship_to_proband)) {
      this.setState({ deleteButtonShown: false })
      return
    }

    const payload = currentProband?.probandID
    const memberProfile = await this.getMemberPartnerAndChildren(
      payload,
      currentMember?.member_id
    )

    if (memberProfile?.length < 1) return

    if (
      memberProfile[0]?.relationship_to_proband !==
        currentMember?.relationship_to_proband ||
      memberProfile[0]?.children.length > 0
    ) {
      this.setState({ deleteButtonShown: false })
    }
  }

  getMemberPartnerAndChildren = async (payload, memberId) => {
    const MemberPartnerAndChildrenPayload = {
      path: `member/${memberId}/partner_and_children/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'EditProfile.controllers.js',
      },
    }

    const response = await ApiFetchHandler(MemberPartnerAndChildrenPayload)

    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    return response
  }

  setNavigationEventAction = async () => {
    const {
      shouldReloadParents,
      shouldReloadParentsBloodRelation,
      shouldReloadTwins,
      shouldReloadInviteStatus,
      shouldReloadDiseases,
      shouldReloadGeneticTestings,
      _setReloadersToDefault_,
    } = this.props

    /* Reload actions */
    if (shouldReloadParents) await this.updateParentInfo()
    if (shouldReloadParentsBloodRelation)
      await this.reloadParentsBloodRelationSection()
    if (shouldReloadTwins) await this.reloadTwinsSection()
    if (shouldReloadInviteStatus) await this.reloadInviteStatus()
    if (shouldReloadDiseases) await this.reloadDiseases()
    if (shouldReloadGeneticTestings) await this.reloadGeneticTestings()

    /* Return reloading identifiers to default. */
    _setReloadersToDefault_()
  }

  filterMemberFromBasicFamilyMembers = (
    filteredMemberData,
    basicFamilyMembers = []
  ) => {
    /* 
    Params:
    - filteredMemberData: <A reference holder>
    - basicFamilyMembers: <An array of basic member data>
     */

    /* Filter current Member from Family Members */
    for (const i in basicFamilyMembers) {
      const _familyGroup_ = basicFamilyMembers[i]
      const _familyGroupData_ = _familyGroup_?.data ?? []

      if (!_familyGroupData_?.length) continue
      for (const j in _familyGroupData_) {
        const _memberData_ = _familyGroupData_[j]
        filteredMemberData = this.crossCheckMemberIDs(
          filteredMemberData,
          _memberData_
        )

        /* Filter from Partners of current Member */
        if (!filteredMemberData) {
          const partners = _memberData_?.partners ?? null
          if (partners) {
            filteredMemberData = this.filterMemberFromPartners(
              filteredMemberData,
              partners
            )
          }
        }
      }
    }

    return filteredMemberData
  }

  filterMemberFromPartners = (filteredMemberData, partners = []) => {
    /* 
    Params:
    - filteredMemberData: <A reference holder>
    - partners: <An array of basic member data>
     */

    for (const i in partners) {
      const _memberData_ = partners[i]
      filteredMemberData = this.crossCheckMemberIDs(
        filteredMemberData,
        _memberData_
      )

      /* Filter from Children of current Member */
      if (!filteredMemberData) {
        const children = _memberData_?.children ?? null
        if (children) {
          filteredMemberData = this.filterMemberFromChildren(
            filteredMemberData,
            children
          )
        }
      }
    }

    return filteredMemberData
  }

  filterMemberFromChildren = (filteredMemberData, children = []) => {
    /* 
    Params:
    - filteredMemberData: <A reference holder>
    - children: <An array of basic member data>
     */

    for (const i in children) {
      const _memberData_ = children[i]
      filteredMemberData = this.crossCheckMemberIDs(
        filteredMemberData,
        _memberData_
      )

      /* 
      ## Recursive function ##
      Filter from Partners of current Member
       */
      if (!filteredMemberData) {
        const partners = _memberData_?.partners ?? null
        if (partners) {
          filteredMemberData = this.filterMemberFromPartners(
            filteredMemberData,
            partners
          )
        }
      }
    }

    return filteredMemberData
  }

  crossCheckMemberIDs = (filteredMemberData, memberData) => {
    /* 
    Params:
    - filteredMemberData: <A reference holder>
    - memberData: <A basic data of current member>
     */

    const currentMemberID = this.state.currentMember?.member_id
    const member = memberData?.member ?? memberData

    if (member?.member_id && member?.member_id === currentMemberID) {
      filteredMemberData = memberData
      return filteredMemberData
    }

    return filteredMemberData
  }

  updateState = (key, value) => {
    /* Note: Must use memberProfile from state */
    const memberProfile = this.state.memberProfile ?? new MemberProfile()

    memberProfile[key] = value

    /* Update component state */
    this.setState({ memberProfile })

    /* Update Redux store. */
    const probandID = this.proband?.probandID
    this.updateMemberProfileStore(probandID, memberProfile)
  }

  /* TODO: To be replaced by updateState() */
  updateParentState = (key, value) => {
    /* Note: Must use memberProfile from state */
    const memberProfile = this.state.memberProfile ?? new MemberProfile()
    memberProfile[key] = value

    /* Update component state */
    this.setState({ memberProfile })

    /* Update Redux store. */
    const probandID = this.proband?.probandID
    this.updateMemberProfileStore(probandID, memberProfile)
  }

  disableDoneButton = (disabled = true) => {
    this.setState({ disabledDoneButton: disabled })
  }

  showTooltip = (title = '', message = '') => {
    showDefaultAlert(title, message)
  }

  doneButtonAction = () => {
    const { keyboardShown, memberProfile, isSameValueAges } = this.state
    const { ageOfDeath, currentAge, deceased } = memberProfile.profileData

    if (!deceased) {
      this.handleDeceased({
        keyboardShown,
        memberProfile,
        ageOfDeath,
        currentAge,
      })
      return
    }

    if (!memberProfile?.profileData.currentAge || isSameValueAges)
      return this.handleKeyboardShown(keyboardShown, memberProfile)

    if (ageOfDeath > currentAge) {
      return showDefaultAlert(
        i18n.t('oops').default,
        i18n.t('age_of_death_must_not_be_greater_than_current_age').default
      )
    }
  }

  handleKeyboardShown = async (keyboardShown, memberProfile) => {
    if (keyboardShown) {
      Keyboard.dismiss()
    } else {
      /* Function for Done button. */

      /* Disable 'Done' button on press */
      this.disableDoneButton()

      Keyboard.dismiss()

      /* Note: Do not delay. Must use memberProfile from state */
      await this.updateMemberProfile(this.proband, memberProfile)
    }
  }

  handleDeceased = ({
    keyboardShown,
    memberProfile,
    ageOfDeath,
    currentAge,
  }) => {
    if (memberProfile?.profileData.currentAge) {
      if (ageOfDeath > currentAge) {
        return showDefaultAlert(
          i18n.t('oops').default,
          i18n.t('age_of_death_must_not_be_greater_than_current_age').default
        )
      }
    }
    this.handleKeyboardShown(keyboardShown, memberProfile)
  }

  navigateToInviteFamilyMember = () => {
    const { navigate } = this.props.navigation

    navigate('InviteFamilyMemberNavigator')
  }

  getMemberDataSource = () => {
    let memberID
    let member = null

    if (this.filteredMemberData?.member) {
      // Check if member data is filtered from the family list.
      member = this.filteredMemberData?.member ?? this.filteredMemberData
      memberID = member?.member_id
    } else {
      // Or else, get member data from props.
      member = this.props?.currentMember
      memberID = member?.member_id
    }

    return { memberID, member }
  }

  editDiseaseListItem = async (item) => {
    const {
      selectedDiseases,
      navigation,
      authCredentials,
      _saveIndividualDisease_,
      saveError,
    } = this.props
    const { disease_id } = item

    try {
      const diseasePayload = {
        method: 'get',
        path: 'disease/',
        token: authCredentials?.accessToken,
      }

      const response = await ApiFetchHandler(diseasePayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        return
      }

      const diseaseList = response.diseases
      let getDiseases = diseaseList.find((disease) => disease.id === disease_id)

      if (!getDiseases) {
        getDiseases = diseaseList.find(
          (disease) => disease.umls_id === disease_id
        )
      }

      const umlsDisease = selectedDiseases.find(
        (disease) => disease.disease_id === disease_id
      )

      const constructedUmlsDiseases = {
        ...umlsDisease,
        id: disease_id,
        skip_logistics: [],
        short_name: umlsDisease?.disease_name,
        umls_search: true,
        umls_id: disease_id,
      }

      const destination = 'DiseasesSkipLogicView'

      _saveIndividualDisease_(getDiseases || constructedUmlsDiseases)

      navigation.navigate(destination, {
        editMemberViewEdit: true,
      })
    } catch (err) {
      console.log(
        '🚀 \n\n file: EditProfile.controller.js:501 \n\n EditProfileController \n\n editDiseaseListItem= \n\n err:',
        err
      )
    }
  }

  updateMemberProfileStore = (probandID, memberProfile) => {
    const memberID = memberProfile?.memberID ?? null

    if (probandID && memberID) {
      // Save MemberProfile on redux store.
      this.props._storeMemberProfileAction_(memberProfile)

      if (probandID === memberID) {
        // If member is the Proband, update the ProbandProfile on Redux store.
        this.props._saveProbandProfileToStore_(memberProfile)
      }

      this.setState({ memberProfile })
    }
  }

  updateBasicMemberData = (member, memberProfile, data = {}) => {
    const profileData = memberProfile?.profileData

    const currentMemberName = [
      profileData?.firstName,
      profileData?.lastName,
    ].join(' ')

    let age = profileData?.currentAge
    if (profileData?.deceased) {
      age = profileData?.ageOfDeath
    }

    /* Update (referenced) current member data. */
    if (member?.member_id) {
      member.name = currentMemberName
      member.is_dead = profileData?.deceased
      member.age = age

      /*
      Update basic diseases list
      Note: Currently not applicable to 'partners'
      Because `data.member_disease` of partners is an Object, not Array.
      */
      try {
        let updatedMemberDiseases = []
        const _memberDiseases_ = data?.member_disease ?? []

        updatedMemberDiseases = _memberDiseases_.map((disease) => ({
          age_diagnosed: disease?.age_diagnosed ?? null,
          age_diagnosed_string: disease?.age_diagnosed_string ?? null,
          disease_short_name: disease?.disease_short_name ?? null,
        }))

        member.diseases = updatedMemberDiseases
      } catch (e) {
        console.log(
          '🚀 \n\n file: EditProfile.controller.js:558 \n\n EditProfileController \n\n e:',
          e
        )
      }

      // Save basic member profile data to redux store
      this.props._saveCurrentMemberToStore_(member)
      this.setState({
        currentMember: this.props.member,
      })
    }
  }

  handleNavigationAction = () => {
    const { navigation, route } = this.props
    const routeNameComingFrom = route.params?.routeNameComingFrom ?? false

    if (route?.params?.isComingFromFamilyMembersNavigator) {
      navigation.pop()

      return
    }

    if (routeNameComingFrom) {
      navigation.replace(routeNameComingFrom, { isModalShown: false })

      return
    }

    navigation.pop()
  }

  fetchPatientDiseases = () => {
    const { currentMemberProfile, _saveDiseaseProband_ } = this.props
    const memberDisease =
      currentMemberProfile?.healthHistoryData?.diseasesData?.diseases ?? []

    const convertedDisease = convertIncomingDiseases(memberDisease)
    _saveDiseaseProband_(convertedDisease)
  }

  getMemberProfile = async (shouldDismissComponent = false) => {
    const { selectedLanguage } = this.props
    const { memberID, member } = this.getMemberDataSource()

    if (!memberID || memberID === '') return

    const probandID = this.proband?.probandID

    /* API request to get MemberProfile */
    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
        member_id: memberID,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(getMemberProfilePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /* Map data to MemberProfile instance */
    const memberProfile = mapDataToMemberProfile(response)

    this.updateMemberProfileStore(probandID, memberProfile)
    this.updateBasicMemberData(member, memberProfile, response)

    /* Update Family Members on Redux store to apply changes on current Member. */
    this.props._setBasicFamilyMembers_(this.basicFamilyMembers)

    /* Reload and render component */
    this.reloadAndRenderView(false)

    if (shouldDismissComponent) {
      this.props._setShouldReloadFamilyList_(true)
      return this.handleNavigationAction()
    }
  }

  updateMemberProfile = async (proband, memberProfile) => {
    const { authCredentials, saveError } = this.props

    const probandID = proband?.probandID

    const payload = memberProfile?.createPayloadForUpdate(probandID)

    /* Payload fail safe */
    if (!payload) {
      this.disableDoneButton(false)
      showGenericError()
      return
    }
    /* Activate loading view. */
    this.setState({ showLoadingView: true })

    /* Update Member Personal Info */
    const updatePersonalInfoPayload = {
      path: 'member/update_personal_info/',
      method: 'post',
      token: authCredentials.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(updatePersonalInfoPayload)

    if (response.isError) {
      this.reloadAndRenderView(false)

      /* Enable 'Done' button. */
      this.disableDoneButton(false)

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /** If success, reload current Member Profile */
    await this.getMemberProfile(true)

    /* Parallel endpoint: Update Adopted Info */
    // await ApiFetchHandler(updatePersonalInfoPayload)
  }

  /* EXPERIMENT: Helper function for re-rendering/reloading only specific parts of the component */
  sectionalReload = async ({
    personalInfo = false,
    healthHistory = false,
    diseasesDataOnly = false,
    geneTestsDataOnly = false,
    relationshipInfo = false,
    parentsOnly = false,
    twinsOnly = false,
    parentsBloodRelationOnly = false,
    inviteStatusOnly = false,
  }) => {
    const { memberID, member } = this.getMemberDataSource()
    const { selectedLanguage } = this.props
    if (!memberID || memberID === '') return
    const probandID = this.proband?.probandID

    /* Activate loading view */
    this.setState({ showLoadingView: true })

    /* API request to get MemberProfile */
    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
        member_id: memberID,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(getMemberProfilePayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.reloadAndRenderView(false)
      return
    }

    /* Previous MemberProfile data from Redux store. */
    /* Note: JSON.parse strips off the class functions */
    const prevMemberProfile = this.props.memberProfile ?? new MemberProfile()

    /* New MemberProfile data from API. */
    const newMemberProfile = mapDataToMemberProfile(response)
    const newProfileData = newMemberProfile?.profileData
    const newHealthHistoryData = newMemberProfile?.healthHistoryData
    const newRelationshipData = newMemberProfile?.relationshipData

    /* Update "Invite Status" only */
    if (inviteStatusOnly) {
      prevMemberProfile.inviteStatus = newMemberProfile?.inviteStatus

      /* Update MemberProfile and BasicMemberData on Redux Store */
      const memberProfile = prevMemberProfile
      this.updateMemberProfileStore(probandID, memberProfile)
      this.updateBasicMemberData(member, memberProfile, response)
    }

    /* Update "PersonalInfo" only */
    if (personalInfo) {
      prevMemberProfile.profileData = newProfileData

      /* Update MemberProfile and BasicMemberData on Redux Store */
      const memberProfile = prevMemberProfile
      this.updateMemberProfileStore(probandID, memberProfile)
      this.updateBasicMemberData(member, memberProfile, response)
    }

    /* Update "HealthHistory" only */
    if (healthHistory) {
      switch (true) {
        case diseasesDataOnly:
          prevMemberProfile.healthHistoryData.diseasesData =
            newHealthHistoryData?.diseasesData
          break

        case geneTestsDataOnly:
          prevMemberProfile.healthHistoryData.geneTestsData =
            newHealthHistoryData?.geneTestsData
          break

        default:
          prevMemberProfile.healthHistoryData = newHealthHistoryData
          break
      }

      /* Update MemberProfile and BasicMemberData on Redux Store */
      const memberProfile = prevMemberProfile
      this.updateMemberProfileStore(probandID, memberProfile)
      this.updateBasicMemberData(member, memberProfile, response)
    }

    /* Update "RelationshipInfo" only */
    if (relationshipInfo) {
      switch (true) {
        case parentsOnly:
          prevMemberProfile.relationshipData.mother =
            newRelationshipData?.mother
          prevMemberProfile.relationshipData.father =
            newRelationshipData?.father
          break

        case twinsOnly:
          prevMemberProfile.relationshipData.isTwin =
            newRelationshipData?.isTwin
          prevMemberProfile.relationshipData.twinGroup =
            newRelationshipData?.twinGroup
          break

        case parentsBloodRelationOnly:
          prevMemberProfile.relationshipData.parentSpecific =
            newRelationshipData?.parentSpecific
          break

        default:
          prevMemberProfile.relationshipData = newRelationshipData
          break
      }

      /* FALLTHROUGH: */
      /* Update MemberProfile and BasicMemberData on Redux Store */
      const memberProfile = prevMemberProfile
      this.updateMemberProfileStore(probandID, memberProfile)
      this.updateBasicMemberData(member, memberProfile, response)
    }

    /* Exit loading view */
    this.setState({ showLoadingView: false })
  }

  updateParentInfo = async () => {
    /* This function is used to update the Parents of current Member. */

    const { memberProfile } = this.state
    const memberID = memberProfile?.memberID

    const { currentMember, saveError } = this.props
    const dataForEditParent = currentMember?.dataForEditParent
    const newParentData = dataForEditParent?.newParentData
    const newParentGroupID = newParentData?.bp_id

    if (!memberID || !newParentGroupID) return

    /* API endpoint for updating the Parents of current member */
    const updateParentInfoPayload = {
      path: 'member/update_parent_info/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        member_id: memberID,
        bp_id: newParentGroupID,
      },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(updateParentInfoPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.reloadAndRenderView(false)
      return
    }

    await this.sectionalReload({
      relationshipInfo: true,
      parentsOnly: true,
    })
  }

  removeParentsBloodRelation = async () => {
    /* This function is used for REMOVING the blood relation of the current member's Parents */
    const { authCredentials, saveError } = this.props
    const API_IS_PARENTS_BLOOD_RELATED = 'is_parent_blood_related'
    const API_BLOOD_RELATION_TYPE = 'blood_relation_type'

    const probandID = this.proband?.probandID
    const memberID = this.state.memberProfile?.memberID

    const payload = {
      proband_id: probandID,
      member_id: memberID,
    }

    payload[API_IS_PARENTS_BLOOD_RELATED] = false
    payload[API_BLOOD_RELATION_TYPE] = null

    /* API endpoint for updating Parents' blood relation */
    const updateParentBloodRelationPayload = {
      path: 'member/update_parent_blood_relation/',
      method: 'post',
      token: authCredentials.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(updateParentBloodRelationPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.reloadAndRenderView(false)
      return
    }

    await this.reloadParentsBloodRelationSection()
  }

  reloadTwinsSection = async () => {
    /* Reload Twins section. */
    await this.sectionalReload({
      relationshipInfo: true,
      twinsOnly: true,
    })
  }

  removeTwinGroup = async (twinGroupID = null, memberID) => {
    /* This function is used for removing the current member's Twin Group */
    const { authCredentials, saveError } = this.props
    if (!twinGroupID) return

    const removeTwinGroupPayload = {
      path: 'member/remove_twin/',
      method: 'put',
      token: authCredentials.accessToken,
      body: {
        twin_id: twinGroupID,
        member_id: memberID,
      },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(removeTwinGroupPayload)

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.reloadAndRenderView(false)
      return
    }

    this.sectionalReload({
      relationshipInfo: true,
      twinsOnly: true,
    })
  }

  reloadParentsBloodRelationSection = async () => {
    /* Reload Parents' Blood Relation section. */
    await this.sectionalReload({
      relationshipInfo: true,
      parentsBloodRelationOnly: true,
    })
  }

  reloadInviteStatus = async () => {
    /* Reload current member's family invitation status */
    await this.sectionalReload({ inviteStatusOnly: true })
  }

  reloadDiseases = async () => {
    /* Reload current member's Diseases section */
    await this.sectionalReload({
      healthHistory: true,
      diseasesDataOnly: true,
    })
  }

  reloadGeneticTestings = async () => {
    /* Reload current member's Genetic Testings section */
    await this.sectionalReload({
      healthHistory: true,
      geneTestsDataOnly: true,
    })
  }

  errorResponseHandler = (response) => {
    const { status, error } = response
    const { saveError } = this.props

    switch (status) {
      case 500:
        showDefaultAlert(
          i18n.t('server_error')?.default,
          i18n.t('sorry_we_cannot_process_your_request_this_time')?.default
        )
        break

      case 409:
        showDefaultAlert(
          i18n.t('action_not_allowed')?.default,
          i18n.t(
            'sorry_dot_this_person_may_already_be_attached_to_an_account_or_this_person_may_already_have_other_family_members_under_it_dot'
          )?.default
        )
        break

      default:
        saveError({
          isShown: true,
          status,
          message: error,
        })
        break
    }
  }

  deleteProfile = async (probandID, memberID) => {
    /* This function is used for deleting the current member */

    if (probandID == memberID) {
      showDefaultAlert(
        i18n.t('action_not_allowed')?.default,
        i18n.t('you_cannot_delete_your_own_profile')?.default
      )
      return
    }

    /* Activate loading view */
    this.reloadAndRenderView(true)

    /* API request to delete member */
    const deleteMemberPayload = {
      path: `member/${memberID}/proband_delete_member/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: { proband_id: probandID },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(deleteMemberPayload)

    if (response.isError) {
      this.reloadAndRenderView(false)

      this.errorResponseHandler(response)
      return
    }

    const { _setShouldReloadFamilyList_ } = this.props
    _setShouldReloadFamilyList_(true)

    setTimeout(() => this.handleNavigationAction(), 100)
  }

  alterDiseases = (id) => {
    const { memberProfile } = this.props

    memberProfile.healthHistoryData.diseasesData.diseases =
      memberProfile.healthHistoryData.diseasesData.diseases.filter(
        (disease) => disease.id !== id
      )

    return memberProfile
  }

  convertSkipLogics = (data) =>
    data.map((item) => {
      const diseaseItem = item
      if (diseaseItem.skip_logics) {
        diseaseItem.skipLogic = diseaseItem.skip_logics
        delete diseaseItem.skip_logics
      }
      return item
    })

  deleteDiseaseListItem = async (itemID) => {
    /* This function is used to delete Member Disease item */
    const { _saveDiseaseProband_, authCredentials, saveError } = this.props

    const alteredDiseaseList = this.alterDiseases(itemID)
    const convertedSkipLogic = this.convertSkipLogics(
      alteredDiseaseList?.healthHistoryData?.diseasesData?.diseases ?? []
    )
    const probandID = this.proband?.probandID
    const memberID = this.state.memberProfile?.memberID

    _saveDiseaseProband_(convertedSkipLogic)

    /* API request to delete Member Disease item */
    const removeMemberDiseasePayload = {
      path: `member/${memberID}/remove_disease/`,
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        proband_id: probandID,
        member_disease_id: itemID,
      },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(removeMemberDiseasePayload)

    if (response.isError) {
      this.reloadAndRenderView(false)

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.updateState('healthHistoryData', alteredDiseaseList.healthHistoryData)

    await this.sectionalReload({
      healthHistory: true,
      diseasesDataOnly: true,
    })
  }

  handleAgeUnitChange = (currentAgeUnit) => {
    const { memberProfile } = this.state
    const { profileData } = memberProfile
    if (profileData?.deceased)
      return this.setState({ ageOfDeceasedType: `${currentAgeUnit}` })

    return this.setState({ currentAgeType: `${currentAgeUnit}` })
  }

  handleWebDatepickerWithAgeUnits = (dob) => {
    const { memberProfile } = this.state
    const { profileData, healthHistoryData } = memberProfile

    if (!dob) return

    const memberDiseases = healthHistoryData?.diseasesData?.diseases ?? []
    const calculatedAgeFromDOB = yearIntervalFromNow(dob, true, true)

    /* Format date */
    const calculatedAge = calculatedAgeFromDOB.split(' ')[0]
    const calculatedAgeUnit = calculatedAgeFromDOB.split(' ')[1]

    if (
      isNotValidAOD(calculatedAge, memberDiseases, true, calculatedAgeFromDOB)
    )
      return

    profileData.currentAge = calculatedAge
    profileData.currentAgeString = `${calculatedAge}${handleDisplayShorterAgeUnits(
      calculatedAgeUnit
    )}`

    memberProfile.profileData = profileData
    this.setState({ currentAgeType: `${calculatedAgeUnit}` })
  }

  deleteGeneticTestingItem = async (itemID) => {
    /* This function is used for deleting a Member's Genetic Testing item */

    const probandID = this.proband?.probandID
    const memberID = this.state.memberProfile?.memberID

    /* Activate loading view */
    this.reloadAndRenderView(true)

    /* API request to delete Member's Genetic Testing item */
    const removeMemberGeneTestingPayload = {
      path: `member/${memberID}/remove_genetic_testing/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: probandID,
        member_genetic_testing_id: itemID,
      },
      pageDetails: {
        page: 'EditProfile.controller.js',
      },
    }

    const response = await ApiFetchHandler(removeMemberGeneTestingPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    await this.sectionalReload({
      healthHistory: true,
      geneTestsDataOnly: true,
    })
  }

  navigateToDiseasesMain = () => {
    const {
      _resetDiseasesStore_,
      _setFromOnboardingDiseases_,
      isCustomWorkflow,
    } = this.props
    _resetDiseasesStore_()
    _setFromOnboardingDiseases_(false)

    const routeName = isCustomWorkflow
      ? ROUTES.DiseaseListMainScreen.name
      : ROUTES.DiseasesTypeView.name

    this.navigateTo(routeName)
  }

  /* UPDATED */
  navigateToGeneticTestings = async () => {
    const { _resetGeneTestingStore_, _setFromOnboardingGeneTest_ } = this.props

    /* Set Redux identifiers */
    _resetGeneTestingStore_()
    _setFromOnboardingGeneTest_(false)

    const nextRoute = await 'PositiveGeneTesting'

    this.navigateTo(nextRoute)
  }

  navigateToPositiveTestingsOnly = async () => {
    const {
      _resetGeneTestingStore_,
      _setFromOnboardingGeneTest_,
      isCustomWorkflow,
    } = this.props

    /* Set Redux identifiers */
    _resetGeneTestingStore_()
    _setFromOnboardingGeneTest_(false)

    const nextRoute = isCustomWorkflow
      ? ROUTES.OnboardingPositiveGeneTesting.name
      : ROUTES.PositiveGeneTesting.name

    this.navigateTo(nextRoute)
  }

  navigateToNegativeTestingsOnly = () => {
    const {
      _resetGeneTestingStore_,
      _setFromOnboardingGeneTest_,
      isCustomWorkflow,
    } = this.props

    _resetGeneTestingStore_()
    _setFromOnboardingGeneTest_(false)

    const nextRoute = isCustomWorkflow
      ? ROUTES.OnboardingNegativeGeneTesting.name
      : ROUTES.NegativeGeneTesting.name

    this.navigateTo(nextRoute)
  }

  navigateToSelectParent = (currentParentData, partnerDataSource) => {
    /* Add new object to current member data */
    const currentMember = this.props.currentMember ?? {}

    if (currentMember?.member_id) {
      currentMember.dataForEditParent = {
        currentParentData,
        partnerDataSource,
      }
      this.props._saveCurrentMemberToStore_(currentMember)
    }

    const { navigate } = this.props.navigation

    navigate('SelectParentNavigator', {
      screen: 'SelectParent',
      params: {
        cpg: currentParentData?.gender,
      },
    })
  }

  navigateToSelectParentsBloodRelation = () => {
    const { navigate } = this.props.navigation

    navigate('SelectParentBloodRelationNavigator', {
      screen: 'SelectParentsBloodRelation',
    })
  }

  navigateToAddTwin = () => {
    this.props.navigation.navigate('SelectTwinNavigator', {
      screen: 'SelectTwin',
    })
  }

  toggleDatePicker = () => {
    // Opens the DatePicker View
    this.setState({ datePickerVisible: !this.state.datePickerVisible })
  }

  onDatePickerChange = ({ selectedDate, dismiss = false }) => {
    /* 
    This function triggers when a date is selected from the date picker.
    Note: Should use referenced data from state because of changes on DatePicker.
    */

    const { memberProfile } = this.state
    const { profileData, healthHistoryData } = memberProfile
    const memberDiseases = healthHistoryData?.diseasesData?.diseases ?? []

    if (selectedDate) {
      /* Double check if currentAge is valid. Abort update if FALSE. */
      const calculatedAgeFromDOB = yearIntervalFromNow(selectedDate, true, true)

      const calculatedAge = calculatedAgeFromDOB.split(' ')[0]

      const calculatedAgeUnit = calculatedAgeFromDOB.split(' ')[1]

      const currentAge = `${yearIntervalFromNow(selectedDate)}`

      if (isNotValidAOD(currentAge, memberDiseases, true, calculatedAgeFromDOB))
        return

      /* Format date */
      const formattedDOB = moment(selectedDate).format(defaultDateFormat)

      profileData.dob = formattedDOB

      profileData.currentAge = calculatedAge

      profileData.currentAgeString = `${calculatedAge}${handleDisplayShorterAgeUnits(
        calculatedAgeUnit
      )}`

      memberProfile.profileData = profileData
      this.setState({ currentAgeType: `${calculatedAgeUnit}` })
    }

    this.setState({
      memberProfile,
      datePickerVisible: !dismiss,
    })
  }

  toggleNextButtonColor = (disabled = false) => {
    let style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_enabled ?? 'white',
      borderColor: this.colorSettings?.bottom_next_btn_enabled,
    }
    if (disabled) {
      style = {
        backgroundColor:
          this.colorSettings?.bottom_next_btn_disabled ?? 'white',
        borderColor: this.colorSettings?.bottom_next_btn_disabled,
      }
    }
    return style
  }

  handleBottomSheetCurrentAgePressButton = (event) => {
    const { memberProfile } = this.state

    const profileData = memberProfile?.profileData

    profileData.currentAgeString = `${
      memberProfile.profileData.currentAge
    }${handleDisplayShorterAgeUnits(event)}`

    this.setState({
      currentAgeType: event,
    })

    this.updateState('profileData', profileData)
    return this.bottomSheetRef.current.close()
  }

  handleBottomSheetDeceasedAgePressButton = (event) => {
    const { memberProfile } = this.state

    const profileData = memberProfile?.profileData

    profileData.ageOfDeathString = `${
      memberProfile.profileData.ageOfDeath
    }${handleDisplayShorterAgeUnits(event)}`

    this.setState({
      ageOfDeceasedType: event,
    })

    this.updateState('profileData', profileData)
    return this.bottomSheetRef.current.close()
  }

  setScrollViewOffsetY = (e) => {
    this._scrollViewOffsetY_ = e?.nativeEvent?.contentOffset?.y
  }

  _onScroll_ = (e) => {
    this.setScrollViewOffsetY(e)
  }

  _onContentSizeChange_ = (w, h) => {
    /* Event for handling changes in the ScrollView's content size DO WE NEED IT? */
    switch (true) {
      case this._origScrollViewContentSize_?.w == 0:
      case this._origScrollViewContentSize_?.h == 0:
        this._origScrollViewContentSize_ = { w, h }
        break
      default:
        this._currentScrollViewContentSize_ = { w, h }
        break
    }

    const offsetY =
      this._currentScrollViewContentSize_?.h -
      this._origScrollViewContentSize_?.h

    if (offsetY > 0) {
      /* Create a negative reference of offSetY */
      this._offsetFromContentSizeChange_ = -offsetY
      this.scrollDownBy(offsetY)
    } else {
      this.scrollDownBy(this._offsetFromContentSizeChange_)
    }
  }

  scrollDownBy = (y = 0) => {
    /* Moves down the ScrollView by 'y' */
    if (!this._scrollView_) return
    this._scrollView_?.scrollTo({
      x: 0,
      y: this._scrollViewOffsetY_ + y,
      animated: true,
    })
  }

  hasTimeStampPass = (timeStamp) => {
    const durationInSeconds = 60

    const getTimeStamp = Math.floor(new Date(timeStamp) / 1000)
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const targetTimestamp = getTimeStamp + durationInSeconds

    if (currentTimestamp > targetTimestamp) {
      return true
    }

    return false
  }

  getFamilyTimeStamp = async () => {
    const payload = {
      path: `family/family_last_modified/`,
      method: 'get',
      token: this.authCredentials.accessToken,
      pageDetails: {
        page: 'FamilyMain.controllers.js',
      },
    }

    const response = await ApiFetchHandler(payload)

    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      this.setState({ sectionListRefreshing: false })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    return this.hasTimeStampPass(response)
  }

  updateSameValuesAges = (value) => {
    this.setState({ isSameValueAges: value })
  }
}

export default EditProfileController
