import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import styles from '../styles'
import { i18n } from '$localization/config.js'
import { handleRelationshipName } from '../helpers/helper'
import GeneticTestingSection from './GeneticTestingSection'
import DiseaseSection from './DiseaseSection'

function SectionListItem({ currentMember }) {
  const { error } = currentMember
  const colorSettings = useSelector((state) => state.store.colorSettings)

  if (currentMember.relationship_to_proband === 'partner') return null

  if (error) {
    return (
      <View style={styles.sectionListSummaryDetailsContainer}>
        <Text style={styles.sectionListSummaryDetailsText}>
          {i18n.t(error).default}
        </Text>
      </View>
    )
  }

  const familyMemberNameWhenFirstNameEmpty = currentMember.first_name
    ? ` (${currentMember.first_name})`
    : ''

  const familyMemberNameWithRelationship = `${handleRelationshipName(
    currentMember
  )}${familyMemberNameWhenFirstNameEmpty}`

  const isMemberProband = currentMember.relationship_to_proband === 'proband'

  const userName = isMemberProband
    ? `${currentMember.first_name} ${currentMember.last_name}`
    : `${familyMemberNameWithRelationship}`

  const userAge = currentMember?.age_string ?? currentMember?.age

  return (
    <View style={styles.sectionListItemContainer}>
      <View style={styles.sectionListItemHeaderContainer}>
        <Text style={styles.sectionListItemHeaderText(colorSettings)}>
          {userName}, {userAge ?? '?'}
        </Text>
      </View>
      <DiseaseSection diseases={currentMember?.diseases} />
      <GeneticTestingSection genes={currentMember?.genes} />
    </View>
  )
}

export default SectionListItem
