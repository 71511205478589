/* React & React Native libraries */
import { useEffect, useState } from 'react'
import { Text, View, Image, Platform, Dimensions } from 'react-native'

import { WEB } from '$constants/Platforms.js'

/* Custom Stylesheets */
import global_styles from '$constants/styles/global.styles.js'

const window = Dimensions.get('window')

const TutorialContent = ({
  colorSettings,
  numberImageSource,
  descriptionText,
  demoImageSource,
}) => {
  const [width, setWidth] = useState(window.width)

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', (window) => {
      setWidth(Dimensions.get('window').width)
    })

    return () => subscription?.remove()
  })

  const smallScreenTextFlex = Platform.OS === 'web' && width < 500 ? 0.25 : 0.35
  const smallScreenImageFlex =
    Platform.OS === 'web' && width < 500 ? 0.65 : 0.55

  return (
    <View style={{ flexDirection: 'row', flex: 1.0 }}>
      <View
        style={{
          flex: 0.1,
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Image
          style={{
            ...Platform.select({
              [WEB]: {
                width: 30,
                height: 30,
                resizeMode: 'contain',
              },
            }),
          }}
          source={numberImageSource}
        />
      </View>

      <View style={{ flex: 0.025 }} />

      <View
        style={{
          flex: smallScreenTextFlex,
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Text
          style={[
            global_styles.helperText,
            {
              textAlign: 'left',
              textAlignVertical: 'center',
              color: colorSettings?.text_input_label_color_2,
            },
          ]}
        >
          {descriptionText}
        </Text>
      </View>

      <View style={{ flex: 0.025 }} />

      <View
        style={{
          flex: smallScreenImageFlex,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <Image
          style={{
            ...Platform.select({
              [WEB]: {
                width: 200,
                height: 200,
                resizeMode: 'contain',
              },
            }),
          }}
          source={demoImageSource}
        />
      </View>
    </View>
  )
}

export default TutorialContent
