import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import decreaseFontSize from './utils/utils'

const ButtonSubView = ({
  colorSettings,
  onPress,
  styleHandler,
  fontSizeHandler,
  title,
  isDisabled,
  customStyle,
}) => (
    <TouchableOpacity
      style={[styles.buttonSelect, styleHandler(), { ...customStyle }]}
      onPress={() => onPress()}
      activeOpacity={1}
      disabled={isDisabled}
      testID="toggleButton"
    >
      <Text
        style={[
          styles.selectButtonText,
          {
            color:
              colorSettings?.selectable_btn_text_active_1 ||
              'rgba(74, 74, 74, 1)',
            // fontSize: fontSizeHandler(),
          },
        ]}
        testID="buttonTitle"
      >
        {title}
      </Text>
    </TouchableOpacity>
  )

const styles = StyleSheet.create({
  buttonSelect: {
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,.5)',
    minHeight: 60,
    marginBottom: 10,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  selectButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})

export default ButtonSubView
