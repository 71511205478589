import { useDispatch, useSelector } from 'react-redux'
import { Platform } from 'react-native'
import ApiFetchHandler from '../api/ApiFetchHandler'
import { setSelectedClinicianAction } from '../redux/clinician/actions'
import {
  setError,
  setLastLoginScreen,
  setNavigationState,
} from '../redux/defaults/actions'
import {
  setProbandAction,
  setProbandClinicianCode,
  setProbandImmediateFamily,
  setProbandProfileAction,
} from '../redux/proband/actions'
import { CUSTOM_FLOW_ROUTES } from '../navigation/constants/routes'
import {
  getFirstWorkflowScreen,
  handleNavigateToNextScreen,
} from '../navigation/_utils/custom_workflow'
import {
  emailValidator,
  errorResponseHandler,
} from '../screens/onboarding/authorizationHelpers'
import {
  setAccountAction,
  setAccountId,
  setAuthCredentialsAction,
  setUserId,
} from '../redux/account/actions'
import { setIsGeneticallyTested } from '../redux/gene_testing/actions'
import Proband from '../data_models/Proband'
import { emailToLowerCase, isEmptyObject } from '../api/_utils/Utils'
import UpdateMemberProfile from '../screens/_utils/UpdateMemberProfile'
import saveLastScreen from '../screens/_utils/SaveLastScreen'
import {
  clinicianCheck,
  getOnboardingInfo,
} from '../screens/onboarding/customValidateDOB/helpers'
import AuthCredentials from '../data_models/AuthCredentials'
import Account from '../data_models/Account'
import { RAW_LAST_VISITED_SCREENS } from '../navigation/constants/lastVisitedScreensRoutes'
import { fetchWorkflow } from '../navigation/_utils/custom_workflow/helpers'
import { showDefaultAlert } from '../navigation/_utils/NavigationUtils'
import { i18n } from '$localization/config.js'

const useAuth = () => {
  const dispatch = useDispatch()
  const appSettings = useSelector((state) => state.store.appSettings)
  const userUUID = useSelector((state) => state.store.inviteID)
  const patientData = useSelector((state) => state.accountStore.account)
  const showLoadingView = useSelector((state) => state.store.showLoadingView)
  const saveClinicianCode = (data) => dispatch(setSelectedClinicianAction(data))
  const saveProbandImmediateFamily = (item) =>
    dispatch(setProbandImmediateFamily(item))
  const saveAuthCredentialsAction = (data) =>
    dispatch(setAuthCredentialsAction(data))
  const saveUserId = (data) => dispatch(setUserId(data))
  const saveProbandToStore = (data) => dispatch(setProbandAction(data))
  const saveProbandProfileToStore = (data) =>
    dispatch(setProbandProfileAction(data))
  const saveAccountId = (id) => dispatch(setAccountId(id))
  const saveIsGeneticallyTested = (isGeneticallyTested) =>
    dispatch(setIsGeneticallyTested(isGeneticallyTested))
  const saveProbandToClinicianCode = (data) =>
    dispatch(setProbandClinicianCode(data))
  const saveNavigationState = (data) => dispatch(setNavigationState(data))
  const saveAccountData = (data) => dispatch(setAccountAction(data))
  const SaveLastScreen = (screen) => dispatch(setLastLoginScreen(screen))
  const saveError = (data) => dispatch(setError(data))

  const handlePorbandFamilySaving = (data) => {
    const familyData = {
      familyID: data.family_id,
      probandID: data.member_id,
    }

    saveProbandImmediateFamily(familyData)
  }

  const saveClinician = async (clinicianCode, headers) => {
    if (!clinicianCode) return
    const clinicianCheckPayload = {
      path: 'clinician/check/',
      method: 'post',
      token: headers.accessToken,
      body: {
        clinician_code: clinicianCode,
      },
      pageDetails: {
        page: 'useAuth hook',
      },
    }

    const response = await ApiFetchHandler(clinicianCheckPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    saveClinicianCode(response)
  }

  const handleSetUserCredentials = async (
    setPasswordPayload,
    authCredentialsPayload
  ) => {
    try {
      const setPatientPasswordPayload = {
        path: `patient_account/${userUUID}/set_credentials/`,
        method: 'put',
        body: {
          ...setPasswordPayload,
        },
        token: authCredentialsPayload?.accessToken,
        pageDetails: {
          page: 'useAuth hook',
        },
      }

      const setPatientPasswordResponse = await ApiFetchHandler(
        setPatientPasswordPayload
      )

      if (setPatientPasswordResponse.isError) {
        showLoadingView(false)
        return errorResponseHandler(setPatientPasswordResponse, saveError)
      }
    } catch (error) {
      console.log(
        '🚀 \n\n file: useAuth hook.js:446 \n\n handleSetUserCredentials \n\n err:',
        error
      )
    }
  }

  const handleLoginCustomWorkflowUser = async (
    navigation,
    email,
    password,
    hasUserEmailEntered
  ) => {
    let lastScreen
    const path = 'patient_account/invite_auth/'

    const payload = {
      inviteID: userUUID,
      dob: patientData?.dob,
    }

    const PlatformConst = Platform.OS === 'web' ? 'web' : 'mobile'

    try {
      const loginUserData = {
        path,
        method: 'post',
        body: {
          ...payload,
        },
        header: {
          'Content-Type': 'application/json;charset=UTF-8',
          platform: PlatformConst,
        },
        pageDetails: {
          page: 'useAuth hook',
        },
      }

      const loginUserDataResponse = await ApiFetchHandler(loginUserData)

      if (loginUserDataResponse.isError) {
        return errorResponseHandler(loginUserDataResponse)
      }

      const authCredentialsPayload = {
        accountID: loginUserDataResponse?.account_id,
        accessToken: loginUserDataResponse?.accessToken,
        refreshToken: loginUserDataResponse?.refreshToken,
      }

      saveAuthCredentialsAction(authCredentialsPayload)

      const userID = loginUserDataResponse?.user_id
      const accountID = loginUserDataResponse?.account_id

      if (password) {
        const setPasswordPayload = {
          ...(!hasUserEmailEntered && { email }),
          password,
        }

        await handleSetUserCredentials(
          setPasswordPayload,
          authCredentialsPayload
        )
      }

      saveAccountData(patientData)
      saveUserId(userID)
      saveAccountId(accountID)

      await saveClinician(
        appSettings?.automatic_opt_in_clinician?.code,
        authCredentialsPayload
      )

      const getOnboardingDetailsPayload = {
        path: `onboarding/${loginUserDataResponse.account_id}/get_info/`,
        method: 'get',
        token: loginUserDataResponse.accessToken,
        pageDetails: {
          page: 'useAuth hook',
        },
      }

      const response = await ApiFetchHandler(getOnboardingDetailsPayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        lastScreen = null
        return
      }

      lastScreen = response.last_screen
      saveIsGeneticallyTested(response.genetic_testing_selected)
      SaveLastScreen(lastScreen)

      const probandProfile = new Proband()

      if (lastScreen) {
        const autoOptInClinician = appSettings?.automatic_opt_in_clinician
        handlePorbandFamilySaving(loginUserDataResponse)

        if (autoOptInClinician && !isEmptyObject(autoOptInClinician)) {
          probandProfile.clinicianCode = autoOptInClinician?.code
        }

        const memberData = {
          proband_id: loginUserDataResponse?.member_id,
          member_id: loginUserDataResponse?.member_id,
        }

        probandProfile.probandID = loginUserDataResponse?.member_id
        probandProfile.familyID = loginUserDataResponse?.family_id

        const mapMemberProfile = await UpdateMemberProfile(
          memberData,
          authCredentialsPayload
        )

        saveProbandToStore({ ...probandProfile, onboarding: true })
        saveProbandProfileToStore(mapMemberProfile)
        const navigationState = response?.navigation_state

        saveNavigationState(navigationState)

        const routeToNavigate = await getFirstWorkflowScreen({
          id: userUUID,
          fileName: 'CustomValidateDOB.js',
          defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
          lastVisitedScreen: lastScreen,
          isShortCustomWorkflow: true,
        })

        setTimeout(() => {
          handleNavigateToNextScreen({
            navigation,
            routeName: routeToNavigate,
            selectedScreen: lastScreen,
          })
        }, 500)
      } else {
        probandProfile.probandID = loginUserDataResponse?.member_id
        probandProfile.familyID = loginUserDataResponse?.family_id

        saveProbandToStore(probandProfile)

        navigation.navigate('MainStack')
      }
    } catch (err) {
      console.log(
        '🚀 \n\n file: useAuth hook.js:446 \n\n handleLoginCustomWorkflowUser \n\n err:',
        err
      )
    }
  }

  const createProband = async (probandData, authCredentials) => {
    try {
      const createProbandPayload = {
        path: 'proband/add/',
        method: 'post',
        token: authCredentials.accessToken,
        body: { ...probandData },
        pageDetails: {
          page: 'useAuth hook',
        },
      }

      const response = await ApiFetchHandler(createProbandPayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.message,
        })
        return
      }

      const { proband_id, family_id } = response.data

      const familyData = {
        familyID: family_id,
        probandID: proband_id,
      }
      const memberData = {
        proband_id,
        member_id: proband_id,
      }

      saveProbandImmediateFamily(familyData)
      /* Get basic member Profile when proband is created */
      const mapMemberProfile = await UpdateMemberProfile(
        memberData,
        authCredentials
      )

      saveProbandProfileToStore(mapMemberProfile)
    } catch (err) {
      console.log(
        '🚀 \n\n file: CustomValidateDOB.js:537 \n\n createProband \n\n err:',
        err
      )
    }
  }

  const checkAndNavigateToPasswordScreen = async () => {
    let response
    try {
      response = await fetchWorkflow({
        id: userUUID,
        fileName: 'CustomValidateDOB.js',
      })
    } catch (error) {
      console.log(error)
    }

    return response?.hasForcedPassword
  }

  const handleScreenSaving = async (data, authCredentials, lastScreen) => {
    const screen = {
      last_screen: lastScreen,
    }
    const newData = {
      accountID: data.account_id,
    }

    await saveLastScreen({
      account: newData,
      authCredentials,
      item: screen,
      saveError,
    })
  }

  const createPatientAccount = async (
    navigation,
    setShowTermsPrivacy,
    email,
    password
  ) => {
    const path = 'account/add_patient_account/'

    const payloadLogin = {
      inviteID: userUUID,
      email: email ?? patientData?.email,
      dob: patientData?.dob,
      password,
    }

    const payloadDOB = {
      inviteID: userUUID,
      dob: patientData?.dob,
    }

    const selectPayload = email ? payloadLogin : payloadDOB

    try {
      const firstWorkflowScreen = await getFirstWorkflowScreen({
        id: userUUID,
        fileName: 'CustomValidateDOB.js',
        defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
        lastVisitedScreen: '',
        isShortCustomWorkflow: true,
      })

      const registerAccountPayload = {
        path,
        method: 'post',
        body: {
          ...selectPayload,
        },
        header: {
          'Content-Type': 'application/json;charset=UTF-8',
          platform: 'patient_account',
        },
        pageDetails: {
          page: 'useAuth hook',
        },
      }
      const registerAccountData = await ApiFetchHandler(registerAccountPayload)

      if (
        registerAccountData?.response ===
        'Account already exists for that patient id'
      ) {
        await handleLoginCustomWorkflowUser(navigation, email, password)
        setShowTermsPrivacy(false)

        return
      }

      if (registerAccountData.isError) {
        setShowTermsPrivacy(false)
        return errorResponseHandler(registerAccountData, saveError)
      }

      const authCredentialsPayload = {
        accountID: registerAccountData?.account_id,
        accessToken: registerAccountData?.accessToken,
        refreshToken: registerAccountData?.refreshToken,
      }

      const userID = registerAccountData?.user_id
      const accountID = registerAccountData?.account_id

      saveAuthCredentialsAction(authCredentialsPayload)

      const probandDataPayload = {
        proband: {
          account_id: registerAccountData?.account_id,
          email: email ?? patientData?.email,
          first_name: patientData?.first_name,
          middle_name: patientData?.middle_name ?? '',
          last_name: patientData?.last_name,
          age: patientData?.age,
          dob: patientData?.dob,
          phone_number: patientData?.phone_number,
          gender: patientData?.gender ?? '',
          clinician_code: registerAccountData?.clin_code,
          onboarding: true,
          patient_id: registerAccountData?.patient_id,
        },
      }
      const automatic_opt_in_clinician = appSettings?.automatic_opt_in_clinician

      if (
        automatic_opt_in_clinician &&
        !isEmptyObject(automatic_opt_in_clinician)
      ) {
        saveAccountData(patientData)
        saveUserId(userID)
        saveAccountId(accountID)

        await saveClinician(
          automatic_opt_in_clinician?.code,
          authCredentialsPayload
        )

        saveProbandToClinicianCode(automatic_opt_in_clinician?.code)

        saveProbandToStore(probandDataPayload.proband)

        probandDataPayload.proband.clinician_code =
          automatic_opt_in_clinician?.code

        await createProband(probandDataPayload, authCredentialsPayload)
        await handleScreenSaving(
          registerAccountData,
          authCredentialsPayload,
          'gender_selection'
        )

        setShowTermsPrivacy(false)

        handleNavigateToNextScreen({
          navigation,
          routeName: firstWorkflowScreen,
          selectedScreen: '',
          params: {
            nextPage: 0,
            prevPage: 0,
            navEditMode: false,
            navCompletedSurvey: null,
          },
        })
      } else {
        saveAccountData(patientData)
        saveUserId(userID)
        saveAccountId(accountID)

        await saveClinician(
          registerAccountData?.clin_code,
          authCredentialsPayload
        )

        saveProbandToClinicianCode(registerAccountData?.clin_code)

        saveProbandToStore(probandDataPayload.proband)

        await createProband(probandDataPayload, authCredentialsPayload)

        await handleScreenSaving(
          registerAccountData,
          authCredentialsPayload,
          'gender_selection'
        )

        setShowTermsPrivacy(false)

        handleNavigateToNextScreen({
          navigation,
          routeName: firstWorkflowScreen,
          selectedScreen: '',
          params: {
            nextPage: 0,
            prevPage: 0,
            navEditMode: false,
            navCompletedSurvey: null,
          },
        })
      }
    } catch (error) {
      setShowTermsPrivacy(false)

      console.log(
        '🚀 \n\n file: useAuth.js:715 \n\n createPatientAccount \n\n error:',
        error
      )
    }
  }

  const handleLoginUser = async (navigation, email, password) => {
    const PlatformConst = Platform.OS === 'web' ? 'web' : 'mobile'
    const payload = {
      username: emailToLowerCase(email),
      email: emailToLowerCase(email),
      password,
      invite_id: userUUID,
    }

    const loginAccountPayload = {
      path: 'account/login/',
      method: 'post',
      header: {
        'Content-Type': 'application/json;charset=UTF-8',
        platform: PlatformConst,
      },
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'Login.controller.js',
      },
    }

    try {
      const loginAccount = await ApiFetchHandler(loginAccountPayload)

      if (loginAccount.isError) {
        return errorResponseHandler(loginAccount, saveError)
      }

      const loginAccountData = loginAccount?.account
      const authCredentials = new AuthCredentials(loginAccount?.token)
      saveAuthCredentialsAction(authCredentials)
      const accountData = new Account(loginAccountData)
      saveAccountData(accountData)

      await saveClinician(
        appSettings?.automatic_opt_in_clinician?.code,
        authCredentials
      )

      const onboardingInfo = await getOnboardingInfo(
        accountData,
        authCredentials,
        saveError
      )

      let lastScreen = onboardingInfo.last_screen

      saveIsGeneticallyTested(onboardingInfo?.genetic_testing_selected)

      if (lastScreen === RAW_LAST_VISITED_SCREENS.sign_up_clinician) {
        const hasClinician = await clinicianCheck(
          loginAccount.family_id,
          authCredentials,
          saveError
        )

        if (hasClinician) {
          lastScreen = RAW_LAST_VISITED_SCREENS.account_created_screen
        }
      }

      SaveLastScreen(lastScreen)

      const probandProfile = new Proband()

      if (lastScreen) {
        handlePorbandFamilySaving(loginAccount)
        const autoOptInClinician = appSettings?.automatic_opt_in_clinician

        if (autoOptInClinician && !isEmptyObject(autoOptInClinician)) {
          probandProfile.clinicianCode = autoOptInClinician?.code
        }

        const memberData = {
          proband_id: loginAccount.member_id,
          member_id: loginAccount.member_id,
        }

        probandProfile.probandID = loginAccount.member_id
        probandProfile.familyID = loginAccount.family_id

        const mapMemberProfile = await UpdateMemberProfile(
          memberData,
          authCredentials
        )

        saveProbandToStore({ ...probandProfile, onboarding: true })
        saveProbandProfileToStore(mapMemberProfile)

        const navigationState = onboardingInfo?.navigation_state

        saveNavigationState(navigationState)
        const routeToNavigate = await getFirstWorkflowScreen({
          id: userUUID,
          fileName: 'CustomValidateDOB.js',
          defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
          lastVisitedScreen: lastScreen,
          isShortCustomWorkflow: true,
        })

        setTimeout(() => {
          handleNavigateToNextScreen({
            navigation,
            routeName: routeToNavigate,
            selectedScreen: lastScreen,
          })
        }, 500)
      } else {
        probandProfile.probandID = loginAccount.member_id
        probandProfile.familyID = loginAccount.family_id

        saveProbandToStore(probandProfile)
        navigation.navigate('MainStack')
      }
    } catch (error) {
      console.log(
        '🚀 \n\n file: useAuth hook.js:935 \n\n handleLoginUser \n\n error:',
        error
      )
    }
  }

  const checkPasswordValidity = (password) => {
    const PasswordValidator = require('password-validator')
    const schema = new PasswordValidator()
    schema
      .is()
      .min(8)
      .is()
      .max(100)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits()
      .has()
      .not()
      .spaces()

    if (!schema.validate(password)) {
      showDefaultAlert(
        i18n.t('password_security')?.default,
        i18n.t(
          'your_password_does_not_meet_the_requirements_please_choose_a_more_secure_password'
        )?.default
      )
      return false
    }

    return true
  }

  const checkEmailAvailability = async (email) => {
    const emailIsValid = emailValidator(email)
    if (!emailIsValid) {
      showDefaultAlert(
        i18n.t('server_error')?.default,
        i18n.t('please_enter_a_valid_email')?.default
      )

      return false
    }

    const payload = {
      email,
    }

    try {
      const emailAvailabilityPayload = {
        path: 'account/check_email/',
        method: 'post',
        body: {
          ...payload,
        },
        pageDetails: {
          page: 'customCreatePassword.js',
        },
      }

      const emailAvailabilityResponse = await ApiFetchHandler(
        emailAvailabilityPayload
      )

      if (emailAvailabilityResponse.isError) {
        errorResponseHandler(emailAvailabilityResponse, saveError)
        return false
      }

      return true
    } catch (error) {
      console.log(
        '🚀 \n\n file: useAuth hook.js:935 \n\n checkEmailAvailability \n\n error:',
        error
      )
    }
  }

  return {
    handlePorbandFamilySaving,
    saveClinician,
    handleLoginCustomWorkflowUser,
    createProband,
    createPatientAccount,
    handleLoginUser,
    handleSetUserCredentials,
    checkEmailAvailability,
    checkPasswordValidity,
    checkAndNavigateToPasswordScreen,
  }
}

export default useAuth
