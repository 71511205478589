/* React & React Native libraries */
import { Text, View, Image, ScrollView } from 'react-native'

/* Custom Components and Constants */
import TutorialContent from './_components/TutorialContent.js'

import { i18n } from '$localization/config.js'

/* Custom Stylesheets */
import global_styles from '$constants/styles/global.styles.js'

const RelationshipInfoTutorialPage3 = ({ colorSettings }) => (
  <View
    style={[
      global_styles.content,
      {
        backgroundColor: 'transparent',
        flex: 1.0,
      },
    ]}
  >
    <View style={{ paddingHorizontal: 16, paddingVertical: 16 }}>
      <Text
        style={[
          global_styles.headerBarMainTitle,
          {
            color: colorSettings?.text_input_title_color_2,
          },
        ]}
      >
        {i18n.t('adopted')?.title}
      </Text>
    </View>
    <ScrollView
      style={{ width: '100%' }}
      contentContainerStyle={{ paddingHorizontal: 24, paddingVertical: 24 }}
    >
      <View style={[global_styles.webContainer, { backgroundColor: '' }]}>
        <TutorialContent
          colorSettings={colorSettings}
          numberImageSource={require('$assets/images/new_assets/t-1.png')}
          descriptionText={i18n.t('tap_the_profile')?.default}
          demoImageSource={require('$assets/images/new_assets/t2-img1.png')}
        />

        <View style={{ height: 48 }} />

        <TutorialContent
          colorSettings={colorSettings}
          numberImageSource={require('$assets/images/new_assets/t-2.png')}
          descriptionText={
            i18n.t('scroll_to_relationship_section_and_toggle_on_adopted')
              ?.default
          }
          demoImageSource={require('$assets/images/new_assets/t2-img2.png')}
        />

        <View style={{ height: 48 }} />
        <TutorialContent
          colorSettings={colorSettings}
          numberImageSource={require('$assets/images/new_assets/t-3.png')}
          descriptionText={i18n.t('edit_adoption_details')?.default}
          demoImageSource={require('$assets/images/new_assets/t2-img3.png')}
        />
      </View>
    </ScrollView>
  </View>
)

export default RelationshipInfoTutorialPage3
