import { Component } from 'react'
import { SafeAreaView, View } from 'react-native'

import { connect } from 'react-redux'

import { WebView } from 'react-native-webview'
import { MaterialIndicator } from 'react-native-indicators'

import { i18n } from '$localization/config.js'

import global_styles from '$constants/styles/global.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

class CustomWebView extends Component {
  state = {
    shouldShowComponent: false,
    showLoadingView: true,
    uri: this.props.customWebViewUri,
  }

  componentDidMount() {
    this.handleHeaderNavigation()

    this.setState({
      shouldShowComponent: true,
      uri: this.props.customWebViewUri,
    })
  }

  handleHeaderNavigation = () => {
    const { navigation, route, colorSettings } = this.props

    const showLoadingView = route.params?.lv ?? true
    let headerButton

    if (showLoadingView) {
      headerButton = (
        <View style={global_styles.headerButton}>
          <MaterialIndicator color={colorSettings?.nav_title_light} size={24} />
        </View>
      )
    } else {
      headerButton = null
    }

    navigation.setOptions({
      headerRight: () => headerButton,
    })
  }

  toggleLoadingView = (start = true) => {
    const { navigation } = this.props

    navigation.setParams({ lv: start })
  }

  render() {
    const { navigation, colorSettings } = this.props
    const { uri, shouldShowComponent } = this.state

    if (shouldShowComponent) {
      return (
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.container,
              {
                backgroundColor: colorSettings?.post_onboarding_bgcolor,
              },
            ]}
          >
            <WebView
              style={{ flex: 1.0, width: '100%' }}
              source={{ uri }}
              onLoadStart={() => this.toggleLoadingView(true)}
              onLoad={() => this.toggleLoadingView(false)}
            />
          </SafeAreaView>
        </MainWrapper>
      )
    }

    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[
            global_styles.container,
            {
              backgroundColor: colorSettings?.post_onboarding_bgcolor,
            },
          ]}
        />
      </MainWrapper>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    customWebViewUri: store.customWebViewUri,
  }
}

export default connect(mapGlobalStateToProps, null)(CustomWebView)
