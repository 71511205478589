/* eslint-disable react-native/no-raw-text */
import { Text, SafeAreaView, ScrollView, StyleSheet } from 'react-native'

import {
  HIDE_RISK,
  SHOW_DOCUMENTS,
  GENETIC_RESOURCES_URL,
  SHOW_NEGATIVE_TEST,
  SHOW_POWERED_BY_LOGO,
  CUSTOM_SURVEY_URL,
  MULTI_LANGUAGE,
  BASE_URL,
  APP_BUILD_NUMBER,
} from '$constants/envVariables.js'

import {
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  DOMAIN_CLIENT,
  DOMAIN_CLIENT_NAME,
  VERSION_MODE,
  CLIN_PORTAL_URL,
} from '$constants/globalVariables.js'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
  },
  sectionText: {
    fontWeight: 'bold',
    paddingTop: 32,
    paddingBottom: 12,
    paddingHorizontal: 10,
    fontSize: 24,
  },
  text: {
    fontWeight: 'bold',
    padding: 10,
  },
  textValue: {
    fontWeight: 'normal',
  },
  scroll: {
    margin: 12,
  },
})

const GLOBAL_VARIABLES = [
  {
    id: 0,
    value: APP_STORE_LINK,
    name: 'APP_STORE_LINK',
  },
  {
    id: 1,
    value: PLAY_STORE_LINK,
    name: 'PLAY_STORE_LINK',
  },
  {
    id: 2,
    value: DOMAIN_CLIENT,
    name: 'DOMAIN_CLIENT',
  },
  {
    id: 3,
    value: DOMAIN_CLIENT_NAME,
    name: 'DOMAIN_CLIENT_NAME',
  },
  {
    id: 4,
    value: VERSION_MODE,
    name: 'VERSION_MODE',
  },
  {
    id: 5,
    value: CLIN_PORTAL_URL,
    name: 'CLIN_PORTAL_URL',
  },
]

const ENV_VARIABLES = [
  {
    id: 0,
    value: HIDE_RISK,
    name: 'HIDE_RISK',
  },
  {
    id: 1,
    value: SHOW_DOCUMENTS,
    name: 'SHOW_DOCUMENTS',
  },
  {
    id: 2,
    value: GENETIC_RESOURCES_URL,
    name: 'GENETIC_RESOURCES_URL',
  },
  {
    id: 3,
    value: SHOW_NEGATIVE_TEST,
    name: 'SHOW_NEGATIVE_TEST',
  },
  {
    id: 4,
    value: SHOW_POWERED_BY_LOGO,
    name: 'SHOW_POWERED_BY_LOGO',
  },
  {
    id: 5,
    value: CUSTOM_SURVEY_URL,
    name: 'CUSTOM_SURVEY_URL',
  },
  {
    id: 6,
    value: MULTI_LANGUAGE,
    name: 'MULTI_LANGUAGE',
  },
  {
    id: 7,
    value: BASE_URL,
    name: 'BASE_URL',
  },
  {
    id: 2,
    value: APP_BUILD_NUMBER,
    name: 'APP_BUILD_NUMBER',
  },
]

function EnvText({ children }) {
  return <Text style={styles.text}>{children}</Text>
}

export default function EnvSecretScreen() {
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scroll}>
        <Text style={styles.sectionText}>ENV VARIABLES</Text>
        {ENV_VARIABLES.map((item) => (
          <EnvText key={item.id}>
            {item.name}: <Text style={styles.textValue}>{item.value}</Text>
          </EnvText>
        ))}

        <Text style={styles.sectionText}>GLOBAL VARIABLES</Text>
        {GLOBAL_VARIABLES.map((item) => (
          <EnvText key={item.id}>
            {item.name}: <Text style={styles.textValue}>{item.value}</Text>
          </EnvText>
        ))}
      </ScrollView>
    </SafeAreaView>
  )
}
