import { i18n } from '$localization/config.js'
import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

const onlySpacesChecker = (string) => string?.trim()?.length === 0

export default function nameHandler(member, siblingsList = []) {
  if (siblingsList?.length === 0) {
    return {
      memberName: '',
      nameTextStyle: {},
    }
  }

  const existingMemberList = siblingsList?.find(
    (sibling) => sibling?.member_id === member?.id
  )

  const relationshipName = onlySpacesChecker(existingMemberList?.name)
    ? existingMemberList?.relationship.split(' ')
    : []

  const memberName = existingMemberList?.relationship
    ? `${i18n.t(relationshipName[0].toLowerCase()).default} ${
        relationshipName[1] ?? ''
      }`
    : member?.name

  const nameTextStyle = existingMemberList?.relationship
    ? custom_styles.itemDetailsSubtitleText
    : custom_styles.memberListItemTitleText

  return {
    memberName: memberName ?? '',
    nameTextStyle: nameTextStyle ?? {},
  }
}
