import { Component } from 'react'
import { View, Text, TextInput } from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'

const FIRST_NAME = 'firstName'
const LAST_NAME = 'lastName'

class NameField extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  state = {
    memberProfile: this.memberProfile,
    profileData: this.profileData,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const { profileData } = memberProfile
    return {
      ...prevState,
      profileData,
    }
  }

  onChangeText = (key, val) => {
    // let newState = this.state;
    // newState.profileData[key] = val
    // this.setState(newState)

    this.parentController?._updateState_(key, val)
  }

  render() {
    if (!this.state.profileData) return null

    const { profileData } = this.state

    return (
      <View>
        <View style={section_styles.contentItem}>
          <View style={section_styles.contentItemLabelTextContainer}>
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: this.colorSettings?.text_input_label_color_1 },
              ]}
              onPress={() => {
                this.firstNameInput.focus()
              }}
            >
              {i18n.t('first_name').default}
            </Text>
          </View>

          <View style={section_styles.contentItemTextInputContainer}>
            <TextInput
              nativeID="web_text_input"
              ref={(input) => {
                this.firstNameInput = input
              }}
              style={[
                section_styles.contentItemTextInput,
                { color: this.colorSettings?.text_input_color_1 },
              ]}
              maxLength={45}
              placeholder={i18n.t('optional').default}
              placeholderTextColor={
                this.colorSettings?.text_input_placeholder_color_1 ||
                'rgba(74,74,74,0.5)'
              }
              autoCorrect={false}
              autoCapitalize="words"
              onChangeText={(val) => this.onChangeText(FIRST_NAME, val)}
              onChange={this.props.onChange}
              value={profileData?.firstName}
            />
          </View>
        </View>

        <View style={section_styles.contentSeparatorLine} />

        <View style={section_styles.contentItem}>
          <View style={section_styles.contentItemLabelTextContainer}>
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: this.colorSettings?.text_input_label_color_1 },
              ]}
              onPress={() => {
                this.lastNameInput.focus()
              }}
            >
              {i18n.t('last_name').default}
            </Text>
          </View>

          <View style={section_styles.contentItemTextInputContainer}>
            <TextInput
              nativeID="web_text_input"
              ref={(input) => {
                this.lastNameInput = input
              }}
              style={[
                section_styles.contentItemTextInput,
                { color: this.colorSettings?.text_input_color_1 },
              ]}
              maxLength={45}
              placeholder={i18n.t('optional').default}
              placeholderTextColor={
                this.colorSettings?.text_input_placeholder_color_1 ||
                'rgba(74,74,74,0.5)'
              }
              autoCorrect={false}
              autoCapitalize="words"
              onChangeText={(val) => this.onChangeText(LAST_NAME, val)}
              onChange={this.props.onChange}
              value={profileData?.lastName}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default NameField
