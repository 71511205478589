import { useSelector } from 'react-redux'
import LoadingView from '../../constants/LoadingView'

function LoadingViewWrapper({ children }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const showLoadingView = useSelector((state) => state.store.showLoadingView)

  return (
    <>
      {children}
      <LoadingView
        backgroundColor={colorSettings?.splash_bgcolor}
        tintColor={colorSettings?.btn_no_fill_border_1}
        textColor={colorSettings?.btn_no_fill_text_1}
        visible={showLoadingView}
      />
    </>
  )
}

export default LoadingViewWrapper
