import { Component } from 'react'
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import { connect } from 'react-redux'

import saveLastScreen from '$screens/_utils/SaveLastScreen'

import { setError } from '$redux/defaults/actions.js'

import { i18n } from '$localization/config.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const CURRENT_SCREEN_NAME =
  CUSTOM_FLOW_ROUTES?.SuccessScreenPersonalHealthInfo?.name

const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },

  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
  },

  messageText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color: 'white',
  },

  helperMessageText: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
    color: 'white',
  },

  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  nextButtonText: {
    color: 'rgb(101,209,185)',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
})

class SuccessScreenPersonalHealthInfo extends Component {
  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      skipLinkModalHidden: true,
    }
  }

  nextButtonAction = () => {
    const { account, authCredentials, navigation, saveError } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.relatives_grandparents,
    }

    saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
    })

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.ParentsGrandparentsList.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  skipButtonAction = () => {
    const {
      account,
      authCredentials,
      turnOnIsFirstLogin,
      navigation,
      saveError,
    } = this.props

    const screen = {
      last_screen: '',
    }

    saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
    })

    turnOnIsFirstLogin()

    navigation.navigate('MainStack', {
      screen: 'Main',
    })
  }

  render() {
    const message = [i18n.t('now_lets_add_your_family_members').default].join(
      '\n\n'
    )

    const helperMessage = [
      i18n.t(
        'or_skip_this_part_if_you_are_adopted_and_dont_have_health_information_about_your_blood_relatives'
      ).default,
    ].join('\n\n')

    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor:
              this.colorSettings?.success_screen_personal_health_info_bg ||
              'white',
          },
        ]}
      >
        <ScrollView>
          <View style={{ height: 50 }} />

          <View style={styles.messageContentContainer}>
            <Image
              source={require('$assets/images/new_assets/success-check-purple.png')}
              style={{ width: 180, height: 180, resizeMode: 'contain' }}
            />

            <View style={{ width: '100%', marginTop: 10 }}>
              <Text
                style={[
                  styles.titleText,
                  {
                    color:
                      this.colorSettings
                        ?.success_screen_personal_health_info_header_text ||
                      'black',
                  },
                ]}
              >
                {i18n.t('personal_health_information_complete').default}
              </Text>
            </View>

            <View
              style={{ width: '100%', marginTop: 25, paddingHorizontal: 16 }}
            >
              <Text
                style={[
                  styles.messageText,
                  {
                    color:
                      this.colorSettings
                        ?.success_screen_personal_health_info_text || 'black',
                  },
                ]}
              >
                {message}
              </Text>

              <View style={{ height: 20 }} />

              <Text
                style={[
                  styles.helperMessageText,
                  {
                    color:
                      this.colorSettings
                        ?.success_screen_personal_health_info_text || 'black',
                  },
                ]}
              >
                {helperMessage}
              </Text>
            </View>

            <View style={{ marginTop: 25, marginHorizontal: 16 }}>
              <View style={styles.nextButtonContainer}>
                <TouchableOpacity
                  style={[
                    styles.nextButton,
                    {
                      borderColor:
                        this.colorSettings
                          ?.success_screen_personal_health_info_button_border ||
                        'black',
                      backgroundColor:
                        this.colorSettings
                          ?.success_screen_personal_health_info_button_bg ||
                        'white',
                      borderWidth: 1.0,
                    },
                  ]}
                  onPress={this.nextButtonAction}
                >
                  <Text
                    style={[
                      styles.nextButtonText,
                      {
                        color:
                          this.colorSettings
                            ?.success_screen_personal_health_info_button_text ||
                          'black',
                      },
                    ]}
                  >
                    {i18n.t('continue').default}
                  </Text>
                </TouchableOpacity>
              </View>

              <View style={{ height: 10 }} />

              <View style={styles.nextButtonContainer}>
                <TouchableOpacity
                  style={[
                    styles.nextButton,
                    { backgroundColor: 'transparent' },
                  ]}
                  onPress={this.skipButtonAction}
                >
                  <Text
                    style={[
                      styles.nextButtonText,
                      {
                        color:
                          this.colorSettings
                            ?.success_screen_personal_health_info_skip_button_text ||
                          'black',
                        textDecorationLine: 'underline',
                      },
                    ]}
                  >
                    {i18n.t('skip').default}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore } = state
  return {
    isFirstLogin: store.isFirstLogin,
    colorSettings: store.colorSettings,
    account: accountStore.account,
    authCredentials: accountStore.authCredentials,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    turnOnIsFirstLogin: () => dispatch({ type: 'TURN_ON_IS_FIRST_LOGIN' }),
    turnOffIsFirstLogin: () => dispatch({ type: 'TURN_OFF_IS_FIRST_LOGIN' }),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(SuccessScreenPersonalHealthInfo)
