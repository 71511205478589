import { Component } from 'react'
import { SafeAreaView } from 'react-native'

import { WebView } from 'react-native-webview'
import LoadingView from '$constants/LoadingView.js'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'

class ViewOnWeb extends Component {
  uri = this.props.navigation.getParam('uri')

  title = this.props.navigation.getParam('title')

  // ========================== header ==========================

  static navigationOptions = ({ navigation }) => {
    const colorSettings = navigation.getParam('cs')
    const options = DefaultNavigationOptions({
      navigation,
      hasCloseButton: true,
      closeButtonType: 'back',
      headerBackgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
      headerTitle: navigation.getParam('getTitle'),
    })

    return options
  }

  // ========================== end of header ==========================

  constructor(props) {
    super(props)

    this.state = {
      showLoadingView: true,
    }
  }

  componentDidMount() {
    this.props.navigation.setParams({ getTitle: this.title })
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <WebView
          style={{ flex: 1 }}
          source={{ uri: this.uri }}
          onLoad={() => this.setState({ showLoadingView: false })}
        />
        {this.state.showLoadingView && (
          <LoadingView visible={this.state.showLoadingView} message="Loading" />
        )}
      </SafeAreaView>
    )
  }
}

export default ViewOnWeb
