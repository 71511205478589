import { Platform, NativeModules } from 'react-native'

import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/en-AU'
import 'intl/locale-data/jsonp/en-IE'
import 'intl/locale-data/jsonp/en-GB'
import 'intl/locale-data/jsonp/en-CA'

export default function GetDateFormatString(
  useMonthName = true,
  separator = ' '
) {
  const locale =
    Platform.OS === 'ios'
      ? NativeModules.SettingsManager.settings.AppleLocale ||
        NativeModules.SettingsManager.settings.AppleLanguages[0] // ios 13
      : NativeModules.I18nManager
      ? NativeModules.I18nManager.localeIdentifier
      : 'en_US'

  let newLocale = null

  const monthFormat = useMonthName ? 'MMM' : 'MM'

  // Default date format
  let dateFormat = [monthFormat, 'DD', 'YYYY']

  try {
    newLocale = locale.replace(/_/g, '-')
  } catch {
    newLocale = locale
  }

  switch (newLocale) {
    case 'en-IE':
    case 'en-GB':
    case 'en-AU':
      dateFormat = ['DD', monthFormat, 'YYYY']
      break
  }

  return dateFormat.join(separator)
}
