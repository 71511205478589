import {
  SafeAreaView,
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

/* Custom Components and Constants */
import BaseController from '$components/BaseController.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$constants/LoadingView.js'
import DarkGraySwitch from '$components/custom-switches/DarkGraySwitch'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import AuthCredentials from '$data_models/AuthCredentials'
import Proband from '$data_models/Proband'

import { i18n } from '$localization/config.js'
import { WEB } from '$constants/Platforms.js'

import {
  showDefaultAlert,
  showGenericServerError,
} from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

import {
  getFamilySideAndRelationshipGender,
  capitalizeFirstLetter,
} from '$api/_utils/Utils.js'

import { storeCurrentMember } from '$redux/member_profile/actions.js'

import global_styles, {
  DefaultFullHeightForWeb,
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'

import custom_styles from '$screens/main/dashboard/invite/_styles/main.styles.js'
import section_styles from './styles/section.styles.js'

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    borderBottomWidth: 2.0,
    borderBottomColor: 'rgb(234,234,234)',
  },
  headerContent: {
    padding: 16,
    flexDirection: 'row',
  },
  headerTitleContainer: {
    justifyContent: 'center',
    paddingHorizontal: 16,
    flex: 1.0,
  },
  headerTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
  },
  headerSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
  },
  contentItem: {
    marginHorizontal: 16,
    marginVertical: 8,
    flexDirection: 'row',
    paddingTop: 12,
    height: 50,
  },
  contentItemLabelTextContainer: {
    flex: 0.8,
    justifyContent: 'flex-start',
  },
  contentItemTextInputContainer: {
    flex: 0.2,
    height: '100%',
    width: '100%',
  },
})

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class SignupFamilySharingSettingsDetail extends BaseController {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  state = {
    ...this.state,
    currentMember: this.props.currentMember,
    scrollViewHeight: getScrollViewHeight(),
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { currentMember } = newProps
    return {
      ...prevState,
      currentMember,
    }
  }

  constructor(props) {
    super(props)

    /* Set shared instance */
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    this.reloadAndRenderView(false)
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  updateMemberSharingSettings = async (
    allowSending = null,
    allowReceiving = null
  ) => {
    /* Prepare API request to updae member sharing settings */

    const { currentMember } = this.state
    const memberID = currentMember?.member_id

    const payload = {
      proband_id: this.proband?.probandID,
      send_notification: null,
      received_notification: null,
    }

    if (allowSending) {
      payload.send_notification = allowSending === 'yes'
    } else {
      payload.send_notification = currentMember?.sharingSettings?.notifyMembers
    }

    if (allowReceiving) {
      payload.received_notification = allowReceiving === 'yes'
    } else {
      payload.received_notification = currentMember?.sharingSettings?.notifyMe
    }

    /* Activate loading view */
    this.toggleLoadingView(true)

    const updateMemberSharingSettingsPayload = {
      path: `member/${memberID}/update_sharing_settings/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SignupFamilySharingSettings.detail.js',
      },
    }

    const response = await ApiFetchHandler(updateMemberSharingSettingsPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.toggleLoadingView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return showDefaultAlert(
        i18n.t('server_error')?.default,
        i18n.t(
          'sorry_we_cannot_process_your_request_at_this_time_please_try_again_later'
        )?.default
      )
    }

    if (allowSending) {
      currentMember.sharingSettings.notifyMembers = allowSending === 'yes'
    }

    if (allowReceiving) {
      currentMember.sharingSettings.notifyMe = allowReceiving === 'yes'
    }

    /* Update state */
    this.setState({ currentMember })

    /* Update current member from Redux store */
    this.props._storeCurrentMember_(currentMember)

    return this.toggleLoadingView(false)
  }

  toggleNotifyMeTooltip = () => {
    showDefaultAlert(
      i18n.t('notify_me')?.default,
      i18n.t(
        'you_will_get_notifications_of_changes_your_family_member_makes_to_their_record_and_you_will_have_a_chance_to_accept_or_decline_those_changes_if_you_decline_the_changes_it_will_only_affect_your_record_not_theirs'
      )?.default
    )
  }

  toggleNotifyMembersTooltip = () => {
    showDefaultAlert(
      i18n.t('notify_family_member')?.default,
      i18n.t(
        'when_you_make_changes_your_family_member_will_get_a_notification_they_will_have_a_chance_to_accept_or_decline_those_changes_for_their_own_record_if_they_decline_a_change_it_will_not_affect_your_record_just_theirs'
      )?.default
    )
  }

  toggleNotifyMeSwitch = async (val) => {
    const allowReceiving = val ? 'yes' : 'no'
    await this.updateMemberSharingSettings(null, allowReceiving)
  }

  toggleNotifyMembersSwitch = async (val) => {
    const allowSending = val ? 'yes' : 'no'
    await this.updateMemberSharingSettings(allowSending, null)
  }

  toggleLoadingView = (show = false) => {
    this.setState({ showLoadingView: show })
  }

  render() {
    const { colorSettings } = this.props

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView
          style={[
            custom_styles.container,
            {
              backgroundColor: colorSettings?.post_onboarding_bgcolor,
              ...Platform.select({
                [WEB]: {
                  flex: null,
                  height: this.state.scrollViewHeight,
                },
              }),
            },
          ]}
        >
          <LoadingView
            visible={this.state.showLoadingView}
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
          />
        </SafeAreaView>
      )
    }

    const member = this.state.currentMember

    if (!member?.member_id) return null

    const name =
      `${member?.name}`.trim() == '' ? i18n.t('unnamed')?.default : member?.name
    const gender = member?.gender

    const notifyMe = member?.sharingSettings?.notifyMe
    const notifyMembers = member?.sharingSettings?.notifyMembers

    let genderIcon = null

    switch (gender.toLowerCase()) {
      case 'm':
        genderIcon = require('$assets/images/icon/male.png')
        break
      case 'f':
        genderIcon = require('$assets/images/icon/female.png')
        break
    }

    const _relationshipToProband = getFamilySideAndRelationshipGender(
      member?.relationship,
      gender
    )

    const _relationship = _relationshipToProband[0]?.toLowerCase()
    const _familySide = _relationshipToProband[1]?.toLowerCase()
    let _greatCounter = _relationshipToProband[2]

    let relationship =
      i18n.t(_relationship)?.default ??
      capitalizeFirstLetter(_relationship, true)

    // Append Family side if available.
    if (_familySide) {
      relationship = `${relationship} (${i18n.t(_familySide)?.default})`
    }

    // Insert 'great' if needed.
    while (_greatCounter > 0) {
      relationship = `${i18n.t('great')?.default} ${relationship}`
      _greatCounter--
    }

    return (
      <SafeAreaView
        nativeID="web_scaled_main_container"
        style={[
          custom_styles.container,
          {
            ...Platform.select({
              [WEB]: {
                flex: null,
                height: this.state.scrollViewHeight,
              },
            }),
          },
        ]}
      >
        {/* Header Container */}
        <View style={styles.headerContainer}>
          <View style={styles.headerContent}>
            <View style={{ backgroundColor: 'rgba(65,70,97,0.1)' }}>
              <Image
                style={{ width: 42, height: 42, resizeMode: 'contain' }}
                source={genderIcon}
              />
            </View>

            <View style={styles.headerTitleContainer}>
              <View>
                <Text
                  style={[
                    styles.headerTitleText,
                    {
                      color: colorSettings?.member_tile_title,
                    },
                  ]}
                >
                  {name}
                </Text>
                <Text
                  style={[
                    styles.headerSubtitleText,
                    {
                      color: colorSettings?.member_tile_subtitle,
                    },
                  ]}
                >
                  {relationship}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Sharing Settings */}
        <View
          nativeID="web_scaled_main_container"
          style={[custom_styles.content, { marginTop: 16 }]}
        >
          <View
            style={[
              section_styles.container,
              {
                backgroundColor:
                  colorSettings?.dashboard_section_btn_bg ?? 'white',
                paddingBottom: 8,
              },
            ]}
          >
            <View style={section_styles.content}>
              <View style={section_styles.titleContainer}>
                <View style={section_styles.titleTextContainer}>
                  <Text style={section_styles.titleText}>
                    {i18n.t('sharing_settings')?.default}
                  </Text>
                </View>
              </View>

              <View style={styles.contentItem}>
                <View style={styles.contentItemLabelTextContainer}>
                  <Text
                    style={[
                      section_styles.contentItemLabelText,
                      {
                        // color: colorSettings?.text_input_label_color_1 ?? 'rgba(153, 153, 153, 1)'
                      },
                    ]}
                  >
                    {
                      i18n.t(
                        'receive_notifications_when_family_member_updates_data'
                      )?.default
                    }
                  </Text>
                </View>

                <TouchableOpacity
                  style={section_styles.infoButton}
                  activeOpacity={0.3}
                  onPress={() => this.toggleNotifyMeTooltip()}
                >
                  <Image
                    style={section_styles.infoButtonIcon}
                    source={require('$assets/images/new_assets/icon-info-teal.png')}
                  />
                </TouchableOpacity>

                <View style={styles.contentItemTextInputContainer}>
                  <DarkGraySwitch
                    value={notifyMe}
                    onValueChange={(val) => this.toggleNotifyMeSwitch(val)}
                  />
                </View>
              </View>

              <View style={section_styles.contentSeparatorLine} />

              <View style={styles.contentItem}>
                <View style={styles.contentItemLabelTextContainer}>
                  <Text
                    style={[
                      section_styles.contentItemLabelText,
                      {
                        // color: colorSettings?.text_input_label_color_1 ?? 'rgba(153, 153, 153, 1)'
                      },
                    ]}
                  >
                    {
                      i18n.t(
                        'send_notifications_to_family_member_when_i_update_data'
                      )?.default
                    }
                  </Text>
                </View>

                <TouchableOpacity
                  style={section_styles.infoButton}
                  activeOpacity={0.3}
                  onPress={() => this.toggleNotifyMembersTooltip()}
                >
                  <Image
                    style={section_styles.infoButtonIcon}
                    source={require('$assets/images/new_assets/icon-info-teal.png')}
                  />
                </TouchableOpacity>

                <View style={styles.contentItemTextInputContainer}>
                  <DarkGraySwitch
                    value={notifyMembers}
                    onValueChange={(val) => this.toggleNotifyMembersSwitch(val)}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>

        {this.state.showLoadingView && (
          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        )}
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    proband: probandStore.proband,
    /** MemberProfile Store */
    currentMember: memberProfileStore.currentMember,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _storeCurrentMember_: (data) => dispatch(storeCurrentMember(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFamilySharingSettingsDetail)
