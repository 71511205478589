import { Component } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'

import DarkGraySwitch from '$components/custom-switches/DarkGraySwitch'

import { i18n } from '$localization/config.js'

import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

class AdoptedSection extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  currentMember = this.props.currentMember

  relationshipData = this.props.relationshipData

  state = {
    currentMember: this.currentMember,
    relationshipData: this.relationshipData,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { currentMember } = newProps
    const { relationshipData } = newProps
    return {
      currentMember,
      relationshipData,
    }
  }

  toggleIsAdopted = (val) => {
    this.parentController?.toggleIsAdopted(val)
  }

  toggleIsAdoptedOut = (val) => {
    this.parentController?.toggleIsAdoptedOut(val)
  }

  toggleIsChildOfRelative = (val) => {
    this.parentController?.toggleIsChildOfRelative(val)
  }

  toggleAdoptedOption = (val) => {
    const style = {
      backgroundColor:
        this.colorSettings?.selectable_btn_active_2 ?? 'rgba(74,74,74,1)',
      borderColor:
        this.colorSettings?.selectable_btn_border_active_2 ?? 'white',
      height: 40,
    }
    if (!val) {
      style.backgroundColor = this.colorSettings?.selectable_btn_inactive_2
      style.borderColor =
        this.colorSettings?.selectable_btn_border_inactive_2 ??
        'rgba(74,74,74,1)'
    }
    return style
  }

  toggleAdoptedOptionText = (val) => {
    const style = {
      color: this.colorSettings?.selectable_btn_text_active_2 ?? 'white',
      fontSize: 12,
    }
    if (!val) {
      style.color =
        this.colorSettings?.selectable_btn_text_inactive_2 ?? 'rgba(74,74,74,1)'
    }
    return style
  }

  shouldRenderIsAdopted(val = false) {
    if (!val) return null

    const { relationshipData } = this.state
    const { isAdoptedOut, isChildOfRelative } = relationshipData?.childSpecific

    return (
      <View>
        <View style={{ flexDirection: 'column', marginHorizontal: 16 }}>
          <View style={{ flex: 1.0, flexDirection: 'row' }}>
            <BorderedOptionButton
              onPress={() => this.toggleIsAdoptedOut(false)}
              labelText={i18n.t('into_our_family')?.default}
              buttonStyle={[
                custom_styles.groupContentItemValueSwitchBorderedButtonContainer,
                this.toggleAdoptedOption(!isAdoptedOut),
                { flex: 0.5 },
              ]}
              labelStyle={[
                custom_styles.groupContentItemValueSwitchButtonText,
                this.toggleAdoptedOptionText(!isAdoptedOut),
              ]}
            />

            <BorderedOptionButton
              onPress={() => this.toggleIsAdoptedOut(true)}
              labelText={i18n.t('out_to_another_family')?.default}
              buttonStyle={[
                custom_styles.groupContentItemValueSwitchBorderedButtonContainer,
                this.toggleAdoptedOption(isAdoptedOut),
                { flex: 0.5 },
              ]}
              labelStyle={[
                custom_styles.groupContentItemValueSwitchButtonText,
                this.toggleAdoptedOptionText(isAdoptedOut),
              ]}
            />
          </View>
        </View>

        {this.shouldRenderIsChildOfRelative(!isAdoptedOut, isChildOfRelative)}
      </View>
    )
  }

  shouldRenderIsChildOfRelative = (
    isAdoptedOut = false,
    isChildOfRelative = false
  ) => {
    const ChildOfRelativeSwitch = ({ show = false }) => {
      if (!show) return <View style={{ height: 16 }} />
      return (
        <View style={custom_styles.groupContentItem}>
          <View
            style={[
              custom_styles.groupContentItemLabelTextContainer,
              { flex: 0.7 },
            ]}
          >
            <Text style={custom_styles.groupContentItemLabelText}>
              {
                i18n.t('is_this_person_the_child_of_someone_else_in_the_family')
                  ?.default
              }
            </Text>
          </View>
          <View
            style={[
              custom_styles.groupContentItemTextInputContainer,
              { flex: 0.3 },
            ]}
          >
            <DarkGraySwitch
              value={isChildOfRelative}
              onValueChange={this.toggleIsChildOfRelative}
            />
          </View>
        </View>
      )
    }

    return (
      <View>
        <ChildOfRelativeSwitch show={isAdoptedOut} />
        <DefaultIsAdoptedText
          show={isChildOfRelative}
          message={
            i18n.t(
              'to_get_the_most_accurate_risk_estimate_you_should_enter_this_person_under_their_biological_parents_instead'
            )?.default
          }
        />
      </View>
    )
  }

  render() {
    const { relationshipData } = this.state
    const isAdopted = relationshipData?.isAdopted

    return (
      <View style={custom_styles.groupContentItemContainer}>
        <View style={custom_styles.groupContentItem}>
          <View
            style={[
              custom_styles.groupContentItemLabelTextContainer,
              { flex: 0.7 },
            ]}
          >
            <Text style={custom_styles.groupContentItemLabelText}>
              {i18n.t('is_this_person_adopted')?.default}
            </Text>
          </View>
          <View
            style={[
              custom_styles.groupContentItemTextInputContainer,
              { flex: 0.3 },
            ]}
          >
            <DarkGraySwitch
              value={isAdopted}
              onValueChange={(val) => this.toggleIsAdopted(val)}
            />
          </View>
        </View>

        {this.shouldRenderIsAdopted(isAdopted)}
      </View>
    )
  }
}

const DefaultIsAdoptedText = ({ show = false, message = '' }) => {
  if (!show) return null
  return (
    <View
      style={{
        flexDirection: 'column',
        marginHorizontal: 16,
        marginBottom: 16,
      }}
    >
      <Text
        style={{
          fontFamily: 'montserrat-medium',
          fontSize: 13,
          color: 'rgb(102,102,102)',
          textAlign: 'left',
        }}
      >
        {message}
      </Text>
    </View>
  )
}

const BorderedOptionButton = ({
  onPress,
  buttonStyle = null,
  labelText = '',
  labelStyle = null,
}) => (
  <TouchableOpacity activeOpacity={1.0} onPress={onPress} style={buttonStyle}>
    <Text style={labelStyle}>{labelText}</Text>
  </TouchableOpacity>
)

export default AdoptedSection
