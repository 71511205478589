export const decreaseFontSize = (name) => {
  const nameLength = name ? parseInt(name.length) : 0
  let fontSize = ''

  switch (true) {
    case nameLength < 45:
      fontSize = 18
      break
    case nameLength < 53:
      fontSize = 14
      break
    default:
      fontSize = 12
  }

  return fontSize
}
