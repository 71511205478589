import { useNavigation, useRoute } from '@react-navigation/core'
import { SafeAreaView, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect, useState } from 'react'
import { i18n } from '$localization/config.js'
import MainWrapper from '$components/main_wrapper/MainWrapper'
import styles from './styles'
import NextButtonAbsolute from '$components/NextButtonAbsolute/NextButtonAbsolute'
import AddFamilyMemberOptions from './components/AddFamilyMemberOptions'
import { handleFilterSelectedFamilyMembers } from './helpers/helpers'
import { showGenericError } from '$navigation/_utils/NavigationUtils'
import { setShouldReloadFamilyList } from '$redux/proband/actions'
import {
  setAddNewMemberPayload,
  setDidAddNewMember,
} from '$redux/member_profile/actions'
import ShortWorkflowHeaderText from './components/ShortWorkflowHeaderText'

import ROUTES, { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'

import {
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'
import ApiFetchHandler from '$api/ApiFetchHandler'

const WEB_SCALED_MAIN_CONTAINER = 'web_scaled_main_container'

function AddFamilyMember() {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const route = useRoute()

  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([])
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [hasMemberParents, setHasMemberParents] = useState(false)

  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const currentProband = useSelector((state) => state.probandStore.proband)
  const currentMember = useSelector(
    (state) => state.memberProfileStore.currentMember
  )
  const isCustomWorkflow = useSelector(
    (state) => state.customFlowRoutesStore.isCustomWorkflow
  )

  const saveShouldReloadFamilyList = (data) =>
    dispatch(setShouldReloadFamilyList(data))
  const saveDidAddNewMember = (data) => dispatch(setDidAddNewMember(data))
  const saveAddNewMemberPayload = (data) =>
    dispatch(setAddNewMemberPayload(data))

  const buttonText = isCustomWorkflow
    ? i18n.t('next')?.default
    : i18n.t('add_relative')?.default

  const checkIfMemberHasParents = async () => {
    const probandID = currentProband?.probandID
    const currentMemberID =
      route.params?.currentMember?.member_id ?? currentMember?.member_id

    const isFromFamilyScreen = route.params?.isFromFamilyScreen
    const memberID =
      isCustomWorkflow && !isFromFamilyScreen ? probandID : currentMemberID

    try {
      const getMemberProfilePayload = {
        path: 'member/get/',
        method: 'post',
        token: authCredentials.accessToken,
        body: {
          proband_id: probandID,
          member_id: memberID,
          lang: selectedLanguage,
        },
        pageDetails: {
          page: 'SurveyCancer.js',
        },
      }

      const response = await ApiFetchHandler(getMemberProfilePayload)

      if (!response) return

      const parentsData = response.parents

      const hasParents =
        Boolean(parentsData?.father?.member_id) &&
        Boolean(parentsData?.mother?.member_id)

      setHasMemberParents(hasParents)
    } catch (error) {
      console.log(error)
    }
  }

  const requestAddRelative = ({ familyMember, familySide = null }) => {
    const probandID = currentProband?.probandID
    const currentMemberID =
      route.params?.currentMember?.member_id ?? currentMember?.member_id

    const isFromFamilyScreen = route.params?.isFromFamilyScreen
    const memberID =
      isCustomWorkflow && !isFromFamilyScreen ? probandID : currentMemberID

    const relationshipType = familyMember?.selectedItemKeyRef

    const gender = familyMember?.selectedItemGender

    if (!memberID) showGenericError()

    let memberCount

    switch (familySide) {
      case 'paternal':
        memberCount = familyMember?.dadSideCount
        break
      case 'maternal':
        memberCount = familyMember?.momSideCount
        break
      default:
        memberCount = familyMember?.count
    }

    const payload = {
      proband_id: probandID,
      member_id: memberID,
      gender,
      pregnancy: !gender,
      side: familySide,
      relationship_to_proband: relationshipType,
    }

    const selectedMembers = Array.from({ length: memberCount }, () => payload)

    return selectedMembers
  }

  const selectPiblingFamilySide = ({ familyMember }) => {
    const familySides = [
      { side: 'paternal', count: familyMember?.dadSideCount },
      { side: 'maternal', count: familyMember?.momSideCount },
    ]

    return familySides.flatMap(({ side, count }) =>
      count ? requestAddRelative({ familyMember, familySide: side }) : []
    )
  }

  const nextButtonAction = async () => {
    const selectedFamilyMember = handleFilterSelectedFamilyMembers(
      selectedFamilyMembers
    )

    let compileFamily = []

    selectedFamilyMember.forEach((member) => {
      const isAuntOrUncle =
        member?.selectedItemKeyRef === 'aunt' ||
        member?.selectedItemKeyRef === 'uncle'

      if (isAuntOrUncle) {
        compileFamily = [
          ...compileFamily,
          ...selectPiblingFamilySide({
            familyMember: member,
          }),
        ]
        return
      }

      compileFamily = [
        ...compileFamily,
        ...requestAddRelative({
          familyMember: member,
        }),
      ]
    })

    saveDidAddNewMember(true)
    saveAddNewMemberPayload(compileFamily)
    saveShouldReloadFamilyList(true)

    const fromFamilyPedigree = route?.params?.fromFamilyPedigreeScreen

    if (isCustomWorkflow) {
      //* Not using 'getNextWorkflowRoute' because the next route is always the same
      handleNavigateToNextScreen({
        navigation,
        routeName: ROUTES.FamilyMainViewCancer.name,
        selectedScreen: '',
        params: { nextScreenText: route?.params?.nextScreenText },
      })
    } else if (fromFamilyPedigree) {
      navigation.pop()
    } else {
      navigation.replace('FamilyMainViewTab', {
        addFamilyPedigree: true,
      })
      navigation.pop()
    }
  }

  const CURRENT_SCREEN_NAME =
    CUSTOM_FLOW_ROUTES?.AddFamilyMemberViewCancer?.name

  const customScreenText = route?.params?.nextScreenText
    ? route?.params?.nextScreenText
    : handleGetCustomScreenText(CURRENT_SCREEN_NAME)

  useLayoutEffect(() => {
    checkIfMemberHasParents()
  }, [])

  return (
    <MainWrapper navigation={navigation}>
      <SafeAreaView style={styles.container(colorSettings)}>
        <ScrollView
          contentContainerStyle={styles.mainContentContainer}
          nativeID={WEB_SCALED_MAIN_CONTAINER}
        >
          {isCustomWorkflow && (
            <ShortWorkflowHeaderText customScreenText={customScreenText} />
          )}

          <AddFamilyMemberOptions
            setSelectedFamilyMembers={setSelectedFamilyMembers}
            selectedFamilyMembers={selectedFamilyMembers}
            setAllowSubmit={setAllowSubmit}
            hasMemberParents={hasMemberParents}
          />
        </ScrollView>
        <NextButtonAbsolute
          buttonText={buttonText}
          onPressAction={nextButtonAction}
          isDisabled={isCustomWorkflow ? false : !allowSubmit}
        />
      </SafeAreaView>
    </MainWrapper>
  )
}

export default AddFamilyMember
