import { Component } from 'react'
import { View, Text, TouchableOpacity, Dimensions } from 'react-native'

import { MULTI_LANGUAGE, APP_BUILD_NUMBER } from '$constants/envVariables.js'
import { capitalizeFirstLetter } from '$api/_utils/Utils.js'
import { VERSION_MODE } from '$constants/globalVariables'
import { i18n, translations, currentLanguage } from '$localization/config.js'
import { IS_WEB } from '$constants/Platforms.js'
import GlobalStyles from '$constants/styles/global.styles.js'

import styles from './AppInfo.styles'

const packageJsonFile = require('../../../package.json')

const windowDimensions = Dimensions.get('window')
const SMALL_DEVICE_BREAKPOINT = 500

class AppInfoView extends Component {
  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      selectedLanguage: newProps.selectedLanguage,
    }
  }

  constructor(props) {
    super(props)

    const {
      colorSettings,
      onChangeLanguage,
      openLanguageSettings,
      selectedLanguage,
    } = this.props

    this.dimensionsSubscription = null

    this.colorSettings = colorSettings
    this.mode = null
    this.onChangeLanguage = onChangeLanguage
    this.openLanguageSettings = openLanguageSettings
    this.buildNumber = APP_BUILD_NUMBER || Date.now().toString()
    this.versionNumber = packageJsonFile?.version ?? Date.now()

    this.state = {
      selectedLanguage,
      windowDimensions,
    }

    if (VERSION_MODE?.toLowerCase() !== 'live') {
      this.mode = `(${capitalizeFirstLetter(VERSION_MODE)})`
    } else {
      this.mode = ''
    }
  }

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.onChangeDimensions
    )
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove()
  }

  onChangeDimensions = ({ window }) => {
    this.setState({ windowDimensions: window })
  }

  handleLanguage = (selectedLanguage) => {
    const supportedLanguages = Object.keys(translations)
    const defaultLanguage =
      supportedLanguages.find((language) => language === selectedLanguage) ||
      'en'

    return defaultLanguage
  }

  render() {
    const { selectedLanguage } = this.state

    let languageControlView = null
    let versionBuildView = null

    const versionBuildText = `v: ${this.versionNumber} ${this.mode} | ${
      i18n.t('build')?.capitalize
    }: ${this.buildNumber}`

    const language = selectedLanguage || currentLanguage

    const currentSelectedLanguage = IS_WEB()
      ? this.handleLanguage(language)
      : language

    if (MULTI_LANGUAGE === 'true') {
      languageControlView = (
        <View style={styles.appInfoLeftContent}>
          <Text style={styles.languageTitle}>
            {`${i18n.t('language')?.capitalize}:`}
          </Text>
          <TouchableOpacity
            onPress={this.openLanguageSettings}
            style={styles.appLanguage}
          >
            <Text style={styles.appInfoLanguage}>
              {i18n.t(currentSelectedLanguage)?.capitalize}
            </Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      languageControlView = <View style={styles.appInfoLeftContent} />
    }

    versionBuildView = (
      <View style={styles.appInfoRightContent}>
        <Text
          style={[
            styles.appInfoVersion,
            {
              color: this.colorSettings?.nav_title_dark,
            },
          ]}
        >
          {versionBuildText}
        </Text>
      </View>
    )

    return (
      <View
        style={[
          styles.container,
          {
            marginTop:
              windowDimensions?.width <= SMALL_DEVICE_BREAKPOINT ? 60 : 0,
            paddingHorizontal:
              windowDimensions?.width <= SMALL_DEVICE_BREAKPOINT ? 24 : 32,
          },
        ]}
      >
        <View style={GlobalStyles.bottomHorizontalContentFull}>
          {languageControlView}
          {versionBuildView}
        </View>
      </View>
    )
  }
}

// AppInfoView.propTypes = {
//   onChangeLanguage: PropTypes.string.isRequired,
//   openLanguageSettings: PropTypes.func.isRequired,
//   selectedLanguage: PropTypes.string.isRequired,
//   colorSettings: PropTypes.shape({
//     nav_title_dark: PropTypes.string.isRequired,
//   }).isRequired,
// }

export default AppInfoView
