/* eslint-disable global-require */
import { Text, TextInput } from 'react-native'
import { FontDisplay } from 'expo-font'
import { IS_WEB } from '$constants/Platforms.js'
import { i18n, locale } from '$localization/config.js'

import { setLanguage } from '$redux/defaults/actions.js'

export const setDefaultProps = () => {
  Text.defaultProps = Text.defaultProps ?? {}
  Text.defaultProps.allowFontScaling = false

  TextInput.defaultProps = TextInput.defaultProps ?? {}
  TextInput.defaultProps.allowFontScaling = false

  if (!IS_WEB()) return

  // eslint-disable-next-line global-require
  const PlatformSpecificStyling = require('$utils/PlatformSpecificStyling.web.js')
  new PlatformSpecificStyling().inject()
}

export const fontsToLoad = {
  montserrat: {
    uri: require('$assets/fonts/Montserrat-Regular.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-black': {
    uri: require('$assets/fonts/Montserrat-Black.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-black-italic': {
    uri: require('$assets/fonts/Montserrat-BlackItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-bold': {
    uri: require('$assets/fonts/Montserrat-Bold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-bold-italic': {
    uri: require('$assets/fonts/Montserrat-BlackItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-bold': {
    uri: require('$assets/fonts/Montserrat-ExtraBold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-bold-italic': {
    uri: require('$assets/fonts/Montserrat-ExtraBoldItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-light': {
    uri: require('$assets/fonts/Montserrat-ExtraLight.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-light-italic': {
    uri: require('$assets/fonts/Montserrat-ExtraLightItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-italic': {
    uri: require('$assets/fonts/Montserrat-Italic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-light': {
    uri: require('$assets/fonts/Montserrat-Light.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-light-italic': {
    uri: require('$assets/fonts/Montserrat-LightItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-medium': {
    uri: require('$assets/fonts/Montserrat-Medium.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-medium-italic': {
    uri: require('$assets/fonts/Montserrat-MediumItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-semibold': {
    uri: require('$assets/fonts/Montserrat-SemiBold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-semibold-italic': {
    uri: require('$assets/fonts/Montserrat-SemiBoldItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-thin': {
    uri: require('$assets/fonts/Montserrat-Thin.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-thin-italic': {
    uri: require('$assets/fonts/Montserrat-ThinItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  karla: {
    uri: require('$assets/fonts/Karla-Regular.ttf'),
    display: FontDisplay.BLOCK,
  },
  'karla-bold': {
    uri: require('$assets/fonts/Karla-Bold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'karla-italic': {
    uri: require('$assets/fonts/Karla-Italic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'karla-bold-italic': {
    uri: require('$assets/fonts/Karla-BoldItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
}

export const setInitialLanguage = (dispatch) => {
  try {
    const currentLanguage = `${locale}`.split('-')[0]
    const currentRegion = `${locale}`.split('-')[1]

    i18n.locale = `${currentLanguage}-${currentRegion}`

    dispatch(setLanguage(currentLanguage))
  } catch (e) {
    const currentLanguage = 'en'
    const currentRegion = 'US'

    i18n.locale = `${currentLanguage}-${currentRegion}`

    dispatch(setLanguage(currentLanguage))
  }
}
