import { Component } from 'react'
import {
  View,
  Image,
  ImageBackground,
  Modal,
  Text,
  TouchableOpacity,
  Platform,
  ScrollView,
  Dimensions,
} from 'react-native'
import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import styles from '$screens/relatives/_styles/tutorial.modal.styles.js'
import { webModalSizing } from '$screens/_utils/webModalSizing'
import { webModalBackgroundImageSizing } from '$screens/_utils/webModalBackgroundImageSizing'

const windowDimensions = Dimensions.get('window')

class TutorialModal extends Component {
  constructor(props) {
    super(props)
    const { onDismiss, visible } = this.props
    this.onDismiss = onDismiss
    this.state = {
      visible,
      dimensions: windowDimensions,
    }
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      visible: newProps.visible,
    }
  }

  onChangeDimensions = () => {
    this.setState({ dimensions: Dimensions.get('window') })
  }

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.onChangeDimensions
    )
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove()
  }

  dismissButtonAction = () => {
    this.onDismiss()
  }

  render() {
    const { colorSettings } = this.props
    const { visible, dimensions } = this.state

    const { width, height } = dimensions
    const currentWidth = width
    const webModalHeight = currentWidth < 450 ? height / 1.2 : height / 1.4
    const mobileModalHeight = height / 1.3

    return (
      <Modal animationType={'fade'} visible={visible} transparent={true}>
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <View
              style={[
                styles.content,
                {
                  width: webModalSizing(currentWidth),
                  height:
                    Platform.OS === 'web'
                      ? webModalHeight + 50
                      : mobileModalHeight,
                  borderRadius: 10,
                  overflow: 'hidden',
                },
              ]}
            >
              <View style={styles.maxWidthAndHeight}>
                <ImageBackground
                  source={require('$assets/images/new_assets/modal-bg-siblings.png')}
                  style={[
                    styles.contentHeader,
                    { height: webModalBackgroundImageSizing(currentWidth) },
                  ]}
                >
                  <View style={styles.dismissButtonView}>
                    <TouchableOpacity
                      onPress={() => this.dismissButtonAction()}
                    >
                      <Image
                        source={require('$assets/images/new_assets/icon-close-modal-w.png')}
                        style={{ width: 24, height: 24 }}
                      />
                    </TouchableOpacity>
                  </View>
                </ImageBackground>

                <ScrollView
                  contentContainerStyle={styles.siblingsScrollViewContainer}
                >
                  <Text style={[styles.messageHeadText, { fontSize: 21 }]}>
                    {i18n.t('now_lets_add_your_brothers_and_sisters')?.default}
                  </Text>

                  <Text style={styles.messageBodyText}>
                    {
                      i18n.t(
                        'you_can_mark_a_sibling_as_adopted_comma_twin_comma_or_half-sibling_by_selecting_them_to_edit_their_profile_dot'
                      )?.default
                    }
                  </Text>

                  <Text style={styles.messageBodyText}>
                    {
                      i18n.t(
                        'dont_forget_to_edit_details_for_anyone_who_has_had_a_disease_or_genetic_testing'
                      )?.default
                    }
                  </Text>

                  <TouchableOpacity
                    onPress={() => this.dismissButtonAction()}
                    style={[
                      styles.nextButton,
                      {
                        backgroundColor: colorSettings?.btn_filled_enabled_1,
                        borderColor: colorSettings?.btn_filled_border_1,
                        marginTop: 8,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.nextButtonText,
                        {
                          color: colorSettings?.btn_filled_text_1,
                          width: 200,
                        },
                      ]}
                    >
                      {i18n.t('continue')?.default}
                    </Text>
                  </TouchableOpacity>
                </ScrollView>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

// TutorialModal.propTypes = {
//   colorSettings: PropTypes.shape({
//     btn_filled_enabled_1: PropTypes.string,
//     btn_filled_border_1: PropTypes.string,
//     btn_filled_text_1: PropTypes.string,
//   }),
//   onDismiss: PropTypes.func,
//   visible: PropTypes.bool,
// }

TutorialModal.defaultProps = {
  colorSettings: {
    btn_filled_enabled_1: '',
    btn_filled_border_1: '',
    btn_filled_text_1: '',
  },
  onDismiss: () => {},
  visible: false,
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(TutorialModal)
