import { Component } from 'react'
import {
  View,
  Image,
  ImageBackground,
  Modal,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import styles from '$screens/relatives/_styles/tutorial.childModal.styles.js'
import { webModalSizing } from '$screens/_utils/webModalSizing'
import { webModalBackgroundImageSizing } from '$screens/_utils/webModalBackgroundImageSizing'

const windowDimensions = Dimensions.get('window')

class TutorialModal extends Component {
  onDismiss = this.props.onDismiss ?? function () {}
  primaryAction = this.props.primaryAction ?? function () {}
  skipAction = this.props.skipAction ?? function () {}

  state = {
    visible: this.props.visible,
    dimensions: windowDimensions,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      visible: newProps.visible,
    }
  }

  onChangeDimensions = () => {
    this.setState({ dimensions: Dimensions.get('window') })
  }

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.onChangeDimensions
    )
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove()
  }

  dismissButtonAction = () => {
    this.onDismiss()
  }

  primaryButtonAction = () => {
    this.primaryAction()
  }

  skipButtonAction = () => {
    this.skipAction()
  }

  render() {
    const colorSettings = this.props.colorSettings
    const { visible, dimensions } = this.state
    const { width, height } = dimensions
    const currentWidth = width
    const webModalHeight = currentWidth < 450 ? height / 1.3 : height / 1.4
    const mobileModalHeight = height / 1.4

    return (
      <Modal animationType="fade" visible={visible} transparent>
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <View
              style={[
                styles.content,
                {
                  width: webModalSizing(currentWidth),
                  height:
                    Platform.OS === 'web' ? webModalHeight : mobileModalHeight,
                },
              ]}
            >
              <View style={styles.flexContainer}>
                <View style={styles.imageContainer}>
                  <ImageBackground
                    source={require('$assets/images/new_assets/modal-bg-children.png')}
                    resizeMode={'stretch'}
                    style={{
                      width: '100%',
                      height: webModalBackgroundImageSizing(currentWidth),
                    }}
                    imageStyle={{
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <View style={styles.dismissButtonView}>
                      <TouchableOpacity
                        onPress={() => this.dismissButtonAction()}
                      >
                        <Image
                          source={require('$assets/images/new_assets/icon-close-modal-w.png')}
                          style={{ width: 24, height: 24 }}
                        ></Image>
                      </TouchableOpacity>
                    </View>
                  </ImageBackground>
                </View>
                <View style={styles.textContainer}>
                  <Text
                    style={{
                      color: 'rgb(65,70,97)',
                      fontFamily: 'karla-bold',
                      fontSize: 21,
                      lineHeight: 24,
                      textAlign: 'center',
                      marginRight: 5,
                      marginLeft: 5,
                    }}
                  >
                    {i18n.t('if_you_have_children_lets_add_them_now')?.default}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'montserrat-semibold',
                      fontSize: 14,
                      lineHeight: 24,
                      color: 'rgb(65,70,97)',
                      textAlign: 'center',
                    }}
                  >
                    {i18n.t('are_any_of_your_children_col')?.default}
                  </Text>
                  <Text
                    style={{
                      color: 'rgb(102,102,102)',
                      fontFamily: 'montserrat',
                      fontSize: 14,
                      lineHeight: 24,
                      textAlign: 'center',
                    }}
                  >
                    {
                      i18n.t('from_more_than_one_spouse_slash_partner_qm')
                        ?.default
                    }
                    {'\n'}
                    {i18n.t('twins_qm')?.default}
                    {'\n'}
                    {i18n.t('adopted_qm')?.default}
                  </Text>
                </View>
                <View style={styles.buttonContainer}>
                  <View style={styles.topButtonContainer}>
                    <View style={styles.leftButton}>
                      <TouchableOpacity
                        onPress={() => this.dismissButtonAction()}
                      >
                        <Text style={styles.footerButtonText}>
                          {i18n.t('no')?.title}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.rightButton}>
                      <TouchableOpacity
                        onPress={() => this.primaryButtonAction()}
                      >
                        <Text style={styles.footerButtonText}>
                          {i18n.t('yes')?.title}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={styles.bottomButtonContainer}>
                    <TouchableOpacity onPress={() => this.skipButtonAction()}>
                      <Text style={styles.footerButtonText}>
                        {i18n.t('i_dont_have_children')?.default}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(TutorialModal)
