import { Component } from 'react'
import { View, Text } from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import DarkGraySwitch from '$components/custom-switches/DarkGraySwitch'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { setError } from '$redux/defaults/actions.js'

import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

import modifySiblingsListHandler from '../twinsUtils'

import TwinSectionCollapseView from './TwinsSectionCollapseView'

import LoadingView from '$constants/LoadingView.js'

class TwinsSection extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  currentMember = this.props.currentMember

  relationshipData = this.props.relationshipData

  proband = this.props.currentProband

  authCredentials = this.props.authCredentials

  state = {
    currentMember: this.currentMember,
    relationshipData: this.relationshipData,
    isTwin: this.relationshipData?.isTwin,
    authCredentials: this.authCredentials,
    isLoading: false,
    siblingsList: [],
    isOnboarding: false,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { currentMember, relationshipData } = newProps
    const isTwin = relationshipData?.isTwin

    return {
      ...prevState,
      currentMember,
      relationshipData,
      isTwin,
    }
  }

  async componentDidMount() {
    const { currentMember, relationshipData } = this.state
    const { authCredentials, accountStore } = this.props

    const twinGroup = relationshipData?.twinGroup
    const twinMembers = twinGroup?.twinMembers ?? []

    try {
      await this.getOnboardingStatus()
      await this.fetchSiblingsList(currentMember, authCredentials)
    } catch (err) {
      console.log(
        '🚀 \n\n file: Twins.section.js:71 \n\n TwinsSection \n\n componentDidMount \n\n err:',
        err
      )
    }

    this.setState({ isTwin: twinMembers?.length > 0 })
  }

  getOnboardingStatus = async () => {
    const { accountStore, authCredentials } = this.props

    const accountID = accountStore?.account?.accountID

    this.setState({
      isLoading: true,
    })

    const payload = {
      path: `onboarding/${accountID}/get_info/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'Twin.section.js',
      },
    }

    const response = await ApiFetchHandler(payload)

    const { saveError } = this.props

    if (response.isError) {
      this.setState({
        isLoading: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    if (response.last_screen) {
      this.setState({ isOnboarding: true, isLoading: false })
    } else {
      this.setState({ isOnboarding: false, isLoading: false })
    }
  }

  fetchSiblingsList = async (member, headers) => {
    const getSiblingListForTwinsPayload = {
      path: 'member/get_sibling_list_for_twins/',
      method: 'post',
      token: headers.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: this.currentMember?.member_id,
      },
      pageDetails: {
        page: 'Twin.section.js',
      },
    }

    const response = await ApiFetchHandler(getSiblingListForTwinsPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({
        isLoading: false,
      })

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const siblingsList = response?.siblings ?? []

    const modifiedSiblingList = this.modifySiblingsList(siblingsList)

    this.setState({
      siblingsList: modifiedSiblingList,
      isLoading: false,
    })
  }

  modifySiblingsList = (siblingsList = []) => {
    const { basicFamilyMembers = [] } = this.props
    const { isOnboarding } = this.state

    const modifiedSiblings = modifySiblingsListHandler(
      siblingsList,
      this.currentMember,
      basicFamilyMembers,
      isOnboarding
    )

    return modifiedSiblings
  }

  toggleIsTwin = (val) => {
    const twinGroup = this.state.relationshipData?.twinGroup
    const twinMembers = twinGroup?.twinMembers
    const twinGroupID = twinGroup?.twinGroupID

    if (!val && twinMembers?.length > 0) {
      this.setState({ isTwin: false })
      twinMembers.forEach((el) => {
        this.parentController?.removeTwinGroup(twinGroupID, el?.id)
      })
    }
    this.parentController?.toggleIsTwin(val)
  }

  addTwin = () => {
    this.parentController?.navigateToAddTwin()
  }

  removeTwinGroup = (memberID) => {
    const { relationshipData } = this.state

    const twinGroupID = relationshipData?.twinGroup?.twinGroupID
    this.parentController?.removeTwinGroup(twinGroupID, memberID)
  }

  render() {
    const { relationshipData, isTwin, siblingsList, isLoading } = this.state

    const { colorSettings } = this.props

    return (
      <View style={custom_styles.groupContentItemContainer}>
        <View
          style={[custom_styles.groupContentItem, { borderBottomWidth: 0 }]}
        >
          <View
            style={[
              custom_styles.groupContentItemLabelTextContainer,
              { flex: 0.7 },
            ]}
          >
            <Text style={custom_styles.groupContentItemLabelText}>
              {i18n.t('is_this_person_a_twin')?.default}
            </Text>
          </View>

          <View
            style={[
              custom_styles.groupContentItemTextInputContainer,
              { flex: 0.3 },
            ]}
          >
            <DarkGraySwitch
              value={isTwin}
              onValueChange={(val) => this.toggleIsTwin(val)}
            />
          </View>
        </View>

        <LoadingView
          backgroundColor={colorSettings?.splash_bgcolor}
          tintColor={colorSettings?.btn_no_fill_border_1}
          textColor={colorSettings?.btn_no_fill_text_1}
          visible={isLoading}
        />

        {isTwin && (
          <TwinSectionCollapseView
            siblingsList={siblingsList}
            relationshipData={relationshipData}
            onPressAdd={() => this.addTwin()}
            onPressRemove={this.removeTwinGroup}
            colorSettings={colorSettings}
          />
        )}
      </View>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state

  return {
    colorSettings: store.colorSettings,
    authCredentials: accountStore.authCredentials,
    accountStore,
    currentProband: probandStore.proband,
    currentMember: memberProfileStore.currentMember,
    basicFamilyMembers: probandStore.basicFamilyMembers,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwinsSection)
