import { Text, View, TouchableOpacity, Platform } from 'react-native'

import global_styles from '$constants/styles/global.styles.js'
import { StackActions } from '@react-navigation/native'

import { HeaderBackButton } from 'react-navigation-stack'

import HeaderBackImage from '$navigation/_components/HeaderBackImage.js'

import { IS_WEB } from '$constants/Platforms.js'

import { capitalizeFirstLetter } from '$api/_utils/Utils.js'

import { i18n } from '$localization/config.js'

const popAction = StackActions.pop(1)

const DefaultNavigationOptions = ({
  navigation,
  headerTitle,
  headerSubtitle = null,
  headerTintColor = 'black',
  headerBackgroundColor = 'white',
  hasCancelButton = false,
  hasCloseButton = false,
  closeButtonType = 'cancel',
  hasLeftPlaceholderButton = false,
  hasRightPlaceholderButton = false,
  hasBackButtonTitle = false,
  customAction = function () {},
  hasHeaderRight = false,
}) => {
  const options = {
    headerBackTitle: null,
    headerTintColor,
    headerStyle: {
      backgroundColor: headerBackgroundColor,
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
      shadowOpacity: 0,
      boxShadow: '',
    },
    headerTitleAlign: 'center',
  }

  /* Adjust Header Title font size by length. */
  let headerTitleFontSize = 18

  if (headerTitle) {
    const n = parseInt(headerTitle.length)
    switch (true) {
      case n > 20:
        headerTitleFontSize = 14
        break
      case n > 40:
        headerTitleFontSize = 12
        break
    }
  }

  if (hasHeaderRight) {
    options.headerRight = () => <View style={global_styles.headerButton} />
  }

  if (hasCancelButton || hasCloseButton) {
    switch (closeButtonType) {
      case 'close':
      case 'cancel':
        options.headerLeft = () => (
          <TouchableOpacity
            style={global_styles.headerButton}
            onPress={() => navigation.getState().dispatch(popAction)}
          >
            <Text style={global_styles.headerLeftButtonText}>
              {capitalizeFirstLetter(closeButtonType)}
            </Text>
          </TouchableOpacity>
        )
        break
      case 'back':
        options.headerLeft = () => (
          <TouchableOpacity
            style={global_styles.backButton}
            onPress={() => navigation.dispatch(popAction)}
          >
            <HeaderBackImage color={headerTintColor} />
          </TouchableOpacity>
        )
        break
      case 'custom':
        options.headerLeft = () => (
          <TouchableOpacity
            style={global_styles.backButton}
            onPress={() => customAction()}
          >
            {hasBackButtonTitle ? (
              <HeaderBackButton
                onPress={customAction}
                tintColor="white"
                label={i18n.t('back')?.default}
              />
            ) : (
              <HeaderBackImage color={headerTintColor} />
            )}
          </TouchableOpacity>
        )
        break
      default:
        options.headerLeft = () => (
          <TouchableOpacity
            style={global_styles.backButton}
            onPress={() => navigation.dispatch(popAction)}
          >
            <HeaderBackImage color={headerTintColor} />
          </TouchableOpacity>
        )
        break
    }
  }

  /* Placeholder buttons for Android and Web (automatically handled on iOS) */
  if (hasLeftPlaceholderButton && closeButtonType !== 'custom') {
    options.headerLeft = () => (
      <View style={global_styles.headerPlaceholderButton} />
    )
  }

  if (hasRightPlaceholderButton) {
    options.headerRight = () => (
      <View style={global_styles.headerPlaceholderButton} />
    )
  }

  /* Header Title and Subtitle */
  if (headerSubtitle) {
    options.headerTitle = () => (
      <View
        style={[
          global_styles.headerBarTitleContainer,
          global_styles.webTopBarWidth,
          {
            ...Platform.select({
              web: {
                backgroundColor: headerBackgroundColor,
              },
            }),
          },
        ]}
      >
        <Text
          style={[
            global_styles.headerBarMainTitle,
            {
              color: headerTintColor,
              fontSize: headerTitleFontSize,
            },
          ]}
        >
          {headerTitle}
        </Text>
        <Text
          style={[global_styles.headerBarSubtitle, { color: headerTintColor }]}
        >
          {headerSubtitle}
        </Text>
      </View>
    )
  } else {
    options.headerTitle = () => (
      <View
        style={[
          global_styles.headerBarTitleContainer,
          global_styles.webTopBarWidth,
          {
            ...Platform.select({
              web: {
                backgroundColor: headerBackgroundColor,
              },
            }),
          },
        ]}
      >
        <Text
          style={[
            global_styles.headerBarMainTitle,
            {
              color: headerTintColor,
              fontSize: headerTitleFontSize,
            },
          ]}
        >
          {headerTitle}
        </Text>
      </View>
    )
  }

  if (IS_WEB()) {
    options.animationEnabled = false
    options.headerLeftContainerStyle = {
      width: '28%',
      alignItems: 'flex-end',
    }

    options.headerRightContainerStyle = {
      width: '28%',
      alignItems: 'flex-start',
    }
  }

  if (Platform.OS === 'ios') {
    options.headerBackTitle = i18n.t('back')?.default
  }

  return options
}

export default DefaultNavigationOptions
