import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: 18,
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  button: {
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 30,
    minHeight: 60,
    marginBottom: 10,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 35,
    paddingVertical: 10,
  },
  image: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginVertical: 20,
  },
  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
    marginVertical: 20,
  },
})
