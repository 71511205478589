import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import resetSavedNavigationStack from '../../navigation/_utils/navigationHandlers/resetSavedNavigationStack'

function NavigationStateManagement({ children, navigationRef }) {
  const navigation = navigationRef?.current
  const { lastScreenLogin, navigationState } = useSelector(
    (state) => state.store
  )

  if (lastScreenLogin) {
    setTimeout(() => {
      resetSavedNavigationStack(navigation, navigationState)
    }, 1500)
  }

  return <>{children}</>
}

export default NavigationStateManagement
