import { TouchableOpacity, View } from 'react-native'

import { useSelector } from 'react-redux'

import { Switch } from 'react-native-switch'

export default function DarkGraySwitch({ value, onValueChange }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <TouchableOpacity
        style={{
          paddingHorizontal: 2,
          height: '100%',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
        activeOpacity={1.0}
      >
        <Switch
          backgroundActive={colorSettings?.switch_bg_active || 'green'}
          backgroundInactive={colorSettings?.switch_bg_inactive || 'gray'}
          circleActiveColor={colorSettings?.switch_dot_active || 'white'}
          circleInActiveColor={colorSettings?.switch_dot_inactive || 'white'}
          circleSize={16}
          circleBorderWidth={0}
          barHeight={10}
          renderActiveText={false}
          renderInActiveText={false}
          switchWidthMultiplier={1.5}
          onValueChange={onValueChange}
          value={value}
        />
      </TouchableOpacity>
    </View>
  )
}
