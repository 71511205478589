import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'
import uuid from 'react-native-uuid'
import { ButtonSubView } from '$components/button_sub'
import decreaseFontSize from './helper'

export default function OptionButton(props) {
  const { colorSettings, isDisabled, style, masterQuestionChoices, answer } =
    props

  const setAnswer = () => {
    if (!answer) return
    let getAnswer = {}

    masterQuestionChoices.forEach((element) => {
      if (answer.master_question_choice_id === element.id) {
        getAnswer = element
      }
    })

    return getAnswer
  }

  const [userOption, setUserOption] = useState(setAnswer())

  const styleHandler = (selectedItem) => {
    if (selectedItem?.id === userOption?.id) {
      return {
        backgroundColor:
          colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)',
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  const handleSelectedItem = (item) => {
    setUserOption(item)
  }

  if (masterQuestionChoices.length < 1) return null

  return (
    <View>
      {masterQuestionChoices.map((item) => (
        <ButtonSubView
          key={uuid.v4()}
          colorSettings={colorSettings}
          onPress={() => handleSelectedItem(item)}
          styleHandler={() => styleHandler(item)}
          fontSizeHandler={() => decreaseFontSize(item.choice)}
          title={item.choice || ''}
          isDisabled={isDisabled}
          customStyle={style}
        />
      ))}
    </View>
  )
}

OptionButton.propTypes = {
  colorSettings: PropTypes.shape({
    selectable_btn_active_1: PropTypes.string,
    selectable_btn_border_active_1: PropTypes.string,
    selectable_btn_border_inactive_1: PropTypes.string,
  }),
  data: PropTypes.shape({
    input_choices: PropTypes.array,
  }),
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
}

OptionButton.defaultProps = {
  colorSettings: [],
  data: [],
  style: {},
  isDisabled: false,
}
