import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    backgroundColor: 'rgb(245,245,245)',
    flex: 1.0,
  },
  _onPress: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,.4)',
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  loginButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  riskIconContainer: {
    flex: 2.5,
    alignItems: 'center',
  },
  riskIconInnerContainer: {
    flex: 4,
    alignItems: 'center',
  },
  contentContainer: {
    flex: 2.2,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  contentTitle: {
    textAlign: 'center',
    fontSize: 35,
    fontFamily: 'karla-bold',
    color: '#fff',
    fontWeight: 'bold',
  },
  bodyContent: {
    textAlign: 'right',
    fontSize: 18,
    fontFamily: 'montserrat-bold',
    color: '#fff',
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
  },
  buttonInnerContainer: {
    flex: 20,
    justifyContent: 'center',
  },
  titleContent: {
    flex: 1.3,
    flexDirection: 'row',
    alignItems: 'center',
  },
  editButton: {
    flex: 1,
    paddingLeft: 6,
  },
  infoTitle: {
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'montserrat',
    fontSize: 18,
  },
  infoContent: {
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'montserrat-bold',
    fontSize: 18,
  },
  sectionTitleText: {
    color: 'rgb(65,70,97)',
    fontFamily: 'karla-bold',
    fontSize: 18,
    marginHorizontal: 16,
    marginTop: 12,
  },
  sectionSubTitleText: {
    color: 'rgb(65,70,97)',
    fontFamily: 'karla-bold',
    fontSize: 12,
    marginHorizontal: 16,
  },
  emptyMessageText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    textAlign: 'center',
    paddingHorizontal: 24,
  },
})
