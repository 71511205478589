import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  genderButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  genderImage: {
    backgroundColor: 'transparent',
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  genderContent: {
    marginHorizontal: 50,
    flex: 0.7,
  },
  genderContentContainer: {
    flex: 1.0,
  },
  genderDivider: {
    height: '100%',
    width: 10,
  },
  genderIdentityDivider: {
    height: 30,
    width: '100%',
    marginBottom: 35,
  },
  genderIdentityText: {
    textAlign: 'center',
    fontSize: 18,
  },
  innerContentContainer: {
    flex: 1.0,
    width: '100%',
  },
  innerGenderContent: {
    height: 140,
  },
  innerTitleQuestion: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  keyboardAvoidingView: {
    paddingTop: 40,
  },
  keyboardAvoidingViewContainer: {
    height: '100%',
  },
  outerContentContainer: {
    paddingHorizontal: 16,
    // flex: 0.8,
    // height: '50%'
  },
  outerTitleQuestion: {
    flex: 0.25,
  },
  spacer: {
    flex: 0.1,
  },
  titleIconSpacer: {
    width: 10,
  },
  titleIconTextSpacer: {
    width: 15,
  },
  titleSpacer: {
    height: 10,
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
})

export default styles
