import { useState } from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'
import PropTypes from 'prop-types'

import { i18n } from '../../../../../localization/config.js'
import { ButtonSubView } from '../../../../../components/button_sub/index.js'
import { decreaseFontSize } from '../../button/helper.js'
import convertText from '$screens/_utils/TextConverterForTranslation.js'
import { ConvertTextToBool, ConvertBoolToText } from '../../../helpers/index.js'

export default function ToggleField({
  masterQuestion,
  answer,
  saveCompletedSurveyAnswer,
  colorSettings,
}) {
  const [isToggled, setIsToggled] = useState(
    ConvertTextToBool(answer?.answer || false)
  )

  const {
    question_label: questionLabel,
    id: masterQuestionId,
    translation_key: translationKey,
  } = masterQuestion

  const styleHandler = () => {
    if (isToggled) {
      return {
        backgroundColor:
          colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)',
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  const onToggle = () => {
    setIsToggled((prevItem) => !prevItem)
    const payload = {
      master_question_id: masterQuestionId,
      master_question_choice_id: null,
      answer: ConvertBoolToText(!isToggled),
    }

    saveCompletedSurveyAnswer(payload)
  }

  return (
    <View>
      <ButtonSubView
        key={uuid.v4()}
        colorSettings={colorSettings}
        onPress={onToggle}
        styleHandler={styleHandler}
        fontSizeHandler={() =>
          decreaseFontSize(i18n.t(convertText(questionLabel)).default)
        }
        title={i18n.t(translationKey).label ?? questionLabel}
        isDisabled={false}
      />
    </View>
  )
}

ToggleField.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.objectOf(PropTypes.string),
  answer: PropTypes.number,
  saveCompletedSurveyAnswer: PropTypes.func,
}

ToggleField.defaultProps = {
  colorSettings: [],
  answer: '',
  saveCompletedSurveyAnswer: () => {},
}
