import { Component } from 'react'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import Alert from '$components/Alert'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import global_styles from '$constants/styles/global.styles.js'

const styles = StyleSheet.create({
  buttonContainer: {
    width: '100%',
    marginTop: 20,
  },
})

class DeleteProfileButton extends Component {
  colorSettings = this.props.colorSettings

  primaryAction = this.props.primaryAction ?? function () {}

  customContainerStyle = this.props.customContainerStyle ?? {}

  onPressDelete = () => {
    Alert.alert(
      `${i18n.t('confirm_delete')?.default}`,
      `${i18n.t('are_you_sure_you_want_to_delete_this_member')?.default}?`,
      [
        {
          text: i18n.t('yes')?.default,
          style: 'default',
          onPress: this.primaryAction,
        },
        { text: i18n.t('no')?.default, style: 'cancel' },
      ]
    )
  }

  render() {
    return (
      <View
        style={{
          ...styles.buttonContainer,
          ...this.customContainerStyle,
        }}
      >
        <TouchableOpacity
          style={global_styles.normalButtonFull}
          activeOpacity={0.5}
          onPress={this.onPressDelete}
        >
          <Text style={global_styles.normalButtonText}>
            {i18n.t('delete_family_member')?.default ?? 'Delete Family Member'}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state

  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(DeleteProfileButton)
