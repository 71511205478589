import React, { useState } from 'react'
import { View, TouchableOpacity, Text, Image, FlatList } from 'react-native'

import { i18n } from '$localization/config.js'
import styles from './styles.js'

function SearchInput(props) {
  const { colorSettings, ancestryData, onPress, parent, onPressRemove } = props

  const renderSelectedAncestry = (selectedItem) => {
    const { item } = selectedItem
    const { display_name: displayName } = item

    return (
      <View style={styles.listContainer}>
        <View style={styles.listItemContainer}>
          <Text
            style={[
              {
                color: colorSettings?.selectable_btn_text_active_1 || 'black',
              },
              styles.listItemText,
            ]}
          >
            {displayName}
          </Text>
        </View>
        <TouchableOpacity
          style={styles.removeButtonContainer}
          onPress={() => onPressRemove(item)}
        >
          <Text
            style={[
              {
                color:
                  colorSettings?.selectable_btn_active_1 ||
                  'rgba(74, 74, 74, 0.5)',
              },
              styles.removeButtonText,
            ]}
          >
            {i18n.t('remove').default}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View>
      <View style={styles.inputContainer}>
        <View style={styles.innerInputContainer}>
          <View style={styles.searchInputInnerContainer}>
            <TouchableOpacity
              activeOpacity={1.0}
              style={[
                styles.itemButton,
                {
                  backgroundColor:
                    colorSettings?.selectable_btn_active_1 || 'white',
                  borderColor:
                    colorSettings?.selectable_btn_active_1 || 'black',
                },
              ]}
              onPress={() => onPress(parent)}
            >
              <Text
                style={[
                  styles.itemButtonText,
                  {
                    color:
                      colorSettings?.selectable_btn_text_active_1 ||
                      'rgba(74, 74, 74, 0.5)',
                  },
                ]}
              >
                {`${i18n.t('search_ancestry').default}...`}
              </Text>
              <Image
                style={styles.iconImage}
                source={
                  colorSettings?.selectable_btn_text_active_1
                    ? require('$assets/images/icon/searchIcon.png')
                    : require('$assets/images/new_assets/icon-search-family.png')
                }
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <FlatList
        style={styles.searchInputList}
        data={ancestryData}
        renderItem={renderSelectedAncestry}
      />
    </View>
  )
}

export default SearchInput
