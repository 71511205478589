import { useMemo } from 'react'
import BottomSheet, {
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet'
import { Text, TouchableOpacity, View } from 'react-native'
import { i18n } from '$localization/config.js'
import styles from './styles'

const defaultShadowOptions = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 16.0,
  elevation: 24,
  zIndex: 999,
}

const BottomSheetComponent = ({
  children,
  handleSheetChanges = () => {},
  startPoint,
  endPoint,
  reference,
  index,
  listItems,
  selectedItem,
  onSelectItemAction,
}) => {
  const snapPoints = useMemo(
    () => [`${startPoint}%`, endPoint ? `${endPoint}%` : 'CONTENT_HEIGHT'],
    []
  )

  const defaultContent = () => {
    const defaultContentButtons = (handlePressButton, buttonName) => {
      const isSelectedButton = selectedItem === buttonName ? '#E5E7EB' : 'white'

      return (
        <View
          style={[
            styles.bottomSheetButtonContainer,
            {
              backgroundColor: isSelectedButton,
              borderRadius: 6,
            },
          ]}
        >
          <TouchableOpacity onPress={() => handlePressButton(buttonName)}>
            <Text style={{ fontSize: 15, fontWeight: '400' }}>
              {i18n.t(buttonName).default}
            </Text>
          </TouchableOpacity>
        </View>
      )
    }

    return (
      <View style={styles.bottomSheetContainer}>
        {listItems?.map((buttonName) =>
          defaultContentButtons(onSelectItemAction, buttonName)
        )}
      </View>
    )
  }

  const {
    handleContentLayout,
    animatedSnapPoints,
    animatedHandleHeight,
    animatedContentHeight,
  } = useBottomSheetDynamicSnapPoints(snapPoints)

  const hasSpecifiedHeight = endPoint ? snapPoints : animatedSnapPoints

  return (
    <BottomSheet
      index={index}
      ref={reference}
      snapPoints={hasSpecifiedHeight}
      onChange={handleSheetChanges}
      enablePanDownToClose
      handleHeight={animatedHandleHeight}
      contentHeight={animatedContentHeight}
      style={defaultShadowOptions}
    >
      <BottomSheetView onLayout={handleContentLayout}>
        {Boolean(children) || defaultContent()}
      </BottomSheetView>
    </BottomSheet>
  )
}

// BottomSheetComponent.propTypes = {
//   children: PropTypes.node.isRequired,
//   handleSheetChanges: PropTypes.func,
//   startPoint: PropTypes.number.isRequired,
//   endPoint: PropTypes.number,
//   reference: PropTypes.object.isRequired,
//   index: PropTypes.number,
//   listItems: PropTypes.array.isRequired,
//   selectedItem: PropTypes.number | PropTypes.string,
//   onChangeItemAction: PropTypes.func,
// }

BottomSheetComponent.defaultProps = {
  handleSheetChanges: () => {},
  index: -1,
}

export default BottomSheetComponent
