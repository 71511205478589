import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
  },
  content: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  contentHeaderContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 18,
    paddingHorizontal: 16,
    borderBottomWidth: 2,
  },
  contentHeaderTitle: {
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'center',
  },
  contentBodyContainer: {
    marginHorizontal: 16,
    paddingVertical: 20,
  },
  contentFooterContainer: {
    flexDirection: 'row',
    borderColor: 'rgb(234,234,234)',
    borderTopWidth: 2.0,
    height: 60,
  },

  footerButton: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1.0,
  },
  footerButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },

  switchableFieldContainer: {
    height: 30,
    justifyContent: 'center',
  },
  switchableFieldLabelText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlignVertical: 'bottom',
  },
  switchableField: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 2,
  },

  contentBulletPointsContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingTop: 5,
  },
  contentBulletPoints: {
    backgroundColor: '(rgb(102,102,102)',
    height: 8,
    width: 8,
  },
  contentBulletedText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 12,
  },
  contentHelperText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat',
    fontSize: 14,
  },
})

export default styles
