import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setError } from '$redux/defaults/actions.js'
import sectionListOrder from '$screens/main/family/FamilyListScreen/constants/sectionListOrder.js'
import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'
import ApiFetchHandler from '$api/ApiFetchHandler'

function useGetFamilyMembers(probandID, authCredentials) {
  const dispatch = useDispatch()

  const saveError = (data) => dispatch(setError(data))

  const [familyMembers, setFamilyMembers] = useState()
  const [familyMemberError, setFamilyMemberError] = useState()
  const [familyMemberLoading, setFamilyMemberLoading] = useState(true)

  useEffect(() => {
    const getMembers = async () => {
      try {
        const getReduxTree = {
          path: `refinery/redux-tree/${probandID}/app_redux_tree/`,
          method: 'get',
          token: authCredentials.accessToken,
          pageDetails: {
            page: 'FamilyPedigreeScreen.js',
          },
        }

        const response = await ApiFetchHandler(getReduxTree)

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          setFamilyMemberError(response.isError)
          setFamilyMemberLoading(false)
        }

        const familyDataStructure = [
          { title: RelationshipTypes.YOU_AND_YOUR_CHILDREN, data: [] },
          { title: RelationshipTypes.BROTHERS_AND_SISTERS, data: [] },
          { title: RelationshipTypes.PARENTS, data: [] },
          { title: RelationshipTypes.AUNTSUNCLES_DADS_SIDE, data: [] },
          { title: RelationshipTypes.AUNTSUNCLES_MOMS_SIDE, data: [] },
          { title: RelationshipTypes.GRANDPARENTS_DADS_SIDE, data: [] },
          { title: RelationshipTypes.GRANDPARENTS_MOMS_SIDE, data: [] },
          { title: RelationshipTypes.OTHER_FAMILY_MEMBERS, data: [] },
        ]

        const keys = Object.keys(sectionListOrder).filter((key) =>
          Object.keys(response).includes(key)
        )

        const newFamilyList = keys.map((familyType) => {
          const patientsData = response
          const relationshipToProband = familyType.toLowerCase()
          const isProband = relationshipToProband === 'proband'
          const SECTION_KEY = sectionListOrder[relationshipToProband]

          return {
            title: familyDataStructure[SECTION_KEY]?.title,
            data: isProband
              ? [patientsData[relationshipToProband]]
              : [...patientsData[relationshipToProband]],
          }
        })

        setFamilyMembers(newFamilyList)
        setFamilyMemberLoading(false)
      } catch (error) {
        setFamilyMemberError(error)
        setFamilyMemberLoading(false)
        console.log(error)
      } finally {
        setFamilyMemberLoading(false)
      }
    }

    getMembers()
  }, [probandID, authCredentials])

  return { familyMembers, familyMemberError, familyMemberLoading }
}

export default useGetFamilyMembers
