import React from 'react'
import { View, Text, TouchableOpacity, FlatList, Platform } from 'react-native'

import { connect } from 'react-redux'

import BaseController from '$components/BaseController.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$constants/LoadingView.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { i18n } from '$localization/config.js'
import * as Platforms from '$constants/Platforms.js'

import { capitalizeFirstLetter } from '$api/_utils/Utils.js'

import {
  showDefaultAlert,
  showGenericServerError,
  handleDefaultError,
} from '$navigation/_utils/NavigationUtils.js'

import { setShouldReloadTwins } from '$redux/member_profile/actions.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import custom_styles from './_styles/custom.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

const API_MEMBER_ID = 'member_id'

const TWIN_TYPES = [
  { id: 1, name: 'identical' },
  { id: 2, name: 'fraternal' },
  { id: 3, name: 'im_not_sure' },
]

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class SelectTwinType extends BaseController {
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  proband = this.props.currentProband

  currentMember = this.props.currentMember

  state = {
    listData: TWIN_TYPES,
    selectedItem: null,
    showLoadingView: false,
    scrollViewHeight: getScrollViewHeight(),
  }

  constructor(props) {
    super(props)
    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  onPressItem = (item) => {
    this.setState({ selectedItem: item })
  }

  onDone = async () => {
    if (!this.state.selectedItem) {
      showDefaultAlert(
        i18n.t('select_twin_type')?.default,
        i18n.t('please_select_a_twin_type_dot')?.default
      )
      return
    }

    const memberID = this.currentMember?.[API_MEMBER_ID]
    const twinGroupMembers = this.props.addNewTwinsPayload ?? []
    const twinGroupType = this.state.selectedItem

    const twinGroupMemberIDs = []

    /* Get IDs of Twin Group Members */
    if (twinGroupMembers.length > 0) {
      twinGroupMembers.forEach((e) => {
        if (e?.[API_MEMBER_ID]) twinGroupMemberIDs.push(e?.[API_MEMBER_ID])
      })
    }

    /* Activate loading view */
    this.setState({ showLoadingView: true })

    /* API request to update twins */
    const updateTwinPayload = {
      path: 'member/update_twin/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        member_id: memberID,
        twin_member_id: twinGroupMemberIDs,
        twin_member_type: twinGroupType?.name,
      },
      pageDetails: {
        page: 'SelectTwinType.js',
      },
    }

    const response = await ApiFetchHandler(updateTwinPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.props._setShouldReloadTwins_(true)

    this.props.navigation.getParent().goBack()
  }

  renderItem = ({ item, index, section }) => {
    const selectedItemID = this.state.selectedItem?.id
    const itemID = item?.id
    const twinGroupType = item?.name

    const backgroundColor = selectedItemID == itemID ? '#ccc' : 'white'

    return (
      <View style={[custom_styles.sectionItemContainer, { backgroundColor }]}>
        <TouchableOpacity
          style={custom_styles.sectionItemImageLess}
          activeOpacity={1.0}
          onPress={() => this.onPressItem(item)}
        >
          <Text style={custom_styles.sectionItemLabelText}>
            {i18n.t(twinGroupType)?.default ??
              capitalizeFirstLetter(twinGroupType)}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    const { navigation } = this.props
    const nextButtonDisabled = !this.state.selectedItem?.id

    return (
      <MainWrapper navigation={navigation}>
        <View
          style={{
            width: '100%',
            flex: 1.0,
            ...Platform.select({
              [Platforms.WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          }}
        >
          <View
            nativeID="web_scaled_main_container"
            style={{ flex: 1.0, backgroundColor: 'white' }}
          >
            <FlatList
              style={{
                ...Platform.select({
                  [Platforms.WEB]: {
                    flex: null,
                    height: getScrollViewHeight(),
                  },
                }),
              }}
              data={this.state.listData}
              // extraData={this.state.refresh}   // enabled on web
              keyExtractor={(item) => `${item?.id}`}
              renderItem={this.renderItem}
            />
          </View>

          <View
            style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}
          >
            <TouchableOpacity
              style={[
                global_styles.nextButton,
                this.inversedToggleNextButtonColor(
                  nextButtonDisabled,
                  this.colorSettings
                ),
              ]}
              onPress={this.onDone}
              disabled={nextButtonDisabled}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.colorSettings?.bottom_next_btn_text ?? 'black',
                  },
                ]}
              >
                {i18n.t('done')?.default}
              </Text>
            </TouchableOpacity>
          </View>

          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </View>
      </MainWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** MemberProfile Store */
    currentMember: memberProfileStore.currentMember,
    addNewTwinsPayload: memberProfileStore.addNewTwinsPayload,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setShouldReloadTwins_: (data) => dispatch(setShouldReloadTwins(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTwinType)
