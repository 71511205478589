import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { i18n } from '$localization/config.js'
import translateDisease from '$screens/personal_details/relative_gene_test/_utils/TextTranslation.js'

const SubListView = ({ colorSettings, currentData, onEdit, textChecker }) => {
  const RenderItems = ({ item }) => (
    <View style={styles.listContainer}>
      <Text
        style={[
          styles.questionTitle,
          {
            color: colorSettings?.selectable_btn_text_active_1,
          },
        ]}
      >
        {textChecker(item.question)}
        <Text
          style={[
            styles.questionAnswer,
            {
              color: colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {item.question_type === 'yes_or_no_then_number'
            ? `, ${item.answer}` || null
            : null}
        </Text>
      </Text>
      <TouchableOpacity style={styles.editButton} onPress={() => onEdit()}>
        <Text
          style={[
            styles.editTitle,
            {
              color: colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {i18n.t('edit').default}
        </Text>
      </TouchableOpacity>
    </View>
  )

  const RenderList = ({ selectedData }) => {
    // data checker
    if (!selectedData) return <View />
    const data = selectedData?.skipLogic ?? []

    if (data.length <= 0) return <View />
    if (data.length === 1) {
      if (data[0].answer === 'n') {
        return <View />
      }
    }

    const listItems = data.map((item) => {
      if (item.answer === 'n') return <View />
      return <RenderItems key={item.id} item={item} />
    })
    return listItems
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.aodContainer}>
        <Text
          style={[
            styles.questionTitle,
            {
              color: colorSettings?.btn_no_fill_text_2 || 'black',
            },
          ]}
        >
          {`${i18n.t('age_of_diagnosis').default}: `}
          <Text style={styles.questionAnswer}>
            {currentData.age_diagnosed || '?'}
          </Text>
        </Text>

        <TouchableOpacity style={styles.editButton} onPress={() => onEdit()}>
          <Text
            style={[
              styles.editTitle,
              {
                color: colorSettings?.btn_no_fill_text_2 || 'black',
              },
            ]}
          >
            {i18n.t('edit').default}
          </Text>
        </TouchableOpacity>
      </View>
      <RenderList selectedData={currentData} />
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    paddingHorizontal: 30,
    paddingVertical: 7,
  },
  aodContainer: {
    flexDirection: 'row',
    marginVertical: 7,
  },
  editTitle: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'right',
  },
  questionTitle: {
    flex: 1.3,
    fontFamily: 'montserrat',
    fontSize: 13,
  },
  questionAnswer: {
    fontFamily: 'montserrat-semibold',
  },
  editButton: {
    flex: 0.7,
  },
  listContainer: {
    flexDirection: 'row',
    marginVertical: 7,
  },
})

export default SubListView
