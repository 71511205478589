import { useEffect, useRef, useState } from 'react'
import { Dimensions, Image, Modal, TouchableOpacity, View } from 'react-native'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import styles from './static/main.styles'
import {
  _carousel,
  carouselContentHeight,
  carouselContentWidth,
  carouselData,
  carouselInterval,
  currentContentHeight,
  currentContentWidth,
  hideRisk,
  autoplay,
  paginationDotStyle,
  paginationInactiveDotStyle,
  tappableDots,
} from './constans/constans'
import { IS_MOBILE, IS_WEB } from '../../../constants/Platforms'
import WalkthroughScreens from './components/WalkthroughScreens'
import WalkthroughDots from './components/WalkthroughDots'
import DummyFamilyTab from './components/DummyFamilyTab'
import DummyRisksTab from './components/DummyRisksTab'

const windowDimensions = Dimensions.get('window')

function FamilyScreenWalkthrough({
  colorSettings,
  isFirstLogin,
  tutorialWithSurvey,
  onFamilyScreenWalkthroughCloseAction,
}) {
  const carouselRef = useRef(_carousel)
  const [activePaginatorIndex, setActivePaginatorIndex] = useState(0)

  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
  })

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions({ window })
    })
    return () => subscription?.remove()
  })

  useEffect(() => {
    if (hideRisk || tutorialWithSurvey) carouselData.push({ key: 3 })
  }, [])

  return (
    <Modal animationType="fade" visible={isFirstLogin} transparent>
      <View style={styles.container} nativeID="modal_scaled_main_container">
        <View style={styles.content(currentContentWidth, currentContentHeight)}>
          <View style={styles.contentContainer}>
            <View style={styles.carouselContainer}>
              <View style={styles.carouselContentContainer}>
                <View style={styles.dismissButtonView}>
                  <TouchableOpacity
                    onPress={onFamilyScreenWalkthroughCloseAction}
                  >
                    <Image
                      source={require('$assets/images/new_assets/icon-close-modal-w.png')}
                      style={styles.dismissButtonIcon}
                    />
                  </TouchableOpacity>
                </View>

                {IS_MOBILE() && (
                  <Carousel
                    ref={carouselRef}
                    data={carouselData}
                    sliderWidth={carouselContentWidth}
                    itemWidth={carouselContentWidth}
                    itemHeight={carouselContentHeight}
                    onSnapToItem={(index) => setActivePaginatorIndex(index)}
                    renderItem={({ _, index }) => (
                      <WalkthroughScreens
                        index={index}
                        onFamilyScreenWalkthroughCloseAction={
                          onFamilyScreenWalkthroughCloseAction
                        }
                      />
                    )}
                  />
                )}
                {IS_WEB() && (
                  <Carousel
                    layout="default"
                    ref={carouselRef}
                    data={carouselData}
                    sliderWidth={carouselContentWidth}
                    itemWidth={carouselContentWidth}
                    itemHeight={carouselContentHeight}
                    onSnapToItem={(index) => setActivePaginatorIndex(index)}
                    autoplay={autoplay}
                    autoplayDelay={0}
                    autoplayInterval={carouselInterval}
                    renderItem={({ _, index }) => (
                      <WalkthroughScreens
                        index={index}
                        onFamilyScreenWalkthroughCloseAction={
                          onFamilyScreenWalkthroughCloseAction
                        }
                      />
                    )}
                  />
                )}

                {IS_MOBILE() && (
                  <Pagination
                    containerStyle={styles.paginatorContainer}
                    dotsLength={carouselData.length}
                    activeDotIndex={activePaginatorIndex}
                    dotStyle={[
                      styles.paginatorDotStyle,
                      {
                        backgroundColor:
                          colorSettings?.pagination_dot_active ??
                          'rgba(74,74,74,1)',
                      },
                    ]}
                    inactiveDotStyle={[
                      styles.paginatorDotStyle,
                      {
                        backgroundColor:
                          colorSettings?.pagination_dot_inactive ??
                          'rgba(74,74,74,0.5)',
                      },
                    ]}
                    inactiveDotScale={1.0}
                    carouselRef={carouselRef}
                  />
                )}
                {IS_WEB() && (
                  <Pagination
                    containerStyle={styles.paginatorContainer}
                    dotsLength={carouselData.length}
                    activeDotIndex={activePaginatorIndex}
                    dotColor={
                      colorSettings?.pagination_dot_active ?? 'rgba(74,74,74,1)'
                    }
                    inactiveDotColor={
                      colorSettings?.pagination_dot_inactive ??
                      'rgba(74,74,74,0.5)'
                    }
                    dotStyle={paginationDotStyle}
                    inactiveDotStyle={paginationInactiveDotStyle}
                    inactiveDotScale={1.0}
                    carouselRef={carouselRef}
                    tappableDots={tappableDots}
                    renderDots={({ index }) => (
                      <WalkthroughDots activeIndex={index} ref={carouselRef} />
                    )}
                  />
                )}
              </View>
            </View>
          </View>

          <View style={styles.dummyTabsSeparator} />
        </View>

        {activePaginatorIndex === 1 ? (
          <DummyFamilyTab screenWidth={dimensions.window.width} />
        ) : null}

        {activePaginatorIndex === 2 && !hideRisk && !tutorialWithSurvey ? (
          <DummyRisksTab screenWidth={dimensions.window.width} />
        ) : null}
      </View>
    </Modal>
  )
}
export default FamilyScreenWalkthrough
