module.exports = function () {
  this.inject = function () {
    var styleElement = document.createElement('style')
    styleElement.innerHTML =
      '@media screen and (max-width: 1600px) {' +
      '    #web_scaled_main_container > div:first-child {' +
      '        max-width: 800px;' +
      '        width: 100%;' +
      '        margin-left: auto;' +
      '        margin-right: auto;' +
      '    }' +
      '    #modal_scaled_main_container > div {' +
      '        max-width: 700px;' +
      '        width: 100%;' +
      '        margin-left: auto;' +
      '        margin-right: auto;' +
      '    }' +
      '}' +
      '@media screen and (min-width: 1600px) and (max-width: 9000px) {' +
      '    #web_scaled_main_container > div:first-child {' +
      '        max-width: 800px;' +
      '        width: 100%;' +
      '        margin-left: auto;' +
      '        margin-right: auto;' +
      '    }' +
      '    #modal_scaled_main_container > div {' +
      '        max-width: 700px;' +
      '        width: 100%;' +
      '        margin-left: auto;' +
      '        margin-right: auto;' +
      '    }' +
      '}' +
      '#forgot_password_button {' +
      '    position: relative;' +
      '    margin-top: 70px;' +
      '}' +
      '#login_view_content_container {' +
      '    height: initial;' +
      '}' +
      '#family_section_title {' +
      '    padding-left: 15px;' +
      '}' +
      '#login_screen_input {' +
      '    outline: none;' +
      '    margin-bottom: -4px;' +
      '}' +
      '#login_screen_input_container > div {' +
      '    min-height: 62px;' +
      '}' +
      '#text_input_web {' +
      '        width: 330px;' +
      '        margin-left: auto;' +
      '        margin-right: auto;' +
      '}' +
      '#text_input_web:focus {' +
      '        outline: none !important;' +
      '        border:2px solid #ccc;' +
      '}' +
      '#signup_clinician_logo_container {' +
      '        border-width: 0px;' +
      '}' +
      '#signup_gender_selection_tile {' +
      '        width: 170px;' +
      '        height: 170px;' +
      '}' +
      '#gender_id_tile {' +
      '        width: 110px;' +
      '}' +
      '#risk_genetic_resources_button {' +
      '        margin-left: auto;' +
      '        margin-right: auto;' +
      '}' +
      '#phone_no_down_arrow {' +
      '        margin-left: 11px;' +
      '        margin-bottom: 2px;' +
      '        border: solid currentColor;' +
      '        border-width: 0 2px 2px 0;' +
      '        display: inline-block;' +
      '        padding: .20em;' +
      '        transform: rotate(45deg);' +
      '}' +
      '#phone_flag_and_ccode_signup_process input {' +
      '        width: 24px;' +
      '}' +
      '#phone_input_signup_process input {' +
      '        width: 200px;' +
      '}' +
      '#phone_input_row_signup_process {' +
      '        padding-left: 166px;' +
      '}' +
      '#yes_no_toggle_button_container > div {' +
      '        flex-direction: row;' +
      '        justify-content: center;' +
      '        border: none;' +
      '}' +
      '#web_text_input:focus {' +
      '        outline: none !important;' +
      '        border:2px solid #ccc;' +
      '}'

    document.getElementsByTagName('head')[0].appendChild(styleElement)
  }
}
