import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import uuid from 'react-native-uuid'
import customStyles from '../../_styles/main.styles'
import PartnerView from '../../_includes/partners/Partners.view'

import { i18n } from '$localization/config.js'

import {
  formatDisplayedMemberName,
  getGenderIcon,
  getGenderIconBorderRadius,
} from '$api/_utils/Utils'
import FamilyCard from '$components/family-card/FamilyCard'
import {
  setInvitedMember,
  storeCurrentMember,
} from '$redux/member_profile/actions'
import { setShouldReloadFamilyList } from '$redux/proband/actions'
import calculateRelationship from '../helpers/calculateRelationship'
import MemberProfile from '../../../../../data_models/MemberProfile'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils'

function SectionListItem({
  expandedSections,
  section,
  item,
  relationshipList,
  isCustomWorkflow,
}) {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const saveCurrentMemberToStore = (data) => dispatch(storeCurrentMember(data))
  const saveShouldReloadFamilyList = (data) =>
    dispatch(setShouldReloadFamilyList(data))
  const saveInvitedMember = (data) => dispatch(setInvitedMember(data))
  const isExpanded = expandedSections?.has(section?.title)

  if (!isExpanded) return null

  if (!item) return null

  const member = item

  if (!member?.member_id || member?.is_deleted) return null

  const titleText = formatDisplayedMemberName(
    member?.name,
    member?.age_string ?? member?.age,
    member?.is_dead
  )

  const addToMemberButtonAction = (currentMember = null) => {
    saveCurrentMemberToStore(currentMember)

    if (isCustomWorkflow) {
      navigation.push('AddFamilyMemberViewCancer', {
        isFromFamilyScreen: true,
        currentMember,
      })
    } else {
      navigation.navigate('AddMemberNavigator', {
        screen: 'AddFamilyMemberView',
      })
    }
  }

  const navigateToMemberProfile = (
    clickedUser,
    shouldReloadList = false,
    hasMultipleAddedRelative = false
  ) => {
    const currentMember = new MemberProfile().constructBasicProfile({
      ...clickedUser,
    })

    if (shouldReloadList) {
      saveShouldReloadFamilyList(true)
    }

    saveCurrentMemberToStore(currentMember)
    saveInvitedMember(clickedUser)

    if (member?.is_deleted) {
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('sorry_this_person_might_have_already_been_deleted')?.default
      )
      return
    }

    if (!currentMember?.member_id) return

    const routeName = isCustomWorkflow ? 'EditProfileCancer' : 'EditProfileView'

    if (!hasMultipleAddedRelative) {
      navigation.navigate(routeName, {
        isFamilyScreen: true,
      })
    }
  }

  const { relationship } = calculateRelationship(member, relationshipList)

  return (
    <FamilyCard
      member={member}
      navigateToMemberProfile={() => navigateToMemberProfile(member, false)}
      getGenderIcon={getGenderIcon(member?.gender, member?.is_dead)}
      getGenderIconBorderRadius={getGenderIconBorderRadius(
        member?.gender,
        member?.is_dead
      )}
      addToMemberButtonAction={() => addToMemberButtonAction(member)}
      relationship={relationship}
      item={item}
      customStyles={customStyles}
      titleText={titleText}
      colorSettings={colorSettings}
      component={
        <PartnerView
          listKey={uuid.v4()}
          colorSettings={colorSettings}
          navigateToMemberProfile={navigateToMemberProfile}
          addToMemberButtonAction={addToMemberButtonAction}
          mainMember={member}
          partners={item?.partners}
        />
      }
    />
  )
}

export default SectionListItem
