import { Component } from 'react'
import {
  SafeAreaView,
  Text,
  View,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
  Keyboard,
  KeyboardAvoidingView,
  StatusBar,
} from 'react-native'
import { Sae } from 'react-native-textinput-effects'

import { connect } from 'react-redux'
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome'
import { i18n } from '$localization/config.js'

import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import AlertModal from './AlertModal.js'

import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { IS_WEB, IOS, WEB } from '$constants/Platforms.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'
import { setError } from '$redux/defaults/actions.js'
import ErrorBoundary from 'react-native-error-boundary'

const inputAccessoryViewID = 'ChangePasswordScreen'

class CustomTextInput extends Component {
  parentController = this.props.parentController

  setRef = this.props.setRef

  colorSettings = this.props.colorSettings

  render() {
    return (
      <Sae
        ref={(ref) => this.setRef(ref)}
        style={styles.textInputContainer}
        labelStyle={[
          styles.textInputLabel,
          { color: this.colorSettings?.text_input_label_color_1 },
        ]}
        inputStyle={[
          styles.textInputField,
          { color: this.colorSettings?.text_input_color_1 },
        ]}
        iconClass={FontAwesomeIcon}
        iconName=""
        iconColor=""
        inputPadding={8}
        selectionColor="rgb(74,74,74)"
        borderHeight={0}
        autoCapitalize="none"
        autoCorrect={false}
        returnKeyType="next"
        inputAccessoryViewID={inputAccessoryViewID}
        {...this.props}
      />
    )
  }
}

class ChangePassword extends Component {
  // compiled constant text
  OLD_PASSWORD = i18n.t('old_password').default

  TOOLTIP_MAIN = i18n.t(
    'your_password_must_containnat_least_8_charactersnincluding_a_numbernan_upper_case_letterna_lower_case_letternand_a_special_characternnand_shouldnt_be_too_similarnto_your_other_personal_informationnor_a_recently_used_password'
  ).default

  CURRENT_PASSWORD_MATCH = i18n.t('current_password_match').default

  CURRENT_PASSWORD_DID_NOT_MATCH = i18n.t('current_password_did_not_match')
    .default

  ENTER_OLD_PASSWORD = i18n.t('please_enternyour_old_password').default

  NEW_PASSWORD_BOTH_FIELD = i18n.t(
    'please_enternyour_new_password_onnboth_fields'
  ).default

  NEW_PASSWORD_DID_NOT_MATCH = i18n.t('new_password_does_not_match').default

  ENTER_NEW_PASSWORD = i18n.t('enter_new_password').default

  ALPHANUMERIC_CHARACTERS = i18n.t('at_least_8_alphanumeric_characters').default

  NEW_PASSWORD = i18n.t('new_password').default

  CONFIRM_PASSWORD = i18n.t('confirm_password').default

  SUBMIT = i18n.t('submit').default

  parentRefs = {
    _oldPassword: null,
    _newPassword: null,
    _confirmPassword: null,
  }

  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials
  // account = this.props.currentAccount || new Account();

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)

    this.state = {
      tooltipVisible: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordHidden: true,
      newPasswordHidden: true,
      confirmPasswordHidden: true,
      oldPasswordImageURI: require('$assets/images/icon/eye-close/black.png'),
      newPasswordImageURI: require('$assets/images/icon/eye-close/black.png'),
      confirmPasswordImageURI: require('$assets/images/icon/eye-close/black.png'),
      oldPasswordLabel: this.OLD_PASSWORD,
      subtitleStyle: {
        fontFamily: 'montserrat',
        fontSize: 18,
        color: 'rgba(74,74,74,0.5)',
        marginBottom: 10,
      },
      isSubmitDisabled: true,
      tooltipContent: this.TOOLTIP_MAIN,
      errorType: 'default',
      scrollViewHeight: DefaultFullHeight(),
      isActive: false,
    }
  }

  componentDidMount() {
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    // Set the component's navigation event listeners
    this.setNavigationEventListener('focus')
  }

  componentWillUnmount() {
    // This event happens before component is removed from memory.
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  setNavigationEventListener = (event = 'focus') => {
    // Set the component's navigation event listeners
    // Default event: 'didFocus'
    const { navigation } = this.props
    navigation.addListener(event, async () => {
      this.colorSettings = this.props.colorSettings
    })
  }

  oldPassword = ''

  newPassword = ''

  confirmPassword = ''

  toggleNextButtonColor = (enabled = true) => {
    const style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled,
    }
    if (enabled) {
      style.backgroundColor = this.colorSettings?.bottom_next_btn_enabled
    }
    return style
  }

  toggleNextButtonText = (enabled = true) => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  showPasswordButtonAction = (hiddenRefKey, imageUriRefKey) => {
    const passwordIsHidden = !this.state[hiddenRefKey]
    const newState = this.state

    if (passwordIsHidden) {
      newState[hiddenRefKey] = passwordIsHidden
      newState[
        imageUriRefKey
      ] = require('$assets/images/icon/eye-close/black.png')
      this.setState(newState)
    } else {
      newState[hiddenRefKey] = passwordIsHidden
      newState[imageUriRefKey] = require('$assets/images/icon/eye/black.png')
      this.setState(newState)
    }
  }

  onEndEditing = async () => {
    this.setState({
      isSubmitDisabled: true,
      errorType: 'old_pw',
    })

    if (!this.oldPassword) {
      this.setState({
        isSubmitDisabled: true,
        oldPasswordLabel: this.OLD_PASSWORD,
        subtitleStyle: {
          fontFamily: 'montserrat',
          fontSize: 18,
          color: this.colorSettings?.text_input_placeholder_color_1,
          marginBottom: 10,
        },
      })
      return
    }
    // Validate password
    const passwordValidator = require('password-validator')
    const schema = new passwordValidator()

    // Minimum length 8
    schema.is().min(8)

    if (schema.validate(this.oldPassword)) {
      const checkPasswordPayload = {
        path: 'account/strict/check_password/',
        method: 'post',
        body: {
          current_password: this.oldPassword,
        },
        token: this.authCredentials.accessToken,
        pageDetails: {
          page: 'ChangePassword.js',
        },
      }

      const response = await ApiFetchHandler(checkPasswordPayload)
      const { saveError } = this.props

      if (response.isError) {
        this.setState({
          isSubmitDisabled: true,
          oldPasswordLabel: this.CURRENT_PASSWORD_DID_NOT_MATCH,
          subtitleStyle: {
            fontFamily: 'montserrat',
            fontSize: 18,
            color: 'rgba(255,0,0,1)',
            marginBottom: 10,
          },
          errorType: 'old_pw',
        })

        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        return
      }

      if (response.status_code !== 200) {
        return
      }

      this.setState({
        isSubmitDisabled: false,
        oldPasswordLabel: this.CURRENT_PASSWORD_MATCH,
        subtitleStyle: {
          fontFamily: 'montserrat',
          fontSize: 18,
          color: this.colorSettings?.bottom_next_btn_enabledChange,
          marginBottom: 10,
        },
        errorType: null,
      })
    } else {
      this.setState({
        isSubmitDisabled: true,
        oldPasswordLabel: this.CURRENT_PASSWORD_DID_NOT_MATCH,
        subtitleStyle: {
          fontFamily: 'montserrat',
          fontSize: 18,
          color: 'rgba(255,0,0,1)',
          marginBottom: 10,
        },
        errorType: 'old_pw',
      })
    }
  }

  validateInput = () => {
    // Validate password
    if (!this.oldPassword) {
      this.setState({
        tooltipContent: this.ENTER_OLD_PASSWORD,
        tooltipVisible: true,
        errorType: 'missing_pw',
      })
      return false
    }

    if (this.state.isSubmitDisabled) {
      this.setState({
        tooltipContent: this.CURRENT_PASSWORD_DID_NOT_MATCH,
        tooltipVisible: true,
        errorType: 'old_pw',
      })
      return false
    }

    if (!this.newPassword || !this.confirmPassword) {
      this.setState({
        tooltipContent: this.NEW_PASSWORD_BOTH_FIELD,
        tooltipVisible: true,
        errorType: 'missing_pw',
      })
      return false
    }

    if (this.newPassword != this.confirmPassword) {
      this.setState({
        tooltipContent: this.NEW_PASSWORD_DID_NOT_MATCH,
        tooltipVisible: true,
        errorType: 'mismatch_pw',
      })
      return false
    }

    // Validate password
    const passwordValidator = require('password-validator')
    const schema = new passwordValidator()

    // Add properties to it
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces

    if (
      schema.validate(this.newPassword) &&
      schema.validate(this.confirmPassword)
    ) {
      return true
    }
    this.setState({
      tooltipContent: this.TOOLTIP_MAIN,
      tooltipVisible: true,
      errorType: 'default',
    })
    return false
  }

  handlePress = async () => {
    Keyboard.dismiss()

    if (!this.validateInput()) {
      return
    }

    // Prepare API request to get Account data
    const payload = {
      new_password: this.newPassword,
    }

    const headers = {
      accessToken: this.authCredentials.accessToken,
    }

    const changePasswordPayload = {
      path: 'account/strict/change_password/',
      method: 'post',
      body: {
        new_password: this.newPassword,
      },
      token: this.authCredentials.accessToken,
      pageDetails: {
        page: 'ChangePassword.js',
      },
    }

    const response = await ApiFetchHandler(changePasswordPayload)

    if (response.isError) {
      this.setState({
        tooltipContent: this.TOOLTIP_MAIN,
        tooltipVisible: true,
        errorType: 'default',
      })

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      return
    }

    this.props.navigation.navigate('SuccessScreenPasswordChanged')
  }

  toggleTooltip = () => {
    this.setState({
      tooltipVisible: !this.state.tooltipVisible,
      errorType: null,
    })
  }

  setParentRef(key, ref) {
    if (key && ref) {
      this.parentRefs[key] = ref
    }
  }

  navigateToNextInput = (event, callback) => {
    if (WEB === Platform.OS) {
      if (event.code === 'Tab') {
        event.preventDefault()
        callback()
      }
    }
  }

  render() {
    const { navigation } = this.props
    const { isActive } = this.state

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation} screenActive={isActive}>
          <SafeAreaView style={styles.container}>
            {Platform.OS === IOS && <StatusBar barStyle="dark-content" />}

            <KeyboardAvoidingView
              style={{ flex: 1.0 }}
              keyboardVerticalOffset={Platform.OS === IOS ? 64 : 0}
              behavior={Platform.OS === IOS ? 'padding' : null}
              enabled={Platform.OS === IOS}
              contentContainerStyle={{ flex: 1.0 }}
            >
              <ScrollView style={{ height: this.state.scrollViewHeight }}>
                <View style={styles.content}>
                  <View style={{ height: 20 }} />
                  <View style={styles.titleMainContainer}>
                    <View style={styles.titleContainer}>
                      <Text
                        style={[
                          styles.title,
                          {
                            color: this.colorSettings?.text_input_title_color_1,
                          },
                        ]}
                      >
                        {this.ENTER_NEW_PASSWORD}
                      </Text>
                    </View>
                    <View style={styles.subtitleContainer}>
                      <Text
                        style={[
                          styles.subtitle,
                          {
                            color: this.colorSettings?.text_input_label_color_1,
                          },
                        ]}
                      >
                        {this.ALPHANUMERIC_CHARACTERS}
                      </Text>
                    </View>
                  </View>
                  <View style={{ height: 20 }} />

                  <View style={styles.sectionContainer}>
                    <View style={styles.sectionContent}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <CustomTextInput
                          nativeID="web_text_input"
                          colorSettings={this.colorSettings}
                          parentController={this}
                          setRef={(ref) =>
                            this.setParentRef('_oldPassword', ref)
                          }
                          inputType="password"
                          label={this.state.oldPasswordLabel}
                          inputAccessoryViewID={inputAccessoryViewID}
                          onSubmitEditing={() =>
                            this.parentRefs._newPassword.focus()
                          }
                          // onEndEditing={() => this.onEndEditing()}
                          onChangeText={(value) => {
                            this.oldPassword = value
                            this.setState({ oldPassword: value })
                            this.onEndEditing()
                          }}
                          onChange={() =>
                            this.setState((prev) => ({
                              isActive: !prev.isActive,
                            }))
                          }
                          secureTextEntry={this.state.oldPasswordHidden}
                          value={this.state.oldPassword}
                          onKeyPress={(key) =>
                            this.navigateToNextInput(key, () =>
                              this.parentRefs?._newPassword?.focus()
                            )
                          }
                          tabindex={1}
                        />
                        <View
                          style={{
                            marginLeft: -24,
                            marginBottom: 10,
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <TouchableOpacity
                            onPress={() =>
                              this.showPasswordButtonAction(
                                'oldPasswordHidden',
                                'oldPasswordImageURI'
                              )
                            }
                          >
                            <Image
                              style={{
                                height: 24,
                                width: 24,
                                resizeMode: 'contain',
                              }}
                              source={this.state.oldPasswordImageURI}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={styles.sectionContainer}>
                    <View style={styles.sectionContent}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <CustomTextInput
                          nativeID="web_text_input"
                          colorSettings={this.colorSettings}
                          parentController={this}
                          setRef={(ref) =>
                            this.setParentRef('_newPassword', ref)
                          }
                          inputType="password"
                          label={this.NEW_PASSWORD}
                          inputAccessoryViewID={inputAccessoryViewID}
                          onSubmitEditing={() =>
                            this.parentRefs._confirmPassword.focus()
                          }
                          onChangeText={(value) => {
                            this.newPassword = value
                            this.setState({ newPassword: value })
                          }}
                          onChange={() =>
                            this.setState((prev) => ({
                              isActive: !prev.isActive,
                            }))
                          }
                          secureTextEntry={this.state.newPasswordHidden}
                          value={this.state.newPassword}
                          onKeyPress={(key) =>
                            this.navigateToNextInput(key, () =>
                              this.parentRefs?._confirmPassword?.focus()
                            )
                          }
                        />
                        <View
                          style={{
                            marginLeft: -24,
                            marginBottom: 10,
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <TouchableOpacity
                            onPress={() =>
                              this.showPasswordButtonAction(
                                'newPasswordHidden',
                                'newPasswordImageURI'
                              )
                            }
                          >
                            <Image
                              style={{
                                height: 24,
                                width: 24,
                                resizeMode: 'contain',
                              }}
                              source={this.state.newPasswordImageURI}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={styles.sectionContainer}>
                    <View style={styles.sectionContent}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <CustomTextInput
                          nativeID="web_text_input"
                          colorSettings={this.colorSettings}
                          parentController={this}
                          setRef={(ref) =>
                            this.setParentRef('_confirmPassword', ref)
                          }
                          inputType="password"
                          label={this.CONFIRM_PASSWORD}
                          inputAccessoryViewID={inputAccessoryViewID}
                          colorSettings={this.colorSettings}
                          onChangeText={(value) => {
                            this.confirmPassword = value
                            this.setState({ confirmPassword: value })
                          }}
                          onChange={() =>
                            this.setState((prev) => ({
                              isActive: !prev.isActive,
                            }))
                          }
                          secureTextEntry={this.state.confirmPasswordHidden}
                          value={this.state.confirmPassword}
                        />
                        <View
                          style={{
                            marginLeft: -24,
                            marginBottom: 10,
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <TouchableOpacity
                            onPress={() =>
                              this.showPasswordButtonAction(
                                'confirmPasswordHidden',
                                'confirmPasswordImageURI'
                              )
                            }
                          >
                            <Image
                              style={{
                                height: 24,
                                width: 24,
                                resizeMode: 'contain',
                              }}
                              source={this.state.confirmPasswordImageURI}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ height: 30 }} />
                </View>
                {/* </View> */}
              </ScrollView>
            </KeyboardAvoidingView>

            <View style={{ height: 60, width: '100%' }}>
              <View style={styles.nextButtonContainer}>
                <TouchableOpacity
                  disabled={this.state.isSubmitDisabled}
                  style={[
                    styles.nextButton,
                    this.toggleNextButtonColor(!this.state.isSubmitDisabled),
                    {
                      backgroundColor:
                        this.colorSettings?.bottom_next_btn_enabled,
                    },
                  ]}
                  onPress={() => {
                    this.handlePress()
                  }}
                >
                  <Text
                    style={[
                      styles.nextButtonText,
                      {
                        color: this.toggleNextButtonText(
                          !this.state.isSubmitDisabled
                        ),
                      },
                    ]}
                  >
                    {this.SUBMIT}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <NextButtonAccessoryView
              backgroundColor="white"
              labelText={this.SUBMIT}
              labelColor={this.toggleNextButtonText(
                !this.state.isSubmitDisabled
              )}
              nativeID={inputAccessoryViewID}
              disabled={this.state.isSubmitDisabled}
              style={[
                styles.nextButton,
                this.toggleNextButtonColor(!this.state.isSubmitDisabled),
                {
                  backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={() => {
                this.handlePress()
              }}
            />

            <AlertModal
              visible={this.state.tooltipVisible}
              toggleTooltip={() => this.toggleTooltip()}
              tooltipContent={this.state.tooltipContent}
              errorType={this.state.errorType}
            />
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

const styles = StyleSheet.create({
  titleMainContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '25%',
  },
  titleContainer: {
    height: '65%',
    justifyContent: 'flex-end',
  },
  title: {
    fontFamily: 'karla-bold',
    fontSize: 24,
    color: 'rgb(74,74,74)',
  },
  subtitleContainer: {
    height: '35%',
    justifyContent: 'center',
  },
  subtitle: {
    fontFamily: 'montserrat',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    opacity: 0.5,
  },
  bodyContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '45%',
  },

  tooltipContentContainer: {
    backgroundColor: 'white',
    flex: 1.0,
    alignItems: 'center',
    // justifyContent: "center",
    marginHorizontal: 18.0,
    borderRadius: 10.0,
    borderWidth: 2.0,
    borderColor: 'rgba(234,234,234,1.0)',
  },
  tooltipContent: {
    // backgroundColor: "blue",
    flex: 1.0,
    width: '70%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipContentTitleContainer: {
    // backgroundColor: "yellow",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipContentTitle: {
    color: 'rgba(74,74,74,1.0)',
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },
  tooltipContentText: {
    color: 'rgba(74,74,74,1.0)',
    fontFamily: 'montserrat',
    fontSize: 16,
    lineHeight: 24,
    // textAlign: "center",
    textAlign: 'justify',
  },
  tooltipDismissButton: {
    // backgroundColor: "green",
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // height: 60,
    borderTopColor: 'rgba(234,234,234,1.0)',
    borderTopWidth: 2.0,
  },
  tooltipDismissButtonText: {
    color: 'rgba(117,84,136,1.0)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },
  navigationBar: {
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
  },
  navigationBarBackButton: {
    marginHorizontal: 10,
    height: '100%',
    justifyContent: 'center',
  },
  // from login
  container: {
    backgroundColor: '#fff',
    flex: 1.0,
  },
  content: {
    justifyContent: 'center',
    flex: 1.0,
    width: '100%',
  },
  nextButton: {
    justifyContent: 'center',
    // backgroundColor: 'rgba(132,209,192,1)',
    width: '100%',
    height: '100%',
  },
  nextButtonText: {
    // color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  textInputContainer: {
    // backgroundColor: "lime",
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(74,74,74,0.5)',
  },
  textInputLabel: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: 'rgba(74,74,74,0.5)',
    marginBottom: 10,
  },
  textInputField: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: 'rgb(74,74,74)',
    ...Platform.select({
      web: {
        outlineColor: '#ccc',
      },
    }),
  },

  sectionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionContent: {
    width: 280,
    justifyContent: 'flex-end',
  },
  nextButtonContainer: {
    height: 60,
  },
})

function mapGlobalStateToProps(state) {
  const { store, accountStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(ChangePassword)
