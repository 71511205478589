/* eslint-disable no-case-declarations */
import { capitalizeFirstLetter } from '$api/_utils/Utils.js'

import { RELATION_ID, RELATION_TYPE } from './constants'

function findParentArrayForMember(data, memberId) {
  const findParentArrayRecursive = (array, memberId) => {
    for (const item of array) {
      if (item.member.member_id === memberId) {
        return array
      }

      if (item.partners.length > 0) {
        const foundArray = findParentArrayRecursive(
          item.partners[0].children,
          memberId
        )

        if (foundArray) {
          return foundArray
        }
      }
    }
    return null
  }

  return findParentArrayRecursive(data, memberId)
}

function findAuntsUnclesChildren(data) {
  const auntsUnclesMomsSide = data.find(
    (item) => item.title === 'auntsuncles_moms_side'
  )

  const auntsUnclesDadsSide = data?.find(
    (item) => item.title === 'auntsuncles_dads_side'
  )

  const filteredData = [
    ...(auntsUnclesMomsSide?.data ?? []),
    ...(auntsUnclesDadsSide?.data ?? []),
  ]

  const auntsUnclesChildren = []

  for (const item of filteredData) {
    if (item?.partners?.length > 0) {
      const { children } = item?.partners?.[0]

      if (children && children.length > 0) {
        auntsUnclesChildren.push(...children)
      }
    }
  }

  return auntsUnclesChildren
}

const onlySpacesChecker = (string) => string?.trim()?.length === 0

const getTwinsCount = (data, relationType) => {
  if (!Array.isArray(data)) return []

  const newData = []
  const counters = {}

  data.forEach((item) => {
    const relationship = item?.relationship_to_proband ?? item?.relationship

    if (!counters[relationship]) {
      counters[relationship] = 0
    }

    newData.push({
      ...item,
      index: counters[relationship] + 1,
      relationType: relationship,
    })

    counters[relationship] += 1
  })

  return newData
}

const getMemberTypeData = (currentMember, basicFamilyMembers, isOnboarding) => {
  switch (currentMember?.relationship_to_proband) {
    case RELATION_TYPE.FATHER:
      const getFatherData = () => {
        if (isOnboarding) {
          const parentsOnboarding = basicFamilyMembers.find(
            (item) => item.title === 'parents'
          )

          return parentsOnboarding?.fatherSiblings
        }

        const auntsUnclesDadPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'auntsuncles_dads_side'
        )

        return auntsUnclesDadPostOnboarding?.data
      }

      return {
        id: RELATION_ID.IS_DAD,
        twins: getTwinsCount(getFatherData(), RELATION_TYPE.FATHER),
      }
    case RELATION_TYPE.MOTHER:
      const getMotherData = () => {
        if (isOnboarding) {
          const parentsOnboarding = basicFamilyMembers.find(
            (item) => item.title === 'parents'
          )

          return parentsOnboarding?.motherSiblings
        }

        const auntsUnclesMomPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'auntsuncles_moms_side'
        )

        return auntsUnclesMomPostOnboarding?.data
      }

      return {
        id: RELATION_ID.IS_MOM,
        twins: getTwinsCount(getMotherData(), RELATION_TYPE.MOTHER),
      }

    case RELATION_TYPE.DAUGHTER:
    case RELATION_TYPE.SON:
      const getSonsData = () => {
        if (isOnboarding) {
          const childrenOnboarding = basicFamilyMembers.find(
            (item) => item.title === 'children'
          )

          return childrenOnboarding?.data
        }

        const probandPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'you_and_your_children'
        )

        return probandPostOnboarding?.data[0]?.partners[0]?.children
      }

      return {
        id: RELATION_ID.IS_CHILD_FROM_PROBAND,
        twins: getTwinsCount(getSonsData(), RELATION_TYPE.DAUGHTER),
      }

    case RELATION_TYPE.SISTER:
    case RELATION_TYPE.BROTHER:
      const getBrothersData = () => {
        if (isOnboarding) {
          const siblingsOnboarding = basicFamilyMembers.find(
            (item) => item.title === 'siblings'
          )

          return siblingsOnboarding?.data
        }

        const siblingsPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'brothers_and_sisters'
        )

        const probandPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'you_and_your_children'
        )

        const probandPostOboardingData =
          probandPostOnboarding?.data[0]?.member ??
          probandPostOnboarding?.data[0]

        return [...siblingsPostOnboarding?.data, probandPostOboardingData]
      }

      return {
        id: RELATION_ID.IS_BROTHER_OR_SISTER,
        twins: getTwinsCount(getBrothersData(), RELATION_TYPE.SISTER),
      }

    case RELATION_TYPE.PROBAND:
      const getProbandData = () => {
        if (isOnboarding) {
          return basicFamilyMembers[0]?.data
        }

        const brothersPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'brothers_and_sisters'
        )

        return brothersPostOnboarding?.data
      }

      return {
        id: RELATION_ID.IS_PROBAND,
        twins: getTwinsCount(getProbandData(), RELATION_TYPE.PROBAND),
      }

    case RELATION_TYPE.PATERNAL_UNCLE:
    case RELATION_TYPE.PATERNAL_AUNT:
      const getPaternalAuntsUnclesData = () => {
        const auntsUnclesDadsSide = basicFamilyMembers?.find(
          (item) => item.title === 'auntsuncles_dads_side'
        )

        if (isOnboarding) {
          const fatherOnboardingData = auntsUnclesDadsSide?.parentsData?.find(
            (item) =>
              item.member?.relationship_to_proband === 'father' ??
              item?.relationship_to_proband === 'father'
          )

          return [...auntsUnclesDadsSide?.data, fatherOnboardingData]
        }

        const parentsPostOnboardingData = basicFamilyMembers.find(
          (item) => item.title === 'parents'
        )

        const fatherPostOnboardingData = parentsPostOnboardingData?.data?.find(
          (item) =>
            item?.member?.relationship_to_proband === 'father' ||
            item?.relationship_to_proband === 'father'
        )

        return [...auntsUnclesDadsSide?.data, fatherPostOnboardingData]
      }

      return {
        id: RELATION_ID.IS_AUNT_OR_UNCLE_DAD,
        twins: getTwinsCount(
          getPaternalAuntsUnclesData(),
          RELATION_TYPE.PATERNAL_UNCLE
        ),
      }
    case RELATION_TYPE.MATERNAL_UNCLE:
    case RELATION_TYPE.MATERNAL_AUNT:
      const getMaternalAuntsUnclesData = () => {
        const auntsUnclesMomsSide = basicFamilyMembers.find(
          (item) => item.title === 'auntsuncles_moms_side'
        )

        if (isOnboarding) {
          const onboardingMotherData = auntsUnclesMomsSide?.parentsData?.find(
            (item) =>
              item.member?.relationship_to_proband === 'mother' ??
              item?.relationship_to_proband === 'mother'
          )

          return [...auntsUnclesMomsSide?.data, onboardingMotherData]
        }

        const parentsPostOnboardingData = basicFamilyMembers.find(
          (item) => item.title === 'parents'
        )

        const motherPostOnboardingData = parentsPostOnboardingData?.data?.find(
          (item) =>
            item?.member?.relationship_to_proband === 'mother' ||
            item?.relationship_to_proband === 'mother'
        )

        return [...auntsUnclesMomsSide?.data, motherPostOnboardingData]
      }

      return {
        id: RELATION_ID.IS_AUNT_OR_UNCLE_MOM,
        twins: getTwinsCount(
          getMaternalAuntsUnclesData(),
          RELATION_TYPE.MATERNAL_UNCLE
        ),
      }

    case RELATION_TYPE.GRAND_DAUGHTER:
    case RELATION_TYPE.GRAND_SON:
    case RELATION_TYPE.GREAT_GRAND_SON:
    case RELATION_TYPE.GREAT_GRAND_DAUGHTER:
      const getGrandBrothersData = () => {
        if (isOnboarding) {
          const childrenOnboarding = basicFamilyMembers.find(
            (item) => item.title === 'children'
          )

          return findParentArrayForMember(
            childrenOnboarding?.data,
            currentMember?.member_id
          )
        }

        const probandPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'you_and_your_children'
        )

        return findParentArrayForMember(
          probandPostOnboarding?.data[0]?.partners[0]?.children,
          currentMember?.member_id
        )
      }

      return {
        id: RELATION_ID.IS_GRAND_SON_OR_DAUGHTER,
        twins: getTwinsCount(
          getGrandBrothersData(),
          RELATION_TYPE.GRAND_DAUGHTER
        ),
      }

    case RELATION_TYPE.NIECE:
    case RELATION_TYPE.NEPHEW:
    case RELATION_TYPE.GRAND_NIECE:
    case RELATION_TYPE.GRAND_NEPHEW:
      const getNiecesBrothersData = () => {
        if (isOnboarding) {
          const siblingsOnboarding = basicFamilyMembers.find(
            (item) => item.title === 'siblings'
          )

          return findParentArrayForMember(
            siblingsOnboarding?.data,
            currentMember?.member_id
          )
        }

        const siblingsPostOnboarding = basicFamilyMembers.find(
          (item) => item.title === 'brothers_and_sisters'
        )

        return findParentArrayForMember(
          siblingsPostOnboarding?.data[0]?.partners[0]?.children,
          currentMember?.member_id
        )
      }

      return {
        id: RELATION_ID.IS_NIECE_OR_NEPHEW,
        twins: getTwinsCount(getNiecesBrothersData(), RELATION_TYPE.NIECE),
      }

    case RELATION_TYPE.FIRST_COUSIN:
    case RELATION_TYPE.FIRST_COUSIN_REMOVED:
      const getCousinBrothersData = () => {
        const auntsUnclesChildren = findAuntsUnclesChildren(basicFamilyMembers)

        return findParentArrayForMember(
          auntsUnclesChildren,
          currentMember?.member_id
        )
      }

      return {
        id: RELATION_ID.IS_FIRST_COUSIN,
        twins: getTwinsCount(
          getCousinBrothersData(),
          RELATION_TYPE.FIRST_COUSIN
        ),
      }

    default:
      return {
        id: '',
        twins: [],
      }
  }
}

const handleTwinsFormat = (siblingsExistingData, sibling) => {
  let relationship = {}

  const siblingRelationshipToProband =
    siblingsExistingData?.member?.relationship_to_proband?.replace(' ', '_') ||
    siblingsExistingData?.relationship_to_proband?.replace(' ', '_')

  if (
    ![
      RELATION_ID.IS_DAD,
      RELATION_ID.IS_MOM,
      RELATION_TYPE.FATHER,
      RELATION_TYPE.MOTHER,
    ].includes(siblingRelationshipToProband)
  ) {
    const relationShipCount =
      siblingsExistingData?.member?.relationshipCount?.toString() ??
      siblingsExistingData?.relationshipCount?.toString() ??
      siblingsExistingData?.index

    relationship = {
      relationship: `${capitalizeFirstLetter(
        siblingRelationshipToProband
      )} ${relationShipCount}`,
    }
  } else {
    relationship = {
      relationship: `${capitalizeFirstLetter(siblingRelationshipToProband)}`,
    }
  }

  const siblingWithRelationship = {
    ...sibling,
    ...relationship,
  }

  return siblingWithRelationship
}

const modifySiblingsListHandler = (
  siblingsList = [],
  currentMember,
  basicFamilyMembers,
  isOnboarding
) => {
  const memberTypeData = getMemberTypeData(
    currentMember,
    basicFamilyMembers,
    isOnboarding
  )

  const modifiedSiblingList = siblingsList?.map((sibling) => {
    if (!onlySpacesChecker(sibling?.name)) return sibling

    const siblingsExistingData = memberTypeData?.twins?.find(
      (siblingData) =>
        siblingData?.member?.member_id === sibling?.member_id ||
        siblingData?.member_id === sibling?.member_id
    )

    if (!siblingsExistingData) return null

    const formatedTwins = handleTwinsFormat(siblingsExistingData, sibling)

    return formatedTwins
  })

  return modifiedSiblingList
}

export default modifySiblingsListHandler
