import ModalLoadingView from './ModalLoading.view'

export const ModalLoadingLogic = ({
  colorSettings,
  isVisible,
  loadingTitle,
}) => (
    <ModalLoadingView
      colorSettings={colorSettings}
      isVisible={isVisible}
      loadingTitle={loadingTitle}
    />
  )
