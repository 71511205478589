import { Component, createRef } from 'react'
import { View, Text, TextInput, Platform, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { isNotValidAOD } from '$screens/main/family/edit_profile/_components/personal_info/components/_utils/Utils.js'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import { IS_WEB } from '$constants/Platforms'
import DropdownComponent from '$components/DropdownComponent/DropdownComponent'
import { isAgeValid } from '$screens/main/family/edit_profile/_utils/Utils'
import { handleDisplayShorterAgeUnits } from '$api/_utils/Utils'

const AGE_OF_DEATH = 'ageOfDeath'
const AGE_OF_DEATH_STRING = 'ageOfDeathString'
const CAUSE_OF_DEATH = 'causeOfDeath'

class DeceasedFields extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  state = {
    memberProfile: this.memberProfile,
    profileData: this.profileData,
    visible: this.props.visible,
    causeOfDeath: null,
    ageOfDeath: 0,
    tempAgeOfDeath: null,
    showDropdown: false,
    isSameValueAges: false,
  }

  bottomSheetRef = this.props.bottomSheetRef ?? createRef()

  _ageOfDeath_ = this.state.ageOfDeath

  constructor(props) {
    super(props)

    const { profileData } = this.state
    let ageOfDeath = 0

    if (profileData?.ageOfDeath !== '') {
      this._ageOfDeath_ = ageOfDeath = parseInt(profileData?.ageOfDeath, 0)
    } else {
      this._ageOfDeath_ = ageOfDeath = 0
    }

    this.state = {
      ...this.state,
      ageOfDeath,
      causeOfDeath: profileData?.causeOfDeath,
    }
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const { visible } = newProps

    const { profileData } = memberProfile
    let ageOfDeath = 0

    if (profileData?.ageOfDeath !== '') {
      ageOfDeath = parseInt(profileData?.ageOfDeath, 0)
    } else {
      ageOfDeath = 0
    }

    const newState = {
      ...prevState,
      memberProfile,
      profileData,
      causeOfDeath: profileData?.causeOfDeath,
      visible,
    }

    /* Note: No need to update ageOfDeath! */

    return newState
  }

  componentDidMount() {
    const { ageOfDeath, currentAge } = this.profileData
    const { handleSameValueAges } = this.props

    if (!currentAge) return
    if (ageOfDeath === currentAge) this.setState({ isSameValueAges: true })
    handleSameValueAges(true)
  }

  didUpdateField = (key, val) => {
    const newState = this.state
    this._ageOfDeath_ = newState[key] = val
    this.setState(newState)

    this.parentController?._updateState_(key, val)
  }

  updateAgeOfDeath = (value) => {
    if (value >= 0) {
      this.didUpdateField(AGE_OF_DEATH, `${value}`)
    }
  }

  clearAgeOnFocus = () => {
    // Copy ageOfDeath to tempAgeOfDeath and temporarily set ageOfDeath to blank,
    const { ageOfDeath } = this.state
    this.setState({
      ageOfDeath: '',
      tempAgeOfDeath: ageOfDeath,
    })
  }

  onEndEditingAge = () => {
    const prevState = this.state

    let updatedAgeOfDeath = prevState.tempAgeOfDeath

    // let memberProfile = this.memberProfile;
    const { memberProfile, isSameValueAges } = this.state

    const memberDisease =
      memberProfile?.healthHistoryData?.diseasesData?.diseases
    const currentAge = memberProfile?.profileData?.currentAge
    const currentAgeString = memberProfile?.profileData?.currentAgeString
    const updatedAgeOfDeathString = `${Number(
      this._ageOfDeath_
    )}${handleDisplayShorterAgeUnits(this.props.ageOfDeceasedType)}`

    if (!prevState?.ageOfDeath || prevState?.ageOfDeath === '') {
      // If ageOfDeath is null or blank, use previous valid value.
      updatedAgeOfDeath = this._ageOfDeath_ = prevState?.tempAgeOfDeath
    } else if (
      isNotValidAOD(
        this._ageOfDeath_,
        memberDisease,
        true,
        updatedAgeOfDeathString
      )
    ) {
      // Double check if ageOfDeath is valid. If not, use previous valid value.
      updatedAgeOfDeath = this._ageOfDeath_ = prevState?.tempAgeOfDeath
    } else if (Number(this._ageOfDeath_ > 130)) {
      // Limit valid age from 0 to 130 only
      showDefaultAlert(
        i18n.t('oops').default,
        i18n.t('current_age_must_not_be_greater_than_130').default
      )
      updatedAgeOfDeath = prevState?.tempAgeOfDeath
    } else if (!isSameValueAges) {
      if (
        (currentAge || currentAge !== '') &&
        Number(this._ageOfDeath_) > currentAge &&
        currentAge !== 0
      ) {
        return showDefaultAlert(
          i18n.t('oops').default,
          i18n.t('age_of_death_must_not_be_greater_than_current_age').default
        )
      }

      updatedAgeOfDeath = this._ageOfDeath_
    } else {
      updatedAgeOfDeath = this._ageOfDeath_
    }

    // Make sure that updatedAgeOfDeath is a Number
    this.didUpdateField(
      AGE_OF_DEATH_STRING,
      `${Number(updatedAgeOfDeath)}${handleDisplayShorterAgeUnits(
        this.props.ageOfDeceasedType
      )}`
    )
    this.didUpdateField(AGE_OF_DEATH, `${Number(updatedAgeOfDeath)}`)
  }

  renderChoiceDeceasedAgeButton = () => {
    const { showDropdown } = this.state
    const availableDiagnosisValues = ['days', 'weeks', 'months', 'years']

    const handlePressButton = (event) => {
      this.parentController?._updateState_(
        AGE_OF_DEATH_STRING,
        `${this.state.ageOfDeath}${handleDisplayShorterAgeUnits(event)}`
      )

      this.props.handleAgeUnitChange(event)

      this.setState({ showDropdown: false })
    }

    const handleShowingChoice = () => {
      if (IS_WEB()) return this.setState({ showDropdown: !showDropdown })

      if (!this.bottomSheetRef.current.expand()) {
        return this.bottomSheetRef.current.expand()
      }

      return this.bottomSheetRef.current.close()
    }

    return (
      <View styles={section_styles.choiceOfDiagnosisAgeContainer}>
        <TouchableOpacity
          style={section_styles.choiceOfDiagnosisAgeButton}
          onPress={handleShowingChoice}
        >
          <Text
            style={[
              section_styles.choiceOfDiagnosisAgeButtonText,
              { color: this.colorSettings?.linked_text || 'black' },
            ]}
          >
            ({i18n.t(this.props.ageOfDeceasedType).default})
          </Text>
        </TouchableOpacity>
        {IS_WEB() && (
          <DropdownComponent
            data={availableDiagnosisValues}
            isShown={showDropdown}
            onPress={handlePressButton}
            selectedItem={this.props.ageOfDeceasedType}
          />
        )}
      </View>
    )
  }

  render() {
    if (!this.state.visible) return null

    const { ageOfDeath, causeOfDeath } = this.state

    const ageOfDeathSection = (
      <View style={section_styles.contentItem}>
        <View
          style={[
            section_styles.contentItemLabelTextContainer,
            {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            },
          ]}
        >
          <Text
            style={[
              section_styles.contentItemLabelText,
              {
                color: this.colorSettings?.text_input_label_color_1,
                marginRight: 15,
              },
            ]}
            onPress={() => {
              this.ageOfDeathInput.focus()
            }}
          >
            {i18n.t('age_of_death').default}
          </Text>
          {this.renderChoiceDeceasedAgeButton()}
        </View>

        <View
          style={[
            section_styles.contentItemTextInputContainer,
            {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            },
          ]}
        >
          <TextInput
            nativeID="web_text_input"
            ref={(input) => (this.ageOfDeathInput = input)}
            style={[
              section_styles.contentItemTextInput,
              { color: this.colorSettings?.text_input_color_1 },
            ]}
            maxLength={3}
            placeholder="0"
            placeholderTextColor={
              this.colorSettings?.text_input_placeholder_color_1 ||
              'rgba(74,74,74,0.5)'
            }
            keyboardType="number-pad"
            onFocus={() => this.clearAgeOnFocus()}
            onEndEditing={() => this.onEndEditingAge()}
            onBlur={() =>
              Platform.OS === 'web' ? this.onEndEditingAge() : null
            }
            onChangeText={(val) => this.updateAgeOfDeath(val)}
            value={`${ageOfDeath}`}
          />
        </View>
      </View>
    )

    const causeOfDeathSection = (
      <View style={[section_styles.contentItem, { zIndex: -1 }]}>
        <View style={section_styles.contentItemLabelTextContainer}>
          <Text
            style={[
              section_styles.contentItemLabelText,
              { color: this.colorSettings?.text_input_label_color_1 },
            ]}
            onPress={() => {
              this.causeOfDeathInput.focus()
            }}
          >
            {i18n.t('cause_of_death').default}
          </Text>
        </View>

        <View style={section_styles.contentItemTextInputContainer}>
          <TextInput
            nativeID="web_text_input"
            ref={(input) => (this.causeOfDeathInput = input)}
            style={[
              section_styles.contentItemTextInput,
              { color: this.colorSettings?.text_input_color_1 },
            ]}
            placeholder={i18n.t('unknown').default}
            placeholderTextColor={
              this.colorSettings?.text_input_placeholder_color_1 ||
              'rgba(74,74,74,0.5)'
            }
            autoCorrect={false}
            autoCapitalize="none"
            onChangeText={(val) => this.didUpdateField(CAUSE_OF_DEATH, val)}
            value={`${causeOfDeath}`}
          />
        </View>
      </View>
    )

    return (
      <View>
        <View style={section_styles.contentSeparatorLine} />
        {ageOfDeathSection}
        <View style={section_styles.contentSeparatorLine} />
        {causeOfDeathSection}
      </View>
    )
  }
}

export default DeceasedFields
