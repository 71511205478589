import AsyncStorage from '@react-native-async-storage/async-storage'

import { isEmptyObject } from '$api/_utils/Utils.js'

class SimpleStorage {
  getItem = (key, jsonify = false) =>
    new Promise((resolve, reject) => {
      this._getItem(key)
        .then((response) => {
          if (jsonify) {
            return resolve(JSON.parse(response))
          }
          return resolve(response)
        })
        .catch(reject)
    })

  setItem = (key, value, stringify = true) => {
    if (stringify) {
      this._setItem(key, JSON.stringify(value))
    } else {
      this._setItem(key, value)
    }
  }

  removeItem = (key) => {
    this._removeItem(key)
  }

  _setItem = async (key, value) => {
    if (!key || key.trim() === '') return

    if (!value) return

    try {
      // await AsyncStorage.setItem("@App:" + key, JSON.parse(JSON.stringify(value)))
      await AsyncStorage.setItem(`@App:${key}`, value)
    } catch (error) {
      console.log(
        '🚀 \n\n file: SimpleStorage.js:42 \n\n SimpleStorage \n\n _setItem= \n\n error:',
        error
      )
    }
  }

  _getItem = async (key) => {
    if (!key || key.trim() === '') return

    let value = null

    try {
      value = (await AsyncStorage.getItem(`@App:${key}`)) || null
    } catch (error) {
      console.log(
        '🚀 \n\n file: SimpleStorage.js:55 \n\n SimpleStorage \n\n _getItem= \n\n error:',
        error
      )
    }

    return value
  }

  _removeItem = async (key) => {
    if (!key || key.trim() === '') return

    try {
      await AsyncStorage.removeItem(`@App:${key}`)
    } catch (error) {
      console.log(
        '🚀 \n\n file: SimpleStorage.js:70 \n\n SimpleStorage \n\n _removeItem= \n\n error:',
        error
      )
    }
  }
}

export default SimpleStorage
