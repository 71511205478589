import { View, Text, Image, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'
import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

import {
  capitalizeFirstLetter,
  getGenderIcon,
  getGenderIconBorderRadius,
} from '$api/_utils/Utils.js'

import AddRemoveTwinGroupButton from './AddRemoveTwinGroupButton'

import nameHandler from './helpers'

const trashIcon = require('$assets/images/new_assets/icon-trash.png')

export default function TwinSectionCollapseView({
  siblingsList,
  relationshipData,
  onPressAdd = function () {},
  onPressRemove = function () {},
}) {
  const handleCreateTwinsList = () => {
    const twinGroup = relationshipData?.twinGroup
    const twinMembers = twinGroup?.twinMembers ?? []

    const twinsList = []

    for (const i in twinMembers) {
      const member = twinMembers[i]
      const twinType = `${member?.twin_type ?? ''}`.toLowerCase()
      const { memberName, nameTextStyle } = nameHandler(member, siblingsList)

      if (i > 0) {
        twinsList.push(
          <View key={`ListItemsSeparator-${i}`} style={{ height: 5 }} />
        )
      }

      twinsList.push(
        <View
          key={`${member?.id}`}
          style={custom_styles.memberListItemContainer}
        >
          <View style={custom_styles.memberListItemContent}>
            <Image
              source={getGenderIcon(member?.gender)}
              style={[
                getGenderIconBorderRadius(member?.gender),
                custom_styles.memberListItemImage,
              ]}
            />

            <View style={{ marginVertical: 5, flex: 1.0 }}>
              <Text style={nameTextStyle}>{memberName}</Text>
              <Text style={custom_styles.memberListItemSubtitleText}>
                {i18n.t(twinType)?.capitalize ??
                  capitalizeFirstLetter(twinType)}
              </Text>
            </View>

            <TouchableOpacity
              style={custom_styles.memberListDeleteIcon}
              onPress={() => onPressRemove(member?.id)}
            >
              <Image source={trashIcon} style={custom_styles.deleteTwinIcon} />
            </TouchableOpacity>
          </View>
        </View>
      )
    }

    return twinsList
  }

  const hasSiblings = siblingsList?.length > 0
  const ListItems = handleCreateTwinsList()

  const ListContainer = () => {
    if (ListItems?.length === 0) return null

    return (
      <View key="ListContainer" style={custom_styles.memberListContainer}>
        {ListItems}
      </View>
    )
  }

  return (
    <View>
      <View
        style={[
          custom_styles.groupContentItem,
          {
            marginVertical: 0,
            borderBottomWidth: 0,
          },
        ]}
      >
        <View
          style={[
            custom_styles.groupContentItemLabelTextContainer,
            { marginHorizontal: 0, flex: 1.0 },
          ]}
        >
          {!hasSiblings && (
            <Text style={custom_styles.groupContentItemHelperText}>
              {
                i18n.t(
                  'after_you_add_other_siblings_for_this_person_you_will_be_able_to_designate_which_is_the_twin'
                )?.default
              }
            </Text>
          )}
        </View>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <AddRemoveTwinGroupButton
          siblingsList={siblingsList}
          onPressAdd={onPressAdd}
          //   onPressRemove={onPressRemove}
          //   twinGroup={twinGroup}
        />
      </View>

      <ListContainer />
    </View>
  )
}
