import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  Platform,
  StyleSheet,
} from 'react-native'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { connect } from 'react-redux'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'
import LoadingView from '$constants/LoadingView.js'

import { i18n } from '$localization/config.js'
import { IS_WEB, IS_MOBILE } from '$constants/Platforms.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import {
  showDefaultAlert,
  showGenericError,
  showGenericServerError,
} from '$navigation/_utils/NavigationUtils.js'

import {
  storeCurrentMember,
  clearCurrentMember,
} from '$redux/member_profile/actions.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'

const styles = StyleSheet.create({
  resendCodeButtonContainer: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  resendCodeButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  resendCodeButtonText: {
    color: '#fff',
    fontFamily: 'montserrat',
    fontSize: 15,
    textAlign: 'center',
  },
})

const inputAccessoryViewID = 'SignupFamilyInvitePhoneCode'

const DefaultResendCodeTimer = 240

const CHARACTER_SPACING = Array(6).join(' ')

const getScrollViewHeight = () => DefaultFullHeight()

class SignupFamilyInvitePhoneCode extends Component {
  mainTextInput = null

  resendCodeInterval = null

  state = {
    currentMember: this.props.currentMember,
    showLoadingView: false,
    mainTextValue: '',
    mainTextValueLocked: '',
    didTappedResendButton: false,
    resendCodeTimer: DefaultResendCodeTimer,
    scrollViewHeight: getScrollViewHeight(),
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    // Reset resend code interval counter
    clearInterval(this.resendCodeInterval)
    this.resendCodeInterval = null

    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    this.setState({ scrollViewHeight: getScrollViewHeight() })
  }

  verifyPhoneCode = async () => {
    /* Prepare API request to verify code. */

    // var currentMember = this.state.currentMember
    const { currentMember, mainTextValueLocked } = this.state
    const { _storeCurrentMember_, navigation, colorSettings } = this.props

    const memberID = currentMember?.member_id
    const verificationCode = this.getFormattedValue(mainTextValueLocked)

    this.setState({ showLoadingView: true })

    const verifyPhoneCodePayload = {
      path: 'invite/verify/',
      method: 'post',
      body: {
        member_id: memberID,
        verification_code: verificationCode,
      },
      pageDetails: {
        page: 'SignupFamilyInvitePhoneCode.js',
      },
    }

    const response = await ApiFetchHandler(verifyPhoneCodePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      const status = response?.status
      switch (status) {
        case 500:
          showGenericServerError()
          break

        case 404:
          showDefaultAlert(
            i18n.t('server_error')?.default,
            i18n.t(
              'the_code_you_entered_is_not_valid__please_try_again_or_ask_the_family_member_who_invited_you_to_send_you_a_new_invite'
            )?.default
          )
          break

        default:
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          break
      }
      return
    }

    this.setState({ showLoadingView: false })

    const data = response ?? {}

    /* 
    Sample response data
    Object {
      "dialing_code": "1",
      "first_name": "Sister 1",
      "inviter_id": 9011,
      "phone_number": "7065649572",
    }
    */

    const member = {
      ...currentMember,
      ...data,
    }

    /* Update current member data on Redux store */
    _storeCurrentMember_(member)

    /* Proceed to Sign Up screen */

    navigation.navigate('SignupMainScreen')
  }

  toggleNextButton = () => {
    const isValid = this.validateInput()
    return !isValid
  }

  toggleNextButtonColor = () => {
    const { colorSettings } = this.props

    let style = {
      backgroundColor: colorSettings?.btn_filled_disabled_1 ?? 'white',
      borderColor: colorSettings?.btn_filled_disabled_1,
    }

    if (this.validateInput()) {
      style = {
        backgroundColor: colorSettings?.btn_filled_enabled_1 ?? 'white',
        borderColor: colorSettings?.btn_filled_enabled_1,
      }
    }

    return style
  }

  nextButtonAction = () => {
    /* Dismiss main text input */
    this.mainTextInput?.blur()

    this.verifyPhoneCode()
  }

  validateInput = () => {
    const textValue = this.state.mainTextValueLocked ?? ''
    const newVal = this.getFormattedValue(textValue)
    return newVal.length >= 6
  }

  getFormattedValue = (textValue) => {
    const val = textValue.replace(/ /g, '').split('')
    const newVal = val.join('')
    return newVal
  }

  onChangeMainTextInput = (e) => {
    const val = e.replace(/ /g, '').split('')
    const newVal = val.join(CHARACTER_SPACING)

    if (val.length <= 6) {
      this.setState({
        mainTextValue: newVal,
        mainTextValueLocked: newVal,
      })
    }
  }

  render() {
    const { colorSettings } = this.props
    const textValue = this.state.mainTextValueLocked

    let textInputCharsType = 'number-pad'
    if (Platform.OS === 'web') {
      textInputCharsType = 'default'
    }

    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: colorSettings?.onboarding_bgcolor,
          },
        ]}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={[
            global_styles.contentContainer,
            {
              height: this.state.scrollViewHeight,
            },
          ]}
        >
          <View
            style={[
              global_styles.content,
              {
                justifyContent: 'center',
                alignItems: 'center',
                flex: 0.9,
              },
            ]}
          >
            <Text
              style={[
                global_styles.titleText,
                {
                  color: colorSettings?.text_input_label_color_2,
                },
              ]}
            >
              {i18n.t('we_sent_you_a_6-digit_code')?.default}
            </Text>

            <View style={{ marginVertical: 30, width: '80%' }}>
              <TextInput
                nativeID="text_input_web"
                style={[
                  global_styles.mainTextInput,
                  {
                    color: colorSettings?.text_input_color_2 ?? 'black',
                    fontSize: 24,
                    height: 60,
                  },
                ]}
                ref={(ref) => (this.mainTextInput = ref)}
                inputAccessoryViewID={inputAccessoryViewID}
                selectionColor={colorSettings?.text_input_color_2}
                keyboardType={IS_MOBILE() ? 'number-pad' : 'default'}
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={31}
                onChangeText={(val) => this.onChangeMainTextInput(val)}
                value={textValue}
              />

              <View
                style={{
                  borderBottomColor:
                    colorSettings?.text_input_placeholder_color_2,
                  borderBottomWidth: 1,
                  minWidth: 300,
                  alignSelf: 'center',
                }}
              />

              {/* Overlapping button */}
              <TouchableOpacity
                activeOpacity={1.0}
                onPress={() => this.mainTextInput?.focus()}
                style={global_styles.buttonOverTextInput}
              />
            </View>
          </View>

          <View style={{ flex: 0.1 }} />
        </KeyboardAwareScrollView>

        <View style={global_styles.nextButtonContainer}>
          <TouchableOpacity
            style={[global_styles.nextButton, this.toggleNextButtonColor()]}
            disabled={this.toggleNextButton()}
            onPress={() => this.nextButtonAction()}
          >
            <Text
              style={[
                global_styles.nextButtonText,
                {
                  color: colorSettings?.btn_filled_text_1 ?? 'black',
                },
              ]}
            >
              {i18n.t('next')?.default}
            </Text>
          </TouchableOpacity>
        </View>

        <NextButtonAccessoryView
          nativeID={inputAccessoryViewID}
          style={[global_styles.nextButton, this.toggleNextButtonColor()]}
          disabled={this.toggleNextButton()}
          labelColor={colorSettings?.btn_filled_text_1}
          labelText={i18n.t('next')?.title}
          onPress={() => this.nextButtonAction()}
        />

        {this.state.showLoadingView && (
          <LoadingView
            backgroundColor="white"
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        )}
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  const { store, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Proband Store */
    currentMember: memberProfileStore.currentMember,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _storeCurrentMember_: (data) => dispatch(storeCurrentMember(data)),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFamilyInvitePhoneCode)
