import { useCallback, useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'
import PropTypes from 'prop-types'

import LabelField from '../LabelField'
import styles from './styles'
import { i18n } from '../../../../../localization/config.js'
import { ButtonSubView } from '../../../../../components/button_sub'
import { DecreaseFontSize, LabelHandler } from '../../../helpers'

export default function MultiSelectField({
  masterQuestion,
  colorSettings,
  answers,
  saveCompletedSurveyAnswer,
}) {
  const {
    question_label: questionLabel,
    master_question_choices: masterQuestionChoices,
  } = masterQuestion

  const [selectedItem, setSelectedValues] = useState([])

  useEffect(() => {
    const commonValues = masterQuestionChoices.filter((choice) =>
      answers.some((answer) => answer.master_question_choice_id === choice.id)
    )
    setSelectedValues(commonValues)
  }, [])

  const checkItemIfFound = (item) => {
    const found = selectedItem.some((selectItem) => selectItem?.id === item?.id)
    return found
  }

  const styleHandler = useCallback((item) => {
    const selectedItemExist = checkItemIfFound(item)

    if (selectedItemExist) {
      return {
        backgroundColor:
          colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)',
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  })

  const handleSelectedItem = (item) => {
    const selectedItemExist = checkItemIfFound(item)
    let newSelectedValues

    if (selectedItemExist) {
      newSelectedValues = selectedItem.filter((value) => value.id !== item.id)
    } else {
      newSelectedValues = [...selectedItem, item]
    }

    setSelectedValues(newSelectedValues)

    const payload = {
      field_type: 'select_all_that_apply',
      master_question_id: masterQuestion.id,
      master_question_choice_id: item.id,
      answer: null,
    }

    saveCompletedSurveyAnswer(payload)
  }

  return (
    <View style={styles.container}>
      {LabelHandler({
        questionLabel: i18n.t(questionLabel).default || questionLabel,
        colorSettings,
        masterQuestion,
      })}

      {masterQuestionChoices.map((item) => {
        const translation_key = item.translation_key + '.choice'
        let choice_label_translation = i18n.t(translation_key)
        if (
          choice_label_translation === translation_key ||
          !choice_label_translation
        ) {
          choice_label_translation = item.choice
        }

        return (
          <ButtonSubView
            key={uuid.v4()}
            colorSettings={colorSettings}
            onPress={() => handleSelectedItem(item)}
            styleHandler={() => styleHandler(item)}
            fontSizeHandler={() => DecreaseFontSize(choice_label_translation)}
            title={choice_label_translation}
          />
        )
      })}
    </View>
  )
}

MultiSelectField.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.shape({
    text_input_title_color_2: PropTypes.string,
    selectable_btn_active_1: PropTypes.string,
    selectable_btn_border_active_1: PropTypes.string,
    selectable_btn_border_inactive_1: PropTypes.string,
  }),
  answers: PropTypes.isRequired,
  saveCompletedSurveyAnswer: PropTypes.func,
}

MultiSelectField.defaultProps = {
  colorSettings: {
    selectable_btn_active_1: '',
    selectable_btn_border_active_1: '',
  },
  saveCompletedSurveyAnswer: () => {},
}
