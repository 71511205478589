/* React & React Native libraries */
import { Component } from 'react'
import {
  Text,
  View,
  SafeAreaView,
  Dimensions,
  ImageBackground,
  Platform,
  TouchableOpacity,
} from 'react-native'

import { connect } from 'react-redux'

/* Third-party libraries */
import Carousel, { Pagination } from 'react-native-snap-carousel'

/* Custom Components and Constants */
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import RelationshipInfoTutorialPage1 from './pages/RelationshipInfoTutorialPage1.js'
import RelationshipInfoTutorialPage2 from './pages/RelationshipInfoTutorialPage2.js'
import RelationshipInfoTutorialPage3 from './pages/RelationshipInfoTutorialPage3.js'

import { i18n } from '$localization/config.js'
import { IS_WEB, WEB, IS_MOBILE } from '$constants/Platforms.js'

/* Custom Stylesheets */
import global_styles, {
  DefaultFullHeightForWeb,
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'

import styles from './_static/css/main.styles.js'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class RelationshipInfoTutorial extends Component {
  dimensions = Dimensions.get('window')

  screenWidth = this.dimensions.width

  screenHeight = this.dimensions.height

  _carousel = null

  carouselData = [{ key: 0 }, { key: 1 }, { key: 2 }]

  paginationDotStyle = {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginHorizontal: -4,
    paddingHorizontal: 0,
  }

  paginationInactiveDotStyle = {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginHorizontal: -4,
    paddingHorizontal: 0,
  }

  tappableDots = false

  dotsDistance = 6

  autoplay = false

  carouselInterval = 5000

  state = {
    activePaginatorIndex: 0,
    showDoneButton: false,
    scrollViewHeight: getScrollViewHeight(),
  }

  constructor(props) {
    super(props)

    if (IS_WEB()) {
      this.paginationDotStyle = this.paginationInactiveDotStyle = {
        height: 15,
        width: 15,
        borderRadius: 8,
        marginHorizontal: 0,
        paddingHorizontal: 0,
      }

      this.tappableDots = true
      this.autoplay = true
      this.dotsDistance = 15
    }
  }

  componentDidMount() {
    const { setParams } = this.props.navigation
    setParams({ cs: this.props.colorSettings })

    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }

    setTimeout(() => {
      this._carousel?.triggerRenderingHack(0)
    }, 300)
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }
  }

  toggleDoneButtonOpacity = () => {
    const style = {
      opacity: this.state.showDoneButton ? 1.0 : 0,
    }
    return style
  }

  toggleDoneButton = (index = 0) =>
    Number(index) === this.carouselData.length - 1

  renderItem = ({ item, index }) => {
    const { colorSettings } = this.props

    switch (index) {
      case 0:
        return <RelationshipInfoTutorialPage1 colorSettings={colorSettings} />
      case 1:
        return <RelationshipInfoTutorialPage2 colorSettings={colorSettings} />
      case 2:
        return <RelationshipInfoTutorialPage3 colorSettings={colorSettings} />
      default:
        return null
    }
  }

  renderDots = (activeIndex) => {
    const { colorSettings } = this.props

    const layout = this.carouselData.map((screen, i) => (
      <TouchableOpacity
        style={[
          this.paginationDotStyle,
          { marginHorizontal: this.dotsDistance },
        ]}
        key={i}
        onPress={() => {
          if (!this.tappableDots) {
            return
          }
          this._carousel?.stopAutoplay()
          this._carousel?._snapToItem(this._carousel?._getPositionIndex(i))
        }}
      >
        <View
          style={[
            this.paginationDotStyle,
            {
              backgroundColor:
                activeIndex === i
                  ? colorSettings?.pagination_dot_active
                  : colorSettings?.pagination_dot_inactive,
            },
          ]}
        />
      </TouchableOpacity>
    ))

    return layout
  }

  render() {
    const { colorSettings } = this.props

    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: colorSettings?.onboarding_bgcolor,
            ...Platform.select({
              [WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          },
        ]}
      >
        <ImageBackground
          style={{
            backgroundColor: 'transparent',
            flex: 1.0,
            width: '100%',
            ...Platform.select({
              [WEB]: {
                height: getScrollViewHeight(),
              },
            }),
          }}
        >
          <View
            style={[
              global_styles.content,
              {
                backgroundColor: 'transparent',
                flex: 1.0,
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            {/* CAROUSEL */}
            {IS_MOBILE() && (
              <Carousel
                ref={(ref) => (this._carousel = ref)}
                data={this.carouselData}
                sliderWidth={this.screenWidth}
                itemWidth={this.screenWidth}
                onSnapToItem={(index) => {
                  this.setState({
                    activePaginatorIndex: index,
                    showDoneButton: this.toggleDoneButton(index),
                  })
                }}
                inactiveSlideOpacity={0}
                inactiveSlideScale={0.9}
                activeAnimationType="spring"
                activeAnimationOptions={{ friction: 10 }}
                autoplay={this.autoplay}
                autoplayDelay={0}
                autoplayInterval={this.carouselInterval}
                renderItem={this.renderItem}
              />
            )}
            {IS_WEB() && (
              <Carousel
                containerCustomStyle={{ flex: 1 }}
                layout="default"
                ref={(ref) => (this._carousel = ref)}
                data={this.carouselData}
                sliderWidth={Dimensions.get('window').width}
                itemWidth={Dimensions.get('window').width}
                itemHeight={Dimensions.get('window').height}
                onSnapToItem={(index) => {
                  this.setState({
                    activePaginatorIndex: index,
                    showDoneButton: this.toggleDoneButton(index),
                  })
                }}
                inactiveSlideOpacity={0}
                inactiveSlideScale={0.9}
                activeAnimationType="spring"
                activeAnimationOptions={{ friction: 10 }}
                autoplay={this.autoplay}
                autoplayDelay={0}
                autoplayInterval={this.carouselInterval}
                renderItem={this.renderItem}
              />
            )}

            {/* PAGINATION */}
            {IS_MOBILE() && (
              <Pagination
                containerStyle={styles.paginatorContainer}
                dotsLength={this.carouselData.length}
                activeDotIndex={this.state.activePaginatorIndex}
                dotStyle={[
                  styles.paginatorDotStyle,
                  {
                    backgroundColor:
                      colorSettings?.pagination_dot_active ??
                      'rgba(74,74,74,1)',
                  },
                ]}
                inactiveDotStyle={[
                  styles.paginatorDotStyle,
                  {
                    backgroundColor:
                      colorSettings?.pagination_dot_inactive ??
                      'rgba(74,74,74,0.5)',
                  },
                ]}
                inactiveDotScale={1.0}
                carouselRef={this._carousel}
              />
            )}
            {IS_WEB() && (
              <Pagination
                containerStyle={styles.paginatorContainer}
                dotsLength={this.carouselData.length}
                activeDotIndex={this.state.activePaginatorIndex}
                dotColor={colorSettings?.pagination_dot_active}
                inactiveDotColor={colorSettings?.pagination_dot_inactive}
                dotStyle={[
                  styles.paginatorDotStyle,
                  {
                    backgroundColor:
                      colorSettings?.pagination_dot_active ??
                      'rgba(74,74,74,1)',
                  },
                ]}
                inactiveDotStyle={[
                  styles.paginatorDotStyle,
                  {
                    backgroundColor:
                      colorSettings?.pagination_dot_inactive ??
                      'rgba(74,74,74,0.5)',
                  },
                ]}
                inactiveDotScale={1}
                carouselRef={this._carousel}
                tappableDots={this.tappableDots}
                renderDots={this.renderDots}
              />
            )}

            {/* DONE BUTTON */}
            <View
              style={[
                styles.doneButtonContainer,
                this.toggleDoneButtonOpacity(),
              ]}
            >
              <TouchableOpacity
                disabled={!this.state.showDoneButton}
                onPress={() => this.props.navigation.pop()}
                style={styles.doneButton}
              >
                <Text
                  style={[
                    styles.doneButtonText,
                    {
                      color: colorSettings?.text_input_label_color_2,
                    },
                  ]}
                >
                  {i18n.t('done')?.title}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(RelationshipInfoTutorial)
