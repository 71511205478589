import { Component } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'

import DarkGraySwitch from '$components/custom-switches/DarkGraySwitch'

import { IS_WEB, IS_MOBILE } from '$constants/Platforms.js'
import { i18n } from '$localization/config.js'

import {
  isEmptyObject,
  getParentBloodRelationTypes,
} from '$api/_utils/Utils.js'

import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

const RelationshipTypes = getParentBloodRelationTypes()
const PARENT_SPECIFIC = 'parentSpecific'
const RELATIONSHIP_INFO = 'relationshipInfo'
const RELATIONSHIP_NAME = 'name'
const RELATIONSHIP_DEFINITION = 'definition'

class BloodRelatedParentsSection extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  currentMember = this.props.currentMember

  relationshipData = this.props.relationshipData

  parentSpecific = this.relationshipData?.[PARENT_SPECIFIC] ?? null

  relationshipInfo = this.parentSpecific?.[RELATIONSHIP_INFO] ?? null

  state = {
    currentMember: this.currentMember,
    relationshipData: this.relationshipData,
    parentSpecific: this.parentSpecific,
    relationshipInfo: this.relationshipInfo,
  }

  constructor(props) {
    super(props)

    /* Check if there's already a selected RelationshipInfo */
    this.checkRelationshipInfo()
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { currentMember } = newProps
    const { relationshipData } = newProps
    const parentSpecific = relationshipData?.[PARENT_SPECIFIC] ?? null
    let relationshipInfo = parentSpecific?.[RELATIONSHIP_INFO] ?? null

    for (const e of RelationshipTypes) {
      if (relationshipInfo?.key === e.key) {
        relationshipInfo = e
        break
      }
    }

    return {
      currentMember,
      relationshipData,
      parentSpecific,
      relationshipInfo,
    }
  }

  checkRelationshipInfo = () => {
    let { relationshipInfo } = this.state
    for (const e of RelationshipTypes) {
      if (relationshipInfo?.key === e.key) {
        relationshipInfo = e
        break
      }
    }
    this.setState({ relationshipInfo })
  }

  toggleBloodRelatedParents = (val) => {
    if (val === false) this.parentController?.removeParentsBloodRelation()

    this.parentController?.toggleBloodRelatedParents(val)
  }

  /* TODO: Find out if this is still needed */
  didSelectRelationshipType = (val, reset = false) => {
    this.parentController?.didSelectRelationshipType(val, reset)
  }

  onPressSelectRelationshipButton = () => {
    this.parentController?.navigateToSelectParentsBloodRelation()
  }

  onPressDeleteRelationshipButton = () => {
    this.parentController?.removeParentsBloodRelation()
  }

  showTooltip = (title = '', message = '') => {
    this.parentController?.showTooltip(title, message)
  }

  render() {
    const { parentSpecific, relationshipInfo } = this.state

    return (
      <View
        style={[
          custom_styles.groupContentItemContainer,
          { borderBottomWidth: 0 },
        ]}
      >
        <View
          style={[custom_styles.groupContentItem, { borderBottomWidth: 0 }]}
        >
          <View
            style={[
              custom_styles.groupContentItemLabelTextContainer,
              {
                flex: 0.7,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            ]}
          >
            <Text style={custom_styles.groupContentItemLabelText}>
              {
                i18n.t(
                  'are_the_parents_of_this_person_blood_related_to_each_other__'
                )?.default
              }
            </Text>
            {
              /* Adjust tooltip icon for WEB */
              IS_WEB() && (
                <TouchableOpacity
                  onPress={() =>
                    this.showTooltip(
                      i18n.t('blood_related')?.default,
                      i18n.t(
                        'this_is_important_because_people_whose_parents_are_related_to_each_other_by_blood_have_a_higher_chance_of_inheriting_a_recessive_genetic_condition'
                      )?.default
                    )
                  }
                >
                  <Image
                    style={{ width: 20, height: 20 }}
                    source={require('$assets/images/new_assets/icon-info-teal.png')}
                  />
                </TouchableOpacity>
              )
            }
          </View>

          {
            /* Adjust tooltip icon for MOBILE */
            IS_MOBILE() && (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    this.showTooltip(
                      i18n.t('blood_related')?.default,
                      i18n.t(
                        'this_is_important_because_people_whose_parents_are_related_to_each_other_by_blood_have_a_higher_chance_of_inheriting_a_recessive_genetic_condition'
                      )?.default
                    )
                  }
                >
                  <Image
                    style={{ width: 20, height: 20 }}
                    source={require('$assets/images/new_assets/icon-info-teal.png')}
                  />
                </TouchableOpacity>
              </View>
            )
          }

          <View
            style={[
              custom_styles.groupContentItemTextInputContainer,
              { flex: 0.3 },
            ]}
          >
            <DarkGraySwitch
              value={parentSpecific?.bloodRelatedParents}
              onValueChange={(val) => this.toggleBloodRelatedParents(val)}
            />
          </View>
        </View>

        {parentSpecific?.bloodRelatedParents && (
          <SelectRelationshipView
            relationshipInfo={relationshipInfo}
            onPressPrimary={this.onPressSelectRelationshipButton}
            onPressDelete={this.onPressDeleteRelationshipButton}
          />
        )}
      </View>
    )
  }
}

const SelectRelationshipView = ({
  relationshipInfo,
  onPressPrimary = function () {},
  onPressDelete = function () {},
}) => {
  if (relationshipInfo && !isEmptyObject(relationshipInfo)) {
    return (
      <View style={custom_styles.relationshipItemContainer}>
        <View style={custom_styles.relationshipItemContent}>
          <Image
            style={[
              custom_styles.relationshipItemImage,
              { width: 0, opacity: 0 },
            ]}
          />

          <View style={{ marginVertical: 5, flex: 1.0 }}>
            <Text style={custom_styles.relationshipItemTitleText}>
              {i18n.t(relationshipInfo?.[RELATIONSHIP_NAME]).default}
            </Text>
            <Text style={custom_styles.relationshipItemSubtitleText}>
              {i18n.t(relationshipInfo?.[RELATIONSHIP_DEFINITION]).default}
            </Text>
          </View>
        </View>

        <View style={custom_styles.deleteButtonContainer}>
          <TouchableOpacity
            style={custom_styles.deleteButton}
            onPress={onPressDelete}
          >
            <Image
              source={require('$assets/images/new_assets/icon-trash.png')}
              style={{ width: 14, height: 16, resizeMode: 'contain' }}
            />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  return (
    <View
      style={[
        custom_styles.groupContentItemListContainer,
        { height: 40, width: 150 },
      ]}
    >
      <TouchableOpacity
        onPress={onPressPrimary}
        style={{
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'montserrat-medium',
            fontSize: 12,
            color: 'rgb(153,153,153)',
            textAlign: 'center',
          }}
        >
          {i18n.t('select_relationship')?.default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default BloodRelatedParentsSection
