import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  KeyboardAvoidingView,
  ScrollView,
  SafeAreaView,
  Keyboard,
} from 'react-native'

import { errorResponseHandler } from '../authorizationHelpers'
import { i18n } from '$localization/config.js'
import styles from './main.styles'

import LoadingView from '$constants/LoadingView.js'
import { emailToLowerCase } from '$api/_utils/Utils.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { setAccountEmail, setAccountPassword } from '$redux/account/actions'

import { setError } from '$redux/defaults/actions.js'
import TermsAndPrivacyScreenModal from '../sign_up/TermsAndPrivacyScreen.modal'

import useAuth from '../../../hooks/useAuth'
import LoginInputs from './components/LoginInputs'
import NextButtonAbsolute from '../../../components/NextButtonAbsolute/NextButtonAbsolute'

function CustomCreatePassword({ navigation }) {
  const dispatch = useDispatch()
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const patientData = useSelector((state) => state.accountStore.account)
  const userUUID = useSelector((state) => state.store.inviteID)

  const {
    handleLoginCustomWorkflowUser,
    createPatientAccount,
    checkEmailAvailability,
    checkPasswordValidity,
  } = useAuth()

  const setAccountEmailRedux = (data) => dispatch(setAccountEmail(data))
  const setAccountPasswordRedux = (data) => dispatch(setAccountPassword(data))
  const saveError = (data) => dispatch(setError(data))

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(true)
  const [allowSubmitButtonAction, setAllowSubmitButtonAction] = useState(true)
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [showLoadingView, setShowLoadingView] = useState(false)
  const [hasUserEmailEntered, setHasUserEmailEntered] = useState(false)
  const [showTermsPrivacy, setShowTermsPrivacy] = useState(false)

  const shouldSubmit = () => setAllowSubmit(Boolean(email) && Boolean(password))

  const checkIfUserHasEmailEntered = async () => {
    const getIfUserHasEmailEntered = {
      path: `patient_account/${userUUID}/validate_email/`,
      method: 'get',
      pageDetails: {
        page: 'CustomCreatePassword.js',
      },
    }
    let response
    try {
      response = await ApiFetchHandler(getIfUserHasEmailEntered)

      if (response?.isError) {
        return errorResponseHandler(response, saveError)
      }
    } catch (err) {
      console.log(
        '🚀 \n\n file: customCreatePassword.js:188 \n\n checkIfUserHasEmailEntered \n\n err:',
        err
      )
    }

    const { validation } = response

    if (!validation) return

    setEmail(response.email)
    setHasUserEmailEntered(true)
  }

  const navigateToTermsAndPrivacy = async () => {
    if (
      patientData?.invite_status === 'InviteSent' ||
      patientData?.invite_status === '2'
    ) {
      setShowTermsPrivacy(true)
    } else {
      if (!hasUserEmailEntered) return errorResponseHandler({ status: 403 })

      try {
        await handleLoginCustomWorkflowUser(
          navigation,
          email,
          password,
          hasUserEmailEntered
        )
      } catch (error) {
        console.log(
          '🚀 \n\n file: customCreatePassword.js:11 \n\n handleSetPassAndLogin \n\n error:',
          error
        )
      }
    }
  }

  const submitButtonAction = async () => {
    Keyboard.dismiss()

    if (!allowSubmitButtonAction) return

    setAllowSubmitButtonAction(false)

    const lowerCaseEmail = emailToLowerCase(email)
    setAccountEmailRedux(lowerCaseEmail)
    setAccountPasswordRedux(password)

    const isPasswordValid = checkPasswordValidity(password)

    if (!isPasswordValid) return setAllowSubmitButtonAction(true)

    if (!patientData?.email) {
      const isValid = await checkEmailAvailability(email)

      if (!isValid) {
        setAllowSubmitButtonAction(true)
        return setShowLoadingView(false)
      }
    }

    setAllowSubmitButtonAction(true)
    setShowLoadingView(false)
    return navigateToTermsAndPrivacy()
  }

  useEffect(() => {
    shouldSubmit()
  }, [email, password])

  useEffect(() => {
    checkIfUserHasEmailEntered()
  }, [])

  return (
    <SafeAreaView
      style={[
        styles.container,
        {
          backgroundColor: colorSettings?.splash_bgcolor,
        },
      ]}
    >
      <KeyboardAvoidingView
        style={styles.keyboardAvoiding}
        contentContainerStyle={styles.contentContainer}
        behavior="padding"
        keyboardVerticalOffset={60}
      >
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
          <LoginInputs
            email={email}
            hasUserEmailEntered={hasUserEmailEntered}
            password={password}
            setPassword={setPassword}
            setEmail={setEmail}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
          />
        </ScrollView>
        <NextButtonAbsolute
          onPressAction={submitButtonAction}
          isDisabled={!allowSubmit}
          buttonText={i18n.t('create_password')?.title}
        />
        <LoadingView
          backgroundColor="white"
          tintColor={colorSettings?.btn_no_fill_border_1}
          textColor={colorSettings?.btn_no_fill_text_1}
          visible={showLoadingView}
        />
      </KeyboardAvoidingView>
      <TermsAndPrivacyScreenModal
        modalVisible={showTermsPrivacy}
        primaryButtonAction={async () =>
          createPatientAccount(navigation, setShowTermsPrivacy, email, password)
        }
        cancelButtonAction={() => setShowTermsPrivacy(false)}
      />
    </SafeAreaView>
  )
}

export default CustomCreatePassword
