import { Component } from 'react'
import {
  ScrollView,
  TouchableOpacity,
  View,
  Text,
  SafeAreaView,
  Platform,
  StatusBar,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import custom_styles from './styles/main.styles.js'

import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { WEB } from '$constants/Platforms.js'
import ErrorBoundary from 'react-native-error-boundary'

class ForgotPWScreenSelectOption extends Component {
  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      nextButtonIsActive: false,
      viaEmailSelected: false,
      viaPhoneNumberSelected: false,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  nextButtonAction = () => {
    const selectedCategory = this.state.viaEmailSelected
      ? 'ForgotPWEnterEmail'
      : 'ForgotPWEnterPhoneNumber'
    const { push } = this.props.navigation

    push(selectedCategory)
  }

  onPressOption = (tag) => {
    switch (tag) {
      case 0:
        this.setState({
          nextButtonIsActive: true,
          viaEmailSelected: true,
          viaPhoneNumberSelected: false,
        })
        break
      case 1:
        this.setState({
          nextButtonIsActive: true,
          viaEmailSelected: false,
          viaPhoneNumberSelected: true,
        })
        break
      default:
        return null
    }
  }

  toggleOptionButton = (selected = false) => {
    const bgColor = selected
      ? 'selectable_btn_active_3'
      : 'selectable_btn_inactive_3'
    const borderColor = selected
      ? 'selectable_btn_border_active_3'
      : 'selectable_btn_border_inactive_3'

    const isNullBgColor = selected ? 'rgba(0,0,0,0.2)' : 'white'

    return {
      backgroundColor: this.colorSettings?.[bgColor] || isNullBgColor,
      borderColor: this.colorSettings?.[borderColor],
    }
  }

  toggleOptionButtonText = (selected = false) => {
    const isNullTextColor = selected ? 'white' : 'black'

    if (selected) {
      return { color: this.colorSettings?.selectable_btn_text_active_3 }
    }
    return { color: this.colorSettings?.selectable_btn_text_inactive_3 }
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    let style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled,
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }

    const isValid = this.validateInput()
    if (isValid) {
      style = {
        backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return style
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const enabled = this.validateInput()
    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  validateInput = () => this.state.nextButtonIsActive

  render() {
    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            custom_styles.container,
            { backgroundColor: this.colorSettings?.splash_bgcolor },
          ]}
        >
          {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}

          <ScrollView
            style={{ height: this.state.scrollViewHeight }}
            nativeID="web_scaled_main_container"
            contentContainerStyle={custom_styles.contentContainer}
          >
            <View style={[custom_styles.content]}>
              {/* <TitleText> */}
              <View style={custom_styles.titleTextContainer}>
                <Text
                  style={[
                    custom_styles.titleText,
                    {
                      color: this.colorSettings?.text_input_title_color_1,
                    },
                  ]}
                >
                  {`${
                    i18n.t('how_do_you_want_to_reset_your_password').default
                  }?`}
                </Text>
              </View>
              {/* </TitleText> */}

              <View style={{ height: 32, width: '100%' }} />

              {/* <Options> */}
              <View style={custom_styles.optionsContainer}>
                {/* <via Email> */}
                <TouchableOpacity
                  style={[
                    custom_styles.optionButton,
                    this.toggleOptionButton(this.state.viaEmailSelected),
                  ]}
                  activeOpacity={1.0}
                  onPress={() => this.onPressOption(0)}
                >
                  <Text
                    style={[
                      custom_styles.optionButtonText,
                      this.toggleOptionButtonText(this.state.viaEmailSelected),
                    ]}
                  >
                    {i18n.t('via_email_address').default}
                  </Text>
                </TouchableOpacity>
                {/* </via Email> */}

                {/* <via Phone number> */}
                <TouchableOpacity
                  style={[
                    custom_styles.optionButton,
                    this.toggleOptionButton(this.state.viaPhoneNumberSelected),
                  ]}
                  activeOpacity={1.0}
                  onPress={() => this.onPressOption(1)}
                >
                  <Text
                    style={[
                      custom_styles.optionButtonText,
                      this.toggleOptionButtonText(
                        this.state.viaPhoneNumberSelected
                      ),
                    ]}
                  >
                    {i18n.t('via_phone_number').default}
                  </Text>
                </TouchableOpacity>
                {/* <via Phone number> */}
              </View>
              {/* </Options> */}
            </View>
          </ScrollView>

          {/* <Next button> */}
          <View style={custom_styles.nextButtonContainer}>
            <TouchableOpacity
              disabled={this.toggleNextButton()}
              style={[
                custom_styles.nextButton,
                this.toggleNextButtonColor(),
                {
                  backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  custom_styles.nextButtonText,
                  {
                    color: this.toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('next').default}
              </Text>
            </TouchableOpacity>
          </View>
          {/* </Next button> */}
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(ForgotPWScreenSelectOption)
