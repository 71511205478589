import moment from 'moment'
import 'moment/min/locales'

import { Component } from 'react'

import {
  Image,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  SafeAreaView,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Appearance,
  Linking,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import { connect } from 'react-redux'

import { Sae } from 'react-native-textinput-effects'

import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome'
import Alert from '$components/Alert'
import { DOMAIN_CLIENT } from '$constants/globalVariables.js'

import TermsAndPrivacyScreen from '$screens/onboarding/sign_up/TermsAndPrivacyScreen.modal.js'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$constants/LoadingView.js'
import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'
import CustomDatePicker from '$components/custom-date-picker/custom-date-picker.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { setError } from '$redux/defaults/actions.js'

import { WEB, IS_WEB, IOS, IS_MOBILE } from '$constants/Platforms.js'
import { i18n } from '$localization/config.js'

import AuthCredentials from '$data_models/AuthCredentials'
import Account from '$data_models/Account'
import Proband from '$data_models/Proband'

import SimplerDatePicker from '$components/web_date_picker/SimpleDatePicker.js'

import {
  setAccountEmail,
  setAccountPassword,
  setAccountFirstName,
  setAccountLastName,
  setAccountDOB,
  setAccountAge,
  setAccountAction,
  setAuthCredentialsAction,
} from '$redux/account/actions'

import {
  setProbandAction,
  setProbandID,
  setProbandFamilyId,
} from '$redux/proband/actions'

import { storeCurrentMember } from '$redux/member_profile/actions.js'

import {
  yearIntervalFromNow,
  emailToLowerCase,
  defaultDateFormatAPI,
} from '$api/_utils/Utils.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import SimpleStorage from '$api/_utils/SimpleStorage.js'

import GetDateFormatString from '$screens/_utils/LocaleDateFormat.js'

import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import styles from './styles/main.styles.js'
import simpledatepicker_styles from './styles/simpledatepicker.styles'
import { getLang } from '$utils/lang_utils.js'

const LOGIN_SCREEN_INPUT_CONTAINER = 'login_screen_input_container'
const LOGIN_SCREEN_INPUT = 'login_screen_input'

const activeDateFormat = GetDateFormatString()
const inputAccessoryViewID = 'SignupMainScreen'

const storage = new SimpleStorage()

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)
const PlatformConst = Platform.OS === 'web' ? 'web' : 'mobile'

export class CustomTextInput extends Component {
  setRef = this.props.setRef ?? function () {}

  value = this.props.value

  render() {
    const { colorSettings, containerStyle } = this.props

    return (
      <View style={[containerStyle, styles.customTextInputContainer]}>
        <Sae
          ref={(ref) => this.setRef(ref)}
          style={[
            styles.textInputContainer,
            {
              borderBottomColor: colorSettings?.text_input_label_color_1,
            },
          ]}
          labelStyle={[
            styles.textInputLabel,
            {
              color: colorSettings?.text_input_placeholder_color_1,
            },
          ]}
          inputStyle={[
            styles.textInputField,
            {
              color: colorSettings?.text_input_color_1,
            },
          ]}
          iconClass={FontAwesomeIcon}
          iconName=""
          iconColor=""
          inputPadding={8}
          selectionColor={colorSettings?.text_input_color_1}
          borderHeight={0}
          autoCapitalize="none"
          autoCorrect={false}
          returnKeyType="next"
          inputAccessoryViewID={inputAccessoryViewID}
          value={this.value}
          {...this.props}
        />
      </View>
    )
  }
}

export class BirthdatePicker extends Component {
  setRef = this.props.setRef

  toggleDatePicker = this.props.toggleDatePicker ?? function () {}

  state = {
    colorScheme: 'light',
    date: this.props.date,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      date: newProps.date,
    }
  }

  componentDidMount() {
    const bgAppearance = Appearance.getColorScheme()
    if (bgAppearance !== 'no-preference' || bgAppearance !== null) {
      this.setState({ colorScheme: bgAppearance })
    }
    storage.setItem('onSplash', false)
  }

  render() {
    moment.locale(this.props.selectedLanguage)

    const { colorSettings } = this.props
    const currentDate = this.state.date
      ? moment(`${this.state.date}`).format(activeDateFormat)
      : ''

    return (
      <View>
        <CustomTextInput
          setRef={(ref) => this.setRef(ref)}
          colorSettings={colorSettings}
          label={i18n.t('birthdate')?.default}
          value={`${currentDate}`}
        />
        <TouchableOpacity
          style={styles.datePickerButton}
          onPress={() => this.toggleDatePicker()}
          activeOpacity={1}
        />
      </View>
    )
  }
}

class SignupMainScreen extends Component {
  // redux states
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  account = this.props.account ?? new Account()

  proband = this.props.proband ?? new Proband()

  firstName = ''

  lastName = ''

  email = ''

  password = ''

  dob = ''

  age = 0

  formValues = {
    email: this.email,
    firstName: this.firstName,
    lastName: this.lastName,
    password: this.password,
    dob: null,
    age: this.age,
  }

  // Reference holder for components
  parentRefs = {
    _textInput1: null,
    _textInput2: null,
    _textInput3: null,
    _textInput4: null,
    _textInput5: null,
  }

  /* TODO: Migrate parentRefs here */
  /* Text Input references */
  textInputFirstName = null

  textInputLastName = null

  textInputEmail = null

  textInputPassword = null

  textInputDOB = null

  state = {
    currentMember: this.props.currentMember,
    shouldRenderComponent: false,
    showLoadingView: false,
    loadingViewMessage: i18n.t('please_wait')?.default,
    hasDOB: false,
    showPassword: true,
    showPasswordIcon: require('$assets/images/icon/eye-close/black.png'),
    showTermsAndPrivacy: false,
    allowSubmitButtonAction: true,
    datePickerVisible: false,
    scrollViewHeight: getScrollViewHeight(),
    ...this.formValues,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      currentMember: newProps.currentMember,
    }
  }

  componentDidMount() {
    const { navigation, colorSettings } = this.props

    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    navigation.setParams({
      cs: colorSettings,
    })
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = getScrollViewHeight()
    this.setState({ scrollViewHeight: height })
  }

  focusNextTextInput = (textInput) => {
    if (textInput) textInput?.focus()
  }

  toggleNextButtonColor = (allowSubmit) => {
    const { colorSettings } = this.props

    const style = {
      backgroundColor: colorSettings?.bottom_next_btn_disabled ?? 'white',
      borderColor: colorSettings?.bottom_next_btn_disabled,
    }

    if (allowSubmit) {
      style.backgroundColor = colorSettings?.bottom_next_btn_enabled ?? 'white'
      style.borderColor = colorSettings?.bottom_next_btn_enabled
    }

    return style
  }

  toggleNextButtonText = (allowSubmit) => {
    const { colorSettings } = this.props

    let style = colorSettings?.bottom_next_btn_text_disabled ?? 'black'

    if (allowSubmit) {
      style = colorSettings?.bottom_next_btn_text_enabled ?? 'black'
    }

    return style
  }

  showPasswordButtonAction = () => {
    const showPassword = !this.state.showPassword
    const showPasswordIcon = showPassword
      ? require('$assets/images/icon/eye-close/black.png')
      : require('$assets/images/icon/eye/black.png')

    this.setState({ showPassword, showPasswordIcon })
  }

  onDatePickerWebChange = (date) => {
    this.setState({
      dob: date,
      hasDOB: date != null,
    })
  }

  onDatePickerChange = ({ selectedDate, dismiss = false }) => {
    /* Note: Use a copy of data from state as much as possible. */
    const newState = JSON.parse(JSON.stringify(this.state))

    // Uncomment to use referenced data from state.
    // let newState = this.state;

    if (selectedDate) {
      newState.dob = selectedDate
      newState.hasDOB = true
    }

    newState.datePickerVisible = !dismiss
    this.setState(newState)
  }

  shouldSubmit = () => {
    let allowSubmit = true

    if (!this.state.email || this.state.email === '') {
      allowSubmit = false
    }
    if (!this.state.dob || this.state.dob === '') {
      allowSubmit = false
    }
    if (!this.state.firstName || this.state.firstName === '') {
      allowSubmit = false
    }
    if (!this.state.lastName || this.state.lastName === '') {
      allowSubmit = false
    }
    if (!this.state.password || this.state.password === '') {
      allowSubmit = false
    }

    return allowSubmit
  }

  handleContactUs = async () => {
    try {
      await Linking.openURL('https://famgenix.com/faq-app')
    } catch (err) {
      console.log(
        '🚀 \n\n file: SignupScreen.main.js:380 \n\n SignupMainScreen \n\n handleContactUs= \n\n err:',
        err
      )
    }
  }

  errorResponseHandler = (response) => {
    const { status, error } = response
    const { saveError } = this.props

    switch (status) {
      case 409:
        showDefaultAlert(
          i18n.t('email_not_available')?.default,
          i18n.t(
            'the_email_address_that_you_entered_is_already_being_used_by_another_user'
          )?.default
        )
        break
      case 400:
        showDefaultAlert(
          i18n.t('oops')?.default,
          i18n.t('looks_like_you_entered_the_wrong_birthdate_please_try_again')
            ?.default
        )
        break

      case 401:
        Alert.alert(
          i18n.t('oops')?.default,
          i18n.t(
            'you_entered_the_wrong_birthdate_too_many_times_your_account_is_now_locked'
          )?.default,
          [
            {
              text: i18n.t('contact_us')?.default,
              onPress: () => this.handleContactUs(),
            },
            { text: i18n.t('okay').title, style: 'cancel' },
          ]
        )
        break

      default:
        saveError({
          isShown: true,
          status,
          message: error,
        })
    }
  }

  async validateDobAction() {
    try {
      const formattedDateOfBirthdate = moment(this.state.dob).format(
        'YYYY-MM-DD'
      )

      const validateDobPayload = {
        inviteID: this.props.inviteID,
        dob: formattedDateOfBirthdate,
      }

      const validateDOB = {
        path: 'account/verify_patient_invite/',
        method: 'post',
        body: {
          ...validateDobPayload,
        },
        pageDetails: {
          page: 'SignupScreen.main.js',
        },
      }

      const validateDobResponse = await ApiFetchHandler(validateDOB)

      if (validateDobResponse?.isError) {
        const { status } = validateDobResponse

        this.errorResponseHandler(validateDobResponse)

        return this.setState({
          allowSubmitButtonAction: true,
          showLoadingView: false,
        })
      }

      return this.setState({
        showTermsAndPrivacy: validateDobResponse.status_code === 200,
        allowSubmitButtonAction: true,
        showLoadingView: false,
      })
    } catch (err) {
      console.log(
        '🚀 \n\n file: SignupScreen.main.js:477 \n\n SignupMainScreen \n\n validateDobAction \n\n err:',
        err
      )
    }
  }

  submitButtonAction = async () => {
    Keyboard.dismiss()

    // Handle unnecessary number of taps
    if (!this.state.allowSubmitButtonAction) return

    this.setState({ allowSubmitButtonAction: false })

    const dob = moment(this.state.dob).format(defaultDateFormatAPI)
    // DONE: eliminate account and change to redux account
    this.props._setAccountEmail_(emailToLowerCase(this.state.email))
    this.props._setAccountPassword_(this.state.password)
    this.props._setAccountFirstName_(this.state.firstName)
    this.props._setAccountLastName_(this.state.lastName)
    this.props._setAccountDOB_(dob)
    this.props._setAccountAge_(yearIntervalFromNow(dob))

    // Check user's current age
    if (yearIntervalFromNow(dob) < 18) {
      this.setState({ allowSubmitButtonAction: true })

      const ans = await this.AsyncAlert()
      if (!ans) {
        return
      }
    }

    // Validate password
    const passwordValidator = require('password-validator')
    const schema = new passwordValidator()
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
    // .is().not().oneOf(['password', 'passw0rd']);    // Blacklist these values

    if (!schema.validate(this.state.password)) {
      this.setState({ allowSubmitButtonAction: true })
      showDefaultAlert(
        i18n.t('password_security')?.default,
        i18n.t(
          'your_password_does_not_meet_the_requirements_please_choose_a_more_secure_password'
        )?.default
      )
      return
    }

    const payload = {
      email: this.state.email,
    }

    // API request for email address availability

    const emailIsValid = this.emailValidator(this.state.email)

    if (!emailIsValid) {
      showDefaultAlert(
        i18n.t('server_error')?.default,
        i18n.t('please_enter_a_valid_email')?.default
      )

      return this.setState({
        allowSubmitButtonAction: true,
        showLoadingView: false,
      })
    }

    this.setState({ showLoadingView: true })

    const emailAvailabilityPayload = {
      path: 'account/check_email/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SignupScreen.main.js',
      },
    }

    const response = await ApiFetchHandler(emailAvailabilityPayload)

    if (response.isError) {
      const { status } = response

      this.errorResponseHandler(response)

      return this.setState({
        allowSubmitButtonAction: true,
        showLoadingView: false,
      })
    }

    if (this.props.inviteID) {
      await this.validateDobAction()
      return
    }

    this.setState({
      showTermsAndPrivacy: response.status_code === 200,
      allowSubmitButtonAction: true,
      showLoadingView: false,
    })
  }

  emailValidator = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/

    return emailRegex.test(email)
  }

  AsyncAlert = () =>
    new Promise((resolve, reject) => {
      Alert.alert(
        i18n.t('birthday')?.default,
        i18n.t('underage_disclaimer')?.default,
        [
          { text: i18n.t('cancel').default, onPress: () => resolve(false) },
          { text: i18n.t('continue').default, onPress: () => resolve(true) },
        ],
        { cancelable: false }
      )
    })

  createAccountInvited = async () => {
    // Prepare API request to create Invited Account.

    const { currentMember } = this.state

    const formValues = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: emailToLowerCase(this.state.email),
      password: this.state.password,
      dob: moment(this.state.dob).format(defaultDateFormatAPI),
      age: yearIntervalFromNow(this.state.dob) ?? 0,
    }

    const payload = {
      domain_client: `${DOMAIN_CLIENT}`?.toUpperCase(),
      lang: getLang(),
      ...currentMember,
      ...formValues,
    }

    /* Activate loading view */
    this.setState({
      showLoadingView: true,
      loadingViewMessage: i18n.t('creating_account')?.default,
    })

    /* API request for creating Account of invited member */
    const familyInvitePayload = {
      path: 'invite/account/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SignupScreen.main.js',
      },
    }

    const response = await ApiFetchHandler(familyInvitePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }

    this.setState({ showLoadingView: false })

    /* If create account is successful, force login account */
    await this.forceAccountLogin()
  }

  forceAccountLogin = async () => {
    const { email, password } = this.state

    const payload = {
      username: emailToLowerCase(email),
      email: emailToLowerCase(email),
      password,
    }

    this.setState({
      showLoadingView: true,
      loadingViewMessage: i18n.t('logging_in')?.default,
    })

    const loginAccountPayload = {
      path: 'account/login/',
      method: 'post',
      header: {
        'Content-Type': 'application/json;charset=UTF-8',
        platform: PlatformConst,
      },
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SignupScreen.main.js',
      },
    }

    const loginAccountDetails = await ApiFetchHandler(loginAccountPayload)
    const { saveError } = this.props

    if (loginAccountDetails.isError) {
      this.setState({ showLoadingView: false })

      saveError({
        isShown: true,
        status: loginAccountDetails.status,
        message: loginAccountDetails.error,
      })

      return showDefaultAlert(
        i18n.t('server_error')?.default,
        i18n.t(
          'sorry_there_was_an_error_processing_your_request_please_try_again_later'
        )?.default
      )
    }

    const _authData = loginAccountDetails?.token
    const _accountData = loginAccountDetails?.account
    // const hasProbandData = data?.has_proband_data;
    const probandID = loginAccountDetails?.member_id
    const familyID = loginAccountDetails?.family_id

    /* Save authCredentials to redux store. */
    const authCredentials = new AuthCredentials(_authData)
    this.props._setAuthCredentialsAction_(authCredentials)

    /* Save account to redux store. */
    const accountData = (this.account = new Account(_accountData))
    this.props._setAccountAction_(accountData)

    /* Save proband to redux store. */
    this.proband.probandID = probandID
    this.proband.familyID = familyID
    this.props._setProbandAction_(this.proband)

    this.setState({ showLoadingView: false })

    /* Proceed to success screen */
    this.navigateToSuccessFamilyInvite()
  }

  proceedToSignup = async () => {
    Keyboard.dismiss()

    this.setState({ showTermsAndPrivacy: false })

    const { currentMember } = this.state

    if (currentMember?.is_invited) {
      /* If Member is invited into Family */
      await this.createAccountInvited()
    } else {
      /* For default Sign up */
      /* Note: Added delay to give time for T&C modal to hide */
      setTimeout(() => {
        const { navigate } = this.props.navigation
        navigate('SignupScreenPhone')
      }, 300)
    }
  }

  navigateToSuccessFamilyInvite = () => {
    const { navigate } = this.props.navigation
    navigate('SuccessScreenFamilyInvite')
  }

  DobToMoment = (value) => {
    if (!value) return null
    return moment(value.replaceAll(' ', ''), 'MM/DD/YYYY')
  }

  getDatePickerForWeb = () => (
    <View style={styles.datePickerContainer}>
      <Text style={custom_styles.birthdateTitle}>
        {i18n.t('birthdate')?.default}
      </Text>
      <SimplerDatePicker
        containerStyle={simpledatepicker_styles.container}
        yearStyle={simpledatepicker_styles.yearStyle}
        monthStyle={simpledatepicker_styles.monthStyle}
        dayStyle={simpledatepicker_styles.dayStyle}
        minDate={moment().subtract(185, 'years')}
        maxDate={moment().subtract(1, 'days')}
        onDatePicked={this.onDatePickerWebChange}
        getPromptString={(name) => i18n.t(name.toLowerCase())?.default}
        date={this.state.dob}
        language={this.props.selectedLanguage}
      />
    </View>
  )

  setParentRef(key, ref) {
    // Add new refs by adding objects in this.parentRefs
    // and then passing an object using the Component's 'ref' prop
    if (key && ref) {
      this.parentRefs[key] = ref
    }
  }

  toggleDatePicker = () => {
    const prevState = this.state
    this.setState({
      datePickerVisible: !prevState?.datePickerVisible,
    })
  }

  navigateToNextInput = (event, callback) => {
    if (WEB === Platform.OS) {
      if (event.code === 'Tab') {
        event.preventDefault()
        callback()
      }
    }
  }

  render() {
    const { colorSettings } = this.props
    const allowSubmit = this.shouldSubmit()

    /* For invited member */
    const { currentMember } = this.state
    const isInvited = currentMember?.is_invited
    const invitedFirstName = currentMember?.first_name

    let enableKeyboardAvoiding = false
    if (Platform.OS === IOS) {
      enableKeyboardAvoiding = true
    }

    let signupFormWidth = 280
    if (IS_WEB()) {
      signupFormWidth = 500
    }

    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            styles.container,
            {
              backgroundColor: colorSettings?.splash_bgcolor,
              ...Platform.select({
                [WEB]: {
                  flex: null,
                  height: getScrollViewHeight(),
                },
              }),
            },
          ]}
        >
          {isInvited && (
            <InvitePushPin
              firstName={invitedFirstName}
              colorSettings={{
                backgroundColor: colorSettings?.btn_filled_enabled_1,
                textColor: colorSettings?.btn_filled_text_1,
              }}
            />
          )}
          <KeyboardAvoidingView
            style={styles.keyboardAvoiding}
            contentContainerStyle={styles.contentContainer}
            behavior="padding"
            keyboardVerticalOffset={60}
            enabled={enableKeyboardAvoiding}
          >
            <ScrollView
              style={{
                ...Platform.select({
                  [WEB]: {
                    height: getScrollViewHeight(),
                  },
                }),
              }}
              contentContainerStyle={styles.scrollViewContainer}
            >
              <View style={styles.signUpContainer}>
                <CustomTextInput
                  setRef={(ref) => this.setParentRef('_textInput1', ref)}
                  nativeID={LOGIN_SCREEN_INPUT}
                  colorSettings={colorSettings}
                  label={i18n.t('first_name')?.default}
                  autoCapitalize="words"
                  onSubmitEditing={() => this.parentRefs?._textInput2?.focus()}
                  tabindex={1}
                  value={this.firstName}
                  onChangeText={(value) => {
                    this.firstName = value
                    this.setState({ firstName: value })
                  }}
                  onKeyPress={(key) =>
                    this.navigateToNextInput(key, () =>
                      this.parentRefs?._textInput2?.focus()
                    )
                  }
                />
                <CustomTextInput
                  setRef={(ref) => this.setParentRef('_textInput2', ref)}
                  nativeID={LOGIN_SCREEN_INPUT}
                  colorSettings={colorSettings}
                  label={i18n.t('last_name')?.default}
                  autoCapitalize="words"
                  onSubmitEditing={() => this.parentRefs?._textInput3?.focus()}
                  tabindex={2}
                  value={this.lastName}
                  onChangeText={(value) => {
                    this.lastName = value
                    this.setState({ lastName: value })
                  }}
                  onKeyPress={(key) =>
                    this.navigateToNextInput(key, () =>
                      this.parentRefs?._textInput3?.focus()
                    )
                  }
                />
                <CustomTextInput
                  setRef={(ref) => this.setParentRef('_textInput3', ref)}
                  nativeID={LOGIN_SCREEN_INPUT}
                  colorSettings={colorSettings}
                  label={i18n.t('email')?.default}
                  keyboardType="email-address"
                  onSubmitEditing={() => this.parentRefs?._textInput4?.focus()}
                  tabindex={3}
                  value={this.email}
                  onChangeText={(value) => {
                    this.email = value
                    this.setState({ email: value })
                  }}
                  onKeyPress={(key) =>
                    this.navigateToNextInput(key, () =>
                      this.parentRefs?._textInput4?.focus()
                    )
                  }
                />
                <View style={styles.textInputPasswordContainer}>
                  <CustomTextInput
                    containerStyle={{ flex: 1 }}
                    inputStyle={[
                      styles.textInputField,
                      styles.textInputPassword,
                      {
                        color: colorSettings?.text_input_color_1 ?? 'black',
                      },
                    ]}
                    setRef={(ref) => this.setParentRef('_textInput4', ref)}
                    nativeID={LOGIN_SCREEN_INPUT}
                    colorSettings={colorSettings}
                    label={i18n.t('password')?.default}
                    secureTextEntry={this.state.showPassword}
                    onSubmitEditing={() => this.toggleDatePicker()}
                    tabindex={4}
                    value={this.password}
                    onChangeText={(value) => {
                      this.password = value
                      this.setState({ password: value })
                    }}
                  />
                  <View
                    style={[
                      styles.showPasswordContainer,
                      {
                        borderBottomColor:
                          colorSettings?.text_input_label_color_1,
                      },
                    ]}
                  >
                    <TouchableOpacity
                      activeOpacity={0.8}
                      onPress={this.showPasswordButtonAction}
                    >
                      <Image
                        style={styles.showPasswordImage}
                        source={this.state.showPasswordIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.helperTextContainer}>
                  <Text
                    style={[
                      styles.passwordHelperText,
                      {
                        color: colorSettings?.text_input_label_color_1,
                      },
                    ]}
                  >
                    {
                      i18n.t(
                        'minimum_of_8_characters_one_uppercase_letter_one_lowercase_letter_one_number'
                      )?.default
                    }
                  </Text>
                </View>
                {IS_WEB() && (
                  <View>
                    {this.getDatePickerForWeb()}
                    <View style={styles.helperTextContainer}>
                      <Text
                        style={[
                          styles.passwordHelperText,
                          {
                            color: colorSettings?.text_input_label_color_1,
                          },
                        ]}
                      >
                        {
                          i18n.t(
                            'you_must_be_18_years_old_to_use_this_app_an_accurate_age_is_also_important_for_assessing_your_risks'
                          )?.default
                        }
                      </Text>
                    </View>
                  </View>
                )}
                {IS_MOBILE() && (
                  <View>
                    <BirthdatePicker
                      setRef={(ref) => this.setParentRef('_textInput5', ref)}
                      colorSettings={colorSettings}
                      toggleDatePicker={() => this.toggleDatePicker()}
                      date={this.state.dob}
                      selectedLanguage={this.props.selectedLanguage}
                    />

                    <View style={styles.helperTextContainer}>
                      <Text
                        style={[
                          styles.passwordHelperText,
                          {
                            color: colorSettings?.text_input_label_color_1,
                          },
                        ]}
                      >
                        {
                          i18n.t(
                            'you_must_be_18_years_old_to_use_this_app_an_accurate_age_is_also_important_for_assessing_your_risks'
                          )?.default
                        }
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </ScrollView>
          </KeyboardAvoidingView>

          <View style={styles.nextButtonContainer}>
            <TouchableOpacity
              style={[
                styles.submitButton,
                this.toggleNextButtonColor(allowSubmit),
              ]}
              onPress={this.submitButtonAction}
              disabled={!allowSubmit}
            >
              <Text
                style={[
                  styles.submitButtonText,
                  {
                    color: this.toggleNextButtonText(allowSubmit),
                  },
                ]}
              >
                {i18n.t('sign_up')?.title}
              </Text>
            </TouchableOpacity>
          </View>

          <NextButtonAccessoryView
            nativeID={inputAccessoryViewID}
            backgroundColor={colorSettings?.splash_bgcolor}
            labelText={i18n.t('sign_up')?.title}
            labelColor={this.toggleNextButtonText(allowSubmit)}
            style={[
              styles.submitButton,
              this.toggleNextButtonColor(allowSubmit),
            ]}
            onPress={this.submitButtonAction}
            disabled={!allowSubmit}
          />

          <TermsAndPrivacyScreen
            modalVisible={this.state.showTermsAndPrivacy}
            primaryButtonAction={() => this.proceedToSignup()}
            cancelButtonAction={() =>
              this.setState({ showTermsAndPrivacy: false })
            }
          />

          <LoadingView
            backgroundColor="white"
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            message={this.state.loadingViewMessage}
            visible={this.state.showLoadingView}
          />

          {this.state.datePickerVisible && (
            <CustomDatePicker
              colorSettings={colorSettings}
              onDatePickerChange={this.onDatePickerChange}
              date={this.state.dob ?? new Date(0)}
              visible={this.state.datePickerVisible}
              selectedLanguage={this.props.selectedLanguage}
            />
          )}
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

function InvitePushPin({
  firstName,
  colorSettings = {
    backgroundColor: '',
    textColor: '',
  },
}) {
  const memberFirstName = `${firstName ?? i18n.t('unknown')?.default}`.trim()
  const titleText = [
    i18n.t('you_are_about_to_set_up_an_account_as_')?.default,
    `"${memberFirstName}".`,
    i18n.t(
      '_if_this_is_not_correct_contact_the_family_member_who_invited_you_and_ask_them_to_send_another_invitation'
    )?.default,
  ].join(' ')

  return (
    <View
      style={{
        backgroundColor: colorSettings?.backgroundColor ?? 'white',
        width: '100%',
        paddingVertical: 16,
        paddingHorizontal: 16,
        alignSelf: IS_WEB() ? 'center' : 'auto',
      }}
    >
      <Text
        style={{
          color: colorSettings?.textColor,
          fontFamily: 'montserrat',
          fontSize: 12,
          textAlign: IS_WEB() ? 'center' : 'auto',
        }}
      >
        {titleText}
      </Text>
    </View>
  )
}

const custom_styles = StyleSheet.create({
  birthdateTitle: {
    marginTop: 10,
    fontSize: 14,
    color: 'rgba(74,74,74,0.5)',
    fontFamily: 'montserrat',
    alignSelf: 'flex-start',
  },
})

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
    /** Proband Store */
    proband: probandStore.proband,
    /** MemberProfile Store */
    currentMember: memberProfileStore.currentMember,
    selectedLanguage: store.selectedLanguage,
    inviteID: store.inviteID,
  }
}

const actionCreators = {
  /* Account actions */
  _setAccountEmail_: setAccountEmail,
  _setAccountPassword_: setAccountPassword,
  _setAccountFirstName_: setAccountFirstName,
  _setAccountLastName_: setAccountLastName,
  _setAccountDOB_: setAccountDOB,
  _setAccountAge_: setAccountAge,
  _setAccountAction_: setAccountAction,
  _setAuthCredentialsAction_: setAuthCredentialsAction,
  /* Proband actions */
  _setProbandAction_: setProbandAction,
  _setProbandID_: setProbandID,
  _setProbandFamilyId_: setProbandFamilyId,
  /* MemberProfile actions */
  _storeCurrentMember_: storeCurrentMember,
  saveError: setError,
}

export default connect(mapGlobalStateToProps, actionCreators)(SignupMainScreen)
