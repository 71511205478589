/* React & React Native libraries */
import { View, SafeAreaView, SectionList, Platform } from 'react-native'

import { HeaderBackButton } from 'react-navigation-stack'

import { connect } from 'react-redux'

/* Custom Components and Constants */
import GenericFamilyListView from '../_components/GenericFamilyList'
import LoadingView from '$constants/LoadingView.js'
import NextButton from '$components/next-button/NextButton.component'

import TutorialModal from './_components/TutorialModal'
import ExtendedHeaderView from '$screens/relatives/refactored/_components//ExtendedHeaderView'
import GenericHeaderControls from '$screens/relatives/refactored/_components/GenericHeaderControls'

import { setError } from '$redux/defaults/actions.js'

import MemberProfile from '$data_models/MemberProfile.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'

/* Custom Utilities */
import { WEB } from '$constants/Platforms.js'
import { i18n } from '$localization/config.js'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

import { storeCurrentMember } from '$redux/member_profile/actions.js'

import {
  setBasicFamilyMembers,
  setShouldReloadFamilyList,
} from '$redux/proband/actions.js'

/* Custom Stylesheets */
import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import { resetStackActionRelatives } from '$navigation/_utils/NavigationUtils.js'

import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

let isLastScreenBeforeLogin = false

class ChildrenListController extends GenericFamilyListView {
  /** Note: Do not change. These keys are strictly tied to an API response. */
  sectionListOrder = {
    [RelationshipTypes.CHILDREN]: 0,
  }

  state = {
    ...this.state,
    allowFamilyInvite: false,
    showTutorialModal: true,
  }

  componentDidMount(...args) {
    super.componentDidMount.apply(this, args)
    const { lastScreenLogin, route } = this.props
    isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.relatives_children

    const higherScreenList = [
      'relatives_children',
      'relatives_siblings',
      'relatives_paternal_side',
      'relatives_maternal_side',
    ]

    const screenExist = higherScreenList.find(
      (screen) => screen === lastScreenLogin
    )
    const isModalShown = route.params?.isModalShown ?? true

    if (screenExist) {
      this.setState({ showTutorialModal: false })
    } else {
      this.setState({ showTutorialModal: isModalShown })
    }
  }

  getBasicFamilyMembers = async () => {
    const { familyID, headers, payload } = super
      .constructor(this.props)
      .getBasicFamilyMembersPayload()

    if (!familyID) return

    /* Activate loading view */
    this.reloadAndRenderView()

    /* API Request to load basic Family Members */

    const getBasicFamilyMembers = {
      path: `family/${familyID}/members/`,
      method: 'post',
      token: headers.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'ChildrenList index.js',
      },
    }

    const response = await ApiFetchHandler(getBasicFamilyMembers)

    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      this.setState({ sectionListRefreshing: false })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /** 
         What to do here?
         * Get only the Proband's Partner(s) and Children.
        */

    const _keys = Object.keys(response)

    // Loop through each family member by relationship to Proband.
    KeyLoop1: for (const i in _keys) {
      const relationshipToProband = `${_keys[i]}`.toLowerCase()

      switch (relationshipToProband) {
        case RelationshipTypes.PROBAND:
          var _memberData_ = response[relationshipToProband]
          var memberData = new MemberProfile().constructBasicMemberData(
            _memberData_
          )

          await this.getMemberPartnerAndChildren(memberData, payload, headers)

          /* If Proband: Exit loop immediately */
          break KeyLoop1

        default:
          /* Skip for unspecified RelationshipTypes */
          continue KeyLoop1
      }
    }
  }

  getMemberPartnerAndChildren = async (memberData = {}, payload, headers) => {
    const memberID = memberData?.member?.member_id

    /* Initialize Family data */
    const newFamilyData = (this.familyData = [
      { title: RelationshipTypes.CHILDREN, data: [] },
    ])

    const newChildrenList = []

    /* API Request to load Partners and Children of Member */

    const memberPartnerAndChildrenPayload = {
      path: `member/${memberID}/partner_and_children/`,
      method: 'post',
      token: headers.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'ChildrenList index.js',
      },
    }

    const response = await ApiFetchHandler(memberPartnerAndChildrenPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      this.setState({ sectionListRefreshing: false })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /** 
         What to do here?
         * Update family data on Redux.
         * Update the data on SectionList.
         * Stop refreshing of SectionList.
         * Stop loading view.
        */

    // Loop through each Partner of current Member.
    response.forEach((_partnerData_) => {
      // Get children from each Partner.
      const _childrenList_ = _partnerData_[RelationshipTypes.CHILDREN]
      _childrenList_.forEach((_childData_) => {
        newChildrenList.push(_childData_)
      })
    })

    /* Add Children to Family data */
    const SECTION_KEY = this.sectionListOrder[RelationshipTypes.CHILDREN]
    newFamilyData[SECTION_KEY] = {
      ...newFamilyData[SECTION_KEY],
      data: await this.addToFamilyData(newChildrenList, payload, headers),
    }

    this.props._setBasicFamilyMembers_(newFamilyData)

    this.reloadAndRenderView(false)

    this.setState({
      sectionListRefreshing: false,
      familyData: newFamilyData,
    })
  }

  navigateToRelationshipInfoTutorial = () => {
    this.toggleTutorialModal(false)

    setTimeout(() => {
      this.navigateTo('RelationshipInfoTutorialNavigator', {
        screen: 'RelationshipInfoTutorial',
      })
    }, 300)
  }

  nextButtonAction = async (delay = 0) => {
    const { account, authCredentials, saveError, navigation } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.relatives_children,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    super.constructor(this.props).nextButtonAction()

    this.toggleTutorialModal(false)

    setTimeout(() => {
      this.navigateTo('SiblingsList')
    }, delay)
  }

  toggleTutorialModal = (showTutorialModal = false) => {
    this.setState({ showTutorialModal })
  }
}

class ChildrenListView extends ChildrenListController {
  renderSectionHeader = (section) =>
    super.constructor(this.props).renderSectionHeader(section)

  renderItem = ({ item, index, section }) =>
    super.constructor(this.props).renderItem({ item, index, section })

  render() {
    const { colorSettings } = this.props

    const message =
      this.allMembersList.length > 0
        ? i18n.t(
            'be_sure_to_add_details_about_anyone_who_had_a_disease_or_genetic_testing'
          )?.default
        : i18n.t('now_lets_add_your_children')?.default

    return (
      <SafeAreaView
        style={[
          global_styles.containerWhite,
          {
            ...Platform.select({
              [WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          },
        ]}
      >
        {/* Custom Header */}
        <ExtendedHeaderView
          colorSettings={colorSettings}
          message={message}
          controlsView={
            <GenericHeaderControls
              colorSettings={colorSettings}
              primaryButtonAction={({ relationshipToProband }) =>
                this.requestAddRelative({ relationshipToProband })
              }
              showTertiaryButton
              leftButton={{ key: RelationshipTypes.SON }}
              rightButton={{ key: RelationshipTypes.DAUGHTER }}
              tertiaryButton={{ key: RelationshipTypes.PREGNANCY }}
            />
          }
        />

        {/* Content */}
        <View style={[global_styles.content, { flex: 1.0 }]}>
          <SectionList
            nativeID="web_scaled_main_container"
            style={{
              ...Platform.select({
                [WEB]: {
                  height: getScrollViewHeight(),
                },
              }),
            }}
            bounces
            contentContainerStyle={{ paddingBottom: 16 }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            sections={this.state.familyData}
            keyExtractor={(item, index) => index}
            renderSectionHeader={({ section }) =>
              this.renderSectionHeader(section)
            }
            renderItem={({ item, index, section }) =>
              this.renderItem({ item, index, section })
            }
            refreshing={this.state.sectionListRefreshing}
          />
        </View>

        {/* Next button */}
        <NextButton
          buttonStyle={[
            global_styles.nextButton,
            {
              backgroundColor: colorSettings?.bottom_next_btn_enabled,
              borderColor: colorSettings?.bottom_next_btn_enabled,
            },
          ]}
          buttonTitleStyle={[
            global_styles.nextButtonText,
            {
              color: colorSettings?.bottom_next_btn_text_enabled,
            },
          ]}
          buttonTitle={i18n.t('next')?.title}
          primaryAction={() => this.nextButtonAction()}
        />

        {/* Loading View */}
        {this.state.showLoadingView && (
          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
          />
        )}

        {/* Tutorial modal */}
        {this.state.showTutorialModal && (
          <TutorialModal
            onDismiss={() => this.toggleTutorialModal(false)}
            primaryAction={() => this.navigateToRelationshipInfoTutorial()}
            skipAction={() => this.nextButtonAction(300)}
            visible={this.state.showTutorialModal}
          />
        )}
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    lastScreenLogin: store.lastScreenLogin,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
    /** Proband Store */
    proband: probandStore.proband,
    shouldReloadFamilyList: probandStore.shouldReloadFamilyList,
    basicFamilyMembers: probandStore.basicFamilyMembers,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
    currentMember: memberProfileStore.currentMember,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _setShouldReloadFamilyList_: (data) =>
    dispatch(setShouldReloadFamilyList(data)),
  _saveCurrentMemberToStore_: (data) => dispatch(storeCurrentMember(data)),
  _setBasicFamilyMembers_: (data) => dispatch(setBasicFamilyMembers(data)),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenListView)
