import { i18n } from '$localization/config.js'

const getLang = () => {
  const { locale } = i18n

  let lang = 'en'
  if (locale && typeof locale === 'string') {
    const lang_arry = `${locale}`.split('-')
    if (lang_arry.length > 0) lang = lang_arry[0]
  }

  return lang
}

export { getLang }
