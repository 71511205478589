/* React & React Native libraries */
import { Text, View, TouchableOpacity, Image } from 'react-native'

/* Custom Components and Constants */
import GenericFamilyListController from './controller.js'

/* Custom Utilities */
import { i18n } from '$localization/config.js'

import {
  formatDisplayedMemberName,
  getGenderIcon,
  getGenderIconBorderRadius,
  getFamilySideAndRelationshipGender,
  capitalizeFirstLetter,
} from '$api/_utils/Utils.js'
import DiseasesView from '$screens/main/family/_includes/diseases/Diseases.view'
/* Custom Stylesheets */
import custom_styles from '$screens/main/family/_styles/main.styles.js'

class GenericFamilyListView extends GenericFamilyListController {
  renderSectionHeader = (section) => {
    const { colorSettings } = this.props

    if (!section || !section.data || section.data.length === 0) return null

    const _title_ = section.title ? section.title.toLowerCase() : ''
    const translatedTitle = i18n.t(_title_)?.default ?? '--'

    // Render section header
    return (
      <View
        style={[
          custom_styles.section,
          {
            backgroundColor: colorSettings?.member_section_bg,
          },
        ]}
      >
        <View style={custom_styles.sectionTitleView}>
          <Text
            style={[
              custom_styles.sectionTitleText,
              {
                color: colorSettings?.member_section_title,
              },
            ]}
          >
            {translatedTitle}
          </Text>
        </View>
      </View>
    )
  }

  renderItem = ({ item, index, section, onPressItem = function () {} }) => {
    const { colorSettings } = this.props

    if (!item) return null

    /* Make a copy of member */
    const member = JSON.parse(JSON.stringify(item?.member))

    /* Check if Member is valid or is still existing. */
    if (!member?.member_id || member?.is_deleted) return null

    /* Get Member name, age, and vital status. */
    const titleText = formatDisplayedMemberName(
      member?.name,
      member?.age_string ?? member?.age,
      member?.is_dead
    )

    /* Get Member's relationship to Proband. */
    const _relationshipToProband = getFamilySideAndRelationshipGender(
      item?.relationship,
      member?.gender
    )

    const _relationship = _relationshipToProband[0]?.toLowerCase()
    const _familySide = _relationshipToProband[1]?.toLowerCase()

    let relationship =
      i18n.t(_relationship)?.default ??
      capitalizeFirstLetter(_relationship, true)

    /* Append Family side if available. */
    if (_familySide) {
      relationship = `${relationship} (${i18n.t(_familySide)?.default})`
    }

    // //TODO: I added this in order to add a number to the family members without a name
    // const noNameFamilyIndex = !titleText ? ` ${index + 1}` : ''

    return (
      <View key={member?.member_id} style={{ width: '100%', paddingBottom: 0 }}>
        <View
          style={[custom_styles.itemViewContainer, { paddingHorizontal: 10 }]}
        >
          <TouchableOpacity
            onPress={() => this.navigateToMemberProfile(member)}
            activeOpacity={0.8}
            style={{ width: '100%' }}
          >
            <View style={[custom_styles.itemView, { marginVertical: 8 }]}>
              <View style={custom_styles.itemImageContainer}>
                <Image
                  source={getGenderIcon(member?.gender, member?.is_dead)}
                  style={getGenderIconBorderRadius(
                    member?.gender,
                    member?.is_dead
                  )}
                />
              </View>

              <View style={custom_styles.itemDetailsContainer}>
                <Text style={custom_styles.itemDetailsTitleText}>
                  {titleText}
                </Text>

                <View style={{ height: 4.0 }} />

                <Text
                  style={[
                    custom_styles.itemDetailsSubtitleText,
                    { fontFamily: 'montserrat-italic' },
                  ]}
                >
                  {relationship}

                  {/* //TODO: I added this in order to add a number to the family members without a name
                  {noNameFamilyIndex} */}
                </Text>

                <View style={{ height: 2.0 }} />
                <DiseasesView diseases={member?.diseases} />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

export default GenericFamilyListView
