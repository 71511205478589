import React from 'react'
import { Text, View, Image, StyleSheet } from 'react-native'
import { i18n } from '$localization/config.js'

import EllipseButton from '$components/EllipseButton'
import styles from './styles'

function SurveyCompleteScreen({
  colorSettings,
  editBtnClicked,
  onExitSurveyClicked,
}) {
  return (
    <View style={styles.container}>
      <Image
        source={require('$assets/images/new_assets/illus-survey-complete.png')}
        style={styles.image}
      />
      <Text
        style={[
          styles.titleText,
          {
            color: colorSettings?.success_screen_account_header_text || 'black',
          },
        ]}
      >
        {i18n.t('survey_complete').title ?? 'Survey Complete'}
      </Text>
      <EllipseButton
        buttonStyle={[
          styles.button,
          {
            backgroundColor: colorSettings?.bottom_next_btn_enabled || 'white',
          },
        ]}
        activeOpacity={0.2}
        onPress={editBtnClicked}
      >
        <Text
          style={[
            styles.nextButtonText,
            {
              color: colorSettings?.bottom_next_btn_text || 'black',
            },
          ]}
        >
          {i18n.t('edit').uppercase ?? 'EDIT'}
        </Text>
      </EllipseButton>
      <EllipseButton
        buttonStyle={[
          styles.button,
          {
            backgroundColor: colorSettings?.bottom_next_btn_enabled || 'white',
          },
        ]}
        activeOpacity={0.2}
        onPress={onExitSurveyClicked}
      >
        <Text
          style={[
            styles.nextButtonText,
            {
              color: colorSettings?.bottom_next_btn_text || 'black',
            },
          ]}
        >
          {i18n.t('go_to_surveys').uppercase ?? 'GO TO SURVEYS'}
        </Text>
      </EllipseButton>
    </View>
  )
}

export default SurveyCompleteScreen
