import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import { i18n } from '$localization/config.js'

import {
  handleGetCustomScreenText,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { CUSTOM_FLOW_ROUTES, ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import { setNextIndexAction } from '$redux/customFlowRoutes/actions'
import { setError } from '$redux/defaults/actions.js'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

export default function CustomSuccessScreen({ navigation }) {
  const dispatch = useDispatch()
  const saveError = (data) => dispatch(setError(data))

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const workflowSelectedScreens = useSelector(
    (state) => state.customFlowRoutesStore.selectedScreens
  )
  const { account = null, authCredentials = null } = useSelector(
    (state) => state.accountStore
  )

  const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.CustomSuccessScreen?.name
  const FAMILY_MEMBERS_CANCER = CUSTOM_FLOW_ROUTES?.FamilyMembersCancer?.name

  const firstWorkflowScreenData = workflowSelectedScreens.find(
    (screen) => screen.screen_order === 1
  )

  const familyMembersCancerData = workflowSelectedScreens.find(
    (screen) => screen.name === FAMILY_MEMBERS_CANCER
  )

  const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

  const topScreenText = customScreenText?.topText
    ? customScreenText?.topText
    : i18n.t('youre_done')?.default

  const middleScreenText = customScreenText?.middleText
    ? customScreenText?.middleText
    : i18n.t(
        'thank_you_for_providing_your_health_details_you_may_edit_your_data_anytime'
      )?.default

  const handleSaveLastScreen = async () => {
    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.custom_success_screen,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })
  }

  useEffect(() => {
    handleSaveLastScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateToFirstWorkflowScreen = () => {
    const nextRouteIndex = 2

    dispatch(
      setNextIndexAction({
        nextRouteIndex,
      })
    )

    handleNavigateToNextScreen({
      navigation,
      routeName: firstWorkflowScreenData.name,
    })
  }

  const navigateToEditFamily = () => {
    const isFamilyMembersCancerLastScreenInWorkflow =
      workflowSelectedScreens?.length === familyMembersCancerData.screen_order

    const nextRouteIndex = isFamilyMembersCancerLastScreenInWorkflow
      ? null
      : familyMembersCancerData.screen_order + 1

    dispatch(
      setNextIndexAction({
        nextRouteIndex,
      })
    )

    handleNavigateToNextScreen({
      navigation,
      routeName: familyMembersCancerData.name,
    })
  }

  const navigateToSwitchToFamgenixAccount = () => {
    navigation.navigate(ROUTES.SwitchToFamgenixAccount.name)
  }

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: colorSettings?.success_screen_account_bg ?? 'white',
        },
      ]}
    >
      <ScrollView>
        <View style={styles.messageContentContainer}>
          <View style={{ height: 50 }} />

          <Image
            source={require('$assets/images/icon/success/badge.png')}
            style={{ width: 120, height: 120, resizeMode: 'contain' }}
          />

          <View style={{ width: '100%', marginVertical: 16 }}>
            <Text
              style={[
                styles.titleText,
                {
                  color:
                    colorSettings?.success_screen_account_header_text ??
                    'black',
                },
              ]}
            >
              {topScreenText}
            </Text>
          </View>
          <View style={{ width: '100%', paddingHorizontal: 16 }}>
            <Text
              style={[
                styles.bodyText,
                {
                  color: colorSettings?.success_screen_account_text ?? 'black',
                },
              ]}
            >
              {middleScreenText}
            </Text>
          </View>

          <View style={{ marginTop: 40, marginBottom: 25 }}>
            <View style={styles.nextButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.nextButton,
                  {
                    backgroundColor:
                      colorSettings?.success_screen_account_button_bg ??
                      'white',
                    borderColor:
                      colorSettings?.success_screen_account_button_border ??
                      'black',
                    borderWidth: 1.0,
                  },
                ]}
                onPress={navigateToFirstWorkflowScreen}
              >
                <Text
                  style={[
                    styles.nextButtonText,
                    {
                      color:
                        colorSettings?.success_screen_account_button_text ??
                        'black',
                    },
                  ]}
                >
                  {i18n.t('edit_from_start')?.default}
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          {familyMembersCancerData && (
            <View style={styles.nextButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.nextButton,
                  {
                    backgroundColor:
                      colorSettings?.success_screen_account_button_bg ??
                      'white',
                    borderColor:
                      colorSettings?.success_screen_account_button_border ??
                      'black',
                    borderWidth: 1.0,
                  },
                ]}
                onPress={navigateToEditFamily}
              >
                <Text
                  style={[
                    styles.nextButtonText,
                    {
                      color:
                        colorSettings?.success_screen_account_button_text ??
                        'black',
                    },
                  ]}
                >
                  {i18n.t('edit_family')?.default}
                </Text>
              </TouchableOpacity>
            </View>
          )}

          <View
            style={{
              width: '100%',
              paddingHorizontal: 16,
              marginTop: 40,
            }}
          >
            <Text
              style={[
                styles.bodyText,
                {
                  color: colorSettings?.success_screen_account_text ?? 'black',
                },
              ]}
            >
              {
                i18n.t(
                  'want_to_continue_to_track_your_family_health_history_and_share_with_other_clinicians_or_family_members'
                )?.default
              }
            </Text>
            <TouchableOpacity onPress={navigateToSwitchToFamgenixAccount}>
              <Text style={[styles.bodyText, styles.learnMoreButtonText]}>
                {i18n.t('learn_more')?.default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
  },
  bodyText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 63,
    width: 300,
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 20,
    textAlign: 'center',
  },
  learnMoreButtonText: {
    color: 'rgb(155,89,182)',
  },
})
