import { View, Text, TouchableOpacity } from 'react-native'

import global_styles from '$constants/styles/global.styles.js'

export default function NextButton({
  primaryAction,
  buttonStyle,
  buttonTitleStyle,
  buttonTitle,
  disabled,
}) {
  return (
    <View style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}>
      <View style={global_styles.nextButtonContainer}>
        <TouchableOpacity
          style={buttonStyle}
          disabled={disabled}
          onPress={primaryAction}
        >
          <Text style={buttonTitleStyle}>{buttonTitle}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
