export const webModalBackgroundImageSizing = (currentWidth, currentHeight) => {
  switch (true) {
    case currentHeight < 800:
      return 200
    case currentWidth >= 850:
      return 250
    case currentWidth <= 850 && currentWidth >= 450:
      return 160
    case currentWidth <= 450:
      return 160
    default:
      return 300
  }
}
