import axios from 'axios'
import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

import { BASE_URL } from '$constants/envVariables.js'

import { store } from '$redux/configureStore'

const defaultContentType = 'application/json'

const oneSecond = 1000
const netWorkTimeOutValue = oneSecond * 30

export default async (payload) => {
  const {
    path,
    method,
    body,
    token,
    cType,
    header,
    networkTimeout = netWorkTimeOutValue,
    params,
    pageDetails,
  } = payload

  const { CancelToken } = axios
  const source = CancelToken?.source()

  let clearNetworkTimeout

  if (networkTimeout) {
    clearNetworkTimeout = setTimeout(() => {
      source.cancel('Network connection timeout')
    }, networkTimeout)
  }

  const url = `${BASE_URL}/${path}`

  const contentType = cType ?? defaultContentType

  const headers = {
    ...header,
    'Content-Type': `${contentType}`,
    Accept: `${contentType}`,
  }

  if (token) headers.Authorization = `Bearer ${token}`

  try {
    if (
      (__DEV__ || process.env.NODE_ENV === 'development') &&
      process.env?.APP_MANIFEST?.extra?.SHOW_NETWORK_LOGS
    ) {
      console.groupCollapsed(
        `%c${method} ${url} - fetch arguments`,
        'color: purple'
      )
      console.log(`METHOD:\n  ${method}`)
      console.log(`URL:\n  ${url}`)
      console.log(`BODY:\n\n  ${JSON.stringify(body, null, 4)}`)
      console.log(`PARAMS:\n\n  ${JSON.stringify(params, null, 4)}`)
      console.groupEnd()
    }

    const response = await axios({
      cancelToken: source?.token,
      method,
      url,
      headers,
      data: body,
      params,
    })

    if (
      (__DEV__ || process.env.NODE_ENV === 'development') &&
      process.env?.APP_MANIFEST?.extra?.SHOW_NETWORK_LOGS == 'true'
    ) {
      console.groupCollapsed(
        `%c${method} ${url} - fetch response`,
        'color: purple'
      )
      console.log(
        `RESPONSE.DATA:\n\n${JSON.stringify(response?.data, null, 4)}\n\n`
      )
      console.log(`RESPONSE:\n\n${JSON.stringify(response, null, 4)}`)
      console.groupEnd()
    }

    clearTimeout(clearNetworkTimeout)

    return response.data
  } catch (error) {
    let errorMessage =
      error?.response?.data?.message ??
      error?.response?.data?.error ??
      error?.response?.data?.detail

    // FIXME: Think about a better solution of error handling and error shape standarization

    if (Platform.OS === 'web') {
      const { account = {} } = store?.getState()?.accountStore

      Sentry.Browser.withScope((scope) => {
        scope.setTag('USED_ID', account?.userID)
        scope.setTag('USER_ACCOUNT_ID', account?.accountID)
        scope.setTag('USER_EMAIL', account?.email)

        scope.setTag('BASE_URL', BASE_URL ?? null)
        scope.setTag('FILE_NAME', pageDetails?.page)
        scope.setTag('FILE_LINE', pageDetails?.line)

        scope.setTag('URL_PATH', path ?? null)
        scope.setTag('URL_METHOD', method ?? null)
        scope.setTag('SERVER_ERROR_MESSAGE', errorMessage ?? null)
        scope.setTag('SERVER_ERROR_STATUS', error?.response?.status)

        Sentry.Browser.captureException(error)
      })
    } else {
      const { account = {} } = store?.getState()?.accountStore

      Sentry.Native.withScope((scope) => {
        scope.setTag('USED_ID', account?.userID)
        scope.setTag('USER_ACCOUNT_ID', account?.accountID)
        scope.setTag('USER_EMAIL', account?.email)

        scope.setTag('BASE_URL', BASE_URL ?? null)
        scope.setTag('FILE_NAME', pageDetails?.page)
        scope.setTag('FILE_LINE', pageDetails?.line)

        scope.setTag('URL_PATH', path ?? null)
        scope.setTag('URL_METHOD', method ?? null)
        scope.setTag('SERVER_ERROR_MESSAGE', errorMessage ?? null)
        scope.setTag('SERVER_ERROR_STATUS', error?.response?.status)

        Sentry.Native.captureException(error)
      })
    }

    if (axios.isCancel(error)) {
      errorMessage = error.message
    }

    if (
      (__DEV__ || process.env.NODE_ENV === 'development') &&
      process.env?.APP_MANIFEST?.extra?.SHOW_NETWORK_LOGS == 'true'
    ) {
      console.groupCollapsed(
        `%c${method} ${url} - fetch error location`,
        'color: purple'
      )
      console.log(`ERROR IN\n\n  ${pageDetails?.page}\n`)
      console.log(`ERROR URL\n\n  ${path}\n`)
      console.log(`ERROR IN LINE\n\n  ${pageDetails?.line}`)
      console.groupEnd()

      console.groupCollapsed(
        `%c${method} ${url} - fetch error config`,
        'color: purple'
      )
      console.log(
        `ERROR.CONFIG:\n\n  ${JSON.stringify(error?.config, null, 4)}`
      )
      console.groupEnd()

      console.groupCollapsed(
        `%c${method} ${url} - fetch error details`,
        'color: purple'
      )
      console.log(`ERROR STATUS:\n\n  ${error?.response?.status}\n`)
      console.log(`ERRORMESSAGE:\n\n  ${errorMessage}\n`)
      console.log(
        `ERROR.RESPONSE:\n\n  ${JSON.stringify(error?.response, null, 4)}\n`
      )
      console.log(`ERROR.MESSAGE:\n\n  ${error?.message}`)
      console.groupEnd()

      console.groupCollapsed(
        `%c${method} ${url} - fetch error request`,
        'color: purple'
      )
      console.log(
        `ERROR.REQUEST:\n\n  ${JSON.stringify(error?.request, null, 4)}\n`
      )
      console.log(`ERROR.REQUEST.RESPONSE:\n\n  ${error?.request?._response}`)
      console.groupEnd()
    }

    return {
      isError: true,
      error: errorMessage ?? 'Network request failed',
      status: error?.response?.status ?? 0,
      response: error?.response?.data ?? '',
    }
  }
}
