import BaseController from '$components/BaseController.js'

import Proband from '$data_models/Proband.js'
import Clinician from '$data_models/Clinician.js'

import { isEmptyObject } from '$api/_utils/Utils.js'
import ApiFetchHandler from '$api/ApiFetchHandler'

class ClinicianListController extends BaseController {
  sectionListData = []

  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  account = this.props.currentAccount || new Account()

  proband = this.props.currentProband || new Proband()

  clinicians = this.props.clinicians || new Clinician()

  constructor(props) {
    super(props)
    // This event will happen when the component loads into memory.

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    // This component event happens after render().

    this.props.navigation.setParams({
      headerRightButtonAction: this.navigateToAddClinician,
    })

    // this.getFamilyClinicians()

    // Set the component's willFocus event
    this.useFocusEvent()
    // Set the component's didFocus event
    this.setNavigationEventListener('focus')
  }

  setNavigationEventListener = (event = 'focus') => {
    // Set the component's navigation event listeners
    const { navigation } = this.props
    navigation.addListener(event, async () => {
      this.props.navigation.setParams({
        cs: this.props.colorSettings,
      })
    })
  }

  useFocusEvent = () => {
    const { navigation } = this.props
    navigation.addListener('focus', async () => {
      await this.getFamilyClinicians()
    })
  }

  reloadComponentData = async () => {
    // This function can be called to reload the component's data.

    await this.getFamilyClinicians()
  }

  navigateToAddClinician = () => {
    this.props.navigation.navigate('AddClinicianNavigator', {
      screen: 'AddClinicianCode',
    })
  }

  navigateToClinicianDetails = (item) => {
    // Save selected clinican data to redux store
    this.props._setSelectedClinicianAction_(item)

    // Temporarily unused.
    this.props.navigation.navigate('ClinicianDetailsView')
  }

  getFamilyClinicians = async (showLoading = true) => {
    // API request to get Family Clinicians.

    if (!this.proband || isEmptyObject(this.proband)) {
      return
    }

    if (showLoading) {
      this.toggleLoadingView(true)
    }

    this.sectionListData = []

    const familyClinicianPayload = {
      path: `family/${this.proband.familyID}/clinicians/`,
      method: 'get',
      token: this.authCredentials.accessToken,
      pageDetails: {
        page: 'ClinicianList.controller.js',
      },
    }

    const response = await ApiFetchHandler(familyClinicianPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.toggleLoadingView((shouldReloadView = false), (delay = 0))
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.props._setCliniciansAction_(response)

    if (response.length > 0) {
      const newData = {
        title: 'Clinicians',
        data: response,
      }
      this.sectionListData.push(newData)
    }

    this.toggleLoadingView(false, 0)
  }
}

export default ClinicianListController
