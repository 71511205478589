import React, { useState } from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'

import EllipseButton from '../../../../../components/EllipseButton'
import { adjustFontSizeByLength } from '../../../../../api/_utils/Utils.js'
import styles from './styles'
import { i18n } from '../../../../../localization/config.js'
import { LabelHandler } from '../../../helpers'

export default function YesOrNoField({
  colorSettings,
  setContainerStyle,
  masterQuestion,
  answer,
  saveCompletedSurveyAnswer,
}) {
  const [isToggled, setIsToggled] = useState(answer?.answer || null)

  const { question_label: questionLabel } = masterQuestion
  const handleYesBtnHighlight = () => {
    let bgColor = ''
    let borderColor = ''

    if (isToggled === null || isToggled === 'no') {
      bgColor = colorSettings?.selectable_btn_border_active_1
      borderColor = colorSettings?.selectable_btn_border_inactive_1
    } else if (isToggled === 'yes') {
      bgColor = colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      borderColor = colorSettings?.selectable_btn_border_active_1
    }

    return { backgroundColor: bgColor, borderColor }
  }

  const handleNoBtnHighlight = () => {
    let bgColor = ''
    let borderColor = ''

    if (isToggled === null || isToggled === 'yes') {
      bgColor = colorSettings?.selectable_btn_border_active_1
      borderColor = colorSettings?.selectable_btn_border_inactive_1
    } else if (isToggled === 'no') {
      bgColor = colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      borderColor = colorSettings?.selectable_btn_border_active_1
    }
    return { backgroundColor: bgColor, borderColor }
  }

  const buildPayload = (getAnswer, repeatClick) => {
    const payload = {
      field_type: 'yes_or_no',
      master_question_id: masterQuestion.id,
      master_question_choice_id: null,
      answer: getAnswer,
      repeat_click: repeatClick,
    }

    return payload
  }

  const onPressHandler = (value) => {
    if (isToggled === value) {
      setIsToggled('')
      const payload = buildPayload(null, true)
      saveCompletedSurveyAnswer(payload)
    } else {
      setIsToggled(value)
      const payload = buildPayload(value, false)
      saveCompletedSurveyAnswer(payload)
    }
  }

  return (
    <View style={[setContainerStyle]}>
      {LabelHandler({
        questionLabel: i18n.t(questionLabel).default || questionLabel,
        colorSettings,
        masterQuestion,
      })}

      <View style={[styles.buttonContainerStyle]}>
        <EllipseButton
          onPress={() => onPressHandler('yes')}
          buttonStyle={[styles.buttonStyle, handleYesBtnHighlight()]}
        >
          <Text
            style={[
              styles.roundedButtonTitleText,
              {
                color: colorSettings?.selectable_btn_text_active_1,
                fontSize: adjustFontSizeByLength('Yes'),
              },
            ]}
          >
            {i18n.t('yes').title}
          </Text>
        </EllipseButton>
        <EllipseButton
          onPress={() => onPressHandler('no')}
          buttonStyle={[styles.buttonStyle, handleNoBtnHighlight()]}
        >
          <Text
            style={[
              styles.roundedButtonTitleText,
              {
                color: colorSettings?.selectable_btn_text_active_1,
                fontSize: adjustFontSizeByLength('No'),
              },
            ]}
          >
            {i18n.t('no').title}
          </Text>
        </EllipseButton>
      </View>
    </View>
  )
}

YesOrNoField.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.objectOf(PropTypes.string),
  answer: PropTypes.number,
  saveCompletedSurveyAnswer: PropTypes.func,
  setContainerStyle: PropTypes.objectOf(PropTypes.string),
}

YesOrNoField.defaultProps = {
  colorSettings: [],
  answer: '',
  saveCompletedSurveyAnswer: () => {},
  setContainerStyle: {},
}
