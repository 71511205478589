import React from 'react'

import {
  Image,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput,
  SafeAreaView,
  Platform,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { connect } from 'react-redux'
import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'
import LoadingView from '$constants/LoadingView.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { yearIntervalFromNow } from '$api/_utils/Utils.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'
import custom_styles from './styles/main.styles.js'
import { WEB } from '$constants/Platforms.js'

import { i18n } from '$localization/config.js'
// redux actions
import { setProbandClinicianCode } from '$redux/proband/actions'
import {
  setSelectedClinicianAction,
  resetClinicianStore,
} from '$redux/clinician/actions'

import { setError } from '$redux/defaults/actions.js'

const inputAccessoryViewID = 'SignupClinicianCode'

let silent = false

class SignupClinicianCode extends React.Component {
  account = this.props.account

  authCredentials = this.props.authCredentials

  colorSettings = this.props.colorSettings

  clinicianLogoImage = null

  constructor(props) {
    super(props)

    this.state = {
      clinicianCode: '',
      showLoadingView: false,
      shouldDisplayClinicianLogo: false,
      logoURI: null,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    const { navigation, colorSettings } = this.props

    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    navigation.setParams({
      cs: colorSettings,
    })
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state === nextState) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  clinicianCheck = async (silent = false) => {
    let clinicianCode = this.state.clinicianCode || ''
    clinicianCode = clinicianCode.trim()

    const clinicianCheckPayload = {
      path: 'clinician/check/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        clinician_code: clinicianCode,
      },
      pageDetails: {
        page: 'SignupClinicianCode.js',
      },
    }

    const response = await ApiFetchHandler(clinicianCheckPayload)

    if (response?.isError) {
      this.setState({
        showLoadingView: false,
        shouldDisplayClinicianLogo: false,
        logoURI: null,
      })

      setTimeout(() => {
        this.showCodeError()
      }, 100)
      return
    }

    // check data for clinician code id

    this.props._setSelectedClinicianAction_(response)

    this.setState({
      showLoadingView: false,
      shouldDisplayClinicianLogo: true,
      logoURI: response.logo,
    })

    if (!silent) {
      // Update Proband data.
      this.props._saveProbandClinicianCode_(clinicianCode)

      // Navigate to next screen.
      await this.navigateToSuccessScreen(response.clinician_code.id)
    }
  }

  connectProbandClinician = async (clinicianCode) => {
    if (!clinicianCode) return

    const { authCredentials, proband } = this.props

    const clinicianConnectPayload = {
      path: `family/${proband.familyID}/add_clinician/`,
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        proband_id: proband.probandID,
        clinician_code_id: clinicianCode,
      },
      pageDetails: {
        page: 'SignupClinicianCode.js',
      },
    }

    return await ApiFetchHandler(clinicianConnectPayload)
  }

  nextButtonAction = async () => {
    // DONE: reference to redux account
    // Calculate current age using date of birth.
    if (this.account.dob && this.account.dob !== '') {
      this.account.age = yearIntervalFromNow(this.account.dob) || 0
    }

    let clinicianCode = this.state.clinicianCode || ''
    clinicianCode = clinicianCode.trim()

    if (clinicianCode === '') {
      // Skip and navigate to next screen.
      this.navigateToSuccessScreen()
      this.props._resetClinicianStore_()
      return
    }

    this.setState({ showLoadingView: true })

    await this.clinicianCheck(false)
    silent = false
  }

  showCodeError = () => {
    const message = [
      i18n.t('sorry_the_clinician_code_you_entered_does_not_exist').default,
      i18n.t('please_contact_your_clinician').default,
      i18n.t(
        'or_you_can_skip_this_screen_by_removing_the_code_you_entered_and_pressing_next'
      ).default,
    ].join(' ')

    showDefaultAlert(i18n.t('invalid_clinician_code').default, message)
  }

  navigateToSuccessScreen = async (clinicianCodeID) => {
    const response = await this.connectProbandClinician(clinicianCodeID)

    const { saveError, navigation } = this.props

    if (response?.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    navigation.navigate('SuccessScreenAccountCreated', {
      clinicianCodeID,
    })
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled || 'white',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }

    const isValid = this.validateInput()
    if (isValid) {
      style.backgroundColor =
        this.colorSettings?.bottom_next_btn_enabled || 'white'
      style.borderColor = this.colorSettings?.bottom_next_btn_enabled
    }

    return style
  }

  toggleSkipText = () => {
    const { clinicianCode } = this.state
    if (clinicianCode.length >= 1) {
      return i18n.t('next').default
    }
    return i18n.t('skip').default
  }

  validateInput = () => {
    if (this.state.clinicianCode && this.state.clinicianCode.length >= 6) {
      return true
    }
    return false
  }

  onErrorLoadImage = () => {
    this.setState((prevState) => ({
      ...prevState,
      logoURI: null,
      showLoadingView: false,
    }))
  }

  renderClinicianLogo = (show = false) => {
    if (!show) {
      return (
        <View
          nativeID="signup_clinician_logo_container"
          style={[
            custom_styles.clinicianLogoContainer,
            {
              backgroundColor: 'transparent',
              marginTop: 16,
            },
          ]}
        />
      )
    }

    return (
      <View
        nativeID="signup_clinician_logo_container"
        style={[
          custom_styles.clinicianLogoContainer,
          {
            backgroundColor:
              this.colorSettings?.btn_no_fill_border_2 || 'transparent',
            marginTop: 16,
            justifyContent: 'center',
          },
        ]}
      >
        <Image
          style={{ resizeMode: 'contain', height: '100%' }}
          source={{ uri: this.state.logoURI }}
        />
      </View>
    )
  }

  onEndEditingClinicianCode = async () => {
    if (this.state.clinicianCode.trim() === '') {
      return
    }
    this.setState({ showLoadingView: true })

    await this.clinicianCheck(true)
    silent = true
  }

  render() {
    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            global_styles.container,
            {
              backgroundColor:
                this.colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          <ScrollView
            style={{ height: this.state.scrollViewHeight }}
            showsHorizontalScrollIndicator={false}
          >
            <View
              style={[
                global_styles.contentContainerFullHeight,
                {
                  marginVertical: 16,
                  justifyContent: 'center',
                },
              ]}
            >
              <Text
                style={[
                  global_styles.titleText,
                  {
                    color:
                      this.colorSettings?.text_input_title_color_2 || 'black',
                  },
                ]}
              >
                {
                  i18n.t(
                    'invited_by_a_clinician_enter_the_clinician_code_below_otherwise_skip_this_step'
                  ).default
                }
              </Text>

              <View style={{ marginVertical: 30 }}>
                <TextInput
                  nativeID="text_input_web"
                  style={[
                    global_styles.mainTextInput,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                    },
                  ]}
                  inputAccessoryViewID={inputAccessoryViewID}
                  maxLength={30}
                  placeholder={i18n.t('enter_clinician_code').default}
                  placeholderTextColor={
                    this.colorSettings?.text_input_placeholder_color_2 ||
                    'rgba(74,74,74,0.5)'
                  }
                  selectionColor={this.colorSettings?.text_input_color_2}
                  autoCorrect={false}
                  autoCapitalize="none"
                  onBlur={() => this.onEndEditingClinicianCode()}
                  onChangeText={(value) =>
                    this.setState({ clinicianCode: value })
                  }
                  value={this.state.clinicianCode}
                />
              </View>

              {this.renderClinicianLogo(this.state.shouldDisplayClinicianLogo)}
            </View>
          </ScrollView>

          <View style={global_styles.nextButtonContainer}>
            <TouchableOpacity
              // style={[global_styles.nextButton, this.toggleNextButtonColor()]}
              style={[
                global_styles.nextButton,
                {
                  backgroundColor:
                    this.colorSettings?.bottom_next_btn_enabled || 'white',
                  borderColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={() => this.nextButtonAction()}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.colorSettings?.btn_filled_text_1,
                  },
                ]}
              >
                {this.toggleSkipText()}
              </Text>
            </TouchableOpacity>
          </View>

          <NextButtonAccessoryView
            nativeID={inputAccessoryViewID}
            // style={[global_styles.nextButton, this.toggleNextButtonColor()]}
            style={[
              global_styles.nextButton,
              {
                backgroundColor:
                  this.colorSettings?.bottom_next_btn_enabled || 'white',
                borderColor: this.colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            backgroundColor={this.colorSettings?.onboarding_bgcolor}
            labelText={i18n.t('done').default}
            labelColor={this.colorSettings?.btn_filled_text_1}
            onPress={() =>
              console.log(
                '🚀 \n\n file: SignupClinicianCode.js:422 \n\n SignupClinicianCode \n\n render \n\n onPress'
              )
            }
          />

          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, clinicianStore } = state

  return {
    colorSettings: store.colorSettings,
    account: accountStore.account,
    authCredentials: accountStore.authCredentials,
    probandProfile: probandStore.probandProfile,
    proband: probandStore.proband,
    clinicians: clinicianStore.clinicians,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _saveProbandClinicianCode_: (code) => dispatch(setProbandClinicianCode(code)),

  _setSelectedClinicianAction_: (data) =>
    dispatch(setSelectedClinicianAction(data)),

  _resetClinicianStore_: () => dispatch(resetClinicianStore()),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupClinicianCode)
