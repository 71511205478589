import { Component } from 'react'
import {
  Animated,
  Dimensions,
  View,
  Image,
  ImageBackground,
  Modal,
  TouchableOpacity,
} from 'react-native'

import { connect } from 'react-redux'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import Constants from 'expo-constants'

import Page0 from './pages/page0'
import Page1 from './pages/page1'

import { IS_WEB, IS_MOBILE } from '$constants/Platforms.js'

import styles from '$screens/relatives/_styles/tutorial.modal.styles.js'
import { webModalSizing } from '$screens/_utils/webModalSizing'
import { webModalBackgroundImageSizing } from '$screens/_utils/webModalBackgroundImageSizing'

class TutorialModal extends Component {
  dimensions = Dimensions.get('window')

  screenWidth = this.dimensions.width

  screenHeight = this.dimensions.height

  currentContentWidth = this.screenWidth - 50

  currentContentHeight = this.screenHeight / 1.3

  onDismiss = this.props.onDismiss ?? function () {}

  carouselData = [
    { key: 0, contentHeight: this.currentContentHeight },
    { key: 1, contentHeight: this.currentContentHeight + 150 },
  ]

  carouselContentWidth = this.currentContentWidth - 5

  carouselContentHeight = this.currentContentHeight

  tappableDots = false

  dotsDistance = 6

  autoplay = false

  carouselInterval = 5000

  state = {
    activePaginatorIndex: 0,
    visible: this.props.visible,
    currentWidth: Dimensions.get('window').width,
    currentHeight: Dimensions.get('window').height,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      visible: newProps.visible,
    }
  }

  constructor(props) {
    super(props)

    if (IS_WEB()) {
      this.tappableDots = true
      this.autoplay = true
      this.dotsDistance = 15
    }
  }

  onChangeDimensions = () => {
    this.setState({
      currentWidth: Dimensions.get('window').width,
      currentHeight: Dimensions.get('window').height,
    })
  }

  componentDidMount() {
    this.dimensionSubscription = Dimensions.addEventListener(
      'change',
      this.onChangeDimensions
    )
  }

  componentWillUnmount() {
    this.dimensionSubscription?.remove()
  }

  animateContentHeight = (initialHeight, nextHeight) => {
    this.currentContentHeight = new Animated.Value(initialHeight)

    const calculatedNextHeight =
      nextHeight > this.screenHeight
        ? this.screenHeight - Constants?.statusBarHeight * 2
        : nextHeight

    Animated.timing(this.currentContentHeight, {
      toValue: calculatedNextHeight,
      duration: 200,
    }).start()
  }

  dismissButtonAction = () => {
    this.onDismiss()
  }

  renderItem = ({ item, index }) => {
    const { colorSettings } = this.props
    switch (index) {
      case 0:
        return (
          <Page0
            colorSettings={colorSettings}
            screenWidth={this.screenWidth}
            parentClass={this}
          />
        )
      case 1:
        return (
          <Page1
            colorSettings={colorSettings}
            screenWidth={this.state.currentWidth}
            parentClass={this}
          />
        )
    }
  }

  renderDots = (activeIndex) => {
    const { colorSettings } = this.props
    const layout = this.carouselData.map((screen, i) => (
      <TouchableOpacity
        style={[
          styles.paginatorDotStyleWeb,
          { marginHorizontal: this.dotsDistance },
        ]}
        key={i}
        onPress={() => {
          if (!this.tappableDots) return
          this._carousel?.stopAutoplay()
          this._carousel?._snapToItem(this._carousel?._getPositionIndex(i))
        }}
      >
        <View
          style={[
            styles.paginatorDotStyleWeb,
            {
              backgroundColor:
                activeIndex === i
                  ? colorSettings?.pagination_dot_active
                  : colorSettings?.pagination_dot_inactive,
            },
          ]}
        />
      </TouchableOpacity>
    ))
    return layout
  }

  render() {
    const { colorSettings } = this.props
    const { activePaginatorIndex, visible, currentWidth } = this.state

    const currentCarouselContentHeight =
      this.carouselData[activePaginatorIndex]?.contentHeight

    const widthBreakpointsWeb = webModalSizing(currentWidth)
    const photoHeight = webModalBackgroundImageSizing(currentWidth)

    return (
      <Modal animationType="fade" visible={visible} transparent>
        <View style={[styles.container, { paddingHorizontal: 20 }]}>
          <View style={styles.contentContainer}>
            <Animated.View
              style={[
                styles.content,
                {
                  width: widthBreakpointsWeb,
                  height: this.currentContentHeight,
                  borderRadius: 10,
                  overflow: 'hidden',
                },
              ]}
            >
              <View
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <ImageBackground
                  source={require('$assets/images/new_assets/modal-bg-family.png')}
                  style={[styles.contentHeader, { height: photoHeight }]}
                  imageStyle={{ resizeMode: 'stretch' }}
                >
                  <View style={styles.dismissButtonView}>
                    <TouchableOpacity
                      onPress={() => this.dismissButtonAction()}
                    >
                      <Image
                        source={require('$assets/images/new_assets/icon-close-modal-w.png')}
                        style={{ width: 24, height: 24 }}
                      />
                    </TouchableOpacity>
                  </View>
                </ImageBackground>

                {IS_MOBILE() && (
                  <Carousel
                    ref={(ref) => (this._carousel = ref)}
                    data={this.carouselData}
                    sliderWidth={widthBreakpointsWeb}
                    itemWidth={widthBreakpointsWeb}
                    itemHeight={currentCarouselContentHeight}
                    onSnapToItem={(index) => {
                      this.setState({ activePaginatorIndex: index })
                      this.animateContentHeight(
                        currentCarouselContentHeight,
                        this.carouselData[index]?.contentHeight - 50
                      )
                    }}
                    renderItem={this.renderItem}
                  />
                )}
                {IS_WEB() && (
                  <Carousel
                    layout="default"
                    ref={(ref) => (this._carousel = ref)}
                    data={this.carouselData}
                    sliderWidth={widthBreakpointsWeb}
                    itemWidth={widthBreakpointsWeb}
                    itemHeight={currentCarouselContentHeight}
                    onSnapToItem={(index) => {
                      this.setState({ activePaginatorIndex: index })
                      this.animateContentHeight(
                        currentCarouselContentHeight,
                        this.carouselData[index]?.contentHeight
                      )
                    }}
                    inactiveSlideOpacity={0}
                    inactiveSlideScale={0.9}
                    activeAnimationType="spring"
                    activeAnimationOptions={{ friction: 10 }}
                    autoplay={this.autoplay}
                    autoplayDelay={0}
                    autoplayInterval={this.carouselInterval}
                    renderItem={this.renderItem}
                  />
                )}

                {IS_MOBILE() && (
                  <Pagination
                    containerStyle={styles.paginatorContainer}
                    dotsLength={this.carouselData.length}
                    activeDotIndex={activePaginatorIndex}
                    dotStyle={[
                      styles.paginatorDotStyle,
                      {
                        backgroundColor:
                          colorSettings?.pagination_dot_active ??
                          'rgba(74,74,74,1)',
                      },
                    ]}
                    inactiveDotStyle={[
                      styles.paginatorDotStyle,
                      {
                        backgroundColor:
                          colorSettings?.pagination_dot_inactive ??
                          'rgba(74,74,74,0.5)',
                      },
                    ]}
                    inactiveDotScale={1.0}
                    carouselRef={this._carousel}
                  />
                )}
                {IS_WEB() && (
                  <Pagination
                    containerStyle={styles.paginatorContainer}
                    dotsLength={this.carouselData.length}
                    activeDotIndex={activePaginatorIndex}
                    dotColor={colorSettings?.pagination_dot_active}
                    inactiveDotColor={colorSettings?.pagination_dot_inactive}
                    dotStyle={[
                      styles.paginatorDotStyleWeb,
                      {
                        backgroundColor:
                          colorSettings?.pagination_dot_active ??
                          'rgba(74,74,74,1)',
                      },
                    ]}
                    inactiveDotStyle={[
                      styles.paginatorDotStyleWeb,
                      {
                        backgroundColor:
                          colorSettings?.pagination_dot_inactive ??
                          'rgba(74,74,74,0.5)',
                      },
                    ]}
                    inactiveDotScale={1.0}
                    carouselRef={this._carousel}
                    tappableDots={this.tappableDots}
                    renderDots={this.renderDots}
                  />
                )}
              </View>
            </Animated.View>
          </View>
        </View>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(TutorialModal)
