import { TouchableOpacity, Image, View, SafeAreaView, Text } from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import ClinicianDetailsController from './ClinicianDetails.controller.js'

import LoadingView from '$constants/LoadingView.js'

import { setError } from '$redux/defaults/actions.js'

import { isEmptyObject } from '$api/_utils/Utils.js'

import { ConnectionStatusColor } from '$screens/main/dashboard/clinicians/_utils/utils.js'

import global_styles from '$constants/styles/global.styles.js'
import styles from '../_styles/main.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

class ClinicianDetailsView extends ClinicianDetailsController {
  renderClinicianConnectionButton = () => {
    let buttonStyle = [
      global_styles.normalButtonFull,
      { borderColor: this.colorSettings?.btn_no_fill_border_3, width: '40%' },
    ]
    let buttonTextStyle = [
      global_styles.normalButtonText,
      { color: this.colorSettings?.btn_no_fill_text_3 },
    ]
    let buttonText = i18n.t('disconnect_clinician').default

    const { clinician } = this.state
    if (!clinician || isEmptyObject(clinician)) {
      return null
    }
    if (clinician.status === 'disconnected') {
      buttonStyle = [
        global_styles.normalTealButtonFull,
        {
          backgroundColor: this.colorSettings?.btn_filled_enabled_1,
          width: '40%',
        },
      ]
      buttonTextStyle = [
        global_styles.normalTealButtonText,
        { color: this.colorSettings?.btn_filled_text_1 },
      ]
      buttonText = i18n.t('reconnect').default
    }

    return (
      <TouchableOpacity
        ref={(ref) => (this.clinicianConnectionButton = ref)}
        style={buttonStyle}
        activeOpacity={0.5}
        onPress={() => this.clinicianConnectionButtonAction(clinician)}
      >
        <Text style={buttonTextStyle}>{buttonText}</Text>
      </TouchableOpacity>
    )
  }

  render() {
    const { navigation } = this.props
    const { clinician } = this.state
    // let clinician = this.props.selectedClinician;

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView style={styles.container}>
          <LoadingView
            visible={this.state.showLoadingView}
            tintColor={this.colorSettings?.post_onboarding_nav_bgcolor}
            textColor={this.colorSettings?.post_onboarding_nav_bgcolor}
          />
        </SafeAreaView>
      )
    }

    if (!clinician || isEmptyObject(clinician)) {
      return null
    }

    let _statusText_ = 'pending'
    switch (clinician.status) {
      case 'two-way-connected':
        _statusText_ = 'connected'
        break
      case 'one-way-connected':
        _statusText_ = 'connected_clinician_opted_out_of_updates'
        break
      default:
        _statusText_ = clinician.status
        break
    }

    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[styles.container, { height: this.state.scrollViewHeight }]}
          nativeID="web_scaled_main_container"
        >
          <View style={styles.itemViewFull}>
            <View style={styles.itemImageContainer}>
              <Image
                source={require('$assets/images/new_assets/avatar-clinician.png')}
                style={{ width: 40, height: 40, resizeMode: 'contain' }}
              />
            </View>
            <View style={styles.itemDetailsContainer}>
              <Text style={styles.itemDetailsTitleText}>
                {clinician.organization.org_name}
              </Text>
              <View style={{ height: 5 }} />
              <Text
                style={[
                  styles.itemDetailsSubtitleText,
                  {
                    color: ConnectionStatusColor(
                      clinician.status,
                      this.colorSettings
                    ),
                  },
                ]}
              >
                {i18n.t(_statusText_).default}
              </Text>
            </View>
          </View>

          <View
            style={{
              alignItems: 'center',
              paddingVertical: 25,
              paddingHorizontal: 16,
            }}
          >
            {this.renderClinicianConnectionButton()}
          </View>

          <LoadingView
            visible={this.state.showLoadingView}
            tintColor={this.colorSettings?.post_onboarding_nav_bgcolor}
            textColor={this.colorSettings?.post_onboarding_nav_bgcolor}
          />
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, clinicianStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** Clinican Store */
    clinicians: clinicianStore.clinicians,
    selectedClinician: clinicianStore.selectedClinician,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(ClinicianDetailsView)
