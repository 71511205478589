import { useSelector } from 'react-redux'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import styles from '../main.styles'
import { CustomTextInput } from '../../sign_up/SignupScreen.main'
import { i18n } from '$localization/config.js'

const LOGIN_SCREEN_INPUT = 'login_screen_input'

function LoginInputs({
  hasUserEmailEntered,
  email,
  setEmail,
  showPassword,
  password,
  setPassword,
  setShowPassword,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const parentRefs = {
    emailInputRef: null,
    passwordInputRef: null,
  }

  const setParentRef = (key, ref) => {
    if (!key || !ref) return
    parentRefs[key] = ref
  }

  const shouldShowPassword = showPassword
    ? require('$assets/images/icon/eye-close/black.png')
    : require('$assets/images/icon/eye/black.png')

  return (
    <View style={styles.signUpContainer}>
      <CustomTextInput
        setRef={(ref) => setParentRef('emailInputRef', ref)}
        nativeID={LOGIN_SCREEN_INPUT}
        colorSettings={colorSettings}
        label={i18n.t('email')?.default}
        keyboardType="email-address"
        disabled={hasUserEmailEntered}
        onSubmitEditing={() => parentRefs.passwordInputRef.focus()}
        value={email}
        onChangeText={(value) => {
          setEmail(value)
        }}
      />
      <View style={styles.textInputPasswordContainer}>
        <CustomTextInput
          containerStyle={{ flex: 1 }}
          inputStyle={[
            styles.textInputField,
            styles.textInputPassword,
            {
              color: colorSettings?.text_input_color_1 ?? 'black',
            },
          ]}
          setRef={(ref) => setParentRef('passwordInputRef', ref)}
          nativeID={LOGIN_SCREEN_INPUT}
          colorSettings={colorSettings}
          label={i18n.t('password')?.default}
          secureTextEntry={showPassword}
          value={password}
          onChangeText={(value) => {
            setPassword(value)
          }}
        />
        <View
          style={[
            styles.showPasswordContainer,
            {
              borderBottomColor: colorSettings?.text_input_label_color_1,
            },
          ]}
        >
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={() => setShowPassword(!showPassword)}
          >
            <Image
              style={styles.showPasswordImage}
              source={shouldShowPassword}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.helperTextContainer}>
        <Text
          style={[
            styles.passwordHelperText,
            {
              color: colorSettings?.text_input_label_color_1,
            },
          ]}
        >
          {
            i18n.t(
              'minimum_of_8_characters_one_uppercase_letter_one_lowercase_letter_one_number'
            )?.default
          }
        </Text>
      </View>
    </View>
  )
}

export default LoginInputs
