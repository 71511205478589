import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
    backgroundColor: 'rgba(155,89,182,1.0)',
  },
  contentContainer: {
    // backgroundColor: "red",
    flex: 1.0,
    marginHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },

  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },

  content: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalContent: {
    // backgroundColor: "lime",
    flexDirection: 'row',
    height: '100%',
  },

  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
  },

  roundedTileButtonContainer: {},
  roundedTileButton: {
    // backgroundColor: "pink",
    borderRadius: 20,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundedTileButtonText: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
  },

  maleIcon: {
    backgroundColor: 'white',
    marginTop: 8,
    marginBottom: 16,
    width: 40,
    height: 40,
  },
  femaleIcon: {
    backgroundColor: 'white',
    marginTop: 8,
    marginBottom: 16,
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  },

  linkedText: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 14,
    textDecorationLine: 'underline',
  },

  nextButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: 'rgba(255,255,255,1.0)',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})

export default styles
