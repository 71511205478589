import { StyleSheet } from 'react-native'

const pink = 'rgba(155,89,182,1.0)'
const white = 'white'
const grey = 'rgba(255,255,255,0.5)'
const green = 'rgba(132,209,192,1.0)'
const darkGrey = 'rgba(255,255,255,.3)'
const trans = 'transparent'

export default StyleSheet.create({
  container: {
    backgroundColor: pink,
    flex: 1.0,
  },
  contentContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: white,
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginBottom: 15,
  },

  subTitleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: white,
    fontFamily: 'karla-bold',
    fontSize: 18,
  },

  itemButton: {
    marginTop: 5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 60,
    borderRadius: 30,
    flexDirection: 'row',
    backgroundColor: grey,
  },
  itemButtonText: {
    color: white,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    letterSpacing: 1,
    lineHeight: 22,
    textAlign: 'left',
    flex: 1,
    marginLeft: 24,
  },

  nextButton: {
    justifyContent: 'center',
    backgroundColor: green,
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: white,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  horizontalBorder: {
    width: '120%',
    borderBottomWidth: 0.7,
    borderBottomColor: darkGrey,
    marginTop: 40,
    alignSelf: 'center',
  },
  nextButtonContainer: {
    height: 60,
  },
  searchInputMainContainer: {
    width: '100%',
    backgroundColor: trans,
    maxWidth: 380,
  },
  searchInputInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  searchInputList: {
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 10,
    minHeight: 80,
  },
})
