import React from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web
import {
  capitalizeFirstLetter,
  getFamilySideAndRelationshipGender,
} from '$api/_utils/Utils.js'

import { fixDiseaseName } from '$api/_utils/Utils'

import { i18n } from '$localization/config.js'
import defaultHeaderSettings from '$constants/navigationConstants'

import PersonalGender from '$screens/personal_details/gender/PersonalGender.js'
import PersonalGenderIdentity from '$screens/personal_details/gender_identity/PersonalGenderIdentity.js'
import PersonalScreenEthnicity from '$screens/personal_details/PersonalScreenEthnicity'
import Ancestry from '$screens/personal_details/Ancestry'
import PersonalScreenDiagnosis from '$screens/personal_details/PersonalScreenDiagnosis'
import PositiveGeneTestOnboarding from '$screens/personal_details/genetic_testing/PositiveGeneTestOnboarding'
import PersonalScreenDiagnosisC from '$screens/personal_details/PersonalScreenDiagnosisC'
import PersonalScreenDiagnosisD from '$screens/personal_details/PersonalScreenDiagnosisD'
import SkipLogicScreen from '$screens/personal_details/personalScreenDiagnosis_components/skipLogicComponent.js'
import DiseaseListMain from '$screens/personal_details/onboarding_disease_selection/DiseaseMainList.js'
import CustomScreen from '$screens/CustomScreen'

import DiseaseSubList from '$screens/personal_details/onboarding_disease_selection/DiseaseSubList.js'

import DiseaseSkipLogic from '$screens/personal_details/onboarding_disease_selection/DiseaseSkipLogic'

import SuccessScreenPersonalHealthInfo from '$screens/success_screens/SuccessScreenPersonalHealthInfo'

import headerNavigationDefaultOptions from '../_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '../_components/HeaderTitleComponent'
import LeftButtonComponent from '../_components/LeftButtonComponent'

import FamilyMembersCancer from '$screens/onboarding/FamilyMembersCancer'
import EditProfileView from '$screens/main/family/edit_profile/EditProfile.view.js'
import FamilyListScreen from '$screens/main/family/FamilyListScreen/FamilyListScreen'
import PositiveGeneTestMain from '$screens/personal_details/genetic_testing/PositiveGeneTestMain.js'
import NegativeGeneTesting from '$screens/personal_details/genetic_testing/NegativeGeneTesting.js'

import ExpandAndCollapseButtons from '../main/family/components/ExpandAndCollapseButtons'
import AddFamilyMember from '../../screens/main/family/AddFamilyMemberScreen/AddFamilyMember'
import SwitchToFamgenixAccount from '../../screens/onboarding/SwitchToFamgenixAccount'
import RisksScreen from '$screens/main/risks/RisksScreen'
import GeneticResourcesView from '$screens/main/dashboard/geneticResources/GeneticResources.view'
import CustomWebView from '$components/CustomWebView.component.js'
import RiskInfoView from '$screens/main/risks/risk_info/RiskInfo.view.js'
import CustomSuccessScreen from '$screens/success_screens/CustomSuccessScreen.js'

const Stack = createStackNavigator()

function PersonalDetailsNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="PersonalGender"
      screenOptions={{ ...defaultHeaderSettings }}
    >
      <Stack.Screen
        name="PersonalGender"
        component={PersonalGender}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'personal_details/gender',
      />
      <Stack.Screen
        name="PersonalGenderIdentity"
        component={PersonalGenderIdentity}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'personal_details/gender_identity',
      />
      <Stack.Screen
        name="PersonalDiagnosis"
        component={PersonalScreenDiagnosis}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PersonalGeneticTesting"
        component={PositiveGeneTestOnboarding}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PersonalScreenDiagnosisC"
        component={PersonalScreenDiagnosisC}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PersonalScreenDiagnosisD"
        component={PersonalScreenDiagnosisD}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PersonalEthnicity"
        component={PersonalScreenEthnicity}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PersonalAncestry"
        component={Ancestry}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="skipLogic"
        component={SkipLogicScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DiseaseListMainScreen"
        component={DiseaseListMain}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'personal_details/diseases'
      />
      <Stack.Screen
        name="OtherDiseasesList"
        component={DiseaseSubList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'personal_details/diseases/subcategory',
      />
      <Stack.Screen
        name="DiseaseSkipLogic"
        component={DiseaseSkipLogic}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'personal_details/diseases/skiplogic'
      />
      <Stack.Screen
        name="SurveyCustom"
        component={CustomScreen}
        options={({ navigation, route }) => {
          const isFirstPage = route?.params?.currentPage === 0

          return isFirstPage
            ? {
                headerShown: true,
                ...headerNavigationDefaultOptions(
                  colorSettings?.nav_title_light,
                  colorSettings?.onboarding_bgcolor
                ),
                headerLeft: () => (
                  <LeftButtonComponent
                    navigation={navigation}
                    closeButtonType="back"
                    backArrowColor={colorSettings?.nav_title_light}
                  />
                ),
              }
            : {
                headerShown: true,
                ...headerNavigationDefaultOptions(
                  colorSettings?.nav_title_light,
                  colorSettings?.onboarding_bgcolor
                ),
              }
        }}
      />
      <Stack.Screen
        name="CustomScreen"
        component={CustomScreen}
        options={{
          headerShown: true,
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_bgcolor
          ),
        }}
      />
      <Stack.Screen
        name="FamilyMembersCancer"
        component={FamilyMembersCancer}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('family').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="AddFamilyMemberViewCancer"
        component={AddFamilyMember}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('family').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="FamilyMainViewCancer"
        component={FamilyListScreen}
        options={({ navigation, route }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('family').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="hidden"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
          // })}
          headerRight: () => {
            const handleExpandFamilyMembers =
              route.params?.handleExpandFamilyMembers

            const handleCollapseFamilyMembers =
              route.params?.handleCollapseFamilyMembers

            return (
              <ExpandAndCollapseButtons
                handleExpandFamilyMembers={handleExpandFamilyMembers}
                handleCollapseFamilyMembers={handleCollapseFamilyMembers}
              />
            )
          },
        })}
        initialParams={{
          isComingFromFamilyMembersNavigator: true,
        }}
      />
      <Stack.Screen
        name="EditProfileCancer"
        component={EditProfileView}
        options={({ navigation, route }) => {
          const getCurrentMember = route.params?.cm

          const gender = getCurrentMember?.gender ?? null

          const relationshipType =
            getCurrentMember?.relationship_to_proband ?? null

          const relationshipToProband = getFamilySideAndRelationshipGender(
            relationshipType,
            gender
          )

          const getRelationship = relationshipToProband[0]?.toLowerCase()

          const getFamilySide = relationshipToProband[1]?.toLowerCase()

          let relationship =
            i18n.t(fixDiseaseName(getRelationship))?.default ?? getRelationship

          let familySide = i18n.t(getFamilySide)?.default ?? getFamilySide

          relationship = capitalizeFirstLetter(relationship, true, ' ')
          familySide = capitalizeFirstLetter(familySide, true, ' ')

          return {
            ...headerNavigationDefaultOptions(
              colorSettings?.nav_title_light,
              colorSettings?.post_onboarding_nav_bgcolor
            ),
            headerTitle: () => (
              <HeaderTitleComponent
                headerTitle={relationship}
                headerSubtitle={familySide}
                headerTintColor={colorSettings?.nav_title_light}
                headerBackgroundColor={
                  colorSettings?.post_onboarding_nav_bgcolor
                }
              />
            ),
            headerLeft: () => (
              <LeftButtonComponent
                navigation={navigation}
                closeButtonType="back"
                backArrowColor={colorSettings?.nav_title_light}
              />
            ),
          }
        }}
        initialParams={{ isComingFromFamilyMembersNavigator: true }}
      />
      <Stack.Screen
        name="RisksScreenView"
        component={RisksScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('risks').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="GeneticResources"
        component={GeneticResourcesView}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('genetic_resources').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen name="CustomWebView" component={CustomWebView} />
      <Stack.Screen
        name="RiskInfoView"
        component={RiskInfoView}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('risks').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="OnboardingNegativeGeneTesting"
        component={NegativeGeneTesting}
      />
      <Stack.Screen
        name="OnboardingPositiveGeneTesting"
        component={PositiveGeneTestMain}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />

      <Stack.Screen
        name="CustomSuccessScreen"
        component={CustomSuccessScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light || 'black',
            colorSettings?.onboarding_nav_bgcolor || 'white'
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle=""
              headerTintColor={colorSettings?.nav_title_light || 'black'}
              headerBackgroundColor={
                colorSettings?.onboarding_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.onboarding_nav_bgcolor || 'white'}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SwitchToFamgenixAccount"
        component={SwitchToFamgenixAccount}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark || 'black',
            colorSettings?.splash_nav_bgcolor || 'white'
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle="FamGenix Account"
              headerTintColor={colorSettings?.nav_title_dark || 'black'}
              headerBackgroundColor={
                colorSettings?.splash_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark || 'black'}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default function () {
  return (
    <Stack.Navigator
      initialRouteName="PersonalDetailsNavigator"
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        headerBackTitle: null,
        mode: 'modal',
      }}
    >
      <Stack.Screen
        name="PersonalDetailsNavigator"
        component={PersonalDetailsNavigator}
        // path: ''
      />

      <Stack.Screen
        name="SuccessScreenPersonalHealthInfo"
        component={SuccessScreenPersonalHealthInfo}
      />
    </Stack.Navigator>
  )
}
