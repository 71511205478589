import { Text, TouchableOpacity, StyleSheet } from 'react-native'
import Colors from '$constants/Colors.js'

export default function CustomSubmitButton({
  onPress,
  style,
  titleStyle,
  title,
}) {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() => onPress()}
      style={[styles.container, style]}
    >
      <Text style={[styles.title, titleStyle]}>{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primaryColor,
    width: '100%',
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    color: Colors.buttonTextColor,
  },
})
