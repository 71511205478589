import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import custom_styles from '../../login/_static/main.styles'
import { i18n } from '$localization/config.js'

function ForgotPasswordButton({ onPress }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={{ marginTop: 25 }}>
      <TouchableOpacity onPress={onPress} activeOpacity={0.5}>
        <Text
          style={[
            custom_styles.forgotPasswordButtonText,
            {
              color: colorSettings?.bottom_next_btn_enabled,
            },
          ]}
        >
          {`${i18n.t('forgot_your_password').capitalize}?`}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default ForgotPasswordButton
