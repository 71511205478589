import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import moment from 'moment'
import { i18n } from '$localization/config.js'
import styles from './main.styles'

import { setError } from '$redux/defaults/actions.js'
import { BirthdatePicker } from '../sign_up/SignupScreen.main'
import CustomDatePicker from '$components/custom-date-picker/custom-date-picker.js'
import ApiFetchHandler from '$api/ApiFetchHandler'
import { setAccountAction, setAccountDOB } from '$redux/account/actions'
import TermsAndPrivacyScreenModal from '../sign_up/TermsAndPrivacyScreen.modal'
import custom_styles from '../login/_static/main.styles'
import { IS_MOBILE } from '$constants/Platforms'
import useAuth from '../../../hooks/useAuth'
import {
  errorResponseHandler,
  toggleNextButtonColor,
  toggleNextButtonText,
} from '../authorizationHelpers'
import LoginForm from './components/LoginForm'
import ScreenDivider from './components/ScreenDivider'
import WebDatePicker from './components/WebDatePicker'
import CreatePasswordNavigationText from './components/CreatePasswordNavigationText'
import CustomValidateDobHeaderText from './components/CustomValidateDobHeaderText'

const isWEB = Platform.OS === 'web' ? 'web' : 'mobile'

function CustomValidateDOB({ navigation }) {
  const dispatch = useDispatch()

  const {
    handleLoginCustomWorkflowUser,
    createPatientAccount,
    handleLoginUser,
    checkAndNavigateToPasswordScreen,
  } = useAuth()

  const setPatientDOB = (data) => dispatch(setAccountDOB(data))
  const saveAccountData = (data) => dispatch(setAccountAction(data))
  const saveError = (data) => dispatch(setError(data))

  const colorSettings = useSelector((state) => state.store.colorSettings)

  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)
  const userUUID = useSelector((state) => state.store.inviteID)

  const patientData = useSelector((state) => state.accountStore.account)

  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [isInputsValid, setIsInputsValid] = useState(false)
  const [showPassword, setShowPassword] = useState(true)
  const [email, setEmail] = useState('')
  const [dateOfBirthday, setDateOfBirthday] = useState(null)
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [isDOBCorrect, setIsDOBCorrect] = useState(false)
  const [showTermsPrivacy, setShowTermsPrivacy] = useState(false)

  const isPatientInviteSent =
    patientData?.invite_status === 'InviteSent' ||
    patientData?.invite_status === '2' ||
    patientData?.invite_status === 'InviteClinicianAccepted'

  const isInputValidOrDOBCorrect = isDOBCorrect || isInputsValid

  const validateDobAction = async () => {
    if (!dateOfBirthday) return

    try {
      const formattedDateOfBirthdate =
        moment(dateOfBirthday).format('YYYY-MM-DD')

      const validateDOB = {
        path: `patient_account/${userUUID}/get/?dob=${formattedDateOfBirthdate}`,
        method: 'get',
        pageDetails: {
          page: 'CustomValidateDOB.js',
        },
      }

      const response = await ApiFetchHandler(validateDOB)

      if (response.isError) {
        setIsDOBCorrect(false)

        return errorResponseHandler(response, saveError)
      }

      saveAccountData(response)
      setIsDOBCorrect(true)
      setIsInputsValid(false)
      setPatientDOB(formattedDateOfBirthdate)
    } catch (err) {
      console.log(
        '🚀 \n\n file: CustomValidateDOB.js:281 \n\n validateDobAction \n\n err:',
        err
      )
    }
  }

  const onDatePickerChange = (selectedDate = false, dismiss = false, date) => {
    if (isWEB) {
      setDateOfBirthday(date)
    }

    if (selectedDate) {
      setDateOfBirthday(selectedDate)
    }

    if (!isWEB) {
      setDatePickerVisible(!dismiss)
    }
  }

  const navigateToPasswordScreen = () => {
    const formattedDateOfBirthdate = moment(dateOfBirthday).format('YYYY-MM-DD')

    setPatientDOB(formattedDateOfBirthdate)
    navigation.navigate('CustomCreatePassword')
  }

  const handleIsInputValid = () => {
    setIsInputsValid(false)
    const emailValidator = require('email-validator')
    const isEmailInvalid = email && !emailValidator.validate(email)

    const isEmailEmpty = !email?.length

    if (isEmailInvalid || isEmailEmpty) {
      setLoginError('emailError')
      return false
    }

    const isPasswordEmpty = !password?.length

    if (isPasswordEmpty || isEmailEmpty) {
      setLoginError('emptyError')
      return false
    }

    setIsInputsValid(true)
    setLoginError(null)

    return true
  }

  const navigateToTermsAndPrivacy = async () => {
    if (isInputsValid) return handleLoginUser(navigation, email, password)

    const shouldNavigateToPasswordScreen =
      await checkAndNavigateToPasswordScreen()

    if (shouldNavigateToPasswordScreen) return navigateToPasswordScreen()

    if (isPatientInviteSent) {
      setShowTermsPrivacy(true)
    } else {
      try {
        await handleLoginCustomWorkflowUser(navigation)
      } catch (error) {
        console.log(
          '🚀 \n\n file: customCreatePassword.js:11 \n\n handleSetPassAndLogin \n\n error:',
          error
        )
      }
    }
  }

  useEffect(() => {
    validateDobAction().catch((error) => console.error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirthday])

  useEffect(() => {
    handleIsInputValid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password])

  return (
    <SafeAreaView
      style={[
        styles.container,
        {
          backgroundColor: colorSettings?.splash_bgcolor,
        },
      ]}
    >
      <KeyboardAvoidingView
        style={styles.keyboardAvoiding}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={60}
      >
        <ScrollView
          contentContainerStyle={custom_styles.contentContainer}
          nativeID="web_scaled_main_container"
          bounces={false}
        >
          <View style={styles.signUpContainer}>
            <View style={styles.validateDobContainer}>
              <CustomValidateDobHeaderText patientData={patientData} />

              {Platform.OS === 'web' && (
                <View style={{ width: '100%' }}>
                  <WebDatePicker
                    dateOfBirthday={dateOfBirthday}
                    onDatePickerChange={onDatePickerChange}
                  />
                </View>
              )}

              {IS_MOBILE() && (
                <View style={{ marginTop: 15 }}>
                  <BirthdatePicker
                    colorSettings={colorSettings}
                    toggleDatePicker={() =>
                      setDatePickerVisible((prev) => !prev)
                    }
                    date={dateOfBirthday}
                    selectedLanguage={selectedLanguage}
                    setRef={() =>
                      console.log(
                        '🚀 \n\n file: CustomValidateDOB.js:1133 \n\n CustomValidateDOB \n\n ref'
                      )
                    }
                  />
                </View>
              )}

              {isDOBCorrect && (
                <CreatePasswordNavigationText
                  navigateToPasswordScreen={navigateToPasswordScreen}
                />
              )}
            </View>
            {!isDOBCorrect && <ScreenDivider />}
            {!isDOBCorrect && (
              <LoginForm
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                password={password}
                email={email}
                setEmail={setEmail}
                setPassword={setPassword}
              />
            )}
          </View>
        </ScrollView>

        <View style={styles.nextButtonContainer}>
          <TouchableOpacity
            style={[
              styles.submitButton,
              toggleNextButtonColor(isInputValidOrDOBCorrect, colorSettings),
            ]}
            onPress={navigateToTermsAndPrivacy}
            disabled={!isInputsValid && !dateOfBirthday}
          >
            <Text
              style={[
                styles.submitButtonText,
                {
                  color: toggleNextButtonText(
                    isInputValidOrDOBCorrect,
                    colorSettings
                  ),
                },
              ]}
            >
              {i18n.t('next')?.title}
            </Text>
          </TouchableOpacity>
        </View>
      </KeyboardAvoidingView>

      {datePickerVisible && (
        <CustomDatePicker
          colorSettings={colorSettings}
          onDatePickerChange={onDatePickerChange}
          date={dateOfBirthday ?? new Date(0)}
          visible={datePickerVisible}
          selectedLanguage={selectedLanguage}
        />
      )}

      <TermsAndPrivacyScreenModal
        modalVisible={showTermsPrivacy}
        primaryButtonAction={async () =>
          createPatientAccount(navigation, setShowTermsPrivacy)
        }
        cancelButtonAction={() => setShowTermsPrivacy(false)}
      />
    </SafeAreaView>
  )
}

export default CustomValidateDOB
