import { useEffect } from 'react'
import { TouchableOpacity } from 'react-native'

import { useSelector } from 'react-redux'

import { Switch } from 'react-native-switch'

import useStateWithCallback from 'use-state-with-callback'

export default function DarkGraySwitch({ value, onValueChange, paddingTop }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const [stateValue, setStateValue] = useStateWithCallback(
    value,
    (prevValue) => {
      if (value) setStateValue(prevValue)
    }
  )

  useEffect(() => {
    setStateValue(value)
  }, [value])

  const applyChange = () => {
    setStateValue(() => !value)

    if (onValueChange) {
      onValueChange(!stateValue)
    }
  }

  return (
    <TouchableOpacity
      style={{
        alignItems: 'flex-end',
        paddingTop: paddingTop || 8,
      }}
      onPress={applyChange}
      activeOpacity={1.0}
    >
      <Switch
        backgroundActive={colorSettings?.switch_bg_active || 'green'}
        backgroundInactive={colorSettings?.switch_bg_inactive || 'gray'}
        circleActiveColor={colorSettings?.switch_dot_active || 'white'}
        circleInActiveColor={colorSettings?.switch_dot_inactive || 'white'}
        circleSize={16}
        circleBorderWidth={0}
        barHeight={10}
        renderActiveText={false}
        renderInActiveText={false}
        switchWidthMultiplier={1.5}
        onValueChange={onValueChange}
        value={stateValue}
      />
    </TouchableOpacity>
  )
}
