import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'

const sectionListOrder = {
  [RelationshipTypes.PROBAND]: 0,
  [RelationshipTypes.SIBLINGS]: 1,
  [RelationshipTypes.PARENTS]: 2,
  [RelationshipTypes.PATERNAL_AUNTS_UNCLES]: 3,
  [RelationshipTypes.MATERNAL_AUNTS_UNCLES]: 4,
  [RelationshipTypes.PATERNAL_GRANDPARENTS]: 5,
  [RelationshipTypes.MATERNAL_GRANDPARENTS]: 6,
  [RelationshipTypes.OTHER_MEMBERS]: 7,
}

export default sectionListOrder
