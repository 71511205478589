export const RELATION_ID = {
  IS_DAD: 'isDad',
  IS_MOM: 'isMom',
  IS_CHILD_FROM_PROBAND: 'isChildFromProband',
  IS_BROTHER_OR_SISTER: 'isBrotherOrSister',
  IS_PROBAND: 'isProband',
  IS_AUNT_OR_UNCLE_DAD: 'isAuntOrUncleDad',
  IS_AUNT_OR_UNCLE_MOM: 'isAuntOrUncleMom',
  IS_GRAND_SON_OR_DAUGHTER: 'isGrandSonOrDaughter',
  IS_GREAT_GRAND_SON_OR_DAUGHTER: 'isGreatGrandSonOrDaughter',
  IS_NIECE_OR_NEPHEW: 'isNieceOrNephew',
  IS_GRAND_NIECE_OR_NEPHEW: 'isGrandNieceOrNephew',
  IS_FIRST_COUSIN: 'isFirstCousin',
}

export const RELATION_TYPE = {
  FATHER: 'father',
  MOTHER: 'mother',
  SON: 'son',
  DAUGHTER: 'daughter',
  BROTHER: 'brother',
  SISTER: 'sister',
  PROBAND: 'proband',
  PATERNAL_AUNT: 'paternal aunt',
  PATERNAL_UNCLE: 'paternal uncle',
  MATERNAL_AUNT: 'maternal aunt',
  MATERNAL_UNCLE: 'maternal uncle',
  GRAND_DAUGHTER: 'granddaughter',
  GRAND_SON: 'grandson',
  GREAT_GRAND_SON: 'great grandson',
  GREAT_GRAND_DAUGHTER: 'great granddaughter',
  NIECE: 'niece',
  NEPHEW: 'nephew',
  GRAND_NIECE: 'grand niece',
  GRAND_NEPHEW: 'grand nephew',
  FIRST_COUSIN: '1st cousin',
  FIRST_COUSIN_REMOVED: '1st cousin 1 removed',
}

// TODO: Leave this for future reference, for once we have Typescript applied to the project and indicate the types
const postOnboardingBasicFamilyMembers = [
  {
    title: 'you_and_your_children',
    data: [
      {
        member: {
          member_id: 45397,
          name: 'Diego Moreno',
          first_name: 'Diego',
          last_name: 'Moreno',
          age: '32',
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'proband',
          allow_family_invite: 'ENABLED',
          relationshipCount: 1,
        },
        partners: [
          {
            member: {
              member_id: 48723,
              name: '',
              first_name: '',
              last_name: '',
              age: null,
              gender: 'f',
              is_dead: false,
              diseases: [],
              is_deleted: false,
              newly_added: false,
              relationship_to_proband: 'partner',
              allow_family_invite: 'ENABLED',
              relationshipCount: 1,
            },
            children: [
              {
                member: {
                  member_id: 53243,
                  name: ' ',
                  first_name: '',
                  last_name: '',
                  age: '',
                  gender: 'm',
                  is_dead: false,
                  diseases: [],
                  is_deleted: false,
                  newly_added: false,
                  relationship_to_proband: 'son',
                  allow_family_invite: 'ENABLED',
                  relationshipCount: 1,
                },
                partners: [],
                relationship: 'son',
                is_blood_related_to_proband: true,
              },
              {
                member: {
                  member_id: 53250,
                  name: '',
                  first_name: '',
                  last_name: '',
                  age: null,
                  gender: 'm',
                  is_dead: false,
                  diseases: [],
                  is_deleted: false,
                  newly_added: false,
                  relationship_to_proband: 'son',
                  allow_family_invite: 'ENABLED',
                  relationshipCount: 2,
                },
                partners: [],
                relationship: 'son',
                is_blood_related_to_proband: true,
              },
              {
                member: {
                  member_id: 53255,
                  name: '',
                  first_name: '',
                  last_name: '',
                  age: null,
                  gender: 'f',
                  is_dead: false,
                  diseases: [],
                  is_deleted: false,
                  newly_added: false,
                  relationship_to_proband: 'daughter',
                  allow_family_invite: 'ENABLED',
                  relationshipCount: 1,
                },
                partners: [],
                relationship: 'daughter',
                is_blood_related_to_proband: true,
              },
              {
                member: {
                  member_id: 53259,
                  name: '',
                  first_name: '',
                  last_name: '',
                  age: null,
                  gender: 'f',
                  is_dead: false,
                  diseases: [],
                  is_deleted: false,
                  newly_added: false,
                  relationship_to_proband: 'daughter',
                  allow_family_invite: 'ENABLED',
                  relationshipCount: 2,
                },
                partners: [],
                relationship: 'daughter',
                is_blood_related_to_proband: true,
              },
            ],
            relationship: 'partner',
          },
        ],
        relationship: 'proband',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'brothers_and_sisters',
    data: [
      {
        member: {
          member_id: 53349,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'sister',
          allow_family_invite: 'ENABLED',
          relationshipCount: 1,
        },
        partners: [],
        relationship: 'sister',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53350,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'sister',
          allow_family_invite: 'ENABLED',
          relationshipCount: 2,
        },
        partners: [],
        relationship: 'sister',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53351,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'brother',
          allow_family_invite: 'ENABLED',
          relationshipCount: 1,
        },
        partners: [],
        relationship: 'brother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53352,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'brother',
          allow_family_invite: 'ENABLED',
          relationshipCount: 2,
        },
        partners: [],
        relationship: 'brother',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'parents',
    data: [
      {
        member: {
          member_id: 45398,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'mother',
          allow_family_invite: 'ENABLED',
        },
        partners: [],
        relationship: 'mother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 45399,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'father',
          allow_family_invite: 'ENABLED',
        },
        partners: [],
        relationship: 'father',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'auntsuncles_dads_side',
    data: [
      {
        member: {
          member_id: 51637,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal uncle',
          allow_family_invite: 'ENABLED',
          relationshipCount: 1,
        },
        partners: [],
        relationship: 'paternal uncle',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53346,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal uncle',
          allow_family_invite: 'ENABLED',
          relationshipCount: 2,
        },
        partners: [],
        relationship: 'paternal uncle',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'auntsuncles_moms_side',
    data: [
      {
        member: {
          member_id: 53347,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal uncle',
          allow_family_invite: 'ENABLED',
          relationshipCount: 1,
        },
        partners: [],
        relationship: 'maternal uncle',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53348,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal uncle',
          allow_family_invite: 'ENABLED',
          relationshipCount: 2,
        },
        partners: [],
        relationship: 'maternal uncle',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'grandparents_dads_side',
    data: [
      {
        member: {
          member_id: 45402,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal grandmother',
          allow_family_invite: 'ENABLED',
        },
        partners: [],
        relationship: 'paternal grandmother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 45403,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal grandfather',
          allow_family_invite: 'ENABLED',
        },
        partners: [],
        relationship: 'paternal grandfather',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'grandparents_moms_side',
    data: [
      {
        member: {
          member_id: 45400,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal grandmother',
          allow_family_invite: 'ENABLED',
        },
        partners: [],
        relationship: 'maternal grandmother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 45401,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal grandfather',
          allow_family_invite: 'ENABLED',
        },
        partners: [],
        relationship: 'maternal grandfather',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'other_family_members',
    data: [],
  },
]

// Onboarding
const motherAndFather = [
  {
    title: 'parents',
    data: [
      {
        member: {
          member_id: 52054,
          name: 'Carmen ',
          first_name: 'Carmen',
          last_name: '',
          age: '',
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'mother',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'mother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 52055,
          name: 'Ignacio',
          first_name: 'Ignacio',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'father',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'father',
        is_blood_related_to_proband: true,
      },
    ],
    fatherSiblings: [
      {
        member: {
          member_id: 53084,
          first_name: 'Aun1Dad',
          last_name: '',
          gender: 'f',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'paternal aunt',
          diseases: [],
        },
      },
      {
        member: {
          member_id: 53085,
          first_name: 'Aun2Dad',
          last_name: '',
          gender: 'f',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'paternal aunt',
          diseases: [],
        },
      },
    ],
    motherSiblings: [
      {
        member: {
          member_id: 53235,
          first_name: 'Uncle1Mom',
          last_name: '',
          gender: 'm',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'maternal uncle',
          diseases: [],
        },
      },
      {
        member: {
          member_id: 53236,
          first_name: 'Uncle2Mom',
          last_name: '',
          gender: 'm',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'maternal uncle',
          diseases: [],
        },
      },
    ],
  },
  {
    title: 'grandparents_dads_side',
    data: [
      {
        member: {
          member_id: 52058,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal grandmother',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'paternal grandmother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 52059,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal grandfather',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'paternal grandfather',
        is_blood_related_to_proband: true,
      },
    ],
  },
  {
    title: 'grandparents_moms_side',
    data: [
      {
        member: {
          member_id: 52056,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal grandmother',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'maternal grandmother',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 52057,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal grandfather',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'maternal grandfather',
        is_blood_related_to_proband: true,
      },
    ],
  },
]

const children = [
  {
    title: 'children',
    data: [
      {
        member: {
          member_id: 53080,
          name: ' ',
          first_name: '',
          last_name: '',
          age: '',
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'daughter',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'daughter',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53081,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'daughter',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'daughter',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53230,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'son',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'son',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53231,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'son',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'son',
        is_blood_related_to_proband: true,
      },
    ],
  },
]

const siblings = [
  {
    title: 'siblings',
    data: [
      {
        member: {
          member_id: 53082,
          name: ' ',
          first_name: '',
          last_name: '',
          age: '',
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'sister',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'sister',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53083,
          name: '',
          first_name: '',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'sister',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'sister',
        is_blood_related_to_proband: true,
      },
    ],
  },
]

const dadSideUnclesAndAunts = [
  {
    title: 'auntsuncles_dads_side',
    data: [
      {
        member: {
          member_id: 53084,
          name: 'Aun1Dad ',
          first_name: 'Aun1Dad',
          last_name: '',
          age: '',
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal aunt',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'paternal aunt',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53085,
          name: 'Aun2Dad',
          first_name: 'Aun2Dad',
          last_name: '',
          age: null,
          gender: 'f',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'paternal aunt',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'paternal aunt',
        is_blood_related_to_proband: true,
      },
    ],
    parentsData: [
      {
        member: {
          member_id: 52054,
          first_name: 'Carmen',
          last_name: '',
          gender: 'f',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'mother',
          diseases: [],
        },
      },
      {
        member: {
          member_id: 52055,
          first_name: 'Ignacio',
          last_name: '',
          gender: 'm',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'father',
          diseases: [],
        },
      },
    ],
  },
]

const motherSideUnclesAndAunts = [
  {
    title: 'auntsuncles_moms_side',
    data: [
      {
        member: {
          member_id: 53235,
          name: 'Uncle1Mom ',
          first_name: 'Uncle1Mom',
          last_name: '',
          age: '',
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal uncle',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'maternal uncle',
        is_blood_related_to_proband: true,
      },
      {
        member: {
          member_id: 53236,
          name: 'Uncle2Mom',
          first_name: 'Uncle2Mom',
          last_name: '',
          age: null,
          gender: 'm',
          is_dead: false,
          diseases: [],
          is_deleted: false,
          newly_added: false,
          relationship_to_proband: 'maternal uncle',
          allow_family_invite: 'HIDDEN',
        },
        partners: [],
        relationship: 'maternal uncle',
        is_blood_related_to_proband: true,
      },
    ],
    parentsData: [
      {
        member: {
          member_id: 52054,
          first_name: 'Carmen',
          last_name: '',
          gender: 'f',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'mother',
          diseases: [],
        },
      },
      {
        member: {
          member_id: 52055,
          first_name: 'Ignacio',
          last_name: '',
          gender: 'm',
          age: null,
          is_dead: false,
          is_blood_related_to_proband: true,
          relationship_to_proband: 'father',
          diseases: [],
        },
      },
    ],
  },
]
