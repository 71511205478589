import { View, Text, TextInput } from 'react-native'
import { i18n } from '$localization/config.js'
import styles from '../../styles/styles.main'

const InputAgeView = ({
  colorSettings,
  headerTitle,
  currentAge,
  validationChecks,
  onChange,
  onFocus,
  onActiveScreen,
  maxLength,
}) => (
  <View style={styles.mainContainer}>
    <Text
      style={[
        styles.headerTitle,
        {
          color: colorSettings?.text_input_label_color_2 || 'black',
        },
      ]}
    >
      {headerTitle}
    </Text>
    <Text
      style={[
        styles.headerTitle,
        {
          color: colorSettings?.text_input_label_color_2 || 'black',
        },
      ]}
    >
      {`(${i18n.t('or_estimate_if_unsure').default})`}
    </Text>
    <View style={styles.textInputOuterContainer}>
      <View style={styles.textInputInnerContainer}>
        <TextInput
          nativeID="web_text_input"
          onChangeText={(val) => onChange(val)}
          onChange={onActiveScreen}
          // onEndEditing={() => validationChecks()}
          onBlur={() => validationChecks()}
          value={`${currentAge}`}
          // onFocus={() => this.props.toggleDoneButton(false)}
          clearTextOnFocus
          onFocus={() => onFocus()}
          // autoFocus={true}
          keyboardType="number-pad"
          returnKeyType="done"
          maxLength={maxLength}
          placeholder="0"
          placeholderTextColor={
            colorSettings?.text_input_placeholder_color_2 ||
            'rgba(74, 74, 74, 0.5)'
          }
          selectionColor={colorSettings?.text_input_color_2}
          style={[
            styles.inputField,
            {
              borderBottomColor: colorSettings?.btn_no_fill_border_2 || 'black',
              color: colorSettings?.text_input_color_2 || 'black',
            },
          ]}
        />
      </View>
    </View>
  </View>
)

export default InputAgeView
