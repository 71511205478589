export default function ConvertBoolToText(item) {
  if (!item) return item
  if (typeof item !== 'boolean') {
    console.warn('convert error: not a boolean')
    return item
  }

  if (item) return 'True'
  return 'False'
}
