import { Component } from 'react'
import { Text, View } from 'react-native'
import { handleDisplayAgeFromAgeString } from '$screens/main/family/edit_profile/_utils/Utils'
import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import { fixDiseaseName } from '$api/_utils/Utils.js'

import custom_styles from '$screens/main/family/_styles/main.styles.js'

class MemberDiseasesView extends Component {
  colorSettings = this.props.colorSettings

  state = {
    diseases: this.props.diseases,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { diseases } = newProps
    const newState = prevState
    newState.diseases = diseases
    return newState
  }

  render() {
    const diseasesList = this.state.diseases

    if (!diseasesList) {
      return null
    }

    const layout = []
    let hiddenDiseasesCount = 0

    for (const i in diseasesList) {
      const disease = diseasesList[i]

      let _diseaseShortName_ = fixDiseaseName(disease?.disease_short_name)
      _diseaseShortName_ = _diseaseShortName_.toLowerCase()
      const diseaseShortName =
        i18n.t(_diseaseShortName_).default || disease?.disease_short_name
      const ageOfDiagnosedString = handleDisplayAgeFromAgeString(
        disease?.age_diagnosed_string
      )

      if (Number(i) < 4) {
        layout.push(
          <View key={i}>
            <View style={{ height: 4.0 }} />
            <Text style={custom_styles.itemDetailsSubtitleText}>
              {/* {disease.disease_short_name}{" - "}{disease.age_diagnosed} */}
              {diseaseShortName},{' '}
              {ageOfDiagnosedString ?? disease.age_diagnosed ?? '?'}
            </Text>
          </View>
        )
      } else {
        hiddenDiseasesCount += 1
      }
    }

    if (hiddenDiseasesCount > 0) {
      layout.push(
        <View>
          <View style={{ height: 8.0 }} />
          <Text
            style={[
              custom_styles.itemDetailsSubtitleText,
              {
                color: this.colorSettings?.member_tile_more,
              },
            ]}
          >
            {/* {"+ "}{hiddenDiseasesCount}{" "}{i18n.t("more").lowercase} */}
            {`+ ${hiddenDiseasesCount} ${i18n.t('more').lowercase}`}
          </Text>
        </View>
      )
    }

    return layout
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(MemberDiseasesView)
