import {
  SafeAreaView,
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'

/* Custom Components and Constants */
import BaseController from '$components/BaseController.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$constants/LoadingView.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import AuthCredentials from '$data_models/AuthCredentials'
import Proband from '$data_models/Proband'

import { i18n } from '$localization/config.js'
import { WEB } from '$constants/Platforms.js'

import {
  getFamilySideAndRelationshipGender,
  capitalizeFirstLetter,
} from '$api/_utils/Utils.js'

import { storeCurrentMember } from '$redux/member_profile/actions.js'

import { setShouldProceedToMain, setError } from '$redux/defaults/actions.js'

import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import custom_styles from '$screens/main/dashboard/invite/_styles/main.styles.js'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class SignupFamilySharingSettingsMain extends BaseController {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  state = {
    ...this.state,
    listData: [],
    scrollViewHeight: getScrollViewHeight(),
  }

  constructor(props) {
    super(props)

    /* Set shared instance */
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    /* API request to get list of shared Family Members. */
    this.getShareSettingsMemberList()
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  getShareSettingsMemberList = async () => {
    /* Prepare API request to get list of shared Family Members. */

    const shareSettingsMemberListPayload = {
      path: 'invite/share_settings/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband.probandID,
      },
      pageDetails: {
        page: 'SharingSettings.main.js',
      },
    }

    const response = await ApiFetchHandler(shareSettingsMemberListPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.setState({ listData: response })

    return this.reloadAndRenderView(false)
  }

  nextButtonAction = async () => {
    /* Note: Needed by API */
    const probandID = this.proband?.probandID
    const { _setShouldProceedToMain_, colorSettings } = this.props
    const sendNotificationShareSettingsPayload = {
      path: `invite/${probandID}/send_initial_account_creation_notification/`,
      method: 'post',
      token: this.authCredentials.accessToken,
      pageDetails: {
        page: 'SignupFamilySharingSettings.main.js',
      },
    }

    const response = await ApiFetchHandler(sendNotificationShareSettingsPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /* Set identifier to proceed to Main */
    _setShouldProceedToMain_(true)

    this.navigateTo('SuccessScreenAccountCreated', {
      cs: colorSettings,
    })
  }

  onPressItem = (item) => {
    /* Update current member from Redux store for later use */
    const currentMember = item
    this.props._storeCurrentMember_(currentMember)

    this.navigateTo('SignupFamilySharingSettingsNavigator', {
      screen: 'SignupFamilySharingSettingsDetail',
    })
  }

  render() {
    const { colorSettings } = this.props

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView
          style={[
            custom_styles.container,
            {
              backgroundColor: colorSettings?.post_onboarding_bgcolor,
              ...Platform.select({
                [WEB]: {
                  flex: null,
                  height: this.state.scrollViewHeight,
                },
              }),
            },
          ]}
        >
          <LoadingView
            visible={this.state.showLoadingView}
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
          />
        </SafeAreaView>
      )
    }

    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            global_styles.containerWhite,
            {
              backgroundColor: colorSettings?.post_onboarding_bgcolor,
              ...Platform.select({
                [WEB]: {
                  flex: null,
                  height: getScrollViewHeight(),
                },
              }),
            },
          ]}
        >
          <ScrollView
            nativeID="web_scaled_main_container"
            // bounces={false}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            style={{
              paddingHorizontal: 16,
              ...Platform.select({
                [WEB]: {
                  height: getScrollViewHeight(),
                },
              }),
            }}
          >
            <View style={{ height: 16 }} />

            <View style={{ marginBottom: 24 }}>
              <Text
                style={[
                  global_styles.titleHelperText,
                  {
                    color: colorSettings?.text_input_label_color_1,
                    fontSize: 14,
                    textAlign: 'left',
                  },
                ]}
              >
                {i18n.t('family_members_using_the_app')?.default}
              </Text>

              <View style={{ height: 16 }} />

              <Text
                style={[
                  global_styles.helperText,
                  {
                    color: colorSettings?.text_input_label_color_1,
                    textAlign: 'left',
                  },
                ]}
              >
                {
                  i18n.t(
                    'good_news_some_of_your_family_members_have_already_entered_health_information_we_will_send_a_request_to_them_so_you_can_connect_and_keep_each_other_updated_of_changes'
                  )?.default
                }
              </Text>

              <View style={{ height: 16 }} />

              <Text
                style={[
                  global_styles.helperText,
                  {
                    color: colorSettings?.text_input_label_color_1,
                    fontSize: 13,
                    textAlign: 'left',
                  },
                ]}
              >
                {
                  i18n.t(
                    'you_can_change_sharing_settings_for_each_family_member_below_or_later_from_the_dashboard'
                  )?.default
                }
              </Text>
            </View>

            {/* Members list */}
            <View style={{ overflow: 'hidden', borderRadius: 12 }}>
              <SharedFamilyMembersListContainer
                onPressItem={(item) => this.onPressItem(item)}
                listData={this.state.listData}
                colorSettings={colorSettings}
              />
            </View>

            <View style={{ height: 16 }} />
          </ScrollView>

          {/* <Next button> */}
          <View style={global_styles.nextButtonContainer}>
            <TouchableOpacity
              style={[
                global_styles.nextButton,
                {
                  backgroundColor:
                    colorSettings?.bottom_next_btn_enabled ?? 'white',
                  borderColor:
                    colorSettings?.bottom_next_btn_enabled ?? 'black',
                },
              ]}
              onPress={() => this.nextButtonAction()}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: colorSettings?.bottom_next_btn_text ?? 'black',
                  },
                ]}
              >
                {i18n.t('next')?.default}
              </Text>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

const SharedFamilyMembersListContainer = ({
  colorSettings = {},
  listData = [],
  onPressItem = function () {},
}) => {
  if (listData.length == 0) {
    return (
      <View style={global_styles.contentContainer}>
        <Text
          style={[
            global_styles.emptyListHelperText,
            {
              color: colorSettings?.text_input_color_1,
            },
          ]}
        >
          {i18n.t('error_loading_data_please_press_next')?.default}
        </Text>
      </View>
    )
  }

  const layout = []

  listData.forEach((item) => {
    layout.push(
      <FamilyMemberTile
        colorSettings={colorSettings}
        member={item}
        onPressItem={onPressItem}
      />
    )
  })

  return layout
}

const FamilyMemberTile = ({
  colorSettings = {},
  member,
  onPressItem = function () {},
}) => {
  const name =
    `${member?.name}`.trim() == '' ? i18n.t('unnamed')?.default : member?.name
  const gender = member?.gender

  let genderIcon = null

  switch (gender.toLowerCase()) {
    case 'm':
      genderIcon = require('$assets/images/icon/male.png')
      break
    case 'f':
      genderIcon = require('$assets/images/icon/female.png')
      break
  }

  const _relationshipToProband = getFamilySideAndRelationshipGender(
    member?.relationship,
    gender
  )

  const _relationship = _relationshipToProband[0]?.toLowerCase()
  const _familySide = _relationshipToProband[1]?.toLowerCase()
  let _greatCounter = _relationshipToProband[2]

  let relationship =
    i18n.t(_relationship)?.default ?? capitalizeFirstLetter(_relationship, true)

  // Append Family side if available.
  if (_familySide) {
    relationship = `${relationship} (${i18n.t(_familySide)?.default})`
  }

  // Insert 'great' if needed.
  while (_greatCounter > 0) {
    relationship = `${i18n.t('great')?.default} ${relationship}`
    _greatCounter--
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        borderTopWidth: 0.5,
        borderBottomWidth: 0.5,
        borderColor: 'rgb(234,234,234)',
        height: 75,
        justifyContent: 'center',
      }}
    >
      <TouchableOpacity
        style={custom_styles.listItemContent}
        activeOpacity={1.0}
        onPress={() => onPressItem(member)}
      >
        <View style={{ backgroundColor: 'rgba(65,70,97,0.1)' }}>
          <Image style={{ width: 42, height: 42 }} source={genderIcon} />
        </View>

        <View style={custom_styles.listItemTitleContainer}>
          <View>
            <Text
              style={[
                custom_styles.listItemTitleText,
                {
                  color: colorSettings?.member_tile_title,
                },
              ]}
            >
              {name}
            </Text>
            <Text
              style={[
                custom_styles.listItemSubtitleText,
                {
                  color: colorSettings?.member_tile_subtitle,
                },
              ]}
            >
              {relationship}
            </Text>
          </View>
        </View>

        <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
          <Image
            source={require('$assets/images/new_assets/icon-dash-arrow.png')}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    shouldProceedToMain: store.shouldProceedToMain,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    proband: probandStore.proband,
    /** MemberProfile Store */
    currentMember: memberProfileStore.currentMember,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _storeCurrentMember_: (data) => dispatch(storeCurrentMember(data)),
    _setShouldProceedToMain_: (data) => dispatch(setShouldProceedToMain(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFamilySharingSettingsMain)
