import React from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import BaseController from '$components/BaseController.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$constants/LoadingView.js'

import { i18n } from '$localization/config.js'
import * as Platforms from '$constants/Platforms.js'

import { getGenderIcon, capitalizeFirstLetter } from '$api/_utils/Utils.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import {
  showDefaultAlert,
  showGenericServerError,
} from '$navigation/_utils/NavigationUtils.js'

import { setAddTwinsPayload } from '$redux/member_profile/actions.js'

import global_styles, {
  DefaultFullHeightForWeb,
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'

import custom_styles from './_styles/custom.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

import modifySiblingsListHandler from '../twinsUtils'

const API_MEMBER_ID = 'member_id'

const getScrollViewHeight = () => DefaultFullHeightForWeb(-50)

class SelectTwin extends BaseController {
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  proband = this.props.currentProband

  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  currentMember = this.props.currentMember

  constructor(props) {
    super(props)
    // Set shared instance
    this.constructor.setSharedInstance(this)
    this.state = {
      listData: null,
      selectedItems: [],
      showLoadingView: false,
      scrollViewHeight: getScrollViewHeight(),
      isOnboarding: false,
    }
  }

  getOnboardingStatus = async () => {
    const { accountStore, authCredentials } = this.props

    const accountID = accountStore?.account?.accountID

    this.setState({
      showLoadingView: true,
    })

    const payload = {
      path: `onboarding/${accountID}/get_info/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'SelectTwin.js',
      },
    }

    const response = await ApiFetchHandler(payload)

    const { saveError } = this.props

    if (response.isError) {
      this.setState({
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    if (response.last_screen) {
      this.setState({ isOnboarding: true, showLoadingView: false })
    } else {
      this.setState({ isOnboarding: false, showLoadingView: false })
    }
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    await this.getOnboardingStatus()
    // API request to get Sibling List.
    await this.getSiblingListForTwins()
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  onDone = () => {
    if (this.state.selectedItems.length == 0) {
      showDefaultAlert(
        i18n.t('select_sibling')?.default,
        i18n.t('please_select_a_sibling_to_be_added_as_your_twin_dot')?.default
      )
      return
    }

    /* Save selected twins to Redux store for later use. */
    this.props._setAddTwinsPayload_(this.state.selectedItems)

    /* Proceed to next screen */
    const { navigate } = this.props.navigation
    navigate('SelectTwinType')
  }

  onPressItem = (item) => {
    let selectedItems = this.state.selectedItems ?? []

    if (selectedItems.length > 0) {
      /* If list is not empty: Make a copy of the list. */
      const _selectedItemsCopy_ = JSON.parse(JSON.stringify(selectedItems))
      /* Check if current item is already existing in the list */
      if (
        _selectedItemsCopy_.some(
          (e) => item?.[API_MEMBER_ID] == e?.[API_MEMBER_ID]
        )
      ) {
        /* If true: Remove the current item from list. */
        _selectedItemsCopy_?.findIndex((e, index) => {
          if (item?.[API_MEMBER_ID] == e?.[API_MEMBER_ID]) {
            _selectedItemsCopy_.splice(index, 1)
            selectedItems = _selectedItemsCopy_
          }
        })
      } else {
        /* Default: Add current item to list */
        selectedItems.push(item)
      }
    } else {
      /* Default: If no items in list */
      selectedItems.push(item)
    }

    /* Update the Section List data */
    this.setState({ selectedItems })

    this.setState({ refresh: !this.state.refresh }) // after clicking on a row let the web version refresh (re-render) the list items with a selected or not selected style
  }

  getSiblingListForTwins = async () => {
    const { currentMember } = this

    /* Activate loading view */
    this.setState({ showLoadingView: true })

    /* API request to get Siblings of current Member. */
    const getSiblingListForTwinsPayload = {
      path: 'member/get_sibling_list_for_twins/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: currentMember?.[API_MEMBER_ID],
      },
      pageDetails: {
        page: 'SelectTwin.js',
      },
    }

    const response = await ApiFetchHandler(getSiblingListForTwinsPayload)

    if (response.isError) {
      showGenericServerError(response?.status)
      return
    }

    const listData = this.modifySiblingsList(response.siblings)

    this.setState({
      listData,
      showLoadingView: false,
    })
  }

  modifySiblingsList = (siblingsList = []) => {
    const { basicFamilyMembers = [] } = this.props

    const { isOnboarding } = this.state

    const modifiedSiblings = modifySiblingsListHandler(
      siblingsList,
      this.currentMember,
      basicFamilyMembers,
      isOnboarding
    )

    return modifiedSiblings
  }

  onlySpacesChecker = (string) => string?.trim()?.length === 0

  renderItem = ({ item, index, section }) => {
    /* Hide item if Member is already a Twin. */
    if (!item?.is_available) return null

    const { selectedItems } = this.state

    let backgroundColor = 'white'

    /* Check if Member is already selected. */
    if (selectedItems?.length > 0) {
      if (
        selectedItems?.some((e) => e?.[API_MEMBER_ID] == item?.[API_MEMBER_ID])
      ) {
        backgroundColor = '#ccc'
      }
    }

    const relationshipName = this.onlySpacesChecker(item.name)
      ? item.relationship.split(' ')
      : []

    const nameTextStyle = this.onlySpacesChecker(item.name)
      ? custom_styles.itemDetailsSubtitleText
      : custom_styles.sectionItemLabelText

    const i18nRelationshipString =
      relationshipName?.length === 3
        ? `${relationshipName[0]?.toLowerCase()}_${relationshipName[1]}`
        : relationshipName[0]?.toLowerCase()

    const relationshipCount = relationshipName[1]

    const siblingName = this.onlySpacesChecker(item?.name)
      ? `${i18n.t(i18nRelationshipString).default} ${relationshipCount ?? ''}`
      : `${i18n.t(item?.name).default ?? item?.name}`

    return (
      <View style={[custom_styles.sectionItemContainer, { backgroundColor }]}>
        <TouchableOpacity
          style={custom_styles.sectionItem}
          activeOpacity={1.0}
          onPress={() => this.onPressItem(item)}
        >
          <View style={{ flex: 1.0, flexDirection: 'row' }}>
            <View style={custom_styles.sectionItemImageContainer}>
              <Image
                style={custom_styles.sectionItemImage}
                source={getGenderIcon(item?.gender)}
              />
            </View>
            <View style={custom_styles.sectionItemLabelTextContainer}>
              <Text style={nameTextStyle}>{siblingName}</Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    const { navigation } = this.props
    const nextButtonDisabled = this.state.selectedItems.length === 0

    return (
      <MainWrapper navigation={navigation}>
        <View
          style={{
            width: '100%',
            flex: 1.0,
            ...Platform.select({
              [Platforms.WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          }}
        >
          <View
            nativeID="web_scaled_main_container"
            style={{ flex: 1.0, backgroundColor: 'white' }}
          >
            <FlatList
              style={{
                ...Platform.select({
                  [Platforms.WEB]: {
                    flex: null,
                    height: getScrollViewHeight(),
                  },
                }),
              }}
              data={this.state.listData}
              // extraData={this.state.refresh}   // enabled on web
              keyExtractor={(item) => `${item?.member_id}`}
              renderItem={this.renderItem}
            />
          </View>

          <View
            style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}
          >
            <TouchableOpacity
              style={[
                global_styles.nextButton,
                this.inversedToggleNextButtonColor(
                  nextButtonDisabled,
                  this.colorSettings
                ),
              ]}
              onPress={this.onDone}
              disabled={nextButtonDisabled}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.colorSettings?.bottom_next_btn_text ?? 'black',
                  },
                ]}
              >
                {i18n.t('done')?.default}
              </Text>
            </TouchableOpacity>
          </View>

          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </View>
      </MainWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    colorSettings: store.colorSettings,
    accountStore,
    authCredentials: accountStore.authCredentials,
    currentProband: probandStore.proband,
    currentMember: memberProfileStore.currentMember,
    basicFamilyMembers: probandStore.basicFamilyMembers,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setAddTwinsPayload_: (data) => dispatch(setAddTwinsPayload(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTwin)
