/* eslint-disable global-require */
import { useState, useEffect, useCallback, useRef } from 'react'
import { Platform, StatusBar, View } from 'react-native'
// import * as Device from 'expo-device'
import { preventAutoHideAsync, hideAsync } from 'expo-splash-screen'
// import * as Notifications from 'expo-notifications'
import { useFonts } from 'expo-font'

import { useDispatch, useSelector } from 'react-redux'
import { setShouldReloadFamilyList } from '$redux/proband/actions.js'
import {
  setIsFirstLogin,
  setBottomTabBarTitles,
  setShouldProceedToMain,
  setShouldShowOtherFamilyMembersTutorial,
  setDelayOtherFamilyMembersTutorial,
  setError,
  setBackgroundRefreshIntervalAll,
  setShowLoadingView as _setShowLoadingView,
} from '$redux/defaults/actions.js'

import { IOS } from '$constants/Platforms.js'
import AppNavigator from '$navigation/AppNavigator'
import ApiFetchHandler from '$api/ApiFetchHandler.js'
import { DOMAIN_CLIENT } from '$constants/globalVariables.js'

import { setDefaultProps, fontsToLoad, setInitialLanguage } from './utils'

// import {
//   PERMISSION_STATUS,
//   requestPermission,
//   setNotificationChannel,
//   getNotificationsStatus,
// } from '$utils/pushNotificationsUtils'

import {
  saveData,
  deleteData,
  ASstoreData,
  ASgetData,
} from '../../constants/SecureStore'

import ErrorView from '$components/error-view'

const style = {
  flex: 1,
  backgroundColor: '#ccc',
}

// TODO: Uncomment when backend for push notifications is ready
// Notifications.setNotificationHandler({
//   handleNotification: async () => ({
//     shouldShowAlert: true,
//     shouldPlaySound: false,
//     shouldSetBadge: false,
//   }),
// })

export default function AppDelegate() {
  // TODO: Uncomment when backend for push notifications is ready
  // const notificationListener = useRef()
  // const responseListener = useRef()
  const [fontsLoaded] = useFonts(fontsToLoad)

  const dispatch = useDispatch()

  const isError = useSelector((state) => state.store.isError)

  const saveError = (data) => dispatch(setError(data))
  const setShowLoadingView = (state) => dispatch(_setShowLoadingView(state))

  const apiCheckTimer = null
  const isIOS = Platform.OS === IOS

  const [isAppReady, setIsAppReady] = useState(false)

  // TODO: Uncomment when backend for push notifications is ready
  // const [pushToken, setPushToken] = useState('')
  // const [notification, setNotification] = useState(false)

  const getAppSettings = async () => {
    saveError({
      isShown: false,
      status: '',
      message: '',
    })

    const appSettingsPayload = {
      path: 'domain_client/app_settings/',
      method: 'post',
      body: {
        domain_client: DOMAIN_CLIENT?.toUpperCase(),
      },
      pageDetails: {
        page: 'AppDelegate.js',
      },
    }

    const response = await ApiFetchHandler(appSettingsPayload)

    if (response.isError) {
      console.groupCollapsed('%cApp Delegate Logs (error):', 'color: red')
      console.log('Error: API is down.')
      console.log('Error: Failed to load App settings.')
      console.groupEnd()

      setIsAppReady(false)

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }

    // eslint-disable-next-line camelcase
    const { app_setting, splash, color } = response

    dispatch({ type: 'SET_APP_SETTINGS', data: app_setting })
    dispatch({ type: 'SET_SPLASH_SETTINGS', data: splash })
    dispatch({ type: 'SET_COLOR_SETTINGS', data: color })
  }

  const handleLoadingError = (err) => {
    console.log(
      '🚀 \n\n file: AppDelegate.js:132 \n\n handleLoadingError \n\n err:',
      err
    )
    // TODO: In this case, you might want to report the error to your error reporting service, for example Sentry
  }

  const triggerInitialReduxHandlers = () => {
    dispatch(setIsFirstLogin(false))

    dispatch(setShouldShowOtherFamilyMembersTutorial(false))
    dispatch(setDelayOtherFamilyMembersTutorial(false))

    dispatch(setBottomTabBarTitles())

    dispatch(setShouldReloadFamilyList(false))

    dispatch(setShouldProceedToMain(false))

    const oneMinute = 60000
    const refreshInterval = __DEV__ ? oneMinute * 5 : oneMinute

    dispatch(setBackgroundRefreshIntervalAll(refreshInterval))
  }

  // const registerForPushNotificationsAsync = async () => {
  //   // if (!Device.isDevice) {
  //   //   // prettier-ignore
  //   // console.log('🚀 \n\n file: AppDelegate.js:148 \n\n registerForPushNotificationsAsync \n\n A physical device is required to set PushNotifications')
  //   //   return
  //   // }

  //   try {
  //     const isGranted = PERMISSION_STATUS.GRANTED

  //     let status = await getNotificationsStatus()

  //     if (status !== isGranted) status = await requestPermission()

  //     if (status !== isGranted) throw new Error()

  //     const token = await Notifications.getExpoPushTokenAsync()

  //     if (Platform.OS === 'android') setNotificationChannel()

  //     setPushToken(token.data)

  //     // TODO: Submit the token to a server/backend after the user is signed-up or signed-in
  //   } catch (e) {
  //     console.log(
  //       '🚀 \n\n file: AppDelegate.js:164 \n\n registerForPushNotificationsAsync \n\n e:',
  //       e
  //     )

  //     setPushToken('')
  //   }
  // }

  const clearLoginCredentials = async () => {
    const checkIfFirstLogin = await ASgetData({ key: 'isFirstLogin' })

    if (checkIfFirstLogin === null) {
      await deleteData({ key: 'email' })
      await deleteData({ key: 'password' })
      await deleteData({ key: 'enabledSecurity' })
      await deleteData({ key: 'hasUserAcceptedBiometric' })
      await ASstoreData({ key: 'isFirstLogin', value: 'false' })
    }
  }

  const prepareScreen = async () => {
    try {
      await preventAutoHideAsync()
      await getAppSettings()
      await saveData({ key: 'isLogout', value: 'false' })
      await clearLoginCredentials()
      // TODO: Uncomment when backend for push notifications is ready
      // await registerForPushNotificationsAsync()

      triggerInitialReduxHandlers()
      setDefaultProps()
      setInitialLanguage(dispatch)
    } catch (error) {
      console.log(
        '🚀 \n\n file: AppDelegate.js:215 \n\n prepareScreen \n\n error:',
        error
      )

      handleLoadingError(error)
    } finally {
      setIsAppReady(true)
    }
  }

  useEffect(() => {
    prepareScreen()
    setShowLoadingView(false)
    // TODO: Uncomment when backend for push notifications is ready
    // This listener is fired whenever a notification is received while the app is foregrounded
    // notificationListener.current =
    //   Notifications.addNotificationReceivedListener((notificationPayload) => {
    //     setNotification(notificationPayload)
    //   })

    // TODO: Uncomment when backend for push notifications is ready
    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    // responseListener.current =
    //   Notifications.addNotificationResponseReceivedListener(
    // (notificationResponse) => {
    // console.log('🚀 \n\n file: AppDelegate.js:205 \n\n useEffect \n\n notificationResponse:', notificationResponse)
    //     }
    //   )

    return () => {
      clearInterval(apiCheckTimer)

      // TODO: Uncomment when backend for push notifications is ready
      // Notifications.removeNotificationSubscription(notificationListener.current)
      // Notifications.removeNotificationSubscription(responseListener.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (isAppReady) {
      await hideAsync()
    }
  }, [isAppReady])

  if (!isAppReady || !fontsLoaded) return null

  return (
    <View style={style} onLayout={onLayoutRootView}>
      {isError?.isShown && (
        <ErrorView
          title={isError?.status}
          messageBody={isError?.message}
          onPress={(val) =>
            saveError({
              isShown: val,
              status: '',
              message: '',
            })
          }
          type={isError?.type}
        />
      )}

      <>
        {isIOS ? (
          <StatusBar barStyle="dark-content" />
        ) : (
          <StatusBar backgroundColor="white" barStyle="dark-content" />
        )}
        <AppNavigator
        //* Is commented because other developers were doing an experiment with this, but is not really used
        // persistNavigationState={persistNavigationState}
        // loadNavigationState={loadNavigationState}
        />
      </>
    </View>
  )
}
