import { HIDE_RISK } from '../../../../constants/envVariables'
import { IS_WEB } from '../../../../constants/Platforms'

const hideRisk = HIDE_RISK === 'true'
const _carousel = null
const carouselData = [
  {
    key: 1,
  },
  {
    key: 2,
  },
]

const paginationDotStyle = IS_WEB()
  ? {
      height: 15,
      width: 15,
      borderRadius: 8,
      marginHorizontal: 0,
      paddingHorizontal: 0,
    }
  : {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginHorizontal: -4,
      paddingHorizontal: 0,
    }

const paginationInactiveDotStyle = IS_WEB()
  ? {
      height: 15,
      width: 15,
      borderRadius: 8,
      marginHorizontal: 0,
      paddingHorizontal: 0,
    }
  : {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginHorizontal: -4,
      paddingHorizontal: 0,
    }

const FORTY = 40
const tappableDots = IS_WEB()
const dotsDistance = IS_WEB() ? 15 : 6
const autoplay = IS_WEB()
const carouselInterval = 5000
const currentContentWidth = 375
const currentContentHeight = 514
const carouselContentWidth = currentContentWidth - FORTY
const carouselContentHeight = currentContentHeight

export {
  hideRisk,
  _carousel,
  carouselData,
  paginationDotStyle,
  paginationInactiveDotStyle,
  tappableDots,
  dotsDistance,
  autoplay,
  carouselInterval,
  currentContentWidth,
  carouselContentWidth,
  carouselContentHeight,
  currentContentHeight,
}
