import ApiFetchHandler from '$api/ApiFetchHandler.js'
import { i18n } from '$localization/config.js'

const skipLogicMapping = (skipLogic) => {
  if (!skipLogic) {
    return []
  }

  const filteredSkipLogic = skipLogic.map((item) => ({
    answer: item.answer,
    skip_logic_id: item.skip_logic_id,
  }))
  return filteredSkipLogic
}

export const convertOutgoingDiseases = (diseases) => {
  if (!diseases) {
    return null
  }

  const filteredDiseases = diseases.map((item) => {
    if (typeof item.disease_id === 'string') {
      return {
        disease_id: null,
        umls_id: item.umls_id,
        age_diagnosed: item.age_diagnosed,
        age_diagnosed_string: item?.age_diagnosed_string ?? null,
        umls_name: item.disease_name,
        skip_logic: skipLogicMapping(item.skipLogic),
      }
    }

    return {
      age_diagnosed: item.age_diagnosed,
      age_diagnosed_string: item?.age_diagnosed_string,
      disease_id: item.disease_id,
      skip_logic: skipLogicMapping(item.skipLogic),
    }
  })

  return filteredDiseases
}

export const memberSaveDiseases = async ({
  diseaseSelected,
  authCredentials,
  currentSelectedMember,
  proband,
  probandProfile,
  savingStatusStart,
  savingStatusEnd,
  APIfetchingActions,
}) => {
  const convertedDiseases = convertOutgoingDiseases(diseaseSelected)
  const headers = authCredentials
  const memberId = currentSelectedMember.memberID
  const probandId = probandProfile.memberID

  const payload = {
    diseases: convertedDiseases,
    probandId,
    member_id: probandId,
  }

  savingStatusStart(true)
  const updateMemberDiseaseBulkPayload = {
    path: `member/${memberId}/add_disease_bulk/`,
    method: 'post',
    token: headers.accessToken,
    body: {
      ...payload,
    },
    pageDetails: {
      page: '_utils.js',
    },
  }

  const response = await ApiFetchHandler(updateMemberDiseaseBulkPayload)

  if (response.isError) return

  APIfetchingActions()

  setTimeout(() => {
    savingStatusEnd(false)
  }, 100)
}

const diseaseSkipLogicMapping = (skipLogic) => {
  if (!skipLogic) {
    return null
  }

  const filteredSkipLogic = skipLogic.map((item) =>
    // const { answer } = item
    // let itemType

    // if (!Number.isNaN(answer)) {
    //   itemType = 'yes_or_no_then_number'
    // } else {
    //   itemType = 'yes_or_no';
    // }

    ({
      answer: item.answer,
      question: item.question,
      question_type: item.question,
      skip_logic_id: item.skip_logic_id,
    })
  )

  return filteredSkipLogic
}

export const convertIncomingDiseases = (diseases) => {
  if (!diseases) {
    return null
  }

  const filteredDiseases = diseases.map((disease) => ({
    age_diagnosed: disease.age_diagnosed,
    age_diagnosed_string: disease?.age_diagnosed_string,
    disease_id: disease.disease_id,
    disease_name: disease.disease_name,
    disease_type: disease.disease_type,
    skipLogic: diseaseSkipLogicMapping(disease.skip_logics),
    umls_name: disease.umls_name,
    umls_id: disease.umls_id,
  }))

  return filteredDiseases
}

export const insertionSort = (arr) => {
  for (let i = 1; i < arr.length; i++) {
    const holder = arr[i]
    const currentVal = arr[i].id
    for (var j = i - 1; j >= 0 && arr[j].id > currentVal; j--) {
      arr[j + 1] = arr[j]
    }
    arr[j + 1] = holder
  }
  return arr
}

export const translationConverter = (
  title,
  translationType = 'title',
  fromUmls = false
) => {
  const name = title
    .trim()
    .toLowerCase()
    .replace(/\//g, ' ')
    .replace(/[^\w-\s]/gi, '')
    .replace(/\s/g, '_')

  if (fromUmls) {
    return title
  }

  return i18n.t(name)[translationType]
}

export const alphabeticalSort = (valA, valB, name) => {
  if (valA[name] < valB[name]) {
    return -1
  }
  if (valA[name] > valB[name]) {
    return 1
  }
  return 0
}
