import BaseController from '$components/BaseController.js'
/* Custom Utilities */
import { translations } from '$localization/config.js'

class SelectLanguageController extends BaseController {
  colorSettings = this.props.colorSettings

  onChangeLanguage = this.props.route?.params?.ocl ?? function () {}

  state = {
    listData: null,
    selectedItem: null,
  }

  constructor(props) {
    super(props)
    // Set shared instance
    this.constructor.setSharedInstance(this)

    const supportedLanguages = Object.keys(translations)

    this.state = {
      ...this.state,
      listData: supportedLanguages,
      selectedItem: null,
    }
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
      od: () => this.onDone(),
    })

    this.toggleLoadingView(false)
  }

  onPressItem = (item) => {
    this.setState({ selectedItem: item })
  }

  onDone = () => {
    if (!this.state.selectedItem) {
      return null
    }

    this.setState({ showLoadingView: true })

    setTimeout(() => {
      if (this.onChangeLanguage) {
        this.onChangeLanguage(this.state.selectedItem)
      }
      this.props.navigation.goBack()
    }, 300)
  }
}

export default SelectLanguageController
