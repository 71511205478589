import { useSelector } from 'react-redux'
import uuid from 'react-native-uuid'
import ChildrenView from '$screens/main/family/_includes/children/Children.view.js'
import FamilyCard from '$components/family-card/FamilyCard.js'
import {
  formatDisplayedMemberName,
  getGenderIcon,
  getGenderIconBorderRadius,
} from '$api/_utils/Utils.js'

import customStyles from '$screens/main/family/_styles/main.styles.js'
import calculateRelationship from '../../FamilyListScreen/helpers/calculateRelationship'

const excludedRelationship = [
  'proband',
  'uncle',
  'aunt',
  'mother',
  'father',
  'paternal grandmother',
  'paternal grandfather',
  'maternal grandmother',
  'maternal grandfather',
  'paternal great grandmother',
  'paternal great grandfather',
  'maternal great grandfather',
  'maternal great grandmother',
  'maternal great great grandmother',
  'maternal great great grandfather',
  'paternal great great grandmother',
  'paternal great great grandfather',
]

function PartnersView({
  mainMember,
  partners,
  navigateToMemberProfile,
  addToMemberButtonAction,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const relationshipList = []

  if (!partners || partners.length === 0) return null

  const filteredPartners = partners.filter((partner) => !partner.is_deleted)

  return (
    <>
      {filteredPartners.map((partner) => {
        const titleText = formatDisplayedMemberName(
          partner?.name,
          partner?.age_string ?? partner?.age,
          partner?.is_dead
        )

        const { relationship } = calculateRelationship(
          partner,
          relationshipList
        )

        if (excludedRelationship.includes(partner?.relationship_to_proband))
          return null

        return (
          <FamilyCard
            key={uuid.v4()}
            listKey={uuid.v4()}
            member={partner}
            navigateToMemberProfile={() =>
              navigateToMemberProfile(partner, false)
            }
            getGenderIcon={getGenderIcon(partner?.gender, partner?.is_dead)}
            getGenderIconBorderRadius={getGenderIconBorderRadius(
              partner?.gender,
              partner?.is_dead
            )}
            addToMemberButtonAction={() =>
              addToMemberButtonAction(partner, partner?.relationship)
            }
            relationship={relationship}
            customStyles={customStyles}
            titleText={titleText}
            colorSettings={colorSettings}
            component={
              <ChildrenView
                listKey={`${uuid.v4()}`}
                colorSettings={colorSettings}
                navigateToMemberProfile={navigateToMemberProfile}
                addToMemberButtonAction={addToMemberButtonAction}
                mainMember={mainMember}
                fromPartner={partner}
              />
            }
          />
        )
      })}
    </>
  )
}

export default PartnersView
