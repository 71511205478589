import { Component } from 'react'
import {
  Modal,
  Image,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
} from 'react-native'

import { WebView } from 'react-native-webview'

import { connect } from 'react-redux'

import LoadingView from '$constants/LoadingView.js'

class SimpleWebViewer extends Component {
  modalVisible = this.props.modalVisible

  websiteURL = this.props.websiteURL

  backButtonAction = this.props.backButtonAction ?? function () {}

  state = {
    showLoadingView: true,
    modalVisible: this.modalVisible,
    websiteURL: this.websiteURL,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const {modalVisible} = newProps
    const {websiteURL} = newProps

    const newState = prevState
    newState.modalVisible = modalVisible
    newState.websiteURL = websiteURL
    return newState
  }

  render() {
    const {colorSettings} = this.props

    return (
      <Modal
        animationType="slide"
        visible={this.state.modalVisible}
        transparent
      >
        <SafeAreaView
          style={{
            backgroundColor: 'white',
            width: '100%',
            flex: 1.0,
            // marginTop: 20,
          }}
        >
          {/* <Modal Header> */}
          <View
            style={{
              backgroundColor: 'white',
              width: '100%',
              paddingHorizontal: 6,
              height: 48,
              borderColor: 'rgba(100,100,100,0.5)',
              // borderTopWidth: 0.2,
              borderBottomWidth: 1.0,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                flex: 1.0,
                alignItems: 'center',
              }}
            >
              <View style={{ flex: 0.1 }}>
                <TouchableOpacity
                  style={{ height: '100%', justifyContent: 'center' }}
                  onPress={() => this.backButtonAction()}
                >
                  <Image
                    source={require('$assets/images/new_assets/back/black.png')}
                    style={{ width: 24, height: 24, resizeMode: 'contain' }}
                  />
                </TouchableOpacity>
              </View>

              <View style={{ flex: 0.9, alignContent: 'center' }}>
                <Text
                  style={{
                    fontFamily: 'karla-bold',
                    fontWeight: 'bold',
                    fontSize: 18,
                    textAlign: 'center',
                    color: 'black',
                  }}
                >
                  {this.state.webViewerTitle}
                </Text>
              </View>

              <View style={{ flex: 0.1 }} />
            </View>
          </View>
          {/* </Modal Header> */}

          {/* <Modal Content> */}
          <View style={{ flex: 1.0, width: '100%', backgroundColor: '#ccc' }}>
            <WebView
              style={{ flex: 1.0, width: '100%' }}
              source={{ uri: this.state.websiteURL ?? '' }}
              onLoadStart={() => this.setState({ showLoadingView: true })}
              onLoad={() => this.setState({ showLoadingView: false })}
            />
          </View>
          {/* </Modal Content> */}

          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
           />
        </SafeAreaView>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(SimpleWebViewer)
