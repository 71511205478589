import { Component } from 'react'
import {
  SafeAreaView,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Modal,
  Platform,
  RefreshControl,
  Dimensions,
} from 'react-native'

import { connect } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'

import { HIDE_RISK, SHOW_DOCUMENTS } from '$constants/envVariables.js'

import { DOMAIN_CLIENT } from '$constants/globalVariables.js'

import SuccessScreenSurveyCompleted from '$screens/success_screens/SuccessScreenSurveyComplete'
import DashboardNav from '$screens/main/dashboard/_includes/DashboardNav.component.js'
import LoadingView from '$constants/LoadingView.js'
import DashboardSettings from '$screens/main/dashboard/components/DashboardSettings.js'

import ApiFetchHandler from '$api/ApiFetchHandler'

import AuthCredentials from '$data_models/AuthCredentials'
import Account from '$data_models/Account'
import Proband from '$data_models/Proband'
import MemberProfile from '$data_models/MemberProfile.js'

import SettingsMenuButton from './components/SettingsMenuButton/SettingsMenuButton'

import { i18n } from '$localization/config.js'
import { IS_WEB, WEB } from '$constants/Platforms.js'
import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'
import * as DashboardMenuItems from '$constants/DashboardMenuItems.js'
import { setError, setIsOnboarding } from '$redux/defaults/actions.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import { mapDataToMemberProfile } from '$api/_utils/Utils.js'

import {
  setAuthCredentialsAction,
  clearAuthCredentialsAction,
  setAccountAction,
  clearAccountAction,
  setIsAccountDeletedAction,
} from '$redux/account/actions.js'

import {
  setBasicFamilyMembers,
  setProbandAction,
  clearProbandAction,
  setProbandProfileAction,
  clearProbandProfileAction,
  clearBasicFamilyMembers,
} from '$redux/proband/actions.js'

import {
  clearMemberProfileAction,
  storeCurrentMember,
  clearCurrentMember,
} from '$redux/member_profile/actions.js'

import { clearRiskFactorsAction } from '$redux/risk_factor/actions.js'

import { clearWorkflowDataAction } from '$redux/customFlowRoutes/actions.js'
import { clearCliniciansAction } from '$redux/clinician/actions.js'

import { clearSurveysAction } from '$redux/survey/actions.js'

import { resetDiseasesStore } from '$redux/diseases/actions.js'

import { resetGeneTestingStore } from '$redux/gene_testing/actions.js'

import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import styles from '$screens/main/dashboard/_styles/main.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'
import { deleteData, saveData } from '$constants/SecureStore'
import { setIsLogoutAction } from '$redux/account/actions'
import { setLastLoginScreen } from '../../../redux/defaults/actions'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

class DashboardScreen extends Component {
  scrollView = null

  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  account = this.props.account ?? new Account()

  proband = this.props.proband ?? new Proband()

  probandProfile = this.props.probandProfile ?? new MemberProfile()

  currentMember = null

  allowBackgroundRefresh = this.props.allowBackgroundRefresh

  backgroundRefreshInterval = this.props.backgroundRefreshInterval

  backgroundRefreshTimer = null

  appSettings = this.props.appSettings

  colorSettings = this.props.colorSettings

  familyData = []

  sectionListOrder = {
    [RelationshipTypes.PROBAND]: 0,
    [RelationshipTypes.SIBLINGS]: 1,
    [RelationshipTypes.PARENTS]: 2,
    [RelationshipTypes.PATERNAL_AUNTS_UNCLES]: 3,
    [RelationshipTypes.MATERNAL_AUNTS_UNCLES]: 4,
    [RelationshipTypes.PATERNAL_GRANDPARENTS]: 5,
    [RelationshipTypes.MATERNAL_GRANDPARENTS]: 6,
    [RelationshipTypes.OTHER_MEMBERS]: 7,
  }

  state = {
    ...this.state,
    modalVisible: false,
    shouldRenderComponent: false,
    showDashboardSettings: false,
    scrollViewHeight: DefaultFullHeightForWeb(),
    window: Dimensions.get('window'),
    errorMessage: '',
    isLoggingOut: false,
  }

  constructor(props) {
    super(props)

    const { navigation } = this.props

    navigation.setOptions({
      headerRight: () => (
        <SettingsMenuButton actionItem={this.toggleDashboardSettings} />
      ),
    })
  }

  onChangeDimension = ({ window }) => {
    this.setState({ window })
  }

  async componentDidMount() {
    const {
      saveIsUserLoggedOut,
      navigation,
      colorSettings,
      _saveProbandToStore_,
      proband,
      isFirstLogin,
      clearLastLoginScreen,
      account,
      _clearWorkflowDataAction,
      authCredentials,
      saveError,
    } = this.props

    navigation.setParams({
      cs: colorSettings,
    })

    _saveProbandToStore_({
      ...proband,
      onboarding: false,
    })
    clearLastLoginScreen(null)
    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      this.dimensionSubscribtion = Dimensions.addEventListener(
        'change',
        this.onChangeDimension
      )

      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }

    this.screenFocus = navigation.addListener('focus', () => {
      const { saveIsOnboarding } = this.props

      saveIsOnboarding(false)
    })

    this.setState({ shouldRenderComponent: false })

    // Set the component's navigation event listeners
    await this.setNavigationEventListener('focus')

    // API Request to get Proband Profile
    await this.getMemberProfile()

    // Get Survey status
    await this.getSurveyStatus()
    await this.getBasicFamilyMembers()

    this.setState({ shouldRenderComponent: true })

    _clearWorkflowDataAction()

    saveIsUserLoggedOut(false)

    const screen = {
      last_screen: '',
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
    })
    // Navigate to Family Info if 'isFirstLogin' to show tutorial.
    setTimeout(() => {
      if (isFirstLogin) this.navigateToFamilyInfo(true)
    }, 1000)
  }

  async componentWillUnmount() {
    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      this.dimensionSubscribtion?.remove()
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }

    this.screenFocus()

    // clear survey badge
    this.props._storeSurveyBadgeCount(0)

    // Clear auto background refresh timer when component unmounts.
    this.resetBackgroundRefreshTimer()

    this.setState({ isLoggingOut: false })
  }

  getBasicFamilyMembers = async () => {
    const { authCredentials } = this.props

    if (!this.proband?.probandID) return

    const { proband } = this
    const probandID = proband?.probandID
    const familyID = proband?.familyID

    const headers = authCredentials
    const payload = {
      proband_id: probandID,
    }

    /* Initialize Family data */
    this.familyData = [
      { title: RelationshipTypes.YOU_AND_YOUR_CHILDREN, data: [] },
      { title: RelationshipTypes.BROTHERS_AND_SISTERS, data: [] },
      { title: RelationshipTypes.PARENTS, data: [] },
      { title: RelationshipTypes.AUNTSUNCLES_DADS_SIDE, data: [] },
      { title: RelationshipTypes.AUNTSUNCLES_MOMS_SIDE, data: [] },
      { title: RelationshipTypes.GRANDPARENTS_DADS_SIDE, data: [] },
      { title: RelationshipTypes.GRANDPARENTS_MOMS_SIDE, data: [] },
      { title: RelationshipTypes.OTHER_FAMILY_MEMBERS, data: [] },
    ]

    const newFamilyData = this.familyData

    /* Reset Family Member ID list */
    this.familyMemberIDs = []

    /* Activate loading view */
    // this.reloadAndRenderView()

    /*
    1st-layer API Request:
    Load basic family Members
    */

    const getBasicFamilyMembers = {
      path: `family/${familyID}/members/`,
      method: 'post',
      token: authCredentials.accessToken,
      body: { proband_id: probandID },
      pageDetails: {
        page: 'DashboardScreen',
      },
    }
    const { saveError } = this.props
    const response = await ApiFetchHandler(getBasicFamilyMembers)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.setState({ shouldRenderComponent: false })

      return
    }

    const dataL1 = response
    const _keys = Object.keys(dataL1)

    // Loop through each family member by relationship to Proband.
    for (const i in _keys) {
      const relationshipToProband = `${_keys[i]}`.toLowerCase()

      /* Get section key */
      const SECTION_KEY = this.sectionListOrder[relationshipToProband]

      switch (relationshipToProband) {
        case RelationshipTypes.PROBAND:
          var _memberData = dataL1[relationshipToProband]

          newFamilyData[SECTION_KEY] = {
            ...newFamilyData[SECTION_KEY],
            data: await this.addToFamilyData([_memberData], payload, headers),
            // data: this.addToFamilyData([_memberData], payload, headers, true),
          }

          // Break switch
          break

        case RelationshipTypes.PATERNAL_AUNTS_UNCLES:
        case RelationshipTypes.MATERNAL_AUNTS_UNCLES:
        case RelationshipTypes.SIBLINGS:
          var memberList = dataL1[relationshipToProband]

          newFamilyData[SECTION_KEY] = {
            ...newFamilyData[SECTION_KEY],
            data: await this.addToFamilyData(memberList, payload, headers),
          }

          // Break switch
          break

        case RelationshipTypes.PATERNAL_GRANDPARENTS:
        case RelationshipTypes.MATERNAL_GRANDPARENTS:
        case RelationshipTypes.PARENTS:
          var memberList = dataL1[relationshipToProband]

          newFamilyData[SECTION_KEY] = {
            ...newFamilyData[SECTION_KEY],
            data: await this.addToFamilyData(
              memberList,
              payload,
              headers,
              true
            ),
          }

          // Break switch
          break

        /* TODO: Figure out if this works for every RelationshipType */
        /*
            default:
              var memberList = dataL1[relationshipToProband];

              newFamilyData[SECTION_KEY] = {
                ...newFamilyData[SECTION_KEY],
                data: this.addToFamilyData(memberList, payload, headers),
              };

              // Break switch
              break KeyLoop2;
            */
      }
    }

    /**
         What to do here?
         * Update family data on Redux.
         * Update the data on SectionList.
         * Stop refreshing of SectionList.
         * Should not stop loading view.
        */
    // Save basic family members to redux store.
    this.props._setBasicFamilyMembers_(this.familyData)
  }

  addToFamilyData = async (
    memberList,
    payload,
    headers,
    partnersOnly = false
  ) => {
    const sectionData = []
    const relationshipList = []

    await memberList.forEach(async (memberData, index) => {
      const constructedMemberData =
        new MemberProfile().constructBasicMemberData(memberData)

      const { relationship_to_proband } = memberData

      const item = {
        relationshipName: relationship_to_proband,
        count: 1,
      }

      const [
        hasRelationshipItem = false,
        hasRelationshipCount = false,
        hasRelationshipIndex = false,
      ] = this.familyMemberCounter(
        relationshipList,
        memberList,
        relationship_to_proband
      )

      if (hasRelationshipItem) relationshipList.push(item)
      if (hasRelationshipCount)
        constructedMemberData.member.relationshipCount = 1
      if (hasRelationshipIndex) {
        const getRelationshipIndex = relationshipList.findIndex(
          (relationship) =>
            relationship.relationshipName === relationship_to_proband
        )
        constructedMemberData.member.relationshipCount =
          relationshipList[getRelationshipIndex].count + 1

        relationshipList[getRelationshipIndex].count += 1
      }

      // Add Member ID to Family Member ID list
      this.familyMemberIDs.push(constructedMemberData?.member?.member_id)

      sectionData.push(constructedMemberData)

      // Load partners and children of Member.

      // await this.getMemberPartnerAndChildren(
      //   constructedMemberData,
      //   payload,
      //   headers,
      //   partnersOnly
      // )
    })

    return sectionData
  }

  familyMemberCounter = (
    relationshipList,
    memberList,
    relationshipToProband
  ) => {
    const relationshipExceptions = [
      'mother',
      'father',
      'maternal grandfather',
      'paternal grandfather',
      'maternal grandmother',
      'paternal grandmother',
    ]
    if (memberList.length <= 1) return []
    if (relationshipExceptions.includes(relationshipToProband)) return []

    if (relationshipList.length < 1) {
      return [true, true]
    }

    const getRelationshipStatus = relationshipList.find(
      (childrenRelationship) =>
        childrenRelationship?.relationshipName === relationshipToProband
    )

    if (!getRelationshipStatus) {
      return [true, true]
    }

    return [false, false, true]
  }

  resetLoadingOfOtherFamilyMembers = (stopTimer = false) => {
    // Clear timers before initializing a new one.
    this.resetBackgroundRefreshTimer()

    if (!stopTimer) {
      let countdown = this.otherFamilyMembersTimeout / 1000

      this.countdownTimer = setInterval(() => {
        countdown -= 1
      }, 1000)

      this.otherFamilyMembersTimer = setTimeout(async () => {
        await this.getOtherFamilyMembers(this.familyMemberIDs)
      }, this.otherFamilyMembersTimeout)
    }
  }

  setNavigationEventListener = async (event = 'focus') => {
    // Set the component's navigation event listeners
    // Default event: 'didFocus'
    const { navigation } = this.props
    await navigation.addListener(event, async () => {
      await this.setNavigationEventAction()
    })
  }

  setNavigationEventAction = async () => {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    await this.getMemberProfile()
  }

  getSurveyStatus = async () => {
    const { _storeSurveyStatus, saveError, authCredentials } = this.props

    const getProbandSurveyPayload = {
      path: 'family/surveys/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        domain_client: DOMAIN_CLIENT?.toUpperCase(),
      },
      pageDetails: {
        page: 'DashboardScreen.js',
      },
    }

    const response = await ApiFetchHandler(getProbandSurveyPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.setState({ shouldRenderComponent: false })
      return
    }

    const survey = response?.clinician_surveys
    if (!Array.isArray(survey) || !survey.length) {
      _storeSurveyStatus(false)
      return
    }
    _storeSurveyStatus(true)
  }

  toggleDashboardSettings = () => {
    this.setState({ showDashboardSettings: !this.state.showDashboardSettings })
  }

  // set ScrollView scroll to top
  _scrollToTop = () => {
    // check ref scrollView
    if (this.scrollView) {
      this.scrollView.scrollTo({ x: 0, y: 0, animated: false })
    }
  }

  resetBackgroundRefreshTimer = () => {
    clearInterval(this.backgroundRefreshTimer)
    this.backgroundRefreshTimer = null
  }

  getMemberProfile = async () => {
    const { authCredentials, selectedLanguage } = this.props

    try {
      const probandID = this.props.proband?.probandID ?? null

      const { saveError } = this.props

      if (!probandID) return

      // Get MemberProfile of Proband from API
      const getMemberProfilePayload = {
        path: 'member/get/',
        method: 'post',
        token: authCredentials.accessToken,
        body: {
          proband_id: probandID,
          member_id: probandID,
          lang: selectedLanguage,
        },
        pageDetails: {
          page: 'DashboardScreen.js',
        },
        params: {
          lang: selectedLanguage,
        },
      }

      const response = await ApiFetchHandler(getMemberProfilePayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })

        this.setState({ shouldRenderComponent: false })

        return
      }

      // Map data to MemberProfile instance
      const memberProfile = (this.probandProfile =
        mapDataToMemberProfile(response))

      const profileData = memberProfile?.profileData

      // Save proband profile to redux store
      this.props._saveProbandProfileToStore_(memberProfile)

      let age = profileData?.currentAge
      if (profileData?.deceased) {
        age = profileData?.ageOfDeath
      }

      // Create basic member profile data
      this.currentMember = new MemberProfile().constructBasicProfile({
        relationship_to_proband: RelationshipTypes.PROBAND,
        member_id: memberProfile?.memberID,
        first_name: profileData?.firstName,
        last_name: profileData?.lastName,
        gender: profileData?.biologicalGender,
        is_dead: profileData?.deceased,
        age,
      })
    } catch (error) {
      console.log(
        '🚀 \n\n file: DashboardScreen.js:596 \n\n DashboardScreen \n\n getMemberProfile= \n\n error:',
        error
      )
    }
  }

  navigateToProfileInfo = () => {
    const { currentMember } = this

    /* Save basic member profile data to redux store */
    this.props._saveCurrentMemberToStore_(currentMember)

    /* Proceed navigating to Edit Profile */
    if (this.proband?.probandID) this.navigateTo('EditProfileView')
  }

  navigateTo = (routeName, params = {}) => {
    const { navigation, isFirstLogin } = this.props

    const { navigate, push, popToTop } = navigation

    const isFamilyTab = routeName === 'FamilyMainView'

    if (!isFamilyTab) return navigate(routeName, { ...params })

    if (isFirstLogin && isFamilyTab) return navigate(routeName, { ...params })

    if (isFamilyTab) {
      navigate(routeName, { ...params })
    }

    popToTop()
    return push(routeName, { ...params })
  }

  navigateToFamilyInfo = (tabMode = false) => {
    const routeName = 'Family'

    this.navigateTo(routeName, {
      ftab: false,
    })
  }

  navigateToRisks = (tabMode = false) => {
    const routeName = 'Risks'

    this.navigateTo(routeName, {
      ftab: false,
    })
  }

  navigateToSurveys = (tabMode = false) => {
    const routeName = 'Surveys'

    this.navigateTo(routeName, {
      ftab: false,
    })
  }

  navigateToGeneticResources = () => {
    this.navigateTo('GeneticResources')
  }

  /* TODO: Needs fixing */
  navigateToNotifications = () => {
    this.navigateTo('NotificationsMain')
  }

  navigateToClinicians = () => {
    this.navigateTo('ClinicianListView')
  }

  navigateToSharingSettings = () => {
    this.navigateTo('SharingSettingsMain')
  }

  /* TODO: Needs fixing */
  navigateToInvite = () => {
    this.navigateTo('InviteFamilyMemberMain')
  }

  // FIXME: add loading to the button
  logoutButtonAction = async () => {
    const { navigation, inviteID, saveIsUserLoggedOut } = this.props

    this.setState({ isLoggingOut: true })

    try {
      saveIsUserLoggedOut(true)

      this.resetStoredData()

      await saveData({ key: 'isLogout', value: 'true' })

      await deleteData({ key: 'BIOMETRICS' })

      navigation.replace('AuthStack', {
        id: inviteID,
        isLogout: true,
      })
    } catch (error) {
      this.setState({ isLoggingOut: false })

      console.log(
        '🚀 \n\n file: DashboardScreen.js:689 \n\n DashboardScreen \n\n logoutButtonAction= \n\n error:',
        error
      )
    }
  }

  resetStoredData = async () => {
    /** Clears some data from redux store */
    this.props._clearAuthCredentialsFromStore_()
    this.props._clearAccountActionFromStore_()
    this.props._clearProbandFromStore_()
    this.props._clearProbandProfileFromStore_()
    this.props._clearMemberProfileFromStore_()
    this.props._clearCurrentMember_()
    this.props._clearRiskFactorsAction_()
    this.props._clearCliniciansAction_()
    this.props._clearSurveysAction_()
    this.props._clearBasicFamilyMembers_()
    this.props._clearWorkflowDataAction()

    this.props._resetDiseasesStore_()
    this.props._resetGeneTestingStore_()
  }

  deleteAccount = async () => {
    const { authCredentials, saveError, saveIsAccountDeleted } = this.props

    const deleteAccountPayload = {
      path: 'account/delete/',
      method: 'delete',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'DashboardScreen.js',
      },
    }

    const response = await ApiFetchHandler(deleteAccountPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.setState({ shouldRenderComponent: false })
    }

    saveIsAccountDeleted(true)
  }

  exitErrorViewHandler = () => {
    this.setState((prevState) => ({ showErrorView: !prevState.ErrorView }))
  }

  render() {
    const { isLoggingOut } = this.state
    const { navigation, colorSettings } = this.props
    const { window } = this.state

    const isSmallScreen = window.width < 500
    const isWebBrowser = Platform.OS === 'web'

    if (!this.state.shouldRenderComponent || isLoggingOut) {
      return (
        <SafeAreaView
          style={[
            global_styles.containerWhite,
            {
              backgroundColor:
                colorSettings?.post_onboarding_bgcolor ?? 'rgb(245,245,245)',
              ...Platform.select({
                [WEB]: {
                  flex: null,
                  height: this.state.scrollViewHeight,
                },
              }),
            },
          ]}
        >
          <LoadingView
            visible={!this.state.shouldRenderComponent || isLoggingOut}
            tintColor={colorSettings?.post_onboarding_nav_bgcolor}
            textColor={colorSettings?.post_onboarding_nav_bgcolor}
            message={
              isLoggingOut
                ? i18n.t('logout')?.default
                : i18n.t('please_wait')?.default
            }
          />
        </SafeAreaView>
      )
    }

    const probandName = this.currentMember?.name
    const { probandProfile } = this

    const profileData = probandProfile?.profileData
    const biologicalGender = profileData?.biologicalGender ?? null

    let headerBackground = 'lightgray'
    let borderColor = 'lightgray'
    let avatarImage = null
    let titleColor = 'black'
    let subtitleColor = 'black'

    if (biologicalGender) {
      switch (biologicalGender) {
        case 'm':
          avatarImage = require('$assets/images/icon/people/male.png')
          headerBackground = colorSettings?.member_tile_male_bg ?? 'white'
          borderColor = colorSettings?.member_tile_male_border
          titleColor = colorSettings?.member_tile_male_title
          subtitleColor = colorSettings?.member_tile_male_subtitle
          break
        case 'f':
          avatarImage = require('$assets/images/icon/people/female.png')
          headerBackground = colorSettings?.member_tile_female_bg ?? 'white'
          borderColor = colorSettings?.member_tile_female_border
          titleColor = colorSettings?.member_tile_female_title
          subtitleColor = colorSettings?.member_tile_female_subtitle
          break
      }
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor:
                  colorSettings?.post_onboarding_bgcolor ?? 'rgb(245,245,245)',
                ...Platform.select({
                  [WEB]: {
                    flex: null,
                    height: this.state.scrollViewHeight,
                  },
                }),
              },
            ]}
          >
            <ScrollView
              nativeID="web_scaled_main_container"
              style={{
                ...Platform.select({
                  [WEB]: {
                    height: this.state.scrollViewHeight,
                  },
                }),
              }}
              contentContainerStyle={{ paddingVertical: 16 }}
              ref={(ref) => (this.scrollView = ref)}
              // bounces={false}
              refreshControl={
                <RefreshControl
                  refreshing={this.state.showLoadingView}
                  onRefresh={() => this.getMemberProfile()}
                />
              }
            >
              <TouchableOpacity
                style={[
                  styles.profile,
                  {
                    backgroundColor: headerBackground,
                    borderColor,
                  },
                ]}
                onPress={() => this.navigateToProfileInfo()}
              >
                <View
                  style={[
                    styles.profImageContainer,
                    isSmallScreen && isWebBrowser && { flex: 1.5 },
                  ]}
                >
                  <Image
                    source={avatarImage}
                    style={{ width: 42, height: 42, resizeMode: 'contain' }}
                  />
                </View>

                <View style={styles.profDetails}>
                  <Text
                    style={[
                      styles.profInfoName,
                      {
                        color: titleColor,
                      },
                    ]}
                  >
                    {probandName ?? ''}
                  </Text>
                  <Text
                    style={[
                      styles.profInfo,
                      {
                        color: subtitleColor,
                      },
                    ]}
                  >
                    {i18n.t(DashboardMenuItems.VIEW_MY_INFO)?.default}
                  </Text>
                </View>

                <View style={styles.profileNext}>
                  <Image
                    source={require('$assets/images/new_assets/icon-dash-arrow.png')}
                    style={{ width: 10, height: 16, resizeMode: 'contain' }}
                  />
                </View>
              </TouchableOpacity>

              <View style={styles.DetailContainer}>
                {/* Family */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-family.png')}
                  buttonName={DashboardMenuItems.FAMILY}
                  navigateTo={() => this.navigateToFamilyInfo()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                />

                {/* Surveys */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-surveys.png')}
                  buttonName={DashboardMenuItems.SURVEYS}
                  showBadge
                  proband={this.proband}
                  navigateTo={() => this.navigateToSurveys()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                  navigation={navigation}
                />

                {/* TODO: What is this for? */}
                {/* survey completed modal */}
                {false && (
                  <Modal visible={this.state.modalVisible}>
                    <SuccessScreenSurveyCompleted parent={this} />
                  </Modal>
                )}

                {/* Risks */}
                {HIDE_RISK == 'false' && (
                  <DashboardNav
                    icon={require('$assets/images/new_assets/icon-dash-risks.png')}
                    buttonName={DashboardMenuItems.RISKS}
                    navigateTo={() => this.navigateToRisks()}
                    isSmallScreen={isSmallScreen}
                    isWebBrowser={isWebBrowser}
                  />
                )}

                {/* Family Updates (Notifications) */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-updates.png')}
                  buttonName={DashboardMenuItems.FAMILY_UPDATES}
                  showBadge
                  proband={this.proband}
                  navigateTo={() => this.navigateToNotifications()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                  navigation={navigation}
                />

                {/* Genetic Resources */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-genetic.png')}
                  buttonName={DashboardMenuItems.GENETIC_RESOURCES}
                  navigateTo={() => this.navigateToGeneticResources()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                />

                {/* Documents */}
                {SHOW_DOCUMENTS == 'true' && (
                  <DashboardNav
                    icon={require('$assets/images/new_assets/icon-dash-documents.png')}
                    buttonName={DashboardMenuItems.DOCUMENTS}
                    navigateTo={() =>
                      showDefaultAlert(
                        i18n.t('documents')?.default,
                        i18n.t('coming_soon')?.default
                      )
                    }
                    isSmallScreen={isSmallScreen}
                    isWebBrowser={isWebBrowser}
                  />
                )}

                {/* Clinicians */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-clinicians.png')}
                  buttonName={DashboardMenuItems.CLINICIANS}
                  navigateTo={() => this.navigateToClinicians()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                />

                {/* Sharing Settings */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-sharing.png')}
                  buttonName={DashboardMenuItems.SHARING_SETTINGS}
                  navigateTo={() => this.navigateToSharingSettings()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                />

                {/* Invite */}
                <DashboardNav
                  icon={require('$assets/images/new_assets/icon-dash-invite.png')}
                  hideSeparator
                  buttonName={DashboardMenuItems.INVITE}
                  navigateTo={() => this.navigateToInvite()}
                  isSmallScreen={isSmallScreen}
                  isWebBrowser={isWebBrowser}
                />
              </View>

              {/* <Logout Button> */}
              <TouchableOpacity
                style={styles.logoutButton}
                onPress={() => this.logoutButtonAction()}
              >
                <Text
                  style={[
                    styles.logoutButtonText,
                    {
                      color: colorSettings?.btn_no_fill_text_3,
                    },
                  ]}
                >
                  {i18n.t(DashboardMenuItems.LOGOUT)?.default}
                </Text>
              </TouchableOpacity>
            </ScrollView>

            {/* Menu */}
            <Modal
              visible={this.state.showDashboardSettings}
              transparent
              onRequestClose={this.toggleDashboardSettings}
            >
              <DashboardSettings
                navigateTo={(routeName, params) =>
                  this.navigateTo(routeName, params)
                }
                toggleDashboardSettings={this.toggleDashboardSettings}
                logoutButtonAction={this.logoutButtonAction}
                deleteAccountAction={this.deleteAccount}
              />
            </Modal>
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    inviteID: store.inviteID,
    isFirstLogin: store.isFirstLogin,
    allowBackgroundRefresh: store.allowBackgroundRefresh,
    backgroundRefreshInterval: store.backgroundRefreshInterval,
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    tutorialWithSurvey: store.tutorialWithSurvey,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
    /** Proband Store */
    proband: probandStore.proband,
    probandProfile: probandStore.probandProfile,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
    currentMember: memberProfileStore.currentMember,
    selectedLanguage: store.selectedLanguage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    /** Old actions */
    _storeAccount: (account) =>
      dispatch({ type: 'STORE_ACCOUNT', data: account }),
    _storeProband: (proband) =>
      dispatch({ type: 'STORE_PROBAND', data: proband }),
    _storeSurveyStatus: (status) =>
      dispatch({ type: 'SET_SURVEY_STATUS', data: status }),
    _storeSurveyBadgeCount: (surveyBadgeCount) =>
      dispatch({ type: 'STORE_SURVEYCOUNT', data: surveyBadgeCount }),

    /** Refactored actions */
    _saveAuthCredentialsToStore_: (authCredentials) =>
      dispatch(setAuthCredentialsAction(authCredentials)),

    saveIsUserLoggedOut: (bool) => dispatch(setIsLogoutAction(bool)),
    saveIsAccountDeleted: (bool) => dispatch(setIsAccountDeletedAction(bool)),
    _saveAccountToStore_: (account) => dispatch(setAccountAction(account)),
    clearLastLoginScreen: (screen) => dispatch(setLastLoginScreen(screen)),
    _saveProbandToStore_: (proband) => dispatch(setProbandAction(proband)),
    _saveProbandProfileToStore_: (probandProfile) =>
      dispatch(setProbandProfileAction(probandProfile)),

    _saveCurrentMemberToStore_: (data) => dispatch(storeCurrentMember(data)),

    _clearAuthCredentialsFromStore_: () =>
      dispatch(clearAuthCredentialsAction()),
    _clearAccountActionFromStore_: () => dispatch(clearAccountAction()),
    _clearProbandFromStore_: () => dispatch(clearProbandAction()),
    _clearProbandProfileFromStore_: () => dispatch(clearProbandProfileAction()),
    _clearMemberProfileFromStore_: () => dispatch(clearMemberProfileAction()),
    _clearCurrentMember_: () => dispatch(clearCurrentMember()),
    _clearRiskFactorsAction_: () => dispatch(clearRiskFactorsAction()),
    _clearCliniciansAction_: () => dispatch(clearCliniciansAction()),
    _clearSurveysAction_: () => dispatch(clearSurveysAction()),
    _clearBasicFamilyMembers_: () => dispatch(clearBasicFamilyMembers()),
    _clearWorkflowDataAction: () => dispatch(clearWorkflowDataAction()),

    /* DiseasesStore actions */
    _resetDiseasesStore_: () => dispatch(resetDiseasesStore()),
    /* GeneTesting actions */
    _resetGeneTestingStore_: () => dispatch(resetGeneTestingStore()),
    _setBasicFamilyMembers_: (data) => dispatch(setBasicFamilyMembers(data)),
    saveError: (data) => dispatch(setError(data)),
    saveIsOnboarding: (data) => dispatch(setIsOnboarding(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen)
