import { useState } from 'react'
import { View, TextInput, StyleSheet, KeyboardAvoidingView } from 'react-native'

import { connect } from 'react-redux'

import global_styles from '$constants/styles/global.styles.js'

const DiseaseTextInput = (props) => {
  const {colorSettings} = props

  return (
    <View
      style={[
        global_styles.mainTextInputContainer,
        props.containerStyle,
        {
          borderColor: colorSettings?.text_input_placeholder_color_2,
        },
      ]}
    >
      <TextInput
        {...props}
        style={[
          global_styles.mainTextInput,
          {
            color: colorSettings?.text_input_color_2 || 'rgba(0,0,0,1)',
          },
        ]}
        placeholder="0"
        // placeholderTextColor={'rgba(255,255,255,0.5)'}
        placeholderTextColor={
          colorSettings?.text_input_placeholder_color_2 || 'rgba(0,0,0,0.5)'
        }
        selectionColor={colorSettings?.text_input_color_2}
        // returnKeyType={'done'}
        maxLength={3}
        keyboardType="number-pad"
        autoCapitalize="none"
        clearTextOnFocus
       />
    </View>
  )
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps)(DiseaseTextInput)
