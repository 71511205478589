import { Component } from 'react'
import {
  ScrollView,
  Text,
  View,
  FlatList,
  SafeAreaView,
  Platform,
} from 'react-native'
import { connect } from 'react-redux'
import { CUSTOM_SURVEY_URL } from '$constants/envVariables.js'
import { DOMAIN_CLIENT } from '$constants/globalVariables.js'
import { IS_WEB, WEB } from '$constants/Platforms.js'
import { i18n } from '$localization/config.js'
import LoadingView from '$constants/LoadingView.js'
import SurveyButtonComponent from './survey_screen_components/SurveyButtonComponent.js'
import ErrorBoundary from 'react-native-error-boundary'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import AuthCredentials from '$data_models/AuthCredentials'

import Proband from '$data_models/Proband'

import { isEmptyObject } from '$api/_utils/Utils.js'

import { setCliniciansAction } from '$redux/clinician/actions.js'

import {
  setSurveysAction,
  setClinicianSurveysAction,
  setOptionalSurveysAction,
  setSelectedSurveyAction,
} from '$redux/survey/actions.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import styles from '$screens/main/survey/_static/main.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

import { getLang } from '$utils/lang_utils.js'

class SurveyCompleted extends Component {
  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  backgroundRefreshTimer = null

  sectionListData = []

  constructor(props) {
    super(props)

    this.state = {
      showLoadingView: true,
      buttonData: [],
      surveyList: [],
      scrollViewHeight: DefaultFullHeightForWeb(),
    }
  }

  componentDidMount() {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })

    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }

    // API request to get Family Clinicians
    this.getFamilyClinicians()

    // Activate auto background refresh.
    if (this.props.allowBackgroundRefresh) {
      this.handleBackgroundRefresh()
    }

    // Set the component's navigation event listeners
    this.setNavigationEventListener('focus')
  }

  componentWillUnmount() {
    this.resetBackgroundRefreshTimer()

    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: DefaultFullHeightForWeb() })
      )
    }
  }

  setNavigationEventListener = (event = 'focus') => {
    const { navigation } = this.props

    navigation.addListener(event, async () => {
      this.setNavigationEventAction()
    })
  }

  setNavigationEventAction = () => {
    this.props.navigation.setParams({
      cs: this.props.colorSettings,
    })
  }

  handleBackgroundRefresh = (activate = true) => {
    this.resetBackgroundRefreshTimer()

    if (!activate) return

    const { allowBackgroundRefresh, backgroundRefreshInterval } = this.props

    if (allowBackgroundRefresh) {
      this.backgroundRefreshTimer = setInterval(async () => {
        // Call API request again.
        await this.getFamilyClinicians()
      }, backgroundRefreshInterval)
    }
  }

  resetBackgroundRefreshTimer = () => {
    clearInterval(this.backgroundRefreshTimer)
    this.backgroundRefreshTimer = null
  }

  getFamilyClinicians = async () => {
    /* API request to get Family Clinicians. */
    const { authCredentials, _setCliniciansAction_, saveError } = this.props
    const { proband } = this
    const familyID = proband?.familyID

    if (!familyID) return

    this.sectionListData = []

    const familyClinicianPayload = {
      path: `family/${familyID}/clinicians/`,
      method: 'get',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'SurveyScreen.js',
      },
    }

    const response = await ApiFetchHandler(familyClinicianPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      this.setState({ showLoadingView: false })
      return
    }

    if (response.length > 0) {
      /* Save clinican data to redux store */
      _setCliniciansAction_(response)

      const newData = {
        title: 'Clinicians',
        data: response,
      }

      this.sectionListData.push(newData)
    }

    await this.getByClinicianSurveys(this.sectionListData.length !== 0)
  }

  async getByClinicianSurveys(connected = false) {
    const { authCredentials, saveError } = this.props

    const getProbandSurveyPayload = {
      path: 'family/surveys/',
      method: 'post',
      token: authCredentials?.accessToken,
      body: {
        domain_client: DOMAIN_CLIENT?.toUpperCase(),
      },
      pageDetails: {
        page: 'SurveyScreen.js',
      },
    }

    const response = await ApiFetchHandler(getProbandSurveyPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      this.setState({ showLoadingView: false })

      return
    }

    const clinicianSurveys = response?.clinician_surveys
    const optionalSurveys = response?.optional_surveys

    // Save survey data to redux store
    if (response || response?.length > 0) {
      this.props._setSurveysAction_(response)
      this.props._setClinicianSurveysAction_(clinicianSurveys)
      this.props._setOptionalSurveysAction_(optionalSurveys)
    }

    const surveyPerClinicianList = []

    if (clinicianSurveys.length !== 0) {
      for (const surveys of clinicianSurveys) {
        const surveyData = []
        for (const item of surveys.surveys) {
          const buttonIcon =
            item.status === 'in_progress'
              ? require('$assets/images/new_assets/icon-survey-warn.png')
              : require('$assets/images/new_assets/icon-survey-ok.png')

          const survey = {
            id: item?.id,
            buttonTitle: item?.name,
            buttonIcon,
            navItem: 'SurveyCustom',
            dataItems: {
              surveyID: item?.id,
              clinician_code: surveys?.clinician_code,
            },
            isCustomSurvey: true,
          }

          surveyData.push(survey)
        }

        const tempClinicianList = {
          clinician_header: surveys.header,
          clinician_list: surveyData,
        }

        surveyPerClinicianList.push(tempClinicianList)
      }
    }

    if (DOMAIN_CLIENT !== 'COH-APP') {
      const surveyData = []
      for (const item of optionalSurveys) {
        const buttonIcon =
          item.status === 'in_progress'
            ? require('$assets/images/new_assets/icon-survey-warn.png')
            : require('$assets/images/new_assets/icon-survey-ok.png')

        const survey = {
          id: item?.id,
          buttonTitle: item?.name,
          buttonIcon,
          navItem: 'SurveyCustom',
          dataItems: {
            surveyID: item?.id,
            clinician_code: null,
          },
          isCustomSurvey: true,
        }

        surveyData.push(survey)
      }

      if (surveyData.length !== 0) {
        const tempOptionalList = {}
        if (connected) {
          tempOptionalList.clinician_header =
            i18n.t('optional_surveys')?.default
          tempOptionalList.clinician_subheader = i18n.t(
            'not_shared_with_your_clinician'
          )?.default
        }
        tempOptionalList.clinician_list = surveyData
        surveyPerClinicianList.push(tempOptionalList)
      }
    }

    this.setState({
      surveyList: surveyPerClinicianList,
      showLoadingView: false,
    })
  }

  setSurveyUrl = async (item = {}) => {
    // TODO: Improve this error handling, we should show an alert or navigate to a default route
    if (!this.proband || isEmptyObject(this.proband)) return

    this.setState({ showLoadingView: true })

    const { saveError } = this.props

    const accessToken = this.authCredentials?.accessToken
    const probandID = this.proband?.probandID
    const surveyItem = JSON.parse(JSON.stringify(item))

    if (!surveyItem?.clinician_code) {
      const customURL = `${CUSTOM_SURVEY_URL}/${accessToken}/${
        surveyItem?.surveyID
      }/${probandID}/${getLang()}`

      surveyItem.customURL = customURL
      surveyItem.accessToken = accessToken

      this.setState({ showLoadingView: false })

      this.navigateToSurvey(surveyItem)
    } else {
      const clinicianCheckPayload = {
        path: 'clinician/check/',
        method: 'post',
        token: accessToken,
        body: {
          clinician_code: surveyItem?.clinician_code,
        },
        pageDetails: {
          page: 'SurveyScreen.js',
        },
      }

      const response = await ApiFetchHandler(clinicianCheckPayload)

      if (response.isError) {
        this.setState({ showLoadingView: false })

        setTimeout(() => {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
        }, 100)
      }

      const clinicianCodeId = response?.clinician_code?.id

      const customURL = `${CUSTOM_SURVEY_URL}/${accessToken}/${
        surveyItem?.surveyID
      }/${probandID}/${clinicianCodeId}/${getLang()}`

      surveyItem.customURL = customURL
      surveyItem.accessToken = accessToken

      this.setState({ showLoadingView: false })

      this.navigateToSurvey(surveyItem)
    }
  }

  navigateToSurvey = (surveyItem) => {
    const { navigation, _setSelectedSurveyAction_, colorSettings } = this.props

    _setSelectedSurveyAction_(surveyItem)

    navigation.navigate('CustomScreen', {
      nextPage: 0,
      prevPage: 0,
      navEditMode: false,
      navCompletedSurvey: null,
    })
  }

  renderSurveyList() {
    const { colorSettings } = this.props

    if (this.state.surveyList.length !== 0) {
      const list = []
      for (const i in this.state.surveyList) {
        const clinician = this.state.surveyList[i]
        if (clinician?.clinician_list?.length !== 0) {
          list.push(
            <View key={`${i}-${clinician?.clinician_header}`}>
              {clinician?.clinician_header && (
                <Text
                  style={[
                    styles.sectionTitleText,
                    {
                      color: colorSettings?.member_section_title,
                    },
                  ]}
                >
                  {clinician?.clinician_header}
                </Text>
              )}

              {clinician?.clinician_subheader && (
                <Text
                  style={[
                    styles.sectionSubTitleText,
                    {
                      color: colorSettings?.member_section_title,
                    },
                  ]}
                >
                  {clinician?.clinician_subheader}
                </Text>
              )}

              <FlatList
                style={{ paddingTop: 16 }}
                data={clinician?.clinician_list}
                renderItem={({ item }) => {
                  const onPressItem = () => {
                    this.navigateTo(
                      item?.navItem,
                      item?.dataItems,
                      item?.isCustomSurvey,
                      item
                    )
                  }

                  return (
                    <SurveyButtonComponent
                      key={`${item?.id}-<SurveyButtonComponent>`}
                      colorSettings={colorSettings}
                      onPressItem={() => onPressItem()}
                      buttonTitle={item?.buttonTitle}
                      buttonIcon={item?.buttonIcon}
                      navItem={item?.navItem}
                      dataItems={item?.dataItems}
                      isCustomSurvey={item?.isCustomSurvey}
                    />
                  )
                }}
              />
            </View>
          )
        }
      }
      return (
        <ScrollView
          style={{ width: '100%' }}
          nativeID="web_scaled_main_container"
          bounces={false}
        >
          {list}
        </ScrollView>
      )
    }

    return (
      <View
        style={{
          paddingHorizontal: 16,
          flex: 1.0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.emptyMessageText}>
          {
            i18n.t(
              'you_do_not_have_any_additional_surveys_to_complete_right_now'
            )?.default
          }
        </Text>
      </View>
    )
  }

  navigateTo = (direction, data, isCustomSurvey, item) => {
    /* Save selected survey on Redux store for later use */
    const surveyItem = data

    this.props._setSelectedSurveyAction_(surveyItem)

    if (isCustomSurvey) {
      this.setSurveyUrl(surveyItem)
    } else {
      const { navigate } = this.props.navigation

      navigate(direction)
    }
  }

  render() {
    const { colorSettings, navigation } = this.props

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor:
                  this.colorSettings?.post_onboarding_bgcolor ??
                  'rgb(245,245,245)',
                ...Platform.select({
                  [WEB]: {
                    flex: null,
                    height: this.state.scrollViewHeight,
                  },
                }),
              },
            ]}
          >
            {this.renderSurveyList()}

            {this.state.showLoadingView && (
              <LoadingView
                visible={this.state.showLoadingView}
                tintColor={colorSettings?.post_onboarding_nav_bgcolor}
                textColor={colorSettings?.post_onboarding_nav_bgcolor}
              />
            )}
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, clinicianStore, surveyStore } =
    state

  return {
    /** Default Store */
    allowBackgroundRefresh: store.allowBackgroundRefresh,
    backgroundRefreshInterval: store.backgroundRefreshInterval,
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    proband: probandStore.proband,
    /** Clinican Store */
    clinicians: clinicianStore.clinicians,
    /** Survey Store */
    surveys: surveyStore.surveys,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setCliniciansAction_: (data) => dispatch(setCliniciansAction(data)),
    _setSurveysAction_: (data) => dispatch(setSurveysAction(data)),
    _setClinicianSurveysAction_: (data) =>
      dispatch(setClinicianSurveysAction(data)),
    _setOptionalSurveysAction_: (data) =>
      dispatch(setOptionalSurveysAction(data)),
    _setSelectedSurveyAction_: (data) =>
      dispatch(setSelectedSurveyAction(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCompleted)
