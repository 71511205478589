import moment from 'moment'
import MemberProfile from '$data_models/MemberProfile.js'
import Family from '$data_models/Family.js'
import { i18n } from '$localization/config.js'

const defaultDateFormat = 'MM/DD/YYYY'
const defaultDateFormatAPI = 'YYYY-MM-DD'

const getParentBloodRelationTypes = () => {
  const relationshipTypes = [
    {
      id: 1,
      key: 'siblings',
      name: 'siblings',
      definition: 'parents_shares_both_parents_in_common',
    },
    {
      id: 2,
      key: 'half_siblings',
      name: 'half-siblings',
      definition: 'parents_shares_one_parent_in_common',
    },
    {
      id: 3,
      key: 'father-daughter',
      name: 'father_slash_daughter',
      definition:
        'male_parent_having_a_sexual_relationship_with_one_of_his_female_offspring',
    },
    {
      id: 4,
      key: 'mother-son',
      name: 'mother_slash_son',
      definition:
        'female_parent_having_a_sexual_relationship_with_one_of_her_male_offspring',
    },
    {
      id: 5,
      key: 'first_cousins',
      name: 'first_cousins',
      definition: 'parents_shares_common_grandparents_but_different_parents',
    },
    {
      id: 6,
      key: 'first_cousins_once_removed',
      name: 'first_cousins_once_removed',
      definition: 'parents_first_cousin_or_child_of_first_cousin',
    },
    {
      id: 7,
      key: 'second_cousins',
      name: 'second_cousins',
      definition:
        'parents_shares_common_great-grandparents_but_different_parents_and_grandparents',
    },
    {
      id: 8,
      key: 'farther_than_second_cousins',
      name: 'farther_than_second_cousins',
      definition: 'very_distant_relative',
    },
    {
      id: 9,
      key: 'grandfather-granddaughter',
      name: 'grandfather_slash_granddaughter',
      definition:
        'male_parent_having_a_sexual_relationship_with_one_of_his_female_grandchild',
    },
    {
      id: 10,
      key: 'grandmother-grandson',
      name: 'grandmother_slash_grandson',
      definition:
        'female_parent_having_a_sexual_relationship_with_one_of_her_male_grandchild',
    },
    {
      id: 11,
      key: 'uncle-niece',
      name: 'uncle_slash_niece',
      definition:
        'male_parent_having_a_sexual_relationship_with_one_of_his_siblings_female_offspring',
    },
    {
      id: 12,
      key: 'aunt-nephew',
      name: 'aunt_slash_nephew',
      definition:
        'female_parent_having_a_sexual_relationship_with_one_of_her_siblings_male_offspring',
    },
    {
      id: 13,
      key: 'other_not_sure',
      name: 'other_slash_not_sure',
      definition: 'just_some_relative',
    },
  ]

  return relationshipTypes
}

const mapDataToMemberProfile = (data) => {
  // Function to map API data to a "MemberProfile" instance.

  const memberProfile = new MemberProfile()

  // Fail safe condition
  if (isEmptyObject(data)) {
    return memberProfile
  }

  // memberProfile.accountID = data.account_id
  memberProfile.memberID = data.member_info.member_id
  memberProfile.inviteStatus = data.invite_status

  // <Personal Info>
  const { profileData } = memberProfile

  // Verify if date of birth is not null.
  let dob = null
  if (data.member_info.dob && data.member_info.dob !== '') {
    // Option 1: DOB with timezone offset
    const timezoneOffset = getTimezoneOffset(new Date(data.member_info.dob))
    const newDob = moment(
      new Date(data.member_info.dob),
      defaultDateFormatAPI
    ).add(parseInt(timezoneOffset, 10), 'hours')

    // Option 2: DOB without timezone offset
    // let newDob = moment(
    //   new Date(data.member_info.dob),
    //   defaultDateFormatAPI,
    // )

    dob = newDob.format(defaultDateFormat)
  }

  // If date of birth is not null, calculate the correct age.
  // Or else, just get the "age" from the API.
  let { age } = data.member_info
  if (dob) {
    age = yearIntervalFromNow(dob)
  }

  // If deceased, get "age" and assign to ageOfDeath
  profileData.deceased = data.member_info.viral_status
  if (profileData.deceased === true) {
    profileData.ageOfDeath = data.member_info?.age || ''
    profileData.causeOfDeath = data.member_info?.cause_of_death || ''
  }

  profileData.dob = dob
  profileData.currentAge = age || ''
  profileData.currentAgeString = data?.member_info.age_string ?? ''
  profileData.firstName = data.member_info.first_name
  profileData.lastName = data.member_info.last_name
  profileData.email = data.member_info.email
  profileData.genderIdentity = data.member_info.gender_identity
  profileData.pronoun = data.member_info.pronoun
  profileData.ethnicity = data.member_info.ethnicity
  profileData.ashkenazi = data.member_info.ashkenazi
  profileData.ancestry = data.member_info.ancestry

  let biologicalGender = ''

  if (data.member_info.gender && data.member_info.gender !== '') {
    biologicalGender = data.member_info.gender
  }

  profileData.biologicalGender = biologicalGender.toLowerCase()
  // </Personal Info>

  // <Diseases and Genetic Testings>
  const { healthHistoryData } = memberProfile
  const diagnosedWithDisease = data.member_disease.length > 0
  const geneTested = data.member_genetic_testing.length > 0

  healthHistoryData.diseasesData.diagnosedWithDisease = diagnosedWithDisease
  healthHistoryData.geneTestsData.geneTested = geneTested

  healthHistoryData.diseasesData.diseases = data.member_disease
  healthHistoryData.geneTestsData.testings = data.member_genetic_testing

  memberProfile.healthHistoryData = healthHistoryData
  // </Diseases and Genetic Testings>

  // <Relationship Info>
  const { relationshipData } = memberProfile

  // <Father>
  if (!isEmptyObject(data.parents.father)) {
    let father = null
    if (data.parents.father.member_id) {
      let memberName = data.parents.father.member_name
      memberName = memberName.trim() !== '' ? memberName : 'unnamed'

      father = {
        id: data.parents.father.member_id,
        name: memberName,
        isEditable: data.parents.father.is_editable,
        gender: 'M',
      }
    }
    relationshipData.father = father
  }

  // <Mother>
  if (!isEmptyObject(data.parents.mother)) {
    let mother = null
    if (data.parents.mother.member_id) {
      let memberName = data.parents.mother.member_name
      memberName = memberName.trim() !== '' ? memberName : 'unnamed'

      mother = {
        id: data.parents.mother.member_id,
        name: memberName,
        isEditable: data.parents.mother.is_editable,
        gender: 'F',
      }
    }
    relationshipData.mother = mother
  }

  // <Are the parents of this person blood related?>
  if (!isEmptyObject(data.blood_related_parents)) {
    const obj = data.blood_related_parents
    const isEnabled = obj.is_enable
    let relationshipInfo = null
    let bloodRelationType = null

    if (obj.blood_relation_type && obj.blood_relation_type !== '') {
      bloodRelationType = obj.blood_relation_type
      relationshipInfo = {
        key: bloodRelationType,
        name: null,
        definition: null,
      }
    }

    relationshipData.parentSpecific.bloodRelatedParents = isEnabled
    relationshipData.parentSpecific.relationshipInfo = relationshipInfo
  }

  // <Is this person adopted?>
  if (!isEmptyObject(data.adopted) && data.adopted !== 'disabled') {
    const adoptionDetails = data.adopted
    relationshipData.isAdopted = true
    relationshipData.childSpecific.isAdoptedOut = adoptionDetails.adopted_out
    relationshipData.childSpecific.isChildOfRelative =
      adoptionDetails.is_adopted_in_blood_related
  } else {
    relationshipData.isAdopted = false
    relationshipData.childSpecific.isAdoptedOut = false
    relationshipData.childSpecific.isChildOfRelative = false
  }

  // <Is this person a twin?>
  if (!isEmptyObject(data.twin) && data.twin !== 'disabled') {
    const twinGroup = {
      twinGroupID: data.twin.twin_id,
      twinMembers: data.twin.twin_members,
    }
    relationshipData.twinGroup = twinGroup
    relationshipData.isTwin = true
  } else {
    relationshipData.twinGroup = null
    relationshipData.isTwin = false
  }

  // </Relationship Info>

  return memberProfile
}

const mapDataToFamily = (data) => {
  // Function to map API data to a "Family" instance.
  const family = new Family()

  // Fail safe condition
  if (isEmptyObject(data)) {
    return family
  }

  family.proband = data.proband

  family.grandparents = data.grandparents
  family.parents = data.parents

  family.siblings = data.siblings

  family.children = data.children
  family.partners = data.partners

  family.paternalPibling = data.paternal_pibling
  family.maternalPibling = data.maternal_pibling

  return family
}

const getFormattedBirthdate = (val) => {
  const rawBirthdate = val
  const re = /\s\/\s/g
  const formattedBirthdate = rawBirthdate.replace(re, '/')
  return formattedBirthdate
}

const getTimezoneOffset = (value) => {
  const date = new Date(value)

  const offset = date.getTimezoneOffset()

  return offset ? offset / 60 : 0
}

function fixedTimeZoneOffset() {
  const timezoneOffset = parseInt(getTimezoneOffset(new Date()), 10)

  if (timezoneOffset > 0) {
    return -Math.abs(timezoneOffset)
  }

  return Math.abs(timezoneOffset)
}

function calculateAge(dateOfBirth) {
  const now = new Date()
  const birthDate = new Date(
    dateOfBirth.getFullYear(),
    dateOfBirth.getMonth(),
    dateOfBirth.getDate()
  )
  const diff = now.getTime() - birthDate.getTime()
  const diffSeconds = diff / 1000
  const diffMinutes = diffSeconds / 60
  const diffHours = diffMinutes / 60
  const diffDays = diffHours / 24
  const diffWeeks = diffDays / 7

  const birthYear = birthDate.getFullYear()
  const currentYear = now.getFullYear()
  const diffYears =
    currentYear -
    birthYear -
    (now.getMonth() < birthDate.getMonth() ||
      (now.getMonth() === birthDate.getMonth() &&
        now.getDate() < birthDate.getDate()))

  const monthsElapsed =
    (now.getFullYear() - birthDate.getFullYear()) * 12 +
    (now.getMonth() - birthDate.getMonth())
  const currentMonthDays = now.getDate()
  const birthMonthDays = birthDate.getDate()
  const diffMonths = monthsElapsed - (currentMonthDays < birthMonthDays ? 1 : 0)

  switch (true) {
    case diffYears > 1:
      return `${diffYears.toFixed()} years`
    case diffMonths >= 1:
      return `${diffMonths.toFixed()} months`
    case diffWeeks >= 1:
      return `${diffWeeks.toFixed()} weeks`
    default:
      return `${diffDays.toFixed()} days`
  }
}

function handleDisplayShorterAgeUnits(unit) {
  switch (unit) {
    case 'months':
      return 'm'
    case 'days':
      return 'd'
    case 'weeks':
      return 'w'
    default:
      return ''
  }
}

function yearIntervalFromNow(date, isBirthday = false, useUnits = false) {
  const formatedDate = moment(date, 'MM-DD-YYYY')

  const targetDate = moment(moment(formatedDate).format(defaultDateFormatAPI))

  if (isBirthday && useUnits) {
    const calculatedAgeWithUnit = calculateAge(new Date(targetDate))

    return calculatedAgeWithUnit
  }

  if (isBirthday) {
    return moment().diff(targetDate, 'years')
  }

  const timezoneOffset = fixedTimeZoneOffset()

  const now = moment(moment().format(defaultDateFormatAPI)).add(
    timezoneOffset,
    'hours'
  )
  const targetDateForSingupScreen = moment(
    moment(date).format(defaultDateFormatAPI)
  )
  const intervalYears = now.diff(targetDateForSingupScreen, 'years')

  if (!intervalYears) return 0

  return intervalYears
}

const calculateAgeFromDob = (dob, currentAge) => {
  let newAge = 0

  const isDobEmpty = !dob || dob === ''
  const isNewAgeEmpty = currentAge === ''

  if (isDobEmpty) {
    newAge = isNewAgeEmpty ? 0 : parseInt(currentAge, 10)

    return newAge
  }

  const formattedBirthdate = getFormattedBirthdate(dob)
  const newDob = moment(new Date(formattedBirthdate)).format(defaultDateFormat)

  newAge = yearIntervalFromNow(newDob)

  return newAge
}

const formatDisplayedMemberName = (name, age, is_dead) => {
  // Get Member name, age, and vital status.
  let titleText = name
  if (age && age !== '' && age !== '0') {
    if (name && name !== '') {
      titleText += ', '
    }
    if (is_dead) {
      titleText += `d. ${age}`
    } else {
      titleText += age
    }
  }
  return titleText
}

function getRelationshipGender(relationship, gender) {
  if (!relationship || relationship === '') return null

  let new_relationship = relationship
  let tempRelationship = `${relationship}`.toLowerCase().replaceAll('_', ' ')

  let greatCounter = 0

  if (tempRelationship.includes('great')) {
    while (tempRelationship.includes('great')) {
      tempRelationship = tempRelationship.replace('great', '')
      greatCounter += 1
    }
    new_relationship = tempRelationship.trim().replaceAll(' ', '_')
  }

  if (new_relationship === 'proband') new_relationship = 'you'

  switch (new_relationship) {
    case 'child':
    case 'son':
    case 'daughter':
      if (gender === 'm') {
        new_relationship = 'son'
      } else if (gender === 'f') {
        new_relationship = 'daughter'
      } else {
        new_relationship = 'child'
      }
      break

    case 'sibling':
    case 'brother':
    case 'sister':
      if (gender === 'm') {
        new_relationship = 'brother'
      } else if (gender === 'f') {
        new_relationship = 'sister'
      } else {
        new_relationship = 'sibling'
      }
      break
    case 'halfsibling':
    case 'halfbrother':
    case 'halfsister':
    case 'half_sibling':
    case 'half_brother':
    case 'half_sister':
      if (gender === 'm') {
        new_relationship = 'half_brother'
      } else if (gender === 'f') {
        new_relationship = 'half_sister'
      } else {
        new_relationship = 'half_sibling'
      }
      break
    case 'half_niece':
    case 'half_nephew':
    case 'half_niece_nephew':
      if (gender === 'm') {
        new_relationship = 'half_nephew'
      } else if (gender === 'f') {
        new_relationship = 'half_niece'
      } else {
        new_relationship = 'half_niece_nephew'
      }
      break
    case 'half_aunt':
    case 'half_uncle':
    case 'half_aunt_uncle':
      if (gender === 'm') {
        new_relationship = 'half_uncle'
      } else if (gender === 'f') {
        new_relationship = 'half_aunt'
      } else {
        new_relationship = 'half_aunt_uncle'
      }
      break
    case 'nibling':
    case 'nephew_niece':
    case 'niece_nephew':
    case 'nephew':
    case 'niece':
      if (gender === 'm') {
        new_relationship = 'nephew'
      } else if (gender === 'f') {
        new_relationship = 'niece'
      } else {
        new_relationship = 'nephew_niece'
      }
      break

    case 'parent':
    case 'father':
    case 'mother':
      if (gender === 'm') {
        new_relationship = 'father'
      } else if (gender === 'f') {
        new_relationship = 'mother'
      } else {
        new_relationship = 'parent'
      }
      break

    case 'pibling':
    case 'uncle_aunt':
    case 'aunt_uncle':
    case 'uncle':
    case 'aunt':
      if (gender === 'm') {
        new_relationship = 'uncle'
      } else if (gender === 'f') {
        new_relationship = 'aunt'
      } else {
        new_relationship = 'aunt_uncle'
      }
      break
    case 'grandparent':
    case 'grandfather':
    case 'grandmother':
    case 'grand_parent':
    case 'grand_father':
    case 'grand_mother':
      if (gender === 'm') {
        new_relationship = 'grandfather'
      } else if (gender === 'f') {
        new_relationship = 'grandmother'
      } else {
        new_relationship = 'grandparent'
      }
      break

    case 'grandchild':
    case 'grandson':
    case 'granddaughter':
    case 'grand_child':
    case 'grand_son':
    case 'grand_daughter':
      if (gender === 'm') {
        new_relationship = 'grandson'
      } else if (gender === 'f') {
        new_relationship = 'granddaughter'
      } else {
        new_relationship = 'grandchild'
      }
      break

    case 'grandnephew':
    case 'grandniece':
    case 'grand nephew':
    case 'grand niece':
    case 'grand niece_nephew':
    case 'grand nephew_niece':
    case 'grand_nephew':
    case 'grand_niece':
    case 'grand_niece_nephew':
    case 'grand_nephew_niece':
      if (gender === 'm') {
        new_relationship = 'grand_nephew'
      } else if (gender === 'f') {
        new_relationship = 'grand_niece'
      } else {
        new_relationship = 'grand_nephew_niece'
      }
      break
    case 'cousin':
      break
  }

  return {
    greatCounter,
    relationshipGender: new_relationship,
  }
}

function getFamilySideAndRelationshipGender(
  relationshipToProband,
  gender = null,
  separator = ' '
) {
  if (!relationshipToProband) return ''

  let relationship = []
  let familySide = null

  relationship = relationshipToProband.split(separator)

  switch (true) {
    case relationship.includes('maternal'):
      relationship.splice(0, 1)
      familySide = 'moms_side'
      break
    case relationship.includes('paternal'):
      relationship.splice(0, 1)
      familySide = 'dads_side'
      break
  }

  /* Test: Remove 'Great' in relationships */
  /* 
  if (relationship.includes("great")) {
    let greatRelationship = [];
    relationship.forEach((e) => {
      if (e != "great") { greatRelationship.push(e) }
    });
    relationship = greatRelationship;
  }
   */

  /* Test: Remove 'st, nd, rd, th' in 'Cousin' relationships */
  /* 
  if (relationship.includes("cousin")) {
    let greatRelationship = [];
    relationship.forEach((e) => {
      // let re = /stndrdth/g;
      let deSuffixed = e;
      deSuffixed = deSuffixed.replace("st", "")
      deSuffixed = deSuffixed.replace("nd", "")
      deSuffixed = deSuffixed.replace("rd", "")
      deSuffixed = deSuffixed.replace("th", "")
      greatRelationship.push(deSuffixed);
    });
    relationship = greatRelationship;
  }
   */

  // Join relationship array
  relationship = relationship.join(' ')

  // Get Relationship Gender and 'Great' counter
  const { greatCounter, relationshipGender } = getRelationshipGender(
    relationship,
    gender
  )

  relationship = `${relationshipGender}`.toLowerCase()

  // Append Family side if available.
  const values = [relationship, familySide, greatCounter]

  return values
}

const getFamilySideAndRelationship = (
  relationshipToProband,
  separator = ' '
) => {
  if (!relationshipToProband) {
    return ''
  }

  let relationship = []
  let familySide = null

  relationship = relationshipToProband.split(separator)

  switch (true) {
    case relationship.includes('maternal'):
      relationship.splice(0, 1)
      familySide = 'moms_side'
      break
    case relationship.includes('paternal'):
      relationship.splice(0, 1)
      familySide = 'dads_side'
      break
  }

  relationship = capitalizeFirstLetter(relationship.join(' '), true, ' ')

  const values = [relationship]
  if (familySide) {
    values.push(familySide)
  }

  return values
}

const getGenderIcon = (gender, isDeceased = false) => {
  let genderIcon = null

  if (gender && gender !== '') {
    switch (gender.toLowerCase()) {
      case 'm':
        if (isDeceased) {
          genderIcon = require('$assets/images/new_assets/gender-male-deceased.png')
        } else {
          genderIcon = require('$assets/images/new_assets/gender-male.png')
        }
        break
      case 'f':
        if (isDeceased) {
          genderIcon = require('$assets/images/new_assets/gender-female-deceased.png')
        } else {
          genderIcon = require('$assets/images/new_assets/gender-female.png')
        }
        break
    }
  }

  return genderIcon
}

const getGenderIconBorderRadius = (gender, isDeceased = false) => {
  const style = {
    backgroundColor: 'white',
    width: 42,
    height: 42,
    resizeMode: 'contain',
  }

  if (isDeceased) {
    style.opacity = 0.3
  }

  if (gender && gender !== '') {
    if (gender.toLowerCase() === 'f') {
      style.borderRadius = style.width / 2
    }
  } else {
    style.backgroundColor = 'rgba(204, 204, 204, 0.3)'
    style.borderRadius = 6
  }

  return style
}

function isEmptyObject(obj) {
  /* Use to check if a Dictionary is empty. */
  if (obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false
      }
    }
  }
  return true
}

const formatDateTimeCreated = (datetime) => {
  const today = new Date()
  const newDateTime = new Date(datetime)

  const dateToday = moment(today).format('ll')
  const newDate = moment(newDateTime).format('ll')

  let formattedDateTime = null

  if (dateToday === newDate) {
    formattedDateTime = moment(newDateTime).format('LT')
  } else {
    formattedDateTime = moment(newDateTime).format('lll')
  }

  return formattedDateTime
}

const capitalizeFirstLetter = (string, multiple = false, separator = ' ') => {
  if (!string) {
    return ''
  }
  if (multiple) {
    const newStringArr = []
    const splitString = string.split(separator)
    for (const i in splitString) {
      const word = splitString[i]
      newStringArr.push(word.charAt(0).toUpperCase() + word.slice(1))
    }
    return newStringArr.join(separator)
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

function fixSnakeCaseText(text, separator = ' ') {
  const splitText = text.split('_')
  return splitText.join(separator)
}

function convertTextToSnakeCase(text) {
  return text
    .replace(/\s/g, '_')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('')
}

function removePunctuationMarksFromString(text, removeSpaces = true) {
  let regex = /[&\/\\#,+()$~%.'":*?<>{}]/g
  if (removeSpaces) {
    regex = /\W+/g
  }
  return text.replace(regex, '')
}

const adjustFontSizeByLength = (string) => {
  const n = parseInt(string.length)
  let res = ''

  switch (true) {
    case n < 45:
      res = 18
      break
    case n < 53:
      res = 14
      break
    default:
      res = 12
  }

  return res
}

/**
 * Note: To be deprecated
 * Use appendParamsToObject();
 */
function _appendParamsToObject_(params = {}, obj) {
  if (!obj) {
    return
  }
  const keys = Object.keys(params)
  for (const i in keys) {
    const _key = keys[i]
    obj[_key] = params[_key]
  }
  return obj
}

function appendParamsToObject(params = {}, obj) {
  /** This function creates a deep copy of an object 
  and then tries to append the new parameters. */
  if (!obj) return
  const objCopy = JSON.parse(JSON.stringify(obj))
  return { ...objCopy, ...params }
}

function emailToLowerCase(email = '') {
  // remove spaces
  const removedSpaces = email.replace(/\s/g, '')
  // change to lower case
  const changetoLowerCase = removedSpaces.toLowerCase()

  return changetoLowerCase
}

const nonAppFilterNotif = (item) => {
  const lastIndex = 'non-app_user'.length
  const isAppUser = item.indexOf('non-app_user')
  let newString = ''

  if (isAppUser <= -1) {
    return item
  }

  if (
    item.charAt(isAppUser - 1).includes('_') &&
    item.charAt(isAppUser + lastIndex).includes('_')
  ) {
    newString = item.replace('_non-app_user_', '_')
  } else if (item.charAt(isAppUser - 1).includes('_')) {
    newString = item.replace('non-app_user_', '')
  } else if (item.charAt(isAppUser + lastIndex).includes('_')) {
    newString = item.replace('_non-app_user', '')
  } else {
    newString = item.replace('non-app_user', '')
  }

  return newString
}

function hasInputMask(cca2) {
  const region = cca2.toLowerCase()
  if (region === 'us' || region === 'ca') {
    return true
  }
  return false
}

function fixDiseaseName(diseaseName = '', isUmlsDisease = false) {
  if (!diseaseName) return ''
  if (isUmlsDisease) return diseaseName

  const text = diseaseName
    .toLowerCase()
    .replace(/\//g, ' ')
    .replace(/[^\w-\s]/gi, '')
    .replace(/\s/g, '_')

  return text
}

function wordReplacements(item) {
  // Define rules for word replacements

  const replacements = {
    '1st': '1st',
    '2nd': '2nd',
    '3rd': '3rd',
    cousin: 'cousin',
    removed: 'removed',
    '1x': 'once',
    '2x': 'twice',
  }

  // Iterate through the words and apply replacements
  const translatedWords = item.map(
    (word) => i18n.t(replacements[word])?.default || word
  )

  // Join the words back into a string
  const translatedString = translatedWords.join(' ')

  return translatedString
}

function translateLongRelationshipName(input) {
  // Check if the input has no underscores
  if (!input.includes('_')) {
    return input
  }

  const modifiedInput = input

  // Split the modified string into an array of words
  const words = modifiedInput.split('_')

  return wordReplacements(words)
}

export {
  defaultDateFormatAPI,
  defaultDateFormat,
  getParentBloodRelationTypes,
  mapDataToMemberProfile,
  mapDataToFamily,
  getRelationshipGender,
  getFamilySideAndRelationship,
  getFamilySideAndRelationshipGender,
  getGenderIcon,
  getGenderIconBorderRadius,
  isEmptyObject,
  yearIntervalFromNow,
  getFormattedBirthdate,
  capitalizeFirstLetter,
  formatDateTimeCreated,
  fixSnakeCaseText,
  convertTextToSnakeCase,
  removePunctuationMarksFromString,
  adjustFontSizeByLength,
  getTimezoneOffset,
  fixedTimeZoneOffset,
  calculateAgeFromDob,
  formatDisplayedMemberName,
  appendParamsToObject,
  emailToLowerCase,
  nonAppFilterNotif,
  hasInputMask,
  fixDiseaseName,
  handleDisplayShorterAgeUnits,
  translateLongRelationshipName,
  wordReplacements,
}
