import { TouchableOpacity, View, ViewPropTypes } from 'react-native'
import styles from './FamilyCardButton.style'

export default function FamilyCardButton({
  icon,
  customStyles,
  addToMemberButtonAction,
}) {
  return (
    <TouchableOpacity
      onPress={addToMemberButtonAction}
      style={[customStyles.itemAccessoryButton, styles.iconButton]}
    >
      <View style={styles.iconContainer}>{icon}</View>
    </TouchableOpacity>
  )
}

// FamilyCardButton.propTypes = {
//   icon: PropTypes.elementType,
//   customStyles: ViewPropTypes.style,
//   addToMemberButtonAction: PropTypes.func,
// }

FamilyCardButton.defaultProps = {
  icon: <></>,
  customStyles: {},
  addToMemberButtonAction: () => {},
}
