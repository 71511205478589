import ButtonDoneView from './ButtonDone.view'

export const ButtonDoneLogic = ({
  colorSettings,
  title,
  onPress,
  disable = false,
}) => (
  <ButtonDoneView
    colorSettings={colorSettings}
    title={title}
    onPress={onPress}
    disable={disable}
  />
)
