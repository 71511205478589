import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { HeaderBackButton } from '@react-navigation/elements'
import { i18n } from '$localization/config.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'
// components
import {
  showDefaultAlert,
  resetStackAction,
} from '$navigation/_utils/NavigationUtils.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import { DefaultFullHeight } from '$constants/styles/global.styles.js'
import { WEB } from '$constants/Platforms.js'
// COMPONENTS
import GeneTestUI from './GeneTestUI'
import { checkForMoreButtons } from './utils/addingMoreAndUnsureButtonMethod'
// redux
import { setError } from '$redux/defaults/actions.js'
import { getLang } from '$utils/lang_utils'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'
import saveLastScreen from '../../_utils/SaveLastScreen'
import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.PersonalGeneticTesting?.name
const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

let hasGenderIdentity = false
let isLastScreenBeforeLogin = false

export default function PositiveGeneTestOnboarding({ navigation }) {
  const geneTestType = 'p'
  const loadMainQuestion = true
  const dispatch = useDispatch()
  const saveError = (data) => dispatch(setError(data))

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const geneTestFromOnboarding = useSelector(
    (state) => state.geneTestingStore.fromOnboarding
  )
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const selectedClinician = useSelector(
    (state) => state.clinicianStore.selectedClinician
  )
  const probandProfile = useSelector(
    (state) => state.probandStore.probandProfile
  )
  const account = useSelector((state) => state.accountStore.account)
  const lastScreenLogin = useSelector((state) => state.store.lastScreenLogin)
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  const [startCount, setStartCount] = useState(0)
  const [mainQuestionValue, setMainQuestionValue] = useState(null)
  const [loadSubQuestion, setLoadSubQuestion] = useState(false)
  const [subQuestionValue, setSubQuestionValue] = useState(null)
  const [loadGeneList, setLoadGeneList] = useState(false)
  const [listValues, setListValues] = useState([])
  const [disableDoneButton, setDisableDoneButton] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())
  const [selectedGeneTest, setSelectedGeneTest] = useState([])
  const [onLoadSelectedGenes, setOnLoadSelectedGenes] = useState([])
  const [UMLSGenesList, setUMLSGenesList] = useState([])

  const screenResizeHandler = () => {
    const height = DefaultFullHeight()
    setScrollViewHeight(height)
  }

  const loadGeneTest = async () => {
    const getGeneTestingListPayload = {
      path: `genetic_testing/?lang=${getLang().toString()}`,
      method: 'get',
      pageDetails: {
        page: 'PositiveGeneTestOnboarding.js',
      },
    }

    const response = await ApiFetchHandler(getGeneTestingListPayload)

    if (response?.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    setListValues(response)
  }

  const loadUMLSGenes = async (gene, moreButton) => {
    const isMoreButtonSelected = moreButton === 'more'
    const startedCount = isMoreButtonSelected ? startCount + 10 : 0

    const getUmlsGenesPayload = {
      path: `umls/gene/?term=${gene}&start=${startedCount}`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'PositiveGeneTestOnboarding.js',
      },
    }

    const response = await ApiFetchHandler(getUmlsGenesPayload)

    if (response?.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const UMLSGenes = response.ConceptList

    if (UMLSGenes.length > 0) {
      const result = checkForMoreButtons(UMLSGenes)
    }

    const filteredGenes = UMLSGenes.map((item) => {
      const isSelectedGenes = selectedGeneTest.some(
        (selectedGene) => selectedGene.CUI === item.CUI
      )
      return { ...item, isSelected: isSelectedGenes }
    })

    setUMLSGenesList(
      isMoreButtonSelected
        ? (prevState) => [...prevState.slice(0, -2), ...filteredGenes]
        : filteredGenes
    )

    setStartCount(startedCount)
  }

  const handleMainQuestion = (value) => {
    setSubQuestionValue(null)
    setLoadGeneList(false)
    setSelectedGeneTest([])
    switch (value) {
      case true:
        setMainQuestionValue(value)
        setLoadSubQuestion(true)
        setDisableDoneButton(true)

        break
      case false:
        setMainQuestionValue(value)
        setLoadSubQuestion(false)
        setDisableDoneButton(false)
        break
      default:
        setDisableDoneButton(true)
    }
  }

  const handleSubQuestion = (value) => {
    setDisableDoneButton(false)
    setSelectedGeneTest([])
    switch (value) {
      case true:
        setSubQuestionValue(value)
        setLoadGeneList(true)
        break
      case false:
        setSubQuestionValue(value)
        setLoadGeneList(false)
        break
      default:
        setDisableDoneButton(true)
    }
  }

  const handleDeleteSelectedGene = (itemSelected) => {
    const filteredSelectedGeneTest = selectedGeneTest.filter(
      (gene) => gene.CUI !== itemSelected.CUI
    )
    const selectedGenesList = UMLSGenesList.map((item) => ({
      ...item,
      isSelected: item.CUI === itemSelected.CUI ? false : item.isSelected,
    }))
    setUMLSGenesList(selectedGenesList)
    setSelectedGeneTest(filteredSelectedGeneTest)
  }

  const handleSelectedGeneTest = (itemSelected) => {
    const isGeneSelected = itemSelected.isSelected
    const selectedGenes = UMLSGenesList.map((item) => ({
      ...item,
      isSelected:
        item.CUI === itemSelected.CUI ? !isGeneSelected : item.isSelected,
    }))
    setUMLSGenesList(selectedGenes)
    setSelectedGeneTest((prevState) => [...prevState, itemSelected])
    isGeneSelected && handleDeleteSelectedGene(itemSelected)
  }

  const loadMemberGeneTests = async () => {
    setIsLoading(true)

    const getOnboardingDetailsPayload = {
      path: `onboarding/${account.accountID}/get_info/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'PositiveGeneTestOnboarding.js',
      },
    }

    const onboardingMemberDetails = await ApiFetchHandler(
      getOnboardingDetailsPayload
    )

    if (onboardingMemberDetails?.isError) {
      saveError({
        isShown: true,
        status: onboardingMemberDetails.status,
        message: onboardingMemberDetails.error,
      })
      return
    }

    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        proband_id: probandProfile?.memberID,
        member_id: probandProfile?.memberID,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: 'PositiveGeneTestOnboarding.js',
      },
    }

    const memberGeneTest = await ApiFetchHandler(getMemberProfilePayload)

    if (memberGeneTest?.isError) {
      saveError({
        isShown: true,
        status: memberGeneTest.status,
        message: memberGeneTest.error,
      })
      return
    }

    const filteredMemberGeneList = memberGeneTest.member_genetic_testing.filter(
      (geneTest) => geneTest.result === geneTestType
    )

    if (
      onboardingMemberDetails.genetic_testing_selected &&
      filteredMemberGeneList.length !== 0
    ) {
      handleMainQuestion(true)
      handleSubQuestion(null)
      const loadedGeneTest = []

      filteredMemberGeneList.forEach((item) => {
        const payload = {
          CUI: item.umls_id,
          Name: item.umls_name,
          member_gene_id: item.id,
        }
        loadedGeneTest.push(payload)
        handleSelectedGeneTest(payload)
      })
      setOnLoadSelectedGenes(loadedGeneTest)
    } else if (onboardingMemberDetails.genetic_testing_selected) {
      handleMainQuestion(true)
      handleSubQuestion(false)
    } else if (onboardingMemberDetails.genetic_testing_selected === false) {
      handleMainQuestion(false)
    }
  }

  const componentMountHandler = async () => {
    try {
      if (WEB === Platform.OS) {
        window.addEventListener('resize', screenResizeHandler)
      }

      if (probandProfile && probandProfile.profileData.genderIdentity) {
        hasGenderIdentity = true
      } else {
        hasGenderIdentity = false
      }

      isLastScreenBeforeLogin =
        lastScreenLogin === RAW_LAST_VISITED_SCREENS.gene_testing

      navigation.setParams({
        hasGenderIdentity,
        isLastScreenBeforeLogin,
      })

      setIsLoading(true)
      await loadGeneTest()
      await loadMemberGeneTests()
      setIsLoading(false)
    } catch (error) {
      console.log(
        '🚀 \n\n file: PositiveGeneTestOnboarding.js:304 \n\n componentMountHandler \n\n error:',
        error
      )
    }
  }

  useEffect(() => {
    componentMountHandler()
  }, [])

  const handleOnboardingSaving = async () => {
    const saveOnboardingDetailsPayload = {
      path: 'onboarding/update_info/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        account_id: account.accountID,
        genetic_testing_selected: mainQuestionValue,
        last_screen: RAW_LAST_VISITED_SCREENS.gene_testing,
      },
      pageDetails: {
        page: 'PositiveGeneTesting.js',
      },
    }

    await ApiFetchHandler(saveOnboardingDetailsPayload)
  }

  const handleToggleGeneTest = (getValue) => {
    const itemFilter = selectedGeneTest.find((gene) => gene.id === getValue.id)
    if (itemFilter) {
      return true
    }
    return false
  }

  const handleGeneTestSaving = async () => {
    const { race_ethnicity_display = 'show_ashkanazi_and_race' } =
      selectedClinician || 'show_ashkanazi_and_race'

    const isHideAshkanaziAndRaceSelected =
      race_ethnicity_display === 'hide_ashkanazi_and_race'

    const routeName = isHideAshkanaziAndRaceSelected
      ? CUSTOM_FLOW_ROUTES.PersonalAncestry.name
      : CUSTOM_FLOW_ROUTES.PersonalEthnicity.name

    const nextRoute = getNextWorkflowRoute({ defaultNextRoute: routeName })

    const savedGeneTestData = selectedGeneTest.filter(
      (testA) => !onLoadSelectedGenes.some((testB) => testA.CUI === testB.CUI)
    )
    const removedGeneTestData = onLoadSelectedGenes.filter(
      (testA) => !selectedGeneTest.some((testB) => testA.CUI === testB.CUI)
    )

    setIsLoading(true)

    await Promise.all(
      savedGeneTestData.map(async (item) => {
        const addGeneTestToMemberOnboardingPayload = {
          path: `member/${probandProfile.memberID}/add_genetic_testing/`,
          method: 'post',
          token: authCredentials.accessToken,
          body: {
            proband_id: probandProfile?.memberID,
            umls_id: item.CUI,
            umls_name: item.Name,
            result: geneTestType,
          },
          pageDetails: {
            page: 'PositiveGeneTestOnboarding.js',
          },
        }

        const response = await ApiFetchHandler(
          addGeneTestToMemberOnboardingPayload
        )

        if (response?.isError) {
          setIsLoading(false)
          return saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
        }
      })
    )
    // handle removing
    await Promise.all(
      removedGeneTestData.map(async (item) => {
        const removeMemberGeneTestingPayload = {
          path: `member/${probandProfile.memberID}/remove_genetic_testing/`,
          method: 'post',
          token: authCredentials.accessToken,
          body: {
            proband_id: probandProfile?.memberID,
            member_genetic_testing_id: item.member_gene_id,
          },
          pageDetails: {
            page: 'PositiveGeneTestOnboarding.js',
          },
        }

        const response = await ApiFetchHandler(removeMemberGeneTestingPayload)

        if (response?.isError) {
          setIsLoading(false)
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
        }
      })
    )
    const response = await handleOnboardingSaving()

    if (response?.isError) {
      setIsLoading(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gene_testing,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })

    setIsLoading(false)
  }

  const handleToolTip = () => {
    showDefaultAlert(
      i18n.t('test_positive').default,
      i18n.t(
        'testing_positive_means_that_a_change_or_mutation_was_found_in_the_gene_this_could_also_be_worded_as_pathogenic_likely_pathogenic_deleterious_or_likely_deleterious_on_a_report'
      ).default
    )
  }

  return (
    <GeneTestUI
      // PROPS
      navigation={navigation}
      buttonText="next"
      // REDUX
      colorSettings={colorSettings}
      geneTestFromOnboarding={geneTestFromOnboarding}
      // STATE
      setUMLSGenesList={setUMLSGenesList}
      UMLSGenesList={UMLSGenesList}
      loadMainQuestion={loadMainQuestion}
      mainQuestionValue={mainQuestionValue}
      loadSubQuestion={loadSubQuestion}
      subQuestionValue={subQuestionValue}
      loadGeneList={loadGeneList}
      listValues={listValues}
      disableDoneButton={disableDoneButton}
      isLoading={isLoading}
      scrollViewHeight={scrollViewHeight}
      selectedGeneTest={selectedGeneTest}
      // METHODS
      handleDeleteSelectedGene={handleDeleteSelectedGene}
      loadUMLSGenes={loadUMLSGenes}
      handleMainQuestion={handleMainQuestion}
      handleSubQuestion={handleSubQuestion}
      handleSelectedGeneTest={handleSelectedGeneTest}
      handleGeneTestSaving={handleGeneTestSaving}
      handleToggleGeneTest={handleToggleGeneTest}
      handleToolTip={handleToolTip}
    />
  )
}
