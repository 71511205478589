import { mapDataToMemberProfile } from '$api/_utils/Utils.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

const UpdateProbandProfile = async (memberData, authCredentials) => {
  const getMemberProfilePayload = {
    path: 'member/get/',
    method: 'post',
    token: authCredentials.accessToken,
    body: { ...memberData },
    pageDetails: {
      page: 'UpdateMemberProfile.js',
    },
  }

  const response = await ApiFetchHandler(getMemberProfilePayload)

  if (response.isError) return

  const mapMemberProfile = mapDataToMemberProfile(response)

  return mapMemberProfile
}

export default UpdateProbandProfile
