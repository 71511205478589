import { useCallback, useRef, useState } from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'

import ButtonSub from '$components/button_sub'
import LabelField from '../label-field/LabelField'
import styles from './SingleSelectField.style'

export default function SingleSelectField({ inputFieldData, colorSettings }) {
  const itemsRef = useRef([])
  const choiceListHandler = () => {
    const { input_choices } = inputFieldData

    const onPressHandler = useCallback((item, selectedItem, choiceIndex) => {
      input_choices.map((item, index) => {
        if (index !== choiceIndex) {
          itemsRef.current[index].setToggle()
        }
      })
    })

    return input_choices.map((itemChoice, choiceIndex) => (
      <ButtonSub
        key={uuid.v4()}
        ref={(el) => (itemsRef.current[choiceIndex] = el)}
        title={itemChoice.choice}
        colorSettings={colorSettings}
        onPress={onPressHandler}
        selectedItem={itemChoice}
        id={choiceIndex}
      />
    ))
  }
  return (
    <View style={styles.container}>
      <LabelField title={inputFieldData.label} colorSettings={colorSettings} />
      {choiceListHandler()}
    </View>
  )
}

// SingleSelectField.propTypes = {
//   inputFieldData: PropTypes.shape({
//     input_choices: PropTypes.arrayOf(
//       PropTypes.shape({
//         id: PropTypes.number,
//         choice: PropTypes.string,
//         order: PropTypes.number,
//       })
//     ),
//     label: PropTypes.string,
//   }),
// }

SingleSelectField.defaultProps = {
  inputFieldData: {},
}
