import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  title: {
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },
  buttonContainerStyle: {
    flexDirection: 'row',
  },
  buttonStyle: {
    flex: 1,
    marginHorizontal: 10,
  },
  roundedButtonTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'center',
  },
})
