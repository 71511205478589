import ApiFetchHandler from '../../../../api/ApiFetchHandler'

const getOnboardingInfo = async (accountData, authCredentials, saveError) => {
  try {
    const getOnboardingDetailsPayload = {
      path: `onboarding/${accountData.accountID}/get_info/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'Login.controller.js',
      },
    }

    const response = await ApiFetchHandler(getOnboardingDetailsPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    return response
  } catch (error) {
    console.log(
      '🚀 \n\n file: CustomValidateDOB.js:791 \n\n getOnboardingInfo \n\n error:',
      error
    )
  }
}

const clinicianCheck = async (familyId, authCredentials, saveError) => {
  if (!familyId) return false

  const familyClinicianPayload = {
    path: `family/${familyId}/clinicians/`,
    method: 'get',
    token: authCredentials.accessToken,
    pageDetails: {
      page: 'Login.controller.js',
    },
  }

  const response = await ApiFetchHandler(familyClinicianPayload)

  if (response.isError) {
    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
    return false
  }

  return response.length > 0
}

export { getOnboardingInfo, clinicianCheck }
