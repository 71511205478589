import { Component } from 'react'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
  Dimensions,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'
import { WebView } from 'react-native-webview'

import { getStatusBarHeight, ifIphoneX } from 'react-native-iphone-x-helper'
import { DOMAIN_CLIENT } from '$constants/globalVariables.js'

import LoadingView from '$constants/LoadingView.js'
import { IS_WEB, WEB, IS_MOBILE } from '$constants/Platforms.js'

import { i18n } from '$localization/config.js'

import global_styles from '$constants/styles/global.styles.js'
import MainWrapper from '$components/main_wrapper/MainWrapper.js'

import { getLang } from '$utils/lang_utils.js'
import { isEmptyObject } from '$api/_utils/Utils.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'
import HeaderBackImage from '$navigation/_components/HeaderBackImage.js'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

import { setError } from '$redux/defaults/actions.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { CUSTOM_SURVEY_URL } from '$constants/envVariables.js'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.SurveyCustom?.name
const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

const screenWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
  containerWeb: {
    flex: 1.0,
    backgroundColor: 'rgba(155,89,182,1.0)',
  },
  container: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: screenWidth / 4,
    right: 0,
    ...ifIphoneX(
      {
        height: 100, // For iPhoneX screens
      },
      {
        height: 100, // For regular iPhone screens
      }
    ),
    ...Platform.select({
      ios: {
        top: getStatusBarHeight(true),
      },
      android: {
        height: 95, // For android phone screens
      },
    }),
  },
  exitButton: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  nextButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  nextButtonContainer: {
    height: 60,
  },
  backButton: {
    position: 'absolute',
    top: 45,
    left: 50,
  },
})

const WEB_IFRAME_CONTAINER_ID = 'custom_survey_iframe_container'

class SurveyCustom extends Component {
  state = {
    showCloseButton: false,
    showLoadingView: true,
    selectedSurvey: this.props.selectedSurvey ?? {},
  }

  fontWeight = IS_WEB() ? 700 : 400

  selectedSurvey = this.props.selectedSurvey ?? {}

  site = this.selectedSurvey?.customURL ?? ''

  async componentDidMount() {
    const { selectedSurvey } = this.state
    const { isOnboarding, account, authCredentials, saveError } = this.props

    let surveyURL = selectedSurvey

    if (isOnboarding) {
      surveyURL = await this.setSurveyUrl()
    }

    if (IS_WEB()) {
      window.addEventListener('message', this.onMessage, true)

      const WebViewIframeOpen = require('$utils/WebViewIframeOpen.web.js')

      new WebViewIframeOpen().openInIframe(
        surveyURL.customURL ?? '',
        WEB_IFRAME_CONTAINER_ID
      )
    }

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.survey_custom,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
    })
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('message', this.onMessage, true)
    }
  }

  async getByClinicianSurveys(connected = false) {
    const { authCredentials, saveError, proband } = this.props

    const getProbandSurveyPayload = {
      path: 'family/surveys/',
      method: 'post',
      token: authCredentials?.accessToken,
      body: {
        domain_client: DOMAIN_CLIENT?.toUpperCase(),
      },
      pageDetails: {
        page: 'SurveyScreen.js',
      },
    }

    const response = await ApiFetchHandler(getProbandSurveyPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      const selectedClinician = response.find(
        (clinician) =>
          proband.clinicianCode === clinician.clinician_surveys.clinician_code
      )

      this.setState({ showLoadingView: false })
    }
  }

  setSurveyUrl = async () => {
    const { proband, authCredentials, saveError } = this.props
    if (!proband || isEmptyObject(proband)) return

    this.setState({ showLoadingView: true })

    const accessToken = authCredentials?.accessToken
    const probandID = proband?.probandID
    const selectScreen = this.selectCurrentScreen()

    let surveyItem = {}

    if (!proband?.clinicianCode) {
      const customURL = `${CUSTOM_SURVEY_URL}/${accessToken}/${
        selectScreen.surveyId
      }/${probandID}/${getLang()}`

      this.setState({
        showLoadingView: false,
      })

      surveyItem = {
        accessToken,
        clinician_code: proband.clinicianCode,
        customURL,
        surveyID: selectScreen.surveyId,
      }
    } else {
      const clinicianCheckPayload = {
        path: 'clinician/check/',
        method: 'post',
        token: accessToken,
        body: {
          clinician_code: proband?.clinicianCode,
        },
        pageDetails: {
          page: 'SurveyScreen.js',
        },
      }

      const response = await ApiFetchHandler(clinicianCheckPayload)

      if (response.isError) {
        this.setState({ showLoadingView: false })

        setTimeout(() => {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
        }, 100)
      }

      const clinicianCodeId = response?.clinician_code?.id

      const customURL = `${CUSTOM_SURVEY_URL}/${accessToken}/${
        selectScreen?.surveyId
      }/${probandID}/${clinicianCodeId}/${getLang()}`

      this.setState({
        showLoadingView: false,
      })

      surveyItem = {
        accessToken,
        clinician_code: proband.clinicianCode,
        customURL,
        surveyID: selectScreen.surveyId,
      }
    }

    return surveyItem
  }

  selectCurrentScreen = () => {
    const { selectedScreens, nextRouteIndex } = this.props
    const subtractIndex = 1

    const selectScreen = selectedScreens.find(
      (screen) => screen.screen_order === nextRouteIndex - subtractIndex
    )

    return selectScreen
  }

  dismissWebView = () => {
    const { navigation, isOnboarding } = this.props

    if (!isOnboarding) {
      navigation.navigate('SurveysTab', {
        colorSettings: this.props.colorSettings,
      })
      return
    }

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.SuccessScreenPersonalHealthInfo.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  onMessage = (e) => {
    const message = e?.data

    if (message === 'Exiting Survey...') {
      this.dismissWebView()
    }
  }

  webViewOnMessage = (e) => {
    const message = e?.nativeEvent?.data

    if (message === 'Exiting Survey...') {
      this.dismissWebView()
    }
  }

  showCloseButton = () => {
    const { colorSettings } = this.props

    return (
      <SafeAreaView style={styles.container}>
        <TouchableOpacity
          onPress={() => this.dismissWebView()}
          style={styles.exitButton}
          activeOpacity={0.3}
        >
          <Text
            style={{
              ...global_styles.headerRightButtonText,
              fontWeight: this.fontWeight,
              paddingRight: 20,
              color: colorSettings?.nav_title_dark,
              ...Platform.select({
                [WEB]: {
                  color: '#ffffff',
                  fontFamily: 'Karla',
                  paddingTop: 20,
                },
              }),
            }}
          >
            {i18n.t('exit')?.title}
          </Text>
        </TouchableOpacity>
      </SafeAreaView>
    )
  }

  showBackButton = () => {
    const { navigation } = this.props

    if (navigation.canGoBack) {
      return (
        <TouchableOpacity
          style={styles.backButton}
          onPress={() => navigation.goBack()}
        >
          <HeaderBackImage color="#ffffff" />
        </TouchableOpacity>
      )
    }
  }

  render() {
    const { navigation, isOnboarding } = this.props
    const colorSettings = this.props.colorSettings ?? {}

    /* For Web only */
    if (IS_WEB()) {
      const content = (
        <MainWrapper navigation={navigation}>
          <View
            style={[
              styles.containerWeb,
              {
                backgroundColor: colorSettings?.onboarding_bgcolor,
              },
            ]}
          >
            <View
              style={{
                flex: 1.0,
                height: '100%',
                width: '100%',
                maxWidth: '800px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              nativeID={WEB_IFRAME_CONTAINER_ID}
            >
              {isOnboarding && this.showBackButton()}
            </View>
          </View>
        </MainWrapper>
      )

      return content
    }

    /* For Mobile only */
    if (IS_MOBILE()) {
      const { selectedSurvey } = this.state

      return (
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor: colorSettings?.post_onboarding_bgcolor,
              },
            ]}
          >
            <WebView
              style={{ backgroundColor: '#fff' }}
              containerStyle={{ backgroundColor: '#fff' }}
              onMessage={(e) => this.webViewOnMessage(e)}
              onLoadEnd={() =>
                this.setState({
                  showCloseButton: false,
                  showLoadingView: false,
                })
              }
              onError={() =>
                this.setState({ showCloseButton: true, showLoadingView: false })
              }
              onHttpError={() =>
                this.setState({ showCloseButton: true, showLoadingView: false })
              }
              bounces={false}
              source={{ uri: selectedSurvey.customURL }}
            />

            {this.state.showLoadingView && (
              <LoadingView
                tintColor={colorSettings?.post_onboarding_nav_bgcolor}
                textColor={colorSettings?.post_onboarding_nav_bgcolor}
                visible={this.state.showLoadingView}
              />
            )}

            {this.state.showCloseButton && this.showCloseButton()}
          </SafeAreaView>
        </MainWrapper>
      )
    }

    return null
  }
}

function mapStateToProps(state) {
  const {
    store,
    accountStore,
    probandStore,
    clinicianStore,
    surveyStore,
    customFlowRoutesStore,
  } = state

  return {
    colorSettings: store.colorSettings,
    account: accountStore.account,
    authCredentials: accountStore.authCredentials,
    proband: probandStore.proband,
    clinicians: clinicianStore.clinicians,
    selectedSurvey: surveyStore.selectedSurvey,
    selectedScreens: customFlowRoutesStore.selectedScreens,
    nextRouteIndex: customFlowRoutesStore.nextRouteIndex,
    isOnboarding: store.isOnboarding,
  }
}

const mapDispatchToProps = {
  saveError: setError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCustom)
