import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import PhoneInput from 'react-native-phone-input'

// TODO: Update package, for now we are using the most recent compatible version
import CountryPicker from 'react-native-country-picker-modal'

const cca2List = require('./cca2.json')

const styles = StyleSheet.create({
  phoneInputText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'left',
    minWidth: 50,
  },
})

const getExcludedCountries = () => {
  const excludedCountries = cca2List || []
  const includedCountries = [
    'AE',
    'AR',
    'AU',
    'AT',
    'BE',
    'BH',
    'BR',
    'IE',
    'US',
    'CC',
    'CX',
    'CA',
    'CL',
    'CO',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LT',
    'LV',
    'MK',
    'MT',
    'MX',
    'NL',
    'NZ',
    'NO',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'GB',
    'VA',
    'TR',
    'IL',
    'JP',
    'HK',
    'ID',
    'FJ',
    'SG',
    'TW',
  ]

  for (const i in cca2List) {
    const cca2 = cca2List[i]
    if (includedCountries.includes(cca2)) {
      excludedCountries.splice(i, 1)
    }
  }

  return excludedCountries
}

function PhoneInputView({
  sender,
  initialCountryCode = 'us',
  countryPickerVisible = false,
  colorSettings,
  prefixTextColor = null,
}) {
  const { selectedLanguage } = useSelector((state) => state.store)

  const handleGetTranslationKey = () => {
    switch (selectedLanguage) {
      case 'fr':
        return 'fra'
      case 'nl':
        return 'nld'
      case 'de':
        return 'deu'
      default:
        return 'common'
    }
  }

  const excludedCountries = getExcludedCountries()
  const initialCountry = initialCountryCode.toLowerCase()
  const translationKey = handleGetTranslationKey()

  return (
    <View
      nativeID="web_scaled_main_container"
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <CountryPicker
        nativeID="country_picker_element"
        // theme={DARK_THEME}
        containerButtonStyle={{ height: 0, width: 0 }}
        placeholder=""
        ref={(ref) => {
          sender.countryPicker = ref
        }}
        excludeCountries={excludedCountries}
        onSelect={(value) => sender?.countryPickerOnSelect(value)}
        onClose={() => sender?.toggleCountryPicker(false)}
        translation={translationKey}
        filterable
        autoFocusFilter={false}
        closeable
        filterPlaceholder="Search country"
        showCallingCode
        animationType="slide"
        visible={countryPickerVisible}
        withFilter
        withCallingCode
      />

      <TouchableOpacity
        style={{
          flex: 1.0,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        activeOpacity={1.0}
        onPress={sender.onPressFlag}
      >
        <View nativeID="phone_flag_and_ccode_signup_process">
          <PhoneInput
            style={{ paddingLeft: 4 }}
            nativeID="text_input_web"
            ref={(ref) => {
              sender.phone = ref
            }}
            initialCountry={initialCountry}
            disabled
            onSelectCountry={sender.countryDidSelect}
            textStyle={[
              styles.phoneInputText,
              {
                color: prefixTextColor || colorSettings?.text_input_color_1,
              },
            ]}
            offset={0}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default PhoneInputView
