import { useEffect, useState } from 'react'
import ButtonToggleView from './ButtonToggle.view'
import { i18n } from '$localization/config.js'

export const ButtonToggleLogic = ({
  colorSettings,
  isSelected = null,
  currentState,
  title,
  onPress,
  setContainerStyle,
}) => {
  const [isToggled, setToggled] = useState(isSelected)

  useEffect(() => {
    if (currentState) currentState(isToggled)
  }, [isToggled])

  const toggleSwitcher = (val) => {
    setToggled(val)
    onPress(val)
  }

  const toggledOn = () => {
    if (isToggled === null) return false
    if (isToggled) return true
    return false
  }

  const ToggledOff = () => {
    if (isToggled === null) return false
    if (isToggled) return false
    return true
  }

  const textToKey = (introTitle) => {
    if (!introTitle) return ''
    const key = introTitle
      .toLowerCase()
      .split(' ')
      .join('_')
      .replace(/\//g, '_')
      .replace(/[^-0-9a-zA-Z _]/g, '')

    return i18n.t(key).default
  }

  return (
    <ButtonToggleView
      colorSettings={colorSettings}
      isToggledOn={toggledOn}
      isToggledOff={ToggledOff}
      toggleSwitcher={toggleSwitcher}
      title={title}
      setContainerStyle={setContainerStyle}
    />
  )
}
