import { memo, useEffect, useRef, useCallback } from 'react'
import { View, PanResponder, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'
import { LOGOUT_TIMER } from '$constants/globalVariables.js'
import {
  clearAuthCredentialsAction,
  clearAccountAction,
} from '$redux/account/actions.js'
import {
  clearProbandAction,
  clearProbandProfileAction,
  clearBasicFamilyMembers,
} from '$redux/proband/actions.js'
import {
  clearMemberProfileAction,
  clearCurrentMember,
} from '$redux/member_profile/actions.js'
import { clearRiskFactorsAction } from '$redux/risk_factor/actions.js'
import { clearCliniciansAction } from '$redux/clinician/actions.js'
import { clearSurveysAction } from '$redux/survey/actions.js'
import { resetDiseasesStore } from '$redux/diseases/actions.js'
import { resetGeneTestingStore } from '$redux/gene_testing/actions.js'
import { deleteData, saveData } from '$constants/SecureStore.js'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

const MainWrapper = memo(
  ({ children, navigation, disableLogout, screenActive }) => {
    const defaultLogoutTimer = 1800000
    const timer = useRef(false)
    const dispatch = useDispatch()
    const logoutTimer = parseInt(LOGOUT_TIMER || defaultLogoutTimer, 10)
    const resetStoredData = useCallback(() => {
      /** Clears some data from redux store */
      dispatch(clearAuthCredentialsAction())
      dispatch(clearAccountAction())
      dispatch(clearProbandAction())
      dispatch(clearProbandProfileAction())
      dispatch(clearMemberProfileAction())
      dispatch(clearCurrentMember())
      dispatch(clearRiskFactorsAction())
      dispatch(clearCliniciansAction())
      dispatch(clearSurveysAction())
      dispatch(clearBasicFamilyMembers())

      /* Reset Diseases and Genetic Testings data from redux store */
      dispatch(resetDiseasesStore())
      dispatch(resetGeneTestingStore())
    }, [dispatch])

    const logoutButtonAction = useCallback(async () => {
      await saveData({ key: 'isLogout', value: 'true' })
      await deleteData({ key: 'BIOMETRICS' })
      resetStoredData()
      setTimeout(() => {
        navigation.replace('AuthStack')
      }, 100)
    }, [navigation, resetStoredData])

    const resetInactivityTimeout = useCallback(() => {
      clearTimeout(timer.current)
      if (!disableLogout) {
        timer.current = setTimeout(() => {
          // action after user has been detected idle
          logoutButtonAction()
        }, logoutTimer)
      }
    }, [logoutButtonAction, logoutTimer, disableLogout])

    useEffect(() => {
      const willBlurSubscription = navigation.addListener('blur', () => {
        clearTimeout(timer.current)
      })

      const willFocusSubscription = navigation.addListener('focus', () => {
        resetInactivityTimeout()
      })

      return () => {
        willBlurSubscription()
        willFocusSubscription()
      }
    }, [navigation, resetInactivityTimeout])

    useEffect(() => {
      resetInactivityTimeout()

      return () => {
        clearTimeout(timer.current)
      }
    }, [resetInactivityTimeout, screenActive])

    const panResponder = useRef(
      PanResponder.create({
        // onStartShouldSetPanResponder: () => {
        //   resetInactivityTimeout()
        //   return true
        // },
        onStartShouldSetPanResponderCapture: () => {
          resetInactivityTimeout()
          return false
        },
      })
    ).current

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <View style={styles.container} {...panResponder.panHandlers}>
        {children}
      </View>
    )
  }
)

// MainWrapper.propTypes = {
//   disableLogout: PropTypes.bool,
//   navigation: PropTypes.func.isRequired,
//   children: PropTypes.element.isRequired,
//   screenActive: PropTypes.bool,
// }

MainWrapper.defaultProps = {
  disableLogout: false,
  screenActive: true,
}

export default MainWrapper
