import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import styles from '../main.styles'
import SimplerDatePicker from '../../../../components/web_date_picker/SimpleDatePicker'
import { i18n } from '$localization/config.js'

function WebDatePicker({ dateOfBirthday, onDatePickerChange }) {
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  return (
    <View style={styles.datePickerContainer}>
      <Text style={styles.birthdateTitle}>{i18n.t('birthdate')?.default}</Text>

      <SimplerDatePicker
        containerStyle={styles.simpleDatePickerContainer}
        yearStyle={styles.yearStyle}
        monthStyle={styles.monthStyle}
        dayStyle={styles.dayStyle}
        minDate={moment().subtract(185, 'years')}
        maxDate={moment().subtract(1, 'days')}
        onDatePicked={onDatePickerChange}
        getPromptString={(name) => i18n.t(name.toLowerCase())?.default}
        date={dateOfBirthday}
        language={selectedLanguage}
      />
    </View>
  )
}

export default WebDatePicker
