import {
  Platform,
  InputAccessoryView,
  View,
  TouchableOpacity,
  Text,
  Keyboard,
} from 'react-native'

import global_styles from '$constants/styles/global.styles.js'

export default function NextButtonAccessoryView({
  style: buttonStyle,
  backgroundColor: initialBackgroundColor,
  labelText,
  labelColor,
  disabled: buttonDisabled,
  nativeID,
  onPress: onPressProp,
}) {
  const onPress = () => {
    Keyboard.dismiss()
    onPressProp()
  }

  if (Platform.OS === 'ios') {
    return (
      <InputAccessoryView nativeID={nativeID}>
        <View style={{ backgroundColor: initialBackgroundColor || 'white' }}>
          <View style={{ height: 60, width: '100%' }}>
            <View>
              <TouchableOpacity
                disabled={buttonDisabled}
                style={buttonStyle}
                onPress={onPress}
              >
                <Text
                  style={[
                    global_styles.nextButtonText,
                    {
                      marginBottom: 0,
                      color: labelColor,
                    },
                  ]}
                >
                  {labelText || 'Next'}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </InputAccessoryView>
    )
  }
  return null
}
