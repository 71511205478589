import { createRef, Component } from 'react'
import { connect } from 'react-redux'
import {
  Platform,
  SafeAreaView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import {
  clearProbandDisease,
  clearProbandSkipLogic,
} from 'redux/proband/actions.js'
import {
  setDiseaseProband,
  setFromOnboardingDiseases,
} from 'redux/diseases/actions.js'

import { WEB } from '$constants/Platforms.js'

import global_styles, {
  DefaultFullHeight,
} from '$constants/styles/global.styles.js'
import secondaryStyles from './styles/styles.secondary'

import {
  setProbandGenderIdentity,
  setProbandPronoun,
} from '$redux/proband/actions'

import ApiFetchHandler from '$api/ApiFetchHandler.js'
import GenderIdentityOptions from './components/GenderIdentityOptions.js'

import UpdateMemberProfile from '$screens/_utils/UpdateMemberProfile.js'
import custom_styles from './styles/styles.main.js'
import { i18n } from '$localization/config.js'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'
import { setFromOnboardingGeneTest } from '$redux/gene_testing/actions.js'
import { setLastLoginScreen, setError } from '$redux/defaults/actions.js'
import { setProbandProfileAction } from '$redux/proband/actions.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

let hasGenderIdentity = false
let isLastScreenBeforeLogin = false

const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.PersonalGenderIdentity?.name
const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

class PersonalGenderIdentity extends Component {
  pronounList = [
    {
      key: 1,
      name: 'He/Him',
      value: 'he/him',
    },
    {
      key: 2,
      name: 'She/Her',
      value: 'she/her',
    },
    {
      key: 3,
      name: 'They/Them',
      value: 'they/them',
    },
    {
      key: 4,
      name: 'Other',
      value: 'other',
    },
  ]

  refs = {
    otherPronounTextInput: null,
    otherPronounTextInputAccessory: null,
  }

  // DONE: fetch on redux
  colorSettings = this.props.colorSettings

  selectedClinician = this.props.selectedClinician

  constructor(props) {
    super(props)

    this.genderIdentityElement = createRef()
    this.state = {
      selectedGenderIdentity: 'null',
      pronounList: this.pronounList,
      selectedPronoun: '',
      otherPronounSelected: false,
      otherPronountTextInputEditable: true,
      otherPronounTextInputValue: '',
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  // ==================================================================================================================
  // ==================================================================================================================

  componentDidMount() {
    const { probandProfile, navigation, colorSettings, lastScreenLogin } =
      this.props

    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.gender_identity

    navigation.setParams({
      cs: colorSettings,
      isLastScreenBeforeLogin,
    })

    if (probandProfile) {
      this.setGenderIdentity()
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  setGenderIdentity = () => {
    const { profileData } = this.props?.probandProfile
    const { lastScreenLogin } = this.props
    let finalGender

    switch (profileData.genderIdentity) {
      case 'female':
        finalGender = 'f'
        break
      case 'male':
        finalGender = 'm'
        break
      case 'non-binary':
        finalGender = 'nb'
        break
      default:
        finalGender = null
    }

    isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.gender_identity

    if (profileData.genderIdentity) {
      hasGenderIdentity = true
      this.handleGenderIdentityOnMount(finalGender)
    }

    if (profileData.pronoun) {
      const selectedPronoun = this.pronounList.find(
        (val) => val.value === profileData.pronoun
      )

      this.didSelectPronounOption(selectedPronoun)
    }
  }

  handleGenderIdentityOnMount = (gender) => {
    const element = this.genderIdentityElement.current
    element.onSelectOption(gender)
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  togglePronounOptionBackgroundColor = (pronounOption) => {
    const notSelectedStyle = {
      backgroundColor:
        this.colorSettings?.selectable_btn_inactive_1 || 'transparent',
      borderColor:
        this.colorSettings?.selectable_btn_border_inactive_1 || 'black',
    }
    const selectedStyle = {
      backgroundColor:
        this.colorSettings?.selectable_btn_active_1 || 'rgba(74, 74, 74, 0.1)',
      borderColor:
        this.colorSettings?.selectable_btn_border_active_1 || 'black',
    }

    let style = {}

    if (this.state.selectedPronoun === pronounOption) {
      style = selectedStyle
    } else {
      style = notSelectedStyle
    }

    return style
  }

  didSelectPronounOption = (item) => {
    if (!item) {
      return
    }
    const selectedPronoun = item

    this.setState({
      otherPronounSelected: false,
      otherPronountTextInputEditable: true,
      selectedPronoun,
    })
    // DONE: move it ro proband redux
    // Update Proband data

    this.props._saveProbandPronoun_(selectedPronoun.value)
  }

  renderPronounOptions = () => {
    if (!this.state.pronounList) {
      return null
    }

    const { pronounList } = this.state
    const { selectedPronoun } = this.state

    const getPronounOptions = () => {
      const pronounOptions = []

      for (let i = 0; i < pronounList.length; i++) {
        const pronoun = pronounList[i]
        const pronounName = `${pronoun.name}`
          .replace('/', '_slash_')
          .toLowerCase()

        if (pronounName === 'other') {
          // If selected Pronoun is "Other"...
          pronounOptions.push(
            <TouchableOpacity
              style={[
                custom_styles.ellipticalButton,
                this.togglePronounOptionBackgroundColor(pronoun),
              ]}
              onPress={() => this.didSelectOtherPronounOption(pronoun)}
              activeOpacity={1.0}
            >
              <Text
                style={[
                  custom_styles.ellipticalButtonText,
                  {
                    color:
                      this.colorSettings?.selectable_btn_text_active_1 ||
                      'black',
                  },
                ]}
              >
                {i18n.t(pronounName).default}
              </Text>
            </TouchableOpacity>
          )
        } else {
          // If selected Pronoun is any other else...
          pronounOptions.push(
            <TouchableOpacity
              style={[
                custom_styles.ellipticalButton,
                this.togglePronounOptionBackgroundColor(pronoun),
              ]}
              onPress={() => this.didSelectPronounOption(pronoun)}
              activeOpacity={1.0}
            >
              <Text
                style={[
                  custom_styles.ellipticalButtonText,
                  {
                    color:
                      this.colorSettings?.selectable_btn_text_active_1 ||
                      'black',
                  },
                ]}
              >
                {i18n.t(pronounName).default}
              </Text>
            </TouchableOpacity>
          )
        }
      }

      return pronounOptions
    }

    // Render the Pronoun options
    return (
      <View style={[custom_styles.content, { width: '100%' }]}>
        {getPronounOptions()}
      </View>
    )
  }

  // ==================================================================================================================
  // ==================================================================================================================

  didSelectOtherPronounOption = (item) => {
    const { show_gender_identity = false } = this.selectedClinician || false
    if (!item) {
      return
    }
    const selectedPronoun = item

    this.setState({
      otherPronounSelected: true,
      otherPronountTextInputEditable: true,
      selectedPronoun,
    })

    this.props._saveProbandPronoun_(selectedPronoun.value)
    show_gender_identity &&
      this.props.getGenderPronounInfo(selectedPronoun.value)
  }

  toggleOtherPronounTextInput = (isSelected = false) => {
    this.setState({ otherPronounSelected: isSelected })
  }

  otherPronounTextHandler = (value) => {
    const { show_gender_identity = false } = this.selectedClinician || false
    this.setState({ otherPronounTextInputValue: value })
    show_gender_identity && this.props.getGenderOtherPronounInfo(value)
  }

  renderOtherPronountTextInputView = (visible = true) => {
    if (!visible) {
      return null
    }
    if (Platform.OS === 'ios') {
      return (
        <View
          style={[
            custom_styles.otherPronounInput,
            {
              borderColor:
                this.colorSettings?.selectable_btn_border_inactive_1 || 'black',
            },
          ]}
        >
          <TextInput
            style={{
              color: 'white',
              fontFamily: 'montserrat',
              fontSize: 20,
              lineHeight: 24,
              textAlign: 'center',
            }}
            onFocus={() => {
              if (!this.refs.otherPronounTextInputAccessory) {
                return
              }
              this.refs.otherPronounTextInputAccessory.focus()
            }}
            selectionColor="white"
            placeholderTextColor={
              this.colorSettings?.text_input_placeholder_color_2 ||
              'rgba(74, 74, 74, 0.5)'
            }
            placeholder={i18n.t('preferred_pronoun_placeholder').default}
            maxLength={50}
            editable={this.state.otherPronountTextInputEditable}
            // inputAccessoryViewID={textInputAccessoryViewID}
            onChangeText={(value) => {
              this.setState({ otherPronounTextInputValue: value })
            }}
            value={this.state.otherPronounTextInputValue}
          />
        </View>
      )
    }
    return (
      <View
        style={[
          custom_styles.otherPronounInput,
          {
            borderColor:
              this.colorSettings?.selectable_btn_border_inactive_1 || 'black',
          },
        ]}
      >
        <TextInput
          // ref={(ref) => (this.refs.otherPronounTextInput = ref)}
          style={{
            color: 'white',
            fontFamily: 'montserrat',
            fontSize: 20,
            lineHeight: 24,
            textAlign: 'center',
          }}
          selectionColor="white"
          placeholderTextColor={
            this.colorSettings?.text_input_placeholder_color_2 ||
            'rgba(74, 74, 74, 0.5)'
          }
          placeholder={i18n.t('preferred_pronoun_placeholder').default}
          autoFocus
          multiline
          editable
          maxLength={50}
          onChangeText={this.otherPronounTextHandler}
          value={this.state.otherPronounTextInputValue}
        />
      </View>
    )
  }

  // ==================================================================================================================
  // ==================================================================================================================

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const { selectedGenderIdentity } = this.state
    if (selectedGenderIdentity) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const { selectedGenderIdentity } = this.state

    if (selectedGenderIdentity) {
      return {
        backgroundColor: this.colorSettings?.bottom_next_btn_enabled || 'white',
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled || 'white',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'
    const { selectedGenderIdentity } = this.state

    if (selectedGenderIdentity) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  toggleNextButtonTextColor = () => {
    const { selectedGenderIdentity } = this.state

    if (selectedGenderIdentity) {
      return {
        color: this.colorSettings?.selectable_btn_text_active_1 || 'black',
      }
    }
    return {
      color:
        this.colorSettings?.selectable_btn_active_1 || 'rgba(74, 74, 74, 0.5)',
    }
  }

  nextButtonAction = async () => {
    // DONE: remove passed data
    const {
      account,
      authCredentials,
      saveError,
      _saveProbandDisease_,
      _clearProbandDisease_,
      _clearProbandSkipLogic_,
      _setFromOnboardingDiseases_,
      _setFromOnboardingGeneTest_,
      navigation,
    } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gender_identity,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    await this.updatePersonalInfo()
    await this.updateProbandProfileStorage()

    _saveProbandDisease_([])
    _clearProbandDisease_()
    _clearProbandSkipLogic_()
    _setFromOnboardingDiseases_(true)
    _setFromOnboardingGeneTest_(true)

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.DiseaseListMainScreen.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  onSelectGenderIdentity = (value) => {
    const selectedValue = value.toLowerCase()
    const { show_gender_identity = false } = this.selectedClinician || false

    // Update state
    this.setState({ selectedGenderIdentity: selectedValue })

    const genderIdentity = this.genderSelect(selectedValue)
    show_gender_identity && this.props.getGenderIdentityInfo(genderIdentity)
    // Update Proband data
    // DONE: transfer to prboand redux
    this.props._saveProbandGenderIdentity_(genderIdentity)
  }

  updatePersonalInfo = async () => {
    const { proband, authCredentials, saveError } = this.props
    const {
      selectedGenderIdentity,
      selectedPronoun,
      otherPronounTextInputValue,
    } = this.state
    const finalGender = this.genderSelect(selectedGenderIdentity)

    const updatePersonalInfoPayload = {
      path: 'member/update_personal_info/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        proband_id: proband.probandID,
        member_id: proband.probandID,
        member_info: {
          gender_identity: finalGender,
          pronoun: selectedPronoun.value,
          other_pronoun: otherPronounTextInputValue,
        },
      },
      pageDetails: {
        page: 'PersonalGenderIdentity.js',
      },
    }

    const response = await ApiFetchHandler(updatePersonalInfoPayload)

    if (response.isError) {
      return saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }
  }

  updateProbandProfileStorage = async () => {
    const { proband, _saveProbandProfileToStore_, authCredentials } = this.props

    const memberData = {
      proband_id: proband.probandID,
      member_id: proband.probandID,
    }

    try {
      const memberProfile = await UpdateMemberProfile(
        memberData,
        authCredentials
      )
      _saveProbandProfileToStore_(memberProfile)
    } catch (err) {
      console.log(
        '🚀 \n\n file: PersonalGenderIdentity.js:567 \n\n PersonalGenderIdentity \n\n updateProbandProfileStorage= \n\n err:',
        err
      )
    }
  }

  genderSelect = (selectedGenderIdentity) => {
    let gender

    switch (selectedGenderIdentity) {
      case 'f':
        gender = 'female'
        break
      case 'm':
        gender = 'male'
        break
      case 'nb':
        gender = 'non-binary'
        break
      default:
        gender = null
    }
    return gender
  }

  render() {
    const { selectedGenderIdentity, selectedPronoun } = this.state
    const { show_gender_identity = false } = this.selectedClinician || false

    const mainContent = (
      <View
        styles={custom_styles.contentContainer}
        nativeID="web_scaled_main_container"
      >
        {/* <Content Body> */}
        <View style={[custom_styles.content, secondaryStyles.content]}>
          {/* <Title question> */}
          <View style={custom_styles.content}>
            <Text
              style={[
                custom_styles.titleText,
                {
                  color:
                    this.colorSettings?.text_input_title_color_2 || 'black',
                },
              ]}
            >
              {i18n.t('what_is_your_gender_identity').default}
            </Text>
          </View>
          {/* </Title question> */}

          <View style={secondaryStyles.divider} />

          {/* <Gender Identity Options> */}
          <View
            style={[
              custom_styles.content,
              secondaryStyles.genderIdentityOptionContainer,
            ]}
          >
            <GenderIdentityOptions
              ref={this.genderIdentityElement}
              onSelect={this.onSelectGenderIdentity}
              colorSettings={this.colorSettings}
            />
          </View>
          {/* </Gender Identity Options> */}

          <View style={{ height: 20, width: '100%' }} />

          {/* <Preferred Pronoun> */}
          <View style={custom_styles.content}>
            <Text
              style={[
                custom_styles.titleText,
                {
                  fontSize: 21,
                  color:
                    this.colorSettings?.text_input_title_color_2 || 'black',
                },
              ]}
            >
              {i18n.t('pronoun').default}
            </Text>
          </View>
          {/* </Preferred Pronoun> */}

          <View style={secondaryStyles.genderPronounDivider} />

          {this.renderPronounOptions()}
          <View style={secondaryStyles.pronoundOption}>
            {this.state.otherPronounSelected &&
              this.renderOtherPronountTextInputView()}
          </View>
        </View>
        {/* </Content Body> */}
      </View>
    )

    if (!show_gender_identity) {
      return (
        <ErrorBoundary>
          <SafeAreaView
            style={[
              custom_styles.container,
              {
                backgroundColor:
                  this.colorSettings?.onboarding_bgcolor || 'white',
              },
            ]}
          >
            <KeyboardAvoidingView
              style={custom_styles.container}
              behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            >
              <ScrollView>
                {mainContent}
                {/* <Next button> */}
              </ScrollView>
              {show_gender_identity === false && (
                <View style={global_styles.nextButtonContainer}>
                  <TouchableOpacity
                    style={[
                      global_styles.nextButton,
                      this.toggleNextButtonColor(),
                    ]}
                    disabled={this.toggleNextButton()}
                    onPress={this.nextButtonAction}
                  >
                    <Text
                      style={[
                        global_styles.nextButtonText,
                        {
                          color: this.toggleNextButtonText(),
                        },
                      ]}
                    >
                      {i18n.t('next').default}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

              {/* </Next button> */}
            </KeyboardAvoidingView>
          </SafeAreaView>
        </ErrorBoundary>
      )
    }
    return (
      <ErrorBoundary>
        <View>{mainContent}</View>
      </ErrorBoundary>
    )
  }

  // ==================================================================================================================
  // ==================================================================================================================

  _bindTextInputAccessoryView = (textInput) => {
    if (textInput) {
      this.refs.otherPronounTextInputAccessory = textInput
    }
  }

  textInputAccessoryViewOnPressDone = (value) => {
    const { selectedPronoun } = this.state
    selectedPronoun.value = value

    this.setState({
      otherPronounTextInputValue: value,
      otherPronountTextInputEditable: true,
      selectedPronoun,
    })
    // DONE: transfer data to proband redux
    // Update Proband data
    this.props._saveProbandPronoun_(selectedPronoun.value)
  }

  textInputAccessoryViewOnPressCancel = () => {
    this.setState({
      otherPronountTextInputEditable: true,
    })
  }
}

// <Redux functions>
function mapGlobalStateToProps(state) {
  const { store, probandStore, accountStore, diseaseStore, clinicianStore } =
    state
  return {
    colorSettings: store.colorSettings,
    proband: probandStore.proband,
    probandProfile: probandStore.probandProfile,
    account: accountStore.account,
    selectedClinician: clinicianStore.selectedClinician,
    authCredentials: accountStore.authCredentials,
    diseaseFromOnboarding: diseaseStore.fromOnboarding,
    lastScreenLogin: store.lastScreenLogin,
  }
}

const actionCreators = {
  _saveProbandPronoun_: setProbandPronoun,
  _saveProbandGenderIdentity_: setProbandGenderIdentity,
  _clearProbandDisease_: clearProbandDisease,
  _clearProbandSkipLogic_: clearProbandSkipLogic,
  _setFromOnboardingDiseases_: setFromOnboardingDiseases,
  _setFromOnboardingGeneTest_: setFromOnboardingGeneTest,
  _saveProbandDisease_: setDiseaseProband,
  _saveProbandProfileToStore_: setProbandProfileAction,
  saveLastLoginScreen: setLastLoginScreen,
  saveError: setError,
}

export default connect(
  mapGlobalStateToProps,
  actionCreators
)(PersonalGenderIdentity)
