import { Component } from 'react'
import { TouchableOpacity, Image, Text, View, Platform } from 'react-native'

import { i18n } from '$localization/config.js'
import { WEB } from '$constants/Platforms.js'

import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

class ParentsSection extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  currentMember = this.props.currentMember

  relationshipData = this.props.relationshipData

  state = {
    currentMember: this.currentMember,
    relationshipData: this.relationshipData,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { currentMember } = newProps
    const { relationshipData } = newProps
    return {
      currentMember,
      relationshipData,
    }
  }

  selectParentButtonAction = (currentParentData, partnerDataSource) => {
    // Notes:
    // 1. When changing the Mother, the Father's data should be passed.
    // 2. When changing the Father, the Mother's data should be passed.
    this.parentController?.navigateToSelectParent(
      currentParentData,
      partnerDataSource
    )
  }

  render() {
    const { currentMember } = this.state
    const { relationshipData } = this.state
    const motherData = relationshipData?.mother
    const fatherData = relationshipData?.father

    // Temporary disable editing of Parents for Aunts / Uncles
    if (currentMember?.relationship_to_proband) {
      const relationshipToProband = currentMember?.relationship_to_proband
      if (
        relationshipToProband?.includes('aunt') ||
        relationshipToProband?.includes('uncle')
      ) {
        fatherData.isEditable = false
        motherData.isEditable = false
      }
    }

    return (
      <View>
        <FatherSection
          data={fatherData}
          onPressEdit={() =>
            this.selectParentButtonAction(fatherData, motherData)
          }
        />
        <MotherSection
          data={motherData}
          onPressEdit={() =>
            this.selectParentButtonAction(motherData, fatherData)
          }
        />
      </View>
    )
  }
}

const MotherSection = ({ data, onPressEdit = null }) => {
  const layout = []

  layout.push(
    <View
      key="title"
      style={[custom_styles.groupContentItem, { borderBottomWidth: 0 }]}
    >
      <View style={custom_styles.groupContentTitleTextContainer}>
        <Text style={custom_styles.groupContentTitleText}>
          {i18n.t('the_mother_of_this_person_is').default}
        </Text>
      </View>
    </View>
  )

  if (!data?.id) {
    layout.push(
      <View key="status" style={custom_styles.relationshipItemContainer}>
        <View style={custom_styles.relationshipItemContent}>
          <Image style={custom_styles.relationshipItemImage} />
          <View>
            <Text style={custom_styles.relationshipItemTitleText}>
              {i18n.t('not_available').default}
            </Text>
          </View>
        </View>
      </View>
    )
  } else {
    layout.push(
      <View key="data" style={custom_styles.relationshipItemContainer}>
        <View
          style={[
            custom_styles.relationshipItemContent,
            {
              ...Platform.select({
                [WEB]: {
                  paddingVertical: 16,
                },
              }),
            },
          ]}
        >
          <Image style={custom_styles.relationshipItemImage} />
          <View>
            <Text style={custom_styles.relationshipItemTitleText}>
              {i18n.t(data?.name)?.default ?? data?.name}
            </Text>
            <Text style={custom_styles.relationshipItemSubtitleText}>
              {i18n.t('mother').default}
            </Text>
            {!data?.isEditable && (
              <Text style={custom_styles.relationshipItemSubtitleText}>
                {`(${i18n.t('this_persons_mother_cannot_be_changed').default})`}
              </Text>
            )}
          </View>
        </View>

        <EditButton isEditable={data?.isEditable} onPress={onPressEdit} />
      </View>
    )
  }

  return <View style={custom_styles.groupContent}>{layout}</View>
}

const FatherSection = ({ data, onPressEdit = null }) => {
  const layout = []

  layout.push(
    <View
      key="title"
      style={[custom_styles.groupContentItem, { borderBottomWidth: 0 }]}
    >
      <View style={custom_styles.groupContentTitleTextContainer}>
        <Text style={custom_styles.groupContentTitleText}>
          {i18n.t('the_father_of_this_person_is').default}
        </Text>
      </View>
    </View>
  )

  if (!data?.id) {
    layout.push(
      <View key="status" style={custom_styles.relationshipItemContainer}>
        <View style={custom_styles.relationshipItemContent}>
          <Image style={custom_styles.relationshipItemImage} />
          <View>
            <Text style={custom_styles.relationshipItemTitleText}>
              {i18n.t('not_available').default}
            </Text>
          </View>
        </View>
      </View>
    )
  } else {
    layout.push(
      <View key="data" style={custom_styles.relationshipItemContainer}>
        <View
          style={[
            custom_styles.relationshipItemContent,
            {
              ...Platform.select({
                [WEB]: {
                  paddingVertical: 16,
                },
              }),
            },
          ]}
        >
          <Image style={custom_styles.relationshipItemImage} />

          <View style={custom_styles.relationshipItemTextContainer}>
            <Text style={custom_styles.relationshipItemTitleText}>
              {i18n.t(data?.name)?.default ?? data?.name}
            </Text>
            <Text style={custom_styles.relationshipItemSubtitleText}>
              {i18n.t('father').default}
            </Text>
            {!data?.isEditable && (
              <Text style={custom_styles.relationshipItemSubtitleText}>
                {`(${i18n.t('this_persons_father_cannot_be_changed').default})`}
              </Text>
            )}
          </View>
        </View>

        <EditButton isEditable={data?.isEditable} onPress={onPressEdit} />
      </View>
    )
  }

  return <View style={custom_styles.groupContent}>{layout}</View>
}

const EditButton = ({
  isEditable = false,
  onPress = function () {
    console.log('🚀 \n\n file: Parents.section.js:220 \n\n onPress')
  },
}) => {
  if (!isEditable) return null

  return (
    <View style={custom_styles.deleteButtonContainer}>
      <TouchableOpacity
        style={{
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        activeOpacity={0.5}
        onPress={onPress}
      >
        <Image
          source={require('$assets/images/new_assets/icon-edit.png')}
          style={{ width: 14, height: 16, resizeMode: 'contain' }}
        />
      </TouchableOpacity>
    </View>
  )
}

export default ParentsSection
