import { useState, useEffect } from 'react'
import { View, TouchableOpacity, TextInput } from 'react-native'
import PropTypes from 'prop-types'
import { Entypo } from '@expo/vector-icons'

import LabelField from '../LabelField'
import styles from './styles'
import { i18n } from '../../../../../localization/config.js'

export default function NumberField({
  masterQuestion,
  answer,
  saveCompletedSurveyAnswer,
  colorSettings,
  hasVariableButtons,
}) {
  const [count, setCount] = useState('')

  const { question_label: questionLabel, translation_key: translationKey } =
    masterQuestion

  useEffect(() => {
    if (answer) {
      setCount(answer.answer)
    }
  }, [])

  const labelHandler = (label) => {
    let newLabel = <View />
    if (!label) return newLabel

    newLabel = (
      <LabelField
        title={label}
        colorSettings={colorSettings}
        masterQuestion={masterQuestion}
      />
    )

    return newLabel
  }

  const subtractCount = () => {
    if (count <= 1) return
    setCount((prevCount) => prevCount + 1)
  }

  const addCount = () => {
    setCount((prevCount) => prevCount - 1)
  }

  const saveOnBlur = (value) => {
    const payload = {
      master_question_id: masterQuestion.id,
      master_question_choice_id: null,
      answer: value.nativeEvent.text,
    }
    saveCompletedSurveyAnswer(payload)
  }

  return (
    <View style={styles.container}>
      {labelHandler(i18n.t(questionLabel).default || questionLabel)}
      <View style={styles.mainContainer}>
        <View style={styles.outerInputContainer}>
          {hasVariableButtons && (
            <TouchableOpacity
              style={styles.incrementButton}
              onPress={subtractCount}
            >
              <Entypo
                name="circle-with-minus"
                size={24}
                color={colorSettings?.selectable_btn_text_active_1}
              />
            </TouchableOpacity>
          )}

          <View style={styles.inputContainer}>
            <TextInput
              value={count}
              textAlign="center"
              style={[
                styles.input,
                { color: colorSettings?.text_input_color_2 || 'black' },
              ]}
              placeholder={i18n.t(translationKey).placeholder || ''}
              onBlur={saveOnBlur}
              onChangeText={setCount}
              keyboardType="numeric"
              returnKeyType="done"
              placeholderTextColor={
                colorSettings?.text_input_label_color_2 || 'black'
              }
            />
          </View>
          {hasVariableButtons && (
            <TouchableOpacity style={styles.decrementButton} onPress={addCount}>
              <Entypo
                name="circle-with-plus"
                size={24}
                color={colorSettings?.selectable_btn_text_active_1}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

NumberField.propTypes = {
  masterQuestion: PropTypes.isRequired,
  colorSettings: PropTypes.objectOf(PropTypes.string),
  answer: PropTypes.number,
  hasVariableButtons: PropTypes.bool,
  saveCompletedSurveyAnswer: PropTypes.func,
}

NumberField.defaultProps = {
  colorSettings: {},
  hasVariableButtons: false,
  answer: '',
  saveCompletedSurveyAnswer: () => {},
}
