import { Component } from 'react'
import {
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Modal,
  SafeAreaView,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import Account from '$data_models/Account'
import Diseases from '$data_models/Diseases.js'
import ApiFetchHandler from '$api/ApiFetchHandler.js'
import { setError } from '$redux/defaults/actions.js'

import LoadingView from '$constants/LoadingView.js'
import {
  showGenericServerError,
  handleDefaultError,
} from '$navigation/_utils/NavigationUtils.js'

class SurveyCancer extends Component {
  colorSettings = null

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      diseasePerType: '',
    }

    if (this.colorSettings == null) {
      this.colorSettings = this.props.colorSettings
    }
  }

  memberDisease = ''

  // from SurveyScreen
  data = this.props.navigation.getParam('data', null)

  // componentWillReceiveProps(newProps) {
  // 	this.data = newProps.data
  //
  // 	this.setState(this.state)
  // }

  async componentDidMount() {
    // load disease API type
    const payload = {
      path: 'disease/?disease_type=cancer-polyps',
      method: 'get',
    }

    const response = await ApiFetchHandler(payload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.setState({
      diseasePerType: this.modifyDiseasesList(response, this.memberDisease),
      isLoading: false,
    })
  }

  // ===============================
  // Modify diseasesList
  // ===============================
  modifyDiseasesList = (diseaseList, memberDisease) => {
    const diseasesListData = diseaseList
    // map disease
    const newList = diseasesListData.map((item) => {
      const diseaseData = this.diseasesFilter(
        item.id,
        item,
        item.skip_logistics,
        memberDisease
      )

      return diseaseData
    })

    return newList
  }

  diseasesFilter = (id, item, skipLogic, memberDisease) => {
    const diseaseListModel = memberDisease
    // let newItem = JSON.parse(JSON.stringify(item))

    for (const val in diseaseListModel) {
      if (diseaseListModel[val].disease_id == id) {
        // map skiplogic
        const skipLogicList = skipLogic.map((skip) => {
          const skipLogicData = this.skipLogicFilter(
            skip.id,
            skip,
            diseaseListModel[val].skip_logics
          )

          return skipLogicData
        })

        item.skip_logistics = skipLogicList

        item.age_diagnosed = diseaseListModel[val].age_diagnosed

        return {
          ...item,
        }
      }
    }

    const skipData = item.skip_logistics.map((item) => {
      delete item.answer
      return item
    })

    delete item.age_diagnosed

    item.skip_logistics = skipData

    return {
      ...item,
    }
  }

  skipLogicFilter = (id, item, skipLogicModel) => {
    for (const val in skipLogicModel) {
      if (skipLogicModel[val].skip_logic_id == id) {
        item.answer = skipLogicModel[val].answer
        return {
          ...item,
        }
      }
    }

    delete item.answer
    return {
      ...item,
    }
  }

  navDisease = () => {
    const { navigate } = this.props.navigation
    navigate('DiseasesPerCategory', {
      proband: this.data.proband,
      diseaseType: this.data.diseaseType,
      diseaseDataList: this.data.diseaseDataList,
      diseaseListModel: new Diseases(),
      diseasePerType: this.state.diseasePerType,
      fromSurvey: true,
      parent: this,
    })
  }

  updateSurveyScreen = async () => {
    this.setState({ isLoading: true })
    await this.getMemberProfile()
    this.getCancerList()
  }

  getMemberProfile = async () => {
    const { proband } = this.data
    const { selectedLanguage } = this.props
    const headers = proband.account.authCredentials
    const proband_details = {
      proband_id: proband.probandID,
      member_id: proband.probandID,
    }

    // get proband disease and add to state
    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: headers.accessToken,
      body: {
        proband_id: proband.probandID,
        member_id: proband.probandID,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: 'SurveyCancer.js',
      },
    }

    const response = await ApiFetchHandler(getMemberProfilePayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.memberDisease = response.member_disease
  }

  render() {
    this.getMemberProfile()
    return (
      <View style={styles.container}>
        <ImageBackground
          // source={require("$assets/images/new_assets/bg-survey-complete.png")}
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 42,
            backgroundColor: this.colorSettings?.onboarding_bgcolor || 'white',
          }}
        >
          <View
            style={{
              flex: 1.5,
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Image
              source={require('$assets/images/new_assets/illus-survey-complete.png')}
              style={{ width: 117, height: 188, resizeMode: 'contain' }}
            />
          </View>
          <View
            style={{
              flex: 0.6,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                fontFamily: 'karla-bold',
                fontSize: 36,
                color: this.colorSettings?.text_input_title_color_2 || 'black',
                textAlign: 'center',
              }}
            >
              {i18n.t('survey_complete').default}
            </Text>
          </View>
          <View style={{ flex: 0.9, width: '100%' }}>
            <TouchableOpacity
              style={{
                height: 60,
                backgroundColor: this.colorSettings?.btn_filled_enabled_1,
                borderWidth: 1,
                borderColor: this.colorSettings?.btn_filled_enabled_1,
                borderRadius: 37.5,
                width: '95%',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
              onPress={this.navDisease}
            >
              <Text
                style={{
                  fontFamily: 'montserrat-medium',
                  fontSize: 18,
                  textAlign: 'center',
                  color: this.colorSettings?.btn_filled_text_1 || 'black',
                }}
              >
                {i18n.t('edit').default}
              </Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
        <Modal visible={this.state.isLoading}>
          <SafeAreaView style={{ flex: 1, backgroundColor: 'rgba(0,0,0,.6)' }}>
            {/* <LoadingView
							visible={true}
							message={'Please Wait'}
							/> */}

            <LoadingView
              visible
              message="Please Wait"
              backgroundColor={this.colorSettings?.splash_bgcolor}
              tintColor={this.colorSettings?.btn_no_fill_border_1}
              textColor={this.colorSettings?.btn_no_fill_text_1}
            />
          </SafeAreaView>
        </Modal>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(101, 209, 185)',
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
})

// <Redux functions>
function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
    selectedLanguage: store.selectedLanguage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapGlobalStateToProps, mapDispatchToProps)(SurveyCancer)
