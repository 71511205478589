import { View, Text, Image, TouchableOpacity, ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'
import styles from '../_styles/main.styles'

import { i18n } from '$localization/config.js'

import { formatDateTimeCreated } from '$api/_utils/Utils.js'

const notificationIcon = require('$assets/images/new_assets/icon-notif.png')

/* API Notification types */
const clinician_accepted_connection = 'clinician_accepted_connection'
const clinician_declined_connection = 'clinician_declined_connection'
const clinician_disconnected = 'clinician_disconnected'
const clinician_reconnected = 'clinician_reconnected'
const family_member_joined = 'family_member_joined'
const linkedFamilyMemberJoined = 'linked_family_member_joined'

const NotificationItem = ({
  colorSettings = {},
  item,
  onDismiss = function () {},
  onAccept = function () {},
  onDeny = function () {},
}) => {
  if (!item?.id) return null

  const notificationData = item
  const notificationType = item?.notification_type
  const createdAt = formatDateTimeCreated(notificationData?.created_at)
  const messageHeader = notificationData?.message_header
  const messageBody = notificationData?.message_body

  const notificationIsLinkedFamilyMemberJoined =
    notificationType === linkedFamilyMemberJoined
  /* Check if Notification is dismissable */
  let isDismissable = false
  switch (notificationType.toLowerCase()) {
    case family_member_joined:
    case clinician_accepted_connection:
    case clinician_declined_connection:
    case clinician_disconnected:
    case clinician_reconnected:
      isDismissable = true
      break

    default:
      isDismissable = false
      break
  }

  return (
    <ScrollView
      contentContainerStyle={[
        styles.notificationItemContent,
        { paddingHorizontal: 16, flexGrow: 1 },
      ]}
    >
      <View style={styles.listItemContainer}>
        {/* <Content> */}
        <View style={styles.listItemContent}>
          {/* <Content Header> */}
          <View style={styles.listItemContentHeader}>
            <Image
              source={notificationIcon}
              style={{ width: 13, height: 15, resizeMode: 'contain' }}
            />
            <View style={{ marginHorizontal: 10, width: '100%' }}>
              <Text
                style={[
                  styles.listItemContentHeaderTitle,
                  {
                    color: colorSettings?.text_input_title_color_1,
                    flexWrap: 'wrap',
                    width: '90%',
                  },
                ]}
              >
                {notificationIsLinkedFamilyMemberJoined
                  ? i18n.t('family_members.uppercase')
                  : i18n.t(notificationType)?.uppercase}
              </Text>
            </View>
          </View>

          <View style={{ height: 16 }} />

          {/* <Content Body> */}
          <View style={styles.listItemContentBody}>
            {/* <Datetime created> */}
            <Text
              style={[
                styles.listItemDateTimeText,
                {
                  color: colorSettings?.text_input_title_color_1,
                },
              ]}
            >
              {createdAt}
            </Text>
            <View style={{ height: 16 }} />
            {/* <Content Title> */}
            <Text
              style={[
                styles.listItemContentTitle,
                {
                  color: colorSettings?.text_input_title_color_1,
                },
              ]}
            >
              {messageHeader}
            </Text>
            <View style={{ height: 16 }} />
            {/* <Content Message> */}
            <Text
              style={[
                styles.listItemContentText,
                {
                  color: colorSettings?.text_input_label_color_1,
                  fontSize: 14,
                },
              ]}
            >
              {messageBody}
            </Text>
          </View>
        </View>

        <View style={{ height: 10 }} />

        {/* <Action Controls> */}
        {!isDismissable && !notificationIsLinkedFamilyMemberJoined && (
          <AcceptDenyButton
            colorSettings={colorSettings}
            onAccept={onAccept}
            onDeny={onDeny}
            item={item}
          />
        )}
        {notificationIsLinkedFamilyMemberJoined && (
          <ViewSettingsButtons onDismiss={onDismiss} item={item} />
        )}
        {isDismissable && (
          <DismissButton
            colorSettings={colorSettings}
            onDismiss={onDismiss}
            item={item}
          />
        )}
      </View>
    </ScrollView>
  )
}

const AcceptDenyButton = ({
  colorSettings = {},
  item,
  onAccept = function () {},
  onDeny = function () {},
}) => (
  <View style={styles.controlsContainer}>
    <View style={styles.stackContainerHorizontal}>
      {/* <Accept Button> */}
      <View style={[styles.controlButtonContainer, { flex: 0.5 }]}>
        <TouchableOpacity
          style={styles.controlButton}
          onPress={() => onAccept(item)}
        >
          <Text
            style={[
              styles.controlButtonText,
              {
                color: colorSettings?.linked_text,
              },
            ]}
          >
            {i18n.t('accept')?.default}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.controlButtonSeparator} />

      {/* <Deny Button> */}
      <View style={[styles.controlButtonContainer, { flex: 0.5 }]}>
        <TouchableOpacity
          style={styles.controlButton}
          onPress={() => onDeny(item)}
        >
          <Text
            style={[
              styles.controlButtonText,
              {
                color: colorSettings?.text_input_label_color_1,
              },
            ]}
          >
            {i18n.t('deny')?.default}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>
)

const DismissButton = ({
  colorSettings = {},
  item,
  onDismiss = function () {},
}) => (
  <View style={styles.controlsContainer}>
    <View style={styles.stackContainerHorizontal}>
      <View style={[styles.controlButtonContainer, { flex: 1.0 }]}>
        <TouchableOpacity
          style={styles.notifDismissButton}
          activeOpacity={0.5}
          onPress={() => onDismiss(item)}
        >
          <Text
            style={[
              styles.notifDismissButtonText,
              {
                color: colorSettings?.linked_text,
              },
            ]}
          >
            {i18n.t('dismiss')?.default}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>
)

const ViewSettingsButtons = ({ onDismiss, item }) => {
  const navigation = useNavigation()
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const handleViewSettingsButtonClick = () => {
    navigation.replace('SharingSettingsMain')
  }

  return (
    <View style={styles.controlsContainer}>
      <View style={styles.stackContainerHorizontal}>
        <View style={[styles.controlButtonContainer, { flex: 1.0 }]}>
          <TouchableOpacity
            style={[styles.notifDismissButton, { width: '50%' }]}
            activeOpacity={0.5}
            onPress={handleViewSettingsButtonClick}
          >
            <Text
              style={[
                styles.notifDismissButtonText,
                {
                  color: colorSettings?.linked_text,
                },
              ]}
            >
              {i18n.t('view_settings.title')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.controlButtonSeparator} />
        <View style={[styles.controlButtonContainer, { flex: 1.0 }]}>
          <TouchableOpacity
            style={[styles.notifDismissButton, { width: '50%' }]}
            activeOpacity={0.5}
            onPress={() => onDismiss(item)}
          >
            <Text
              style={[
                styles.notifDismissButtonText,
                {
                  color: colorSettings?.text_input_label_color_1,
                },
              ]}
            >
              {i18n.t('dismiss')?.default}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

export default NotificationItem
