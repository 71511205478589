export const webModalSizing = (currentWidth) => {
  switch (true) {
    case currentWidth >= 850:
      return 500
    case currentWidth <= 850 && currentWidth >= 450:
      return 350
    case currentWidth <= 450:
      return 300
    default:
      return 950
  }
}
