import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native'

import styles from './styles'

function EllipseButton(props) {
  let buttonHeightStyle = { height: 60.0 }
  if (Platform.OS === 'web') {
    buttonHeightStyle = { minHeight: 60.0 }
  }

  return (
    <TouchableOpacity
      style={[
        styles.roundedButtonContainer,
        props.buttonStyle,
        buttonHeightStyle,
      ]}
      activeOpacity={1.0}
      {...props}
    >
      <View style={[styles.roundedButtonTitleTextContainer, props.innerStyle]}>
        {props.children}
      </View>
    </TouchableOpacity>
  )
}

export default EllipseButton
