import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  ProgressViewIOS,
  TextInput,
  Image,
  KeyboardAvoidingView,
} from 'react-native'

import SkipLogic from '$data_models/SkipLogic.js'

export default class AgeInput extends Component {
  toggleEdit =
    typeof this.props.toggleEdit === 'undefined' ? false : this.props.toggleEdit

  parent = this.props.parent

  ageTitle = this.props.subCategory

  skipDetails = this.props.skipDetails

  toggle = this.props.skipToggle

  DiseaseDiagnosed = this.props.mainAge

  diseaseId = this.props.diseaseId

  skipLogic = this.parent.newSkipList

  skipId = this.props.skipId

  state = {
    AOD: 1,
    title: '',
  }

  _valueCount = (shouldIncrement) => {
    if (shouldIncrement) {
      this.setState((prevState) => ({
        AOD: `${parseInt(prevState.AOD) > 0 ? parseInt(prevState.AOD) + 1 : 1}`,
      }))
    } else {
      this.setState((prevState) => ({
        AOD: `${parseInt(prevState.AOD) > 1 ? parseInt(prevState.AOD) - 1 : 1}`,
      }))
    }
  }

  componentDidMount() {
    const diseaseList = this.skipLogic.diseases_list
    const skipList = this.skipLogic.skip_logic_list

    if (this.toggleEdit) {
      if (this.DiseaseDiagnosed) {
        for (const val in diseaseList) {
          if (diseaseList[val].id == this.diseaseId) {
            this.setState({ AOD: diseaseList[val].age_diagnosed })
          }
        }
      } else {
        for (const val in skipList) {
          if (skipList[val].id == this.skipId) {
            this.setState({ AOD: skipList[val].answer })
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.AOD !== this.state.AOD) {
      if (this.DiseaseDiagnosed) {
        this.parent.AgeDiagnosed(this.state.AOD)
      } else {
        this.parent.didSelectSkipLogicButton(
          this.skipDetails.id,
          this.state.AOD
        )
      }
    }
  }

  updateAge = () => {
    if (this.DiseaseDiagnosed) {
      this.parent.AgeDiagnosed(this.state.AOD)
    } else {
      this.parent.didSelectSkipLogicButton(this.skipDetails.id, this.state.AOD)
    }
  }

  componentWillMount() {
    if (this.ageTitle) {
      this.setState({ title: 'Age of Second Diagnosis:' })
    } else {
      this.setState({ title: 'Age of Diagnosis:' })
    }
  }

  render() {
    return (
      <View>
        <Text
          style={{
            fontFamily: 'karla-bold',
            fontSize: 18,
            color: '#fff',
            textAlign: 'center',
          }}
        >
          {this.state.title}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={() => this._valueCount(false)}
              style={styles.controlButton}
            >
              <Image
                source={require('$assets/images/icon/minus/white.png')}
                style={{ width: 40, height: 40, resizeMode: 'contain' }}
                resizeMode="center"
              />
            </TouchableOpacity>

            <TextInput
              onChangeText={(value) => this.setState({ AOD: value })}
              value={`${this.state.AOD}`}
              clearTextOnFocus
              selectionColor="white"
              keyboardType="number-pad"
              style={[styles.inputField, { height: 80 }]}
            />

            <TouchableOpacity
              onPress={() => this._valueCount(true)}
              style={styles.controlButton}
            >
              <Image
                source={require('$assets/images/icon/plus/white.png')}
                style={{ width: 40, height: 40, resizeMode: 'contain' }}
                resizeMode="center"
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  controlButton: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    // backgroundColor: "black",
    width: 80,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
  },
})
