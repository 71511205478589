/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  ScrollView,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import { useRoute } from '@react-navigation/core'
import ErrorBoundary from 'react-native-error-boundary'
import { DefaultFullHeight } from '../../constants/styles/global.styles'
import { WEB } from '../../constants/Platforms'
import ButtonDone from '../../components/button_done'
import { i18n } from '../../localization/config'
import ApiFetchHandler from '../../api/ApiFetchHandler.js'
import { setError } from '../../redux/defaults/actions.js'
import { PageBuilder } from './helpers'
import MainWrapper from '../../components/main_wrapper/MainWrapper.js'
import FlexContainer from '../../components/flex_container'
import LoadingView from '../../constants/LoadingView.js'
import { SurveyCompleteScreen } from './components'
import styles from './assets/styles.js'
import mock_survey_data from './data/mock-survey-data'

import { ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'
import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

const states = {
  RENDERING: 'rendering',
  FOUND: 'found',
  NOT_FOUND: 'not_found',
}

function CustomInputScreen({ navigation }) {
  const route = useRoute()

  const nextPage = route?.params?.nextPage
  const navEditMode = route?.params?.navEditMode
  const prevPage = route?.params?.prevPage

  let timeoutWasSet = false

  const deletes_in_progress = []
  const dispatch = useDispatch()
  const isFirstRender = React.useRef(false)
  const shouldExitSurvey = React.useRef(false)

  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())
  const [survey, setSurvey] = useState({ ...mock_survey_data.empty_survey })

  const [current_page, setCurrentPage] = useState(nextPage || 0)
  const [previous_page, setPreviousPage] = useState(prevPage || 0)

  const [completed_survey_answers, setCompletedSurveyAnswer] = useState([])
  const [proband, setProband] = useState(null)
  const [proband_diseases, setProbandDiseases] = useState([])
  const [proband_disease_skip_logics, setProbandDiseaseSkipLogic] = useState([])
  const [status, setStatus] = useState(states.RENDERING)

  const [edit_mode, setEditMode] = useState(navEditMode, false)

  const [show_completed_screen, setShowCompletedScreen] = useState(false)

  const familyId = useSelector(
    (state) => state?.probandStore?.proband?.familyID
  )
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const selectedSurvey = useSelector(
    (state) => state.surveyStore.selectedSurvey
  )
  const getProband = useSelector((state) => state.probandStore.proband)
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const selectedScreens = useSelector(
    (state) => state.customFlowRoutesStore.selectedScreens
  )
  const account = useSelector((state) => state.accountStore.account)
  const isOnboarding = useSelector((state) => state.store.isOnboarding)

  const saveError = (data) => dispatch(setError(data))

  // TODO: need to check name if it is SurveyCustom then return surveyId
  const currentScreenSelected = () => {
    const selectScreen = selectedScreens.find(
      (screen) => screen.name === 'SurveyCustom'
    )

    return selectScreen?.surveyId
  }

  const surveyNotFound = () => {
    setStatus(states.NOT_FOUND)
  }

  const setPageNumbers = (currentPage, previousPage) => {
    setCurrentPage(currentPage)
    setPreviousPage(previousPage)
  }

  const onExitSurveyClicked = () => {
    if (!isOnboarding) {
      navigation.popToTop()
      return
    }

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: ROUTES.PersonalGender.name,
    })

    if (isOnboarding) {
      handleNavigateToNextScreen({
        navigation,
        routeName: nextRoute,
        selectedScreen: 'SurveyCustom',
      })

      return
    }

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: 'SurveyCustom',
    })
  }

  const fetchCompletedSurveyNotification = async (payload) => {
    const getSurveyPayload = {
      path: 'member/send_proband_completed_survey_notification/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await ApiFetchHandler(getSurveyPayload)

    if (response.isError) {
      setTimeout(() => {
        switch (response.status) {
          case 404:
            break
          default:
            saveError({
              isShown: true,
              status: response.status,
              message: response.error,
            })
        }
      }, 100)
    }
  }

  const fetchSaveSurvey = async (payload) => {
    const getSurveyPayload = {
      path: 'completed_survey/save_completed_survey/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await ApiFetchHandler(getSurveyPayload)

    if (response.isError) {
      setTimeout(() => {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }, 100)

      return null
    }

    // debugger
    return response
  }

  const completedSurveyCallback = async (surveyData, pages = null) => {
    // debugger

    if (!surveyData) return

    let editMode = edit_mode

    if (!pages) {
      setEditMode(editMode)

      return
    }

    const navigationPayload = {
      nextPage: pages.next_page,
      prevPage: pages.previous_page,
      navEditMode: editMode,
      navCompletedSurvey: surveyData,
    }

    const nextPageIsLastPage = pages.next_page === survey.total_pages

    if (isOnboarding && nextPageIsLastPage) {
      const COMPLETED_STATUS = 'completed'

      const updatedSurveyData = {
        ...surveyData,
        status: COMPLETED_STATUS,
      }

      editMode = true

      await fetchSaveSurvey(updatedSurveyData)

      const screen = RAW_LAST_VISITED_SCREENS?.survey_custom

      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
      })

      if (survey.survey_organization && survey.survey_organization.id) {
        const notification_payload = {
          survey_organization_id: survey.survey_organization.id,
          proband_id: getProband.probandID,
        }

        if (!edit_mode) {
          // send initial completed survey notification to clinician
          fetchCompletedSurveyNotification(notification_payload)
        }
      }

      if (!isOnboarding) {
        setShowCompletedScreen(true)
      }

      return onExitSurveyClicked()
    }

    navigation.push('CustomScreen', navigationPayload)
  }

  const saveCompletedSurvey = async (payload, pages) => {
    const surveyResponseData = await fetchSaveSurvey(payload)

    completedSurveyCallback(surveyResponseData, pages)
  }

  const setTimeoutBool = (set_timeout) => {
    timeoutWasSet = set_timeout
  }

  const previousPage = () => {
    const payload = {
      member_id: getProband.probandID,
      survey_id: selectedSurvey?.surveyID ?? currentScreenSelected(),
      status: 'in_progress',
    }

    if (current_page > 1) {
      const prev_page = current_page
      const new_page = current_page - 1

      setPageNumbers(new_page, prev_page)

      payload.page_num = prev_page

      saveCompletedSurvey(payload)
    }

    setTimeoutBool(false)
  }

  // NOTES: This function it is likely causing the issue on normal surveys to crash
  const surveyFoundCallback = (survey_data) => {
    if (!survey_data) return surveyNotFound()

    // NOTES: This setState requests make the renderHandler to run multiple times
    setSurvey(survey_data.survey)
    setCompletedSurveyAnswer(survey_data.completed_survey_answers)
    setProband(survey_data.proband)
    setProbandDiseases(survey_data.proband_diseases)
    setProbandDiseaseSkipLogic(survey_data.proband_disease_skip_logics)

    if (survey_data?.completed_survey?.page_num > 0 && nextPage === 0) {
      setCurrentPage(survey_data?.completed_survey?.page_num)
      setPreviousPage(survey_data?.completed_survey?.page_num)
      // NOTES: Maybe setStatus after detecting page_num is 1?
    }

    // debugger
    setStatus(states.FOUND)

    const isSurveyCompleted =
      survey_data?.completed_survey?.status === 'completed'

    if (nextPage !== prevPage || (nextPage === 0 && !isSurveyCompleted)) return

    setEditMode(isSurveyCompleted)

    setShowCompletedScreen(isSurveyCompleted)
  }

  const screenResizeHandler = useCallback(() => {
    setScrollViewHeight(DefaultFullHeight())
  }, [])

  const getClinicianId = async () => {
    if (getProband?.clinicianCode)
      return {
        clinician_code: getProband?.clinicianCode,
      }

    if (getProband?.clinician_code)
      return {
        clinician_code: getProband?.clinician_code,
      }

    if (!familyId) return false

    const familyClinicianPayload = {
      path: `family/${familyId}/clinicians/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'CustomScreen - index.js',
      },
    }

    const response = await ApiFetchHandler(familyClinicianPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      return false
    }

    if (response.length > 0) {
      const clinicianId = response?.[0]?.organization?.clinician_id
      return { clinician_id: clinicianId }
    }

    return null
  }

  const fetchClinicianCheck = async () => {
    const clinicianCode = await getClinicianId()

    if (!clinicianCode) return null

    const clinicianCheckPayload = {
      path: 'clinician/check/',
      method: 'post',
      token: authCredentials?.accessToken,
      body: { ...clinicianCode },
      pageDetails: {
        page: 'CustomScreen - index.js',
      },
    }

    const response = await ApiFetchHandler(clinicianCheckPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }

    return response?.clinician_code?.id
  }

  const getSurvey = async () => {
    const clinicianCodeId = await fetchClinicianCheck()

    const getSurveyPayload = {
      path: 'survey/get_survey/',
      method: 'get',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      params: {
        clinician_id: !clinicianCodeId ? undefined : clinicianCodeId,
        survey_id: selectedSurvey?.surveyID ?? currentScreenSelected(),
        proband_id: getProband?.probandID,
      },
    }

    const response = await ApiFetchHandler(getSurveyPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      return
    }

    surveyFoundCallback(response)
  }

  useEffect(() => {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', screenResizeHandler)
    }

    getSurvey()

    return () => {
      if (WEB === Platform.OS) {
        window.removeEventListener('resize', screenResizeHandler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSurvey])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }

    navigation.setOptions({
      title: survey.name,
    })

    navigation.setParams({ currentPage: current_page })

    if (!isOnboarding) return

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.survey_custom,
    }

    const saveScreen = async () => {
      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
      })
    }

    saveScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendUpdateSurveyNotification = async (payload) => {
    const getPayload = {
      path: 'member/send_notification_to_clinicians_for_updating_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await ApiFetchHandler(getPayload)

    if (response.isError) {
      setTimeout(() => {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }, 100)
    }
  }

  const completedSurveyAnswerCallback = async (
    completedSurveyAnswers,
    master_question_id
  ) => {
    // update completedSurveyAnswers with data returned
    if (!completedSurveyAnswers) return

    setCompletedSurveyAnswer(completedSurveyAnswers)

    // send notification about answer update
    if (master_question_id && edit_mode) {
      const notification_payload = {
        master_question_id,
        proband_id: proband.probandID,
      }

      await sendUpdateSurveyNotification(notification_payload)
    }
  }

  const fetchCreateSurveyAnswer = async (payload) => {
    const getPayload = {
      path: 'completed_survey_answer/create_completed_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await ApiFetchHandler(getPayload)

    if (response.isError) {
      setTimeout(() => {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }, 100)

      return
    }

    completedSurveyAnswerCallback(response, payload.master_question_id)
  }

  const fetchDeleteSurveyAnswer = async (payload) => {
    const getPayload = {
      path: 'completed_survey_answer/delete_completed_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await ApiFetchHandler(getPayload)

    if (response.isError) {
      setTimeout(() => {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }, 100)

      return
    }

    completedSurveyAnswerCallback(response, payload.master_question_id)
  }

  const fetchUpdateSurveyAnswer = async (payload) => {
    const getPayload = {
      path: 'completed_survey_answer/update_completed_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await ApiFetchHandler(getPayload)

    if (response.isError) {
      setTimeout(() => {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }, 100)

      return
    }

    completedSurveyAnswerCallback(response, payload.master_question_id)
  }

  // FIXME: Remove nested if conditions
  const saveCompletedSurveyAnswer = (payload) => {
    payload.member_id = proband.id

    // trim the string answer from the user
    if (typeof payload.answer === 'string')
      payload.answer = payload.answer.trim()

    // lookup to see if the user has a CompletedSurveyAnswer record already by looking in the completed_survey_answers
    let answers = completed_survey_answers.filter(
      (answer) =>
        parseInt(payload.master_question_id, 10) ===
        parseInt(answer.master_question_id, 10)
    )

    const headers = { accessToken: authCredentials.accessToken }

    if (payload.field_type && payload.field_type === 'select_all_that_apply') {
      // use the master_question_choice_id to narrow it down to see if there is already a completed survey answer
      // if no answer was found then create a new one with master_question_choice_id
      answers = answers.filter(
        (answer) =>
          parseInt(answer.master_question_choice_id, 10) ===
          parseInt(payload.master_question_choice_id, 10)
      )

      if (answers.length === 0) {
        // no previous answer was found so hit the create completed survey answer endpoint
        fetchCreateSurveyAnswer(payload)
      } else if (answers.length === 1) {
        // unchecking the question choice, delete the answer
        payload.id = answers[0].id
        fetchDeleteSurveyAnswer(payload)
      }
    } else if (
      payload.field_type &&
      (payload.field_type === 'select_one' ||
        payload.field_type === 'yes_or_no')
    ) {
      if (payload.repeat_click) {
        // unchecking the question choice, delete the answer
        if (answers.length === 1) {
          payload.id = answers[0].id
          fetchDeleteSurveyAnswer(payload, headers)
        }
      } else if (answers.length === 1) {
        // they already have an answer, hit the update completed survey answer endpoint
        payload.id = answers[0].id
        fetchUpdateSurveyAnswer(payload, headers)
      } else if (answers.length > 1) {
        console.log(
          'Error: Should only have multiple answers if question type Select All That Apply'
        )
      } else {
        // no previous answer was found so hit the create completed survey answer endpoint
        fetchCreateSurveyAnswer(payload, headers)
      }
    } else if (answers.length === 1) {
      // they already have an answer, hit the update completed survey answer endpoint
      payload.id = answers[0].id
      fetchUpdateSurveyAnswer(payload, headers)
    } else if (answers.length > 1) {
      console.log(
        'Error: Should only have multiple answers if question type Select All That Apply'
      )
    } else {
      // no previous answer was found so hit the create completed survey answer endpoint
      fetchCreateSurveyAnswer(payload, headers)
    }
  }

  const inDeletesInProgress = (id) => deletes_in_progress.includes(id)

  const addToDeletesInProgress = (id) => {
    deletes_in_progress.push(id)
  }

  const deleteCompletedSurveyAnswer = (answer) => {
    if (inDeletesInProgress(answer.id)) return

    addToDeletesInProgress(answer.id)

    const headers = { accessToken: authCredentials.accessToken }

    const payload = {
      id: answer.id,
      master_question_id: answer.master_question_id,
    }

    fetchDeleteSurveyAnswer(payload, headers)
  }

  // FIXME: Remove nested if conditions
  const nextPageHandler = async (blankPages = null, currentPage = null) => {
    const payload = {
      member_id: getProband.probandID,
      survey_id: selectedSurvey?.surveyID ?? currentScreenSelected(),
      status: 'in_progress',
    }

    const newCurrentPage = currentPage ?? current_page
    const isLastPage = newCurrentPage === survey.total_pages

    if (isLastPage) {
      payload.status = 'completed'
      payload.page_num = newCurrentPage

      saveCompletedSurvey(payload)

      // edit_mode is false at the moment of completing again an already completed survey
      if (survey?.survey_organization?.id && !edit_mode) {
        const notification_payload = {
          survey_organization_id: survey.survey_organization.id,
          proband_id: getProband.probandID,
        }

        // send initial completed survey notification to clinician
        fetchCompletedSurveyNotification(notification_payload)
      }

      setShowCompletedScreen(true)
    } else {
      // debugger
      // eslint-disable-next-line no-shadow
      const previous_page = newCurrentPage
      const next_page = newCurrentPage + 1
      const pages = blankPages ?? {
        previous_page,
        next_page,
      }

      payload.page_num = next_page
      saveCompletedSurvey(payload, pages)
    }

    setTimeoutBool(false)

    // debugger
  }

  // NOTES: This function it is likely causing the issue on normal surveys to crash
  const blankPageHandler = (pageBuilderPayload) => {
    const survey_page_builder = PageBuilder(pageBuilderPayload)

    const survey_field_canvas = survey_page_builder.startBuildPage()

    const content = survey_field_canvas.filter((el) => el)

    const pages = {
      previous_page: pageBuilderPayload.current_page - 1,
      next_page: pageBuilderPayload.current_page,
    }

    // debugger
    if (pageBuilderPayload.current_page >= survey.total_pages) {
      return nextPageHandler(pages, pageBuilderPayload.current_page)
    }

    // NOTES: This condition make use of recursion, in other words it calls this function itsel
    if (content.length === 1) {
      const modifiedPayload = {
        ...pageBuilderPayload,
        current_page: pageBuilderPayload.current_page + 1,
      }

      blankPageHandler(modifiedPayload)
    } else {
      // NOTES: It enters here after pressing `next` button on page 1 of survey, when the `completed_survey.status` from API call is 'not_started` or `in_progress`
      // NOTES: It enters here multiple times, it seems this is an infinite cycle we need to break with a certain conditions
      // NOTES: Sending here `null` seems to fix the issue of the infinite cycle
      nextPageHandler(null, pageBuilderPayload.current_page)
    }
  }

  // NOTES: This function it is likely causing the issue on normal surveys to crash
  const customPageBuilder = () => {
    // NOTES: This line fixes a glitch of rendering/showing the SucessScreen multiple times
    if (!survey.name) return null

    let percent_complete = 0

    if (current_page <= survey.total_pages) {
      percent_complete = (current_page / survey.total_pages).toFixed(2)
      percent_complete *= 100
    }

    const done_translation = i18n.t('survey_btn_label_done')
      ? i18n.t('done').default
      : 'Done'

    const next_translation = i18n.t('survey_btn_label_next')
      ? i18n.t('next').default
      : 'Next'

    const btn_label =
      current_page === survey.total_pages ? done_translation : next_translation

    const translation_key = `${survey?.translation_key}.name`
    let survey_name_translation = i18n.t(translation_key)

    if (
      survey_name_translation === translation_key ||
      !survey_name_translation
    ) {
      survey_name_translation = survey?.name
    }

    const pageBuilderPayload = {
      current_page,
      survey,
      proband,
      proband_diseases,
      proband_disease_skip_logics,
      completed_survey_answers,
      saveCompletedSurveyAnswer,
      deleteCompletedSurveyAnswer,
      colorSettings,
    }

    const survey_page_builder = PageBuilder(pageBuilderPayload)

    const survey_field_canvas = survey_page_builder.startBuildPage()

    const content = survey_field_canvas.filter((el) => el)

    // debugger

    if (content.length === 0 && !timeoutWasSet) {
      // NOTES: It enters here when the `completed_survey.status` from API call is 'not_started` or `in_progress` and `page_num` is 0
      // NOTES: It also enteres on 'not_started` or `in_progress` with `page_num` 1 and clicking `next` button on survey

      // NOTES: It is not entering when the `completed_survey.status` from API call is 'completed`

      if (current_page >= previous_page) {
        // NOTES: It enters here when the `completed_survey.status` from API call is 'not_started` or `in_progress` and `page_num` is 0 on first mounting

        // NOTES: It also enters having `page_num` as 1 in 'not_started` or `in_progress` state and clicking `next` button on survey
        // NOTES: It also enters having `page_num` as 2 (or more) in 'not_started` or `in_progress`

        // NOTES: It seems the blankPageHandler needs to be executed, if not after clicking `next` button on survey, a blank page is shown
        return blankPageHandler(pageBuilderPayload)
      }

      previousPage()
    }

    const page_content = (
      <>
        <ScrollView
          style={{
            height: scrollViewHeight,
            backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
          }}
        >
          <FlexContainer style={styles.flexContainer}>
            {survey_field_canvas}
          </FlexContainer>
        </ScrollView>

        <ButtonDone
          title={btn_label}
          onPress={nextPageHandler}
          colorSettings={colorSettings}
        />
      </>
    )

    // debugger

    return page_content
  }

  const editBtnClicked = () => {
    setCurrentPage(1)
    setPreviousPage(1)
    setShowCompletedScreen(false)
  }

  // NOTES: This function it is likely causing the issue on normal surveys to crash
  const renderingHandler = () => {
    if (status === states.RENDERING) {
      return <LoadingView />
    }

    if (status !== states.FOUND) return <View />

    // debugger
    if (!show_completed_screen) return customPageBuilder()

    if (!isOnboarding) {
      return (
        <FlexContainer style={styles.innerContainer}>
          <SurveyCompleteScreen
            colorSettings={colorSettings}
            editBtnClicked={editBtnClicked}
            onExitSurveyClicked={onExitSurveyClicked}
          />
        </FlexContainer>
      )
    }

    if (!shouldExitSurvey.current) {
      shouldExitSurvey.current = true
      onExitSurveyClicked()
    }
  }

  return (
    <ErrorBoundary>
      <MainWrapper navigation={navigation}>
        <KeyboardAvoidingView
          behavior="padding"
          style={styles.innerContainer}
          enabled
        >
          <SafeAreaView
            style={[
              styles.innerContainer,
              {
                backgroundColor: colorSettings?.onboarding_bgcolor ?? 'white',
              },
            ]}
          >
            {renderingHandler()}
          </SafeAreaView>
        </KeyboardAvoidingView>
      </MainWrapper>
    </ErrorBoundary>
  )
}

export default CustomInputScreen
