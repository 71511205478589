import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  ProgressViewIOS,
  TextInput,
  Image,
  Modal,
} from 'react-native'
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from 'accordion-collapse-react-native'

import SkipLogic from '$data_models/SkipLogic.js'

import { i18n } from '$localization/config.js'

export default class SelectButton extends Component {
  data = this.props.data

  parent = this.props.prop

  newSkipList = this.props.skipList

  constructor(props) {
    super(props)

    this.state = {
      selectOption: false,
      collapse: false,
      diseaseData: this.data,
    }
  }

  skipLogic = []

  // state= {
  //  selectOption: false,
  //  collapse: false,
  //  diseaseData: this.data
  // }

  _onSelect = () => {
    if (this.state.selectOption) {
      this.setState((prev) => ({
        selectOption: !prev.selectOption,
      }))
      this.parent.removeDisease(this.data.id)
    }

    this.parent.navigateTo(this.state.selectOption, this.data, this)
    // this.parent.selectedDisease(this.data.id, this.state.selectOption)
    // this.parent.diseaseList (this.data.id, this.state.selectOption)

    // this.shouldAddDisease(!this.state.selectOption, this.state.diseaseData)
  }

  toggleSelect = () => {
    const { id } = this.data
    const skipList = this.newSkipList.skip_logic_list
    this.setState({ selectOption: true })

    this.skipLogic.length = 0

    for (const val in skipList) {
      if (skipList[val].disease_list_id == id) {
        this.skipLogic.push(skipList[val])
      }
    }
  }

  filterQuestion = (question) => {
    const n = question.includes('Was there')
    const m = question.includes('Was it')
    let newQuestion = ''

    if (n) {
      newQuestion = question.replace('Was there', '')
      newQuestion = newQuestion.slice(0, -1)
      newQuestion = newQuestion.trimLeft()
      newQuestion = newQuestion.charAt(0).toUpperCase() + newQuestion.slice(1)
      return newQuestion
    }
    if (m) {
      newQuestion = question.replace('Was it', '')
      newQuestion = newQuestion.slice(0, -1)
      newQuestion = newQuestion.trimLeft()
      newQuestion = newQuestion.charAt(0).toUpperCase() + newQuestion.slice(1)
      return newQuestion
    }
    return question
  }

  renderSkipLogic = () => {
    const diseaseList = []

    const { skipLogic } = this
    const disease = this.newSkipList.diseases_list
    const { id } = this.data

    for (const val in disease) {
      if (disease[val].id == id) {
        diseaseList.push(this.diseaseAOD(disease[val].age_diagnosed))
      }
    }

    for (const val in skipLogic) {
      const question = this.filterQuestion(skipLogic[val].question)

      const age = skipLogic[val].answer
      const qType = skipLogic[val].question_type

      diseaseList.push(
        <View style={{ flexDirection: 'row', marginBottom: 16 }}>
          <Text style={styles.questionTitle}>
            {question}
            {qType == 'yes_or_no_then_number' ? (
              <Text style={styles.AOD}>{`, ${age} y/o`}</Text>
            ) : (
              ''
            )}
          </Text>
          <TouchableOpacity
            style={{ flex: 0.3 }}
            onPress={() => {
              this.parent.navigateTo(
                !this.state.selectOption,
                this.data,
                this,
                true
              )
            }}
          >
            <Text style={styles.editButton}>{i18n.t('edit').default}</Text>
          </TouchableOpacity>
        </View>
      )
    }

    return diseaseList
  }

  diseaseAOD = (age) => (
    <View style={{ flexDirection: 'row', marginBottom: 16 }}>
      <Text style={styles.questionTitle}>
        {`${i18n.t('age_of_diagnosis').default}: `}
        <Text style={styles.AOD}>{`${age} y/o`}</Text>
      </Text>
      <TouchableOpacity
        style={{ flex: 0.3 }}
        onPress={() => {
          this.parent.navigateTo(
            !this.state.selectOption,
            this.data,
            this,
            true
          )
        }}
      >
        <Text style={styles.editButton}>{i18n.t('edit').default}</Text>
      </TouchableOpacity>
    </View>
  )

  render() {
    return (
      <Collapse style={{ width: '100%' }} isCollapsed={this.state.selectOption}>
        <CollapseHeader>
          <TouchableOpacity
            style={[
              styles.buttonSelect,
              this.state.selectOption
                ? { backgroundColor: 'rgba(245,245,245,.5)', borderWidth: 0 }
                : null,
            ]}
            onPress={this._onSelect}
            activeOpacity={1}
          >
            <Text style={styles.selectButtonText}>{this.props.buttonName}</Text>
          </TouchableOpacity>
        </CollapseHeader>
        <CollapseBody>
          <View style={styles.mainContainer}>{this.renderSkipLogic()}</View>
        </CollapseBody>
      </Collapse>
    )
  }
}

const styles = StyleSheet.create({
  buttonSelect: {
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,.5)',
    height: 60,
    marginBottom: 10,
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  polypsMainContainer: {
    width: '100%',
    marginTop: 10,
  },
  editSkipLogic: {
    color: '#fff',
    textAlign: 'right',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
  },
  skipLogicName: {
    fontFamily: 'montserrat-medium',
    flex: 2,
    fontSize: 13,
    color: '#fff',
  },
  mainContainer: {
    marginBottom: 17,
    marginTop: 14,
    marginHorizontal: 18,
  },
  questionTitle: {
    flex: 1,
    fontFamily: 'montserrat',
    fontSize: 13,
    color: '#fff',
  },
  AOD: {
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
  },
  editButton: {
    textAlign: 'right',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: '#fff',
  },
})
