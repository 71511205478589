import { useState, useEffect } from 'react'
import {
  View,
  Modal,
  SafeAreaView,
  Platform,
  Text,
  TouchableOpacity,
  Appearance,
} from 'react-native'

import moment from 'moment'
import DateTimePicker from '@react-native-community/datetimepicker'

import { i18n, translations, locale } from '$localization/config.js'
import { IOS } from '$constants/Platforms.js'

import { fixedTimeZoneOffset } from '$api/_utils/Utils.js'

import global_styles from '$constants/styles/global.styles.js'
import styles from './styles/main.styles'

const timezoneOffset = fixedTimeZoneOffset()

export default function CustomDatePicker({
  colorSettings,
  onDatePickerChange,
  selectedLanguage,
  maxDate: propMaxDate,
  minDate: propMinDate,
  date: propDate,
  visible: propVisible,
}) {
  const minYearsFromNow = 184
  const androidMinDate = new Date(1840, 0, 1)

  const formattedPropDate = propDate ? new Date(propDate) : new Date()
  const formattedPropMaxDate = propMaxDate ?? new Date()
  const formattedPropMinDate = propMinDate ?? new Date()

  /* Limit maxDate to current date */
  const [maxDate, setMaxDate] = useState(formattedPropMaxDate)
  /* Limit minDate to N years from maxDate */
  const [minDate, setMinDate] = useState(formattedPropMinDate)

  const [date, setDate] = useState(formattedPropDate)
  const [visible, setVisible] = useState(propVisible)
  const [showPickerModal, setShowPickerModal] = useState(false)
  const [colorScheme, setColorScheme] = useState('light')

  let localSelectedDate = date

  useEffect(() => {
    setVisible(propVisible)
  }, [propVisible])

  useEffect(() => {
    const formattedCurrentDate = moment(date).format('YYYY-MM-DD')
    const formattedMaxDate = moment(maxDate).format('YYYY-MM-DD')

    const offsetCurrentDate = moment(new Date(formattedCurrentDate))
      .add(timezoneOffset, 'hours')
      .add(1, 'days')

    const offsetMaxDate = moment(new Date(formattedMaxDate)).add(
      timezoneOffset,
      'hours'
    )

    const formattedMminDate = moment(new Date(offsetMaxDate)).subtract(
      minYearsFromNow,
      'years'
    )

    setDate(new Date(offsetCurrentDate))
    setMaxDate(new Date(offsetMaxDate))
    setMinDate(new Date(formattedMminDate))
  }, [])

  const getColorScheme = () => {
    const bgAppearance = Appearance.getColorScheme()
    if (bgAppearance !== 'no-preference' || bgAppearance !== null) {
      return bgAppearance
    }
    return colorScheme
  }

  useEffect(() => {
    const bgAppearance = getColorScheme()
    setColorScheme(bgAppearance)

    setTimeout(() => {
      setShowPickerModal(true)
    }, 100)
  }, [])

  const onChangeAndroid = (e, selectedDate) => {
    /* For Android only */
    if (e.type === 'set') {
      const adjustedDate = selectedDate
      onDatePickerChange({ selectedDate: adjustedDate, dismiss: true })
    } else {
      onDatePickerChange({ selectedDate: null, dismiss: true })
    }
  }

  const onChangeIOS = (e, selectedDate) => {
    /* For iOS only */
    localSelectedDate = selectedDate ?? new Date(0)

    // Keep selected date from being less than minDate.
    const dateDiff = moment(minDate).diff(localSelectedDate, 'minutes')
    if (dateDiff > 0) localSelectedDate = minDate

    setDate(localSelectedDate)
  }

  const onChange = (e, selectedDate, platformOS) => {
    if (platformOS === 'ios') {
      onChangeIOS(e, selectedDate)
    } else if (platformOS === 'android') {
      onChangeAndroid(e, selectedDate)
    } else {
      onDatePickerChange({ selectedDate, dismiss: true })
    }
  }

  const onDoneIOS = () => {
    /* For iOS only */
    onDatePickerChange({ selectedDate: localSelectedDate, dismiss: true })
  }

  const onCancelIOS = () => {
    /* For iOS only */
    onDatePickerChange({ selectedDate: null, dismiss: true })
  }

  const handleLocalization = () => {
    const supportedLanguages = Object.keys(translations)
    let defaultLanguage = 'en'

    for (const language of supportedLanguages) {
      if (selectedLanguage === language) {
        defaultLanguage = selectedLanguage
      }
    }
    return defaultLanguage
  }

  if (!visible) return null

  return (
    <SafeAreaView style={styles.container}>
      {Platform.OS === 'ios' && (
        <Modal animationType="slide" visible={showPickerModal} transparent>
          {Platform.OS === IOS && (
            <TouchableOpacity
              onPress={() => onCancelIOS()}
              activeOpacity={1.0}
              style={{ flex: 1.0 }}
            />
          )}

          <View
            style={[
              styles.pickerContainer,
              {
                backgroundColor: getColorScheme() === 'dark' ? '#000' : '#fff',
              },
            ]}
          >
            <View style={styles.toolbarContainer}>
              <TouchableOpacity
                style={[styles.toolbarButton, { flex: 20 }]}
                onPress={() => onCancelIOS()}
              >
                <Text style={styles.toolbarButtonText}>
                  {i18n.t('cancel').title}
                </Text>
              </TouchableOpacity>

              <Text
                style={[
                  global_styles.headerBarMainTitle,
                  {
                    flex: 60,
                    fontSize: 15,
                  },
                ]}
              >
                {i18n.t('select_date').title}
              </Text>

              <TouchableOpacity
                style={[styles.toolbarButton, { flex: 20 }]}
                onPress={() => onDoneIOS()}
              >
                <Text
                  style={[
                    styles.toolbarButtonText,
                    {
                      color: colorSettings?.bottom_next_btn_enabled || '#000',
                    },
                  ]}
                >
                  {i18n.t('done').title}
                </Text>
              </TouchableOpacity>
            </View>

            {/* Note: Do not use 'timeZoneOffsetInMinutes' */}
            <DateTimePicker
              minimumDate={minDate}
              maximumDate={maxDate}
              mode="date"
              display="spinner"
              onChange={onChangeIOS}
              value={date}
              locale={selectedLanguage}
            />
          </View>
        </Modal>
      )}
      {Platform.OS === 'android' && (
        <DateTimePicker
          minimumDate={androidMinDate}
          maximumDate={maxDate}
          mode="date"
          display="spinner"
          onChange={onChangeAndroid}
          value={date}
          locale={selectedLanguage}
        />
      )}
    </SafeAreaView>
  )
}
