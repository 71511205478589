import { Text, View, Modal, StyleSheet } from 'react-native'
import LoadingView from '$constants/LoadingView.js'

const ModalLoadingView = ({ colorSettings, isVisible, loadingTitle }) => (
    <Modal visible={isVisible} animationType="none" transparent>
      <View style={styles.loadingContainer}>
        <LoadingView
          backgroundColor={colorSettings?.onboarding_bgcolor}
          tintColor={colorSettings?.btn_no_fill_text_2}
          textColor={colorSettings?.btn_no_fill_text_2}
          visible={isVisible}
          message={loadingTitle}
        >
          {/* message={i18n.t('saving_datanplease_wait').default}> */}
        </LoadingView>
      </View>
    </Modal>
  )

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1.0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default ModalLoadingView
