import React, { Component } from 'react'
import { View, Text } from 'react-native'
import { cloneDeep } from 'lodash'
import { i18n } from '../../../localization/config'

import uuid from 'react-native-uuid'

import {
  MultiSelectField,
  SingleSelectField,
  NumberField,
  TextShort,
  DateField,
  YesOrNoField,
  OptionButton,
  LabelField,
  ToggleField,
  TextLong,
} from '../components'

const field_types = {
  LABEL: 'label',
  TEXT_SHORT: 'text_short',
  TEXT_LONG: 'text_long',
  INTEGER: 'integer',
  FLOAT: 'float',
  YES_OR_NO: 'yes_or_no',
  SELECT_ALL_THAT_APPLY: 'select_all_that_apply',
  SELECT_ONE: 'select_one',
  PHONE: 'phone',
  LONG_LIST_SEARCH: 'long_list_search',
  DATE: 'date',
  TOGGLE: 'toggle',
}

// TODO: refactor to improve and optimize code

function buildPage(surveyObject) {
  const survey = { ...surveyObject.survey }

  // get the categories that belong to the current page
  const page_categories = survey.survey_categories
    .filter((e) => e.page_num === surveyObject.current_page)
    .sort((a, b) => a.order - b.order)

  // loop through the page categories and build the page with the components
  let page_content = []

  page_categories.forEach((category) => {
    const category_content = renderCategory(category, surveyObject)

    const content = category_content.filter((el) => el)
    if (content.length > 0) {
      if (category.category_label) {
        const category_label_translation = i18n.t(
          category.translation_key + '.label'
        )

        page_content.push(
          <React.Fragment key={uuid.v4()}>
            <View key={uuid.v4()}>
              <Text>{category_label_translation}</Text>
            </View>
            {content}
          </React.Fragment>
        )
      } else {
        page_content.push(content)
      }
    }
  })

  return page_content
}

function checkPrerequisites(master_question, surveyObj) {
  const { completed_survey_answers, survey } = surveyObj
  for (const prerequisite of master_question.question_prerequisites) {
    const master_question_prerequisite_id = parseInt(
      prerequisite.master_question_prerequisite_id,
      10
    )

    const prerequisite_answers = completed_survey_answers.filter((answer) => {
      return (
        parseInt(answer.master_question_id, 10) ===
        master_question_prerequisite_id
      )
    })

    if (prerequisite_answers.length === 0) {
      // if they don't have any answers then fail and return
      return false
    }

    if (prerequisite_answers.length > 1) {
      let selectAllAnswerArray = []
      selectAllAnswerArray = createSelectAllAnswersArrray(
        prerequisite_answers,
        master_question_prerequisite_id,
        surveyObj
      )

      //for skip logics with select all field
      //Add all the answers to an array and check if pre req field is included in answers array
      //Only time length would be > 1 would be if for select all field which uses the == operator

      if (selectAllAnswerArray.length === 0) {
        return false
      }

      if (prerequisite.operator === '==') {
        if (!selectAllAnswerArray.includes(prerequisite.value)) {
          return false
        }
      } else if (prerequisite.operator === 'IN') {
        if (typeof prerequisite.value === 'string') {
          // prerequisite_answer is the probands answer for the question
          // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
          const values_array = prerequisite.value.split(',')
          for (var i = 0; i < selectAllAnswerArray.length; i++) {
            if (values_array.includes(selectAllAnswerArray[i])) {
              return true
            }
          }
          return false
        } else {
          // for some reason the prerequisite value is not a string
          // that can be split into an array
          return false
        }
      } else if (prerequisite.operator === 'NOT IN') {
        if (typeof prerequisite.value === 'string') {
          // prerequisite_answer is the probands answer for the question
          // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
          const values_array = prerequisite.value.split(',')
          for (var i = 0; i < selectAllAnswerArray.length; i++) {
            if (values_array.includes(selectAllAnswerArray[i])) {
              return false
            }
          }
        } else {
          // for some reason the prerequisite value is not a string
          // that can be split into an array
          return false
        }
      } else {
        // prerequisite operator does not equal one that we recognize
        return false
      }
    }

    if (prerequisite_answers.length === 1) {
      const prerequisite_answer = prerequisite_answers[0]

      let answer = null
      if (prerequisite_answer.master_question_choice_id) {
        const master_question_choice_id = parseInt(
          prerequisite_answer.master_question_choice_id,
          10
        )

        // get choices for master_question_prerequisite_id
        let break_loops = false
        for (const cat of survey.survey_categories) {
          for (const question_workflow of cat.question_workflows) {
            if (
              master_question_prerequisite_id ===
              parseInt(question_workflow.master_question.id, 10)
            ) {
              const choices =
                question_workflow.master_question.master_question_choices

              answer = choices.find((c) => {
                return parseInt(c.id, 10) === master_question_choice_id
              })

              if (answer) {
                answer = answer.value
                break_loops = true
                break
              }
            }
          }

          if (break_loops) break
        }
      } else {
        answer = prerequisite_answer.answer
      }

      if (!answer) {
        return false
      }

      if (prerequisite.operator === '==') {
        if (answer !== prerequisite.value) {
          return false
        }
      } else if (prerequisite.operator === '!=') {
        if (answer === prerequisite.value) {
          return false
        }
      } else if (prerequisite.operator === '>') {
        if (parseInt(answer, 10) <= parseInt(prerequisite.value, 10)) {
          return false
        }
      } else if (prerequisite.operator === '>=') {
        if (parseInt(answer, 10) < parseInt(prerequisite.value, 10)) {
          return false
        }
      } else if (prerequisite.operator === '<') {
        if (parseInt(answer, 10) >= parseInt(prerequisite.value, 10)) {
          return false
        }
      } else if (prerequisite.operator === '<=') {
        if (parseInt(answer, 10) > parseInt(prerequisite.value, 10)) {
          return false
        }
      } else if (prerequisite.operator === 'IN') {
        if (typeof prerequisite.value === 'string') {
          // prerequisite_answer is the probands answer for the question
          // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
          const values_array = prerequisite.value.split(',')
          if (!values_array.includes(answer)) {
            return false
          }
        } else {
          // for some reason the prerequisite value is not a string
          // that can be split into an array
          return false
        }
      } else if (prerequisite.operator === 'NOT IN') {
        if (typeof prerequisite.value === 'string') {
          // prerequisite_answer is the probands answer for the question
          // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
          const values_array = prerequisite.value.split(',')
          if (values_array.includes(answer)) {
            return false
          }
        } else {
          // for some reason the prerequisite value is not a string
          // that can be split into an array
          return false
        }
      } else {
        // prerequisite operator does not equal one that we recognize
        return false
      }
    } // end prerequisite answers loop
  } // end question prerequisites loop

  return true
}

function checkMemberPrerequisites(master_question, surveyObj) {
  const { proband } = surveyObj
  for (const prerequisite of master_question.member_question_prerequisites) {
    let member_field_value = proband[prerequisite.member_field]

    if (typeof member_field_value === 'string') {
      // do nothing
    } else if (typeof member_field_value === 'number') {
      member_field_value += ''
    } else if (typeof member_field_value === 'boolean') {
      if (member_field_value) {
        member_field_value = 'true'
      } else {
        member_field_value = 'false'
      }
    }

    if (!member_field_value) {
      return false
    }

    if (prerequisite.operator === '==') {
      if (member_field_value !== prerequisite.value) {
        return false
      }
    } else if (prerequisite.operator === '!=') {
      if (member_field_value === prerequisite.value) {
        return false
      }
    } else if (prerequisite.operator === '>') {
      if (
        parseInt(member_field_value, 10) <= parseInt(prerequisite.value, 10)
      ) {
        return false
      }
    } else if (prerequisite.operator === '>=') {
      if (parseInt(member_field_value, 10) < parseInt(prerequisite.value, 10)) {
        return false
      }
    } else if (prerequisite.operator === '<') {
      if (
        parseInt(member_field_value, 10) >= parseInt(prerequisite.value, 10)
      ) {
        return false
      }
    } else if (prerequisite.operator === '<=') {
      if (parseInt(member_field_value, 10) > parseInt(prerequisite.value, 10)) {
        return false
      }
    } else if (prerequisite.operator === 'IN') {
      if (typeof prerequisite.value === 'string') {
        const temp_values = prerequisite.value.split(',')
        const values_array = temp_values.map((v) => v.trim())
        if (!values_array.includes(member_field_value)) {
          return false
        }
      } else {
        return false
      }
    } else if (prerequisite.operator === 'NOT IN') {
      if (typeof prerequisite.value === 'string') {
        const temp_values = prerequisite.value.split(',')
        const values_array = temp_values.map((v) => v.trim())
        if (values_array.includes(member_field_value)) {
          return false
        }
      } else {
        return false
      }
    } else {
      // prerequisite operator does not equal one that we recognize
      return false
    }
  } // end member prerequisites loop

  return true
}

function checkDiseasePrerequisites(master_question, surveyObj) {
  const { proband_diseases, proband_disease_skip_logics } = surveyObj

  for (const prerequisite of master_question.disease_question_prerequisites) {
    const prerequisite_disease_id = parseInt(prerequisite.disease_id, 10)
    const prerequisite_age_diagnosed = prerequisite.age_diagnosed
    const prerequisite_operator = prerequisite.operator

    let found_disease = null
    found_disease = proband_diseases.find((disease) => {
      return parseInt(disease.disease_id, 10) === prerequisite_disease_id
    })

    if (!found_disease) return false

    // check and see if prerequisite_age_diagnosed is set
    // if not then return true its only checking if the member had/has disease
    if (!prerequisite_age_diagnosed) {
      return true
    }

    const age_diagnosed = found_disease.age_diagnosed
    if (prerequisite_operator === '==') {
      // even if this is an integer its saved as varchar in DB
      // so we can compare it as is
      if (age_diagnosed !== prerequisite_age_diagnosed) {
        return false
      }
    } else if (prerequisite_operator === '!=') {
      // even if this is an integer its saved as varchar in DB
      // so we can compare it as is
      if (age_diagnosed === prerequisite_age_diagnosed) {
        return false
      }
    } else if (prerequisite_operator === '>') {
      if (parseInt(age_diagnosed, 10) <= parseInt(prerequisite_age_diagnosed)) {
        return false
      }
    } else if (prerequisite_operator === '>=') {
      if (parseInt(age_diagnosed, 10) < parseInt(prerequisite_age_diagnosed)) {
        return false
      }
    } else if (prerequisite_operator === '<') {
      if (parseInt(age_diagnosed, 10) >= parseInt(prerequisite_age_diagnosed)) {
        return false
      }
    } else if (prerequisite_operator === '<=') {
      if (parseInt(age_diagnosed, 10) > parseInt(prerequisite_age_diagnosed)) {
        return false
      }
    } else if (prerequisite_operator === 'IN') {
      if (typeof prerequisite_age_diagnosed === 'string') {
        const temp_values = prerequisite_age_diagnosed.split(',')
        const values_array = temp_values.map((v) => v.trim())

        // if value not in array then test has failed
        if (!values_array.includes(age_diagnosed)) {
          return false
        }
      } else {
        return false
      }
    } else if (prerequisite_operator === 'NOT IN') {
      if (typeof prerequisite_age_diagnosed === 'string') {
        const temp_values = prerequisite_age_diagnosed.split(',')
        const values_array = temp_values.map((v) => v.trim())

        // if value in array then test has failed
        if (values_array.includes(age_diagnosed)) {
          return false
        }
      } else {
        return false
      }
    } else {
      // prerequisite operator does not equal one that we recognize
      return false
    }

    const skip_logics = prerequisite.disease_skip_logic_question_prerequisites
    if (skip_logics.length > 0) {
      // loop through skip logics and test the criteria
      for (let skip_logic of skip_logics) {
        let found_skip_logic = null
        found_skip_logic = proband_disease_skip_logics.find((sl) => {
          return (
            parseInt(sl.skip_logic_id, 10) ===
            parseInt(skip_logic.skip_logic_id, 10)
          )
        })

        if (!found_skip_logic) {
          return false
        }

        // check skip logic criteria
        if (skip_logic.operator === '==') {
          if (found_skip_logic.answer !== skip_logic.skip_logic_answer) {
            return false
          }
        } else if (skip_logic.operator === '!=') {
          if (found_skip_logic.answer === skip_logic.skip_logic_answer) {
            return false
          }
        } else if (skip_logic.operator === '>') {
          if (
            parseInt(found_skip_logic.answer, 10) <=
            parseInt(skip_logic.skip_logic_answer, 10)
          ) {
            return false
          }
        } else if (skip_logic.operator === '>=') {
          if (
            parseInt(found_skip_logic.answer, 10) <
            parseInt(skip_logic.skip_logic_answer, 10)
          ) {
            return false
          }
        } else if (skip_logic.operator === '<') {
          if (
            parseInt(found_skip_logic.answer, 10) >=
            parseInt(skip_logic.skip_logic_answer, 10)
          ) {
            return false
          }
        } else if (skip_logic.operator === '<=') {
          if (
            parseInt(found_skip_logic.answer, 10) >
            parseInt(skip_logic.skip_logic_answer, 10)
          ) {
            return false
          }
        } else if (skip_logic.operator === 'IN') {
          if (typeof skip_logic.skip_logic_answer === 'string') {
            const temp_values = skip_logic.skip_logic_answer.split(',')
            const values_array = temp_values.map((v) => v.trim())

            // if value not in array then test has failed
            if (!values_array.includes(found_skip_logic.answer)) {
              return false
            }
          } else {
            return false
          }
        } else if (skip_logic.operator === 'NOT IN') {
          if (typeof skip_logic.skip_logic_answer === 'string') {
            const temp_values = skip_logic.skip_logic_answer.split(',')
            const values_array = temp_values.map((v) => v.trim())

            // if value in array then test has failed
            if (values_array.includes(found_skip_logic.answer)) {
              return false
            }
          } else {
            return false
          }
        } else {
          // prerequisite operator does not equal one that we recognize
          return false
        }
      } // end skip logic loop
    } // end skip logic if statement
  } // end disease prerequisites loop

  return true
}

function renderField(master_question, surveyObj) {
  const {
    completed_survey_answers,
    deleteCompletedSurveyAnswer,
    saveCompletedSurveyAnswer,
    colorSettings,
  } = surveyObj
  const answers = completed_survey_answers.filter(
    (answer) =>
      parseInt(answer.master_question_id, 10) ===
      parseInt(master_question.id, 10)
  )

  let did_pass_question_prerequisites = checkPrerequisites(
    master_question,
    surveyObj
  )
  let did_pass_member_question_prerequisites = checkMemberPrerequisites(
    master_question,
    surveyObj
  )
  let did_pass_disease_question_prerequisites = checkDiseasePrerequisites(
    master_question,
    surveyObj
  )

  if (
    !did_pass_question_prerequisites ||
    !did_pass_member_question_prerequisites ||
    !did_pass_disease_question_prerequisites
  ) {
    // delete all the answers since this field is no longer triggered

    answers.forEach((answer) => {
      deleteCompletedSurveyAnswer(answer, surveyObj)
    })
    return null
  }

  const field_type = master_question.type

  let returnedFieldType

  switch (field_type) {
    case field_types.LABEL:
      returnedFieldType = (
        <LabelField
          key={uuid.v4()}
          masterQuestion={master_question}
          colorSettings={colorSettings}
        />
      )
      break
    case field_types.TEXT_SHORT:
      returnedFieldType = (
        <TextShort
          key={uuid.v4()}
          masterQuestion={master_question}
          answer={answers.length === 1 ? answers[0] : null}
          colorSettings={colorSettings}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
        />
      )
      break
    case field_types.TEXT_LONG:
      returnedFieldType = (
        <TextLong
          key={uuid.v4()}
          masterQuestion={master_question}
          answer={answers.length === 1 ? answers[0] : null}
          colorSettings={colorSettings}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
        />
      )
      break
    case field_types.INTEGER:
    case field_types.FLOAT:
      returnedFieldType = (
        <NumberField
          key={uuid.v4()}
          masterQuestion={master_question}
          answer={answers.length === 1 ? answers[0] : null}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
          colorSettings={colorSettings}
          hasVariableButtons={false}
        />
      )
      break
    case field_types.YES_OR_NO:
      returnedFieldType = (
        <YesOrNoField
          key={uuid.v4()}
          masterQuestion={master_question}
          answer={answers.length === 1 ? answers[0] : null}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
          colorSettings={colorSettings}
        />
      )
      break
    case field_types.SELECT_ALL_THAT_APPLY:
      returnedFieldType = (
        <MultiSelectField
          key={uuid.v4()}
          masterQuestion={master_question}
          answers={answers}
          colorSettings={colorSettings}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
        />
      )
      break
    case field_types.SELECT_ONE:
      returnedFieldType = (
        <SingleSelectField
          key={uuid.v4()}
          masterQuestion={master_question}
          answer={answers.length === 1 ? answers[0] : null}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
          colorSettings={colorSettings}
        />
      )
      break
    case field_types.PHONE:
      returnedFieldType = (
        // <PhoneField
        //   key={uuid.v4()}
        //   masterQuestion={master_question}
        //   answer={answers.length === 1 ? answers[0] : null}
        //   saveCompletedSurveyAnswer={(payload) =>
        //     saveCompletedSurveyAnswer(payload)
        //   }
        // />
        <View>
          <Text>PhoneField</Text>
        </View>
      )
      break
    case field_types.LONG_LIST_SEARCH:
      returnedFieldType = (
        // <LongListSearchField
        //   key={uuid.v4()}
        //   masterQuestion={master_question}
        //   answer={answers.length === 1 ? answers[0] : null}
        //   saveCompletedSurveyAnswer={(payload) =>
        //     saveCompletedSurveyAnswer(payload)
        //   }
        // />
        <View>
          <Text>LongListSearchField</Text>
        </View>
      )
      break
    case field_types.DATE:
      returnedFieldType = (
        // <DateField
        //   key={uuid.v4()}
        //   masterQuestion={master_question}
        //   answer={answers.length === 1 ? answers[0] : null}
        //   saveCompletedSurveyAnswer={(payload) =>
        //     saveCompletedSurveyAnswer(payload)
        //   }
        // />
        <View>
          <Text>DateField</Text>
        </View>
      )
      break
    case field_types.TOGGLE:
      returnedFieldType = (
        <ToggleField
          key={uuid.v4()}
          masterQuestion={master_question}
          answer={answers.length === 1 ? answers[0] : null}
          colorSettings={colorSettings}
          saveCompletedSurveyAnswer={(payload) =>
            saveCompletedSurveyAnswer(payload)
          }
        />
      )
      break
    default:
  }

  return returnedFieldType
}

function renderWorkflow(question_workflow, surveyObj) {
  const { master_question, show } = question_workflow

  return show ? renderField(master_question, surveyObj) : null
}

function renderCategory(category, surveyObj) {
  // sort the question workflows by order
  const question_workflows = category.question_workflows.sort(
    (a, b) => a.order - b.order
  )

  let category_content = []

  question_workflows.forEach((question_workflow) => {
    category_content.push(renderWorkflow(question_workflow, surveyObj))
  })

  return category_content
}

function createSelectAllAnswersArrray(
  pre_req_answers,
  master_question_prerequisite_id,
  surveyObj
) {
  let selectAll = []
  let answer = null
  const { survey } = surveyObj
  for (const prerequisite_answer of pre_req_answers) {
    if (prerequisite_answer.master_question_choice_id) {
      const master_question_choice_id = parseInt(
        prerequisite_answer.master_question_choice_id,
        10
      )
      // get choices for master_question_prerequisite_id
      for (const cat of survey.survey_categories) {
        for (const question_workflow of cat.question_workflows) {
          if (
            master_question_prerequisite_id ===
            parseInt(question_workflow.master_question.id, 10)
          ) {
            const choices =
              question_workflow.master_question.master_question_choices

            answer = choices.find(
              (c) => parseInt(c.id, 10) === master_question_choice_id
            )
            if (answer) {
              answer = answer.value
              selectAll.push(answer)
            }
          }
        }
      }
    }
  }
  return selectAll
}

function PageBuilder(surveyObject) {
  const startBuildPage = () => buildPage(surveyObject)
  return {
    startBuildPage,
  }
}

export default PageBuilder
