import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ViewPropTypes,
  Platform,
} from 'react-native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import DiseasesView from '$screens/main/family/_includes/diseases/Diseases.view'
import styles from './FamilyCard.style'
import FamilyCardButton from '$components/family-card-button/FamilyCardButton'
import GenesView from '$screens/main/family/_includes/genes/GenesView'
import uuid from 'react-native-uuid'

export default function FamilyCard({
  member,
  navigateToMemberProfile,
  getGenderIcon,
  getGenderIconBorderRadius,
  addToMemberButtonAction,
  relationship,
  customStyles,
  titleText,
  colorSettings,
  component,
  renderVerticalTreeLine,
  childrenView,
}) {
  return (
    <View
      listKey={uuid.v4()}
      key={member?.member_id}
      style={childrenView ? styles.childrenCardContainer : styles.cardContainer}
    >
      {/* {renderVerticalTreeLine} */}
      <View
        style={
          childrenView ? styles.childrenMiddleContainer : styles.middleContainer
        }
      >
        {/* {childrenView && treeLine()} */}
        <View
          style={[
            customStyles.itemViewContainer,
            childrenView
              ? styles.childrenInnerContainer
              : styles.innerContainer,
          ]}
        >
          <TouchableOpacity
            onPress={navigateToMemberProfile}
            activeOpacity={0.8}
            style={styles.cardButton}
          >
            <View style={[customStyles.itemView, styles.profileContainer]}>
              <View
                style={[customStyles.itemImageContainer, styles.profileImage]}
              >
                <Image
                  source={getGenderIcon}
                  style={getGenderIconBorderRadius}
                />
              </View>

              <View style={customStyles.itemDetailsContainer}>
                <Text style={customStyles.itemDetailsTitleText}>
                  {titleText}
                </Text>

                <View style={styles.divider} />

                <Text
                  style={[
                    customStyles.itemDetailsSubtitleText,
                    styles.subtitleText,
                  ]}
                >
                  {relationship}
                </Text>

                <View style={styles.divider} />

                <DiseasesView diseases={member?.diseases} />
                <GenesView genes={member?.genes} />
              </View>

              <View
                style={[
                  customStyles.itemAccessoryContainer,
                  styles.addButtonContainer,
                ]}
              >
                <FamilyCardButton
                  customStyles={customStyles}
                  addToMemberButtonAction={addToMemberButtonAction}
                  icon={
                    <Ionicons
                      name="person-add"
                      size={19}
                      color={colorSettings?.post_onboarding_nav_bgcolor}
                      style={{
                        paddingHorizontal: Platform.OS === 'web' ? 2 : 1,
                      }}
                    />
                  }
                />
                <FamilyCardButton
                  customStyles={customStyles}
                  addToMemberButtonAction={navigateToMemberProfile}
                  icon={
                    <MaterialCommunityIcons
                      name="pencil-outline"
                      size={20}
                      color={colorSettings?.post_onboarding_nav_bgcolor}
                      style={{ paddingLeft: 1 }}
                    />
                  }
                />
              </View>
            </View>
          </TouchableOpacity>

          {component}
        </View>
      </View>
    </View>
  )
}

FamilyCard.defaultProps = {
  navigateToMemberProfile: () => {},
  getGenderIcon: () => {},
  getGenderIconBorderRadius: () => {},
  addToMemberButtonAction: () => {},
  colorSettings: {},
  relationship: '',
  titleText: '',
  member: {},
  customStyles: {},
  renderVerticalTreeLine: () => {},
  childrenView: false,
  component: <></>,
}
