import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, Platform } from 'react-native'
import { HeaderBackButton } from 'react-navigation-stack'
import { useSelector } from 'react-redux'

import { useRoute } from '@react-navigation/core'
import { capitalizeFirstLetter } from '$api/_utils/Utils.js'
import HeaderBackImage from '$navigation/_components/HeaderBackImage.js'
import { handleNavigateToPreviousScreen } from '$navigation/_utils/custom_workflow'

import global_styles from '$constants/styles/global.styles'

import { i18n } from '$localization/config.js'

function LeftButtonComponent({
  closeButtonType = 'close',
  navigation,
  backArrowColor = 'white',
  customAction = null,
}) {
  const { isCustomWorkflow } = useSelector(
    (state) => state.customFlowRoutesStore
  )
  const route = useRoute()

  const [closeButtonTypeValue, setCloseButtonTypeValue] =
    useState(closeButtonType)

  const canGoBack = navigation?.canGoBack()

  useEffect(() => {
    if (isCustomWorkflow) {
      const { canNavigationPop } = handleNavigateToPreviousScreen()

      setCloseButtonTypeValue(
        !canNavigationPop || !canGoBack ? 'hidden' : 'back'
      )
    }
    const isPersonalGenderScreen = route.name === 'PersonalGender'
    if (isPersonalGenderScreen) {
      setCloseButtonTypeValue('hidden')
    }
  }, [isCustomWorkflow, canGoBack])

  const handleBackAction = async () => {
    const isWebPageRefreshed =
      Platform.OS === 'web' && performance.navigation.type === 1

    // TODO: MAYBE FIND BETTER SOLUTION FOR HANDLING LOSING NAVIGATION STACK AFTER REFRESHING PAGE
    if (isWebPageRefreshed && !canGoBack) {
      const routeName = navigation.getParent()?.getState().routeNames[0]
      navigation.replace(routeName)
    }

    if (isCustomWorkflow) {
      const { dispatchPreviousRoute } = handleNavigateToPreviousScreen()
      dispatchPreviousRoute()
    }

    navigation.pop()
  }

  switch (closeButtonTypeValue) {
    case 'hidden':
      return null
    case 'close':
    case 'cancel':
      return (
        <TouchableOpacity
          style={global_styles.headerButton}
          onPress={() => navigation.dismiss()}
        >
          <Text style={global_styles.headerLeftButtonText}>
            {capitalizeFirstLetter(closeButtonTypeValue)}
          </Text>
        </TouchableOpacity>
      )
    case 'back':
      return (
        <TouchableOpacity
          style={global_styles.backButton}
          onPress={!customAction ? () => handleBackAction() : customAction}
        >
          {Platform.OS === 'ios' ? (
            <HeaderBackButton
              tintColor={backArrowColor}
              label={i18n.t('back')?.default}
            />
          ) : (
            <>
              <HeaderBackImage color={backArrowColor} />
              <Text
                style={[
                  global_styles.backButtonText,
                  {
                    color: backArrowColor,
                  },
                ]}
              >
                {i18n.t('back')?.default}
              </Text>
            </>
          )}
        </TouchableOpacity>
      )
    case 'custom':
      return (
        <TouchableOpacity
          style={global_styles.backButton}
          onPress={!canGoBack ? () => handleBackAction() : () => customAction()}
        >
          {Platform.OS === 'ios' ? (
            <HeaderBackButton
              tintColor={backArrowColor}
              label={i18n.t('back')?.default}
            />
          ) : (
            <HeaderBackImage color={backArrowColor} />
          )}
        </TouchableOpacity>
      )
    default:
      return (
        <TouchableOpacity
          style={global_styles.backButton}
          onPress={() => navigation.pop()}
        >
          <HeaderBackImage color={backArrowColor} />
        </TouchableOpacity>
      )
  }
}

export default LeftButtonComponent
