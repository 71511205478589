import { StyleSheet } from 'react-native'

const grey = 'rgb(74, 74, 74)'
const fadedWhite = 'rgb(204, 204, 204)'

const styles = StyleSheet.create({
  yearStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
    alignSelf: 'center',
  },
  monthStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
  },
  dayStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginTop: 7,
    height: 34,
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
  },
  container: {
    width: '100%',
    flexDirection: 'row',
  },
})

export default styles
