import { Component } from 'react'
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  Platform,
  StatusBar,
} from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import NextButtonAccessoryView from '$components/next-button-accessory-view/NextButtonAccessoryView'

import Account from '$data_models/Account'
import ApiFetchHandler from '$api/ApiFetchHandler.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

import custom_styles from './styles/main.styles.js'
import { WEB } from '$constants/Platforms.js'
import { DefaultFullHeight } from '$constants/styles/global.styles.js'

const inputAccessoryViewID = 'ForgotPWScreenEnterNewPassword'

class ForgotPWScreenEnterNewPassword extends Component {
  email = this.props.route.params?.email

  accountID = this.props.route.params?.accountID

  password = ''

  token = this.props.route.params?.token ?? {}

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      password: '',
      passwordIsHidden: true,
      imageURI: require('$assets/images/icon/eye-close/black.png'),
      passwordTextInputIsFocused: false,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    if (WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  onFocusTextInput = (tag, isFocused = true) => {
    this.setState({
      passwordTextInputIsFocused: false,
    })

    if (isFocused) {
      switch (tag) {
        case 0:
          const { passwordTextInputIsFocused } = this.state

          this.setState({
            passwordTextInputIsFocused: !passwordTextInputIsFocused,
          })
          break
        default:
          console.log(
            '🚀 \n\n file: ForgotPWScreenEnterNewPassword.js:84 \n\n ForgotPWScreenEnterNewPassword \n\n Nothing is selected'
          )
      }
    }
  }

  toggleTextInputBorderBottomColor = (isFocused = false) => {
    if (isFocused) {
      return { borderBottomColor: this.colorSettings?.btn_filled_border_1 }
    }
    return {
      borderBottomColor: this.colorSettings?.text_input_placeholder_color_1,
    }
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const isValid = this.validateInput()
    const style = isValid
      ? 'bottom_next_btn_enabled'
      : 'bottom_next_btn_disabled'

    return {
      backgroundColor: this.colorSettings?.[style],
      borderColor: this.colorSettings?.[style],
    }
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const enabled = this.validateInput()
    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }
    return style
  }

  validateInput = () => {
    let passwordIsValid = false

    // Validate password
    const passwordValidator = require('password-validator')
    const schema = new passwordValidator()

    // Minimum length 8
    schema.is().min(8)

    if (schema.validate(this.state.password)) {
      passwordIsValid = true
    }

    if (passwordIsValid) {
      return true
    }

    return false
  }

  showPasswordButtonAction = () => {
    const passwordIsHidden = !this.state.passwordIsHidden

    if (passwordIsHidden) {
      this.setState({
        passwordIsHidden,
        imageURI: require('$assets/images/icon/eye-close/black.png'),
      })
    } else {
      this.setState({
        passwordIsHidden,
        imageURI: require('$assets/images/icon/eye/black.png'),
      })
    }
  }

  onChangeText = (value) => {
    this.setState({ password: value })
  }

  submitNewPassword = async (password) => {
    const { navigation } = this.props

    const passwordResetPayload = {
      path: 'account/strict/change_password/',
      method: 'post',
      body: {
        account_id: this.accountID,
        new_password: password,
      },
      token: this.token.accessToken,
      pageDetails: {
        page: 'ForgotPWScreenEnterNewPassword.js',
      },
    }

    const response = await ApiFetchHandler(passwordResetPayload)
    const { saveError } = this.props

    if (response.isError) {
      if (response.status === 500) {
        return showDefaultAlert(
          i18n.t('server_error').default,
          i18n.t(
            'sorry_there_was_an_error_processing_your_request_please_try_again_later'
          ).default
        )
      }
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      return showDefaultAlert(
        i18n.t('password_security').default,
        i18n.t(
          'your_password_does_not_meet_the_requirements_please_choose_a_more_secure_password'
        ).default
      )
    }

    if (response.status_code !== 200) {
      return
    }

    navigation.navigate('SuccessScreenResetPassword')
  }

  nextButtonAction = async () => {
    if (!this.validateInput()) {
      return
    }

    const { password } = this.state

    const passwordValidator = require('password-validator')
    const schema = new passwordValidator()

    // Add properties to it
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .is()
      .not()
      .oneOf(['password', 'passw0rd']) // Blacklist these values

    if (schema.validate(password)) {
      // Submit new password
      await this.submitNewPassword(password)
    } else {
      showDefaultAlert(
        i18n.t('password_security').default,
        i18n.t(
          'your_password_does_not_meet_the_requirements_please_choose_a_more_secure_password'
        ).default
      )
    }
  }

  render() {
    let enableKeyboardAvoiding = false
    if (Platform.OS === 'ios') {
      enableKeyboardAvoiding = true
    }

    return (
      <SafeAreaView
        style={[
          custom_styles.container,
          { backgroundColor: this.colorSettings?.splash_bgcolor },
        ]}
      >
        {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}

        <KeyboardAvoidingView
          style={{ flex: 1.0 }}
          contentContainerStyle={{ flex: 1.0 }}
          behavior="position"
          enabled={enableKeyboardAvoiding}
        >
          <ScrollView
            style={{ height: this.state.scrollViewHeight }}
            contentContainerStyle={[
              custom_styles.contentContainer,
              { alignItems: 'center', marginHorizontal: 32 },
            ]}
          >
            <View
              style={[
                custom_styles.content,
                {
                  ...Platform.select({
                    web: {
                      width: '30%',
                      minWidth: 370,
                    },
                  }),
                },
              ]}
            >
              {/* <Content> */}
              <View
                style={{ flex: 0.5, width: '100%', justifyContent: 'center' }}
              >
                <View style={{ flex: 1.0, backgroundColor: '' }}>
                  <View style={{ flex: 0.1 }} />

                  {/* <Title Text> */}
                  <View
                    style={{
                      flex: 0.3,
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={[
                        custom_styles.titleText,
                        {
                          color: this.colorSettings?.text_input_title_color_1,
                        },
                      ]}
                    >
                      {i18n.t('enter_new_password').default}
                    </Text>
                    <View style={{ height: 5.0 }} />
                    <Text
                      style={[
                        custom_styles.helperText,
                        {
                          textAlign: 'center',
                          color: this.colorSettings?.text_input_label_color_1,
                        },
                      ]}
                    >
                      {'for '}
                      {this.email}
                    </Text>
                  </View>
                  {/* </Title Text> */}

                  {/* <Main Text Input> */}
                  <View
                    style={{
                      flex: 0.3,
                      justifyContent: 'center',
                    }}
                  >
                    <View
                      style={[
                        styles.mainTextInputContainer,
                        { flex: 1.0, flexDirection: 'row' },
                      ]}
                    >
                      <TextInput
                        nativeID="web_text_input"
                        style={[
                          styles.mainTextInput,
                          this.toggleTextInputBorderBottomColor(
                            this.state.passwordTextInputIsFocused
                          ),
                          {
                            color:
                              this.colorSettings?.text_input_color_1 ||
                              'rgba(74, 74, 74, 1)',
                          },
                        ]}
                        inputAccessoryViewID={inputAccessoryViewID}
                        autoCapitalize="none"
                        autoCorrect={false}
                        secureTextEntry={this.state.passwordIsHidden}
                        selectionColor="black"
                        placeholderTextColor={
                          this.colorSettings?.text_input_placeholder_color_1 ||
                          'rgba(74, 74, 74, 0.5)'
                        }
                        placeholder={i18n.t('new_password').default}
                        onFocus={() => this.onFocusTextInput(0)}
                        // onEndEditing={() => this.onFocusTextInput(false)}
                        onBlur={() => this.onFocusTextInput(false)}
                        onSubmitEditing={this.nextButtonAction}
                        onChangeText={(value) => {
                          this.password = value
                          this.onChangeText(value)
                        }}
                      />

                      {/* <Show Password button> */}
                      <View
                        style={{
                          marginLeft: -24,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TouchableOpacity
                          onPress={this.showPasswordButtonAction}
                        >
                          <Image
                            style={{
                              height: 24,
                              width: 24,
                              resizeMode: 'contain',
                            }}
                            source={this.state.imageURI}
                          />
                        </TouchableOpacity>
                      </View>
                      {/* </Show Password button> */}
                    </View>
                  </View>
                  {/* </Main Text Input> */}

                  {/* <Helper Text Container> */}
                  <View
                    style={{
                      // backgroundColor: "pink",
                      flex: 0.3,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <View style={custom_styles.helperTextContainer}>
                      <Text
                        style={[
                          custom_styles.helperText,
                          {
                            color: this.colorSettings?.text_input_label_color_1,
                          },
                        ]}
                      >
                        {
                          i18n.t(
                            'minimum_of_8_characters_one_uppercase_letter_one_lowercase_letter_one_number'
                          ).default
                        }
                      </Text>
                    </View>
                  </View>
                  {/* </Helper Text Container> */}
                </View>
              </View>
              {/* </Content> */}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        {/* <Next button> */}
        <View style={custom_styles.nextButtonContainer}>
          <TouchableOpacity
            disabled={this.toggleNextButton()}
            style={[
              custom_styles.nextButton,
              this.toggleNextButtonColor(),
              {
                backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            onPress={this.nextButtonAction}
          >
            <Text
              style={[
                custom_styles.nextButtonText,
                {
                  color: this.toggleNextButtonText(),
                },
              ]}
            >
              {i18n.t('next').default}
            </Text>
          </TouchableOpacity>
        </View>

        <NextButtonAccessoryView
          nativeID={inputAccessoryViewID}
          backgroundColor="white"
          labelText={i18n.t('next').default}
          labelColor={this.toggleNextButtonText()}
          disabled={this.toggleNextButton()}
          style={[
            custom_styles.nextButton,
            this.toggleNextButtonColor(),
            {
              backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
            },
          ]}
          onPress={this.nextButtonAction}
        />
        {/* </Next button> */}
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  mainTextInputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainTextInput: {
    width: '100%',
    height: 50,
    justifyContent: 'center',
    // color: "black",
    color: 'rgba(74,74,74,1.0)',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'left',
    borderBottomWidth: 1.0,
    borderBottomColor: 'rgba(74,74,74,1)',
    paddingRight: 24,
  },
  container: {},
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(ForgotPWScreenEnterNewPassword)
