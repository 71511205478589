import React from 'react'
import { Text, TouchableOpacity, Platform } from 'react-native'

import global_styles from '../../constants/styles/global.styles'

import { i18n } from '$localization/config.js'

const RightButtonComponent = ({ navigation, textColor }) => (
  <TouchableOpacity
    style={global_styles.backButton}
    onPress={() => navigation.popToTop()}
  >
    <Text style={[global_styles.headerRightButtonText, { color: textColor }]}>
      {i18n.t('exit').default}
    </Text>
  </TouchableOpacity>
)

export default RightButtonComponent
