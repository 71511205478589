import { View, Text, TouchableOpacity } from 'react-native'
// components
import ButtonSub from '$components/button_sub'
import SubList from '../sub_list'

const ButtonWithListView = ({
  colorSettings,
  updateState,
  isToggled,
  buttonTitle,
  preventToggleEffect,
  data,
  onEdit,
}) => {
  const ShowList = ({ currentData }) => {
    if (isToggled && !preventToggleEffect) {
      return (
        <SubList
          colorSettings={colorSettings}
          currentData={currentData}
          onEdit={onEdit}
        />
      )
    }
    return <View />
  }

  return (
    <View style={{ width: '100%' }}>
      <ButtonSub
        colorSettings={colorSettings}
        onPress={() => updateState()}
        title={buttonTitle}
        preventToggleEffect={preventToggleEffect}
        // currentState={val => currentState(val)}
        setToggle={isToggled}
      />
      <ShowList currentData={data} />
    </View>
  )
}

export default ButtonWithListView
