import { Component } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  Modal,
} from 'react-native'

import { connect } from 'react-redux'

import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view'
import { i18n } from '$localization/config.js'

import LoadingView from '$constants/LoadingView.js'

import DiseaseTextInput from '$components/disease-text-input/DiseaseTextInput.js'
import SkipLogicList from '$screens/relatives/profile/sections/health_history/components/disease_list_components/SkipLogicList.js'

import ApiFetchHandler from '$api/ApiFetchHandler.js'

import global_styles from '$constants/styles/global.styles.js'
import custom_styles from './_styles/custom.styles.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

class EditDiseaseObj extends Component {
  colorSettings = this.props.colorSettings

  memberProfile = this.props.route.params?.memberProfile ?? {}

  proband = this.props.route.params?.proband ?? {}

  existingMemberDiseases = this.props.route.params?.existingMemberDiseases ?? []

  onDismiss = this.props.route.params?.onDismiss ?? null

  parentController = this.props.route.params?.parentController ?? null

  onUpdateMemberDisease = this.props.route.params?.onUpdateMemberDisease ?? null

  editFromMain = this.props.route.params?.editFromMain ?? false

  memberDisease = this.props.route.params?.memberDisease ?? null

  diseaseList = this.props.route.params?.diseaseList ?? null

  filterDiseaseList = this.diseaseList.skip_logistics.filter(
    (item) => item.question_type !== 'text'
  )

  currentDiseaseData = ''

  constructor(props) {
    super(props)

    this.state = {
      memberSkipLogic:
        this.memberDisease.length > 0 ? this.memberDisease[0].skip_logics : [],
      AOD:
        this.memberDisease.length > 0
          ? this.memberDisease[0].age_diagnosed
          : '',
      memberDiseaseSave: false,
      showModal: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.AOD !== this.state.AOD) {
      this.currentDiseaseData.age_diagnosed = this.state.AOD
    }

    if (prevState.memberDiseaseSave !== this.state.memberDiseaseSave) {
      // this.props.navigation.popToTop()
      this.onDismiss((type = 'default'))
      this.props.navigation.dismiss()
      this.setState({ showModal: false })
    }
  }

  componentDidMount() {
    // add object property
    const addPropertyObject = {
      ...this.diseaseList,
      age_diagnosed: 0,
      skip_logics: [],
      id: `dl${this.diseaseList.id}`,
      disease_id: this.diseaseList.id,
    }

    // delete object property
    const { skip_logistics, ...deletedPropertyObject } = addPropertyObject
    if (this.memberDisease.length < 1) {
      this.currentDiseaseData = deletedPropertyObject
    } else {
      this.currentDiseaseData = JSON.parse(
        JSON.stringify(this.memberDisease[0])
      )
    }
  }

  showAgeOfDiagnosisAlert = (
    title = i18n.t('age_of_diagnosis').default,
    message = i18n.t(
      'if_you_dont_know_the_exact_age_please_enter_your_closest_guess_it_is_better_to_guess_than_to_leave_the_age_blank'
    ).default
  ) => {
    showDefaultAlert(title, message)
  }

  skipLogicCount = (item) => item.length === 1

  loadMemberData = () => ({
    ...this.diseaseList,
    age_diagnosed: 0,
    skip_logics: [],
    id: `dl${this.diseaseList.id}`,
    disease_id: this.diseaseList.id,
  })

  handleMemberData = (data) => {
    if (!data && data === undefined) {
      return
    }
    const { skip_logics } = this.currentDiseaseData
    const objIndex = skip_logics.findIndex(
      (obj) => obj.skip_logic_id === data.skip_logic_id
    )

    // check data if it exist in memberDiseaseData
    // does exist in memberDiseaseData
    if (objIndex > -1) {
      // remove data if answer is equal to 'n'
      if (data.answer === 'n') {
        skip_logics.splice(objIndex, 1)
      } else {
        skip_logics[objIndex].answer = data.answer
      }
      // doesn't exist in memberDiseaseData
    } else {
      // doesn't do anything if no answer and is only the question
      if (this.skipLogicCount(this.filterDiseaseList) && data.answer === 'n') {
        return
      }

      skip_logics.push(data)
    }
  }

  renderSkipLogic = (itemData) => {
    // pass skiplogic where (itemData)skip logic id === member skip logic id
    const specificSkipLogic = this.state.memberSkipLogic.find(
      (item) => item.skip_logic_id === itemData.item.id
    )

    return (
      <SkipLogicList
        colorSettings={this.colorSettings}
        onMemberUpdate={(item) => this.handleMemberData(item)}
        diseaseData={itemData.item}
        memberData={specificSkipLogic}
        singleSkipLogic={this.skipLogicCount(this.filterDiseaseList)}
        containerStyle={{ paddingHorizontal: 16 }}
        profileData={this.memberProfile}
        memberDiseaseAOD={this.state.AOD}
      />
    )
  }

  handleSelectAllTitle = () => {
    if (
      this.filterDiseaseList.length < 1 ||
      this.skipLogicCount(this.filterDiseaseList)
    ) {
      return
    }

    return (
      <View
        style={{
          alignSelf: 'center',
          marginBottom: 30,
          marginHorizontal: '20%',
        }}
      >
        <Text
          style={{
            fontFamily: 'karla-bold',
            fontSize: 18,
            textAlign: 'center',
            color: this.colorSettings?.btn_no_fill_text_2,
          }}
        >
          {i18n.t('if_you_know_more_details_select_any_that_apply').default}
        </Text>
      </View>
    )
  }

  invalidAgeAlert = (
    title = i18n.t('invalid_age_of_diagnosis').default,
    message = i18n.t(
      'the_age_of_diagnosis_cannot_be_greater_than_this_persons_current_ageage_of_death'
    ).default
  ) => {
    showDefaultAlert(title, message)
  }

  ageDiscrepancyChecker = () => {
    this.invalidAgeAlert()
    this.setState(() => ({ AOD: 0 }))
    return true
  }

  invalidAgeAbove130 = () => {
    this.invalidAgeAlert(
      i18n.t('invalid_age_of_diagnosis').default,
      i18n.t('age_of_diagnosis_must_not_be_greater_than_130').default
    )
    this.setState(() => ({ AOD: 0 }))
    return true
  }

  ageInputValidation = () => {
    const currentAge = this.memberProfile.profileData.currentAge || 0
    const ageOfDeath = this.memberProfile.profileData.ageOfDeath || 0

    if (this.memberProfile.profileData.deceased) {
      // If ageOfDeath is null or blank AND Age of Diagnosis is greater than 130
      if ((!ageOfDeath || ageOfDeath === '') && this.state.AOD > 130) {
        return this.invalidAgeAbove130()
      }
      // If ageOfDeath is not null or zero AND Age of Diagnosis is greater than Age of Death
      if (this.state.AOD > ageOfDeath && ageOfDeath !== 0) {
        return this.ageDiscrepancyChecker()
      }
    } else if (this.state.AOD > currentAge && currentAge !== 0) {
      return this.ageDiscrepancyChecker()
    }
    return false
  }

  handleInputChange = (val) => {
    const ageInput = val.nativeEvent.text
    this.setState(() => ({ AOD: ageInput }))
  }

  textToKey = (title) => {
    const key = title
      .toLowerCase()
      .split(' ')
      .join('_')
      .replace(/\//g, '_')
      .replace(/[^-0-9a-zA-Z _]/g, '')

    return i18n.t(key).default
  }

  headerComponent = () => {
    const diseaseShortName = this.textToKey(this.diseaseList.short_name)

    return (
      <View>
        <View style={{ alignItems: 'center', width: '100%', paddingTop: 70 }}>
          <View style={global_styles.titleTextContainer}>
            <View style={custom_styles.inlineButton} />

            <Text
              style={[
                global_styles.titleText,
                {
                  color: this.colorSettings?.btn_no_fill_text_2,
                },
              ]}
            >
              {i18n.t('age_of_diagnosis').default}
            </Text>

            <TouchableOpacity
              style={custom_styles.inlineButton}
              onPress={() => this.showAgeOfDiagnosisAlert()}
            >
              <Image
                source={require('$assets/images/new_assets/icon-info-white.png')}
                style={{ width: 16, height: 16, resizeMode: 'contain' }}
              />
            </TouchableOpacity>
          </View>

          <View style={{ height: 30, width: '100%' }} />

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <DiseaseTextInput
              value={this.state.AOD}
              returnKeyType="done"
              onChange={(val) => {
                this.handleInputChange(val)
              }}
              onEndEditing={() => {
                this.ageInputValidation()
              }}
            />
          </View>

          <View
            style={{
              height: 40,
              width: '100%',
              borderBottomWidth: 1.0,
              borderColor: this.colorSettings?.text_input_placeholder_color_2,
              marginBottom: 40,
            }}
          />
        </View>

        <View style={styles.titleContainer}>
          <Text
            style={[
              styles.title,
              {
                color: this.colorSettings?.btn_no_fill_text_2 || 'white',
              },
            ]}
          >
            {diseaseShortName}
          </Text>
        </View>

        {this.handleSelectAllTitle()}
      </View>
    )
  }

  combineData = (existingMemberDisease, currentMemberDisease) => {
    const loadData = [currentMemberDisease, ...existingMemberDisease]
    const newData = loadData.filter(
      (value, index, array) =>
        array.findIndex((item) => item.disease_id === value.disease_id) ===
        index
    )
    const diseaseList = []

    for (const data of newData) {
      const list = {
        disease_id: data.disease_id,
        age_diagnosed: data.age_diagnosed,
        skip_logic:
          data.skip_logics.map((skipLogic) => ({
            skip_logic_id: skipLogic.skip_logic_id,
            answer: skipLogic.answer,
          })) || '',
      }
      diseaseList.push(list)
    }
    return diseaseList
  }

  handleSaveAndExit = async () => {
    this.setState({ showModal: true })

    const { authCredentials } = this.proband.account
    const memberId = this.memberProfile.memberID
    const diseases = this.combineData(
      this.existingMemberDiseases,
      this.currentDiseaseData
    )

    const updateMemberDiseaseBulkPayload = {
      path: `member/${memberId}/add_disease_bulk/`,
      method: 'post',
      token: authCredentials,
      body: {
        proband_id: parseInt(this.proband.probandID, 10),
        diseases,
      },
      pageDetails: {
        page: 'EditMemberDiseases.js',
      },
    }

    const response = await ApiFetchHandler(updateMemberDiseaseBulkPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.setState({ memberDiseaseSave: true })
  }

  saveDiseaseFromMain = () => {
    const { navigation } = this.props

    this.onUpdateMemberDisease(this.currentDiseaseData)
    navigation.pop()
  }

  handleSaveData = async () => {
    if (this.ageInputValidation()) {
      return
    }

    if (this.state.AOD < 1) {
      return this.showAgeOfDiagnosisAlert()
    }

    await this.handleSaveAndExit()
  }

  render() {
    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor,
            flex: 1,
            flexDirection: 'column',
            height: '100%',
          },
        ]}
      >
        <View style={global_styles.contentContainerFullHeight}>
          <View nativeID="web_scaled_main_container">
            <View>
              <KeyboardAwareFlatList
                keyExtractor={(item) => item.id}
                data={this.filterDiseaseList}
                renderItem={this.renderSkipLogic}
                style={{ width: '100%' }}
                contentContainerStyle={{ width: '100%' }}
                ListHeaderComponent={this.headerComponent}
                ListFooterComponent={<View />}
                ListFooterComponentStyle={{ marginBottom: 20 }}
              />
            </View>
          </View>
        </View>

        <View
          style={{
            width: '100%',
            alignSelf: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: '32px',
          }}
        >
          <View style={{ height: 60, width: '100%' }}>
            <TouchableOpacity
              style={[
                global_styles.nextButton,
                {
                  backgroundColor:
                    this.colorSettings?.bottom_next_btn_enabled || 'white',
                  borderColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={() => this.handleSaveData()}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.colorSettings?.btn_filled_text_1,
                  },
                ]}
              >
                {i18n.t('done').title}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <Modal visible={this.state.showModal} animationType="none" transparent>
          <View
            style={{
              flex: 1.0,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingView
              backgroundColor={this.colorSettings?.onboarding_bgcolor}
              tintColor={this.colorSettings?.btn_no_fill_text_2}
              textColor={this.colorSettings?.btn_no_fill_text_2}
              visible={this.state.showModal}
              message={i18n.t('saving_datanplease_wait').default}
            />
          </View>
        </Modal>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    alignSelf: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderColor: 'rgba(0,0,0,0.4)',
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    borderRadius: 50,
    marginBottom: 30,
    borderWidth: 0.5,
  },
  title: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
    color: 'white',
  },
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    account: store.account,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(EditDiseaseObj)
