import { Component } from 'react'
import {
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import Account from '$data_models/Account'

export default class SuccessScreenSurveyCompleted extends Component {
  parent = this.props.parent

  nextButtonAction = () => {
    this.parent.setState({ modalVisible: false })
  }

  render() {
    return (
      <View style={styles.container}>
        <ImageBackground
          style={{
            flex: 1.0,
            width: '100%',
            justifyContent: 'center',
          }}
          source={require('$assets/images/new_assets/bg-survey-complete.png')}
          resizeMode="cover"
        >
          <View style={styles.messageContentContainer}>
            <Image
              source={require('$assets/images/new_assets/illus-survey-complete.png')}
              style={{
                marginBottom: 30,
                width: 117,
                height: 188,
                resizeMode: 'contain',
              }}
            />
            <View
              style={{
                width: '100%',
                paddingHorizontal: 16,
              }}
            >
              <Text
                style={{
                  fontFamily: 'karla-bold',
                  fontSize: 36,
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                Survey Complete
              </Text>
            </View>

            <View
              style={{
                marginTop: 40,
                marginHorizontal: 16,
              }}
            >
              <View style={styles.nextButtonContainer}>
                <TouchableOpacity
                  style={styles.nextButton}
                  onPress={this.nextButtonAction}
                >
                  <Text style={styles.nextButtonText}>Edit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(101, 209, 185)',
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
})
