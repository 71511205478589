import { CUSTOM_FLOW_ROUTES, ROUTES } from '$navigation/constants/routes'

export const RAW_LAST_VISITED_SCREENS = {
  gender_selection: 'gender_selection',
  gender_identity: 'gender_identity',
  diseases: 'diseases',
  gene_testing: 'gene_testing',
  ethnicity: 'ethnicity',
  ancestry: 'ancestry',
  risk_screen: 'risk_screen',
  account_created_screen: 'account_created_screen',
  sign_up_clinician: 'sign_up_clinician',
  completed_health_info: 'completed_health_info',
  relatives_grandparents: 'relatives_grandparents',
  relatives_children: 'relatives_children',
  relatives_siblings: 'relatives_siblings',
  relatives_paternal_side: 'relatives_paternal_side',
  relatives_maternal_side: 'relatives_maternal_side',
  survey_custom: 'survey_custom',
  family_members_cancer: 'family_members_cancer',
  custom_success_screen: 'custom_success_screen',

  // TODO: Maybe we don't need these 3 screens below
  add_family_member_view_cancer: 'add_family_member_view_cancer',
  family_main_view_cancer: 'family_main_view_cancer',
  edit_profile_cancer: 'edit_profile_cancer',
}

export const MAPPED_LAST_VISITED_SCREENS = {
  PersonalGender: {
    routeId: CUSTOM_FLOW_ROUTES.PersonalGender.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.gender_selection,
    routeName: CUSTOM_FLOW_ROUTES.PersonalGender.name,
    ...CUSTOM_FLOW_ROUTES.PersonalGender,
  },
  PersonalGenderIdentity: {
    routeId: CUSTOM_FLOW_ROUTES.PersonalGenderIdentity.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.gender_identity,
    routeName: CUSTOM_FLOW_ROUTES.PersonalGenderIdentity.name,
    ...CUSTOM_FLOW_ROUTES.PersonalGenderIdentity,
  },
  DiseaseListMainScreen: {
    routeId: CUSTOM_FLOW_ROUTES.DiseaseListMainScreen.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.diseases,
    routeName: CUSTOM_FLOW_ROUTES.DiseaseListMainScreen.name,
    ...CUSTOM_FLOW_ROUTES.DiseaseListMainScreen,
  },
  PersonalGeneticTesting: {
    routeId: CUSTOM_FLOW_ROUTES.PersonalGeneticTesting.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.gene_testing,
    routeName: CUSTOM_FLOW_ROUTES.PersonalGeneticTesting.name,
    ...CUSTOM_FLOW_ROUTES.PersonalGeneticTesting,
  },
  PersonalEthnicity: {
    routeId: CUSTOM_FLOW_ROUTES.PersonalEthnicity.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.ethnicity,
    routeName: CUSTOM_FLOW_ROUTES.PersonalEthnicity.name,
    ...CUSTOM_FLOW_ROUTES.PersonalEthnicity,
  },
  PersonalAncestry: {
    routeId: CUSTOM_FLOW_ROUTES.PersonalAncestry.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.ancestry,
    routeName: CUSTOM_FLOW_ROUTES.PersonalAncestry.name,
    ...CUSTOM_FLOW_ROUTES.PersonalAncestry,
  },
  SuccessScreenAccountCreated: {
    routeId: CUSTOM_FLOW_ROUTES.SuccessScreenAccountCreated.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.account_created_screen,
    routeName: CUSTOM_FLOW_ROUTES.SuccessScreenAccountCreated.name,
    ...CUSTOM_FLOW_ROUTES.SuccessScreenAccountCreated,
  },
  SignupClinicianCode: {
    routeId: ROUTES.SignupClinicianCode.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.sign_up_clinician,
    routeName: ROUTES.SignupClinicianCode.name,
    ...ROUTES.SignupClinicianCode,
  },
  SuccessScreenPersonalHealthInfo: {
    routeId: CUSTOM_FLOW_ROUTES.SuccessScreenPersonalHealthInfo.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.completed_health_info,
    routeName: CUSTOM_FLOW_ROUTES.SuccessScreenPersonalHealthInfo.name,
    ...CUSTOM_FLOW_ROUTES.SuccessScreenPersonalHealthInfo,
  },
  ParentsGrandparentsList: {
    routeId: CUSTOM_FLOW_ROUTES.ParentsGrandparentsList.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.relatives_grandparents,
    routeName: CUSTOM_FLOW_ROUTES.ParentsGrandparentsList.name,
    ...CUSTOM_FLOW_ROUTES.ParentsGrandparentsList,
  },
  ChildrenList: {
    routeId: ROUTES.ChildrenList.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.relatives_children,
    routeName: ROUTES.ChildrenList.name,
    ...ROUTES.ChildrenList,
  },
  SiblingsList: {
    routeId: ROUTES.SiblingsList.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.relatives_siblings,
    routeName: ROUTES.SiblingsList.name,
    ...ROUTES.SiblingsList,
  },
  PaternalAuntsUnclesList: {
    routeId: ROUTES.PaternalAuntsUnclesList.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.relatives_paternal_side,
    routeName: ROUTES.PaternalAuntsUnclesList.name,
    ...ROUTES.PaternalAuntsUnclesList,
  },
  MaternalAuntsUnclesList: {
    routeId: ROUTES.MaternalAuntsUnclesList.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.relatives_maternal_side,
    routeName: ROUTES.MaternalAuntsUnclesList.name,
    ...ROUTES.MaternalAuntsUnclesList,
  },
  SurveyCustom: {
    routeId: CUSTOM_FLOW_ROUTES.SurveyCustom.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.survey_custom,
    routeName: CUSTOM_FLOW_ROUTES.SurveyCustom.name,
    ...CUSTOM_FLOW_ROUTES.SurveyCustom,
  },
  FamilyMembersCancer: {
    routeId: CUSTOM_FLOW_ROUTES.FamilyMembersCancer.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.family_members_cancer,
    routeName: CUSTOM_FLOW_ROUTES.FamilyMembersCancer.name,
    ...CUSTOM_FLOW_ROUTES.FamilyMembersCancer,
  },
  AddFamilyMemberViewCancer: {
    routeId: CUSTOM_FLOW_ROUTES.AddFamilyMemberViewCancer.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.add_family_member_view_cancer,
    routeName: CUSTOM_FLOW_ROUTES.AddFamilyMemberViewCancer.name,
    ...CUSTOM_FLOW_ROUTES.AddFamilyMemberViewCancer,
  },
  FamilyMainViewCancer: {
    routeId: CUSTOM_FLOW_ROUTES.FamilyMainViewCancer.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.family_main_view_cancer,
    routeName: CUSTOM_FLOW_ROUTES.FamilyMainViewCancer.name,
    ...CUSTOM_FLOW_ROUTES.FamilyMainViewCancer,
  },
  EditProfileCancer: {
    routeId: CUSTOM_FLOW_ROUTES.EditProfileCancer.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.edit_profile_cancer,
    routeName: CUSTOM_FLOW_ROUTES.EditProfileCancer.name,
    ...CUSTOM_FLOW_ROUTES.EditProfileCancer,
  },
  RisksScreenView: {
    routeId: CUSTOM_FLOW_ROUTES.RisksScreenView.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.risk_screen,
    routeName: CUSTOM_FLOW_ROUTES.RisksScreenView.name,
    ...CUSTOM_FLOW_ROUTES.RisksScreenView,
  },
  CustomSuccessScreen: {
    routeId: CUSTOM_FLOW_ROUTES.CustomSuccessScreen.id,
    selected_screen: RAW_LAST_VISITED_SCREENS.custom_success_screen,
    routeName: CUSTOM_FLOW_ROUTES.CustomSuccessScreen.name,
    ...CUSTOM_FLOW_ROUTES.CustomSuccessScreen,
  },
}

export const lastVisitedScreensList = [
  {
    ...MAPPED_LAST_VISITED_SCREENS.PersonalGender,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.PersonalGenderIdentity,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.DiseaseListMainScreen,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.PersonalGeneticTesting,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.PersonalEthnicity,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.PersonalAncestry,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.SuccessScreenAccountCreated,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.SignupClinicianCode,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.SuccessScreenPersonalHealthInfo,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.ParentsGrandparentsList,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.ChildrenList,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.SiblingsList,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.PaternalAuntsUnclesList,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.MaternalAuntsUnclesList,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.SurveyCustom,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.FamilyMembersCancer,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.AddFamilyMemberViewCancer,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.FamilyMainViewCancer,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.EditProfileCancer,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.RisksScreenView,
  },
  {
    ...MAPPED_LAST_VISITED_SCREENS.CustomSuccessScreen,
  },
]
