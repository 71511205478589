import { View, TouchableOpacity, Text } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import { TextInput } from 'react-native-gesture-handler'
import styles from './NumericalTextinput.styles'

export default function NumericalTextInput({
  colorSettings,
  subtractCount,
  addCount,
  inputValidation,
  count,
  onChangeText,
  title,
  isEditable = true,
  placeholder,
  hasVariableButtons,
  onPressHandler,
  onFocus,
  relativeSide,
}) {
  return (
    <View style={styles.mainContainer}>
      <View>
        {Boolean(title) && (
          <Text
            style={[
              styles.textTitle,
              {
                color: colorSettings?.selectable_btn_text_active_1 || 'black',
              },
            ]}
          >
            {title}
          </Text>
        )}
      </View>
      <View style={styles.outerInputContainer}>
        {hasVariableButtons && (
          <TouchableOpacity
            style={styles.incrementButton}
            onPress={subtractCount}
          >
            <Entypo
              name="circle-with-minus"
              size={24}
              color={colorSettings?.selectable_btn_text_active_1}
            />
          </TouchableOpacity>
        )}

        <View style={styles.inputContainer}>
          <TextInput
            value={count}
            textAlign="center"
            style={styles.input}
            placeholder={placeholder || ''}
            onBlur={() => inputValidation(relativeSide)}
            onFocus={() => onFocus(relativeSide)}
            onChangeText={onChangeText}
            keyboardType="numeric"
            returnKeyType="done"
            editable={isEditable}
            placeholderTextColor="white"
          />
        </View>
        {hasVariableButtons && (
          <TouchableOpacity style={styles.decrementButton} onPress={addCount}>
            <Entypo
              name="circle-with-plus"
              size={24}
              color={colorSettings?.selectable_btn_text_active_1}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

NumericalTextInput.propTypes = {
  colorSettings: PropTypes.shape({
    selectable_btn_text_active_1: PropTypes.string,
  }),
  subtractCount: PropTypes.func,
  count: PropTypes.number,
  addCount: PropTypes.func,
  inputValidation: PropTypes.func,
  onChangeText: PropTypes.func,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  hasVariableButtons: PropTypes.bool,
}

NumericalTextInput.defaultProps = {
  colorSettings: {},
  subtractCount: () => {},
  count: '',
  addCount: () => {},
  inputValidation: () => {},
  onChangeText: () => {},
  title: '',
  placeholder: '',
  hasVariableButtons: true,
}
