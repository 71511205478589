export const excludedRelationships = [
  'proband',
  'grandmother',
  'grandfather',
  'father',
  'mother',
  'maternal uncle',
  'maternal aunt',
  'paternal uncle',
  'paternal aunt',
]
