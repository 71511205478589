export default class Family {
  proband = null

  grandparents = null

  parents = null

  siblings = []

  children = []

  partners = []

  paternalPibling = []

  maternalPibling = []
}
