import { View, TouchableOpacity, Image, Text } from 'react-native'

import { i18n } from '$localization/config.js'

import * as RelationshipTypes from '$constants/relationship_types/RelationshipTypes.js'

import global_styles from '$constants/styles/global.styles.js'

const FamilyInviteButton = ({
  currentMember,
  memberProfile,
  onPress = function () {},
}) => {
  const relationshipToProband = currentMember?.relationship_to_proband
  const _inviteStatus_ = memberProfile?.inviteStatus ?? null

  let allowInvite = true
  let inviteStatusText = i18n.t('invite_to_family')?.default
  const inviteDisabledText = `${inviteStatusText} (${
    i18n.t('not_available')?.default
  })`

  /* Append invite status to text */
  switch (`${_inviteStatus_}`.toLowerCase()) {
    case 'for verification':
      inviteStatusText += ` (${i18n.t('for_verification')?.default})`
      allowInvite = false
      break
    case 'pending':
      inviteStatusText += ` (${i18n.t('pending')?.default})`
      allowInvite = false
      break
    case 'verified':
      inviteStatusText += ` (${i18n.t('verified')?.default})`
      allowInvite = false
      break
  }

  /* Disable for the following Relationship Types */
  switch (relationshipToProband) {
    case RelationshipTypes.PROBAND:
    case RelationshipTypes.PARTNER:
      inviteStatusText = inviteDisabledText
      allowInvite = false
      break
  }

  /* Disable for unknown/null gender */
  if (!currentMember?.gender || currentMember?.gender == '') {
    inviteStatusText = inviteDisabledText
    allowInvite = false
  }

  if (!allowInvite) return null

  return (
    <TouchableOpacity
      style={[
        global_styles.normalButtonFull,
        {
          backgroundColor: 'transparent',
        },
      ]}
      activeOpacity={0.5}
      disabled={!allowInvite}
      onPress={onPress}
    >
      <Image
        source={require('$assets/images/new_assets/icon-invite-gray.png')}
      />

      <View style={{ width: 16 }} />

      <Text style={[global_styles.normalButtonText]}>{inviteStatusText}</Text>
    </TouchableOpacity>
  )
}

export default FamilyInviteButton
