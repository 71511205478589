import { Component } from 'react'
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },

  fullBackgroundImage: {
    flex: 1.0,
    width: '100%',
    justifyContent: 'center',
  },

  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },

  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 36,
    textAlign: 'center',
    color: 'white',
  },
  bodyText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color: 'white',
  },

  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  nextButtonText: {
    color: 'rgb(101,209,185)',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },
})

class SuccessScreenFamilyInvite extends Component {
  componentDidMount() {
    const { navigation, colorSettings } = this.props

    navigation.setParams({
      cs: colorSettings,
    })
  }

  nextButtonAction = () => {
    const { colorSettings, navigation } = this.props

    navigation.navigate('SignupFamilySharingSettingsNavigator', {
      screen: 'SignupFamilySharingSettingsMain',
    })
  }

  render() {
    const { colorSettings } = this.props

    return (
      <View style={styles.container}>
        <ImageBackground
          style={[
            styles.fullBackgroundImage,
            {
              backgroundColor: colorSettings?.success_bgcolor ?? 'white',
            },
          ]}
          resizeMode="cover"
        >
          <View style={styles.messageContentContainer}>
            <View style={{ height: 60 }} />

            <Image
              source={require('$assets/images/icon/success/badge.png')}
              style={{ width: 150, height: 150, resizeMode: 'contain' }}
            />

            <View style={{ width: '100%', marginVertical: 16 }}>
              <Text
                style={[
                  styles.titleText,
                  {
                    color: colorSettings?.text_input_color_2 ?? 'black',
                  },
                ]}
              >
                {i18n.t('welcome_to_the_family')?.default}
              </Text>
            </View>

            <View style={{ marginTop: 40, marginHorizontal: 16 }}>
              <View style={styles.nextButtonContainer}>
                <TouchableOpacity
                  style={[
                    styles.nextButton,
                    {
                      backgroundColor:
                        colorSettings?.selectable_btn_text_active_2 ??
                        'transparent',
                      borderColor:
                        colorSettings?.selectable_btn_text_active_2 ?? 'black',
                      borderWidth: 1.0,
                    },
                  ]}
                  onPress={this.nextButtonAction}
                >
                  <Text
                    style={[
                      styles.nextButtonText,
                      {
                        color:
                          colorSettings?.selectable_btn_active_2 ?? 'black',
                      },
                    ]}
                  >
                    {i18n.t('continue')?.default}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(SuccessScreenFamilyInvite)
