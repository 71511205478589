import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from 'react-native'

import { useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import { useSelector, useDispatch } from 'react-redux'

import ButtonToggle from '$components/button_toggle'
import FlexContainer from '$components/flex_container'

import { i18n } from '$localization/config.js'
import { WEB } from '$constants/Platforms.js'

import global_styles, {
  DefaultFullHeightForWeb,
} from '$constants/styles/global.styles.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { NAVIGATORS, CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import saveLastScreen from '$screens/_utils/SaveLastScreen.js'

import { setError } from '$redux/defaults/actions.js'

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  titleText: {
    marginTop: 20,
  },
  ButtonToggleContainer: {
    marginTop: 30,
    marginBottom: Platform.OS === 'web' ? 40 : 100,
  },
  subtitleSize: {
    fontSize: 16,
  },
})

export default function FamilyMembersCancer() {
  const dispatch = useDispatch()

  const [isToggleSelected, setIsToggleSelected] = useState(null)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const navigation = useNavigation()

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const account = useSelector((state) => state.accountStore.account)
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )

  const saveError = (data) => dispatch(setError(data))

  const handleToggle = (val) => {
    setIsToggleSelected(val)
    setNextButtonDisabled(false)
  }

  const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.FamilyMembersCancer?.name
  const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

  const nextButtonAction = () => {
    const navRoutes = {
      true: CUSTOM_FLOW_ROUTES.AddFamilyMemberViewCancer.name,
      false: getNextWorkflowRoute({
        defaultNextRoute: NAVIGATORS.Main.name,
      }),
    }

    const route = navRoutes[isToggleSelected]

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.family_members_cancer,
    }

    saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: route,
      selectedScreen: '',
      params: {
        nextScreenText: customScreenText?.nextScreenText,
      },
    })
  }

  return (
    <SafeAreaView style={[global_styles.container]}>
      <ScrollView
        style={{
          ...Platform.select({
            [WEB]: {
              height: DefaultFullHeightForWeb(10),
            },
          }),
        }}
        contentContainerStyle={styles.scrollContainer}
      >
        <FlexContainer>
          <View style={[global_styles.content]}>
            <Text
              style={[
                global_styles.titleText,
                {
                  color: colorSettings?.text_input_label_color_2,
                },
              ]}
            >
              {customScreenText?.topText
                ? customScreenText?.topText
                : 'Have any of your family members ever had cancer?'}
            </Text>
            <Text
              style={[
                global_styles.progressText,
                {
                  color: colorSettings?.text_input_label_color_2,
                },
                styles.subtitleSize,
              ]}
            >
              {customScreenText?.middleText
                ? customScreenText?.middleText
                : '(blood relatives only)'}
            </Text>

            <View style={styles.ButtonToggleContainer}>
              <ButtonToggle
                colorSettings={colorSettings}
                onPress={handleToggle}
              />
            </View>

            <Text
              style={[
                global_styles.progressText,
                global_styles.contentContainerPadded,
                {
                  color: colorSettings?.text_input_label_color_2,
                },
                styles.titleText,
                styles.subtitleSize,
              ]}
            >
              {customScreenText?.bottomText
                ? customScreenText?.bottomText
                : 'Include parents, grandparents, children, brothers/sisters, aunts/uncles, nephews/nieces and cousins.'}
            </Text>
          </View>
        </FlexContainer>
      </ScrollView>

      <View style={global_styles.nextButtonContainerAbsolute}>
        <TouchableOpacity
          style={[
            global_styles.nextButton,
            {
              backgroundColor:
                colorSettings?.bottom_next_btn_enabled || 'white',
              borderColor: colorSettings?.bottom_next_btn_enabled,
            },
          ]}
          onPress={nextButtonAction}
          disabled={nextButtonDisabled}
        >
          <Text
            style={[
              global_styles.nextButtonText,
              {
                color: colorSettings?.btn_filled_text_1,
              },
            ]}
          >
            {i18n.t('next').default}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  )
}
